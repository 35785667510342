/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { TextButton } from "@rewards-web/shared/components/text-button";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  RewardsAppAuthError,
  RewardsAppAuthErrorCode,
} from "../../../../shared/modules/auth/errors";
import { VerificationCodeForm } from "../../../../shared/verification-code-form";

interface ReauthCompleteChallengeFormProps {
  phoneNumber: string | undefined;
  onSubmit(
    values: ReauthCompleteChallengeFormValues & { codeWasPasted: boolean }
  ): void;
  rewardsProgramName: string;
  onResend(): void;
  onHelpClick(): void;
  challengeId: string;
}

export interface ReauthCompleteChallengeFormValues {
  code: string;
}

export function ReauthCompleteChallengeForm({
  phoneNumber,
  onSubmit,
  rewardsProgramName,
  onResend,
  onHelpClick,
  challengeId,
}: ReauthCompleteChallengeFormProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const { formatMessage } = useFormatters();

  const handleSubmit = async (code: string, wasPasted: boolean) => {
    try {
      await onSubmit({ code, codeWasPasted: wasPasted });
    } catch (error) {
      if (error instanceof RewardsAppAuthError) {
        switch (error.code) {
          case RewardsAppAuthErrorCode.DEACTIVATED:
            setErrorMessage(
              formatMessage({
                description: "Re-auth page > account deactivated error message",
                defaultMessage: "Code is invalid, please try again",
              })
            );
            break;
          case RewardsAppAuthErrorCode.CHALLENGE_EXPIRED:
            setErrorMessage(
              formatMessage(
                {
                  description: "Re-auth page > challenge expired error message",
                  defaultMessage:
                    "The code has expired. <resend_link>Re-send a new code to try again.</resend_link>",
                },
                {
                  resend_link: (nodes) => (
                    <TextButton
                      onClick={() => {
                        handleResendClick();
                      }}
                      css={(theme: AppTheme) => css`
                        font-weight: bold;
                        text-decoration: underline;
                        color: ${theme.palette.error.main};
                      `}
                    >
                      {nodes}
                    </TextButton>
                  ),
                }
              )
            );
            break;
          case RewardsAppAuthErrorCode.EXCEEDED_MAX_ATTEMPTS:
            setErrorMessage(
              formatMessage(
                {
                  description:
                    "Re-auth page > exceeded max attempts error message",
                  defaultMessage:
                    "You have tried too many times. <resend_link>Re-send a new code to try again.</resend_link>",
                },
                {
                  resend_link: (nodes) => (
                    <TextButton
                      onClick={() => {
                        handleResendClick();
                      }}
                      css={(theme: AppTheme) => css`
                        font-weight: bold;
                        text-decoration: underline;
                        color: ${theme.palette.error.main};
                      `}
                    >
                      {nodes}
                    </TextButton>
                  ),
                }
              )
            );
            break;
          default:
            setErrorMessage(
              formatMessage({
                description:
                  "Re-auth page > invalid verification code error message",
                defaultMessage: "Code is invalid, please try again",
              })
            );
            break;
        }
      }
    }
  };

  const handleResendClick = () => {
    onResend();
    setErrorMessage("");
  };

  return (
    <>
      <Typography
        variant="h1"
        color="primary"
        css={(theme: AppTheme) => css`
          font-size: 2em;
          margin-bottom: ${theme.spacing(2)};
          text-align: center;
          ${theme.breakpoints.up("sm")} {
            font-size: 2.5em;
          }
        `}
      >
        <FormattedMessage
          description="Re-auth page > enter code title"
          defaultMessage="Enter code"
        />
      </Typography>

      <Typography
        variant="body"
        color="textPrimary"
        align="center"
        css={(appTheme: AppTheme) => css`
          font-size: 1.2em;
          margin-bottom: ${appTheme.spacing(3.5)};
          ${appTheme.breakpoints.up("md")} {
            margin-bottom: ${appTheme.spacing(5)};
          }
        `}
      >
        <FormattedMessage
          description="Re-auth page > enter code description"
          defaultMessage="A code was texted to {phone_number}"
          values={{
            phone_number: formatPhoneNumber(phoneNumber ?? "**********"),
          }}
        />
      </Typography>
      <VerificationCodeForm
        key={challengeId}
        errorMessage={errorMessage}
        onResend={handleResendClick}
        onSubmit={handleSubmit}
        onClearErrorMessage={() => setErrorMessage("")}
        buttonText={formatMessage(
          {
            description: "Re-auth page > submit code button",
            defaultMessage: "Open {rewards_program_name}",
          },
          {
            rewards_program_name: rewardsProgramName,
          }
        )}
        onHelpClick={onHelpClick}
      />
    </>
  );
}
