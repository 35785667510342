/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { TimelineItem } from "@material-ui/lab";
import { ReactNode } from "react";

interface ReferralsCardTimelineItemProps {
  children: ReactNode;
}

export function ReferralsCardTimelineItem({
  children,
}: ReferralsCardTimelineItemProps) {
  return (
    <ClassNames>
      {({ css }) => (
        <TimelineItem
          classes={{
            root: css`
              min-height: 60px;
            `,
            missingOppositeContent: css`
              &:before {
                display: none;
              }
            `,
          }}
        >
          {children}
        </TimelineItem>
      )}
    </ClassNames>
  );
}
