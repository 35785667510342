/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { createWidget, Widget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { AppTheme } from "@rewards-web/shared/style/theme";

import { useAuth } from "../../../shared/modules/auth";
import { SubPageHeader } from "../../../shared/sub-page-header";

export function SurveyPreview() {
  const rootDivRef = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<Widget>();
  const { userId } = useAuth();
  const { typeform_survey_id: typeformId } = useParams<"typeform_survey_id">();

  useEffect(() => {
    const widget = createWidget(typeformId!, {
      container: rootDivRef.current!,
      hidden: { user_id: userId! },
      inlineOnMobile: true,
      opacity: 100,
      enableSandbox: true,
      onSubmit: () => {
        widgetRef.current?.unmount();
      },
    });

    widgetRef.current = widget;

    return () => {
      widget.unmount();
    };
  }, [typeformId, userId]);

  return (
    <div
      css={css`
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%;
      `}
    >
      <SubPageHeader
        pageName="Survey Preview"
        analyticsPageName="Survey Preview"
        backTo="rewards"
        css={css`
          flex: 0 1 auto;
        `}
      />
      <div
        css={(theme: AppTheme) => css`
          position: relative;
          flex: 1 1 auto;
          background-color: ${theme.palette.background.default};
          width: 100%;
          padding: ${theme.spacing(2)};
        `}
      >
        <div
          ref={rootDivRef}
          css={css`
            height: 100%;
            width: 100%;
          `}
        />
      </div>
    </div>
  );
}
