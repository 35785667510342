/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { last } from "lodash";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import clockInImageUrl from "../../rewards/evv-completion-rate-offer-card/clock-in.png";
import { EVVCompletionRateOfferEndedAnnouncement } from "../types";
import { useAcknowledgeEvvCompletionRateOfferEndedMutation } from "./acknowledge-evv-completion-rate-offer-ended.generated";

export interface EVVCompletionRateOfferEndedModalProps {
  evvCompletionRateOffer: EVVCompletionRateOfferEndedAnnouncement["evvCompletionRateOffer"];
  onRemoveAnnouncement(): void;
  onOpened(): void;
}

export function EVVCompletionRateOfferEndedModal({
  evvCompletionRateOffer,
  onRemoveAnnouncement,
  onOpened,
}: EVVCompletionRateOfferEndedModalProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();

  useEffect(() => {
    onOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    track("Viewed EVV completion rate offer ended modal", {
      evvCompletionRateOfferId: evvCompletionRateOffer.id,
    });
  }, [track, evvCompletionRateOffer.id]);

  const highestFinishedLevel = last(
    evvCompletionRateOffer.levels.filter((level) => level.finished)
  );

  const [
    acknowledgeEVVCompletionRateOfferEnded,
  ] = useAcknowledgeEvvCompletionRateOfferEndedMutation();

  const handleDismiss = async () => {
    track("Dismissed EVV completion rate offer ended modal", {
      evvCompletionRateOfferId: evvCompletionRateOffer.id,
    });
    onRemoveAnnouncement();

    try {
      await acknowledgeEVVCompletionRateOfferEnded({
        variables: { evvCompletionRateOfferId: evvCompletionRateOffer.id },
      });
    } catch (error) {
      reportError(error);
    }
  };

  return (
    <ResponsiveDialog backgroundColor="white" open mobileType="dialog">
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(2)}
            ${theme.spacing(2)};
        `}
      >
        <img
          src={clockInImageUrl}
          alt="Clock"
          css={(theme: AppTheme) => css`
            display: block;
            margin: 0 auto;
            max-width: 100%;
            width: 117px;
            height: 117px;
            transform: rotate(3deg);
            margin-bottom: ${theme.spacing(3)};
          `}
        />
        <Typography
          variant="h2"
          align="center"
          color="text.primary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
            line-height: 40px;
            font-size: 30px;
          `}
        >
          {highestFinishedLevel!.evvCompletionPercentage < 100 ? (
            <FormattedMessage
              defaultMessage="Great job!"
              description="EVV completion rate offer ended modal > reached target > title"
            />
          ) : (
            <FormattedMessage
              defaultMessage="You are awesome!"
              description="EVV completion rate offer ended modal > reached target 100 > title"
            />
          )}
        </Typography>
        <Typography
          variant="body"
          align="center"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
            color: ${theme.palette.grey[800]};
          `}
        >
          {highestFinishedLevel!.evvCompletionPercentage < 100 ? (
            <FormattedMessage
              defaultMessage="You’ve completed your goal and earned {num_tickets, number} raffle {num_tickets, plural, one {ticket} other {tickets}}!"
              description="EVV completion rate offer ended modal > reached target > body"
              values={{
                num_tickets: highestFinishedLevel!.numDrawTickets,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="You’ve completed your goal with no missed shifts! You’ve earned {num_tickets, number} raffle {num_tickets, plural, one {ticket} other {tickets}}!"
              description="EVV completion rate offer ended modal > reached target 100 > body"
              values={{
                num_tickets: highestFinishedLevel!.numDrawTickets,
              }}
            />
          )}
        </Typography>
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Got it!",
            description: "EVV completion rate offer ended modal > button",
          })}
          onClick={handleDismiss}
        />
      </div>
    </ResponsiveDialog>
  );
}
