/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faChevronRight,
  faCircleQuestion,
  faEnvelope,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken } from "@material-ui/core";

import { Card } from "@rewards-web/shared/components/card";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../shared/page-content-container";
import { SubPageHeader } from "../../../shared/sub-page-header";

const helpEmail = "help@caribou.care";

function HelpAndSupportItem({
  icon,
  linkTo,
  title,
}: {
  icon: JSX.Element;
  linkTo: string;
  title: string;
}) {
  return (
    <a
      href={linkTo}
      target="_blank"
      rel="noreferrer"
      css={(appTheme: AppTheme) => css`
        text-decoration: none;
        border-bottom: solid 1px ${appTheme.palette.grey[400]};
        font-size: 18px;
        padding: ${appTheme.spacing(2.5)} ${appTheme.spacing(1)};
        color: ${appTheme.palette.grey[800]};
        transition: background-color 0.2s;
        :hover {
          background-color: ${darken(appTheme.palette.background.paper, 0.01)};
        }
        display: flex;
        flex-direction: row;
        gap: ${appTheme.spacing(1)};
        align-items: center;
      `}
    >
      <span>{icon}</span>
      {title}
      <FontAwesomeIcon
        icon={faChevronRight}
        css={css`
          margin-left: auto;
        `}
      />
    </a>
  );
}

export function HelpAndSupportPage() {
  const { formatMessage } = useFormatters();

  return (
    <>
      <SubPageHeader
        pageName={formatMessage({
          description: "Help & Support settings page > page title",
          defaultMessage: "Help & Support",
        })}
        analyticsPageName={"Help & Support"}
        backTo={"settings"}
      />

      <PageContentContainer>
        <Card
          css={(appTheme: AppTheme) => css`
            padding: ${appTheme.spacing(2.5)};
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <HelpAndSupportItem
              icon={<FontAwesomeIcon icon={faCircleQuestion} />}
              title={formatMessage({
                description: "Help & Support settings page > FAQ title",
                defaultMessage: "FAQs and Help Center",
              })}
              linkTo="https://support.caribou.care/hc/en-us/sections/13077485669143-Caregivers"
            />
            <HelpAndSupportItem
              icon={<FontAwesomeIcon icon={faEnvelope} />}
              title={formatMessage({
                description:
                  "Help & Support settings page > Email Caribou help title",
                defaultMessage: `Email ${helpEmail}`,
              })}
              linkTo={`mailto:${helpEmail}`}
            />
          </div>
        </Card>
      </PageContentContainer>
    </>
  );
}
