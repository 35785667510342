import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AcknowledgePowerHoursLevelFinishedMutationVariables = Types.Exact<{
  powerHoursOfferId: Types.Scalars['ID'];
  levelNumber: Types.Scalars['Int'];
}>;


export type AcknowledgePowerHoursLevelFinishedMutation = (
  { __typename?: 'Mutation' }
  & { acknowledgePowerHoursLevelFinished: (
    { __typename?: 'PowerHoursOffer' }
    & Pick<Types.PowerHoursOffer, 'id'>
    & { levels: Array<(
      { __typename?: 'PowerHoursLevel' }
      & Pick<Types.PowerHoursLevel, 'id' | 'acknowledgedLevelFinished'>
    )> }
  ) }
);


export const AcknowledgePowerHoursLevelFinishedDocument = gql`
    mutation AcknowledgePowerHoursLevelFinished($powerHoursOfferId: ID!, $levelNumber: Int!) {
  acknowledgePowerHoursLevelFinished(
    powerHoursOfferId: $powerHoursOfferId
    levelNumber: $levelNumber
  ) {
    id
    levels {
      id
      acknowledgedLevelFinished
    }
  }
}
    `;
export type AcknowledgePowerHoursLevelFinishedMutationFn = Apollo.MutationFunction<AcknowledgePowerHoursLevelFinishedMutation, AcknowledgePowerHoursLevelFinishedMutationVariables>;

/**
 * __useAcknowledgePowerHoursLevelFinishedMutation__
 *
 * To run a mutation, you first call `useAcknowledgePowerHoursLevelFinishedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgePowerHoursLevelFinishedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgePowerHoursLevelFinishedMutation, { data, loading, error }] = useAcknowledgePowerHoursLevelFinishedMutation({
 *   variables: {
 *      powerHoursOfferId: // value for 'powerHoursOfferId'
 *      levelNumber: // value for 'levelNumber'
 *   },
 * });
 */
export function useAcknowledgePowerHoursLevelFinishedMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgePowerHoursLevelFinishedMutation, AcknowledgePowerHoursLevelFinishedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgePowerHoursLevelFinishedMutation, AcknowledgePowerHoursLevelFinishedMutationVariables>(AcknowledgePowerHoursLevelFinishedDocument, options);
      }
export type AcknowledgePowerHoursLevelFinishedMutationHookResult = ReturnType<typeof useAcknowledgePowerHoursLevelFinishedMutation>;
export type AcknowledgePowerHoursLevelFinishedMutationResult = Apollo.MutationResult<AcknowledgePowerHoursLevelFinishedMutation>;
export type AcknowledgePowerHoursLevelFinishedMutationOptions = Apollo.BaseMutationOptions<AcknowledgePowerHoursLevelFinishedMutation, AcknowledgePowerHoursLevelFinishedMutationVariables>;