import { useFormatters } from "..";
import { UseFormattersReturnValue } from "../hooks/use-formatters";

interface FormattedMessageProps {
  description: string;
  defaultMessage: string;
  values?: Parameters<UseFormattersReturnValue["formatMessage"]>[1];
}

/**
 * Formats a message using ICU message format (via Format.js).
 * Usage examples: https://formatjs.io/docs/react-intl/components#usage
 */
export function FormattedMessage({
  values,
  ...descriptor
}: FormattedMessageProps) {
  const { formatMessage } = useFormatters();
  return <>{formatMessage(descriptor, values)}</>;
}
