import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobShareFilterOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type JobShareFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { getSharableJobPostingFilterOptions: (
    { __typename?: 'GetSharableJobPostingFilterOptions' }
    & { combinations: Array<(
      { __typename?: 'GetSharableJobPostingFilterCombination' }
      & Pick<Types.GetSharableJobPostingFilterCombination, 'id' | 'title' | 'geography' | 'numJobs'>
    )> }
  ) }
);


export const JobShareFilterOptionsDocument = gql`
    query JobShareFilterOptions {
  getSharableJobPostingFilterOptions {
    combinations {
      id
      title
      geography
      numJobs
    }
  }
}
    `;

/**
 * __useJobShareFilterOptionsQuery__
 *
 * To run a query within a React component, call `useJobShareFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobShareFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobShareFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobShareFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<JobShareFilterOptionsQuery, JobShareFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobShareFilterOptionsQuery, JobShareFilterOptionsQueryVariables>(JobShareFilterOptionsDocument, options);
      }
export function useJobShareFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobShareFilterOptionsQuery, JobShareFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobShareFilterOptionsQuery, JobShareFilterOptionsQueryVariables>(JobShareFilterOptionsDocument, options);
        }
export type JobShareFilterOptionsQueryHookResult = ReturnType<typeof useJobShareFilterOptionsQuery>;
export type JobShareFilterOptionsLazyQueryHookResult = ReturnType<typeof useJobShareFilterOptionsLazyQuery>;
export type JobShareFilterOptionsQueryResult = Apollo.QueryResult<JobShareFilterOptionsQuery, JobShareFilterOptionsQueryVariables>;