/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import coinPointsUrl from "../../../../shared/coin-points.png";
import TicketIcon from "../../../../shared/ticket-icon.png";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";

export interface OfferRewardProps {
  isCompleted: boolean | undefined;
  nextReward: RewardsPageDataQuery["listMyRewardOffers"][0]["nextReward"];
  value:
    | {
        type: "auto";
        completions: RewardsPageDataQuery["listMyRewardOffers"][0]["completions"];
      }
    | {
        type: "custom_text";
        text: string;
      };
  color?: "white";
  hideIcon?: boolean;
}

export function OfferReward({
  value,
  nextReward,
  isCompleted,
  hideIcon = false,
  color,
}: OfferRewardProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();

  const reward: {
    value?: number | null;
    type: "ticket" | "points";
  } | null = (() => {
    if (nextReward?.pointValue) {
      return { value: nextReward.pointValue, type: "points" };
    }

    if (nextReward?.numDrawTickets) {
      return { value: nextReward.numDrawTickets, type: "ticket" };
    }

    return null;
  })();

  const text = ((): string => {
    switch (value.type) {
      case "custom_text":
        return value.text;
      default: {
        if (isCompleted) {
          const totalPointsEarned = value.completions.reduce(
            (prev, completion) => prev + (completion.pointValue ?? 0),
            0
          );
          const totalDrawTicketsEarned = value.completions.reduce(
            (prev, completion) => prev + (completion.numDrawTickets ?? 0),
            0
          );

          return totalPointsEarned
            ? formatMessage(
                {
                  defaultMessage:
                    "{points, number} {points, plural, one {point} other {points}} earned",
                  description: "Rewards page > offer cards > points",
                },
                { points: totalPointsEarned }
              )
            : formatMessage(
                {
                  defaultMessage:
                    "{tickets, number} {tickets, plural, one {ticket} other {tickets}} earned",
                  description: "Rewards page > offer cards > tickets",
                },
                { tickets: totalDrawTicketsEarned }
              );
        }

        if (!reward) {
          return ""; // shouldn't happen
        }

        return reward.type === "points"
          ? formatMessage(
              {
                defaultMessage:
                  "{points, number} {points, plural, one {point} other {points}}",
                description: "Rewards page > offer cards > points",
              },
              { points: reward.value }
            )
          : formatMessage(
              {
                defaultMessage:
                  "{tickets, number} {tickets, plural, one {ticket} other {tickets}}",
                description: "Rewards page > offer cards > tickets",
              },
              { tickets: reward.value }
            );
      }
    }
  })();

  const icon = (() => {
    if (isCompleted) {
      return (
        <FontAwesomeIcon
          color={color === "white" ? "white" : theme.palette.primary.main}
          icon={faCircleCheck}
          fontSize={theme.typography.footnote.fontSize}
        />
      );
    }

    if (!reward || reward.type === "points") {
      // point reward
      return (
        <img
          src={coinPointsUrl}
          alt={formatMessage({
            defaultMessage: "star icon",
            description: "Rewards page > offer cards > star icon alt",
          })}
          css={css`
            width: 16px;
            flex-shrink: 0;
          `}
        />
      );
    }

    // assume draw reward
    return (
      <img
        src={TicketIcon}
        alt={formatMessage({
          defaultMessage: "ticket icon",
          description: "Rewards page > offer cards > ticket icon alt",
        })}
        css={css`
          width: 30px;
          flex-shrink: 0;
        `}
      />
    );
  })();

  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        align-items: flex-start;
        gap: ${appTheme.spacing(0.75)};
      `}
    >
      {!hideIcon && (
        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: ${appTheme.typography.footnote.lineHeight};
          `}
        >
          {icon}
        </div>
      )}
      <Typography variant="footnote">{text}</Typography>
    </div>
  );
}
