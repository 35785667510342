import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecordVideoWatchedMutationVariables = Types.Exact<{
  videoId: Types.Scalars['ID'];
}>;


export type RecordVideoWatchedMutation = (
  { __typename?: 'Mutation' }
  & { recordVideoWatchByMe: (
    { __typename?: 'RecordVideoWatchByMeResponse' }
    & Pick<Types.RecordVideoWatchByMeResponse, 'videoWatchId'>
  ) }
);


export const RecordVideoWatchedDocument = gql`
    mutation RecordVideoWatched($videoId: ID!) {
  recordVideoWatchByMe(videoId: $videoId) {
    videoWatchId
  }
}
    `;
export type RecordVideoWatchedMutationFn = Apollo.MutationFunction<RecordVideoWatchedMutation, RecordVideoWatchedMutationVariables>;

/**
 * __useRecordVideoWatchedMutation__
 *
 * To run a mutation, you first call `useRecordVideoWatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordVideoWatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordVideoWatchedMutation, { data, loading, error }] = useRecordVideoWatchedMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useRecordVideoWatchedMutation(baseOptions?: Apollo.MutationHookOptions<RecordVideoWatchedMutation, RecordVideoWatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordVideoWatchedMutation, RecordVideoWatchedMutationVariables>(RecordVideoWatchedDocument, options);
      }
export type RecordVideoWatchedMutationHookResult = ReturnType<typeof useRecordVideoWatchedMutation>;
export type RecordVideoWatchedMutationResult = Apollo.MutationResult<RecordVideoWatchedMutation>;
export type RecordVideoWatchedMutationOptions = Apollo.BaseMutationOptions<RecordVideoWatchedMutation, RecordVideoWatchedMutationVariables>;