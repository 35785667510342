import { useTheme } from "@emotion/react";
import { SVGProps } from "react";

export function JobFilterIcon(props: SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M18.667 18.668h2.666c.736 0 1.334.597 1.334 1.333v5.334c0 .736-.598 1.333-1.334 1.333h-2.666a1.334 1.334 0 0 1-1.334-1.333V20c0-.736.598-1.333 1.334-1.333Z"
        stroke={props.stroke || theme.palette.primary.main}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 22.667h-5.333M17.333 22.667H4"
        stroke={props.stroke || theme.palette.primary.main}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M13.333 13.332h-2.666a1.334 1.334 0 0 1-1.334-1.333V6.665c0-.736.598-1.333 1.334-1.333h2.666c.736 0 1.334.597 1.334 1.333V12c0 .736-.598 1.333-1.334 1.333Z"
        stroke={props.stroke || theme.palette.primary.main}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 9.33h5.333M14.667 9.33H28"
        stroke={props.stroke || theme.palette.primary.main}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
