import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { CaribouSponsoredDrawPage } from "./caribou-sponsored-draw";
import { useDrawPageDataQuery } from "./draw-page-data.generated";
import { OrganizationRunDrawPage } from "./organization-run-draw";

export function DrawPage() {
  const { formatMessage } = useFormatters();
  const query = useDrawPageDataQuery({
    onError: reportError,
  });

  const errorAlert = (
    <Alert
      severity="error"
      message={formatMessage({
        defaultMessage: "An unexpected error occurred. Please try again later.",
        description: "Draw page > page load error",
      })}
    />
  );

  if (query.error) {
    return errorAlert;
  }

  if (!query.data) {
    return <PageLoadingState />;
  }

  if (query.data.myOrganization.drawsEnabled) {
    // organization-run draws are enabled; let's show that screen
    // (even if they have access to caribou-sponsored draws as well)
    return <OrganizationRunDrawPage />;
  }

  if (query.data.myOrganization.hasAccessToCaribouSponsoredDraws) {
    return <CaribouSponsoredDrawPage />;
  }

  return errorAlert;
}
