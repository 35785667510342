/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { isUndefined } from "lodash";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

import coinPointsUrl from "../../../../coin-points.png";
import drawTicketsUrl from "../../../../ticket-icon.png";

type PointsRewardConfig = { numPoints?: number } & (
  | { labelType: "default" }
  | { labelType: "custom"; label: string }
);

type OrganizationDrawTicketsRewardConfig = {
  numOrganizationDrawTickets: number;
};

type CaribouSponsoredDrawTicketsRewardConfig = {
  numCaribouSponsoredDrawTickets: number;
};

function isValidPointsRewardConfig(
  pointsConfig: GoalRewardProps["rewardConfig"]["points"]
): pointsConfig is PointsRewardConfig {
  return pointsConfig.numPoints ? pointsConfig.numPoints > 0 : false;
}

function isValidCaribouSponsoredDrawTicketsRewardConfig(
  caribouSponsoredDrawTicketsConfig: GoalRewardProps["rewardConfig"]["caribouSponsoredDrawTickets"]
): caribouSponsoredDrawTicketsConfig is CaribouSponsoredDrawTicketsRewardConfig {
  return caribouSponsoredDrawTicketsConfig?.numCaribouSponsoredDrawTickets
    ? caribouSponsoredDrawTicketsConfig.numCaribouSponsoredDrawTickets > 0
    : false;
}

function isValidOrganizationDrawTicketsRewardConfig(
  organizationDrawTicketsConfig: GoalRewardProps["rewardConfig"]["organizationDrawTickets"]
): organizationDrawTicketsConfig is OrganizationDrawTicketsRewardConfig {
  return organizationDrawTicketsConfig?.numOrganizationDrawTickets
    ? organizationDrawTicketsConfig.numOrganizationDrawTickets > 0
    : false;
}
export interface GoalRewardProps extends StylableProps {
  status: "achieved" | "locked" | "available";
  rewardConfig: {
    points: PointsRewardConfig;
    caribouSponsoredDrawTickets?: CaribouSponsoredDrawTicketsRewardConfig;
    organizationDrawTickets?: OrganizationDrawTicketsRewardConfig;
  };
  opacity?: string;
}

export function GoalReward({
  status,
  rewardConfig,
  opacity,
  className,
}: GoalRewardProps) {
  const { formatMessage } = useFormatters();

  if (
    isUndefined(rewardConfig.points) &&
    isUndefined(rewardConfig.caribouSponsoredDrawTickets) &&
    isUndefined(rewardConfig.organizationDrawTickets)
  ) {
    return null;
  }

  const availablePointsReward = isValidPointsRewardConfig(rewardConfig.points)
    ? rewardConfig.points
    : null;

  const availableCaribouSponsoredDrawTicketsReward = isValidCaribouSponsoredDrawTicketsRewardConfig(
    rewardConfig.caribouSponsoredDrawTickets
  )
    ? rewardConfig.caribouSponsoredDrawTickets
    : null;

  const availableOrganizationDrawTicketsReward = isValidOrganizationDrawTicketsRewardConfig(
    rewardConfig.organizationDrawTickets
  )
    ? rewardConfig.organizationDrawTickets
    : null;

  return (
    <div
      className={className}
      css={(appTheme: AppTheme) => css`
        display: flex;
        column-gap: ${appTheme.spacing(1)};
        align-items: center;
        flex-wrap: wrap;
        ${status === "locked" &&
        css`
          filter: grayscale(1);
        `};
      `}
    >
      {availablePointsReward && (
        <div
          className={className}
          css={(appTheme: AppTheme) => css`
            display: flex;
            column-gap: ${appTheme.spacing(1)};
            align-items: center;
            ${opacity &&
            css`
              opacity: ${opacity};
            `}
          `}
        >
          <img height="24px" src={coinPointsUrl} alt="dollar coin" />
          <Typography variant="footnote">
            {(() => {
              switch (availablePointsReward.labelType) {
                case "default":
                  return status === "achieved"
                    ? formatMessage(
                        {
                          defaultMessage: "{num_points, number} pts earned",
                          description: "Goal card > pts earned",
                        },
                        {
                          num_points: availablePointsReward.numPoints,
                        }
                      )
                    : formatMessage(
                        {
                          defaultMessage: "{num_points, number} pts",
                          description: "Goal card > pts",
                        },
                        {
                          num_points: availablePointsReward.numPoints,
                        }
                      );
                case "custom":
                  return availablePointsReward.label;
              }
            })()}
          </Typography>
        </div>
      )}

      {availableCaribouSponsoredDrawTicketsReward && (
        <div
          className={className}
          css={(appTheme: AppTheme) => css`
            display: flex;
            column-gap: ${appTheme.spacing(1)};
            align-items: center;
            ${opacity &&
            css`
              opacity: ${opacity};
            `}
          `}
        >
          <img width="24px" src={drawTicketsUrl} alt="draw ticket" />
          <Typography variant="footnote">
            {(() => {
              return status === "achieved"
                ? formatMessage(
                    {
                      defaultMessage:
                        "{num_draw_tickets} {num_draw_tickets, plural, one {ticket} other {tickets}} earned",
                      description: "Goal card > tickets earned",
                    },
                    {
                      num_draw_tickets:
                        availableCaribouSponsoredDrawTicketsReward.numCaribouSponsoredDrawTickets,
                    }
                  )
                : formatMessage(
                    {
                      defaultMessage:
                        "{num_draw_tickets} {num_draw_tickets, plural, one {ticket} other {tickets}}",
                      description: "Goal card > tickets",
                    },
                    {
                      num_draw_tickets:
                        availableCaribouSponsoredDrawTicketsReward.numCaribouSponsoredDrawTickets,
                    }
                  );
            })()}
          </Typography>
        </div>
      )}
      {availableOrganizationDrawTicketsReward && (
        <div
          className={className}
          css={(appTheme: AppTheme) => css`
            display: flex;
            column-gap: ${appTheme.spacing(1)};
            align-items: center;
            ${opacity &&
            css`
              opacity: ${opacity};
            `}
          `}
        >
          <img width="24px" src={drawTicketsUrl} alt="draw ticket" />
          <Typography variant="footnote">
            {(() => {
              return status === "achieved"
                ? formatMessage(
                    {
                      defaultMessage:
                        "{num_draw_tickets} {num_draw_tickets, plural, one {ticket} other {tickets}} earned",
                      description: "Goal card > tickets earned",
                    },
                    {
                      num_draw_tickets:
                        availableOrganizationDrawTicketsReward.numOrganizationDrawTickets,
                    }
                  )
                : formatMessage(
                    {
                      defaultMessage:
                        "{num_draw_tickets} {num_draw_tickets, plural, one {ticket} other {tickets}}",
                      description: "Goal card > tickets",
                    },
                    {
                      num_draw_tickets:
                        availableOrganizationDrawTicketsReward.numOrganizationDrawTickets,
                    }
                  );
            })()}
          </Typography>
        </div>
      )}
    </div>
  );
}
