/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { last, sortBy } from "lodash";
import { ReactNode, useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import coinPointsUrl from "../../../../shared/coin-points.png";
import { PowerHoursOfferEndedAnnouncement } from "../types";
import { useAcknowledgePowerHoursOfferEndedMutation } from "./acknowledge-power-hours-offer-ended.generated";
import heartIconUrl from "./heart-icon.png";

export interface PowerHoursOfferEndedModalProps {
  powerHoursOffer: PowerHoursOfferEndedAnnouncement["powerHoursOffer"];
  onRemoveAnnouncement(): void;
  onOpened(): void;
}

export function PowerHoursOfferEndedModal({
  powerHoursOffer,
  onRemoveAnnouncement,
  onOpened,
}: PowerHoursOfferEndedModalProps) {
  const { formatMessage } = useFormatters();

  useEffect(() => {
    onOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const levelCompletionStatus = (() => {
    if (powerHoursOffer.levels.every((level) => level.finished)) {
      return "all_levels_completed";
    } else if (powerHoursOffer.levels.some((level) => level.finished)) {
      return "only_some_levels_completed";
    } else {
      return "no_levels_completed";
    }
  })();

  const lastFinishedLevel = last(
    sortBy(powerHoursOffer.levels, (level) => level.levelNumber).filter(
      (level) => level.finished
    )
  );

  const [
    acknowledgePowerHoursOfferEndedMutation,
  ] = useAcknowledgePowerHoursOfferEndedMutation();

  const handleDismiss = async () => {
    onRemoveAnnouncement();

    try {
      await acknowledgePowerHoursOfferEndedMutation({
        variables: { powerHoursOfferId: powerHoursOffer.id },
      });
    } catch (error) {
      reportError(error);
    }
  };

  const renderPointsText = (nodes: ReactNode[]) => (
    <Typography
      variant="subtitle"
      color="text.primary"
      fontWeight={600}
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing(1)};
        margin: ${theme.spacing(1)} 0;
      `}
    >
      <img
        src={coinPointsUrl}
        css={css`
          width: 18px;
          height: 18px;
        `}
        alt={formatMessage({
          defaultMessage: "Star",
          description: "Power hours ended modal > star icon alt",
        })}
      />{" "}
      {nodes}
    </Typography>
  );

  return (
    <ResponsiveDialog backgroundColor="white" open mobileType="dialog">
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(3.75)} ${theme.spacing(2.5)}
            ${theme.spacing(2)} ${theme.spacing(2.5)};
        `}
      >
        <img
          src={heartIconUrl}
          alt="Heart"
          css={(theme: AppTheme) => css`
            display: block;
            margin: 0 auto;
            max-width: 100%;
            width: 81px;
            height: 72px;
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <div
          css={(theme: AppTheme) => css`
            background-color: ${theme.palette.tertiary.main};
            border-radius: 1000px;
            width: max-content;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 ${theme.spacing(0.75)};
            gap: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <FontAwesomeIcon icon={faClock} color="white" />
          <Typography variant="caption" textTransform="uppercase" color="white">
            <FormattedMessage
              defaultMessage="Times up!"
              description="Power hours ended modal > times up"
            />
          </Typography>
        </div>
        <Typography
          variant="h2"
          align="center"
          color="text.primary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {(levelCompletionStatus === "all_levels_completed" ||
            levelCompletionStatus === "only_some_levels_completed") && (
            <FormattedMessage
              defaultMessage="Great job!"
              description="Power hours ended modal > finished some levels > title"
            />
          )}
          {levelCompletionStatus === "no_levels_completed" && (
            <FormattedMessage
              defaultMessage="Power hours has ended"
              description="Power hours ended modal > finished no levels > title"
            />
          )}
        </Typography>
        <Typography
          variant="body"
          align="center"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
            color: ${theme.palette.grey[800]};
          `}
        >
          {levelCompletionStatus === "all_levels_completed" && (
            <FormattedMessage
              defaultMessage="You've completed all levels and earned: <points_text>{points_earned, number} points</points_text>"
              description="Power hours ended modal > finished all levels > body"
              values={{
                points_earned: lastFinishedLevel!.pointValue,
                points_text: renderPointsText,
              }}
            />
          )}
          {levelCompletionStatus === "only_some_levels_completed" && (
            <FormattedMessage
              defaultMessage="You've completed level {level_number, number} and earned: <points_text>{points_earned, number} points</points_text>"
              description="Power hours ended modal > finished some levels > body"
              values={{
                level_number: lastFinishedLevel!.levelNumber,
                points_earned: lastFinishedLevel!.pointValue,
                points_text: renderPointsText,
              }}
            />
          )}
          {levelCompletionStatus === "no_levels_completed" && (
            <FormattedMessage
              defaultMessage="You have not completed any levels. Not to worry, you can always earn points next time!"
              description="Power hours ended modal > finished no levels > body"
            />
          )}
        </Typography>
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Got it!",
            description: "Power hours ended modal > button",
          })}
          onClick={handleDismiss}
        />
      </div>
    </ResponsiveDialog>
  );
}
