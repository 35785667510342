import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDrawWinnersQueryVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
}>;


export type GetDrawWinnersQuery = (
  { __typename?: 'Query' }
  & { draw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'scheduledStartDate' | 'scheduledEndDate' | 'myRewardsUserHasTicketsInDraw'>
    & { prizeStructure: (
      { __typename: 'DrawPrizeStructureSinglePrizeMultipleWinners' }
      & { prize: (
        { __typename: 'DrawPrizePoints' }
        & Pick<Types.DrawPrizePoints, 'pointValue'>
      ) | { __typename: 'DrawTieredPrizePoints' } }
    ) | (
      { __typename: 'DrawPrizeStructureTieredPrizesMultipleWinners' }
      & Pick<Types.DrawPrizeStructureTieredPrizesMultipleWinners, 'numWinners' | 'totalPrizePointValue'>
      & { prizes: Array<{ __typename: 'DrawPrizePoints' } | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'pointValue' | 'numWinners'>
      )> }
    ), winners?: Types.Maybe<Array<(
      { __typename?: 'DrawWinner' }
      & Pick<Types.DrawWinner, 'id' | 'prizeTierIndex' | 'userDisplayName' | 'isMe' | 'claimedPrize'>
    )>> }
  )>, myRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName' | 'lastName'>
  ), org: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar'>
  ), currentDraw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'status'>
  )> }
);


export const GetDrawWinnersDocument = gql`
    query GetDrawWinners($drawId: ID!) {
  draw: getDrawById(drawId: $drawId) {
    id
    scheduledStartDate
    scheduledEndDate
    myRewardsUserHasTicketsInDraw
    prizeStructure {
      __typename
      ... on DrawPrizeStructureSinglePrizeMultipleWinners {
        prize {
          __typename
          ... on DrawPrizePoints {
            pointValue
          }
        }
      }
      ... on DrawPrizeStructureTieredPrizesMultipleWinners {
        prizes {
          __typename
          ... on DrawTieredPrizePoints {
            pointValue
            numWinners
          }
        }
        numWinners
        totalPrizePointValue
      }
    }
    winners {
      id
      prizeTierIndex
      userDisplayName
      isMe
      claimedPrize
    }
  }
  myRewardsUser: getMyRewardsUser {
    id
    firstName
    lastName
  }
  org: getMyRewardsOrganization {
    id
    pointsPerDollar
  }
  currentDraw: getMyCurrentDraw {
    id
    status
  }
}
    `;

/**
 * __useGetDrawWinnersQuery__
 *
 * To run a query within a React component, call `useGetDrawWinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawWinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawWinnersQuery({
 *   variables: {
 *      drawId: // value for 'drawId'
 *   },
 * });
 */
export function useGetDrawWinnersQuery(baseOptions: Apollo.QueryHookOptions<GetDrawWinnersQuery, GetDrawWinnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDrawWinnersQuery, GetDrawWinnersQueryVariables>(GetDrawWinnersDocument, options);
      }
export function useGetDrawWinnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDrawWinnersQuery, GetDrawWinnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDrawWinnersQuery, GetDrawWinnersQueryVariables>(GetDrawWinnersDocument, options);
        }
export type GetDrawWinnersQueryHookResult = ReturnType<typeof useGetDrawWinnersQuery>;
export type GetDrawWinnersLazyQueryHookResult = ReturnType<typeof useGetDrawWinnersLazyQuery>;
export type GetDrawWinnersQueryResult = Apollo.QueryResult<GetDrawWinnersQuery, GetDrawWinnersQueryVariables>;