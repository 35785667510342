import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect } from "react";

import { reportError } from "@rewards-web/shared/modules/error";
import {
  useFeatureFlagsInitialized,
  useFeatureFlagUser,
} from "@rewards-web/shared/modules/feature-flag";

import { useAuth } from "../../../shared/modules/auth";
import {
  RewardsAppAnalyticsAttributesDocument,
  RewardsAppAnalyticsAttributesQuery,
} from "../use-identify-user-for-screen-recording/analytics-attributes.generated";

/**
 * Initializes feature flags for the currently logged-in user
 */
export function useInitializeFeatureFlagsForUser() {
  const { userId } = useAuth();
  const apolloClient = useApolloClient();
  const initialized = useFeatureFlagsInitialized();
  const [featureFlagUser, setFeatureFlagUser] = useFeatureFlagUser();

  const loadAttributesAndSetFeatureFlagUser = useCallback(async () => {
    try {
      const {
        data,
      } = await apolloClient.query<RewardsAppAnalyticsAttributesQuery>({
        query: RewardsAppAnalyticsAttributesDocument,
        fetchPolicy: "cache-first",
      });

      setFeatureFlagUser({
        userId: userId!,
        attributes: {
          organization_id: Number(data.getMyRewardsOrganization.id),
          organization_is_qa: data.getMyRewardsOrganization.isQA,
          organization_is_demo: data.getMyRewardsOrganization.isDemo,
        },
      });
    } catch (error) {
      reportError(error);
      setFeatureFlagUser({ userId: userId!, attributes: {} });
    }
  }, [apolloClient, setFeatureFlagUser, userId]);

  const shouldLoadUser = userId && userId !== featureFlagUser?.userId;

  useEffect(() => {
    if (shouldLoadUser) {
      loadAttributesAndSetFeatureFlagUser();
    }
  }, [shouldLoadUser, loadAttributesAndSetFeatureFlagUser]);

  return {
    initialized: initialized && !shouldLoadUser,
  };
}
