/** @jsxImportSource @emotion/react */
import { useState } from "react";

import {
  EvvRulesClockMethod,
  EvvStreakGoalTracker,
  GoalStatus,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import { BaseGoalCard } from "../base-goal-card";
import { GoalCardSkeleton } from "../goal-card-skeleton";
import { useGoalRewardsUserDataQuery } from "../goal-rewards-user.query.generated";
import { useCommonBaseGoalCardProps } from "../lib";
import { useEvvGoalCardDataQuery } from "./evv-streak-goal-card.query.generated";
import { EVVStreakGoalCardSchedulingSoftwareModal } from "./hhax-modal";
import { getSchedulingSoftwareDetails } from "./lib";

/**
 * Interactive Voice Response (IVR) supports Attendant and Employer of Record (EOR) access to
 * Consumer Direct Care Network. (CDCN) transaction systems via landline telephone.
 */
const CLIENT_PHONE_EVV_METHOD = EvvRulesClockMethod.Ivr;

export function EvvStreakGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage } = useFormatters();
  const [open, setOpen] = useState(false);
  const userDataQueryResult = useGoalRewardsUserDataQuery();

  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/evv",
  });

  const {
    targetStreak,
    currentStreak,
  } = goal.goalTracker as EvvStreakGoalTracker;

  const { data, loading } = useEvvGoalCardDataQuery({
    skip: !targetStreak,
    onError: reportError,
  });

  const isClientPhoneEvvValid = (
    data?.getMyRewardsOrganizationEVVRules?.rules?.validClockMethods ?? []
  ).includes(CLIENT_PHONE_EVV_METHOD)
    ? "YES"
    : "NO"; // For conditional translation matching

  const schedulingSoftwareDetails = getSchedulingSoftwareDetails(
    userDataQueryResult.data?.getMyRewardsUser.schedulingSoftware
  );

  const localizedDetailText = schedulingSoftwareDetails
    ? formatMessage(
        {
          defaultMessage:
            "Use the {scheduling_software} app {is_client_phone_evv_valid, select, YES {or the client's phone} other {}} to clock in and out {num_target_streak, plural, one {of your next visit} other { {num_target_streak} times in a row}}",
          description: "Goal card > evv streak > app based details text",
        },
        {
          scheduling_software: schedulingSoftwareDetails.appName,
          num_target_streak: targetStreak,
          is_client_phone_evv_valid: isClientPhoneEvvValid,
        }
      )
    : formatMessage(
        {
          defaultMessage:
            "Use the scheduling app {is_client_phone_evv_valid, select, YES {or the client's phone} other {}} to clock in and out {num_target_streak, plural, one {of your next visit} other { {num_target_streak} times in a row}}",
          description: "Goal card > evv streak > no app details text",
        },
        {
          num_target_streak: targetStreak,
          is_client_phone_evv_valid: isClientPhoneEvvValid,
        }
      );

  return (
    <>
      <EVVStreakGoalCardSchedulingSoftwareModal
        isOpen={open}
        onClose={() => setOpen(false)}
      />

      {loading ? (
        <GoalCardSkeleton />
      ) : (
        <BaseGoalCard
          {...commonBaseGoalCardProps}
          progressProps={
            targetStreak > 1
              ? {
                  max: targetStreak,
                  value: currentStreak ?? 0,
                  getUnitsLabel: ({ max, value }) =>
                    formatMessage(
                      {
                        defaultMessage: "{value, number}/{max, number} shifts",
                        description: "Goal card > evv streak > progress units",
                      },
                      { max, value }
                    ),
                }
              : undefined
          }
          detailText={localizedDetailText}
          primaryButtonProps={
            goal.status === GoalStatus.Available
              ? {
                  label: formatMessage({
                    defaultMessage: "Launch app",
                    description:
                      "Goal card > evv streak > launch app button label",
                  }),
                  onClick: () => setOpen(true),
                }
              : undefined
          }
        />
      )}
    </>
  );
}
