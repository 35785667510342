/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  useOfferActionHandler,
  useOfferActionLinkProps,
} from "../../../../shared/reward-offer-action";
import { OfferReward } from "../offer-reward";
import { QuickEarnPill } from "../quick-earn-pill";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import TrophiesIcon from "./icons/trophies.png";

export interface SurveyOfferCardProps {
  offer: RewardsPageDataQuery["listMyRewardOffers"][0];
}

export function SurveyOfferCard({ offer }: SurveyOfferCardProps) {
  const { formatMessage } = useFormatters();
  const ref = useRef<HTMLDivElement>(null);
  const trackingProperties = useMemo(
    () => ({
      offerCardTitle: offer.cardTitle,
      offerCardButtonLabel: offer.cardButtonLabel,
      offerId: offer.id,
      completed: offer.completed,
      graphicKey: offer.cardGraphicKey,
      version: 2,
      surveyId: offer.externalId?.split(":")[1],
    }),
    [offer]
  );

  const handleAction = useOfferActionHandler();
  const offerActionLinkProps = useOfferActionLinkProps();

  const onScreen = useOnScreen(ref, { threshold: 0.5 });
  const track = useTrack();

  useEffect(() => {
    if (onScreen) {
      track("Viewed rewards page survey card", trackingProperties);
    }
  }, [track, onScreen, trackingProperties]);

  return (
    <div
      ref={ref}
      css={(appTheme: AppTheme) =>
        css`
          margin-top: ${appTheme.spacing(1.25)};
          border-radius: 10px;
          overflow: hidden;
        `
      }
      key={offer.id}
      onClick={() => {
        if (offer.completed) {
          return; // can't navigate back to survey after completion
        }

        track("Clicked rewards page survey card", trackingProperties);
        handleAction();
      }}
    >
      <div
        css={(appTheme: AppTheme) =>
          css`
            padding: ${appTheme.spacing(2.5)};
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: ${appTheme.palette.tertiary.main};
          `
        }
      >
        <Typography color="white" variant="subtitle">
          {offer.cardTitle}
        </Typography>
        <img
          src={TrophiesIcon}
          alt={formatMessage({
            defaultMessage: "Trophies icon",
            description: "Rewards page > survey offers > icon alt text",
          })}
          css={css`
            width: 75px;
          `}
        />
      </div>
      <div
        css={(appTheme: AppTheme) => css`
          padding: ${appTheme.spacing(2.5)};
          border: 1.5px solid ${appTheme.palette.divider};
          border-top: none;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          ${appTheme.breakpoints.down(390)} {
            flex-direction: column;
            gap: ${appTheme.spacing(2)};
            align-items: flex-start;
          }
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: ${theme.spacing(1.25)};
          `}
        >
          <OfferReward
            value={{
              type: "auto",
              completions: offer.completions,
            }}
            nextReward={offer.nextReward}
            isCompleted={offer.completed}
          />
          {!offer.completed && <QuickEarnPill />}
        </div>
        {!offer.completed &&
          offer.action.__typename === "RewardOfferExternalLinkAction" && (
            <Button
              label={formatMessage({
                defaultMessage: "Start",
                description: "Rewards page > survey offers > start button",
              })}
              color="secondary"
              width="auto"
              onClick={(e) => {
                e.stopPropagation();

                track(
                  "Clicked rewards page survey card button",
                  trackingProperties
                );
                if (!offerActionLinkProps.linkTo) {
                  handleAction();
                }
              }}
              target="_blank"
              css={(appTheme: AppTheme) => css`
                ${appTheme.breakpoints.down(390)} {
                  width: 100%;
                }
              `}
              {...offerActionLinkProps}
            />
          )}
      </div>
    </div>
  );
}
