import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ShareJobTrackPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShareJobTrackPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'maxPointsEarnedPerCandidate'>
  ), activeReferrals: (
    { __typename?: 'ReferralsProgressV2Response' }
    & { referrals?: Types.Maybe<Array<(
      { __typename?: 'Referral' }
      & Pick<Types.Referral, 'id' | 'candidateDisplayName' | 'pointsAwarded' | 'pointsAwardable'>
      & { stepsV2: Array<(
        { __typename?: 'ReferralStepV2' }
        & Pick<Types.ReferralStepV2, 'completed' | 'pointValue'>
      )> }
    )>> }
  ) }
);


export const ShareJobTrackPageDataDocument = gql`
    query ShareJobTrackPageData {
  getMyRewardsOrganization {
    id
    maxPointsEarnedPerCandidate
  }
  activeReferrals: getMyReferralsProgressV2(statusIn: [ACTIVE], limit: 7) {
    referrals {
      id
      candidateDisplayName
      pointsAwarded
      pointsAwardable
      stepsV2 {
        completed
        pointValue
      }
    }
  }
}
    `;

/**
 * __useShareJobTrackPageDataQuery__
 *
 * To run a query within a React component, call `useShareJobTrackPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareJobTrackPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareJobTrackPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useShareJobTrackPageDataQuery(baseOptions?: Apollo.QueryHookOptions<ShareJobTrackPageDataQuery, ShareJobTrackPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShareJobTrackPageDataQuery, ShareJobTrackPageDataQueryVariables>(ShareJobTrackPageDataDocument, options);
      }
export function useShareJobTrackPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShareJobTrackPageDataQuery, ShareJobTrackPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShareJobTrackPageDataQuery, ShareJobTrackPageDataQueryVariables>(ShareJobTrackPageDataDocument, options);
        }
export type ShareJobTrackPageDataQueryHookResult = ReturnType<typeof useShareJobTrackPageDataQuery>;
export type ShareJobTrackPageDataLazyQueryHookResult = ReturnType<typeof useShareJobTrackPageDataLazyQuery>;
export type ShareJobTrackPageDataQueryResult = Apollo.QueryResult<ShareJobTrackPageDataQuery, ShareJobTrackPageDataQueryVariables>;