import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReauthPageDataQueryVariables = Types.Exact<{
  loginTokenId?: Types.Maybe<Types.Scalars['String']>;
  userId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type ReauthPageDataQuery = (
  { __typename?: 'Query' }
  & { getRewardsUserLoginTokenDetails: (
    { __typename?: 'GetRewardsUserLoginTokenDetailsResponse' }
    & Pick<Types.GetRewardsUserLoginTokenDetailsResponse, 'obscuredPhoneNumber'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'faviconImageUrl' | 'optInPageLogoUrl' | 'rewardsProgramShortName' | 'primaryColor' | 'secondaryColor'>
    )> }
  ) }
);


export const ReauthPageDataDocument = gql`
    query ReauthPageData($loginTokenId: String, $userId: ID) {
  getRewardsUserLoginTokenDetails(loginTokenId: $loginTokenId, userId: $userId) {
    obscuredPhoneNumber
    whiteLabelConfig {
      id
      faviconImageUrl
      optInPageLogoUrl
      rewardsProgramShortName
      primaryColor
      secondaryColor
    }
  }
}
    `;

/**
 * __useReauthPageDataQuery__
 *
 * To run a query within a React component, call `useReauthPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReauthPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReauthPageDataQuery({
 *   variables: {
 *      loginTokenId: // value for 'loginTokenId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReauthPageDataQuery(baseOptions?: Apollo.QueryHookOptions<ReauthPageDataQuery, ReauthPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReauthPageDataQuery, ReauthPageDataQueryVariables>(ReauthPageDataDocument, options);
      }
export function useReauthPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReauthPageDataQuery, ReauthPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReauthPageDataQuery, ReauthPageDataQueryVariables>(ReauthPageDataDocument, options);
        }
export type ReauthPageDataQueryHookResult = ReturnType<typeof useReauthPageDataQuery>;
export type ReauthPageDataLazyQueryHookResult = ReturnType<typeof useReauthPageDataLazyQuery>;
export type ReauthPageDataQueryResult = Apollo.QueryResult<ReauthPageDataQuery, ReauthPageDataQueryVariables>;