/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import calendarLargeUrl from "../../../../shared/goal/graphics/calendar-large.png";
import { BaseGoalTrackPage, BaseGoalTrackPageProps } from "../base-track-page";

export interface WeekendShiftGoalTrackProps {
  trackData: BaseGoalTrackPageProps["trackData"];
  analyticsData: BaseGoalTrackPageProps["analyticsData"];
}

export function WeekendShiftGoalTrackPage({
  trackData,
  analyticsData,
}: WeekendShiftGoalTrackProps) {
  const { formatMessage } = useFormatters();

  return (
    <BaseGoalTrackPage
      trackData={trackData}
      analyticsData={analyticsData}
      title={formatMessage({
        defaultMessage: "Weekend shift",
        description: "Goal track page > weekend shift track title",
      })}
      icon={<img src={calendarLargeUrl} alt="calendar" />}
      content={
        <>
          <Typography
            variant="body"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {formatMessage({
              defaultMessage:
                "A weekend shift is any shift that starts or ends between 12:00 AM on Saturday and 11:59 PM on Sunday. Shift data may take several days to reflect on this page.",
              description:
                "Goal track page > weekend shift track > description one",
            })}
          </Typography>

          <Typography
            variant="body"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(-2)};
            `}
          >
            {formatMessage({
              defaultMessage: "How it works:",
              description:
                "Goal track page > weekend shift track > description two",
            })}
          </Typography>

          <Typography variant="body">
            <ul>
              <li>
                <FormattedMessage
                  description="Goal track page > weekend shift track > description bullet one"
                  defaultMessage={`Check your schedule first to avoid conflicts.`}
                />
              </li>

              <li>
                <FormattedMessage
                  description="Goal track page > weekend shift track > description bullet two"
                  defaultMessage={`Click "I'm Interested" to join the list.`}
                />
              </li>

              <li>
                <FormattedMessage
                  description="Goal track page > weekend shift track > description bullet three"
                  defaultMessage={`If your coordinator calls and you take the shift, you'll earn extra points for completing it.`}
                />
              </li>
            </ul>
          </Typography>
        </>
      }
    />
  );
}
