import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ResyncShiftMilestonesDataMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ResyncShiftMilestonesDataMutation = (
  { __typename?: 'Mutation' }
  & { resyncMyShiftMilestones?: Types.Maybe<(
    { __typename?: 'RewardsUserShiftMilestoneDetails' }
    & Pick<Types.RewardsUserShiftMilestoneDetails, 'id'>
    & { currentMilestoneDetails?: Types.Maybe<(
      { __typename?: 'RewardsUserCurrentShiftMilestoneDetails' }
      & Pick<Types.RewardsUserCurrentShiftMilestoneDetails, 'id' | 'percentageCompleted' | 'hoursBetweenPreviousAndCurrentMilestone' | 'shiftHoursCompletedTowardCurrentMilestone' | 'totalShiftHoursWorked'>
      & { currentMilestone: (
        { __typename?: 'RewardsShiftMilestone' }
        & Pick<Types.RewardsShiftMilestone, 'id' | 'pointValue'>
      ) }
    )>, allOrderedMilestones: Array<(
      { __typename?: 'RewardsShiftMilestone' }
      & Pick<Types.RewardsShiftMilestone, 'id' | 'totalShiftLengthHours' | 'pointValue' | 'completed'>
    )> }
  )> }
);


export const ResyncShiftMilestonesDataDocument = gql`
    mutation ResyncShiftMilestonesData {
  resyncMyShiftMilestones {
    id
    currentMilestoneDetails {
      id
      currentMilestone {
        id
        pointValue
      }
      percentageCompleted
      hoursBetweenPreviousAndCurrentMilestone
      shiftHoursCompletedTowardCurrentMilestone
      totalShiftHoursWorked
    }
    allOrderedMilestones {
      id
      totalShiftLengthHours
      pointValue
      completed
    }
  }
}
    `;
export type ResyncShiftMilestonesDataMutationFn = Apollo.MutationFunction<ResyncShiftMilestonesDataMutation, ResyncShiftMilestonesDataMutationVariables>;

/**
 * __useResyncShiftMilestonesDataMutation__
 *
 * To run a mutation, you first call `useResyncShiftMilestonesDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncShiftMilestonesDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncShiftMilestonesDataMutation, { data, loading, error }] = useResyncShiftMilestonesDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useResyncShiftMilestonesDataMutation(baseOptions?: Apollo.MutationHookOptions<ResyncShiftMilestonesDataMutation, ResyncShiftMilestonesDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResyncShiftMilestonesDataMutation, ResyncShiftMilestonesDataMutationVariables>(ResyncShiftMilestonesDataDocument, options);
      }
export type ResyncShiftMilestonesDataMutationHookResult = ReturnType<typeof useResyncShiftMilestonesDataMutation>;
export type ResyncShiftMilestonesDataMutationResult = Apollo.MutationResult<ResyncShiftMilestonesDataMutation>;
export type ResyncShiftMilestonesDataMutationOptions = Apollo.BaseMutationOptions<ResyncShiftMilestonesDataMutation, ResyncShiftMilestonesDataMutationVariables>;