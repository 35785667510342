/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Card } from "@rewards-web/shared/components/card";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../shared/points-icon";
import { VideoOfferPlayer } from "../../../../shared/video-offer-player";
import { QuickEarnPill } from "../quick-earn-pill";
import { CheckIcon } from "./icons/check-icon";
import { useVideoOfferCardDataQuery } from "./video-card-data.generated";

export interface VideoOfferCardProps {
  videoOfferId: string;
}

export function VideoOfferCard({ videoOfferId }: VideoOfferCardProps) {
  const { formatMessage } = useFormatters();
  const { data, error } = useVideoOfferCardDataQuery({
    variables: { videoOfferId },
    onError: reportError,
  });
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const videoOfferIdFromSearchParams = searchParams.get("videoOfferId");
  const isThumbnailVideo = videoOfferIdFromSearchParams !== videoOfferId;

  useEffect(() => {
    if (data && !data.getRewardVideoOfferById) {
      reportError(
        new Error(`received null video offer for id ${videoOfferId}`)
      );
    }
  }, [data, videoOfferId]);
  const track = useTrack();

  const onScreen = useOnScreen(ref, { threshold: 0.5 });

  useEffect(() => {
    if (onScreen && isThumbnailVideo) {
      track("Viewed rewards page video offer card", { videoOfferId });
    }
  }, [track, onScreen, videoOfferId, isThumbnailVideo]);

  if (error || (data && !data.getRewardVideoOfferById)) {
    return null;
  }

  return (
    <Card
      ref={ref}
      borderRadius={10}
      variant={"flat"}
      css={(appTheme: AppTheme) => css`
        position: relative;
        cursor: pointer;
        padding: ${appTheme.spacing(2.5)};
        border: 1.5px solid ${appTheme.palette.divider};
        &:not(:last-of-type) {
          margin-bottom: ${appTheme.spacing(2)};
        }
      `}
      onClick={() =>
        isThumbnailVideo
          ? navigate({
              pathname: location.pathname,
              search: `?videoOfferId=${videoOfferId}`,
            })
          : undefined
      }
    >
      <VideoOfferPlayer
        videoId={data?.getRewardVideoOfferById?.video.id}
        url={data?.getRewardVideoOfferById?.video.url ?? undefined}
        pointsForVideo={data?.getRewardVideoOfferById?.pointValue}
        videoTitle={data?.getRewardVideoOfferById?.video.title}
        alreadyWatched={data?.getRewardVideoOfferById?.watched}
        videoOfferId={videoOfferId}
        firstName={data?.getMyRewardsUser.firstName || ""}
        isThumbnail={isThumbnailVideo}
        isPlayingFromThumbnail
      />
      <Typography
        variant="subtitle"
        color="textPrimary"
        css={(appTheme: AppTheme) => css`
          margin-top: ${appTheme.spacing(1.25)};
        `}
      >
        {data ? (
          data.getRewardVideoOfferById?.video.title
        ) : (
          <Skeleton width="50%" animated />
        )}
      </Typography>
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          align-items: center;
          margin-top: ${appTheme.spacing(1.25)};
          gap: ${appTheme.spacing(1.25)};
        `}
      >
        {data?.getRewardVideoOfferById ? (
          <>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              {data.getRewardVideoOfferById.watched ? (
                <CheckIcon />
              ) : (
                <PointsIcon />
              )}
              <Typography
                variant="footnote"
                css={(appTheme: AppTheme) =>
                  css`
                    margin-left: ${appTheme.spacing(0.75)};
                  `
                }
                color="textPrimary"
              >
                {data.getRewardVideoOfferById.watched
                  ? formatMessage(
                      {
                        defaultMessage: "{points, number} points earned",
                        description:
                          "Rewards page > video offer card > points earned",
                      },
                      { points: data.getRewardVideoOfferById.pointValue }
                    )
                  : formatMessage(
                      {
                        defaultMessage: "{points, number} points",
                        description: "Rewards page > video offer card > points",
                      },
                      { points: data.getRewardVideoOfferById.pointValue }
                    )}
              </Typography>
            </div>
            {!data.getRewardVideoOfferById.watched && <QuickEarnPill />}
          </>
        ) : (
          <Skeleton width="50%" animated />
        )}
      </div>
    </Card>
  );
}
