/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Outlet } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePointBalance } from "../../../shared/modules/point-balance";
import { SubPageNavigationTabs } from "../../../shared/navigation-tabs";
import { PointsSummaryHeader } from "./header";
import { PointsProgress } from "./points-progress";
import { useRewardsDataQuery } from "./query.generated";

export function PointsSummaryPage(): JSX.Element {
  const { formatMessage } = useFormatters();

  const pointBalance = usePointBalance({ refreshOnMount: true });
  const pageDataQuery = useRewardsDataQuery();
  const giftCardsRedemptionOptionsEnabled = useFeatureFlag(
    "rewards-app-gift-card-redemptions-tab-temp"
  );
  if (pointBalance.error || pageDataQuery.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Points summary page > data load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (
    (pageDataQuery.loading && !pageDataQuery.data) ||
    pointBalance.computedBalance === undefined
  ) {
    return <PageLoadingState />;
  }

  const orgData = pageDataQuery.data!.getMyRewardsOrganization;
  const userData = pageDataQuery.data!.getMyRewardsUser;
  // We support only one redemption method for now. So we can just get the first one.
  const redemptionMethod = orgData.allowedRedemptionMethods[0];

  // header only shows for payroll redemptions
  const showHeader = (() => {
    if (
      redemptionMethod === RedemptionMethod.GenericGiftCardLink &&
      giftCardsRedemptionOptionsEnabled
    ) {
      return false;
    }

    return true;
  })();

  return (
    <div>
      {showHeader && <PointsSummaryHeader />}
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          flex-direction: column;
          background-color: ${appTheme.palette.background.paper};
          & > :last-child {
            flex-grow: 1;
          }
          min-height: 100vh;
        `}
      >
        <PointsProgress
          email={userData.email}
          pointsAvailableToRedeem={pointBalance.computedBalance}
          minimumPointsToRedeem={orgData.minimumPointsNeededToRedeem}
          pointsPerDollar={orgData.pointsPerDollar}
          redemptionMethod={redemptionMethod}
          phoneNumber={userData.phoneNumber}
        />
        <div
          css={(appTheme: AppTheme) => css`
            background-color: ${appTheme.palette.primary.main};
          `}
        >
          <div
            css={(appTheme: AppTheme) => css`
              max-width: ${appTheme.maxContentWidth}px;
              background-color: ${appTheme.palette.primary.main};
              margin: 0 auto;
              ${appTheme.breakpoints.up("lg")} {
                padding-top: ${appTheme.spacing(3)};
              }
            `}
          >
            <SubPageNavigationTabs
              tabs={[
                {
                  label: formatMessage({
                    description:
                      "Redemption page > sub page tabs > redeem tab label",
                    defaultMessage: "Redeem",
                  }),
                  path: "/my-rewards/redeem",
                },
                {
                  label: formatMessage({
                    description: "Redemption page > past redemptions tab label",
                    defaultMessage: "Past redemptions",
                  }),
                  path: "/my-rewards/past-redemptions",
                },
              ]}
              hostPageName="Redemption"
            />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}
