import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";

import {
  ListShareableJobPostingsFilters,
  SharePlatform,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import {
  CreateJobShareLinkDocument,
  CreateJobShareLinkMutation,
} from "./create-job-share-link.generated";
import {
  MarkJobSharedDocument,
  MarkJobSharedMutation,
} from "./mark-job-shared.generated";

/**
 * Requests a share link on mount, and yields the job share link when it's available.
 *
 * When a share link has been used, `markLinkShared` should be called with the platform
 * that was used to share the link.
 *
 * At this point, a new share link will be generated.
 */
export function useJobShareLink(
  open: boolean,
  jobPostingId: string,
  analyticsProps: {
    promotionId: string | null;
    numJobsMatchingFilters?: number | undefined;
    selectedFilters?: ListShareableJobPostingsFilters;
  }
): [
  { data: string | null; error: any | null },
  { markJobLinkShared: (platform: SharePlatform) => void }
] {
  const track = useTrack();
  const [jobShare, setJobShare] = useState<{
    jobShareUrl: string;
    jobShareId: string;
  } | null>(null);
  const [error, setError] = useState<any | null>(null);

  const apolloClient = useApolloClient();

  const createNewShareLink = useCallback(
    async (_jobPostingId) => {
      setJobShare(null);

      try {
        const res = await apolloClient.mutate<CreateJobShareLinkMutation>({
          mutation: CreateJobShareLinkDocument,
          variables: {
            jobPostingId: _jobPostingId,
          },
          fetchPolicy: "no-cache",
        });

        setJobShare(res.data!.createJobShareLink!);
      } catch (error) {
        setError(error);
        reportError(error);
      }
    },
    [apolloClient]
  );

  useEffect(() => {
    if (open) {
      createNewShareLink(jobPostingId);
    }
  }, [open, createNewShareLink, jobPostingId]);

  return [
    {
      data: jobShare?.jobShareUrl ?? null,
      error,
    },
    {
      markJobLinkShared: async (platform: SharePlatform) => {
        if (!jobShare) {
          return;
        }

        const { jobShareId } = jobShare;

        createNewShareLink(jobPostingId);

        try {
          await apolloClient.mutate<MarkJobSharedMutation>({
            mutation: MarkJobSharedDocument,
            variables: {
              jobShareId,
              platform,
            },
            fetchPolicy: "no-cache",
          });
        } catch (error) {
          reportError(error);
        }

        track("Shared job", {
          jobPostingId,
          jobShareId,
          platform,
          promotionId: analyticsProps.promotionId,
          numJobsMatchingFilters: analyticsProps.numJobsMatchingFilters,
          selectedTitleFilters: analyticsProps.selectedFilters
            ? analyticsProps.selectedFilters.titleIn ?? []
            : undefined,
          selectedGeographyFilters: analyticsProps.selectedFilters
            ? analyticsProps.selectedFilters.geographyIn ?? []
            : undefined,
        });
      },
    },
  ];
}
