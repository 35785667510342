/** @jsxImportSource @emotion/react */
import {
  GoalStatus,
  SurveyGoalDetails,
  SurveyGoalTracker,
  SurveyGoalTrackerSurveyType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { GoalCardProps } from "..";
import { BaseGoalCard } from "../base-goal-card";
import { getGoalUnlocksInDays, useCommonBaseGoalCardProps } from "../lib";
import { useSurveyGoalCardDataQuery } from "./survey-goal-card-data.generated";

export function SurveyGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage } = useFormatters();
  const snackbar = useSnackbar();
  const query = useSurveyGoalCardDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first", // avoid calling this for every card
  });

  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/survey",
    lockedDetailModalTextOverride: (unlockCriteria) => {
      if (unlockCriteria.__typename === "TimeBasedlUnlockCriteria") {
        const { unlocksAt } = unlockCriteria;

        return formatMessage(
          {
            description: "Goal card > survey > locked details modal",
            defaultMessage:
              "This survey is currently not active. Come back in {num_days, number} {num_days, plural, one {day} other {days}} to share your experience with {agency_name} and earn points for responding.",
          },
          {
            agency_name: query.data?.getMyRewardsOrganization.shortName ?? "",
            num_days: getGoalUnlocksInDays(unlocksAt),
          }
        );
      }

      // we don't expect survey goals to have a depending goal unlock criteria,
      // so fall back to default in that case
    },
  });

  const surveyType = (goal.goalTracker as SurveyGoalTracker)
    .surveyType as SurveyGoalTrackerSurveyType;

  const detailText = (() => {
    switch (surveyType) {
      case SurveyGoalTrackerSurveyType.PulseCheck: {
        return formatMessage({
          defaultMessage: "Share your thoughts in this 1 minute survey",
          description: "Goal card > survey description > pulse check",
        });
      }
      default:
        return null;
    }
  })();

  const buttonLabel = formatMessage({
    defaultMessage: "Answer",
    description: "Goal card > survey > button label",
  });

  const surveyUrl =
    (goal.goalDetails as SurveyGoalDetails)?.externalSurveyUrl ?? null;

  return (
    <BaseGoalCard
      {...commonBaseGoalCardProps}
      detailText={detailText ?? undefined}
      primaryButtonProps={
        goal.status === GoalStatus.Available
          ? surveyUrl
            ? {
                label: buttonLabel,
                linkTo: surveyUrl,
                externalLink: true,
              }
            : {
                label: buttonLabel,
                onClick: () => {
                  // This shouldn't happen, but we want to be notified if it does
                  // without breaking the home page.
                  reportError(
                    new Error(
                      `Missing external survey URL for survey goal ${goal.id}`
                    )
                  );
                  return snackbar.show({
                    message: formatMessage({
                      defaultMessage:
                        "Survey link not available. Please try again later.",
                      description: "Goal card > survey link not available",
                    }),
                    severity: "error",
                  });
                },
              }
          : undefined
      }
    />
  );
}
