import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PointBalanceDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PointBalanceDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'pointsAvailableToRedeem'>
  ) }
);


export const PointBalanceDataDocument = gql`
    query PointBalanceData {
  getMyRewardsUser {
    id
    pointsAvailableToRedeem
  }
}
    `;

/**
 * __usePointBalanceDataQuery__
 *
 * To run a query within a React component, call `usePointBalanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointBalanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointBalanceDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePointBalanceDataQuery(baseOptions?: Apollo.QueryHookOptions<PointBalanceDataQuery, PointBalanceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointBalanceDataQuery, PointBalanceDataQueryVariables>(PointBalanceDataDocument, options);
      }
export function usePointBalanceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointBalanceDataQuery, PointBalanceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointBalanceDataQuery, PointBalanceDataQueryVariables>(PointBalanceDataDocument, options);
        }
export type PointBalanceDataQueryHookResult = ReturnType<typeof usePointBalanceDataQuery>;
export type PointBalanceDataLazyQueryHookResult = ReturnType<typeof usePointBalanceDataLazyQuery>;
export type PointBalanceDataQueryResult = Apollo.QueryResult<PointBalanceDataQuery, PointBalanceDataQueryVariables>;