/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { Outlet, useLocation } from "react-router-dom";

import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { SubPageNavigationTabs } from "../../../shared/navigation-tabs";
import { SubPageHeader } from "../../../shared/sub-page-header";

export function JobsPage(): JSX.Element {
  const { formatMessage } = useFormatters();
  const { state } = useLocation();

  const mobileOrTablet = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("md")
  );

  return (
    <>
      <SubPageHeader
        pageName={formatMessage({
          description: "Jobs page > page main heading smaller screen",
          defaultMessage: "Jobs",
        })}
        analyticsPageName="Jobs"
        hideWhiteBanner={!mobileOrTablet}
        backTo={(() => {
          switch (state?.from) {
            case "draw":
              return "draw";
            case "rewards":
              return "rewards";
            default:
              return undefined;
          }
        })()}
        fixedBackNavigation
      />

      <div
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.primary.main};

          // ensure the user can't scroll up on apple devices
          // and have a strange white background
          margin-top: -300px;
          padding-top: 300px;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            max-width: ${theme.maxContentWidth}px;
            margin: 0 auto;
            ${theme.breakpoints.up("lg")} {
              padding-top: ${theme.spacing(3)};
            }
          `}
        >
          <SubPageNavigationTabs
            tabs={[
              {
                label: formatMessage({
                  description: "Jobs page > Jobs tab label",
                  defaultMessage: "Jobs",
                }),
                path: "/jobs",
                state,
              },
              {
                label: formatMessage({
                  description: "Jobs page > My referrals tab label",
                  defaultMessage: "My Referrals",
                }),
                path: "/jobs/my-referrals",
                state,
              },
            ]}
            hostPageName="Jobs"
          />
        </div>
      </div>

      <Outlet />
    </>
  );
}
