import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GenerateMyNevvonLoginLinkMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateMyNevvonLoginLinkMutation = (
  { __typename?: 'Mutation' }
  & { generateMyNevvonLoginLink?: Types.Maybe<(
    { __typename?: 'GenerateMyNevvonLoginLinkResponse' }
    & Pick<Types.GenerateMyNevvonLoginLinkResponse, 'loginLink'>
  )> }
);


export const GenerateMyNevvonLoginLinkDocument = gql`
    mutation GenerateMyNevvonLoginLink {
  generateMyNevvonLoginLink {
    loginLink
  }
}
    `;
export type GenerateMyNevvonLoginLinkMutationFn = Apollo.MutationFunction<GenerateMyNevvonLoginLinkMutation, GenerateMyNevvonLoginLinkMutationVariables>;

/**
 * __useGenerateMyNevvonLoginLinkMutation__
 *
 * To run a mutation, you first call `useGenerateMyNevvonLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMyNevvonLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMyNevvonLoginLinkMutation, { data, loading, error }] = useGenerateMyNevvonLoginLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateMyNevvonLoginLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMyNevvonLoginLinkMutation, GenerateMyNevvonLoginLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateMyNevvonLoginLinkMutation, GenerateMyNevvonLoginLinkMutationVariables>(GenerateMyNevvonLoginLinkDocument, options);
      }
export type GenerateMyNevvonLoginLinkMutationHookResult = ReturnType<typeof useGenerateMyNevvonLoginLinkMutation>;
export type GenerateMyNevvonLoginLinkMutationResult = Apollo.MutationResult<GenerateMyNevvonLoginLinkMutation>;
export type GenerateMyNevvonLoginLinkMutationOptions = Apollo.BaseMutationOptions<GenerateMyNevvonLoginLinkMutation, GenerateMyNevvonLoginLinkMutationVariables>;