/** @jsxImportSource @emotion/react */
import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { NotificationTimeline } from "./notification-timeline";
import { useGetMyRewardNotificationsQuery } from "./query.generated";

export function NotificationsPage() {
  const { formatMessage } = useFormatters();
  const { data, loading, error } = useGetMyRewardNotificationsQuery({
    onError: reportError,
  });

  const renderContent = () => {
    if (error) {
      return (
        <Alert
          severity="error"
          message={formatMessage({
            description: "Unexpected error occurred",
            defaultMessage: "Something went wrong. Please try again later.",
          })}
        />
      );
    }

    if (loading && !data) {
      return <PageLoadingState />;
    }

    return (
      <NotificationTimeline notifications={data!.listMyRewardNotifications!} />
    );
  };

  return (
    <>
      <SubPageHeader
        pageName={formatMessage({
          defaultMessage: "Notifications",
          description: "Notifications page > page name",
        })}
        analyticsPageName="Notifications"
      />
      <PageContentContainer>
        <ScrollToTopOnMount />
        <div>{renderContent()}</div>
      </PageContentContainer>
    </>
  );
}
