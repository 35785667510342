/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PlayIcon } from "./icons/play-icon";

export function PlayButton() {
  return (
    <div
      css={css`
        display: flex;
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);
        align-items: center;
        justify-content: center;
        padding-left: 4px;
        pointer-events: none;
      `}
    >
      <PlayIcon />
    </div>
  );
}
