import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateReauthChallengeMutationVariables = Types.Exact<{
  loginTokenId?: Types.Maybe<Types.Scalars['String']>;
  userId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type CreateReauthChallengeMutation = (
  { __typename?: 'Mutation' }
  & { createRewardsUserTokenReauthChallenge: (
    { __typename?: 'CreateRewardsUserSMSLoginChallengeResponse' }
    & Pick<Types.CreateRewardsUserSmsLoginChallengeResponse, 'challengeId'>
  ) }
);


export const CreateReauthChallengeDocument = gql`
    mutation CreateReauthChallenge($loginTokenId: String, $userId: ID) {
  createRewardsUserTokenReauthChallenge(
    loginTokenId: $loginTokenId
    userId: $userId
  ) {
    challengeId
  }
}
    `;
export type CreateReauthChallengeMutationFn = Apollo.MutationFunction<CreateReauthChallengeMutation, CreateReauthChallengeMutationVariables>;

/**
 * __useCreateReauthChallengeMutation__
 *
 * To run a mutation, you first call `useCreateReauthChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReauthChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReauthChallengeMutation, { data, loading, error }] = useCreateReauthChallengeMutation({
 *   variables: {
 *      loginTokenId: // value for 'loginTokenId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateReauthChallengeMutation(baseOptions?: Apollo.MutationHookOptions<CreateReauthChallengeMutation, CreateReauthChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReauthChallengeMutation, CreateReauthChallengeMutationVariables>(CreateReauthChallengeDocument, options);
      }
export type CreateReauthChallengeMutationHookResult = ReturnType<typeof useCreateReauthChallengeMutation>;
export type CreateReauthChallengeMutationResult = Apollo.MutationResult<CreateReauthChallengeMutation>;
export type CreateReauthChallengeMutationOptions = Apollo.BaseMutationOptions<CreateReauthChallengeMutation, CreateReauthChallengeMutationVariables>;