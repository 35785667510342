import * as React from "react";

export function BackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.293 9.581l.108.104L9.289.427a.9.9 0 011.299 1.246l.108.104-.108-.104-7.23 7.531-.244.254H22.53a.85.85 0 010 1.7H3.114l.243.254 7.23 7.532.109-.104-.108.104a.9.9 0 01-.026 1.273h0a.9.9 0 01-1.273-.026l-.108.103.108-.104-8.888-9.259a.9.9 0 010-1.246l-.108-.104z"
        fill={props.color}
        stroke="#3F879A"
        strokeWidth={0.3}
      />
    </svg>
  );
}
