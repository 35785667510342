/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { darken } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { stripNonNumericCharacters } from "@rewards-web/shared/lib/strip-non-numeric-characters";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PhoneIcon } from "../../../../shared/phone-icon";

interface PhoneNumberFormValues {
  phoneNumber: string;
}

interface LoginPhoneNumberFormProps {
  onSubmit(phoneNumber: string): Promise<void>;
}

export function LoginPhoneNumberForm({
  onSubmit,
}: LoginPhoneNumberFormProps): JSX.Element {
  const { formatMessage } = useFormatters();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<PhoneNumberFormValues>({
    mode: "onChange",
    defaultValues: { phoneNumber: "" },
  });

  const inputLabel = formatMessage({
    description: "Login page > phone number login input label",
    defaultMessage: "Personal phone number",
  });

  return (
    <div>
      <Form
        onSubmit={handleSubmit((values) => onSubmit(values.phoneNumber))}
        submitting={isSubmitting}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3.5)};
          ${theme.breakpoints.up("md")} {
            margin-bottom: ${theme.spacing(5)};
          }
        `}
      >
        <TextField
          type="tel"
          label={inputLabel}
          hideLabel
          startAdornment={<PhoneIcon />}
          placeholder={inputLabel}
          {...register("phoneNumber", {
            required: true,
            validate: (value) => {
              if (!isPhoneNumber(stripNonNumericCharacters(value))) {
                return formatMessage({
                  description: "Login page > invalid phone number error",
                  defaultMessage: "Please enter a valid phone number",
                });
              }
            },
          })}
        />
        <Button
          type="submit"
          label={formatMessage({
            description: "Login page > submit phone number button",
            defaultMessage: "Verify",
          })}
          color="primary"
          size="large"
          disabled={!isValid}
          loading={isSubmitting}
          css={(theme: AppTheme) => css`
            ${theme.breakpoints.up("md")} {
              margin-top: ${theme.spacing(3)};
            }
            &:hover {
              ${darken(theme.palette.primary.main, 0.1)};
            }
            &.Mui-disabled {
              opacity: 1;
              background-color: ${theme.palette.grey[200]};
            }
          `}
        />
      </Form>
    </div>
  );
}
