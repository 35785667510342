import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobShareRewardStructureQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type JobShareRewardStructureQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUserAvailableGoals: Array<(
    { __typename?: 'Goal' }
    & Pick<Types.Goal, 'id' | 'type' | 'numPoints'>
  )>, getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id'>
    & { referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & Pick<Types.ReferralRewardStructure, 'id'>
      & { jobShareStructure: (
        { __typename?: 'ReferralRewardStructureJobShareItem' }
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets' | 'numWinnersSelectedPerMonth' | 'prizeText'>
        ) }
      ) }
    ) }
  ) }
);


export const JobShareRewardStructureDocument = gql`
    query JobShareRewardStructure {
  getMyRewardsUserAvailableGoals {
    id
    type
    numPoints
  }
  getMyRewardsOrganization {
    id
    referralRewardStructure {
      id
      jobShareStructure {
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
          ... on ReferralRewardStructureDrawTicketReward {
            numTickets
          }
          ... on ReferralRewardStructureManualMonthlyRaffleReward {
            numTickets
            numWinnersSelectedPerMonth
            prizeText
          }
        }
      }
    }
  }
}
    `;

/**
 * __useJobShareRewardStructureQuery__
 *
 * To run a query within a React component, call `useJobShareRewardStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobShareRewardStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobShareRewardStructureQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobShareRewardStructureQuery(baseOptions?: Apollo.QueryHookOptions<JobShareRewardStructureQuery, JobShareRewardStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobShareRewardStructureQuery, JobShareRewardStructureQueryVariables>(JobShareRewardStructureDocument, options);
      }
export function useJobShareRewardStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobShareRewardStructureQuery, JobShareRewardStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobShareRewardStructureQuery, JobShareRewardStructureQueryVariables>(JobShareRewardStructureDocument, options);
        }
export type JobShareRewardStructureQueryHookResult = ReturnType<typeof useJobShareRewardStructureQuery>;
export type JobShareRewardStructureLazyQueryHookResult = ReturnType<typeof useJobShareRewardStructureLazyQuery>;
export type JobShareRewardStructureQueryResult = Apollo.QueryResult<JobShareRewardStructureQuery, JobShareRewardStructureQueryVariables>;