/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PunchCardsCompletedAnnouncement } from "../types";
import { useAcknowledgePunchCardCompletionsMutation } from "./acknowledge-punch-card-completions.generated";
import {
  getPunchCardsToAcknowledge,
  sumUnacknowledgedPunchCardReward,
} from "./lib";
import punchCardSkeletonImageUrl from "./punch-card-skeleton.png";

export interface PunchCardsCompletedModalProps {
  punchCards: PunchCardsCompletedAnnouncement["unacknowledgedPunchCards"];
  onOpened(): void;
  onRemoveAnnouncement(): void;
}

export function PunchCardsCompletedModal({
  punchCards,
  onOpened,
  onRemoveAnnouncement,
}: PunchCardsCompletedModalProps) {
  const { formatMessage } = useFormatters();
  const [
    acknowledgePunchCardCompletionsMutation,
  ] = useAcknowledgePunchCardCompletionsMutation();

  useEffect(() => {
    onOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalPointsEarned = sumUnacknowledgedPunchCardReward(
    punchCards,
    (completion) => completion.pointValue ?? 0
  );

  const totalDrawTicketsEarned = sumUnacknowledgedPunchCardReward(
    punchCards,
    (completion) => completion.numDrawTickets ?? 0
  );

  const punchCardsToAcknowledge = getPunchCardsToAcknowledge(punchCards);
  const numCardCompletionsToAcknowledge = punchCardsToAcknowledge.reduce(
    (prev, punchCardToAcknowledge) =>
      prev + punchCardToAcknowledge.completionIds.length,
    0
  );

  const handleDismiss = async () => {
    onRemoveAnnouncement();

    try {
      await acknowledgePunchCardCompletionsMutation({
        variables: {
          data: punchCardsToAcknowledge,
        },
      });
    } catch (error) {
      reportError(error);
    }
  };

  return (
    <ResponsiveDialog backgroundColor="white" open mobileType="dialog">
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(3.75)} ${theme.spacing(2.5)}
            ${theme.spacing(2)} ${theme.spacing(2.5)};
        `}
      >
        <img
          src={punchCardSkeletonImageUrl}
          alt="Punch card"
          css={css`
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 260px;
          `}
        />
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <FormattedMessage
            defaultMessage="Great job!"
            description="Punch cards completed modal > title"
          />
        </Typography>
        <Typography
          variant="body"
          align="center"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
            color: ${theme.palette.grey[800]};
          `}
        >
          {totalPointsEarned > 0 ? (
            <FormattedMessage
              defaultMessage="You've completed {num_punch_cards, plural, one {a punch card} other {{num_punch_cards, number} punch cards}} and earned {total_points_earned, number} points!"
              description="Punch cards completed modal > body"
              values={{
                num_punch_cards: numCardCompletionsToAcknowledge,
                total_points_earned: totalPointsEarned,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="You've completed {num_punch_cards, plural, one {a punch card} other {{num_punch_cards, number} punch cards}} and earned {total_num_draw_tickets_earned, number} draw tickets!"
              description="Punch cards completed modal > body"
              values={{
                num_punch_cards: numCardCompletionsToAcknowledge,
                total_num_draw_tickets_earned: totalDrawTicketsEarned,
              }}
            />
          )}
        </Typography>
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Got it!",
            description: "Punch cards completed modal > button label",
          })}
          onClick={handleDismiss}
        />
      </div>
    </ResponsiveDialog>
  );
}
