import React from "react";
import ReactDOM from "react-dom";

import "@rewards-web/shared/style/global-styles";

import { App } from "./app";
import { reportWebVitals } from "./report-web-vitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (navigator?.serviceWorker?.getRegistrations) {
  // remove any old service works users may have had,
  // because it can cause a really old version of the app to stay cached
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
