/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../points-icon";

interface MilestoneRewardsPointsProps {
  points: number;
  pointsPerDollar: number;
}

export function MilestoneRewardPoints({
  points,
  pointsPerDollar,
}: MilestoneRewardsPointsProps) {
  const { formatMessage } = useFormatters();
  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        align-items: center;
        gap: ${appTheme.spacing(0.75)};
      `}
    >
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: ${appTheme.typography.footnote.lineHeight};
        `}
      >
        <PointsIcon
          css={css`
            width: 16px;
            flex-shrink: 0;
          `}
        />
      </div>
      <Typography variant="footnote">
        {formatMessage(
          {
            defaultMessage: "{points, number} points {dollars}",
            description: "Milestones rewards > points with dollars",
          },
          {
            points,
            dollars: formatDollars(points / pointsPerDollar),
          }
        )}
      </Typography>
    </div>
  );
}
