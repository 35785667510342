import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type OptInOrganizationFragmentFragment = (
  { __typename?: 'RewardsOrganization' }
  & Pick<Types.RewardsOrganization, 'id' | 'logoImageUrl' | 'optInPageDisclaimer' | 'signUpContactInfoPreference'>
  & { whiteLabelConfig?: Types.Maybe<(
    { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
    & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName' | 'faviconImageUrl' | 'primaryColor' | 'secondaryColor' | 'optInPageLogoUrl'>
  )> }
);

export const OptInOrganizationFragmentFragmentDoc = gql`
    fragment OptInOrganizationFragment on RewardsOrganization {
  id
  logoImageUrl
  optInPageDisclaimer
  whiteLabelConfig {
    id
    rewardsProgramShortName
    faviconImageUrl
    primaryColor
    secondaryColor
    optInPageLogoUrl
  }
  signUpContactInfoPreference
}
    `;