/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

export interface ModalProps {
  children?: ReactNode;
  title: string;
  details: ReactNode;
  open: boolean;
  onClose(): void;
  maxWidth?: string;
}

export function Modal({
  children,
  title,
  details,
  open,
  onClose,
  maxWidth,
}: ModalProps): JSX.Element {
  return (
    <ResponsiveDialog open={open} onClose={onClose} maxWidth={maxWidth}>
      <div
        css={(theme: AppTheme) =>
          css`
            margin: ${theme.spacing(3)};
          `
        }
      >
        <Typography
          variant="h3"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(2)};
            `
          }
        >
          {title}
        </Typography>

        <Typography
          component="div"
          variant="body"
          color="textSecondary"
          css={(theme: AppTheme) =>
            css`
              padding-bottom: ${theme.spacing(1)};
            `
          }
        >
          {details}
        </Typography>
      </div>

      <Divider />

      <div
        css={(theme: AppTheme) =>
          css`
            margin: ${theme.spacing(3)};
          `
        }
      >
        {children}
      </div>
    </ResponsiveDialog>
  );
}
