/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedMessage,
  FormattedNumber,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import drawTicketIconUrl from "./draw-ticket-icon.png";

export interface DrawTicketCountProps {
  /**
   * If `undefined`, shows a loading skeleton
   */
  ticketCount: number | undefined;
}

/**
 * Displays the user's draw ticket count, for use on a draw page
 */
export function DrawTicketCount({ ticketCount }: DrawTicketCountProps) {
  const { formatMessage } = useFormatters();

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: ${theme.spacing(3)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          gap: ${theme.spacing(2)};
          align-items: center;
          justify-content: center;
          padding: ${theme.spacing(1)};
        `}
      >
        <img
          src={drawTicketIconUrl}
          alt={formatMessage({
            description: "Draw page > draw active state > ticket image alt",
            defaultMessage: "Ticket graphic",
          })}
        />
        <Typography variant="h1" component="span" textAlign="center">
          {typeof ticketCount === "number" ? (
            <FormattedNumber value={ticketCount} />
          ) : (
            <Skeleton width="36px" />
          )}
        </Typography>
      </div>
      <Typography variant="body" textAlign="center">
        <FormattedMessage
          defaultMessage="Current tickets"
          description="Draw page > draw active state > current tickets label"
        />
      </Typography>
    </div>
  );
}
