import { SVGProps } from "react";

export function PinkCircleRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={89}
      height={127}
      fill="none"
      {...props}
    >
      <rect width={127} height={127} fill="#EA2276" rx={63.5} />
    </svg>
  );
}
