/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { last, sortBy } from "lodash";
import { ReactNode, useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import coinPointsUrl from "../../../../shared/coin-points.png";
import { PowerHoursLevelFinishedAnnouncement } from "../types";
import { useAcknowledgePowerHoursLevelFinishedMutation } from "./acknowledge-power-hours-level-finished.generated";
import handsIconUrl from "./hands-icon.png";

export interface PowerHoursLevelFinishedModalProps {
  powerHoursOffer: PowerHoursLevelFinishedAnnouncement["powerHoursOffer"];
  onRemoveAnnouncement(): void;
  onOpened(): void;
}

export function PowerHoursLevelFinishedModal({
  powerHoursOffer,
  onRemoveAnnouncement,
  onOpened,
}: PowerHoursLevelFinishedModalProps) {
  const { formatMessage } = useFormatters();

  useEffect(() => {
    onOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this is defensive, since levels should already be ordered
  const orderedLevels = sortBy(
    powerHoursOffer.levels,
    (level) => level.levelNumber
  );

  const lastFinishedLevel = orderedLevels
    .slice()
    .reverse()
    .find((level) => level.finished)!;
  const currentLevel = orderedLevels.find((level) => !level.finished);
  const finalLevel = last(orderedLevels)!;

  const [
    acknowledgePowerHoursLevelFinishedMutation,
  ] = useAcknowledgePowerHoursLevelFinishedMutation();

  const handleDismiss = async () => {
    onRemoveAnnouncement();

    try {
      await acknowledgePowerHoursLevelFinishedMutation({
        variables: {
          powerHoursOfferId: powerHoursOffer.id,
          levelNumber: lastFinishedLevel.levelNumber,
        },
      });
    } catch (error) {
      reportError(error);
    }
  };

  const renderPointsText = (nodes: ReactNode[]) => (
    <Typography
      variant="subtitle"
      color="text.primary"
      fontWeight={600}
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing(1)};
        margin: ${theme.spacing(1)} 0;
      `}
    >
      <img
        src={coinPointsUrl}
        css={css`
          width: 18px;
          height: 18px;
        `}
        alt={formatMessage({
          defaultMessage: "Star",
          description: "Power hours level finished modal > star icon alt",
        })}
      />{" "}
      {nodes}
    </Typography>
  );

  return (
    <ResponsiveDialog backgroundColor="white" open mobileType="dialog">
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(3.75)} ${theme.spacing(2.5)}
            ${theme.spacing(2)} ${theme.spacing(2.5)};
        `}
      >
        <img
          src={handsIconUrl}
          alt="Hands"
          css={(theme: AppTheme) => css`
            display: block;
            margin: 0 auto;
            max-width: 100%;
            width: 85px;
            height: 85px;
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <div
          css={(theme: AppTheme) => css`
            background-color: ${theme.palette.tertiary.main};
            border-radius: 1000px;
            width: max-content;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 ${theme.spacing(0.75)};
            gap: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <FontAwesomeIcon icon={faCheck} color="white" />
          <Typography variant="caption" textTransform="uppercase" color="white">
            {currentLevel ? (
              <FormattedMessage
                defaultMessage="Level {level_number, number} complete"
                description="Power hours level finished modal > level complete"
                values={{
                  level_number: lastFinishedLevel.levelNumber,
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="All levels completed!"
                description="Power hours level finished modal > all level completed"
              />
            )}
          </Typography>
        </div>
        <Typography
          variant="h2"
          align="center"
          color="text.primary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {currentLevel ? (
            <FormattedMessage
              defaultMessage="Keep it up!"
              description="Power hours level finished modal > has levels remaining > title"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Well done!"
              description="Power hours level finished modal > completed all levels > title"
            />
          )}
        </Typography>
        <Typography
          variant="body"
          align="center"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
            color: ${theme.palette.grey[800]};
          `}
        >
          {currentLevel ? (
            <FormattedMessage
              defaultMessage="You're now on Level {current_level, number}. Keep going to earn up to {points_available_to_earn, number} points! So far you've earned: <points_text>{points_earned_so_far, number} points</points_text>"
              description="Power hours level finished modal > has levels remaining > body"
              values={{
                current_level: currentLevel.levelNumber,
                points_available_to_earn: finalLevel.pointValue,
                points_earned_so_far: lastFinishedLevel.pointValue,
                points_text: renderPointsText,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="You've completed all levels. Points will be rewarded at the end of this challenge: <points_text>{points_earned_so_far, number} points</points_text>"
              description="Power hours level finished modal > has levels remaining > body"
              values={{
                points_earned_so_far: lastFinishedLevel.pointValue,
                points_text: renderPointsText,
              }}
            />
          )}
        </Typography>
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Got it!",
            description: "Power hours level finished modal > button",
          })}
          onClick={handleDismiss}
        />
      </div>
    </ResponsiveDialog>
  );
}
