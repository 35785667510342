import * as React from "react";

function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.484 11.347l.177.176.176-.176L16.241.943 17.306 2 5.66 13.646.424 8.41 1.48 7.353l4.003 3.994z"
        fill="#000"
        fillOpacity={0.87}
        stroke="#fff"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default CheckIcon;
