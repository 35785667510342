/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faCalendar,
  faCircleQuestion,
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alpha } from "@material-ui/core";
import { forwardRef, ReactNode } from "react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardOffer } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { OfferReward } from "../offer-reward";
import trophyImageUrl from "./trophy.png";

export interface TimeBasedOfferCardProps {
  offer: Pick<RewardOffer, "nextReward">;
  showSkeleton: boolean;
  hideOfferRewardIcon: boolean;
  titleText: string | JSX.Element;
  rewardText: string;
  completed: boolean;

  /**
   * If `null`, it shows "times up"
   */
  numDaysLeft: number | null;
  showTrophyIfTimesUpAndCompleted?: boolean;
  timeIconType: "calendar" | "clock";
  bodyContent: ReactNode;
  onClick(): void;
}

export const TimeBasedOfferCard = forwardRef(
  (
    {
      onClick,
      showSkeleton,
      titleText,
      rewardText,
      offer,
      hideOfferRewardIcon,
      timeIconType,
      bodyContent,
      numDaysLeft,
      showTrophyIfTimesUpAndCompleted = false,
      completed,
    }: TimeBasedOfferCardProps,
    ref: any
  ): JSX.Element => {
    const timeIcon = (() => {
      switch (timeIconType) {
        case "calendar":
          return faCalendar;
        case "clock":
          return faClock;
        default:
          assertNever(timeIconType);
      }
    })();

    const timesUpContent = (() => {
      if (completed && showTrophyIfTimesUpAndCompleted) {
        return (
          <img
            src={trophyImageUrl}
            alt="Trophy"
            css={css`
              width: 67px;
              margin-top: -10px;
              margin-bottom: -20px;
            `}
          />
        );
      }

      return (
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(0.5)};
            padding: 0 ${theme.spacing(0.75)};
            background-color: white;
            border-radius: 10000px;
            color: ${theme.palette.text.primary};
            margin-top: 2px;
          `}
        >
          <FontAwesomeIcon size="sm" icon={timeIcon} />
          <Typography
            variant="caption"
            color="textPrimary"
            textTransform="uppercase"
          >
            {typeof numDaysLeft === "number" ? (
              <FormattedMessage
                description="Rewards page > time based offer card > time left"
                defaultMessage="{num_days_left, number} {num_days_left, plural, one {day} other {days}} left"
                values={{
                  num_days_left: Math.max(0, numDaysLeft),
                }}
              />
            ) : (
              <FormattedMessage
                description="Rewards page > time based offer card > no time left"
                defaultMessage="Times up"
              />
            )}
          </Typography>
        </div>
      );
    })();

    return (
      <div
        ref={ref}
        css={(appTheme: AppTheme) =>
          css`
            margin-top: ${appTheme.spacing(1.25)};
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
          `
        }
        onClick={onClick}
      >
        <div
          css={(theme: AppTheme) => css`
            border-radius: ${theme.spacing(1.25)} ${theme.spacing(1.25)} 0 0;
            background-color: ${theme.palette.secondary.main};
            width: 100%;
            color: white;
            padding: ${theme.spacing(2.5)};
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: ${theme.spacing(1)};
            flex-wrap: wrap;
          `}
        >
          <div>
            <Typography
              variant="subtitle"
              css={css`
                text-wrap: balance;
              `}
            >
              {showSkeleton ? (
                <Skeleton
                  width={150}
                  animated
                  css={css`
                    display: inline-block;
                  `}
                />
              ) : (
                titleText
              )}{" "}
              <FontAwesomeIcon size="sm" icon={faCircleQuestion} />
            </Typography>
            {showSkeleton ? (
              <Skeleton width={180} animated />
            ) : (
              <OfferReward
                nextReward={offer.nextReward}
                value={{
                  type: "custom_text",
                  text: rewardText,
                }}
                isCompleted={completed}
                hideIcon={hideOfferRewardIcon}
                color="white"
              />
            )}
          </div>
          <div
            css={css`
              flex-shrink: 0;
            `}
          >
            {showSkeleton ? <Skeleton width={80} /> : timesUpContent}
          </div>
        </div>
        <div
          css={(theme: AppTheme) => css`
            border: 1.5px solid ${alpha(theme.palette.grey[400], 0.9)};
            border-top: none;
            border-radius: 0 0 ${theme.spacing(1.25)} ${theme.spacing(1.25)};
            background-color: white;
            padding: ${theme.spacing(2.5)} ${theme.spacing(3)};
          `}
        >
          {showSkeleton ? (
            <>
              <Skeleton height={30} animated />
              <Skeleton height={15} animated />
              <Skeleton height={40} animated />
            </>
          ) : (
            bodyContent
          )}
        </div>
      </div>
    );
  }
);
