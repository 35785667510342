/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { differenceInDays, parseISO, startOfDay } from "date-fns";

import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface ScheduledRewardCountdownProps {
  scheduledRecognition: Pick<
    GraphQLTypes.RecognitionPoints,
    "id" | "scheduledToSendOn" | "status"
  >;
}

export function ScheduledRewardCountdown({
  scheduledRecognition,
}: ScheduledRewardCountdownProps) {
  const { formatMessage } = useFormatters();
  if (
    scheduledRecognition.status !== GraphQLTypes.BonusPointsStatus.Scheduled
  ) {
    return null;
  }

  if (!scheduledRecognition.scheduledToSendOn) {
    return null;
  }

  const scheduledToReceiveOn = parseISO(scheduledRecognition.scheduledToSendOn);
  const daysUntilReward = Math.max(
    0,
    differenceInDays(scheduledToReceiveOn, startOfDay(Date.now()))
  );

  if (daysUntilReward > 99) {
    return null; // only allow 2 digits to display
  }

  return (
    <Typography
      css={(appTheme: AppTheme) => css`
        display: flex;
        align-items: center;
        margin-top: ${appTheme.spacing(6)};
        gap: ${appTheme.spacing(0.75)};
      `}
      variant="footnote"
    >
      {formatMessage(
        {
          defaultMessage: "<boxed_days>{days_left}</boxed_days> days left",
          description: "Rewards Page > upcoming rewards cards  > Countdown",
        },
        {
          days_left: daysUntilReward,
          boxed_days: (node) =>
            node
              .toString()
              .split("")
              .map((digit, index) => (
                <div
                  css={(appTheme: AppTheme) =>
                    css`
                      padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1)};
                      border: 1px solid ${appTheme.palette.divider};
                      border-radius: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `
                  }
                  key={index}
                >
                  <Typography variant="footnote">{digit}</Typography>
                </div>
              )),
        }
      )}
    </Typography>
  );
}
