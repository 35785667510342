/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { times } from "lodash";

import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsShiftMilestone } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ShiftMilestonesBadge } from "../../../../shared/shift-milestone-badge";
import { useStarboyImages } from "./star-boy-images/get-star-boy-images";

export interface ShiftMilestoneBadgeListProps {
  milestoneBadgeSections: {
    title: string;
    badges:
      | ({
          __typename?: "RewardsShiftMilestone" | undefined;
        } & Pick<
          RewardsShiftMilestone,
          "id" | "totalShiftLengthHours" | "pointValue" | "completed"
        >)[]
      | undefined;
    completed: boolean;
  }[];
  nextMilestoneId?: string;
}

export function ShiftMilestoneBadgeList({
  milestoneBadgeSections,
  nextMilestoneId,
}: ShiftMilestoneBadgeListProps): JSX.Element {
  let imageIndex = 0;
  const getStarboyImageUrl = useStarboyImages();

  return (
    <div>
      {milestoneBadgeSections.map((section, sectionIdx) => {
        if (!section.badges || section.badges.length === 0) {
          return null;
        }

        return (
          <div
            key={section.title}
            css={(theme: AppTheme) => css`
              margin: ${theme.spacing(4, 0)};
            `}
          >
            <Typography
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3)};
                font-weight: bold;
              `}
              variant="h3"
              color="primary"
            >
              {section.title}
            </Typography>

            <div
              css={(theme: AppTheme) =>
                css`
                  display: flex;
                  flex-wrap: wrap;
                `
              }
            >
              {section.badges.map((badge) => {
                const badgeState:
                  | "completed"
                  | "in-progress"
                  | "future" = (() => {
                  if (section.completed) {
                    return "completed";
                  }
                  return badge.id === nextMilestoneId
                    ? "in-progress"
                    : "future";
                })();

                imageIndex += 1;

                return (
                  <div
                    css={(theme: AppTheme) => css`
                      margin: ${theme.spacing(1, 0)};
                    `}
                  >
                    <ShiftMilestonesBadge
                      key={badge.id}
                      shiftMilestoneId={badge.id}
                      state={badgeState}
                      imageUrl={getStarboyImageUrl(imageIndex)}
                      numberOfHours={badge.totalShiftLengthHours}
                      numberOfPoints={badge.pointValue}
                    />
                  </div>
                );
              })}

              {sectionIdx === milestoneBadgeSections.length - 1 &&
                times(4, (idx) => {
                  imageIndex += 1;
                  return (
                    <ShiftMilestonesBadge
                      key={idx}
                      shiftMilestoneId="unknown-badge-id"
                      state="future"
                      imagePositionTop={false}
                      imageUrl={getStarboyImageUrl(imageIndex)}
                    />
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
