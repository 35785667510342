import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LoginPageDataForOrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type LoginPageDataForOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization?: Types.Maybe<(
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'logoImageUrl'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName' | 'faviconImageUrl' | 'primaryColor' | 'secondaryColor' | 'optInPageLogoUrl'>
    )> }
  )> }
);


export const LoginPageDataForOrganizationDocument = gql`
    query LoginPageDataForOrganization($organizationId: ID!) {
  organization: getRewardsOrganizationById(organizationId: $organizationId) {
    id
    logoImageUrl
    whiteLabelConfig {
      id
      rewardsProgramShortName
      faviconImageUrl
      primaryColor
      secondaryColor
      optInPageLogoUrl
    }
  }
}
    `;

/**
 * __useLoginPageDataForOrganizationQuery__
 *
 * To run a query within a React component, call `useLoginPageDataForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginPageDataForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginPageDataForOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useLoginPageDataForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<LoginPageDataForOrganizationQuery, LoginPageDataForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginPageDataForOrganizationQuery, LoginPageDataForOrganizationQueryVariables>(LoginPageDataForOrganizationDocument, options);
      }
export function useLoginPageDataForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginPageDataForOrganizationQuery, LoginPageDataForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginPageDataForOrganizationQuery, LoginPageDataForOrganizationQueryVariables>(LoginPageDataForOrganizationDocument, options);
        }
export type LoginPageDataForOrganizationQueryHookResult = ReturnType<typeof useLoginPageDataForOrganizationQuery>;
export type LoginPageDataForOrganizationLazyQueryHookResult = ReturnType<typeof useLoginPageDataForOrganizationLazyQuery>;
export type LoginPageDataForOrganizationQueryResult = Apollo.QueryResult<LoginPageDataForOrganizationQuery, LoginPageDataForOrganizationQueryVariables>;