/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { Page } from "@rewards-web/shared/modules/page";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppThemeProvider } from "@rewards-web/shared/style/theme";
import { AppTheme } from "@rewards-web/shared/style/types";

import caribouLogoUrl from "../../../shared/caribou-logo.png";
import { useAuth } from "../../../shared/modules/auth";
import { RewardsAppAuthError } from "../../../shared/modules/auth/errors";
import { LanguagePage } from "../../authenticated/language";
import { useLanguageSelector } from "../hooks/use-language-selector";
import { UnauthenticatedLanguageSelector } from "../language-selector";
import {
  ReauthCompleteChallengeForm,
  ReauthCompleteChallengeFormValues,
} from "./re-auth-complete-challenge-form";
import { ReauthCreateChallengeForm } from "./re-auth-create-challenge-form";
import { ReauthPrivacyPolicy } from "./re-auth-privacy-policy";
import { useReauthPageDataQuery } from "./reauth-page-data.generated";

const MAX_CONTENT_WIDTH = 440;

export function ReauthPage() {
  const {
    localeName,
    setHasUserUpdatedLanguageForLogin,
  } = useLanguageSelector();
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [redirect] = useQueryParam("redirect");
  const { formatMessage } = useFormatters();
  const [loginTokenId] = useQueryParam("loginTokenId");
  const [userId] = useQueryParam("userId");
  const [tokenAuthFailureId] = useQueryParam("tokenAuthFailureId");
  const [sessionExpirationId] = useQueryParam("sessionExpirationId");
  const reauthSessionId = useMemo(() => v4(), []);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [submittingReauthRequest, setSubmittingReauthRequest] = useState(false);
  const {
    reauthWithPhoneVerification,
    submitPhoneVerificationCode,
    phoneVerificationChallengeId: challengeId,
  } = useAuth();

  const query = useReauthPageDataQuery({
    onError: reportError,
    skip: !loginTokenId && !userId,
    variables: {
      loginTokenId,
      userId,
    },
  });

  const analyticsContext = useMemo(
    () => ({
      loginTokenId,
      userId,
      reauthSessionId,
      tokenAuthFailureId,
      sessionExpirationId,
    }),
    [
      loginTokenId,
      userId,
      reauthSessionId,
      tokenAuthFailureId,
      sessionExpirationId,
    ]
  );

  useEffect(() => {
    track("Reauth page opened", analyticsContext);
  }, [track, analyticsContext]);

  const unexpectedErrorMessage = formatMessage({
    description: "Re-auth page > unexpected error",
    defaultMessage: "An unexpected error occurred. Please try again later.",
  });

  if (!loginTokenId && !userId) {
    return <ErrorPage message={unexpectedErrorMessage} />;
  }

  if (query.error) {
    return <ErrorPage message={unexpectedErrorMessage} />;
  }

  if (!query.data) {
    return <PageLoadingState />;
  }

  const handleCreateChallengeSubmit = async () => {
    try {
      setSubmittingReauthRequest(true);
      const { challengeId } = await reauthWithPhoneVerification({
        loginTokenId: loginTokenId ?? undefined,
        userId: userId ?? undefined,
      });
      trackScreenRecordingEvent("reauth_code_requested");
      track("Reauth code requested", { ...analyticsContext, challengeId });
    } catch (error) {
      reportError(error);
      snackbar.show({ severity: "error", message: unexpectedErrorMessage });
    } finally {
      setSubmittingReauthRequest(false);
    }
  };

  const handleCompleteChallengeSubmit = async ({
    code,
    codeWasPasted,
  }: ReauthCompleteChallengeFormValues & { codeWasPasted: boolean }) => {
    try {
      await submitPhoneVerificationCode(code);

      track("Reauth code entered successfully", {
        ...analyticsContext,
        challengeId,
        codeWasPasted,
      });

      navigate(redirect || "/");
    } catch (error) {
      if (error instanceof RewardsAppAuthError) {
        track("Reauth code entered incorrectly", {
          ...analyticsContext,
          challengeId,
          code: error.code,
          codeWasPasted,
        });

        throw error;
      }

      reportError(error);
      snackbar.show({ severity: "error", message: unexpectedErrorMessage });
    }
  };

  const handleResend = async () => {
    try {
      const { challengeId } = await reauthWithPhoneVerification({
        loginTokenId: loginTokenId ?? undefined,
        userId: userId ?? undefined,
      });

      track("Reauth code re-sent", {
        ...analyticsContext,
        resend: true,
        challengeId,
      });

      snackbar.show({
        severity: "success",
        message: formatMessage({
          description: "Re-auth page > resend code success snackbar",
          defaultMessage: "A new code has been sent",
        }),
      });
    } catch (error) {
      reportError(error);
      snackbar.show({ severity: "error", message: unexpectedErrorMessage });
    }
  };

  const handleHelpClick = () => {
    track("Reauth help email clicked", { ...analyticsContext, challengeId });
  };

  const source = (() => {
    if (tokenAuthFailureId) {
      return "login_token_expired";
    }

    if (sessionExpirationId) {
      return "session_timeout";
    }

    return "unknown";
  })();

  const rewardsProgramShortName =
    query.data?.getRewardsUserLoginTokenDetails.whiteLabelConfig
      ?.rewardsProgramShortName ?? "Caribou Rewards";

  return (
    <AppThemeProvider
      theme={
        query.data?.getRewardsUserLoginTokenDetails.whiteLabelConfig
          ?.primaryColor
          ? {
              palette: {
                primary: {
                  main:
                    query.data?.getRewardsUserLoginTokenDetails.whiteLabelConfig
                      .primaryColor,
                },
              },
            }
          : {}
      }
    >
      {showLanguageSelector && (
        <LanguagePage
          pageType={{
            type: "fullscreen_modal",
            onBackClicked: () => {
              setShowLanguageSelector(false);
            },
            onLocaleUpdated: () => {
              setHasUserUpdatedLanguageForLogin(true);
              setShowLanguageSelector(false);
            },
          }}
        />
      )}

      <Page
        faviconUrl={
          query.data?.getRewardsUserLoginTokenDetails.whiteLabelConfig
            ?.faviconImageUrl ?? undefined
        }
        analyticsPageName="Reauth"
        browserTitle={
          source === "session_timeout"
            ? rewardsProgramShortName
            : formatMessage({
                description: "Re-auth page > browser title",
                defaultMessage: "Welcome back!",
              })
        }
      >
        {!showLanguageSelector && (
          <div
            css={(appTheme: AppTheme) => css`
              padding-top: ${appTheme.spacing(6)};
              ${appTheme.breakpoints.up("md")} {
                padding-top: ${appTheme.spacing(10)};
              }
              padding-bottom: ${appTheme.spacing(4)};
              background-color: white;
              overflow-y: scroll;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <img
                  src={
                    query.data?.getRewardsUserLoginTokenDetails.whiteLabelConfig
                      ?.optInPageLogoUrl ?? caribouLogoUrl
                  }
                  alt={rewardsProgramShortName}
                  css={(theme: AppTheme) => css`
                    display: block;
                    width: 200px;
                    max-width: 100%;
                    margin: ${theme.spacing(2)} auto;
                    ${theme.breakpoints.up("md")} {
                      margin: ${theme.spacing(6)} auto;
                    }
                  `}
                />

                <div
                  css={(theme: AppTheme) => css`
                    width: calc(100% - ${theme.spacing(4)});
                    max-width: ${MAX_CONTENT_WIDTH}px;
                    margin: 0 auto;
                    padding-bottom: ${theme.spacing(2)};
                    flex-grow: 1;
                  `}
                >
                  {challengeId ? (
                    <ReauthCompleteChallengeForm
                      phoneNumber={
                        query.data?.getRewardsUserLoginTokenDetails
                          .obscuredPhoneNumber ?? undefined
                      }
                      onSubmit={handleCompleteChallengeSubmit}
                      rewardsProgramName={rewardsProgramShortName}
                      onResend={handleResend}
                      onHelpClick={handleHelpClick}
                      challengeId={challengeId}
                    />
                  ) : (
                    <ReauthCreateChallengeForm
                      phoneNumber={
                        query.data?.getRewardsUserLoginTokenDetails
                          .obscuredPhoneNumber ?? undefined
                      }
                      source={source}
                      submitting={submittingReauthRequest}
                      onSubmit={handleCreateChallengeSubmit}
                      onHelpClick={handleHelpClick}
                      rewardsProgramShortName={rewardsProgramShortName}
                    />
                  )}
                </div>
              </div>
              <div
                css={(theme: AppTheme) => css`
                  padding: ${theme.spacing(2)};
                  text-align: center;
                  max-width: ${MAX_CONTENT_WIDTH}px;
                  margin: 0 auto;
                `}
              >
                <ReauthPrivacyPolicy />
              </div>
            </div>

            <Divider
              css={css`
                width: 90%;
                margin-left: 2.5%;
              `}
            />

            <UnauthenticatedLanguageSelector
              localeName={localeName}
              setShowLanguageSelector={setShowLanguageSelector}
            />
          </div>
        )}
      </Page>
    </AppThemeProvider>
  );
}
