/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
  PROGRESS_BAR_SECTION_HEIGHT,
  RewardsProgressBarSection,
} from "../rewards-progress-bar-section";
import progressDarkImageUrl from "./progress-bar-dark.png";
import ProgressCheckIcon from "./progress-check-icon";

const CHECK_MARK_WIDTH = 32;

export interface RewardsProgressBarProps {
  completedPercentage: number;
}

export function RewardsProgressBar({
  completedPercentage,
}: RewardsProgressBarProps) {
  const barProgress: number | undefined = (() => {
    if (completedPercentage <= 0) {
      return 0;
    } else if (completedPercentage <= 5) {
      // always show a minimum of 5% so the user sees some of the bar
      return 5;
    } else if (completedPercentage >= 100) {
      return 100;
    } else {
      return completedPercentage;
    }
  })();

  const incomplete = barProgress !== 100;

  return (
    <div
      css={css`
        position: relative;
        height: ${PROGRESS_BAR_SECTION_HEIGHT}px;
        width: 100%;
        > div {
          display: inline-block;
        }
        white-space: nowrap;
      `}
    >
      <div
        css={css`
          position: absolute;
          height: 100%;
          top: 0;
          left: 10px;
          right: 10px;
          background-image: url("${progressDarkImageUrl}");
        `}
      />
      <div
        css={css`
          width: ${incomplete ? `calc(100% - ${CHECK_MARK_WIDTH}px)` : "100%"};
        `}
      >
        <div
          css={css`
            width: ${barProgress}%;
            opacity: ${barProgress === 0 ? "0" : "1"};
            transition: 0.8s width, 0.3s opacity;
          `}
        >
          <RewardsProgressBarSection color="blue" arrowEnd={incomplete} />
        </div>
      </div>
      {incomplete && (
        <ProgressCheckIcon
          css={css`
            position: absolute;
            top: -1px;
            right: 0;
            width: ${CHECK_MARK_WIDTH}px;
          `}
        />
      )}
    </div>
  );
}
