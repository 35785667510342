import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DrawPageTicketCountDataQueryVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
}>;


export type DrawPageTicketCountDataQuery = (
  { __typename?: 'Query' }
  & { countMyTicketsInDraw: (
    { __typename?: 'CountMyTicketsInDrawResponse' }
    & Pick<Types.CountMyTicketsInDrawResponse, 'numTickets'>
  ), getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'referralsEnabled'>
  ) }
);


export const DrawPageTicketCountDataDocument = gql`
    query DrawPageTicketCountData($drawId: ID!) {
  countMyTicketsInDraw(drawId: $drawId) {
    numTickets
  }
  getMyRewardsUser {
    id
    referralsEnabled
  }
}
    `;

/**
 * __useDrawPageTicketCountDataQuery__
 *
 * To run a query within a React component, call `useDrawPageTicketCountDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawPageTicketCountDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawPageTicketCountDataQuery({
 *   variables: {
 *      drawId: // value for 'drawId'
 *   },
 * });
 */
export function useDrawPageTicketCountDataQuery(baseOptions: Apollo.QueryHookOptions<DrawPageTicketCountDataQuery, DrawPageTicketCountDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DrawPageTicketCountDataQuery, DrawPageTicketCountDataQueryVariables>(DrawPageTicketCountDataDocument, options);
      }
export function useDrawPageTicketCountDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrawPageTicketCountDataQuery, DrawPageTicketCountDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DrawPageTicketCountDataQuery, DrawPageTicketCountDataQueryVariables>(DrawPageTicketCountDataDocument, options);
        }
export type DrawPageTicketCountDataQueryHookResult = ReturnType<typeof useDrawPageTicketCountDataQuery>;
export type DrawPageTicketCountDataLazyQueryHookResult = ReturnType<typeof useDrawPageTicketCountDataLazyQuery>;
export type DrawPageTicketCountDataQueryResult = Apollo.QueryResult<DrawPageTicketCountDataQuery, DrawPageTicketCountDataQueryVariables>;