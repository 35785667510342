import { SVGProps } from "react";

export function BlueCircleLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={96}
      height={147}
      fill="none"
      {...props}
    >
      <rect
        width={126.379}
        height={175.719}
        x={16.425}
        y={-45}
        fill="#00C7C7"
        rx={63.19}
        transform="rotate(34.459 16.425 -45)"
      />
    </svg>
  );
}
