import * as Types from '@rewards-web/shared/graphql-types';

import { OptInOrganizationFragmentFragment } from './opt-in-organization-fragment.generated';
import { gql } from '@apollo/client';
import { OptInOrganizationFragmentFragmentDoc } from './opt-in-organization-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OptInPageDataForOrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type OptInPageDataForOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization?: Types.Maybe<(
    { __typename?: 'RewardsOrganization' }
    & OptInOrganizationFragmentFragment
  )> }
);


export const OptInPageDataForOrganizationDocument = gql`
    query OptInPageDataForOrganization($organizationId: ID!) {
  organization: getRewardsOrganizationById(organizationId: $organizationId) {
    ...OptInOrganizationFragment
  }
}
    ${OptInOrganizationFragmentFragmentDoc}`;

/**
 * __useOptInPageDataForOrganizationQuery__
 *
 * To run a query within a React component, call `useOptInPageDataForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptInPageDataForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptInPageDataForOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOptInPageDataForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OptInPageDataForOrganizationQuery, OptInPageDataForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptInPageDataForOrganizationQuery, OptInPageDataForOrganizationQueryVariables>(OptInPageDataForOrganizationDocument, options);
      }
export function useOptInPageDataForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptInPageDataForOrganizationQuery, OptInPageDataForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptInPageDataForOrganizationQuery, OptInPageDataForOrganizationQueryVariables>(OptInPageDataForOrganizationDocument, options);
        }
export type OptInPageDataForOrganizationQueryHookResult = ReturnType<typeof useOptInPageDataForOrganizationQuery>;
export type OptInPageDataForOrganizationLazyQueryHookResult = ReturnType<typeof useOptInPageDataForOrganizationLazyQuery>;
export type OptInPageDataForOrganizationQueryResult = Apollo.QueryResult<OptInPageDataForOrganizationQuery, OptInPageDataForOrganizationQueryVariables>;