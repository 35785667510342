import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobShareGoalCardDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type JobShareGoalCardDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'maxPointsEarnedPerCandidate' | 'pointsPerDollar'>
  ) }
);


export const JobShareGoalCardDataDocument = gql`
    query JobShareGoalCardData {
  getMyRewardsOrganization {
    id
    maxPointsEarnedPerCandidate
    pointsPerDollar
  }
}
    `;

/**
 * __useJobShareGoalCardDataQuery__
 *
 * To run a query within a React component, call `useJobShareGoalCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobShareGoalCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobShareGoalCardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobShareGoalCardDataQuery(baseOptions?: Apollo.QueryHookOptions<JobShareGoalCardDataQuery, JobShareGoalCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobShareGoalCardDataQuery, JobShareGoalCardDataQueryVariables>(JobShareGoalCardDataDocument, options);
      }
export function useJobShareGoalCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobShareGoalCardDataQuery, JobShareGoalCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobShareGoalCardDataQuery, JobShareGoalCardDataQueryVariables>(JobShareGoalCardDataDocument, options);
        }
export type JobShareGoalCardDataQueryHookResult = ReturnType<typeof useJobShareGoalCardDataQuery>;
export type JobShareGoalCardDataLazyQueryHookResult = ReturnType<typeof useJobShareGoalCardDataLazyQuery>;
export type JobShareGoalCardDataQueryResult = Apollo.QueryResult<JobShareGoalCardDataQuery, JobShareGoalCardDataQueryVariables>;