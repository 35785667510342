/** @jsxImportSource @emotion/react */
import {
  ReactNode,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";

import { useTrack } from "@rewards-web/shared/modules/analytics";

interface EmbedSourceValue {
  embedSource: string | null;
}

export const EmbedSourceContext = createContext<EmbedSourceValue>({
  embedSource: null,
});

interface EmbedSourceWrapperProps {
  children: ReactNode;
}

/**
 * Sets the embed source in the context, based on embed_source query param.
 */
export function EmbedSourceWrapper({
  children,
}: EmbedSourceWrapperProps): JSX.Element {
  const [searchParams] = useSearchParams();
  const track = useTrack();

  // No dependencies, as we only want to set the embed source once on initial load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const embedSource = useMemo(() => searchParams.get("embed_source"), []);

  useEffect(() => {
    if (embedSource) {
      track("Embed source detected", {
        embedSource,
      });
    }
  }, [embedSource, track]);

  return (
    <EmbedSourceContext.Provider value={{ embedSource }}>
      {children}
    </EmbedSourceContext.Provider>
  );
}

export function useEmbedSource() {
  const { embedSource } = useContext(EmbedSourceContext);

  return useMemo(
    () => ({
      embedSource,
    }),
    [embedSource]
  );
}
