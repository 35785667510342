/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { TimelineDot } from "@material-ui/lab";
import { ReactNode } from "react";

interface ReferralsCardTimelineDotProps {
  color: "inherit";
  variant?: "outlined";
  children: ReactNode;
}

export function ReferralsCardTimelineDot({
  color,
  children,
}: ReferralsCardTimelineDotProps) {
  return (
    <ClassNames>
      {({ css }) => (
        <TimelineDot
          color={color}
          classes={{
            root: css`
              color: transparent;
              border: 0;
              margin: 0;
              box-shadow: none;
            `,
          }}
        >
          {children}
        </TimelineDot>
      )}
    </ClassNames>
  );
}
