import { sumBy } from "lodash";

import { Referral, ReferralStepV2 } from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { BaseGoalCard } from "../goal-card/base-goal-card";
import referralUserUrl from "../graphics/referral-user.png";

export interface ReferralsGoalCardProps {
  referral: ReferralGoalCardReferral;
}

export type ReferralGoalCardReferral = Pick<
  Referral,
  "id" | "candidateDisplayName"
> & {
  stepsV2: Pick<ReferralStepV2, "completed" | "pointValue">[];
};

export function ReferralsGoalCard({ referral }: ReferralsGoalCardProps) {
  const { formatMessage } = useFormatters();

  const totalPointsEarned = sumBy(
    referral.stepsV2.filter((step) => step.completed),
    (step) => step.pointValue
  );
  const totalPoints = sumBy(referral.stepsV2, (step) => step.pointValue);

  return (
    <BaseGoalCard
      icon={<img src={referralUserUrl} alt="user" />}
      analyticsData={{
        type: "referral",
        candidateId: referral.id,
        totalPointsEarned,
        totalPoints,
      }}
      title={formatMessage(
        {
          description: "Referral goal card > title",
          defaultMessage: "Track {referral_name}",
        },
        {
          referral_name: referral.candidateDisplayName,
        }
      )}
      detailText={formatMessage(
        {
          description: "Referral goal card > detail text",
          defaultMessage:
            "Track your earnings throughout {candidate_display_name}'s hiring progress",
        },
        {
          candidate_display_name: referral.candidateDisplayName,
        }
      )}
      status="available"
      rewardConfig={{
        points: {
          numPoints: totalPoints,
          labelType: "custom",
          label: formatMessage(
            {
              description: "Referral goal card > title",
              defaultMessage:
                "Earned {points_earned, number} of {total_points, number} pts",
            },
            {
              points_earned: totalPointsEarned,
              total_points: totalPoints,
            }
          ),
        },
      }}
      progressProps={{
        value: totalPointsEarned,
        max: totalPoints,
        easingAlgorithm: "ease-out-in",
      }}
      primaryButtonProps={{
        label: formatMessage({
          description: "Referral goal card > button label",
          defaultMessage: "View progress",
        }),
        linkTo: `/jobs/my-referrals?${new URLSearchParams({
          referral_id: referral.id,
        })}`,
      }}
    />
  );
}
