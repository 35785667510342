/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PhoneIcon } from "../../../../shared/phone-icon";
import { ReauthHelpText } from "../re-auth-help-text";

interface ReauthCreateChallengeFormProps {
  phoneNumber: string | undefined;
  source: "login_token_expired" | "session_timeout" | "unknown";
  submitting: boolean;
  onSubmit(): void;
  onHelpClick(): void;
  rewardsProgramShortName: string;
}

export function ReauthCreateChallengeForm({
  phoneNumber,
  source,
  submitting,
  onSubmit,
  onHelpClick,
  rewardsProgramShortName,
}: ReauthCreateChallengeFormProps) {
  const { formatMessage } = useFormatters();

  return (
    <>
      <Typography
        variant="h1"
        color="primary"
        css={(theme: AppTheme) => css`
          font-size: 2em;
          margin-bottom: ${theme.spacing(2)};
          text-align: center;
          ${theme.breakpoints.up("sm")} {
            font-size: 2.5em;
          }
        `}
      >
        {source === "session_timeout" ? (
          rewardsProgramShortName
        ) : (
          <FormattedMessage
            description="Re-auth page > title"
            defaultMessage="Welcome back!"
          />
        )}
      </Typography>

      <Typography
        variant="body"
        color="textPrimary"
        align="center"
        css={(theme: AppTheme) => css`
          ${theme.breakpoints.up("md")} {
            font-size: 1.2em;
          }
        `}
      >
        <FormattedMessage
          description="Re-auth page > description line 1"
          defaultMessage="For your security, we want to confirm it's still you."
        />
      </Typography>

      <Typography
        variant="body"
        color="textPrimary"
        align="center"
        css={(theme: AppTheme) => css`
          ${theme.breakpoints.up("md")} {
            font-size: 1.2em;
          }
        `}
      >
        <FormattedMessage
          description="Re-auth page > description line 2"
          defaultMessage="Please confirm your phone number."
        />
      </Typography>

      {phoneNumber && (
        <Typography
          variant="caption"
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2em",
            letterSpacing: "0.2em",
            fontWeight: "bolder",
          }}
        >
          <PhoneIcon
            css={{
              marginRight: "1em",
            }}
          />

          {formatPhoneNumber(phoneNumber)}
        </Typography>
      )}

      <Button
        color="primary"
        size="large"
        label={formatMessage({
          description: "Re-auth page > confirm button label",
          defaultMessage: "Confirm",
        })}
        loading={submitting}
        onClick={onSubmit}
      />

      <Typography
        variant="body"
        color="textPrimary"
        align="center"
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(4)};
          ${theme.breakpoints.up("md")} {
            font-size: 1.2em;
          }
        `}
      >
        <FormattedMessage
          description="Re-auth page > not phone number text"
          defaultMessage="Not your phone number? <link_to_login>Click here</link_to_login>"
          values={{
            link_to_login: (nodes) => (
              <Typography color="primary" component={Link} to="/login">
                {nodes}
              </Typography>
            ),
          }}
        />
      </Typography>

      <ReauthHelpText onHelpClick={onHelpClick} />
    </>
  );
}
