import { SVGProps } from "react";

export function PhoneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={11}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.067 0H2.933C1.32 0 0 1.33 0 2.957v11.086C0 15.67 1.32 17 2.933 17h5.134C9.68 17 11 15.67 11 14.043V2.957C11 1.33 9.68 0 8.066 0ZM2.933 1.478h5.134c.806 0 1.466.665 1.466 1.478v8.87H1.467v-8.87c0-.813.66-1.478 1.466-1.478Zm5.134 14.043H2.933a1.477 1.477 0 0 1-1.466-1.478v-.739h8.066v.74c0 .812-.66 1.477-1.466 1.477Zm-4.4-11.086h3.666c.44 0 .734-.296.734-.74 0-.443-.294-.739-.734-.739H3.667c-.44 0-.734.296-.734.74 0 .443.294.739.734.739Z"
        fill="#999FAC"
      />
    </svg>
  );
}
