import { useContext, createContext, ReactNode, useMemo, useState } from "react";

interface OnboadingTourTooltipValue {
  show(args: { name: string; message: string; onDismiss?: () => void }): void;
  dismiss: (name: string) => void;
  activeTooltip: { name: string; message: string } | null;
}

export const OnboadingTourTooltipContext = createContext<OnboadingTourTooltipValue>(
  {
    show: () => {},
    activeTooltip: null,
    dismiss: () => {},
  }
);

interface OnboardingTourToolipProviderProps {
  children: ReactNode;
}

export function OnboardingTourToolipProvider({
  children,
}: OnboardingTourToolipProviderProps) {
  const [activeTooltip, setActiveTooltip] = useState<{
    name: string;
    message: string;
  } | null>(null);
  const [onDismiss, setOnDismiss] = useState<(() => void) | null>(null);

  return (
    <OnboadingTourTooltipContext.Provider
      value={{
        show: ({ name, message, onDismiss }) => {
          setActiveTooltip({ name, message });
          setOnDismiss(onDismiss || null);
        },
        activeTooltip,
        dismiss: () => {
          setActiveTooltip(null);
          onDismiss?.();
        },
      }}
    >
      {children}
    </OnboadingTourTooltipContext.Provider>
  );
}

export function useOnboadingTourTooltip(name: string) {
  const { show, activeTooltip, dismiss } = useContext(
    OnboadingTourTooltipContext
  );

  return useMemo(
    () => ({
      show: (args: { onDismiss?: () => void; message: string }) =>
        show({ ...args, name }),
      tooltip: activeTooltip?.name === name ? activeTooltip : null,
      dismiss: () => dismiss(name),
    }),
    [show, activeTooltip, dismiss, name]
  );
}
