/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useRefHeight } from "@rewards-web/shared/hooks/use-ref-height";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { GoalReward, GoalRewardProps } from "../goal-reward";

export interface GoalDetailsModalProps {
  open: boolean;
  onClose(): void;
  goalIcon: JSX.Element | null;
  title: string;
  status: "achieved" | "locked" | "available";
  rewardConfig: GoalRewardProps["rewardConfig"];
  contentsText: ReactNode;
}

export function GoalDetailsModal({
  open,
  onClose,
  goalIcon,
  title,
  status,
  rewardConfig,
  contentsText,
}: GoalDetailsModalProps) {
  const { ref: buttonRef, height: buttonHeight } = useRefHeight({
    isReady: open,
  });
  const { formatMessage } = useFormatters();

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      paddingBottom={buttonHeight}
      maxWidth="500px"
    >
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(5)};
          padding-bottom: ${theme.spacing(3)};
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: flex-start;
            gap: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              > * {
                width: 80px;
                height: 80px;
              }
              margin-left: -${theme.spacing(2)};
            `}
          >
            {goalIcon}
          </div>
          <div
            css={css`
              opacity: 50%;
            `}
          >
            <Typography variant="h6" color="text.primary">
              {title}
            </Typography>
            <GoalReward
              status={status}
              rewardConfig={rewardConfig}
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(0.5)};
              `}
            />
          </div>
        </div>
        <Typography variant="body" color="text.primary">
          {contentsText}
        </Typography>
      </div>
      <div
        ref={buttonRef}
        css={(theme: AppTheme) => css`
          position: sticky;
          width: 100%;
          bottom: 0;
          padding: ${theme.spacing(2)};
        `}
      >
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Close",
            description: "Goal card > details modal > close button",
          })}
          onClick={onClose}
        />
      </div>
    </ResponsiveDialog>
  );
}
