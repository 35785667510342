/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../shared/points-icon";

interface GiftcardProps {
  imageUrl: string;
  brandName: string;
  minPointsAllowed: number;
  maxPointsAllowed: number;
  isLocked: boolean;
  setIsInsufficientPointsDrawerOpen(params: boolean): void;
  setIsRedeemDrawerOpen(params: boolean): void;
}

export function Giftcard({
  imageUrl,
  brandName,
  minPointsAllowed,
  maxPointsAllowed,
  isLocked,
  setIsInsufficientPointsDrawerOpen,
  setIsRedeemDrawerOpen,
}: GiftcardProps) {
  const { formatMessage, formatNumber } = useFormatters();
  const track = useTrack();
  return (
    <div
      css={css`
        overflow: hidden;
        display: flex;
        flex-direction: column;
      `}
    >
      <img
        src={imageUrl}
        alt={brandName}
        css={css`
          width: 100%;
          border-radius: 8px;
          cursor: pointer;
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 0.8;
          }
        `}
        onClick={() => {
          if (isLocked) setIsInsufficientPointsDrawerOpen(true);
          else setIsRedeemDrawerOpen(true);
          track("A gift card was clicked", { brandName });
        }}
      />
      <div
        css={css`
          margin-top: 8px;
        `}
      >
        <Typography
          variant="h6"
          color={isLocked ? "grey.800" : "text.primary"}
          css={(appTheme: AppTheme) => css`
            display: flex;
            align-items: baseline;
            gap: ${appTheme.spacing(0.8)};
          `}
        >
          {isLocked && <FontAwesomeIcon icon={faLock} color="red" />}
          {brandName}
        </Typography>
        <p
          css={(appTheme: AppTheme) => css`
            font-size: 14px;
            margin: 0;
            display: flex;
            align-items: flex-start;
            gap: ${appTheme.spacing(0.8)};
            align-self: stretch;
          `}
        >
          <PointsIcon
            large
            css={css`
              width: 20px;
              ${isLocked && { filter: " grayscale(100%);" }}
            `}
          />
          {formatNumber(minPointsAllowed)} - {formatNumber(maxPointsAllowed)}{" "}
          {formatMessage({
            defaultMessage: "points",
            description: "Points summary page > redeem > points",
          })}
        </p>
      </div>
    </div>
  );
}
