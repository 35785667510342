/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ReauthPrivacyLockIcon } from "./reauth-privacy-lock-icon";

export function ReauthPrivacyPolicy() {
  return (
    <>
      <Typography
        variant="body"
        color="textSecondary"
        css={css`
          font-size: 1em;
        `}
      >
        <FormattedMessage
          description="Re-auth page > privacy policy"
          defaultMessage="<privacy_policy_link>Privacy Policy</privacy_policy_link> | <terms_link>Terms and Conditions</terms_link>"
          values={{
            privacy_policy_link: (nodes) => (
              <a
                href="https://www.caribou.care/privacy-policy-rewards"
                target="_blank"
                rel="noreferrer"
              >
                {nodes}
              </a>
            ),
            terms_link: (nodes) => (
              <a
                href="https://www.caribou.care/terms-rewards"
                target="_blank"
                rel="noreferrer"
              >
                {nodes}
              </a>
            ),
          }}
        />
      </Typography>
      <Typography
        variant="body"
        color="textSecondary"
        css={(theme: AppTheme) => css`
          margin: 0 auto;
          margin-top: ${theme.spacing(1)};
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1em;
        `}
      >
        <ReauthPrivacyLockIcon
          css={(theme: AppTheme) => css`
            margin-right: ${theme.spacing(1)};
          `}
        />
        <FormattedMessage
          description="Re-auth page > privacy policy footer"
          defaultMessage="We respect your privacy."
        />
      </Typography>
    </>
  );
}
