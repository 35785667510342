/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import blueEndArrowImageUrl from "./progress-bar-blue-end-arrow.png";
import blueEndImageUrl from "./progress-bar-blue-end.png";
import blueMidImageUrl from "./progress-bar-blue-mid.png";
import blueStartImageUrl from "./progress-bar-blue-start.png";
import greyEndArrowImageUrl from "./progress-bar-grey-end-arrow.png";
import greyEndImageUrl from "./progress-bar-grey-end.png";
import greyMidImageUrl from "./progress-bar-grey-mid.png";
import greyStartImageUrl from "./progress-bar-grey-start.png";

export const ROUND_EDGE_WIDTH = 15;
const ARROW_EDGE_WIDTH = 22;
export const MAX_EDGE_WIDTH = ROUND_EDGE_WIDTH + ARROW_EDGE_WIDTH;
export const PROGRESS_BAR_SECTION_HEIGHT = 32;

export interface RewardsProgressBarSectionProps {
  color?: "blue" | "grey";
  arrowEnd?: boolean;
}

export function RewardsProgressBarSection({
  color = "blue",
  arrowEnd = false,
}: RewardsProgressBarSectionProps) {
  const edgeWidth = arrowEnd
    ? ARROW_EDGE_WIDTH + ROUND_EDGE_WIDTH
    : ROUND_EDGE_WIDTH * 2;

  const endImageUrl = (() => {
    if (color === "blue") {
      return arrowEnd ? blueEndArrowImageUrl : blueEndImageUrl;
    } else {
      return arrowEnd ? greyEndArrowImageUrl : greyEndImageUrl;
    }
  })();

  return (
    <div
      css={css`
        position: relative;
        height: ${PROGRESS_BAR_SECTION_HEIGHT}px;
        width: 100%;
        > div {
          display: inline-block;
        }
      `}
    >
      <div
        css={css`
          height: 100%;
          background-image: url("${color === "blue"
            ? blueStartImageUrl
            : greyStartImageUrl}");
          width: ${ROUND_EDGE_WIDTH}px;
        `}
      />

      <div
        css={css`
          height: 100%;
          background-image: url("${color === "blue"
            ? blueMidImageUrl
            : greyMidImageUrl}");
          width: calc(100% - ${edgeWidth}px);
        `}
      />

      <div
        css={css`
          height: 100%;
          background-image: url("${endImageUrl}");
          width: ${arrowEnd ? ARROW_EDGE_WIDTH : ROUND_EDGE_WIDTH}px;
        `}
      />
    </div>
  );
}
