/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { StylableProps } from "@rewards-web/shared/types";

import CoinPointsLargeSrc from "../coin-points-large.png";
import CoinPointsSrc from "../coin-points.png";

export interface PointsIconProps extends StylableProps {
  large?: boolean;
}

export function PointsIcon({ className, large }: PointsIconProps) {
  const { formatMessage } = useFormatters();
  return (
    <img
      src={large ? CoinPointsLargeSrc : CoinPointsSrc}
      className={className}
      alt={formatMessage({
        defaultMessage: "Coin icon",
        description: "coin icon alt",
      })}
      css={css`
        width: 16px;
        max-width: ${large ? "128px" : "64px"};
      `}
    />
  );
}
