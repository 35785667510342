import * as Types from '@rewards-web/shared/graphql-types';

import { UserMilestoneRewardStepDetailsFragment } from '../../../shared/milestone-rewards-offer/milestone-step-fragment.generated';
import { gql } from '@apollo/client';
import { UserMilestoneRewardStepDetailsFragmentDoc } from '../../../shared/milestone-rewards-offer/milestone-step-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMilestoneRewardsForSubPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMilestoneRewardsForSubPageQuery = (
  { __typename?: 'Query' }
  & { milestoneRewardsSubPageData?: Types.Maybe<(
    { __typename?: 'UserMilestoneRewards' }
    & Pick<Types.UserMilestoneRewards, 'id' | 'mustCompleteEVV' | 'allMilestonesComplete'>
    & { milestoneSteps: Array<(
      { __typename?: 'UserMilestoneRewardStep' }
      & UserMilestoneRewardStepDetailsFragment
    )>, currentMilestoneStep?: Types.Maybe<(
      { __typename?: 'UserMilestoneRewardStep' }
      & UserMilestoneRewardStepDetailsFragment
    )>, nextMilestoneStep?: Types.Maybe<(
      { __typename?: 'UserMilestoneRewardStep' }
      & UserMilestoneRewardStepDetailsFragment
    )> }
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar'>
  ) }
);


export const GetMilestoneRewardsForSubPageDocument = gql`
    query getMilestoneRewardsForSubPage {
  milestoneRewardsSubPageData: getMyRewardsUserMilestoneRewards {
    id
    milestoneSteps {
      ...UserMilestoneRewardStepDetails
    }
    mustCompleteEVV
    allMilestonesComplete
    currentMilestoneStep {
      ...UserMilestoneRewardStepDetails
    }
    nextMilestoneStep {
      ...UserMilestoneRewardStepDetails
    }
  }
  organization: getMyRewardsOrganization {
    id
    pointsPerDollar
  }
}
    ${UserMilestoneRewardStepDetailsFragmentDoc}`;

/**
 * __useGetMilestoneRewardsForSubPageQuery__
 *
 * To run a query within a React component, call `useGetMilestoneRewardsForSubPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilestoneRewardsForSubPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilestoneRewardsForSubPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMilestoneRewardsForSubPageQuery(baseOptions?: Apollo.QueryHookOptions<GetMilestoneRewardsForSubPageQuery, GetMilestoneRewardsForSubPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMilestoneRewardsForSubPageQuery, GetMilestoneRewardsForSubPageQueryVariables>(GetMilestoneRewardsForSubPageDocument, options);
      }
export function useGetMilestoneRewardsForSubPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMilestoneRewardsForSubPageQuery, GetMilestoneRewardsForSubPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMilestoneRewardsForSubPageQuery, GetMilestoneRewardsForSubPageQueryVariables>(GetMilestoneRewardsForSubPageDocument, options);
        }
export type GetMilestoneRewardsForSubPageQueryHookResult = ReturnType<typeof useGetMilestoneRewardsForSubPageQuery>;
export type GetMilestoneRewardsForSubPageLazyQueryHookResult = ReturnType<typeof useGetMilestoneRewardsForSubPageLazyQuery>;
export type GetMilestoneRewardsForSubPageQueryResult = Apollo.QueryResult<GetMilestoneRewardsForSubPageQuery, GetMilestoneRewardsForSubPageQueryVariables>;