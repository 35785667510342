/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

export function QuickEarnPill() {
  const { formatMessage } = useFormatters();
  return (
    <div
      css={(appTheme: AppTheme) =>
        css`
          background-color: ${appTheme.palette.background.default};
          border-radius: 500px;
          padding: ${appTheme.spacing(0.375)} ${appTheme.spacing(1)};
          flex-shrink: 0;
        `
      }
    >
      <Typography
        css={css`
          text-transform: uppercase;
          font-weight: 600;
        `}
        color="textPrimary"
        variant="caption"
      >
        {formatMessage({
          defaultMessage: "Quick earn 🔥",
          description: "Rewards page > offer cards > quick earn",
        })}
      </Typography>
    </div>
  );
}
