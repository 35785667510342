/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/types";

interface MobileTabContainerProps {
  children: ReactNode;
  height: number;
  position?: "fixed" | "absolute";
}

/**
 * A container which should wrap <NavigationTabs /> to render them
 * in a mobile app context.
 */
export function MobileTabContainer({
  children,
  height,
  position = "fixed",
}: MobileTabContainerProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        position: ${position};
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(${height}px + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
        background-color: ${theme.palette.primary.main};
      `}
    >
      {children}
    </div>
  );
}
