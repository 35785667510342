/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect, useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsBanner } from "../../../shared/points-banner";
import { StickyHeader } from "../../../shared/sticky-header";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { CurrentMilestoneDetailsCard } from "./next-milestone-card";
import {
  ResyncShiftMilestonesDataMutation,
  useResyncShiftMilestonesDataMutation,
} from "./resync-shift-milestones-data.generated";
import { ShiftMilestoneBadgeList } from "./shift-milestone-badge-list";
import { useShiftMilestonesEnabledQuery } from "./shift-milestones-enabled.generated";

export function ShiftMilestones(): JSX.Element {
  const { formatMessage, formatNumber } = useFormatters();
  const [error, setError] = useState(false);
  const [data, setData] = useState<
    Required<ResyncShiftMilestonesDataMutation>["resyncMyShiftMilestones"]
  >();

  const [_resyncShiftMilestonesData] = useResyncShiftMilestonesDataMutation();
  const resyncShiftMilestonesData = useCallback(async () => {
    try {
      const res = await _resyncShiftMilestonesData();

      if (!res.data?.resyncMyShiftMilestones) {
        throw new Error("Shift milestones are not actually enabled");
      }

      setData(res.data.resyncMyShiftMilestones);
    } catch (error) {
      reportError(error);
      setError(true);
    }
  }, [_resyncShiftMilestonesData]);

  useEffect(() => {
    resyncShiftMilestonesData();
  }, [resyncShiftMilestonesData]);

  const milestonesEnabledQuery = useShiftMilestonesEnabledQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
  });

  if (!milestonesEnabledQuery.data || milestonesEnabledQuery.loading) {
    return <PageLoadingState />;
  }

  if (milestonesEnabledQuery.data?.getMyShiftMilestones === null) {
    return (
      <Alert
        message={formatMessage({
          description:
            "Shift milestones page > shift milestones disabled error",
          defaultMessage: "Shift milestones are not enabled.",
        })}
        severity="error"
      />
    );
  }

  if (error || milestonesEnabledQuery.error) {
    return (
      <Alert
        message={formatMessage({
          description:
            "Shift milestones page > shift milestones could not load",
          defaultMessage: "An unexpected error occurred. Please try again",
        })}
        severity="error"
      />
    );
  }

  const nextMilestone = data && Boolean(data.currentMilestoneDetails);
  const loading = !data && !error;

  const shiftMilestonesContent: JSX.Element = (() => {
    if (loading) {
      return <PageLoadingState />;
    }

    const milestoneBadgeSections = [
      {
        title: formatMessage({
          description:
            "Shift milestones page > milestone badges > completed milestones header",
          defaultMessage: "Completed Milestones",
        }),
        badges: data?.allOrderedMilestones.filter((badge) => badge.completed),
        completed: true,
      },
      {
        title: formatMessage({
          description:
            "Shift milestones page > milestone badges > upcoming milestones header",
          defaultMessage: "Upcoming Milestones",
        }),
        badges: data?.allOrderedMilestones.filter((badge) => !badge.completed),
        completed: false,
      },
    ];

    const currentMilestoneDetails = data?.currentMilestoneDetails;

    return (
      <div
        css={(theme: AppTheme) => css`
          width: calc(100% - ${theme.spacing(4)});
          max-width: ${theme.maxContentWidth}px;
          margin: 0 auto;
          padding: ${theme.spacing(2, 0)};
        `}
      >
        <CurrentMilestoneDetailsCard
          milestoneId={currentMilestoneDetails?.currentMilestone.id}
          currentMilestonePoints={
            currentMilestoneDetails?.currentMilestone.pointValue
              ? formatNumber(
                  currentMilestoneDetails.currentMilestone.pointValue
                )
              : "0"
          }
          totalNumberOfHoursCompleted={
            typeof currentMilestoneDetails?.totalShiftHoursWorked === "number"
              ? Math.floor(currentMilestoneDetails.totalShiftHoursWorked)
              : 0
          }
          numberOfHoursCompletedTowardsMilestone={
            typeof currentMilestoneDetails?.shiftHoursCompletedTowardCurrentMilestone ===
            "number"
              ? formatNumber(
                  Math.floor(
                    currentMilestoneDetails.shiftHoursCompletedTowardCurrentMilestone
                  )
                )
              : "?"
          }
          hoursBetweenCurrentAndNextMilestone={
            currentMilestoneDetails?.hoursBetweenPreviousAndCurrentMilestone
              ? formatNumber(
                  currentMilestoneDetails.hoursBetweenPreviousAndCurrentMilestone
                )
              : "?"
          }
          completedPercentage={
            nextMilestone ? currentMilestoneDetails!.percentageCompleted : 100
          }
        />

        <ShiftMilestoneBadgeList
          milestoneBadgeSections={milestoneBadgeSections}
          nextMilestoneId={currentMilestoneDetails?.currentMilestone.id}
        />
      </div>
    );
  })();

  return (
    <>
      <StickyHeader>
        <SubPageHeader
          pageName={formatMessage({
            description: "Shift milestones page > page title",
            defaultMessage: "Shift Milestones",
          })}
          analyticsPageName="Shift Milestones"
          bottomPaddingForPointsBanner
          backTo="rewards"
        />
        <PointsBanner />
      </StickyHeader>

      {shiftMilestonesContent}
    </>
  );
}
