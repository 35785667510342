export function ProfileIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.654 4.38462C13.654 6.8061 11.691 8.76924 9.26939 8.76924C6.84779 8.76924 4.88477 6.80622 4.88477 4.38462C4.88477 1.96302 6.84779 0 9.26939 0C11.691 0 13.654 1.96302 13.654 4.38462Z"
        fill="white"
      />
      <path
        d="M14.3846 11.6923C12.3676 11.6923 10.7307 13.3292 10.7307 15.3462C10.7307 17.3631 12.3676 19 14.3846 19C16.4015 19 18.0384 17.3631 18.0384 15.3462C18.0384 13.3292 16.4015 11.6923 14.3846 11.6923ZM14.3846 12.4231C14.7883 12.4231 15.1153 12.7501 15.1153 13.1539C15.1153 13.5576 14.7883 13.8846 14.3846 13.8846C13.9808 13.8846 13.6538 13.5576 13.6538 13.1539C13.6538 12.7501 13.9808 12.4231 14.3846 12.4231ZM15.4807 17.5385H13.2884C13.0874 17.5385 12.923 17.3741 12.923 17.1731C12.923 16.9721 13.0874 16.8077 13.2884 16.8077H13.6538V14.9808H13.2884C13.0874 14.9808 12.923 14.8164 12.923 14.6154C12.923 14.4144 13.0874 14.25 13.2884 14.25H15.1153V16.8078H15.4807C15.6817 16.8078 15.8461 16.9723 15.8461 17.1732C15.8461 17.3742 15.6817 17.5386 15.4807 17.5386V17.5385Z"
        fill="white"
      />
      <path
        d="M13.3377 11.0932C12.068 10.5424 10.6906 10.2309 9.26927 10.2309C5.89486 10.2309 2.73256 11.8952 0.801451 14.6941C0.604143 14.9873 0.5 15.3262 0.5 15.6751V16.0771C0.5 16.4808 0.827011 16.8079 1.23077 16.8079H10.2558C10.0932 16.3502 10.0001 15.8597 10.0001 15.3463C10.0001 13.2874 11.4232 11.5627 13.3377 11.0931L13.3377 11.0932Z"
        fill="white"
      />
    </svg>
  );
}
