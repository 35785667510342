/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../../../../shared/scroll-to-top-on-mount";
import drawComingSoonTrophyImageUrl from "../draw-two-trophies.png";
import { useDrawComingSoonPageDataQuery } from "./draw-coming-soon-page-data.generated";

export function DrawComingSoonState() {
  const { formatMessage } = useFormatters();
  const query = useDrawComingSoonPageDataQuery({ onError: reportError });

  const track = useTrack();
  useEffect(() => {
    track("Viewed draw coming soon state");
  }, [track]);

  return (
    <div
      css={(theme: AppTheme) => css`
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <ScrollToTopOnMount />
      <PageContentContainer>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            flex-direction: column;

            @media (min-height: 780px) {
              padding-top: ${theme.spacing(8)};
            }
          `}
        >
          <div
            css={css`
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            `}
          >
            <img
              src={drawComingSoonTrophyImageUrl}
              alt={formatMessage({
                defaultMessage: "Trophies",
                description:
                  "Draw page > draw coming soon state > trophy image alt",
              })}
              css={(theme: AppTheme) => css`
                margin: 0 auto;
                width: 300px;
                max-width: 90%;
                margin-bottom: ${theme.spacing(3)};
              `}
            />

            <Typography
              variant="caption"
              textTransform="uppercase"
              component="span"
              color="white"
              css={(theme: AppTheme) => css`
                background-color: ${theme.palette.secondary.main};
                border-radius: 12px;
                height: 24px;
                padding: 0 ${theme.spacing(1)};
                margin-bottom: ${theme.spacing(1)};
                display: flex;
                align-items: center;
              `}
            >
              <FormattedMessage
                defaultMessage="New Feature"
                description="Draw page > draw coming soon state > new feature text"
              />
            </Typography>

            <Typography
              variant="h3"
              component="h2"
              textAlign="center"
              color="#2C393F"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              <FormattedMessage
                defaultMessage="Coming soon!"
                description="Draw page > draw coming soon state > page subtitle"
              />
            </Typography>
            <Typography variant="body" textAlign="center" color="#636666">
              <FormattedMessage
                defaultMessage="Earn chances to win exciting prizes with {org_name}."
                description="Draw page > draw coming soon state > page body"
                values={{ org_name: query.data?.organization.shortName ?? "" }}
              />
            </Typography>
          </div>
        </div>
      </PageContentContainer>
    </div>
  );
}
