/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { take } from "lodash";
import { useState } from "react";
import { Control, Controller } from "react-hook-form";

import { CheckboxField } from "@rewards-web/shared/components/checkbox-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { JobFilterBadge } from "../../job-filter-badge";
import { JobFilterDialogFormValues } from "../types";
import { JobFilterShowMoreButton } from "./job-filter-show-more-button";

const DEFAULT_NUM_OPTIONS_TO_DISPLAY = 5;
const DEFAULT_NUM_OPTIONS_TO_DISPLAY_TALL_SCREEN = 10;

interface JobFilterCheckboxSetProps {
  control: Control;
  title: string;
  options: string[];
  formValues: JobFilterDialogFormValues;
  getFormKey(option: string): string;
  getIsDisabled(option: string): boolean;
  numSelectedOptions: number;
}

export function JobFilterCheckboxSet({
  control,
  title,
  options,
  formValues,
  getFormKey,
  getIsDisabled,
  numSelectedOptions,
}: JobFilterCheckboxSetProps) {
  const [showMoreEnabled, setShowMoreEnabled] = useState(false);

  const tallScreen = useMediaQuery(
    "(min-width: 800px) and (min-height: 550px)"
  );
  const defaultNumOptionsToDisplay = tallScreen
    ? DEFAULT_NUM_OPTIONS_TO_DISPLAY_TALL_SCREEN
    : DEFAULT_NUM_OPTIONS_TO_DISPLAY;

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Typography
          color="textPrimary"
          variant="subtitle"
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {title}
        </Typography>

        {numSelectedOptions > 0 && (
          <JobFilterBadge
            numSelectedFilters={numSelectedOptions}
            css={(theme: AppTheme) => css`
              margin-left: ${theme.spacing(2)};
            `}
          />
        )}
      </div>

      {(showMoreEnabled
        ? options
        : take(options, defaultNumOptionsToDisplay)
      ).map(
        (option) =>
          // ensure the form has initialized this option... otherwise
          // we will run into controlled/uncontrolled input
          // issues
          formValues.hasOwnProperty(getFormKey(option)) && (
            <Controller
              key={option}
              control={control}
              name={getFormKey(option)}
              render={({ field, fieldState }) => (
                <CheckboxField
                  {...field}
                  error={fieldState.error}
                  label={option}
                  display="block"
                  disabled={getIsDisabled(option)}
                />
              )}
            />
          )
      )}

      {options.length > defaultNumOptionsToDisplay && (
        <JobFilterShowMoreButton
          showingMore={showMoreEnabled}
          onClick={() => setShowMoreEnabled((prev) => !prev)}
        />
      )}
    </>
  );
}
