/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { Modal } from "../../../../../../../shared/modal";

interface DrawWayToEarnCardProps {
  analyticsProperties: Record<string, any>;
  titleText: string;
  bodyText: string;
  graphic: ReactNode;
  buttonLabel: string;
  action:
    | {
        type: "internal_link";
        linkTo: string;
        linkState?: Record<string, any>;
      }
    | {
        type: "external_link";
        url: string;
      }
    | {
        type: "modal";
        modalContentsHtml: string;
      };
}

export function DrawWayToEarnCard({
  analyticsProperties,
  titleText,
  bodyText,
  graphic,
  buttonLabel,
  action,
}: DrawWayToEarnCardProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const track = useTrack();
  const { formatMessage } = useFormatters();

  const children = (
    <>
      <div
        css={(theme: AppTheme) => css`
          margin-right: ${theme.spacing(2)};
          display: block;
          width: 40px;
          & > * {
            display: block;
            width: 100%;
          }
        `}
      >
        {graphic}
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
        `}
      >
        <Typography variant="subtitle" color="#2C393F">
          {titleText}
        </Typography>
        <Typography variant="body" color="#636666">
          {bodyText}
        </Typography>
      </div>

      <Button
        {...(action.type === "internal_link"
          ? {
              linkTo: action.linkTo,
              linkState: action.linkState,
            }
          : {})}
        label={buttonLabel}
        size="medium"
        variant="outlined"
        width="auto"
        css={(theme: AppTheme) => css`
          margin-left: ${theme.spacing(1)};
        `}
      />
    </>
  );

  const parentProps = {
    onClick: () => {
      track("Clicked draw way to earn button", { ...analyticsProperties });
    },
    css: (theme: AppTheme) => css`
      display: block;
      background: transparent;
      border-radius: 10px;
      border: 1.5px solid rgba(204, 204, 204, 0.5);
      width: 100%;
      padding: ${theme.spacing(2)};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: stretch;
    `,
  };

  switch (action.type) {
    case "internal_link":
      return (
        <Link {...parentProps} to={action.linkTo} state={action.linkState}>
          {children}
        </Link>
      );
    case "external_link":
      return (
        <a {...parentProps} href={action.url} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    case "modal":
      return (
        <>
          <div
            {...parentProps}
            onClick={() => {
              parentProps.onClick();
              setModalOpen(true);
            }}
          >
            {children}
          </div>
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={titleText}
            maxWidth="500px"
            details={
              <div
                css={(theme: AppTheme) => css`
                  p {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    &:not(:last-child) {
                      margin-bottom: ${theme.spacing(1)};
                    }
                  }
                `}
                dangerouslySetInnerHTML={{ __html: action.modalContentsHtml }}
              />
            }
          >
            <Button
              label={formatMessage({
                description:
                  "Draw page > draw active state > ways to earn > modal close button",
                defaultMessage: "Close",
              })}
              color="primary"
              onClick={() => setModalOpen(false)}
            />
          </Modal>
        </>
      );
    default:
      assertNever(
        action,
        `Unexpected action type ${
          (action as DrawWayToEarnCardProps["action"]).type
        }`
      );
  }
}
