/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import backpackImageUrl from "../../graphics/backpack.png";
import wallClockImageUrl from "../../graphics/wall-clock.png";
import { UserMilestoneRewardStepDetailsFragment } from "../../milestone-step-fragment.generated";

export function MilestoneRewardStepIcon({
  goal,
}: {
  goal: UserMilestoneRewardStepDetailsFragment["goal"];
}) {
  const { formatMessage } = useFormatters();

  const { src, alt } = (() => {
    switch (goal.__typename) {
      case "HoursMilestoneGoal":
        return {
          src: wallClockImageUrl,
          alt: formatMessage({
            defaultMessage: "clock",
            description:
              "Milestones rewards > milestone step icon > clock icon alt",
          }),
        };
      case "VisitMilestoneGoal":
        return {
          src: backpackImageUrl,
          alt: formatMessage({
            defaultMessage: "backpack",
            description:
              "Milestones rewards > milestone step icon > backpack icon alt",
          }),
        };
      case undefined:
      default:
        reportError(
          new Error(
            `Unexpected milestone goal type when processing milestone icon: ${goal.__typename}`
          )
        );
        return {
          src: backpackImageUrl,
          alt: formatMessage({
            defaultMessage: "backpack",
            description:
              "Milestones rewards > milestone step icon > backpack icon alt",
          }),
        };
    }
  })();

  return (
    <img
      {...{ src, alt }}
      css={(appTheme: AppTheme) => css`
        width: 50px;
        object-fit: contain;
        margin-top: ${appTheme.spacing(2)};
      `}
    />
  );
}
