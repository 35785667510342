/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/types";

import { PUNCH_CARD_BREAKPOINTS } from "../constants";

export interface PunchCardPositionRowProps {
  predefinedGridLayout: boolean;
  children: ReactNode;
}

export function PunchCardPositionRow({
  predefinedGridLayout,
  children,
}: PunchCardPositionRowProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: ${predefinedGridLayout ? "nowrap" : "wrap"};
        gap: 4px;
        ${theme.breakpoints.up(276)} {
          gap: 10px;
        }
        ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.sm)} {
          gap: 16px;
        }
        ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.lg)} {
          gap: 30px;
        }

        margin: 0 auto;
        &:not(:last-child) {
          margin-bottom: ${theme.spacing(2)};
        }
      `}
    >
      {children}
    </div>
  );
}
