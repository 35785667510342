/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { ListShareableJobPostingsFilters } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";

import { JobFilterButton } from "./job-filter-button";
import { JobFilterDialog } from "./job-filter-dialog";

interface JobCountAndFilterProps {
  numJobs: number | undefined;
  selectedFilters: ListShareableJobPostingsFilters;
  onChangeSelectedFilters(next: ListShareableJobPostingsFilters): void;
}

export function JobCountAndFilter({
  numJobs,
  selectedFilters,
  onChangeSelectedFilters,
}: JobCountAndFilterProps) {
  const track = useTrack();
  useEffect(() => {
    // track that the user sees the dialog enabled
    // (since we are rolling this out on a feature flag)
    track("Job share filter dialog enabled");
  }, [track]);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const numSelectedFilters = [
    ...(selectedFilters.titleIn ?? []),
    ...(selectedFilters.geographyIn ?? []),
  ].length;

  return (
    <>
      <JobFilterDialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        selectedFilters={selectedFilters}
        onChangeSelectedFilters={onChangeSelectedFilters}
      />
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Typography variant="subtitle" color="textPrimary">
          {typeof numJobs === "number" && (
            <FormattedMessage
              description="Jobs page > jobs count"
              defaultMessage="{num_jobs, number} {num_jobs, plural, one {Job} other {Jobs}}"
              values={{ num_jobs: numJobs }}
            />
          )}
        </Typography>

        <JobFilterButton
          onClick={() => {
            setFilterDialogOpen(true);
          }}
          numSelectedFilters={numSelectedFilters}
        />
      </div>
    </>
  );
}
