import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateSmsLoginChallengeMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String'];
  organizationId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type CreateSmsLoginChallengeMutation = (
  { __typename?: 'Mutation' }
  & { createRewardsUserSMSLoginChallenge: (
    { __typename?: 'CreateRewardsUserSMSLoginChallengeResponse' }
    & Pick<Types.CreateRewardsUserSmsLoginChallengeResponse, 'challengeId'>
  ) }
);


export const CreateSmsLoginChallengeDocument = gql`
    mutation CreateSMSLoginChallenge($phoneNumber: String!, $organizationId: ID) {
  createRewardsUserSMSLoginChallenge(
    phoneNumber: $phoneNumber
    organizationId: $organizationId
  ) {
    challengeId
  }
}
    `;
export type CreateSmsLoginChallengeMutationFn = Apollo.MutationFunction<CreateSmsLoginChallengeMutation, CreateSmsLoginChallengeMutationVariables>;

/**
 * __useCreateSmsLoginChallengeMutation__
 *
 * To run a mutation, you first call `useCreateSmsLoginChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmsLoginChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmsLoginChallengeMutation, { data, loading, error }] = useCreateSmsLoginChallengeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateSmsLoginChallengeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSmsLoginChallengeMutation, CreateSmsLoginChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSmsLoginChallengeMutation, CreateSmsLoginChallengeMutationVariables>(CreateSmsLoginChallengeDocument, options);
      }
export type CreateSmsLoginChallengeMutationHookResult = ReturnType<typeof useCreateSmsLoginChallengeMutation>;
export type CreateSmsLoginChallengeMutationResult = Apollo.MutationResult<CreateSmsLoginChallengeMutation>;
export type CreateSmsLoginChallengeMutationOptions = Apollo.BaseMutationOptions<CreateSmsLoginChallengeMutation, CreateSmsLoginChallengeMutationVariables>;