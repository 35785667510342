/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { LanguageIcon } from "../../authenticated/settings/icons/language-icon";

interface UnauthenticatedLanguageSelectorProps {
  localeName: string;
  setShowLanguageSelector: (value: boolean) => void;
}

export function UnauthenticatedLanguageSelector({
  localeName,
  setShowLanguageSelector,
}: UnauthenticatedLanguageSelectorProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: ${theme.spacing(2)};
        padding-bottom: ${theme.spacing(4)};
      `}
    >
      <LanguageIcon color="#636666" />
      <Typography
        color="textPrimary"
        variant="footnote"
        onClick={() => setShowLanguageSelector(true)}
        css={(theme: AppTheme) =>
          css`
            cursor: pointer;
            text-decoration: underline;
            padding-left: ${theme.spacing(0.5)};
          `
        }
      >
        {localeName}
      </Typography>
    </div>
  );
}
