/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseISO } from "date-fns";
import { useEffect, useMemo, useRef } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { BonusPointsStatus } from "@rewards-web/shared/graphql-types";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../shared/points-icon";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import GiftIcon from "./icons/gift.png";
import { ScheduledRewardCountdown } from "./scheduled-countdown";

export interface ScheduledRewardsCardProps {
  scheduledRecognition: NonNullable<
    RewardsPageDataQuery["getMyEmployeePendingAndSentScheduledRecognitionPoints"][number]
  >;
}

export function ScheduledRewardsCard({
  scheduledRecognition,
}: ScheduledRewardsCardProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const trackingProperties = useMemo(
    () => ({
      recognition_points_id: scheduledRecognition.id,
      point_value: scheduledRecognition.pointValue,
      status: scheduledRecognition.status,
    }),
    [scheduledRecognition]
  );

  const onScreen = useOnScreen(ref, { threshold: 0.5 });
  const track = useTrack();

  useEffect(() => {
    if (onScreen) {
      track(
        "Viewed rewards page scheduled recognition card",
        trackingProperties
      );
    }
  }, [track, onScreen, trackingProperties]);

  return (
    <div
      key={scheduledRecognition.id}
      css={(appTheme: AppTheme) => css`
        border: 1.5px solid ${appTheme.palette.divider};
        margin-top: ${appTheme.spacing(1.75)};
        border-radius: 10px;
        background-color: ${appTheme.palette.background.paper};
        padding: ${appTheme.spacing(2.5)};
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
      onClick={() => {
        track(
          "Clicked rewards page scheduled recognition card",
          trackingProperties
        );
      }}
    >
      <div>
        <Typography variant="subtitle">
          {scheduledRecognition.category?.name ||
            formatMessage({
              defaultMessage: "Recognition Points",
              description:
                "Rewards page > upcoming rewards cards > default title",
            })}
        </Typography>
        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            align-items: center;
            gap: ${appTheme.spacing(0.75)};
            margin-top: ${appTheme.spacing(0.5)};
          `}
        >
          {scheduledRecognition.status === BonusPointsStatus.Scheduled ? (
            <PointsIcon />
          ) : (
            <FontAwesomeIcon
              color={theme.palette.primary.main}
              icon={faCircleCheck}
              fontSize={theme.typography.footnote.fontSize}
            />
          )}
          <Typography variant="footnote">
            {formatMessage(
              {
                defaultMessage:
                  "{amount, number} points on {date, date, medium}",
                description:
                  "Rewards page > upcoming rewards cards > points and date",
              },
              {
                amount: scheduledRecognition.pointValue,
                date: parseISO(scheduledRecognition.scheduledToSendOn),
              }
            )}
          </Typography>
        </div>
        <ScheduledRewardCountdown scheduledRecognition={scheduledRecognition} />
      </div>
      <img
        src={GiftIcon}
        alt={formatMessage({
          defaultMessage: "Gift icon",
          description: "Rewards page > upcoming rewards cards > gift icon alt",
        })}
        css={(appTheme: AppTheme) => css`
          width: 87px;
          margin: ${appTheme.spacing(0, 2)};
        `}
      />
    </div>
  );
}
