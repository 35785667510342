import { useTheme } from "@emotion/react";

import { StylableProps } from "@rewards-web/shared/types";

interface PointsStarIconProps extends StylableProps {
  color: "primary" | "gold";
  width?: number;
  height?: number;
}

export function PointsStarIcon({
  className,
  color,
  height = 19,
  width = 19,
}: PointsStarIconProps) {
  const theme = useTheme();

  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 19"
    >
      <ellipse
        cx={9.489}
        cy={9.42}
        rx={9.489}
        ry={9.42}
        fill={
          color === "primary"
            ? theme.palette.primary.main
            : theme.palette.gold.light
        }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.486 4a.28.28 0 0 0-.254.173L7.816 7.938H3.28a.28.28 0 0 0-.267.172.235.235 0 0 0 .1.28l3.684 2.387-1.416 3.896a.235.235 0 0 0 .103.28.31.31 0 0 0 .332-.001L9.5 12.565l3.683 2.387a.31.31 0 0 0 .332 0 .235.235 0 0 0 .103-.28l-1.416-3.896 3.684-2.387a.235.235 0 0 0 .1-.279.28.28 0 0 0-.267-.172h-4.535L9.768 4.173c-.039-.107-.154-.178-.282-.173Z"
        fill="#fff"
      />
    </svg>
  );
}
