import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

import { reportError } from "@rewards-web/shared/modules/error";

export async function getCognitoSession(): Promise<CognitoUserSession> {
  return Auth.currentSession();
}

export async function getExistingCognitoUserId(): Promise<string | null> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.payload["cognito:username"];
  } catch (error) {
    if (error !== "The user is not authenticated") {
      reportError(
        error instanceof Error
          ? error
          : new Error(
              `Could not initialize authenticated user: ${JSON.stringify(
                error
              )}`
            )
      );
    }
  }

  return null;
}

export async function signOutOfCognito() {
  await Auth.signOut();
}
