/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import MegaphoneIcon from "./icons/megaphone.png";

export interface ReferralsRewardCardProps {
  activeReferrals: NonNullable<
    RewardsPageDataQuery["getMyReferralsProgressV2"]["referrals"]
  >;
}

export function ReferralsRewardCard({
  activeReferrals,
}: ReferralsRewardCardProps) {
  const { formatMessage } = useFormatters();

  const ref = useRef<HTMLDivElement>(null);
  const trackingProperties = useMemo(
    () => ({
      num_active_referrals: activeReferrals.length,
    }),
    [activeReferrals.length]
  );

  const onScreen = useOnScreen(ref, { threshold: 0.5 });
  const track = useTrack();

  useEffect(() => {
    if (onScreen) {
      track("Viewed rewards page referrals card", trackingProperties);
    }
  }, [track, onScreen, trackingProperties]);

  return (
    <div
      ref={ref}
      css={(appTheme: AppTheme) =>
        css`
          margin-top: ${appTheme.spacing(1.25)};
          border: 1.5px solid ${appTheme.palette.divider};
          padding: ${appTheme.spacing(2.5)};
          border-radius: 10px;
          background-color: white;
        `
      }
    >
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        `}
      >
        <div>
          <Typography variant="subtitle">
            {formatMessage(
              {
                defaultMessage:
                  "{num_referrals} active {num_referrals, plural, one {referral} other {referrals}}",
                description: "Rewards page > Referrals card > active referrals",
              },
              { num_referrals: activeReferrals.length }
            )}
          </Typography>
          <ul
            css={(appTheme: AppTheme) =>
              css`
                margin: ${appTheme.spacing(0.5)} 0px;
              `
            }
          >
            {activeReferrals.slice(0, 6).map((referral) => (
              <li key={referral.id}>
                <Typography
                  css={(appTheme: AppTheme) =>
                    css`
                      margin-bottom: ${appTheme.spacing(0.5)};
                    `
                  }
                  variant="footnote"
                >
                  {referral.candidateDisplayName}
                </Typography>
              </li>
            ))}
            {activeReferrals.length > 6 && (
              <li>
                <Typography
                  css={(appTheme: AppTheme) =>
                    css`
                      margin-bottom: ${appTheme.spacing(0.5)};
                    `
                  }
                  variant="footnote"
                >
                  {formatMessage({
                    defaultMessage: "and more!",
                    description: "Rewards page > Referrals card > and more",
                  })}
                </Typography>
              </li>
            )}
          </ul>

          <Button
            css={(appTheme: AppTheme) =>
              css`
                margin-top: ${appTheme.spacing(2.5)};
              `
            }
            linkTo="/jobs/my-referrals"
            onClick={() =>
              track(
                "Clicked rewards page referrals card button",
                trackingProperties
              )
            }
            color="secondary"
            width="auto"
            label={formatMessage({
              defaultMessage: "Upcoming rewards",
              description: "Rewards page > Referrals card > button",
            })}
          />
        </div>
        <img
          src={MegaphoneIcon}
          alt="megaphone icon"
          css={(appTheme: AppTheme) => css`
            width: 83px;
            margin-right: ${appTheme.spacing(2)};
            margin-left: ${appTheme.spacing(0.5)};
            align-self: center;
          `}
        />
      </div>
    </div>
  );
}
