export function roundTrackEarnablePointsValue(amount: number) {
  if (amount > 100_000) {
    // round values up to the nearest 100_000
    return ceil(amount, 10_000);
  }

  if (amount > 10_000) {
    // round values up to the nearest 10_000
    return ceil(amount, 1_000);
  }

  // use exact amount for values smaller than this
  return amount;
}

function ceil(amount: number, granularity: number) {
  return Math.ceil(amount / granularity) * granularity;
}
