/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedDate } from "@rewards-web/shared/modules/formatter";

export interface FormattedVisitDateProps {
  dateValue: Date | number;
  boldDay?: boolean;
}

export function FormattedVisitDate({
  dateValue,
  boldDay = false,
}: FormattedVisitDateProps) {
  const formattedDate = new Date(dateValue);

  return (
    <Typography variant="body">
      <span
        css={css`
          font-weight: ${boldDay ? 700 : 400};
        `}
      >
        <FormattedDate value={formattedDate} weekday="long" />
      </span>{" "}
      <span
        css={css`
          font-weight: 400;
        `}
      >
        <FormattedDate
          value={formattedDate}
          month="numeric"
          day="numeric"
          year="2-digit"
          hour="numeric"
          minute="numeric"
          hour12={true}
        />
      </span>
    </Typography>
  );
}
