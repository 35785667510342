import { useEffect, useState } from "react";

/**
 * Adapted from: https://stackoverflow.com/a/65008608
 */
export function useOnScreen(
  ref: { current: Element | undefined | null },
  options: IntersectionObserverInit
) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (window.IntersectionObserver) {
        // some older browsers don't seem to support IntersectionObserver
        const observer = new IntersectionObserver(
          ([entry]) => setIntersecting(entry.isIntersecting),
          options
        );

        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => {
          observer.disconnect();
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}
