import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AcknowledgePunchCardCompletionsMutationVariables = Types.Exact<{
  data: Array<Types.BulkAcknowledgeMyRewardOfferCompletionsData> | Types.BulkAcknowledgeMyRewardOfferCompletionsData;
}>;


export type AcknowledgePunchCardCompletionsMutation = (
  { __typename?: 'Mutation' }
  & { bulkAcknowledgeMyRewardOfferCompletions: Array<(
    { __typename?: 'RewardOffer' }
    & Pick<Types.RewardOffer, 'id'>
    & { completions: Array<(
      { __typename?: 'RewardOfferCompletion' }
      & Pick<Types.RewardOfferCompletion, 'id' | 'awarded' | 'acknowledged'>
    )> }
  )> }
);


export const AcknowledgePunchCardCompletionsDocument = gql`
    mutation AcknowledgePunchCardCompletions($data: [BulkAcknowledgeMyRewardOfferCompletionsData!]!) {
  bulkAcknowledgeMyRewardOfferCompletions(data: $data) {
    id
    completions {
      id
      awarded
      acknowledged
    }
  }
}
    `;
export type AcknowledgePunchCardCompletionsMutationFn = Apollo.MutationFunction<AcknowledgePunchCardCompletionsMutation, AcknowledgePunchCardCompletionsMutationVariables>;

/**
 * __useAcknowledgePunchCardCompletionsMutation__
 *
 * To run a mutation, you first call `useAcknowledgePunchCardCompletionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgePunchCardCompletionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgePunchCardCompletionsMutation, { data, loading, error }] = useAcknowledgePunchCardCompletionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAcknowledgePunchCardCompletionsMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgePunchCardCompletionsMutation, AcknowledgePunchCardCompletionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgePunchCardCompletionsMutation, AcknowledgePunchCardCompletionsMutationVariables>(AcknowledgePunchCardCompletionsDocument, options);
      }
export type AcknowledgePunchCardCompletionsMutationHookResult = ReturnType<typeof useAcknowledgePunchCardCompletionsMutation>;
export type AcknowledgePunchCardCompletionsMutationResult = Apollo.MutationResult<AcknowledgePunchCardCompletionsMutation>;
export type AcknowledgePunchCardCompletionsMutationOptions = Apollo.BaseMutationOptions<AcknowledgePunchCardCompletionsMutation, AcknowledgePunchCardCompletionsMutationVariables>;