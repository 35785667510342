import * as React from "react";

function CopyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#444">
        <path d="M18.792 18.333h-11A2.294 2.294 0 015.5 16.042V2.292A2.294 2.294 0 017.792 0h8.257c.845 0 1.671.343 2.269.94l1.826 1.826a3.19 3.19 0 01.94 2.27v11.006a2.294 2.294 0 01-2.292 2.291zM7.792.917c-.758 0-1.375.617-1.375 1.375v13.75c0 .758.617 1.375 1.375 1.375h11c.758 0 1.375-.617 1.375-1.375V5.035a2.28 2.28 0 00-.671-1.62L17.67 1.589a2.306 2.306 0 00-1.621-.672H7.792z" />
        <path d="M14.208 22h-11a2.294 2.294 0 01-2.292-2.292V5.042A2.294 2.294 0 013.208 2.75h.916a.458.458 0 010 .917h-.916c-.758 0-1.375.617-1.375 1.375v14.666c0 .758.617 1.375 1.375 1.375h11c.758 0 1.375-.617 1.375-1.375a.458.458 0 01.917 0A2.294 2.294 0 0114.208 22zM16.958 7.334H9.624a.458.458 0 010-.917h7.334a.458.458 0 010 .917z" />
        <path d="M16.958 11H9.624a.458.458 0 010-.917h7.334a.459.459 0 010 .917zM16.958 14.667H9.624a.459.459 0 010-.917h7.334a.458.458 0 010 .917z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CopyIcon;
