import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsPointsBannerDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsPointsBannerDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'minimumPointsNeededToRedeem' | 'pointsPerDollar'>
  ) }
);


export const RewardsPointsBannerDataDocument = gql`
    query RewardsPointsBannerData {
  getMyRewardsOrganization {
    id
    minimumPointsNeededToRedeem
    pointsPerDollar
  }
}
    `;

/**
 * __useRewardsPointsBannerDataQuery__
 *
 * To run a query within a React component, call `useRewardsPointsBannerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsPointsBannerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsPointsBannerDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsPointsBannerDataQuery(baseOptions?: Apollo.QueryHookOptions<RewardsPointsBannerDataQuery, RewardsPointsBannerDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsPointsBannerDataQuery, RewardsPointsBannerDataQueryVariables>(RewardsPointsBannerDataDocument, options);
      }
export function useRewardsPointsBannerDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsPointsBannerDataQuery, RewardsPointsBannerDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsPointsBannerDataQuery, RewardsPointsBannerDataQueryVariables>(RewardsPointsBannerDataDocument, options);
        }
export type RewardsPointsBannerDataQueryHookResult = ReturnType<typeof useRewardsPointsBannerDataQuery>;
export type RewardsPointsBannerDataLazyQueryHookResult = ReturnType<typeof useRewardsPointsBannerDataLazyQuery>;
export type RewardsPointsBannerDataQueryResult = Apollo.QueryResult<RewardsPointsBannerDataQuery, RewardsPointsBannerDataQueryVariables>;