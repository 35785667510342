import { useCallback, useEffect, useMemo, useState } from "react";

import { Locale } from "@rewards-web/shared/graphql-types";
import {
  ALL_SUPPORTED_LOCALES,
  useLocale,
} from "@rewards-web/shared/modules/formatter";

export interface UseLanguageSelectorReturnValue {
  localeName: string;
  setHasUserUpdatedLanguageForLogin(value: boolean): void;
}

export function useLanguageSelector(): UseLanguageSelectorReturnValue {
  const [
    hasUserUpdatedLanguageForLogin,
    setHasUserUpdatedLanguageForLogin,
  ] = useState(false);
  const { locale, setLocale } = useLocale();

  const { name: localeName } = ALL_SUPPORTED_LOCALES.find(
    ({ code }) => code === locale
  )!;

  const mapNavigatorLocalesToEnum = useCallback(() => {
    for (const locale of navigator.languages) {
      // Map each navigator locale to the corresponding Locale enum value
      switch (locale.toLowerCase().split("-")[0]) {
        case "en":
          return Locale.En;
        case "fr":
          return Locale.Fr;
        case "ht":
          return Locale.Ht;
        case "zh":
          return Locale.ZhCn;
        case "ru":
          return Locale.Ru;
        case "es":
          return Locale.Es;
        case "km":
          return Locale.KmKh;
        case "uk":
          return Locale.Uk;
        case "vi":
          return Locale.Vi;
        default:
          continue; // Move to the next locale if no match
      }
    }

    return null;
  }, []);

  useEffect(() => {
    if (!hasUserUpdatedLanguageForLogin) {
      const matchedLocale = mapNavigatorLocalesToEnum();

      if (matchedLocale) {
        setLocale(matchedLocale);
      } else {
        setLocale(Locale.En);
      }
    }
  }, [hasUserUpdatedLanguageForLogin, setLocale, mapNavigatorLocalesToEnum]);

  return useMemo(() => ({ localeName, setHasUserUpdatedLanguageForLogin }), [
    localeName,
    setHasUserUpdatedLanguageForLogin,
  ]);
}
