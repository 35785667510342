import { ApolloProvider, ServerError } from "@apollo/client";
import Amplify from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import { v4 } from "uuid";

import {
  createApolloClient,
  enhanceFetchBreadcrumbs,
} from "@rewards-web/shared/lib/apollo-client";
import { delay } from "@rewards-web/shared/lib/delay";
import { openedFromHomeScreen } from "@rewards-web/shared/lib/opened-from-homescreen";
import { AnalyticsProvider } from "@rewards-web/shared/modules/analytics";
import {
  AppErrorBoundary,
  initializeSentry,
  reportError,
} from "@rewards-web/shared/modules/error";
import {
  createLaunchDarklyFactory,
  LaunchDarklyFeatureFlagProvider,
} from "@rewards-web/shared/modules/feature-flag";
import { LocaleProvider } from "@rewards-web/shared/modules/formatter";
import { MaintenanceModeWrapper } from "@rewards-web/shared/modules/maintenance-mode";
import { SnackbarProvider } from "@rewards-web/shared/modules/snackbar";
import { StyleProviders } from "@rewards-web/shared/style/global-styles";

import { EmbedSourceWrapper } from "./embed-source-wrapper";
import { getLocaleMessages } from "./get-locale-messages";
import { AppRoutes } from "./pages/routes";
import { AddToHomeScreenProvider } from "./shared/add-to-home-screen";
import {
  AuthProvider,
  AuthStatus,
  getCognitoAuthStorage,
  getAccessToken,
  signOut,
} from "./shared/modules/auth";
import { getUserIdFromAccessToken } from "./shared/modules/auth/lib";
import { PointBalanceProvider } from "./shared/modules/point-balance";
import {
  SelectedTenantProvider,
  selectedTenant,
} from "./shared/modules/tenant-selector";
import { OnboardingTourToolipProvider } from "./shared/onboarding-tour-tooltip/context";
import { typePolicies } from "./shared/type-policies";
import { rewardsAppTheme } from "./theme";
import { TokenAuthWrapper } from "./token-auth-wrapper";

// add event listeners to track app background/foreground
// using visibility change instead of focus because it's more accurate for mobile
document.addEventListener("visibilitychange", () => {
  if (window.analytics) {
    const visible = !document.hidden;
    window.analytics.track(visible ? "Window visible" : "Window not visible", {
      openedFromHomescreen: openedFromHomeScreen(),
    });
  }
});

initializeSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN!,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT!,
  transformBreadcrumb: enhanceFetchBreadcrumbs,
  allowUrls: [
    "https://rewards.caribou.care",
    "https://caribourewards.ca",
    /https?:\/\/((rewards(-\w+)?)\.)?quala\.app/,
  ],
});

/**
 * This is now legacy.
 *
 * We previously utilized cognito to manage our authentication tokens.
 * While we no longer generate new tokens via cognito, we need to
 * continue to initialize amplify in order to support older sessions
 * so the token can be continually refreshed (and the user can stay logged in).
 */
Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    authenticationFlowType: "CUSTOM_AUTH",
    mandatorySignIn: true,
    storage: getCognitoAuthStorage(),
  },
  Analytics: {
    disabled: true,
  },
});

const apolloClient = createApolloClient({
  graphQLURI: process.env.REACT_APP_GRAPHQL_URL!,
  appNameHeaderValue: "CARIBOU_REWARDS_APP",
  typePolicies,
  isAuthenticated: () => Promise.resolve(AuthStatus.isAuthenticated()),
  getAccessToken,
  getHeaders: () => {
    const headers: Record<string, string> = {};

    if (selectedTenant.id) {
      headers["x-selected-tenant-id"] = String(selectedTenant.id) ?? undefined;
    }

    return headers;
  },
  onError: (error) => {
    if (
      error.networkError?.name === "ServerError" &&
      (error.networkError as ServerError).statusCode === 401 &&
      (error.networkError as ServerError).result?.message === "Token is expired"
    ) {
      handleSessionExpired();
    } else if (
      error.networkError?.name === "ServerError" &&
      (error.networkError as ServerError).statusCode === 401 &&
      (error.networkError as ServerError).result?.message ===
        "User is deactivated"
    ) {
      analytics?.track("User logged out due to deactivation");

      // This is forcing the the user to be redirected to the deactivated page on failed login attempt due to deactivation
      delay(1000) // wait for segment event to track before navigation
        // user should not be able to access the system anymore -- log them out and redirect them to /deactivated screen
        .then(() => signOut())
        .then(() => {
          window.location.href = "/deactivated";
        });
    } else if (
      error.graphQLErrors?.some((error) => error.message === "Not signed in") ||
      (error.networkError?.name === "ServerError" &&
        (error.networkError as ServerError).statusCode === 401)
    ) {
      // explicitly sign out and redirect to the login page
      // this shouldn't happen in practice
      signOut().then(() => {
        window.location.href = "/login?unexpected_logout_error=true";
      });
    }
  },
});

let handlingSessionExpired = false;

async function handleSessionExpired() {
  if (handlingSessionExpired) {
    return; // avoid running this twice in parallel, which can cause it to fail
  }

  handlingSessionExpired = true;

  try {
    const sessionExpirationId = v4();
    analytics?.track("User logged out due to session expired", {
      sessionExpirationId,
    });
    const userId = getUserIdFromAccessToken(await getAccessToken());
    await signOut();

    window.location.href = `/reauth?${new URLSearchParams({
      sessionExpirationId,
      userId,
    })}`;
  } catch (error) {
    reportError(error);
    await signOut();
    window.location.href = "/login?unexpected_logout_error=true";
  }
}

const launchDarklyFactory = process.env.REACT_APP_LAUNCH_DARKLY_KEY
  ? createLaunchDarklyFactory({
      apiKey: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
    })
  : undefined;

export function App(): JSX.Element {
  return (
    <StyleProviders theme={rewardsAppTheme} renderCssBaseline>
      <AppErrorBoundary>
        <LocaleProvider getLocaleMessages={getLocaleMessages}>
          <LaunchDarklyFeatureFlagProvider
            launchDarklyFactory={launchDarklyFactory}
            appName="rewards_app"
          >
            <AnalyticsProvider>
              <MaintenanceModeWrapper>
                <ApolloProvider client={apolloClient}>
                  <SelectedTenantProvider>
                    <OnboardingTourToolipProvider>
                      <SnackbarProvider adjustedForMobile>
                        <BrowserRouter>
                          <EmbedSourceWrapper>
                            <AddToHomeScreenProvider>
                              <AuthProvider>
                                <TokenAuthWrapper>
                                  <PointBalanceProvider>
                                    <AppRoutes />
                                  </PointBalanceProvider>
                                </TokenAuthWrapper>
                              </AuthProvider>
                            </AddToHomeScreenProvider>
                          </EmbedSourceWrapper>
                        </BrowserRouter>
                      </SnackbarProvider>
                    </OnboardingTourToolipProvider>
                  </SelectedTenantProvider>
                </ApolloProvider>
              </MaintenanceModeWrapper>
            </AnalyticsProvider>
          </LaunchDarklyFeatureFlagProvider>
        </LocaleProvider>
      </AppErrorBoundary>
    </StyleProviders>
  );
}
