import {
  BulkAcknowledgeMyRewardOfferCompletionsData,
  RewardOfferCompletion,
} from "@rewards-web/shared/graphql-types";

export function sumUnacknowledgedPunchCardReward(
  punchCards: Array<{
    completions: Array<
      Pick<
        RewardOfferCompletion,
        "acknowledged" | "pointValue" | "numDrawTickets"
      >
    >;
  }>,
  getReward: (
    offer: Pick<RewardOfferCompletion, "numDrawTickets" | "pointValue">
  ) => number
) {
  return punchCards.reduce(
    (prev, punchCard) =>
      prev +
      punchCard.completions.reduce((prev, completion) => {
        if (completion.acknowledged) {
          return 0; // reset, since we only care about awarded completions after the most recent acknowledgement
        }

        const reward = getReward(completion);

        if (reward) {
          return prev + reward;
        }

        return prev;
      }, 0),
    0
  );
}

export function getPunchCardsToAcknowledge(
  punchCards: Array<{
    id: string;
    completions: Array<
      Pick<RewardOfferCompletion, "id" | "acknowledged" | "awarded">
    >;
  }>
): Array<BulkAcknowledgeMyRewardOfferCompletionsData> {
  return punchCards.map((punchCard) => ({
    offerId: punchCard.id,
    completionIds: punchCard.completions.reduce<string[]>(
      (prev, completion) => {
        if (completion.acknowledged) {
          return []; // reset, since we only care about awarded completions after the most recent acknowledgement
        }

        if (completion.awarded) {
          return [...prev, completion.id];
        }

        return prev;
      },
      []
    ),
  }));
}
