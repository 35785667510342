/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { Modal } from "../../modal";

interface RewardOfferModalProps {
  open: boolean;
  onClose(): void;
  title: string;
  contentsHTML: string;
}

export function RewardOfferModal({
  open,
  onClose,
  title,
  contentsHTML,
}: RewardOfferModalProps) {
  const { formatMessage } = useFormatters();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      maxWidth="500px"
      details={
        <div
          css={(theme: AppTheme) => css`
            p {
              margin-top: 0px;
              margin-bottom: 0px;
              &:not(:last-child) {
                margin-bottom: ${theme.spacing(1)};
              }
            }
          `}
          dangerouslySetInnerHTML={{ __html: contentsHTML }}
        />
      }
    >
      <Button
        label={formatMessage({
          description:
            "Rewards page > Reward offer card > modal close button label",
          defaultMessage: "Close",
        })}
        color="secondary"
        onClick={onClose}
      />
    </Modal>
  );
}
