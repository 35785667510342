/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { last, sortBy } from "lodash";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { usePointBalance } from "../../../../shared/modules/point-balance";
import { TripleStarAnimation } from "../../../../shared/triple-star-animation";
import { RecognitionPointsAnnouncement } from "../types";
import { useClaimRecognitionPointsMutation } from "./claim-recognition-points.generated";

type ClaimRecognitionModalProps = {
  claimableRecognitionPoints: RecognitionPointsAnnouncement["claimableRecognitionPoints"];
  closeModal: () => void;
};
export function ClaimRecognitionModal({
  claimableRecognitionPoints,
  closeModal,
}: ClaimRecognitionModalProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const snackbar = useSnackbar();
  const pointsBalance = usePointBalance();

  const [
    claimRecognitionPoints,
    { loading: claimingRecognitionPoints },
  ] = useClaimRecognitionPointsMutation();

  const handleClaimRecognitionPoints = async () => {
    try {
      const recognitionPointsIds = claimableRecognitionPoints.map(
        (bonusPoints) => bonusPoints.id
      );
      await claimRecognitionPoints({
        variables: { recognitionPointsIds },
      });
      snackbar.show({
        severity: "success",
        message: formatMessage({
          description: "Claim bonus points modal > claimed success snackbar",
          defaultMessage: "Points claimed",
        }),
      });
      track("Claimed bonus points", { bonusPointsIds: recognitionPointsIds });

      pointsBalance.addLocalPoints(
        claimableRecognitionPoints.reduce(
          (prev, recognitionPoints) => prev + recognitionPoints.pointValue,
          0
        )
      );
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Claim bonus points modal > claim error snackbar",
          defaultMessage: "Something went wrong",
        }),
      });
      // TODO consider what happens if the user keeps seeing this announcement
      // over and over because it keeps failing ...
    } finally {
      closeModal();
    }
  };

  const mostRecentBonusPoints = last(
    sortBy(claimableRecognitionPoints, (item) => item.sentAt)
  )!;

  return (
    <ResponsiveDialog
      backgroundColor="white"
      maxWidth="350px"
      open
      mobileType="dialog"
    >
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(2)} ${theme.spacing(3)};
          ${theme.breakpoints.up("sm")} {
            padding: ${theme.spacing(3)} ${theme.spacing(5)};
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        `}
      >
        <TripleStarAnimation
          css={(appTheme: AppTheme) =>
            css`
              margin-bottom: ${appTheme.spacing(2)};
            `
          }
        />
        <Typography variant="h3">
          <FormattedMessage
            defaultMessage="Congratulations!"
            description="Claim bonus points modal > title"
          />
        </Typography>
        <Typography
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(4.25)};
          `}
          variant="body"
        >
          <FormattedMessage
            description="Claim bonus points modal > explaination from"
            defaultMessage="{from} has given you <bold>{points, number} {points, plural, one {point} other {points}}</bold>"
            values={{
              from: mostRecentBonusPoints.from,
              points: mostRecentBonusPoints.pointValue,
              bold: (nodes) => (
                <Typography variant="subtitle">{nodes}</Typography>
              ),
            }}
          />
        </Typography>

        <div
          css={(theme: AppTheme) => css`
            border-radius: ${theme.shape.borderRadius}px;
            padding: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(4.25)};
            background-color: ${theme.palette.grey[100]};
            margin-top: ${theme.spacing(1.75)};
          `}
        >
          <Typography
            css={css`
              font-size: 1.05rem;
              text-align: center;
            `}
            variant="footnote"
          >
            {mostRecentBonusPoints.message}
          </Typography>
        </div>
        <Button
          loading={claimingRecognitionPoints}
          label={formatMessage({
            description: "Claim bonus points modal > claim button",
            defaultMessage: "Claim reward",
          })}
          size="large"
          color="secondary"
          onClick={handleClaimRecognitionPoints}
        />
      </div>
    </ResponsiveDialog>
  );
}
