import { Locale } from "@rewards-web/shared/graphql-types";

export function getLocaleMessages(locale: Locale): Promise<any> {
  switch (locale) {
    case Locale.En:
      return import("./lang/en.json");
    case Locale.Es:
      return import("./lang/es.json");
    case Locale.Fr:
      return import("./lang/fr.json");
    case Locale.Ht:
      return import("./lang/ht.json");
    case Locale.Ru:
      return import("./lang/ru.json");
    case Locale.ZhCn:
      return import("./lang/zh-CN.json");
    case Locale.KmKh:
      return import("./lang/km-KH.json");
    case Locale.Uk:
      return import("./lang/uk.json");
    case Locale.Vi:
      return import("./lang/vi.json");
    default:
      throw new Error(`Rewards app does not support locale ${locale}`);
  }
}
