/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { startCase } from "lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../../../../shared/scroll-to-top-on-mount";
import { getRewardOfferGraphicUrl } from "../../../../rewards/rewards-offer-card/lib";
import { DrawDetailsCard } from "../../../shared/draw-details-card";
import { DrawDetailsCardV2 } from "../../../shared/draw-details-card/draw-details-card-v2";
import { DrawTicketCount } from "../../../shared/draw-ticket-count";
import { getDaysUntilDrawClose } from "../../../shared/lib/get-days-until-draw-close";
import { DrawWinnersAnnouncedCard } from "../draw-winners-announced-card";
import { OrganizationRunDrawPageDataQuery } from "../organization-run-draw-page-data.generated";
import { useDrawPageTicketCountDataQuery } from "./draw-page-ticket-count-data.generated";
import { DrawWayToEarnCard } from "./draw-way-to-earn-card";
import shareJobMegaphoneImageUrl from "./share-job-megaphone.png";

interface DrawOpenStateProps {
  drawId: string;
  scheduledCloseDate: Date;
  prizeStructure: NonNullable<
    OrganizationRunDrawPageDataQuery["currentDraw"]
  >["prizeStructure"];
  myRewardOffers: NonNullable<
    OrganizationRunDrawPageDataQuery["myRewardOffers"]
  >;
  lastDraw: OrganizationRunDrawPageDataQuery["lastDraw"];
  announcementVideoUrl?: string;
}

export function DrawOpenState({
  drawId,
  scheduledCloseDate,
  prizeStructure,
  myRewardOffers,
  lastDraw,
  announcementVideoUrl,
}: DrawOpenStateProps) {
  const { formatMessage } = useFormatters();

  let unexpectedDrawStructureType: string | undefined = undefined;

  const drawOffersWithTicketAmounts = myRewardOffers
    .map((offer) => ({
      numDrawTickets: offer.nextReward?.numDrawTickets,
      offer,
    }))
    .filter(
      (
        item
      ): item is {
        numDrawTickets: number;
        offer: NonNullable<
          OrganizationRunDrawPageDataQuery["myRewardOffers"]
        >[number];
      } => typeof item.numDrawTickets === "number" && item.numDrawTickets > 0
    );

  useEffect(() => {
    if (unexpectedDrawStructureType) {
      reportError(
        new Error(`Unexpected draw structure ${unexpectedDrawStructureType}`)
      );
    }
  }, [unexpectedDrawStructureType]);

  const ticketCountQuery = useDrawPageTicketCountDataQuery({
    variables: {
      drawId,
    },
    onError: reportError,
  });

  const ticketCount = ticketCountQuery.data
    ? ticketCountQuery.data.countMyTicketsInDraw.numTickets
    : undefined;

  const daysUntilDrawClose = getDaysUntilDrawClose(scheduledCloseDate);

  const track = useTrack();
  useEffect(() => {
    if (typeof ticketCount === "number") {
      track("Viewed open draw screen", {
        drawId,
        ticketCount,
        prizeStructure,
        daysUntilDrawClose,
      });
    }
  }, [track, drawId, ticketCount, prizeStructure, daysUntilDrawClose]);

  return (
    <div
      css={(theme: AppTheme) => css`
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <ScrollToTopOnMount />
      <div
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.primary.main};
          color: white;
          padding-bottom: ${theme.spacing(2)};
        `}
      >
        <PageContentContainer>
          <DrawTicketCount ticketCount={ticketCount} />
          {prizeStructure.__typename ===
          "DrawPrizeStructureSinglePrizeMultipleWinners" ? (
            <DrawDetailsCard
              prizeStructure={prizeStructure}
              drawEndsAt={scheduledCloseDate}
              announcementVideoUrl={announcementVideoUrl}
            />
          ) : (
            <DrawDetailsCardV2
              prizeStructure={prizeStructure}
              drawEndsAt={scheduledCloseDate}
              announcementVideoUrl={announcementVideoUrl}
            />
          )}
        </PageContentContainer>
      </div>
      <div
        css={(theme: AppTheme) => css`
          padding-bottom: ${theme.spacing(5)};
        `}
      >
        <PageContentContainer
          css={(theme: AppTheme) => css`
            padding-top: ${theme.spacing(3)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              justify-content: space-between;
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Typography variant="h6" component="h2" color="#2C393F">
              <FormattedMessage
                defaultMessage="Earn tickets"
                description="Draw page > draw active state > earn tickets title"
              />
            </Typography>
            <Typography
              variant="body"
              component={Link}
              to="/draw/how-it-works"
              color="#2C393F"
              type="button"
              onClick={() => {
                track("Clicked draw how it works");
              }}
              css={css`
                border: none;
                background-color: transparent;
                text-decoration: none;
                display: flex;
                align-items: center;
              `}
            >
              <FormattedMessage
                defaultMessage="How It Works"
                description="Draw page > draw active state > how it works button label"
              />
              <span
                css={(theme: AppTheme) => css`
                  margin-left: ${theme.spacing(1)};
                  vertical-align: middle;
                  line-height: 14px;
                  font-size: 14px;
                  display: inline-block;
                  border: 2px solid #2c393f;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  font-weight: 600;
                  padding-left: 3px;
                `}
              >
                ?
              </span>
            </Typography>
          </div>
          <div
            css={(theme: AppTheme) => css`
              & > *:not(:last-child) {
                margin-bottom: ${theme.spacing(2)};
              }
            `}
          >
            {ticketCountQuery.data?.getMyRewardsUser.referralsEnabled && (
              <DrawWayToEarnCard
                analyticsProperties={{
                  type: "share_job",
                }}
                titleText={formatMessage({
                  defaultMessage: "Share a job",
                  description:
                    "Draw page > draw active state > ways to earn > share a job label",
                })}
                bodyText={formatMessage(
                  {
                    defaultMessage:
                      "{num_tickets, number} {num_tickets, plural, one {ticket} other {tickets}} per job share",
                    description:
                      "Draw page > draw active state > ways to earn > num tickets per job share",
                  },
                  {
                    num_tickets: 1,
                  }
                )}
                graphic={
                  <img
                    src={shareJobMegaphoneImageUrl}
                    alt={formatMessage({
                      defaultMessage: "Megaphone",
                      description:
                        "Draw page > draw active state > ways to earn > share a job image alt",
                    })}
                  />
                }
                buttonLabel={formatMessage({
                  defaultMessage: "Share",
                  description:
                    "Draw page > draw active state > ways to earn > share job button",
                })}
                action={{
                  type: "internal_link",

                  linkTo: "/jobs",
                  linkState: { from: "draw" },
                }}
              />
            )}
            {drawOffersWithTicketAmounts.map(({ numDrawTickets, offer }) => (
              <DrawWayToEarnCard
                key={offer.id}
                analyticsProperties={{
                  type: "reward_offer",
                  rewardOfferId: offer.id,
                }}
                titleText={offer.cardTitle}
                bodyText={formatMessage(
                  {
                    defaultMessage:
                      "{num_tickets} {num_tickets, plural, one {ticket} other {tickets}}",
                    description:
                      "Draw page > draw active state > ways to earn > reward offer body generic fallback",
                  },
                  {
                    num_tickets: numDrawTickets,
                  }
                )}
                graphic={
                  <img
                    src={getRewardOfferGraphicUrl(offer.cardGraphicKey)}
                    alt={startCase(offer.cardGraphicKey)}
                  />
                }
                buttonLabel={offer.cardButtonLabel}
                action={(() => {
                  switch (offer.action.__typename) {
                    case "RewardOfferModalAction":
                      return {
                        type: "modal",
                        modalContentsHtml: offer.action.modalContentsHtml,
                      };
                    case "RewardOfferExternalLinkAction":
                      return { type: "external_link", url: offer.action.url };
                    case "RewardOfferInAppLinkAction": {
                      return {
                        type: "internal_link",
                        linkTo: offer.action.linkPath,
                      };
                    }
                    default: {
                      assertNever(
                        offer.action,
                        `Unexpected action type ${
                          (offer.action as NonNullable<
                            OrganizationRunDrawPageDataQuery["myRewardOffers"]
                          >[number]["action"]).__typename
                        }`
                      );
                    }
                  }
                })()}
              />
            ))}
          </div>
          <div
            css={(theme: AppTheme) => css`
              margin-top: ${lastDraw?.winnersAnnounced ? theme.spacing(4) : 0};
            `}
          >
            <DrawWinnersAnnouncedCard lastDraw={lastDraw} />
          </div>
        </PageContentContainer>
      </div>
    </div>
  );
}
