import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RedeemPointsMutationVariables = Types.Exact<{
  amount: Types.Scalars['Int'];
  redemptionMethod?: Types.Maybe<Types.RedemptionMethod>;
}>;


export type RedeemPointsMutation = (
  { __typename?: 'Mutation' }
  & { redeemPoints: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id'>
  ) }
);


export const RedeemPointsDocument = gql`
    mutation RedeemPoints($amount: Int!, $redemptionMethod: RedemptionMethod) {
  redeemPoints(amount: $amount, redemptionMethod: $redemptionMethod) {
    id
  }
}
    `;
export type RedeemPointsMutationFn = Apollo.MutationFunction<RedeemPointsMutation, RedeemPointsMutationVariables>;

/**
 * __useRedeemPointsMutation__
 *
 * To run a mutation, you first call `useRedeemPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPointsMutation, { data, loading, error }] = useRedeemPointsMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      redemptionMethod: // value for 'redemptionMethod'
 *   },
 * });
 */
export function useRedeemPointsMutation(baseOptions?: Apollo.MutationHookOptions<RedeemPointsMutation, RedeemPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemPointsMutation, RedeemPointsMutationVariables>(RedeemPointsDocument, options);
      }
export type RedeemPointsMutationHookResult = ReturnType<typeof useRedeemPointsMutation>;
export type RedeemPointsMutationResult = Apollo.MutationResult<RedeemPointsMutation>;
export type RedeemPointsMutationOptions = Apollo.BaseMutationOptions<RedeemPointsMutation, RedeemPointsMutationVariables>;