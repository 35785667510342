/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../../shared/points-icon";

export function RedemptionsEmptyState() {
  const { formatMessage } = useFormatters();
  return (
    <Card
      css={(appTheme: AppTheme) =>
        css`
          margin-top: ${appTheme.spacing(2.5)};
          padding: ${appTheme.spacing(3)};
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 2px solid ${appTheme.palette.grey[200]};
          text-align: center;
        `
      }
      variant="flat"
      borderRadius={10}
    >
      <PointsIcon
        css={css`
          width: 56px;
          filter: grayscale(100%);
        `}
      />
      <Typography
        css={(appTheme: AppTheme) => css`
          margin-top: ${appTheme.spacing(2.5)};
        `}
        variant="h4"
      >
        {formatMessage({
          defaultMessage: "You have not redeemed any points yet",
          description: "Past redemptions > empty state title",
        })}
      </Typography>
      <Typography
        variant="body"
        css={(appTheme: AppTheme) => css`
          margin-top: ${appTheme.spacing(0.75)};
        `}
      >
        {formatMessage({
          defaultMessage: "Once you redeem, you'll see your history here!",
          description: "Past redemptions > empty state description",
        })}
      </Typography>
    </Card>
  );
}
