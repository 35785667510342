/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
} from "date-fns";
import { last } from "lodash";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  FormattedNumber,
  mapGQLLocaleToStandardLocale,
  useFormatters,
  useLocale,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ProgressBar } from "../../../../shared/progress-bar";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import { TimeBasedOfferCard } from "../time-based-offer-card";
import clockInImageUrl from "./clock-in.png";
import { useEvvCompletionRateOfferCardDataQuery } from "./evv-completion-rate-offer-card-data.generated";
import targetImageUrl from "./target.png";

export interface EVVCompletionRateOfferCardProps {
  offer: RewardsPageDataQuery["listMyRewardOffers"][0];
}

export function EVVCompletionRateOfferCard({
  offer,
}: EVVCompletionRateOfferCardProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { locale } = useLocale();
  const { formatMessage } = useFormatters();

  const query = useEvvCompletionRateOfferCardDataQuery({
    variables: {
      evvCompletionRateOfferId: offer.externalId?.split(":")[1]!,
    },
    onError: reportError,
  });

  const ref = useRef<HTMLDivElement>(null);

  const evvCompletionRateOffer = query.data?.evvCompletionRateOffer;

  const targetLevel = evvCompletionRateOffer?.targetLevel;

  const adjustedTargetLevel = evvCompletionRateOffer?.adjustedTargetLevel;

  const numDaysLeft = evvCompletionRateOffer?.rangeEnd
    ? Math.max(
        differenceInDays(new Date(evvCompletionRateOffer.rangeEnd), new Date()),
        0
      )
    : null;

  const trackingProperties = useMemo(
    () => ({
      offerCardTitle: offer.cardTitle,
      offerCardButtonLabel: offer.cardButtonLabel,
      offerId: offer.id,
      offerCompleted: offer.completed,
      evvCompletionRateOffer: evvCompletionRateOffer?.id,
      adjustedTargetLevel,
      trackedEVVCompletionPercentage:
        evvCompletionRateOffer?.trackedEVVCompletionPercentage,
      numEVVCompletionRateDaysLeft: numDaysLeft,
    }),
    [offer, evvCompletionRateOffer, numDaysLeft, adjustedTargetLevel]
  );

  const onScreen = useOnScreen(ref, { threshold: 0.5 });
  const track = useTrack();

  useEffect(() => {
    if (onScreen && evvCompletionRateOffer) {
      track(
        "Viewed rewards page evv completion rate offer card",
        trackingProperties
      );
    }
  }, [track, onScreen, trackingProperties, evvCompletionRateOffer]);

  if (query.error || (query.data && !query.data.evvCompletionRateOffer)) {
    return null;
  }

  const inProgress = !evvCompletionRateOffer?.ended;

  const highestLevel = last(evvCompletionRateOffer?.levels);

  const highestFinishedLevel = last(
    evvCompletionRateOffer?.levels.filter((level) => level.finished)
  );

  const numDrawTicketsForHighestFinishedLevel =
    highestFinishedLevel?.numDrawTickets ?? 0;

  const text = ((): { title: ReactNode; description: ReactNode } => {
    if (!evvCompletionRateOffer) {
      return { title: "", description: "" };
    }

    if (inProgress) {
      if (evvCompletionRateOffer.numCountedVisits === 0) {
        return {
          title: formatMessage({
            description:
              "Rewards page > evv completion rate > no recorded visits > title",
            defaultMessage: "New week, let's go!",
          }),
          description: formatMessage({
            description:
              "Rewards page > evv completion rate > no recorded visits > description",
            defaultMessage:
              "Clock in and out according to company policy for a chance to win exciting cash prizes",
          }),
        };
      }

      if (!adjustedTargetLevel!.finished) {
        return {
          title: formatMessage({
            description:
              "Rewards page > evv completion rate > in progress > below target > title",
            defaultMessage: "There is still time!",
          }),
          description: formatMessage({
            description:
              "Rewards page > evv completion rate > in progress > below target > description",
            defaultMessage:
              "Remember to clock in and out within 7 minutes of start and end time via client's home phone or mobile app",
          }),
        };
      }

      if (highestFinishedLevel!.evvCompletionPercentage < 100) {
        return {
          title: formatMessage({
            description:
              "Rewards page > evv completion rate > in progress > reached some level not 100 > title",
            defaultMessage: "You're rocking it!",
          }),
          description: formatMessage(
            {
              description:
                "Rewards page > evv completion rate > in progress > reached some level not 100 > description",
              defaultMessage:
                "You're on track to earn {num_tickets, number} raffle tickets.",
            },
            { num_tickets: highestFinishedLevel!.numDrawTickets }
          ),
        };
      }

      // at 100% completion
      return {
        title: formatMessage({
          description:
            "Rewards page > evv completion rate > in progress > at 100 completion > title",
          defaultMessage: "Wow! You're on a roll!",
        }),
        description: formatMessage(
          {
            description:
              "Rewards page > evv completion rate > in progress > at 100 completion > description",
            defaultMessage:
              "Maintain your perfect streak and you'll secure {num_tickets, number} more chances to win a cash prize",
          },
          { num_tickets: highestFinishedLevel!.numDrawTickets }
        ),
      };
    }

    // offer is ended
    if (evvCompletionRateOffer.numCountedVisits === 0) {
      return {
        title: formatMessage({
          description:
            "Rewards page > evv completion rate > ended > no visits > title",
          defaultMessage: "No shifts worked",
        }),
        description: formatMessage(
          {
            description:
              "Rewards page > evv completion rate > ended > no visits > description",
            defaultMessage:
              "Ensure you're clocking in and out from client's home phone or mobile app to reach your goal and earn rewards.",
          },
          {
            bold: (nodes) => <strong>{nodes}</strong>,
          }
        ),
      };
    }

    if (!adjustedTargetLevel!.finished) {
      return {
        title: formatMessage({
          description:
            "Rewards page > evv completion rate > ended > below target > title",
          defaultMessage: "Not quite there...",
        }),
        description: formatMessage(
          {
            description:
              "Rewards page > evv completion rate > ended > below target > description",
            defaultMessage:
              "<bold>Tip</bold>: Ensure you're clocking in and out from client's home phone or mobile app to reach your goal and earn rewards.",
          },
          {
            bold: (nodes) => <strong>{nodes}</strong>,
          }
        ),
      };
    }

    return {
      title: formatMessage({
        description:
          "Rewards page > evv completion rate > ended > reached target > title",
        defaultMessage: "You nailed this one!",
      }),
      description:
        highestFinishedLevel!.evvCompletionPercentage < 100
          ? formatMessage(
              {
                description:
                  "Rewards page > evv completion rate > ended > reached target > description",
                defaultMessage:
                  "With {num_tickets, number} tickets, you've got {num_tickets, number} more chances to win a cash prize. Can you aim for {percentage_100, number, ::percent} next time to earn more tickets?",
              },
              {
                num_tickets: highestFinishedLevel!.numDrawTickets,
                percentage_100: 1,
              }
            )
          : formatMessage(
              {
                description:
                  "Rewards page > evv completion rate > ended > reached target 100 > description",
                defaultMessage:
                  "With {num_tickets, number} tickets, you've got {num_tickets, number} more chances to win an exciting cash prize. Winners will be announced soon.",
              },
              {
                num_tickets: highestFinishedLevel!.numDrawTickets,
              }
            ),
    };
  })();

  return (
    <>
      <ResponsiveDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="500px"
        backgroundColor="#FFFFFF"
      >
        <button
          css={(theme: AppTheme) => css`
            position: absolute;
            top: ${theme.spacing(2)};
            right: ${theme.spacing(2)};
            border-radius: 50%;
            border-width: 0px;
            height: 27px;
            width: 27px;
            background-color: #e0e0e0;
            padding: 0;
            cursor: pointer;
          `}
          onClick={() => setModalOpen(false)}
        >
          <CloseOutlinedIcon
            css={css`
              padding-top: 3px;
              font-size: 1.6em;
              color: #757575;
            `}
          />
        </button>
        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2)};
          `}
        >
          <Typography
            variant="h3"
            align="center"
            css={(theme: AppTheme) =>
              css`
                margin: ${theme.spacing(6)} ${theme.spacing(2)}
                  ${theme.spacing(4)};
              `
            }
          >
            {formatMessage({
              description: "Rewards page > evv completion rate > modal > title",
              defaultMessage: "Attendance compliance",
            })}
          </Typography>

          <img
            src={clockInImageUrl}
            alt="Clock"
            css={css`
              display: block;
              margin: 0 auto;
              width: 104px;
            `}
          />
          <ul>
            <li>
              <Typography variant="body" color="textPrimary">
                <FormattedMessage
                  description="Rewards page > evv completion rate > modal > clock in instructions"
                  defaultMessage="<bold>Clock in and out</bold> within 7 minutes of start and end time using your client's home phone or your HHA mobile app while at the client’s location."
                  values={{ bold: (nodes) => <strong>{nodes}</strong> }}
                />
              </Typography>
            </li>
            {evvCompletionRateOffer && (
              <li>
                <Typography variant="body" color="textPrimary">
                  {evvCompletionRateOffer.levels.length === 1 ? (
                    <FormattedMessage
                      description="Rewards page > evv completion rate > modal > reward single level"
                      defaultMessage="<bold>Earn {num_tickets, number} raffle tickets</bold> when you reach {target_completion_percentage, number, ::percent} compliance."
                      values={{
                        bold: (nodes) => <strong>{nodes}</strong>,
                        num_tickets: targetLevel!.numDrawTickets,
                        target_completion_percentage:
                          targetLevel!.evvCompletionPercentage / 100,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      description="Rewards page > evv completion rate > modal > reward single level"
                      defaultMessage="<bold>Earn {min_num_tickets, number} raffle tickets</bold> when you reach {min_completion_percentage, number, ::percent}, or <bold>{max_num_tickets, number} tickets</bold> for {max_completion_percentage, number, ::percent} compliance."
                      values={{
                        bold: (nodes) => <strong>{nodes}</strong>,
                        min_num_tickets:
                          evvCompletionRateOffer.levels[0].numDrawTickets,
                        min_completion_percentage:
                          evvCompletionRateOffer.levels[0]
                            .evvCompletionPercentage / 100,
                        max_num_tickets: highestLevel!.numDrawTickets,
                        max_completion_percentage:
                          highestLevel!.evvCompletionPercentage / 100,
                      }}
                    />
                  )}
                </Typography>
              </li>
            )}
            <li>
              <Typography variant="body" color="textPrimary">
                <FormattedMessage
                  description="Rewards page > evv completion rate > modal > draw info"
                  defaultMessage="<bold>Winners will be announced</bold> in the <bold>Draws</bold> tab of this app."
                  values={{ bold: (nodes) => <strong>{nodes}</strong> }}
                />
              </Typography>
            </li>
          </ul>
          <div
            css={(theme: AppTheme) => css`
              border: 2px solid ${theme.palette.grey[200]};
              border-radius: 10px;
              padding: ${theme.spacing(1.5)} ${theme.spacing(2.5)};
              max-width: 400px;
              margin: 0 auto;
              margin-top: ${theme.spacing(3)};
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            <Typography
              variant="subtitle"
              color="textPrimary"
              align="center"
              marginBottom={1}
            >
              <FontAwesomeIcon icon={faInfoCircle} size="1x" />{" "}
              <FormattedMessage
                description="Rewards page > evv completion rate > modal > have questions > title"
                defaultMessage="Have questions?"
              />
            </Typography>
            <Typography variant="body" color="textPrimary" align="center">
              <FormattedMessage
                description="Rewards page > evv completion rate > modal > have questions > contact link"
                defaultMessage="Contact <link>{contact_email}</link>"
                values={{
                  contact_email: "help@caribou.care",
                  link: (nodes) => (
                    <a
                      href="mailto:help@caribou.care"
                      css={(theme: AppTheme) => css`
                        cursor: pointer;
                        color: ${theme.palette.primary.main};
                        text-decoration: none;
                      `}
                    >
                      {nodes}
                    </a>
                  ),
                }}
              />
            </Typography>
          </div>

          <Button
            label={formatMessage({
              description:
                "Rewards page > evv completion rate > modal > close button label",
              defaultMessage: "Close",
            })}
            color="secondary"
            onClick={() => setModalOpen(false)}
          />
        </div>
      </ResponsiveDialog>
      <TimeBasedOfferCard
        ref={ref}
        onClick={() => {
          track(
            "Clicked rewards page evv completion rate offer card",
            trackingProperties
          );
          setModalOpen(true);
        }}
        offer={offer}
        showSkeleton={!evvCompletionRateOffer}
        hideOfferRewardIcon={
          !inProgress && numDrawTicketsForHighestFinishedLevel === 0
        }
        titleText={(() => {
          if (!evvCompletionRateOffer) {
            return "";
          }

          if (inProgress) {
            return (
              <FormattedMessage
                description="Rewards page > evv completion rate > card title > in progress"
                defaultMessage="Your goal is {target_evv_percentage, number, ::percent}"
                values={{
                  target_evv_percentage:
                    targetLevel!.evvCompletionPercentage / 100,
                }}
              />
            );
          }

          if (highestFinishedLevel?.evvCompletionPercentage === 100) {
            return (
              <FormattedMessage
                description="Rewards page > evv completion rate > card title > ended goal 100 reached"
                defaultMessage="You reached {percentage_100, number, ::percent}!"
                values={{ percentage_100: 1 }}
              />
            );
          }

          return (
            <FormattedMessage
              description="Rewards page > evv completion rate > card title > ended"
              defaultMessage="Your goal was {target_evv_percentage, number, ::percent}"
              values={{
                target_evv_percentage:
                  targetLevel!.evvCompletionPercentage / 100,
              }}
            />
          );
        })()}
        rewardText={(() => {
          if (!evvCompletionRateOffer) {
            return "";
          }

          if (inProgress) {
            return formatMessage(
              {
                description:
                  "Rewards page > evv completion rate > card points text > in progress",
                defaultMessage:
                  "Earn up to {num_tickets, number} raffle tickets",
              },
              {
                num_tickets: highestLevel!.numDrawTickets,
              }
            );
          }

          if (numDrawTicketsForHighestFinishedLevel === 0) {
            return formatMessage({
              description:
                "Rewards page > evv completion rate > card points text > ended no tickets earned",
              defaultMessage: "No tickets earned",
            });
          }

          return formatMessage(
            {
              description:
                "Rewards page > evv completion rate > card points text > ended tickets earned",
              defaultMessage: "You earned {num_tickets, number} raffle tickets",
            },
            {
              num_tickets: numDrawTicketsForHighestFinishedLevel,
            }
          );
        })()}
        completed={!inProgress && numDrawTicketsForHighestFinishedLevel > 0}
        numDaysLeft={inProgress ? numDaysLeft : null}
        timeIconType="calendar"
        showTrophyIfTimesUpAndCompleted
        bodyContent={
          evvCompletionRateOffer && (
            <>
              <Typography
                color="textPrimary"
                variant="subtitle"
                textAlign="center"
              >
                {text.title}
              </Typography>
              <Typography
                color="grey.800"
                variant="footnote"
                textAlign="center"
              >
                {text.description}
              </Typography>
              <div
                css={(theme: AppTheme) => css`
                  position: relative;
                  display: flex;
                  align-items: center;
                  margin-top: ${theme.spacing(3)};
                `}
              >
                <ProgressBar
                  max={100}
                  value={Math.max(
                    evvCompletionRateOffer.trackedEVVCompletionPercentage ?? 0,
                    3
                  )}
                  height="14px"
                />
                <div
                  css={(theme: AppTheme) => css`
                    position: absolute;
                    background-color: ${theme.palette.tertiary.main};
                    border-radius: 1000px;
                    padding: ${theme.spacing(1)};
                    display: flex;
                    align-items: center;
                    left: calc(
                      ${adjustedTargetLevel!.evvCompletionPercentage}% -
                        ${adjustedTargetLevel!.evvCompletionPercentage * 0.7}px
                    );
                  `}
                >
                  <img
                    src={targetImageUrl}
                    alt="Target"
                    css={(appTheme: AppTheme) => css`
                      width: 16px;
                      margin-right: ${appTheme.spacing(0.75)};
                    `}
                  />
                  <Typography
                    color="white"
                    variant="caption"
                    textTransform="uppercase"
                  >
                    <FormattedNumber
                      value={adjustedTargetLevel!.evvCompletionPercentage}
                    />
                    %
                  </Typography>
                </div>
              </div>

              <div
                css={(theme: AppTheme) => css`
                  margin-top: ${theme.spacing(2)};
                `}
              >
                {evvCompletionRateOffer.numCountedVisits === 0 ? (
                  <Typography
                    color="grey.800"
                    variant="footnote"
                    textAlign="center"
                  >
                    <FormattedMessage
                      description="Rewards page > evv completion rate > footer > no shifts"
                      defaultMessage="No shifts recorded this week"
                    />
                  </Typography>
                ) : (
                  <Typography
                    color="grey.800"
                    variant="footnote"
                    textAlign="center"
                  >
                    <FormattedMessage
                      description="Rewards page > evv completion rate > footer > has shifts"
                      defaultMessage="<bold>{tracked_completion_percentage, number, ::percent}</bold> based on <bold>{num_shifts, number} shifts</bold>"
                      values={{
                        tracked_completion_percentage:
                          evvCompletionRateOffer.trackedEVVCompletionPercentage! /
                          100,
                        num_shifts: evvCompletionRateOffer.numCountedVisits,
                        bold: (nodes) => <strong>{nodes}</strong>,
                      }}
                    />
                  </Typography>
                )}
                {evvCompletionRateOffer.lastUpdatedAsOf && (
                  <Typography
                    color="grey.800"
                    variant="footnote"
                    textAlign="center"
                  >
                    <FormattedMessage
                      description="Rewards page > evv completion rate > footer > last updated as of"
                      defaultMessage="Last update: {last_updated_text}"
                      values={{
                        last_updated_text: formatRelativeTimeHoursI18n(
                          mapGQLLocaleToStandardLocale(locale),
                          new Date(evvCompletionRateOffer.lastUpdatedAsOf)
                        ),
                      }}
                    />
                  </Typography>
                )}
              </div>
            </>
          )
        }
      />
    </>
  );
}

/**
 * Formats to either "x hours ago", or "x minutes ago" (if less than 1 hour ago).
 *
 * Note - ideally we would have used one of the built-in formatters, but
 * we exceeded our time box trying to get it work.
 *
 * If we need to use this elsewhere, we can move it to `useFormatters`
 * as a custom relative time formatter.
 */
function formatRelativeTimeHoursI18n(locale: string, date: Date) {
  const relativeTimeFormatter = new Intl.RelativeTimeFormat(locale, {
    style: "long",
  });

  const diffInHours = differenceInHours(date, new Date());

  if (diffInHours > -1) {
    return relativeTimeFormatter.format(
      differenceInMinutes(date, new Date()),
      "minutes"
    );
  }

  return relativeTimeFormatter.format(diffInHours, "hours");
}
