/** @jsxImportSource @emotion/react */
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { delay } from "@rewards-web/shared/lib/delay";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { useAuth } from "./shared/modules/auth";
import {
  RewardsAppAuthError,
  RewardsAppAuthErrorCode,
} from "./shared/modules/auth/errors";

interface TokenAuthWrapperProps {
  children: ReactNode;
}

/**
 * On mount, checks if invitation token is present in query params.
 * - If token is present, initiates the token auth flow
 * - A loading screen is shown while authenticating
 * - If authentication fails, it will log the existing session out (if any)
 * - Once authentication is complete (success or failure), it renders its children
 *
 * If no token is present in query params, this will just render its children (and do nothing).
 */
export function TokenAuthWrapper({
  children,
}: TokenAuthWrapperProps): JSX.Element {
  const { formatMessage } = useFormatters();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const email = useMemo(() => searchParams.get("loginEmail"), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const phoneNumber = useMemo(() => searchParams.get("loginPhoneNumber"), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loginTokenId = useMemo(() => searchParams.get("loginTokenId"), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loginToken = useMemo(() => searchParams.get("loginToken"), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const impersonating = useMemo(() => searchParams.get("impersonating"), []);
  const smsLoginChallengeId = useMemo(
    () => searchParams.get("smsLoginChallengeId"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasLoginParams = Boolean((email || phoneNumber) && loginToken);
  const shouldAttemptLogin = hasLoginParams && location.pathname !== "/reauth";

  const [signingInUsingToken, setSigningInUsingToken] = useState(
    shouldAttemptLogin
  );
  const { signInWithRewardsInvitationToken, signOut } = useAuth();

  const initialize = async () => {
    // clear from URL
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.delete("loginTokenId");
    nextSearchParams.delete("loginEmail");
    nextSearchParams.delete("loginPhoneNumber");
    nextSearchParams.delete("loginToken");
    nextSearchParams.delete("impersonating");
    nextSearchParams.delete("smsLoginChallengeId");

    if (Array.from(nextSearchParams.entries()).length === 0) {
      // strip "?" from URL if there are no query params
      navigate(location.pathname, { replace: true });
    }

    if (shouldAttemptLogin) {
      // clear search params from URL to attempt to hide
      setSearchParams(nextSearchParams, { replace: true });

      try {
        // sign out before signing in to prevent two active sessions
        await signOut();
      } catch (error) {
        reportError(error);
      }

      // try to log in with the token
      try {
        await signInWithRewardsInvitationToken(
          (email ?? phoneNumber)!,
          loginToken!,
          loginTokenId ?? undefined,
          impersonating === "true"
        );
        track("Token auth succeeded", { loginTokenId, smsLoginChallengeId });

        if (smsLoginChallengeId) {
          trackScreenRecordingEvent("login_from_sms_login_challenge_link");
        }
      } catch (error) {
        if (
          error instanceof RewardsAppAuthError &&
          error.code === RewardsAppAuthErrorCode.DEACTIVATED
        ) {
          navigate("/deactivated");
          track("Token auth failed", {
            loginTokenId,
            loginEmail: email,
            loginPhoneNumber: phoneNumber,
            reason: "account_deactivated",
          });
        } else if (
          // try logging again, but only if we didn't just try again
          (error as Error)?.name === "QuotaExceededError" &&
          !searchParams.has("quota_exceeded_error")
        ) {
          // this is necessary because of a safari bug that occurs after logging in
          // where localstorage suddenly fails...
          // we need to reload the page so amplify can re-initialize with in-memory storage
          // before trying to log in again
          reportError(error);
          searchParams.set("quota_exceeded_error", "true");
          window.location.href = `/login?${searchParams}`;
        } else if (error instanceof RewardsAppAuthError && loginTokenId) {
          const tokenAuthFailureId = v4();

          track("Token auth failed", {
            loginTokenId,
            loginEmail: email,
            loginPhoneNumber: phoneNumber,
            failedReason: error.code,
            tokenAuthFailureId,
          });

          navigate(
            `/reauth?${new URLSearchParams({
              loginTokenId,
              tokenAuthFailureId,
              redirect: `${location.pathname}?${nextSearchParams}${location.hash}`,
            })}`,
            { replace: true }
          );
        } else {
          reportError(error);
          track("Token auth failed", {
            loginTokenId,
            loginEmail: email,
            loginPhoneNumber: phoneNumber,
            reason: "unknown",
          });

          await delay(1000); // wait a second for segment event to track

          // if it fails, just sign out
          try {
            await signOut();
            // keep next search params for email tracking purposes
            window.location.href = `/login?${nextSearchParams}`;
          } catch (error) {
            reportError(error);
          }
        }
      }
    }

    setSigningInUsingToken(false);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (signingInUsingToken) {
    return (
      <PageLoadingState
        label={formatMessage({
          description: "Global > login loading spinner caption",
          defaultMessage: "Logging in...",
        })}
      />
    );
  }

  return <>{children}</>;
}
