import { initial, last } from "lodash";

import { JobPostingItem } from "./hooks";

interface JobGroupHeader {
  key: string;
  type: "promoted" | "branch";
  text: string;
}

/**
 * Groups job postings by a common title.
 * It makes the assumption that job postings load in order of the header.
 *
 * Currently the only supported header is for 'Promoted Jobs'
 *
 */
export function getJobsGroupedByTitle(allItems: Array<JobPostingItem | null>) {
  return allItems.reduce<
    Array<{
      header?: JobGroupHeader;
      items: Array<JobPostingItem | null>;
    }>
  >((prev, job) => {
    const jobHeader: JobGroupHeader | undefined = job
      ? getHeaderForJob(job)
      : undefined;

    // assumes the jobs are ordered
    const lastSection = last(prev);

    if (lastSection && jobHeader?.key === lastSection.header?.key) {
      return [
        ...initial(prev),
        {
          ...lastSection,
          items: [...lastSection.items, job],
        },
      ];
    }

    // add new section
    return [
      ...prev,
      {
        header: jobHeader,
        items: [job],
      },
    ];
  }, []);
}

function getHeaderForJob(job: JobPostingItem): JobGroupHeader | undefined {
  if (job.activePromotion) {
    return { key: "promoted", type: "promoted", text: "Promoted Jobs" };
  }

  if (job.branch) {
    return {
      key: job.branch.id,
      type: "branch",
      text: job.branch.name,
    };
  }

  return undefined;
}
