export function generatePunchPosition(key: string): [number, number] {
  const [x, y] = (Math.abs(hashStringToNumber(key)) % 99)
    .toString()
    .padStart(2, "0")
    .split("")
    .map(Number)
    .map((digit) => digit / 4.5 - 1);

  return [x, y];
}

/**
 * Source: https://www.30secondsofcode.org/js/s/hash-string-into-number/
 */
function hashStringToNumber(str: string) {
  return str
    .split("")
    .reduce(
      (hashCode, currentVal) =>
        (hashCode =
          currentVal.charCodeAt(0) +
          (hashCode << 6) +
          (hashCode << 16) -
          hashCode),
      0
    );
}
