/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Button } from "@rewards-web/shared/components/button";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

interface JobFilterShowMoreButtonProps {
  showingMore: boolean;
  onClick(): void;
}

export function JobFilterShowMoreButton({
  showingMore,
  onClick,
}: JobFilterShowMoreButtonProps) {
  const { formatMessage } = useFormatters();

  return (
    <Button
      color="primary"
      variant="text"
      width="auto"
      startIcon={
        showingMore ? (
          <ExpandLessIcon color="primary" />
        ) : (
          <ExpandMoreIcon color="primary" />
        )
      }
      label={
        showingMore
          ? formatMessage({
              description: "Jobs page > filter dialog > show less button",
              defaultMessage: "Show Less",
            })
          : formatMessage({
              description: "Jobs page > filter dialog > show more button",
              defaultMessage: "Show More",
            })
      }
      onClick={onClick}
      css={(theme: AppTheme) => css`
        margin-top: ${theme.spacing(1)};
        margin-left: -7px;
      `}
    />
  );
}
