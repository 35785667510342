/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../../../../shared/scroll-to-top-on-mount";
import trophiesImageUrl from "../draw-two-trophies.png";
import { DrawWinnersAnnouncedCard } from "../draw-winners-announced-card";
import { OrganizationRunDrawPageDataQuery } from "../organization-run-draw-page-data.generated";
import { useDrawClosedPageDataQuery } from "./draw-closed-page-data.generated";

interface DrawClosedStateProps {
  drawId: string;
  drawClosedAt: Date;
  prizeStructure: NonNullable<
    OrganizationRunDrawPageDataQuery["currentDraw"]
  >["prizeStructure"];
  lastDraw: OrganizationRunDrawPageDataQuery["lastDraw"];
}

export function DrawClosedState({ drawId, lastDraw }: DrawClosedStateProps) {
  const { formatMessage } = useFormatters();
  const query = useDrawClosedPageDataQuery({ onError: reportError });

  const track = useTrack();
  useEffect(() => {
    track("Viewed draw closed state", { lastDrawId: drawId });
  }, [track, drawId]);

  return (
    <div
      css={(theme: AppTheme) => css`
        background-color: ${theme.palette.primary.main};
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <ScrollToTopOnMount />
      <PageContentContainer
        css={css`
          height: 100%;
          color: white;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <DrawWinnersAnnouncedCard marginTop={40} lastDraw={lastDraw} />
          <div
            css={css`
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            `}
          >
            <img
              src={trophiesImageUrl}
              alt={formatMessage({
                defaultMessage: "Trophies",
                description: "Draw page > draw closed state > trophy image alt",
              })}
              css={(theme: AppTheme) => css`
                margin: 0 auto;
                width: 300px;
                max-width: 90%;
                margin-top: ${theme.spacing(3)};
                @media (min-height: 780px) {
                  padding-top: ${theme.spacing(8)};
                }

                margin-bottom: ${theme.spacing(3)};
              `}
            />

            <Typography
              variant="h3"
              component="h2"
              textAlign="center"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              <FormattedMessage
                defaultMessage="New draws coming soon!"
                description="Draw page > draw closed state > page subtitle"
              />
            </Typography>
            <Typography
              variant="body"
              textAlign="center"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(4)};
              `}
            >
              {lastDraw?.winnersAnnounced ? (
                <FormattedMessage
                  defaultMessage="There are currently no active draws. Come back to see the next prizes you could win from {org_name}."
                  description="Draw page > draw closed state > page body > winners announced"
                  values={{
                    org_name: query.data?.organization.shortName ?? "",
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="There are currently no active draws. In the meantime, be on the look out for updates and winner announcements from {org_name}."
                  description="Draw page > draw closed state > page body > winners not announced"
                  values={{
                    org_name: query.data?.organization.shortName ?? "",
                  }}
                />
              )}
            </Typography>
          </div>
        </div>
      </PageContentContainer>
    </div>
  );
}
