/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import RefreshIcon from "@material-ui/icons/Refresh";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { UnauthenticatedPageLayout } from "../../../shared/unauthenticated-page-layout";

export function PrivateBrowsing(): JSX.Element {
  const { formatMessage } = useFormatters();

  return (
    <UnauthenticatedPageLayout>
      <Typography
        variant="h1"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
        color="primary"
      >
        <FormattedMessage
          description="Unexpected logout page > title"
          defaultMessage="An Error Occurred"
        />
      </Typography>
      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <FormattedMessage
          description="Unexpected logout page > description"
          defaultMessage="Caribou Rewards could not be loaded"
        />
        <br />
        <br />
        <Button
          linkTo="/"
          externalLink
          target="_blank"
          startIcon={<RefreshIcon />}
          label={formatMessage({
            description: "Unexpected logout page > refresh button",
            defaultMessage: "Click here to try again",
          })}
        />
        <br />
        <br />
        <FormattedMessage
          description="Unexpected logout page > help description"
          defaultMessage="If you need a hand, please email us at <mailto>{help_email}</mailto>."
          values={{
            mailto: (nodes) => <a href="mailto:help@caribou.care">{nodes}</a>,
            help_email: "help@caribou.care",
          }}
        />
      </Typography>
    </UnauthenticatedPageLayout>
  );
}
