import { SVGProps } from "react";

export function PrivacyLockIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.2422 2.38062C13.7344 2.59937 14.0625 3.06421 14.0625 3.58374C14.0625 10.5837 8.89453 14.0837 7.47266 14.0837C6.10547 14.0837 0.9375 10.6384 0.9375 3.58374C0.9375 3.06421 1.23828 2.59937 1.73047 2.38062L6.98047 0.193115C7.11719 0.138428 7.33594 0.0837402 7.5 0.0837402C7.63672 0.0837402 7.85547 0.138428 7.99219 0.193115L13.2422 2.38062ZM7.47266 12.7712C9.30469 12.033 12.75 9.13452 12.75 3.58374L7.5 1.42358C7.47266 1.42358 7.47266 1.42358 7.44531 1.42358L2.25 3.61108C2.25 8.39624 4.95703 11.7322 7.47266 12.7712ZM5.96875 5.77124C5.96875 4.95093 6.65234 4.23999 7.5 4.23999C8.32031 4.23999 9.03125 4.95093 9.03125 5.77124C9.03125 6.40015 8.64844 6.91968 8.15625 7.16577V9.05249C8.15625 9.4353 7.85547 9.70874 7.5 9.70874C7.11719 9.70874 6.84375 9.4353 6.84375 9.05249V7.16577C6.32422 6.91968 5.96875 6.40015 5.96875 5.77124Z"
        fill="#747676"
      />
    </svg>
  );
}
