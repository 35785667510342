/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lighten } from "@material-ui/core";

import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { MilestoneRewardPoints } from "../milestone-reward-points";
import { UserMilestoneRewardStepDetailsFragment } from "../milestone-step-fragment.generated";
import { MilestoneRewardStepIcon } from "./milestone-reward-step-icon";
import { MilestoneRewardStepProgressBar } from "./milestone-reward-step-progress-bar";

const useMilestoneGoalTitle = ({
  goal,
}: {
  goal: UserMilestoneRewardStepDetailsFragment["goal"];
}): string => {
  const { formatMessage } = useFormatters();

  switch (goal.__typename) {
    case "HoursMilestoneGoal":
      return formatMessage(
        {
          defaultMessage: "{num_hours} Hours",
          description: "Milestones rewards > milestone title > hours",
        },
        { num_hours: goal.numHours }
      );
    case "VisitMilestoneGoal":
      return formatMessage(
        {
          description: "Milestones rewards > milestone title > visit number",
          defaultMessage:
            "{visit_number, selectordinal, one {First} two {Second} few {#rd} other {#th}} Visit",
        },
        { visit_number: goal.visitNumber }
      );
    case undefined:
    default:
      reportError(
        `Unexpected milestone goal type when generating milestone reward title: ${goal.__typename}`
      );
      return "Milestone Goal";
  }
};

interface MilestoneRewardStepProps {
  milestone: UserMilestoneRewardStepDetailsFragment;
  indexOfThisMilestone: number;
  isCurrentMilestone: boolean;
  mustCompleteEVV: boolean;
  pointsPerDollar: number;
  variant: "offer-card" | "subpage"; // controls some css styles
}

export function MilestoneRewardStep({
  milestone,
  indexOfThisMilestone,
  isCurrentMilestone,
  mustCompleteEVV,
  pointsPerDollar,
  variant,
}: MilestoneRewardStepProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();
  const subtitle = (
    <Typography
      variant="caption"
      color={
        isCurrentMilestone || variant === "offer-card"
          ? "tertiary.main"
          : "grey.800"
      }
      css={(appTheme: AppTheme) => css`
        text-transform: uppercase;
        margin-bottom: ${appTheme.spacing(0.25)};
      `}
    >
      {formatMessage(
        {
          defaultMessage: "milestone {order}",
          description: "Milestones rewards > milestone subtitle",
        },
        { order: indexOfThisMilestone + 1 } // add one to zero-index
      )}
    </Typography>
  );

  const milestoneGoalTitle = useMilestoneGoalTitle({ goal: milestone.goal });
  const titleAndCompletedTick = (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Typography
        css={(appTheme: AppTheme) =>
          css`
            margin-bottom: ${appTheme.spacing(0.5)};
          `
        }
        variant="subtitle"
      >
        {milestoneGoalTitle}
      </Typography>
      {milestone.isComplete && (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="lg"
          color={theme.palette.success.main}
        />
      )}
    </div>
  );

  return (
    <div
      css={(appTheme: AppTheme) => css`
        padding: ${appTheme.spacing(2, 3.5, 2, 2.75)};
        display: flex;
        justify-content: left;
        gap: ${appTheme.spacing(2)};
        align-items: flex-start;
        border-radius: inherit;
        background-color: ${isCurrentMilestone
          ? lighten(appTheme.palette.tertiary.main, 0.95)
          : "white"};
      `}
    >
      <MilestoneRewardStepIcon goal={milestone.goal} />
      <div
        css={css`
          width: 100%;
        `}
      >
        {subtitle}
        {titleAndCompletedTick}
        <MilestoneRewardPoints
          points={milestone.pointValue}
          pointsPerDollar={pointsPerDollar}
        />
        {/* only show progress bar for current milestone */}
        {isCurrentMilestone && (
          <div>
            <MilestoneRewardStepProgressBar
              {...{ milestone, mustCompleteEVV }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
