/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";

import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

import { PointsIcon } from "../points-icon";

const starAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
export interface TripleStarAnimationProps extends StylableProps {}

export function TripleStarAnimation({ className }: TripleStarAnimationProps) {
  return (
    <div
      className={className}
      css={(appTheme: AppTheme) =>
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          gap: ${appTheme.spacing(1.25)};
        `
      }
    >
      <PointsIcon
        large
        css={css`
          width: 50px;
          transform: scale(0.5);
          opacity: 0;
          animation: ${starAnimation} 0.3s 0.2s ease-in forwards;
        `}
      />
      <PointsIcon
        large
        css={css`
          width: 80px;
          transform: scale(0.5);
          opacity: 0;
          animation: ${starAnimation} 0.2s 0.2s ease-in forwards;
        `}
      />
      <PointsIcon
        large
        css={css`
          width: 50px;
          transform: scale(0.5);
          opacity: 0;
          animation: ${starAnimation} 0.4s 0.2s ease-in forwards;
        `}
      />
    </div>
  );
}
