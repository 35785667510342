import { TypePolicies } from "@apollo/client";

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      listShareableJobPostings: {
        keyArgs: ["searchQuery", "filters"],
        merge(
          existing: undefined | { total: number; items: any[] },
          incoming: { total: number; items: any[] },
          { args }
        ) {
          if (!existing) {
            return incoming;
          }

          const mergedItems = existing.items.slice();

          const start = args!.offset;
          for (let i = 0; i < incoming.items.length; i++) {
            mergedItems[start + i] = incoming.items[i];
          }

          return {
            total: incoming.total,
            items: mergedItems,
          };
        },
      },
    },
  },
};
