/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { darken, Typography } from "@material-ui/core";

import { Locale } from "@rewards-web/shared/graphql-types";
import { useLocale } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import CambodiaFlag from "./icons/cambodia-flag.png";
import { CheckIcon } from "./icons/check-icon";
import ChineseFlag from "./icons/chinese-flag.png";
import FranceFlag from "./icons/france-flag.png";
import RussiaFlag from "./icons/russia-flag.png";
// import HaitiFlag from "./icons/haiti-flag.png";
import SpainFlag from "./icons/spain-flag.png";
import UkraneFlag from "./icons/ukrane-flag.png";
import USAFlag from "./icons/usa-flag.png";
import VietnamFlag from "./icons/vietnam-flag.png";

export interface LocaleCardsProps {
  supportedLocaleCodes?: Locale[];
  onLocaleChange?: (locale: Locale) => void;
  borderColor?: string;
  borderWidth?: number;
  isDisabled?: boolean;
}

export const ALL_SUPPORTED_LOCALES = [
  {
    name: "English",
    code: Locale.En,
    icon: USAFlag,
  },
  {
    name: "Español",
    code: Locale.Es,
    icon: SpainFlag,
  },
  {
    name: "Français",
    code: Locale.Fr,
    icon: FranceFlag,
  },
  {
    name: "中文",
    code: Locale.ZhCn,
    icon: ChineseFlag,
  },
  {
    name: "русский",
    code: Locale.Ru,
    icon: RussiaFlag,
  },
  {
    name: "українська",
    code: Locale.Uk,
    icon: UkraneFlag,
  },
  {
    name: "Tiếng Việt",
    code: Locale.Vi,
    icon: VietnamFlag,
  },
  {
    name: "Khmer",
    code: Locale.KmKh,
    icon: CambodiaFlag,
  },
];

export function LocaleCards({
  supportedLocaleCodes,
  onLocaleChange,
  borderColor,
  borderWidth,
  isDisabled,
}: LocaleCardsProps) {
  const { setLocale, locale } = useLocale();
  const supportedLocales = supportedLocaleCodes
    ? ALL_SUPPORTED_LOCALES.filter((locale) =>
        supportedLocaleCodes.includes(locale.code)
      )
    : ALL_SUPPORTED_LOCALES;

  return (
    <>
      {supportedLocales.map(({ code, name, icon }) => {
        const isSelected = locale === code;
        return (
          <button
            disabled={isDisabled}
            onClick={() => {
              if (!isSelected) {
                setLocale(code);
                onLocaleChange?.(code);
              }
              !isSelected && setLocale(code);
            }}
            key={code}
            css={(appTheme: AppTheme) => css`
              width: 100%;
              border-radius: 10px;
              background-color: ${appTheme.palette.background.paper};
              margin-bottom: ${appTheme.spacing(1.25)};
              padding: ${appTheme.spacing(1.25)} ${appTheme.spacing(2.75)};
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              transition: background-color 0.2s;
              border: ${borderWidth || 2}px solid
                ${isSelected
                  ? appTheme.palette.primary.main
                  : borderColor || "transparent"};
              :hover {
                background-color: ${darken(
                  appTheme.palette.background.paper,
                  0.01
                )};
              }
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <img
                src={icon}
                alt={name}
                css={(appTheme: AppTheme) =>
                  css`
                    height: ${appTheme.spacing(3.25)};
                    width: ${appTheme.spacing(3.25)};
                    margin-right: ${appTheme.spacing(2)};
                  `
                }
              />
              <Typography>{name}</Typography>
            </div>
            {isSelected && <CheckIcon />}
          </button>
        );
      })}
    </>
  );
}
