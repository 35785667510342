/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function RaffleIcon() {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="19"
        height="19"
        rx="9.5"
        fill={theme.palette.primary.main}
      />
      <path
        d="M6.875 11.625V7.875H13.125V11.625H6.875ZM15.625 8.8125C15.0977 8.8125 14.6875 9.24219 14.6875 9.75C14.6875 10.2773 15.0977 10.6875 15.625 10.6875V12.5625C15.625 13.0898 15.1953 13.5 14.6875 13.5H5.3125C4.78516 13.5 4.375 13.0898 4.375 12.5625V10.6875C4.88281 10.6875 5.3125 10.2188 5.3125 9.73047C5.3125 9.22266 4.88281 8.8125 4.375 8.8125V6.9375C4.375 6.42969 4.78516 6 5.3125 6H14.6875C15.1953 6 15.625 6.42969 15.625 6.9375V8.8125ZM13.75 11.7812V7.71875C13.75 7.46484 13.5352 7.25 13.2812 7.25H6.71875C6.44531 7.25 6.25 7.46484 6.25 7.71875V11.7812C6.25 12.0547 6.44531 12.25 6.71875 12.25H13.2812C13.5352 12.25 13.75 12.0547 13.75 11.7812Z"
        fill="white"
      />
    </svg>
  );
}
