import { SVGProps } from "react";

function ProgressCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17.515} cy={17.515} r={10.193} fill="#fff" />
      <path
        d="M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0Zm6 9.188L24.813 12 14 22.813 7.188 16 10 13.187l4 4 8-8Z"
        fill="#CFE1E3"
      />
    </svg>
  );
}

export default ProgressCheckIcon;
