import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LatestUnclaimedDrawDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LatestUnclaimedDrawDataQuery = (
  { __typename?: 'Query' }
  & { latestUnclaimedDraw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'scheduledStartDate' | 'scheduledEndDate'>
  )> }
);


export const LatestUnclaimedDrawDataDocument = gql`
    query LatestUnclaimedDrawData {
  latestUnclaimedDraw: getMyLatestUnclaimedDraw {
    id
    scheduledStartDate
    scheduledEndDate
  }
}
    `;

/**
 * __useLatestUnclaimedDrawDataQuery__
 *
 * To run a query within a React component, call `useLatestUnclaimedDrawDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestUnclaimedDrawDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestUnclaimedDrawDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestUnclaimedDrawDataQuery(baseOptions?: Apollo.QueryHookOptions<LatestUnclaimedDrawDataQuery, LatestUnclaimedDrawDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestUnclaimedDrawDataQuery, LatestUnclaimedDrawDataQueryVariables>(LatestUnclaimedDrawDataDocument, options);
      }
export function useLatestUnclaimedDrawDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestUnclaimedDrawDataQuery, LatestUnclaimedDrawDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestUnclaimedDrawDataQuery, LatestUnclaimedDrawDataQueryVariables>(LatestUnclaimedDrawDataDocument, options);
        }
export type LatestUnclaimedDrawDataQueryHookResult = ReturnType<typeof useLatestUnclaimedDrawDataQuery>;
export type LatestUnclaimedDrawDataLazyQueryHookResult = ReturnType<typeof useLatestUnclaimedDrawDataLazyQuery>;
export type LatestUnclaimedDrawDataQueryResult = Apollo.QueryResult<LatestUnclaimedDrawDataQuery, LatestUnclaimedDrawDataQueryVariables>;