/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface HourBasedGoalModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  buttonLabel: string;
}

export function HourBasedGoalModal({
  open,
  onClose,
  title,
  description,
  buttonLabel,
}: HourBasedGoalModalProps) {
  return (
    <ResponsiveDialog open={open} onClose={onClose} maxWidth="500px">
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <button
            css={css`
              border-radius: 50%;
              border-width: 0px;
              height: 27px;
              width: 27px;
              background-color: #e0e0e0;
              padding: 0;
              cursor: pointer;
            `}
            onClick={onClose}
          >
            <CloseOutlinedIcon
              css={css`
                padding-top: 3px;
                font-size: 1.6em;
                color: #757575;
              `}
            />
          </button>
        </div>

        <Typography
          css={(theme: AppTheme) => css`
            padding-bottom: ${theme.spacing(4)};
            text-align: center;
          `}
          variant="h5"
          color="text.primary"
        >
          {title}
        </Typography>

        <Typography
          css={css`
            text-align: center;
          `}
          variant="body"
          color="text.primary"
        >
          {description}
        </Typography>
      </div>

      <div
        css={(theme: AppTheme) => css`
          position: sticky;
          width: 100%;
          bottom: 0;
          padding: ${theme.spacing(2)};
        `}
      >
        <Button color="secondary" label={buttonLabel} onClick={onClose} />
      </div>
    </ResponsiveDialog>
  );
}
