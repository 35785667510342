/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useEffect } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { getDaysUntilDrawClose } from "../lib/get-days-until-draw-close";
import { BlurredBackgroundVideoPlayer } from "./blurred-background-video-player";
import { useDrawPrizeCardDataQuery } from "./draw-prize-card-data.generated";
import trophiesImageUrl from "./graphics/trophy.png";

const BORDER_RADIUS_PX = 14;

export interface DrawDetailsCardProps {
  /**
   * If not provided, will render a skeleton state
   */
  prizeStructure:
    | ({
        __typename: "DrawPrizeStructureTieredPrizesMultipleWinners";
      } & Pick<
        GraphQLTypes.DrawPrizeStructureTieredPrizesMultipleWinners,
        "id" | "numWinners" | "totalPrizePointValue"
      > & {
          prizes: Array<
            | { __typename: "DrawPrizePoints" }
            | ({ __typename: "DrawTieredPrizePoints" } & Pick<
                GraphQLTypes.DrawTieredPrizePoints,
                "pointValue" | "numWinners"
              >)
          >;
        })
    | undefined;

  /**
   * If not provided, will render a skeleton state
   */
  drawEndsAt: Date;
  announcementVideoUrl?: string;
}

export function DrawDetailsCardV2({
  prizeStructure,
  drawEndsAt,
  announcementVideoUrl,
}: DrawDetailsCardProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();

  const cardData = useDrawPrizeCardDataQuery({ onError: reportError });

  const daysUntilDrawClose = drawEndsAt
    ? getDaysUntilDrawClose(drawEndsAt)
    : undefined;

  const text = ((): {
    prizeNodes: ReactNode[];
    numWinnersNode: ReactNode[] | null;
    unknown?: true;
  } => {
    const prizeTBDNode = [
      formatMessage({
        defaultMessage: "Prize TBD",
        description: "Draw page > draw active state > prize TBD",
      }),
    ];

    if (!prizeStructure || !cardData.data) {
      return {
        prizeNodes: [<Skeleton />, <Skeleton />],
        numWinnersNode: [<Skeleton />],
      };
    }

    switch (prizeStructure.__typename) {
      case "DrawPrizeStructureTieredPrizesMultipleWinners": {
        switch (prizeStructure.prizes[0].__typename) {
          case "DrawTieredPrizePoints": {
            const prizeNodes = [
              <FormattedMessage
                defaultMessage="{prize_value} "
                description="Draw page > draw active state > prize value text for tiered prize draw"
                values={{
                  prize_value: formatDollars(
                    prizeStructure.prizes[0].pointValue /
                      cardData.data!.getMyRewardsOrganization.pointsPerDollar
                  ),
                }}
              />,
            ];

            if (prizeStructure.prizes.length === 1) {
              return {
                prizeNodes,
                numWinnersNode: [
                  <FormattedMessage
                    defaultMessage="{num_winners} {num_winners, plural, one {WINNER} other {WINNERS}}"
                    description="Draw page > draw active state > num winners text for single prize"
                    values={{ num_winners: prizeStructure.numWinners }}
                  />,
                ],
              };
            }
            let prizeTypeError = false;
            const result: {
              prizeNodes: ReactNode[];
              numWinnersNode: ReactNode[] | null;
              unknown?: true;
            } = {
              prizeNodes: prizeStructure.prizes.map((prize) => {
                if (prize.__typename === "DrawTieredPrizePoints")
                  return (
                    <>
                      <FormattedMessage
                        defaultMessage="{prize_value} "
                        description="Draw page > draw active state > prize value text for tiered prize draw"
                        values={{
                          prize_value: formatDollars(
                            prize.pointValue /
                              cardData.data!.getMyRewardsOrganization
                                .pointsPerDollar
                          ),
                        }}
                      />
                    </>
                  );
                else {
                  prizeTypeError = true;
                  return undefined;
                }
              }),
              numWinnersNode: prizeStructure.prizes.map((prize, index) => {
                if (prize.__typename === "DrawTieredPrizePoints") {
                  return index === 0 ? (
                    <FormattedMessage
                      defaultMessage="{num_winners, number} {num_winners, plural, one {Winner} other {Winners}}"
                      description="Draw page > draw active state > number of winners text for ultimate tier"
                      values={{
                        num_winners: prize.numWinners,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="{num_winners}x "
                      description="Draw page > draw active state >number of winners text for other tiers"
                      values={{
                        num_winners: prize.numWinners,
                      }}
                    />
                  );
                } else {
                  prizeTypeError = true;
                  return undefined;
                }
              }),
            };
            if (prizeTypeError) {
              result.unknown = true;
            }
            return result;
          }

          default:
            return {
              prizeNodes: prizeTBDNode,
              numWinnersNode: null,
              unknown: true,
            };
        }
      }
      default:
        return {
          prizeNodes: prizeTBDNode,
          numWinnersNode: null,
          unknown: true,
        };
    }
  })();

  const prizeStructurePrizesTypeCheck = prizeStructure?.prizes[0].__typename;
  useEffect(() => {
    if (prizeStructure && text.unknown) {
      switch (prizeStructure.__typename) {
        case "DrawPrizeStructureTieredPrizesMultipleWinners": {
          if (prizeStructurePrizesTypeCheck) {
            reportError(
              new Error(
                `Unrecognized prize structure ${prizeStructure.__typename} or prize ${prizeStructurePrizesTypeCheck}`
              )
            );
          } else {
            reportError(
              new Error(
                `Prize is undefined for structure ${prizeStructure.__typename}`
              )
            );
          }
          break;
        }

        default:
          reportError(new Error("Unrecognized prize structure"));
          break;
      }
    }
  }, [text.unknown, prizeStructure, prizeStructurePrizesTypeCheck]);

  const drawContent = (() => {
    if (Array.isArray(text.prizeNodes) && text.prizeNodes.length === 1) {
      return (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(5)} ${theme.spacing(3)};
              text-align: center;
              position: relative;
              justify-content: center;
            `}
          >
            <Typography
              variant="subtitle"
              component="span"
              css={(theme: AppTheme) => css`
                text-transform: uppercase;
                color: ${theme.palette.gold.light};
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              <FormattedMessage
                defaultMessage="DRAW PRIZE"
                description="Draw page > draw active state > draw prize"
              />
              <Typography
                variant="h1"
                component="span"
                css={(theme: AppTheme) => css`
                  display: block;
                  color: ${theme.palette.gold.light};
                `}
              >
                {text.prizeNodes[0]}
              </Typography>
              <Typography
                variant="caption"
                component="span"
                css={(theme: AppTheme) => css`
                  text-transform: uppercase;
                  color: ${theme.palette.gold.light};
                `}
              >
                {text.numWinnersNode![0]}
              </Typography>
            </Typography>
          </div>
          <img
            src={trophiesImageUrl}
            alt={formatMessage({
              defaultMessage: "Trophies",
              description: "Draw page > current draw > trophy image alt",
            })}
            css={css`
              max-width: 30%;
              max-height: 160px;
              display: inline-block;
              flex-shrink: 0;
            `}
          />
        </div>
      );
    } else if (Array.isArray(text.prizeNodes)) {
      return (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(3)} ${theme.spacing(2)}
                ${theme.spacing(3)} ${theme.spacing(2)};
              text-align: left;
              position: relative;
              position: relative;
              justify-content: center;
            `}
          >
            {text.prizeNodes.map((prizeNode, index) => {
              if (index === 0) {
                return (
                  <React.Fragment key={index}>
                    <Typography
                      variant="caption"
                      component="span"
                      css={(theme: AppTheme) => css`
                        text-transform: uppercase;
                        color: ${theme.palette.gold.light};
                      `}
                    >
                      <FormattedMessage
                        defaultMessage="FIRST PRIZE"
                        description="Draw page > draw active state > first prize"
                      />
                    </Typography>
                    <Typography
                      variant="h2"
                      component="span"
                      css={(theme: AppTheme) => css`
                        display: block;
                        color: ${theme.palette.gold.light};
                      `}
                    >
                      {prizeNode}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="span"
                      css={(theme: AppTheme) => css`
                        text-transform: uppercase;
                        color: ${theme.palette.gold.light};
                      `}
                    >
                      {text.numWinnersNode![index]}
                    </Typography>
                    <br />
                    {Array.isArray(text.prizeNodes) &&
                      text.prizeNodes!.length > 1 && (
                        <div
                          css={(theme: AppTheme) => css`
                            margin-top: ${theme.spacing(2)};
                            margin-bottom: ${theme.spacing(1)};
                          `}
                        >
                          <Typography
                            variant="caption"
                            component="span"
                            css={css`
                              color: #ffffff;
                            `}
                          >
                            <FormattedMessage
                              defaultMessage="OTHER PRIZES"
                              description="Draw page > draw active state > other prizes"
                            />
                          </Typography>
                        </div>
                      )}
                  </React.Fragment>
                );
              } else {
                return (
                  <div
                    key={index}
                    css={(theme: AppTheme) => css`
                      display: flex;
                      align-items: center;
                      margin-top: ${theme.spacing(0.5)};
                    `}
                  >
                    <Typography
                      variant="caption"
                      component="span"
                      css={css`
                        text-transform: uppercase;
                        color: white;
                      `}
                    >
                      {text.numWinnersNode![index]}
                    </Typography>
                    <div
                      css={css`
                        background-color: rgba(29, 81, 214, 0.3);
                        border-radius: 20px;
                        margin-left: 10px;
                        min-width: 50px;
                        padding: 0 5px;
                        min-height: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      `}
                    >
                      <Typography
                        variant="subtitle"
                        component="span"
                        css={(theme: AppTheme) => css`
                          color: ${theme.palette.gold.light};
                          line-height: 1;
                          position: relative;
                        `}
                      >
                        {prizeNode}
                      </Typography>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <img
            src={trophiesImageUrl}
            alt={formatMessage({
              defaultMessage: "Trophies",
              description: "Draw page > current draw > trophy image alt",
            })}
            css={css`
              max-width: 50%;
              max-height: 220px;
              display: inline-block;
              flex-shrink: 0;
            `}
          />
        </div>
      );
    } else {
      return <Skeleton />;
    }
  })();

  return (
    <div
      css={css`
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: ${BORDER_RADIUS_PX}px;
        position: relative;
      `}
    >
      {drawContent}
      {announcementVideoUrl && (
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            justify-content: center;
            align-items: center;
            padding: ${theme.spacing(2)};
          `}
        >
          <BlurredBackgroundVideoPlayer
            url={announcementVideoUrl}
            onPlay={() => {
              track("Draw announcement video played", {
                organizationId: cardData.data?.getMyRewardsOrganization.id,
                userId: cardData.data?.getMyRewardsUser.id,
                drawId: prizeStructure?.id,
              });
            }}
            onEnded={() => {
              track("Draw announcement video watched", {
                organizationId: cardData.data?.getMyRewardsOrganization.id,
                userId: cardData.data?.getMyRewardsUser.id,
                drawId: prizeStructure?.id,
              });
            }}
            onOpenFullScreen={() => {
              track("Draw announcement video fullscreen opened", {
                organizationId: cardData.data?.getMyRewardsOrganization.id,
                userId: cardData.data?.getMyRewardsUser.id,
                drawId: prizeStructure?.id,
              });
            }}
          />
        </div>
      )}

      <Divider
        css={css`
          opacity: 0.3;
        `}
      />
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(2)};
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        <FormattedMessage
          defaultMessage="<draw_ends_in_text>Draw ends in</draw_ends_in_text><day_counter_text><day_counter>{num_days}</day_counter> {num_days, plural, one {day} other {days}}</day_counter_text>"
          description="Draw page > draw active state > draw ends in text"
          values={{
            num_days: daysUntilDrawClose ?? 0,
            draw_ends_in_text: (nodes) => (
              <Typography variant="subtitle" component="span">
                {nodes}
              </Typography>
            ),
            day_counter_text: (nodes) =>
              typeof daysUntilDrawClose === "number" ? (
                <Typography variant="subtitle" component="span">
                  {nodes}
                </Typography>
              ) : (
                <Skeleton width={100} height={40} />
              ),
            day_counter: (nodes) =>
              typeof daysUntilDrawClose === "number"
                ? (nodes[0] as string).split("").map((digit, index) => (
                    <div
                      css={(theme: AppTheme) => css`
                        display: inline-block;
                        border: 1px solid white;
                        border-radius: 10px;
                        ${index !== 0 &&
                        css`
                          margin-left: ${theme.spacing(1)};
                        `}
                        width: 30px;
                        height: 36px;
                      `}
                    >
                      <Typography
                        variant="subtitle"
                        component="span"
                        css={css`
                          height: 100%;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        {digit}
                      </Typography>
                    </div>
                  ))
                : undefined,
          }}
        />
      </div>
    </div>
  );
}
