/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedNumber,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PUNCH_CARD_BREAKPOINTS } from "../constants";
import { PunchCardPositionContainer } from "../punch-card-position-container";
import rewardStarImageUrl from "../punch-card-reward-star.png";
import drawTicketIconUrl from "./draw-ticket-icon.png";

interface PunchCardRewardProps {
  starImageAlt: string;
  reward: {
    pointValue: number | null | undefined;
    numDrawTickets: number | null | undefined;
  };
}

export function PunchCardReward({
  starImageAlt,
  reward,
}: PunchCardRewardProps) {
  const { formatMessage } = useFormatters();

  return (
    <PunchCardPositionContainer
      css={css`
        background-color: white;
      `}
    >
      {reward.numDrawTickets ? (
        <img
          src={drawTicketIconUrl}
          alt={formatMessage({
            description: "Rewards page > punch card > ticket image alt",
            defaultMessage: "Ticket",
          })}
          css={(theme: AppTheme) => css`
            display: block;
            width: 20px;
            margin-top: ${theme.spacing(1)};
            ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.sm)} {
              width: 28px;
            }
            ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.lg)} {
              width: 40px;
            }
          `}
        />
      ) : (
        <img
          src={rewardStarImageUrl}
          alt={starImageAlt}
          css={(theme: AppTheme) => css`
            display: block;
            width: 10px;
            ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.sm)} {
              width: 16px;
            }
            ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.lg)} {
              width: 24px;
            }
          `}
        />
      )}
      <Typography
        component="span"
        variant="caption"
        color="primary"
        css={(theme: AppTheme) => css`
          ${theme.breakpoints.down(PUNCH_CARD_BREAKPOINTS.sm)} {
            font-size: 0.65em;
          }
        `}
      >
        <FormattedNumber
          value={(reward.pointValue ?? reward.numDrawTickets)!}
        />
      </Typography>
    </PunchCardPositionContainer>
  );
}
