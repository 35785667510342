/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { times } from "lodash";
import { LegacyRef, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useOfferActionHandler } from "../../../../shared/reward-offer-action";
import { GRID_LAYOUTS_BY_NUM_ITEMS, PUNCH_CARD_BREAKPOINTS } from "./constants";
import { NumCompletedPunchCardsPill } from "./num-completed-punch-cards-pill";
import { PunchCardPosition } from "./punch-card-position";
import { PunchCardPositionRow } from "./punch-card-position-row";
import { PunchCardReward } from "./punch-card-reward";

export interface PunchCardOfferCardProps {
  offer: Pick<
    GraphQLTypes.RewardOffer,
    "id" | "cardTitle" | "cardPointsText"
  > & {
    structure: Pick<
      GraphQLTypes.RewardOfferStructurePunchCard,
      "numPunchesPerCard" | "pointValue" | "numDrawTickets"
    >;
    completions: Pick<
      GraphQLTypes.RewardOfferCompletion,
      "id" | "numDrawTickets" | "pointValue"
    >[];
  };
}

export function PunchCardOfferCard({ offer }: PunchCardOfferCardProps) {
  const handleAction = useOfferActionHandler();
  const ref = useRef<HTMLButtonElement | null>();
  const wideScreen = useMediaQuery(
    `(min-width: ${PUNCH_CARD_BREAKPOINTS.md}px)`
  );
  const [completedPointsModalOpen, setCompletedPointsModalOpen] = useState(
    false
  );
  const { formatMessage } = useFormatters();

  const track = useTrack();

  const onScreen = useOnScreen(ref, { threshold: 0.5 });

  const {
    completions,
    structure: { numPunchesPerCard, numDrawTickets, pointValue },
  } = offer;
  const numCompletedCards = Math.floor(completions.length / numPunchesPerCard);
  const numCompletedPunchesOnCurrentCard =
    completions.length % numPunchesPerCard;
  const trackingProperties = useMemo(
    () => ({
      rewardOfferId: offer.id,
      numCompletedCards,
      numCompletedPunchesOnCurrentCard,
    }),
    [offer.id, numCompletedCards, numCompletedPunchesOnCurrentCard]
  );

  useEffect(() => {
    if (onScreen) {
      track("Viewed rewards page punch card", { ...trackingProperties });
    }
  }, [track, onScreen, trackingProperties]);

  const completionsForCurrentCard = completions.reduce<
    Pick<
      GraphQLTypes.RewardOfferCompletion,
      "id" | "pointValue" | "numDrawTickets"
    >[]
  >(
    (prev, completion) =>
      completion.pointValue || completion.numDrawTickets
        ? []
        : [...prev, completion],
    []
  );

  const starImageAlt = formatMessage({
    description: "Rewards page > punch card > star image alt",
    defaultMessage: "Star",
  });

  const positionElements = [
    ...times(numPunchesPerCard, (index) => (
      <PunchCardPosition
        displayedNumber={index + 1}
        completion={
          completionsForCurrentCard[index]
            ? { completionId: completionsForCurrentCard[index].id }
            : null
        }
        starImageAlt={starImageAlt}
      />
    )),
    <PunchCardReward
      starImageAlt={starImageAlt}
      reward={{ numDrawTickets, pointValue }}
    />,
  ];

  // determine the fixed grid layout (if one is defined)
  const predefinedGridLayout: number[] | undefined =
    GRID_LAYOUTS_BY_NUM_ITEMS[
      positionElements.length as keyof typeof GRID_LAYOUTS_BY_NUM_ITEMS
    ];
  const [positionRows] = predefinedGridLayout
    ? predefinedGridLayout.reduce<[JSX.Element[][], JSX.Element[]]>(
        ([prev, remaining], numInRow) => [
          [...prev, remaining.slice(0, numInRow)],
          remaining.slice(numInRow),
        ],
        [[], positionElements.slice()]
      )
    : [[positionElements]];

  const totalPointValueEarned = completions.reduce(
    (prev, completion) => prev + (completion.pointValue ?? 0),
    0
  );
  const totalDrawValueEarned = completions.reduce(
    (prev, completion) => prev + (completion.numDrawTickets ?? 0),
    0
  );

  const congratsMessage = formatMessage({
    description: "Rewards page > punch card > completed points modal title",
    defaultMessage: "Congrats! 🎉",
  });

  const completedPointsMessage =
    totalDrawValueEarned > 0
      ? formatMessage(
          {
            description:
              "Rewards page > punch card > points earned tooltip draw tickets",
            defaultMessage:
              "You have completed {num_completed_cards, number} {num_completed_cards, plural, one {punch card} other {punch cards}} earning you a total of {total_draw_tickets_earned, number} draw {total_draw_tickets_earned, plural, one {ticket} other {tickets}}.",
          },
          {
            num_completed_cards: numCompletedCards,
            total_draw_tickets_earned: totalDrawValueEarned,
          }
        )
      : formatMessage(
          {
            description:
              "Rewards page > punch card > points earned tooltip points",
            defaultMessage:
              "You have completed {num_completed_cards, number} {num_completed_cards, plural, one {punch card} other {punch cards}} earning you a total of {total_point_value_earned, number} points.",
          },
          {
            num_completed_cards: numCompletedCards,
            total_point_value_earned: totalPointValueEarned,
          }
        );

  return (
    <>
      <button
        ref={ref as LegacyRef<HTMLButtonElement>}
        type="button"
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.primary.main};
          color: white;
          padding: ${theme.spacing(2.5)};
          border-radius: 10px;
          border: none;
          cursor: pointer;
          width: 100%;
          margin-top: ${theme.spacing(1.25)};
          display: block;
        `}
        onClick={() => {
          handleAction();
          track("Clicked rewards page punch card", { ...trackingProperties });
        }}
      >
        <div
          css={(theme: AppTheme) => css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: ${theme.spacing(3.5)};
          `}
        >
          <Typography
            variant={wideScreen ? "h5" : "subtitle"}
            component="h3"
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(1)};
            `}
          >
            {offer.cardTitle}
          </Typography>
          {numCompletedCards > 0 && (
            <div
              css={css`
                flex-shrink: 0;
              `}
            >
              <Tooltip
                title={
                  <Typography
                    variant="body"
                    css={(theme: AppTheme) => css`
                      padding: ${theme.spacing(1)};
                    `}
                  >
                    {congratsMessage} {completedPointsMessage}
                  </Typography>
                }
                placement="bottom"
                onOpen={() => {
                  track("Completed punch card tooltip opened", {
                    offerId: offer.id,
                    numCompletedCards,
                  });
                }}
              >
                <NumCompletedPunchCardsPill
                  numCompletedCards={numCompletedCards}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCompletedPointsModalOpen(true);
                    track("Completed punch card modal opened", {
                      offerId: offer.id,
                      numCompletedCards,
                    });
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3.5)};
          `}
        >
          {positionRows.map((positions) => (
            <PunchCardPositionRow
              predefinedGridLayout={Boolean(predefinedGridLayout)}
            >
              {positions}
            </PunchCardPositionRow>
          ))}
        </div>
        <Typography
          variant={wideScreen ? "body" : "footnote"}
          align="center"
          css={(theme: AppTheme) => css`
            ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.md)} {
              margin-bottom: ${theme.spacing(1)};
            }
          `}
        >
          {offer.cardPointsText}
        </Typography>
      </button>

      <ResponsiveDialog
        open={completedPointsModalOpen}
        onClose={() => setCompletedPointsModalOpen(false)}
        mobileType="dialog"
      >
        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(2)};
            text-align: center;
          `}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {congratsMessage}
          </Typography>
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(4)};
              ${theme.breakpoints.up("md")} {
                padding: 0 ${theme.spacing(2)};
              }
            `}
          >
            {completedPointsMessage}
          </Typography>
          <Button
            color="secondary"
            label={formatMessage({
              description:
                "Rewards page > punch card > completed points modal dismiss button label",
              defaultMessage: "Got it!",
            })}
            onClick={() => setCompletedPointsModalOpen(false)}
          />
        </div>
      </ResponsiveDialog>
    </>
  );
}
