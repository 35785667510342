import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AuthenticateLoginTokenMutationVariables = Types.Exact<{
  emailOrPhoneNumber: Types.Scalars['String'];
  loginTokenId?: Types.Maybe<Types.Scalars['String']>;
  loginToken: Types.Scalars['String'];
  clientId: Types.Scalars['String'];
  impersonating?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type AuthenticateLoginTokenMutation = (
  { __typename?: 'Mutation' }
  & { authenticateRewardsUserLoginToken: (
    { __typename?: 'RewardsUserLoginResponse' }
    & Pick<Types.RewardsUserLoginResponse, 'accessToken'>
  ) }
);


export const AuthenticateLoginTokenDocument = gql`
    mutation AuthenticateLoginToken($emailOrPhoneNumber: String!, $loginTokenId: String, $loginToken: String!, $clientId: String!, $impersonating: Boolean) {
  authenticateRewardsUserLoginToken(
    emailOrPhoneNumber: $emailOrPhoneNumber
    loginTokenId: $loginTokenId
    loginToken: $loginToken
    clientId: $clientId
    impersonating: $impersonating
  ) {
    accessToken
  }
}
    `;
export type AuthenticateLoginTokenMutationFn = Apollo.MutationFunction<AuthenticateLoginTokenMutation, AuthenticateLoginTokenMutationVariables>;

/**
 * __useAuthenticateLoginTokenMutation__
 *
 * To run a mutation, you first call `useAuthenticateLoginTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateLoginTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateLoginTokenMutation, { data, loading, error }] = useAuthenticateLoginTokenMutation({
 *   variables: {
 *      emailOrPhoneNumber: // value for 'emailOrPhoneNumber'
 *      loginTokenId: // value for 'loginTokenId'
 *      loginToken: // value for 'loginToken'
 *      clientId: // value for 'clientId'
 *      impersonating: // value for 'impersonating'
 *   },
 * });
 */
export function useAuthenticateLoginTokenMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateLoginTokenMutation, AuthenticateLoginTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateLoginTokenMutation, AuthenticateLoginTokenMutationVariables>(AuthenticateLoginTokenDocument, options);
      }
export type AuthenticateLoginTokenMutationHookResult = ReturnType<typeof useAuthenticateLoginTokenMutation>;
export type AuthenticateLoginTokenMutationResult = Apollo.MutationResult<AuthenticateLoginTokenMutation>;
export type AuthenticateLoginTokenMutationOptions = Apollo.BaseMutationOptions<AuthenticateLoginTokenMutation, AuthenticateLoginTokenMutationVariables>;