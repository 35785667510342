import { noop } from "lodash";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const AddToHomeScreenContext = createContext<{
  canAddToHomeScreen: boolean;
  openAddToHomeScreenPrompt(): void;
}>({
  canAddToHomeScreen: false,
  openAddToHomeScreenPrompt: noop,
});

interface AddToHomeScreenProviderProps {
  children: ReactNode;
}

export function AddToHomeScreenProvider({
  children,
}: AddToHomeScreenProviderProps): JSX.Element {
  const [
    beforeInstallPromptEvent,
    setBeforeInstallPromptEvent,
  ] = useState<any>();

  useEffect(() => {
    const beforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setBeforeInstallPromptEvent(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPrompt);
    };
  }, []);

  const addToHomeScreen = useCallback(async () => {
    if (beforeInstallPromptEvent) {
      // TODO add analytics tracking

      beforeInstallPromptEvent.prompt();
      const result = await beforeInstallPromptEvent.userChoice;

      if (result.outcome === "accepted") {
        // added to home screen
      } else {
        // did not add to home screen
      }
    }
  }, [beforeInstallPromptEvent]);

  return (
    <AddToHomeScreenContext.Provider
      value={{
        canAddToHomeScreen: !!beforeInstallPromptEvent,
        openAddToHomeScreenPrompt: addToHomeScreen,
      }}
    >
      {children}
    </AddToHomeScreenContext.Provider>
  );
}

export function useAddToHomeScreen() {
  return useContext(AddToHomeScreenContext);
}
