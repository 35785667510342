import { SVGProps } from "react";

export function FullscreenIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.714 5.714H16V2.286A2.286 2.286 0 0 0 13.714 0h-3.428v2.286h3.428v3.428ZM16 13.714v-3.428h-2.286v3.428h-3.428V16h3.428A2.286 2.286 0 0 0 16 13.714ZM2.286 16h3.428v-2.286H2.286v-3.428H0v3.428A2.286 2.286 0 0 0 2.286 16ZM2.286 2.286h3.428V0H2.286A2.286 2.286 0 0 0 0 2.286v3.428h2.286V2.286Z"
        fill="#fff"
      />
    </svg>
  );
}
