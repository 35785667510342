import * as Types from '@rewards-web/shared/graphql-types';

import { GoalCardFragmentFragment } from './goal-card-fragment.generated';
import { gql } from '@apollo/client';
import { GoalCardFragmentFragmentDoc } from './goal-card-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ActivateGoalMutationVariables = Types.Exact<{
  goalId: Types.Scalars['ID'];
}>;


export type ActivateGoalMutation = (
  { __typename?: 'Mutation' }
  & { activateGoal: (
    { __typename?: 'Goal' }
    & GoalCardFragmentFragment
  ) }
);


export const ActivateGoalDocument = gql`
    mutation ActivateGoal($goalId: ID!) {
  activateGoal(goalId: $goalId) {
    ...GoalCardFragment
  }
}
    ${GoalCardFragmentFragmentDoc}`;
export type ActivateGoalMutationFn = Apollo.MutationFunction<ActivateGoalMutation, ActivateGoalMutationVariables>;

/**
 * __useActivateGoalMutation__
 *
 * To run a mutation, you first call `useActivateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateGoalMutation, { data, loading, error }] = useActivateGoalMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useActivateGoalMutation(baseOptions?: Apollo.MutationHookOptions<ActivateGoalMutation, ActivateGoalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateGoalMutation, ActivateGoalMutationVariables>(ActivateGoalDocument, options);
      }
export type ActivateGoalMutationHookResult = ReturnType<typeof useActivateGoalMutation>;
export type ActivateGoalMutationResult = Apollo.MutationResult<ActivateGoalMutation>;
export type ActivateGoalMutationOptions = Apollo.BaseMutationOptions<ActivateGoalMutation, ActivateGoalMutationVariables>;