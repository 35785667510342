import { useTrack } from "@rewards-web/shared/modules/analytics";

interface UseVideoTrackingHandlersParams {
  videoId: string | undefined;
  alreadyWatched: boolean;
  playingFromCard?: boolean;
}

interface DurationAndPercentageCompleted {
  videoDurationSeconds: number;
  percentageCompleted: number;
}

export interface VideoTrackingHandlers {
  onPlay(
    params: DurationAndPercentageCompleted & {
      loadedEnoughToPlayThrough: boolean;
    }
  ): void;
  onPause(params: DurationAndPercentageCompleted): void;
  onOpenFullScreen(): void;
  onEnd(params: {
    videoDurationSeconds: number;
    videoWatchId: string | undefined;
  }): void;

  /**
   * Invoked when the video's metadata (e.g. aspect ratio)
   * has been loaded.
   */
  onLoadMetadata(params: {
    videoDurationSeconds: number;
    metadataLoadTimeMilliseconds: number;
  }): void;

  /**
   * Invoked when the video has loaded enough content such
   * that the browser thinks the user can play the whole video
   * without it lagging.
   */
  onLoadEnoughToPlayThrough(params: { loadTimeMilliseconds: number }): void;

  /**
   * Invoked if the video temporarily pauses because it hasn't
   * loaded enough of the video yet
   */
  onLag(params: DurationAndPercentageCompleted): void;
}

/**
 * Returns props that can be passed to `VideoPlayer` in order
 * to track playback functionality for the user
 */
export function useVideoTrackingHandlers({
  videoId,
  alreadyWatched,
  playingFromCard = false,
}: UseVideoTrackingHandlersParams): VideoTrackingHandlers {
  const track = useTrack();
  const trackingProperties = {
    alreadyWatched,
    playingFromCard,
    videoId,
  };

  return {
    onEnd: ({ videoDurationSeconds, videoWatchId }) => {
      track("Video player watched", {
        ...trackingProperties,
        videoDurationSeconds,
        videoWatchId,
      });
    },
    onOpenFullScreen: () => {
      track("Video player opened full screen", trackingProperties);
    },
    onPlay: ({
      videoDurationSeconds,
      percentageCompleted,
      loadedEnoughToPlayThrough,
    }) => {
      track("Video player played", {
        ...trackingProperties,
        percentageCompleted,
        videoDurationSeconds,
        loadedEnoughToPlayThrough,
      });
    },
    onPause: ({ videoDurationSeconds, percentageCompleted }) => {
      track("Video player paused", {
        ...trackingProperties,
        percentageCompleted,
        videoDurationSeconds,
      });
    },
    onLag: ({ videoDurationSeconds, percentageCompleted }) => {
      track("Video player playback lagged", {
        ...trackingProperties,
        percentageCompleted,
        videoDurationSeconds,
      });
    },
    onLoadMetadata: ({
      videoDurationSeconds,
      metadataLoadTimeMilliseconds,
    }) => {
      track("Video player loaded metadata", {
        ...trackingProperties,
        metadataLoadTimeMilliseconds,
        videoDurationSeconds,
      });
    },
    onLoadEnoughToPlayThrough: ({ loadTimeMilliseconds }) => {
      track("Video player loaded enough to play through", {
        ...trackingProperties,
        loadTimeMilliseconds,
      });
    },
  };
}
