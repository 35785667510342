/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { differenceInDays } from "date-fns";
import { last } from "lodash";
import { useEffect, useMemo, useRef } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  FormattedNumber,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import coinPointsUrl from "../../../../shared/coin-points.png";
import { ProgressBar } from "../../../../shared/progress-bar";
import { useOfferActionHandler } from "../../../../shared/reward-offer-action";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import { TimeBasedOfferCard } from "../time-based-offer-card";
import confettiImageUrl from "./confetti.png";
import heartImageUrl from "./heart.png";
import { usePowerHoursOfferCardDataQuery } from "./power-hours-offer-card-data.generated";

export interface PowerHoursOfferCardProps {
  offer: RewardsPageDataQuery["listMyRewardOffers"][0];
}

export function PowerHoursOfferCard({ offer }: PowerHoursOfferCardProps) {
  const { formatMessage } = useFormatters();

  const query = usePowerHoursOfferCardDataQuery({
    variables: {
      powerHoursOfferId: offer.externalId?.split(":")[1]!,
    },
    onError: reportError,
  });

  const ref = useRef<HTMLDivElement>(null);

  const powerHoursOffer = query.data?.powerHoursOffer;

  const numDaysLeft = powerHoursOffer?.rangeEnd
    ? Math.max(
        differenceInDays(new Date(powerHoursOffer.rangeEnd), new Date()),
        0
      )
    : null;

  const trackingProperties = useMemo(
    () => ({
      offerCardTitle: offer.cardTitle,
      offerCardButtonLabel: offer.cardButtonLabel,
      offerId: offer.id,
      offerCompleted: offer.completed,
      powerHoursOfferId: powerHoursOffer?.id,
      currentPowerHoursLevelNumber: powerHoursOffer?.currentLevelNumber,
      numCompletedHours: powerHoursOffer?.trackedNumCompletedHours,
      completedAllLevels:
        powerHoursOffer && !powerHoursOffer.currentLevelNumber,
      numPowerHoursDaysLeft: numDaysLeft,
    }),
    [offer, powerHoursOffer, numDaysLeft]
  );

  const handleAction = useOfferActionHandler();

  const onScreen = useOnScreen(ref, { threshold: 0.5 });
  const track = useTrack();

  useEffect(() => {
    if (onScreen && powerHoursOffer) {
      track("Viewed rewards page power hours offer card", trackingProperties);
    }
  }, [track, onScreen, trackingProperties, powerHoursOffer]);

  const lastFinishedLevel =
    powerHoursOffer &&
    last(powerHoursOffer.levels.filter((level) => level.finished));
  const hasNotAcknowledgedLastFinishedLevel =
    lastFinishedLevel && !lastFinishedLevel.acknowledgedLevelFinished;

  if (query.error || (query.data && !query.data.powerHoursOffer)) {
    return null;
  }

  const inProgress = !powerHoursOffer?.ended;
  const numLevelsFininshed = powerHoursOffer?.levels.filter(
    (level) => level.finished
  ).length;
  const finishedAtLeastOneLevel =
    typeof numLevelsFininshed === "number" && numLevelsFininshed > 0;

  const pointsEarned =
    last(powerHoursOffer?.levels.filter((level) => level.finished))
      ?.pointValue ?? 0;

  return (
    <TimeBasedOfferCard
      ref={ref}
      onClick={() => {
        track(
          "Clicked rewards page power hours offer card",
          trackingProperties
        );
        handleAction();
      }}
      offer={offer}
      showSkeleton={!powerHoursOffer}
      hideOfferRewardIcon={!inProgress && pointsEarned === 0}
      titleText={(() => {
        if (!powerHoursOffer) {
          return "";
        }

        switch (powerHoursOffer.hourCondition?.__typename) {
          case "PowerHoursWeekendHoursCondition":
            return (
              <FormattedMessage
                description="Rewards page > power hours > card title > weekend hours"
                defaultMessage="Weekend hours"
              />
            );
          default:
            return (
              <FormattedMessage
                description="Rewards page > power hours > card title > all hours"
                defaultMessage="Work hours"
              />
            );
        }
      })()}
      rewardText={(() => {
        if (!powerHoursOffer) {
          return "";
        }

        if (inProgress) {
          return formatMessage(
            {
              description:
                "Rewards page > power hours > card points text > not ended",
              defaultMessage: "Earn up to {points, number} points",
            },
            {
              points: last(powerHoursOffer.levels)!.pointValue,
            }
          );
        }

        return formatMessage(
          {
            description:
              "Rewards page > power hours > card points text > ended",
            defaultMessage: "{points, number} points earned",
          },
          {
            points:
              last(powerHoursOffer.levels.filter((level) => level.finished))
                ?.pointValue ?? 0,
          }
        );
      })()}
      completed={!inProgress}
      numDaysLeft={inProgress ? numDaysLeft : null}
      timeIconType="clock"
      bodyContent={
        <>
          {inProgress &&
            typeof powerHoursOffer?.currentLevelNumber === "number" && (
              <>
                <Typography
                  color="textPrimary"
                  variant="subtitle"
                  textAlign="center"
                >
                  <FormattedMessage
                    description="Rewards page > power hours > current level"
                    defaultMessage="You're on Level {current_level, number} out of {total_num_levels, number}"
                    values={{
                      current_level: powerHoursOffer.currentLevelNumber,
                      total_num_levels: powerHoursOffer.totalNumLevels,
                    }}
                  />
                </Typography>
                <Typography
                  color="grey.800"
                  variant="caption"
                  textAlign="center"
                  textTransform="uppercase"
                >
                  <FormattedMessage
                    description="Rewards page > power hours > hours to go"
                    defaultMessage="{num_hours_to_go} {num_hours_to_go, plural, one {hour} other {hours}} to go"
                    values={{
                      num_hours_to_go:
                        powerHoursOffer.levels.find((level) => level.current)!
                          .hours - powerHoursOffer.trackedNumCompletedHours,
                    }}
                  />
                </Typography>
                <div
                  css={(theme: AppTheme) => css`
                    display: flex;
                    align-items: center;
                    margin-top: ${theme.spacing(1)};
                  `}
                >
                  <ProgressBar
                    max={
                      powerHoursOffer.levels.find((level) => level.current)!
                        .hours
                    }
                    value={
                      // allows it to animate when the user has acknowledged the level is finished
                      hasNotAcknowledgedLastFinishedLevel
                        ? 0
                        : powerHoursOffer.trackedNumCompletedHours
                    }
                    height="14px"
                  />
                  <div
                    css={(theme: AppTheme) => css`
                      background-color: ${theme.palette.tertiary.main};
                      border-radius: 1000px;
                      padding: ${theme.spacing(1)};
                      display: flex;
                      align-items: center;
                      margin-left: -6px; // make the progress bar go underneath this pill
                    `}
                  >
                    <img
                      src={coinPointsUrl}
                      alt={formatMessage({
                        defaultMessage: "star icon",
                        description: "Rewards page > power hours > star icon",
                      })}
                      css={(appTheme: AppTheme) => css`
                        width: 16px;
                        margin-right: ${appTheme.spacing(0.75)};
                      `}
                    />
                    <Typography
                      color="white"
                      variant="caption"
                      textTransform="uppercase"
                    >
                      <FormattedNumber
                        value={
                          powerHoursOffer.levels.find((level) => level.current)!
                            .pointValue
                        }
                      />
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="caption"
                  color="grey.800"
                  textTransform="uppercase"
                >
                  <FormattedMessage
                    description="Rewards page > power hours > hours completed out of total"
                    defaultMessage="{num_hours_completed}/{num_hours_for_level} hrs"
                    values={{
                      num_hours_completed:
                        powerHoursOffer.trackedNumCompletedHours,
                      num_hours_for_level: powerHoursOffer.levels.find(
                        (level) => level.current
                      )!.hours,
                    }}
                  />
                </Typography>
              </>
            )}
          {powerHoursOffer &&
            inProgress &&
            typeof powerHoursOffer.currentLevelNumber !== "number" && (
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  gap: ${theme.spacing(2.5)};
                  max-width: 400px;
                  margin: 0 auto;
                `}
              >
                <img
                  src={confettiImageUrl}
                  alt={formatMessage({
                    defaultMessage: "confetti image",
                    description:
                      "Rewards page > power hours > confetti image alt",
                  })}
                  css={css`
                    width: 67px;
                    height: 62px;
                  `}
                />
                <div>
                  <Typography variant="subtitle" color="textPrimary">
                    <FormattedMessage
                      description="Rewards page > power hours > all levels completed title"
                      defaultMessage="All levels completed!"
                    />
                  </Typography>
                  <Typography variant="footnote" color="grey.800">
                    <FormattedMessage
                      description="Rewards page > power hours > all levels completed description"
                      defaultMessage="{num_points, number} points will be rewarded at the end of this challenge."
                      values={{
                        num_points: pointsEarned,
                      }}
                    />
                  </Typography>
                </div>
              </div>
            )}
          {powerHoursOffer && !inProgress && (
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                justify-content: center;
                gap: ${theme.spacing(2.5)};
                max-width: 400px;
                margin: 0 auto;
              `}
            >
              <img
                src={heartImageUrl}
                alt={formatMessage({
                  defaultMessage: "heart image",
                  description: "Rewards page > power hours > heart image alt",
                })}
                css={css`
                  width: 58px;
                  height: 52px;
                `}
              />
              <div>
                <Typography variant="subtitle" color="textPrimary">
                  {finishedAtLeastOneLevel ? (
                    <FormattedMessage
                      description="Rewards page > power hours > ended > earned points title"
                      defaultMessage="Great job!"
                    />
                  ) : (
                    <FormattedMessage
                      description="Rewards page > power hours > ended > did not earn points title"
                      defaultMessage="Power hours has ended"
                    />
                  )}
                </Typography>
                <Typography variant="footnote" color="grey.800">
                  {finishedAtLeastOneLevel ? (
                    <FormattedMessage
                      description="Rewards page > power hours > ended > earned points description"
                      defaultMessage="You've completed {num_levels_completed, number} {num_levels_completed, plural, one {level} other {levels}} and earned {num_points, number} {num_points, plural, one {point} other {points}}."
                      values={{
                        num_levels_completed: numLevelsFininshed,
                        num_points: last(
                          powerHoursOffer.levels.filter(
                            (level) => level.finished
                          )
                        )?.pointValue,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      description="Rewards page > power hours > ended > did not earn points description"
                      defaultMessage="You have not completed any levels. Not to worry, you can always earn points next time!"
                      values={{
                        num_points: last(powerHoursOffer.levels)!.pointValue,
                        num_days_remaining: numDaysLeft,
                      }}
                    />
                  )}
                </Typography>
              </div>
            </div>
          )}
        </>
      }
    />
  );
}
