import Starboy1 from "./starboy-1.png";
import Starboy2 from "./starboy-2.png";
import Starboy3 from "./starboy-3.png";
import Starboy4 from "./starboy-4.png";
import Starboy5 from "./starboy-5.png";
import Starboy6 from "./starboy-6.png";
import Starboy7 from "./starboy-7.png";
import Starboy8 from "./starboy-8.png";
import Starboy9 from "./starboy-9.png";
import Starboy10 from "./starboy-10.png";
import Starboy11 from "./starboy-11.png";
import Starboy12 from "./starboy-12.png";
import Starboy13 from "./starboy-13.png";

export function useStarboyImages() {
  const images = [
    Starboy1,
    Starboy2,
    Starboy3,
    Starboy4,
    Starboy5,
    Starboy6,
    Starboy7,
    Starboy8,
    Starboy9,
    Starboy10,
    Starboy11,
    Starboy12,
    Starboy13,
  ];

  const useStarboyImageUrl = (index: number) => {
    if (images.length > index + 1) {
      return images[index];
    }
    return images[images.length - 1];
  };

  return useStarboyImageUrl;
}
