/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePointBalance } from "../../../../../../../shared/modules/point-balance";
import { PointsIcon } from "../../../../../../../shared/points-icon";
import { useRedeemPointsMutation } from "../../redeem-points.generated";

export interface GiftCardRedeemDrawerRedeemStepProps {
  pointsAvailableToRedeem: number;
  latestEmail: string;
  isLatestEmailValid: boolean;
  onCancel: () => void;
  onRedeemedPoints: () => void;
  onEnterUpdateEmailFlow: () => void;
}

export function GiftCardRedeemDrawerRedeemStep({
  pointsAvailableToRedeem,
  latestEmail,
  isLatestEmailValid,
  onCancel,
  onRedeemedPoints,
  onEnterUpdateEmailFlow,
}: GiftCardRedeemDrawerRedeemStepProps) {
  const { formatMessage } = useFormatters();
  const showUpdateEmailFlowFlag = useFeatureFlag(
    "rewards-app-gift-cards-redemption-update-email-temp"
  );

  const pointBalance = usePointBalance({ refreshOnMount: true });
  const snackbar = useSnackbar();

  const track = useTrack();

  useEffect(() => {
    track("Viewed giftcard redemption flow - redeem step");
  }, [track]);

  const [
    redeemPoints,
    { loading: redeemPointsLoading },
  ] = useRedeemPointsMutation({
    onError: (error) => {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Redeem points drawer > error",
          defaultMessage:
            "Something went wrong when redeeming your points. Please try again later.",
        }),
      });
    },
  });

  const onRedeem = async () => {
    await redeemPoints({
      variables: {
        amount: pointBalance.computedBalance!,
        redemptionMethod: RedemptionMethod.GenericGiftCardLink,
      },
    });
    onRedeemedPoints();
  };

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        `}
      >
        <div
          css={(appTheme: AppTheme) => css`
            text-transform: uppercase;
            padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1.5)};
            border-radius: 500px;
            background-color: ${appTheme.palette.tertiary.main};
            display: flex;
            align-items: center;
            gap: ${appTheme.spacing(1.25)};
            margin-bottom: ${appTheme.spacing(2.5)};
          `}
        >
          <PointsIcon />
          <Typography variant="caption" color="white">
            {formatMessage(
              {
                defaultMessage: "{redeemable_points, number} points",
                description: "Redeem points drawer > number of points",
              },
              { redeemable_points: pointsAvailableToRedeem }
            )}
          </Typography>
        </div>
        {(isLatestEmailValid || showUpdateEmailFlowFlag) && (
          <>
            <div
              css={(appTheme: AppTheme) => css`
                width: 100%;
                margin-bottom: ${appTheme.spacing(1)};
              `}
            >
              <Typography variant="body">
                {formatMessage(
                  {
                    defaultMessage:
                      "A link to choose your gift card will be sent to <bordered_email>{email}</bordered_email>",
                    description: "Redeem points drawer > giftcard email note",
                  },
                  {
                    email: latestEmail,
                    bordered_email: (node) => (
                      <Typography
                        variant="subtitle"
                        css={(appTheme: AppTheme) => css`
                          width: 100%;
                          margin-top: ${appTheme.spacing(1.25)};
                          padding: ${appTheme.spacing(2.5)};
                          text-align: center;
                          border-radius: 10px;
                          background-color: ${appTheme.palette.grey[200]};
                        `}
                      >
                        <span
                          css={css`
                            overflow-wrap: break-word;
                          `}
                        >
                          {node}
                        </span>
                      </Typography>
                    ),
                  }
                )}
              </Typography>
            </div>
            {showUpdateEmailFlowFlag && (
              <Typography
                variant="buttonLarge"
                color="primary"
                css={(appTheme: AppTheme) => css`
                  cursor: pointer;
                  text-decoration: none;
                  padding: ${appTheme.spacing(1.5, 3)};
                `}
                onClick={() => {
                  track(
                    "Change email pressed in giftcard redemption flow - redeem step"
                  );
                  onEnterUpdateEmailFlow();
                }}
              >
                <FontAwesomeIcon
                  css={(appTheme: AppTheme) =>
                    css`
                      margin-right: ${appTheme.spacing(0.75)};
                    `
                  }
                  icon={faPenToSquare}
                />
                {formatMessage({
                  description:
                    "Redeem points drawer > giftcard change email label",
                  defaultMessage: "Change email",
                })}
              </Typography>
            )}
          </>
        )}
      </div>
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          align-items: center;
          margin-top: ${appTheme.spacing(3.75)};
          gap: ${appTheme.spacing(1.5)};
        `}
      >
        <Button
          label={formatMessage({
            defaultMessage: "Cancel",
            description: "Redeem points drawer > cancel button",
          })}
          onClick={() => {
            track("Cancel pressed in giftcard redemption flow - redeem step");
            onCancel();
          }}
          variant="outlined"
        />
        <Button
          loading={redeemPointsLoading}
          onClick={() => {
            track("Redeem pressed in giftcard redemption flow - redeem step");
            onRedeem();
          }}
          color="secondary"
          label={formatMessage({
            defaultMessage: "Redeem",
            description: "Redeem points drawer > redeem button",
          })}
        />
      </div>
    </>
  );
}
