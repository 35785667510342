/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "@rewards-web/shared/components/button";
import { ProgressSpinner } from "@rewards-web/shared/components/progress-spinner";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import EmailIcon from "../../images/email-icon.png";

export type ResendGiftCardLinkDrawerVariantType =
  | "ready_to_resend"
  | "resend_in_flight"
  | "resent"
  | "must_wait_before_retry";

export interface ResendGiftCardLinkDrawerProps {
  open: boolean;
  // variant referes to different state in drawer i.e. loading, wait, ready to retry, successfully resent
  variant: ResendGiftCardLinkDrawerVariantType;
  // resendGiftCardTimer refers to the waiting period (30 seconds) before a user could retry resending a gift card link.
  resendGiftCardTimer: number;
  onClose: () => void;
  email?: string | null;
}

export function ResendGiftCardLinkDrawer({
  open,
  variant,
  resendGiftCardTimer,
  onClose,
  email,
}: ResendGiftCardLinkDrawerProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();
  const hasMissingEmail = !email || email.endsWith("@example.com");

  const title = (() => {
    if (hasMissingEmail) {
      return formatMessage({
        defaultMessage: "Oops!",
        description: "Resend gift card link > failure",
      });
    } else {
      if (variant === "resent") {
        return formatMessage({
          defaultMessage: "Success!",
          description: "Resend gift card link > success",
        });
      } else {
        return " ";
      }
    }
  })();

  return (
    <ResponsiveDialog
      title={title}
      open={open}
      onClose={onClose}
      maxWidth="431px"
      paddingBottom={0}
      backgroundColor={theme.palette.background.paper}
    >
      <div
        css={(appTheme: AppTheme) =>
          css`
            padding: ${appTheme.spacing(2.5)};
            padding-top: 0;
          `
        }
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          `}
        >
          {variant !== "resend_in_flight" &&
            variant !== "must_wait_before_retry" && (
              <img
                src={EmailIcon}
                alt={formatMessage({
                  defaultMessage: "Email icon",
                  description:
                    "Points summary > email warning > email icon alt",
                })}
                css={(appTheme: AppTheme) => css`
                  width: 135px;
                  margin-bottom: ${appTheme.spacing(1)};
                `}
              />
            )}

          {hasMissingEmail && (
            <>
              <Typography
                variant="h5"
                css={(appTheme: AppTheme) =>
                  css`
                    margin: ${appTheme.spacing(2.5)};
                  `
                }
              >
                {formatMessage({
                  description: "Resend gift card link > email warning > title",
                  defaultMessage: "Missing email",
                })}
              </Typography>
              <Typography variant="body" color="textPrimary">
                {formatMessage({
                  description:
                    "Resend gift card link > email warning > body text",
                  defaultMessage:
                    "Gift cards are required to be sent via email.",
                })}
                <br />
                <br />
                {formatMessage(
                  {
                    description:
                      "Resend gift card link > email warning > body text help",
                    defaultMessage:
                      "Please email <a>help@caribou.care</a> with your email, full name and phone number.",
                  },
                  {
                    a: (node) => (
                      <a
                        css={(appTheme: AppTheme) =>
                          css`
                            color: ${appTheme.palette.primary.main};
                            font-family: ${appTheme.typography.subtitle
                              .fontFamily};
                            font-size: ${appTheme.typography.subtitle.fontSize};
                            line-height: ${appTheme.typography.subtitle
                              .lineHeight};
                            font-weight: ${appTheme.typography.subtitle
                              .fontWeight};
                          `
                        }
                        href={`mailto:${encodeURI("help@caribou.care")}`}
                      >
                        {node}
                      </a>
                    ),
                  }
                )}
              </Typography>
            </>
          )}

          {!hasMissingEmail && variant === "resend_in_flight" && (
            <ProgressSpinner
              size={55}
              css={(appTheme: AppTheme) => css`
                margin-left: ${appTheme.spacing(0.5)};
                color: ${appTheme.palette.primary.main};
              `}
            />
          )}

          {!hasMissingEmail && variant === "must_wait_before_retry" && (
            <p>
              {resendGiftCardTimer > 0
                ? formatMessage(
                    {
                      defaultMessage:
                        "Please wait {num_seconds_remaining} seconds to retry",
                      description:
                        "Past redemptions > resend gift card link > wait text",
                    },
                    { num_seconds_remaining: resendGiftCardTimer }
                  )
                : formatMessage(
                    {
                      defaultMessage: "Please retry now",
                      description:
                        "Past redemptions > resend gift card link > retry text",
                    },
                    { num_seconds_remaining: resendGiftCardTimer }
                  )}
            </p>
          )}

          {!hasMissingEmail && variant === "resent" && (
            <>
              <Typography variant="body" color="textPrimary">
                {formatMessage(
                  {
                    defaultMessage:
                      "Gift card links have been re-sent to: <bordered_email>{email}</bordered_email>",
                    description: "Resend gift card link > giftcard email note",
                  },
                  {
                    email,
                    bordered_email: () => (
                      <Typography
                        variant="subtitle"
                        color="primary"
                        css={(appTheme: AppTheme) => css`
                          width: 100%;
                          border: 1px solid ${appTheme.palette.grey[400]};
                          margin-top: ${appTheme.spacing(2.5)};
                          padding: ${appTheme.spacing(2.5)};
                          text-align: center;
                          border-radius: 10px;
                        `}
                      >
                        <FontAwesomeIcon
                          css={(appTheme: AppTheme) =>
                            css`
                              margin-right: ${appTheme.spacing(1.25)};
                            `
                          }
                          icon={faEnvelope}
                        />
                        {email}
                      </Typography>
                    ),
                  }
                )}
                <br />
                {formatMessage({
                  defaultMessage:
                    "Follow the email(s) for more details. Please check Spam and Junk folders!",
                  description:
                    "Resend gift card link > giftcard email note details",
                })}
              </Typography>
            </>
          )}
        </div>
        <Button
          css={(appTheme: AppTheme) => css`
            margin-top: ${appTheme.spacing(2.5)};
          `}
          color="secondary"
          label={formatMessage({
            defaultMessage: "Close",
            description: "Resend gift card link > close button text",
          })}
          onClick={onClose}
        />
      </div>
    </ResponsiveDialog>
  );
}
