/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useCountUp } from "../../../../../../shared/use-count-up";

export interface PointsBannerCurrentBalanceTextProps {
  pointBalance: number | undefined;
  loading: boolean;
  animationDurationMs: number;
}

export function PointsBannerCurrentBalanceText({
  pointBalance,
  loading,
  animationDurationMs,
}: PointsBannerCurrentBalanceTextProps) {
  const { formatMessage } = useFormatters();

  const pointsDisplay = useCountUp(pointBalance, {
    durationMs: animationDurationMs,
  });

  return (
    <Typography color="white" variant="h2">
      {pointBalance === undefined || loading ? (
        <Skeleton width="40%" />
      ) : (
        formatMessage(
          {
            defaultMessage: "{points} <small_text>pts</small_text>",
            description: "Rewards page > points banner > points",
          },
          {
            points: pointsDisplay,
            small_text: (nodes) => (
              <span
                css={(appTheme: AppTheme) =>
                  css`
                    font-size: ${appTheme.typography.h6.fontSize};
                  `
                }
              >
                {nodes}
              </span>
            ),
          }
        )
      )}
    </Typography>
  );
}
