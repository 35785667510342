/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface SettingsListItemProps {
  title: string;
  subtitle?: string;
  icon: ReactNode;
  iconColor?: string;
  linkTo: string;
}

export function SettingsListItem({
  icon,
  iconColor,
  title,
  subtitle,
  linkTo,
}: SettingsListItemProps) {
  return (
    <Link
      css={css`
        text-decoration: none;
      `}
      to={linkTo}
    >
      <div
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.background.paper};
          display: flex;
          padding: ${theme.spacing(1.5)};
          border-radius: 10px;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(1.5)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              background-color: ${iconColor || theme.palette.primary.main};
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 7px;
            `}
          >
            {icon}
          </div>
          <Typography display="inline" variant="body">
            {title}
          </Typography>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {subtitle && (
            <Typography
              css={(appTheme: AppTheme) =>
                css`
                  margin-right: ${appTheme.spacing(2)};
                `
              }
              display="inline"
              color="textSecondary"
              variant="body"
            >
              {subtitle}
            </Typography>
          )}
          <ChevronRightIcon
            css={(theme: AppTheme) => css`
              color: ${theme.palette.text.secondary};
            `}
          />
        </div>
      </div>
    </Link>
  );
}
