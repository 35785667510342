/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ProgressBar } from "../../../progress-bar";
import { UserMilestoneRewardStepDetailsFragment } from "../../milestone-step-fragment.generated";

interface MilestoneRewardStepProgressBarProps {
  milestone: UserMilestoneRewardStepDetailsFragment;
  mustCompleteEVV: boolean;
}

export function MilestoneRewardStepProgressBar({
  milestone,
  mustCompleteEVV,
}: MilestoneRewardStepProgressBarProps) {
  switch (milestone.goal.__typename) {
    case "HoursMilestoneGoal":
      return (
        <HourProgressBar
          progress={milestone.progress}
          numHours={milestone.goal.numHours}
          mustCompleteEVV={mustCompleteEVV}
        />
      );
    case "VisitMilestoneGoal":
      return null;
    case undefined:
    default:
      reportError(
        new Error(
          `Unexpected milestone goal type when creating progress bar: ${milestone.goal.__typename}`
        )
      );
      return null;
  }
}

function HourProgressBar({
  progress,
  numHours,
  mustCompleteEVV,
}: {
  progress: number;
  numHours: number;
  mustCompleteEVV: boolean;
}) {
  const { formatMessage } = useFormatters();
  return (
    <div>
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: ${appTheme.spacing(2)};
          gap: ${appTheme.spacing(0.5)};
        `}
      >
        <Typography
          variant="footnote"
          css={css`
            @media (max-width: 380px) {
              display: none;
            }
          `}
        >
          {formatMessage({
            defaultMessage: "Your progress",
            description: "Milestone rewards > Your progress",
          })}
        </Typography>
        <Typography
          variant="caption"
          color="tertiary.main"
          css={(appTheme: AppTheme) => css`
            text-transform: uppercase;
            margin-right: ${appTheme.spacing(0.25)};
            text-align: end;
          `}
        >
          {formatMessage(
            {
              defaultMessage: "{progress} / {num_hours} hours",
              description: "Milestone rewards > Progress hours",
            },
            {
              progress,
              num_hours: numHours,
            }
          )}
        </Typography>
      </div>
      <ProgressBar max={numHours} value={progress} />
      {mustCompleteEVV && (
        <Typography
          css={(appTheme: AppTheme) => css`
            margin-top: ${appTheme.spacing(1.5)};
          `}
          color="grey.800"
          variant="footnote"
        >
          {formatMessage({
            description: "Milestone rewards > evv message",
            defaultMessage: "*Clock in and out on mobile to earn reward",
          })}
        </Typography>
      )}
    </div>
  );
}
