import { useContext, useMemo } from "react";

import { Locale } from "@rewards-web/shared/graphql-types";

import { I18nContext } from "../context";

export interface UseLocaleReturnValue {
  /**
   * Changes the currently-selected locale.
   */
  setLocale(locale: Locale): void;
  locale: Locale;
}

export function useLocale(): UseLocaleReturnValue {
  const { setLocale, locale } = useContext(I18nContext);

  return useMemo(() => ({ setLocale, locale }), [setLocale, locale]);
}
