/**
 * Determining a preset of punch grid layouts,
 * since dynamic layouts can cause weird looking wrapping,
 * and this gives the user a consistent look at their punch card
 * on all screen sizes (to mimic having a fixed, physical punch card)
 */
export const GRID_LAYOUTS_BY_NUM_ITEMS = {
  3: [3],
  4: [4],
  5: [5],
  6: [6],
  7: [4, 3],
  8: [4, 4],
  9: [5, 4],
  10: [5, 5],
  11: [6, 5],
  12: [6, 6],
  13: [4, 3, 3, 3],
  14: [5, 5, 4],
  15: [5, 5, 5],
  16: [6, 5, 5],
  17: [6, 6, 5],
  18: [6, 6, 6],
  19: [5, 5, 5, 4],
  20: [5, 5, 5, 5],
};

export const PUNCH_CARD_BREAKPOINTS = {
  sm: 380,
  md: 575,
  lg: 820,
};
