/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedNumber } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PUNCH_CARD_BREAKPOINTS } from "../constants";
import { generatePunchPosition } from "../lib";
import { PunchCardPositionContainer } from "../punch-card-position-container";
import rewardStarImageUrl from "../punch-card-reward-star.png";

interface PunchCardPositionProps {
  displayedNumber: number;
  completion: { completionId: string } | null;
  starImageAlt: string;
}

export function PunchCardPosition({
  displayedNumber,
  completion,
  starImageAlt,
}: PunchCardPositionProps) {
  return (
    <PunchCardPositionContainer
      css={css`
        border-radius: 100px;
        border: 2px dashed rgba(255, 255, 255, 0.7);
      `}
    >
      <Typography
        component="span"
        variant="subtitle"
        color={"rgba(255, 255, 255, 0.7)"}
      >
        <FormattedNumber value={displayedNumber} />
      </Typography>

      {completion &&
        (() => {
          const [transformX, transformY] = generatePunchPosition(
            completion.completionId
          );

          return (
            <img
              src={rewardStarImageUrl}
              alt={starImageAlt}
              css={(theme: AppTheme) => css`
                position: absolute;
                width: 20px;
                transform: translateX(${transformX * 6}px)
                  translateY(${transformY * 8}px);
                ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.sm)} {
                  width: 28px;
                  transform: translateX(${transformX * 12}px)
                    translateY(${transformY * 14}px);
                }
                ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.lg)} {
                  width: 36px;
                  transform: translateX(${transformX * 20}px)
                    translateY(${transformY * 16}px);
                }
              `}
            />
          );
        })()}
    </PunchCardPositionContainer>
  );
}
