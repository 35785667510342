import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SwitchAgenciesDataQueryVariables = Types.Exact<{
  organizationIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type SwitchAgenciesDataQuery = (
  { __typename?: 'Query' }
  & { getRewardsOrganizationsByIds?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'themePrimaryColor'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'primaryColor' | 'secondaryColor'>
    )> }
  )>>>, myOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id'>
  ), myIdentity?: Types.Maybe<(
    { __typename?: 'Identity' }
    & Pick<Types.Identity, 'id'>
    & { roles: Array<(
      { __typename?: 'IdentityRole' }
      & Pick<Types.IdentityRole, 'id' | 'roleName' | 'email' | 'active' | 'tenantId'>
    )> }
  )> }
);


export const SwitchAgenciesDataDocument = gql`
    query SwitchAgenciesData($organizationIds: [ID!]!) {
  getRewardsOrganizationsByIds(ids: $organizationIds) {
    id
    shortName
    themePrimaryColor
    whiteLabelConfig {
      primaryColor
      secondaryColor
    }
  }
  myOrganization: getMyRewardsOrganization {
    id
  }
  myIdentity: getMyIdentity {
    id
    roles {
      id
      roleName
      email
      active
      tenantId
    }
  }
}
    `;

/**
 * __useSwitchAgenciesDataQuery__
 *
 * To run a query within a React component, call `useSwitchAgenciesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwitchAgenciesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwitchAgenciesDataQuery({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *   },
 * });
 */
export function useSwitchAgenciesDataQuery(baseOptions: Apollo.QueryHookOptions<SwitchAgenciesDataQuery, SwitchAgenciesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwitchAgenciesDataQuery, SwitchAgenciesDataQueryVariables>(SwitchAgenciesDataDocument, options);
      }
export function useSwitchAgenciesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwitchAgenciesDataQuery, SwitchAgenciesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwitchAgenciesDataQuery, SwitchAgenciesDataQueryVariables>(SwitchAgenciesDataDocument, options);
        }
export type SwitchAgenciesDataQueryHookResult = ReturnType<typeof useSwitchAgenciesDataQuery>;
export type SwitchAgenciesDataLazyQueryHookResult = ReturnType<typeof useSwitchAgenciesDataLazyQuery>;
export type SwitchAgenciesDataQueryResult = Apollo.QueryResult<SwitchAgenciesDataQuery, SwitchAgenciesDataQueryVariables>;