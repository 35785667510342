import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type WatchVideoPageDataQueryVariables = Types.Exact<{
  videoId: Types.Scalars['ID'];
}>;


export type WatchVideoPageDataQuery = (
  { __typename?: 'Query' }
  & { video?: Types.Maybe<(
    { __typename?: 'RewardVideo' }
    & Pick<Types.RewardVideo, 'id' | 'url' | 'title'>
  )>, availableGoal?: Types.Maybe<(
    { __typename?: 'Goal' }
    & Pick<Types.Goal, 'id' | 'numPoints' | 'numCaribouSponsoredDrawTickets'>
  )> }
);


export const WatchVideoPageDataDocument = gql`
    query WatchVideoPageData($videoId: ID!) {
  video: getRewardVideoById(videoId: $videoId) {
    id
    url
    title
  }
  availableGoal: getMyAvailableGoalForVideoId(videoId: $videoId) {
    id
    numPoints
    numCaribouSponsoredDrawTickets
  }
}
    `;

/**
 * __useWatchVideoPageDataQuery__
 *
 * To run a query within a React component, call `useWatchVideoPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchVideoPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchVideoPageDataQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useWatchVideoPageDataQuery(baseOptions: Apollo.QueryHookOptions<WatchVideoPageDataQuery, WatchVideoPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchVideoPageDataQuery, WatchVideoPageDataQueryVariables>(WatchVideoPageDataDocument, options);
      }
export function useWatchVideoPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchVideoPageDataQuery, WatchVideoPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchVideoPageDataQuery, WatchVideoPageDataQueryVariables>(WatchVideoPageDataDocument, options);
        }
export type WatchVideoPageDataQueryHookResult = ReturnType<typeof useWatchVideoPageDataQuery>;
export type WatchVideoPageDataLazyQueryHookResult = ReturnType<typeof useWatchVideoPageDataLazyQuery>;
export type WatchVideoPageDataQueryResult = Apollo.QueryResult<WatchVideoPageDataQuery, WatchVideoPageDataQueryVariables>;