/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useRefHeight } from "@rewards-web/shared/hooks/use-ref-height";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  useFormatters,
  useLocale,
} from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { RewardsLocaleSelector } from "../../../../shared/rewards-locale-selector";
import { useSetMyRewardsUserLocaleMutation } from "../../language/mutation.generated";

type SetLocaleModalProps = {
  closeModal: () => void;
};

export function SetLocaleModal({ closeModal }: SetLocaleModalProps) {
  const { formatMessage } = useFormatters();
  const { locale } = useLocale();
  const snackbar = useSnackbar();
  const theme = useTheme();
  const [dialogAnimationEnded, setDialogAnimationEnded] = useState(false);

  const isMobileScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("xs")
  );

  const { ref: buttonRef, height: buttonHeight } = useRefHeight({
    isReady: dialogAnimationEnded,
  });

  const [mutate, { loading }] = useSetMyRewardsUserLocaleMutation({
    onError: (error) => {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Set locale modal > set locale error snackbar",
          defaultMessage: "Something went wrong",
        }),
      });
    },
    onCompleted: closeModal,
  });

  return (
    <ResponsiveDialog
      fullScreenOnMobile={true}
      maxWidth="400px"
      open
      onOpened={() => setDialogAnimationEnded(true)}
      mobileType="dialog"
      backgroundColor={theme.palette.background.default}
      paddingBottom={isMobileScreen ? buttonHeight : 0}
    >
      <div
        css={(appTheme: AppTheme) => css`
          ${!isMobileScreen &&
          `border: 5px solid ${appTheme.palette.background.paper};`}
          border-radius: ${!isMobileScreen && appTheme.spacing(2)};
          background-color: ${appTheme.palette.background.default};
          padding: ${appTheme.spacing(3)};
          text-align: center;
        `}
      >
        <Typography variant="h1" color="primary">
          {formatMessage({
            defaultMessage: "Select your language",
            description: "Set locale modal > title",
          })}
        </Typography>
        <Typography variant="body" color="textSecondary">
          {formatMessage({
            defaultMessage:
              "Language can be changed later in your profile settings.",
            description: "Set locale modal > settings note",
          })}
        </Typography>
        <div
          css={(appTheme: AppTheme) =>
            css`
              margin-top: ${appTheme.spacing(3)};
            `
          }
        >
          <RewardsLocaleSelector />
        </div>
      </div>
      <div
        ref={buttonRef}
        css={(appTheme: AppTheme) => css`
          position: ${isMobileScreen ? "fixed" : "sticky"};
          width: 100%;
          bottom: 0;
          background-color: ${appTheme.palette.background.default};
          padding: 5px ${appTheme.spacing(3)};
          box-shadow: -1px 1px 5px 3px rgb(0, 0, 0, 0.15);
        `}
      >
        <Button
          loading={loading}
          css={(appTheme: AppTheme) =>
            css`
              margin-top: ${appTheme.spacing(2)};
              margin-bottom: ${appTheme.spacing(2)};
            `
          }
          color="secondary"
          label={formatMessage({
            defaultMessage: "Proceed",
            description: "Set locale modal > proceed button",
          })}
          onClick={() => mutate({ variables: { locale } })}
        />
      </div>
    </ResponsiveDialog>
  );
}
