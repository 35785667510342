/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { times } from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BackNavigation } from "@rewards-web/shared/components/back-navigation";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { GoalCard } from "../../../../shared/goal/goal-card";
import { GoalCardFragmentFragment } from "../../../../shared/goal/goal-card/goal-card-fragment.generated";
import { GoalCardSkeleton } from "../../../../shared/goal/goal-card/goal-card-skeleton";
import { PageContentContainer } from "../../../../shared/page-content-container";
import { GoalTrackPageHeader } from "./track-page-header";

export interface BaseGoalTrackPageProps {
  title: string;
  icon: JSX.Element;
  additionalGoalCards?: JSX.Element[];
  content: JSX.Element;
  trackData:
    | {
        loading: false;
        pointsEarnedSoFar: number;
        totalEarnablePoints: number;
        availableGoals: GoalCardFragmentFragment[];
        lockedGoals: GoalCardFragmentFragment[];
        achievedGoals: GoalCardFragmentFragment[];
      }
    | { loading: true };
  analyticsData: { [key: string]: any };
}

export function BaseGoalTrackPage({
  title,
  icon,
  additionalGoalCards,
  content,
  trackData,
  analyticsData,
}: BaseGoalTrackPageProps) {
  const track = useTrack();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!trackData.loading) {
      track("Viewed goal track page", analyticsData);
    }
  }, [trackData.loading, analyticsData, track]);

  const backButton = (
    <div
      css={(theme: AppTheme) =>
        css`
          padding-top: ${theme.spacing(3)};
          margin-bottom: ${theme.spacing(-6)};
          position: relative;
          z-index: 1; /* on top of element beneath */
        `
      }
    >
      <BackNavigation
        height={32}
        maxContentWidth={theme.maxContentWidth}
        color="white"
        variant="button"
        onBackClicked={() => {
          track("Goal track page back button pressed", analyticsData);
          navigate("/home");
        }}
      />
    </div>
  );

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
        padding-top: ${theme.spacing(4)};
      `}
    >
      <PageContentContainer
        css={css`
          width: 100%;
          padding-top: 0;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            max-width: 100%;
            padding: 0 ${theme.spacing(2.5)};
          `}
        >
          {backButton}
          <GoalTrackPageHeader
            title={title}
            icon={icon}
            trackProgressData={
              trackData.loading
                ? { loading: true }
                : {
                    loading: false,
                    pointsEarnedSoFar: trackData.pointsEarnedSoFar,
                    totalEarnablePoints: trackData.totalEarnablePoints,
                  }
            }
          />
          <div
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(6)};
                margin-bottom: ${theme.spacing(2)};

                display: flex;
                flex-direction: column;
                row-gap: ${theme.spacing(2.25)};
              `
            }
          >
            {trackData.loading ? (
              <GoalCardSkeleton />
            ) : (
              trackData.availableGoals.map((goal) => (
                <GoalCard goal={goal} cardContext={"subpage"} key={goal.id} />
              ))
            )}
            {additionalGoalCards}
          </div>
          <div
            css={(theme: AppTheme) =>
              css`
                padding: 0 ${theme.spacing(2.75)};
              `
            }
          >
            {content}
          </div>
          <div
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(3.25)};

                display: flex;
                flex-direction: column;
                row-gap: ${theme.spacing(2.25)};
              `
            }
          >
            {trackData.loading
              ? times(3, (i) => <GoalCardSkeleton key={i} />)
              : [
                  ...trackData.lockedGoals,
                  ...trackData.achievedGoals,
                ].map((goal) => (
                  <GoalCard goal={goal} cardContext={"subpage"} key={goal.id} />
                ))}
          </div>
        </div>
      </PageContentContainer>
    </div>
  );
}
