import {
  AppThemeProperties,
  DEFAULT_APP_THEME,
} from "@rewards-web/shared/style/theme";
import { DeepPartial } from "@rewards-web/shared/types";

export const fontFamily = ["Inter", "Helvetica", "Arial", "sans-serif"].join(
  ","
);

const defaultFont = {
  fontFamily,
  fontWeight: 400,
  fontSize: "1.125rem", // 18px
  lineHeight: "1.688rem", // 27px,
};

const defaultHeaderFont = "Lexend, sans-serif";

export const rewardsAppTheme: DeepPartial<AppThemeProperties> = {
  fonts: {
    main: fontFamily,
    headers: defaultHeaderFont,
  },
  palette: {
    primary: {
      main: "#1D51D6",
    },
    text: {
      primary: "#2C393F",
      secondary: DEFAULT_APP_THEME.palette.grey[800],
    },
  },
  buttonBorderRadius: 500,
  shape: { borderRadius: 12 },
  typography: {
    allVariants: defaultFont,
    h1: {
      fontFamily: defaultHeaderFont,
      fontWeight: 700,
      fontSize: "2.5rem", // 40px
      lineHeight: "3.125rem", // 50px
      "@media (max-width:900px)": {
        fontSize: "2.125rem", // 34px
        lineHeight: "2.75rem", // 44px
      },
    },
    h2: {
      fontFamily: defaultHeaderFont,
      fontWeight: 700,
      fontSize: "2.25rem", // 36px
      lineHeight: "2.875rem", // 46px
      "@media (max-width:900px)": {
        fontSize: "1.875rem", // 30px
        lineHeight: "2.5rem", // 40px
      },
    },
    h3: {
      fontFamily: defaultHeaderFont,
      fontWeight: 700,
      fontSize: "2rem", // 32px
      lineHeight: "2.625rem", // 42px
      "@media (max-width:900px)": {
        fontSize: "1.625rem", // 26px
        lineHeight: "2.25rem", // 36px
        fontWeight: 700,
      },
    },
    h4: {
      fontFamily: defaultHeaderFont,
      fontWeight: 700,
      fontSize: "1.75rem", // 28px
      lineHeight: "2.375rem", // 38px
      "@media (max-width:900px)": {
        fontSize: "1.375rem", // 22px
        lineHeight: "2.063rem", // 33px
        fontWeight: 700,
      },
    },
    h5: {
      fontFamily: defaultHeaderFont,
      fontWeight: 700,
      fontSize: "1.5rem", // 24px
      lineHeight: "2.125rem", // 34px
      "@media (max-width:900px)": {
        fontSize: "1.375rem", // 22px
        lineHeight: "1.875rem", // 30px
        fontWeight: 700,
      },
    },
    h6: {
      fontFamily: defaultHeaderFont,
      fontWeight: 700,
      fontSize: "1.25rem", // 20px
      lineHeight: "1.875rem", // 30px
      "@media (max-width:900px)": {
        fontWeight: 700,
        fontSize: "1.25rem", // 20px
        lineHeight: "1.75rem", // 28px
      },
    },
    body: defaultFont,
    subtitle: {
      fontFamily: defaultHeaderFont,
      fontWeight: 600,
      fontSize: "1.125rem", // 18px
      lineHeight: "1.688rem", // 27px
    },
    caption: {
      fontFamily: defaultHeaderFont,
      fontWeight: 600,
      letterSpacing: "0.5px",
      fontSize: "0.75rem", // 12px
      lineHeight: "1.25rem", // 20px
    },
    footnote: {
      fontFamily,
      fontWeight: 400,
      fontSize: "0.875rem", // 14px
      lineHeight: "1.375rem", // 22px
    },
    buttonLarge: {
      fontFamily: defaultHeaderFont,
      fontWeight: 600,
      fontSize: "1.125rem", // 18px
      textTransform: "none",
    },
    buttonSmall: {
      fontFamily: defaultHeaderFont,
      fontWeight: 600,
      fontSize: "0.875rem", // 14px
      textTransform: "none",
    },
  },
};
