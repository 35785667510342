import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type VideoOfferCardDataQueryVariables = Types.Exact<{
  videoOfferId: Types.Scalars['ID'];
}>;


export type VideoOfferCardDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName'>
  ), getRewardVideoOfferById?: Types.Maybe<(
    { __typename?: 'RewardVideoOffer' }
    & Pick<Types.RewardVideoOffer, 'id' | 'pointValue' | 'watched'>
    & { video: (
      { __typename?: 'RewardVideo' }
      & Pick<Types.RewardVideo, 'id' | 'title' | 'url'>
      & { file: (
        { __typename: 'RewardVideoUrlFile' }
        & Pick<Types.RewardVideoUrlFile, 'id' | 'url'>
      ) | { __typename: 'RewardVideoManagedFile' } }
    ) }
  )> }
);


export const VideoOfferCardDataDocument = gql`
    query VideoOfferCardData($videoOfferId: ID!) {
  getMyRewardsUser {
    id
    firstName
  }
  getRewardVideoOfferById(videoOfferId: $videoOfferId) {
    id
    pointValue
    watched
    video {
      id
      title
      url
      file {
        __typename
        ... on RewardVideoUrlFile {
          id
          url
        }
      }
    }
  }
}
    `;

/**
 * __useVideoOfferCardDataQuery__
 *
 * To run a query within a React component, call `useVideoOfferCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoOfferCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoOfferCardDataQuery({
 *   variables: {
 *      videoOfferId: // value for 'videoOfferId'
 *   },
 * });
 */
export function useVideoOfferCardDataQuery(baseOptions: Apollo.QueryHookOptions<VideoOfferCardDataQuery, VideoOfferCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoOfferCardDataQuery, VideoOfferCardDataQueryVariables>(VideoOfferCardDataDocument, options);
      }
export function useVideoOfferCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoOfferCardDataQuery, VideoOfferCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoOfferCardDataQuery, VideoOfferCardDataQueryVariables>(VideoOfferCardDataDocument, options);
        }
export type VideoOfferCardDataQueryHookResult = ReturnType<typeof useVideoOfferCardDataQuery>;
export type VideoOfferCardDataLazyQueryHookResult = ReturnType<typeof useVideoOfferCardDataLazyQuery>;
export type VideoOfferCardDataQueryResult = Apollo.QueryResult<VideoOfferCardDataQuery, VideoOfferCardDataQueryVariables>;