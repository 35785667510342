import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsAppAnalyticsAttributesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsAppAnalyticsAttributesQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'isQA' | 'isDemo'>
  ) }
);


export const RewardsAppAnalyticsAttributesDocument = gql`
    query RewardsAppAnalyticsAttributes {
  getMyRewardsOrganization {
    id
    isQA
    isDemo
  }
}
    `;

/**
 * __useRewardsAppAnalyticsAttributesQuery__
 *
 * To run a query within a React component, call `useRewardsAppAnalyticsAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsAppAnalyticsAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsAppAnalyticsAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsAppAnalyticsAttributesQuery(baseOptions?: Apollo.QueryHookOptions<RewardsAppAnalyticsAttributesQuery, RewardsAppAnalyticsAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsAppAnalyticsAttributesQuery, RewardsAppAnalyticsAttributesQueryVariables>(RewardsAppAnalyticsAttributesDocument, options);
      }
export function useRewardsAppAnalyticsAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsAppAnalyticsAttributesQuery, RewardsAppAnalyticsAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsAppAnalyticsAttributesQuery, RewardsAppAnalyticsAttributesQueryVariables>(RewardsAppAnalyticsAttributesDocument, options);
        }
export type RewardsAppAnalyticsAttributesQueryHookResult = ReturnType<typeof useRewardsAppAnalyticsAttributesQuery>;
export type RewardsAppAnalyticsAttributesLazyQueryHookResult = ReturnType<typeof useRewardsAppAnalyticsAttributesLazyQuery>;
export type RewardsAppAnalyticsAttributesQueryResult = Apollo.QueryResult<RewardsAppAnalyticsAttributesQuery, RewardsAppAnalyticsAttributesQueryVariables>;