import { useTheme } from "@emotion/react";
import { SVGProps } from "react";

export function VideoCompletedBadge(props: SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  return (
    <svg
      width={279}
      height={288}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M234.536 190.446c-3.618-.064-13.505 18.924-18.4 18.929l-12.209-.167c2.794.079 13.195-18.91 17.993-18.928l12.621.166h-.005Z"
          fill="#CD4D58"
        />
        <path
          d="M234.638 190.447c4.502.06 5.695 6.055 3.919 12.336l-12.497-.166c1.777-6.277.93-12.272-3.9-12.332l12.478.162Z"
          fill="#FF6F7A"
        />
        <path
          d="M211.801 190.15c-3.618-.064-13.505 18.924-18.4 18.929l-12.209-.167c2.794.079 13.195-18.914 17.993-18.928l12.621.166h-.005ZM179.263 189.73c-8.393-.107-33.34 12.831-43.222 12.706l-12.214-.166c10.502.143 31.451-12.85 42.814-12.707l12.622.167Z"
          fill="#CD4D58"
        />
        <path
          d="M211.898 190.15c2.013.023 3.239 3.414 1.467 9.691-1.772 6.282 1.175 9.51 3.012 9.534 0 0-9.586-.125-12.497-.167-2.651-.032-4.783-3.252-3.011-9.533 1.776-6.277.758-9.663-1.449-9.691l12.478.162v.004ZM178.407 189.72c3.877.051 7.3 3.182 9.392 9.261 2.068 6.022 3.608 10.074 5.445 10.102 0 0-9.586-.125-12.496-.167-2.651-.032-3.512-4.056-5.446-10.097-1.911-5.977-5.82-9.215-9.373-9.261l12.478.162Z"
          fill="#FF6F7A"
        />
        <path
          d="M93.185 102.083s7.19-3.1 10.993-14.659c3.798-11.564-.375-20.006 13.926-30.492 0 0-14.986 3.344-20.033 16.712-5.048 13.373-2.18 23.36-13.19 29.197l8.309-.758h-.005Z"
          fill="#59CBFF"
        />
        <path
          d="M257.776 58.324c3.317 2.895 5.344 4.056 9.526 5.44-.777 1.36-1.166 2.04-1.943 3.404-4.178-1.388-6.209-2.54-9.526-5.444l1.943-3.4ZM26.558 61.82c2.405-4.407 2.882-6.942 2.244-11.915 2.734.514 4.103.768 6.838 1.281.638 4.982.161 7.512-2.24 11.916-2.739-.513-4.108-.768-6.842-1.281Z"
          fill="#FFCB00"
        />
        <path
          d="M218.899 141.586c-.768 1.605-.888 2.511-.565 4.265l-2.452-.324c-.323-1.753-.199-2.665.569-4.27.981.13 1.476.194 2.448.329ZM62.876 200.151c-7.791 1.929-11.293 4.108-16.466 10.242-2.355-3.78-3.525-5.672-5.88-9.451 5.177-6.133 8.68-8.312 16.475-10.241 2.35 3.779 3.526 5.676 5.876 9.455l-.005-.005ZM222.041 121.862c-2.207-1.198-3.484-1.434-5.969-1.11.255-1.369.38-2.054.634-3.418 2.489-.333 3.762-.097 5.968 1.101-.254 1.369-.384 2.053-.633 3.427ZM99.047 159.302c3.882-1.184 5.593-2.382 8.041-5.62l3.225 4.612c-2.448 3.238-4.155 4.436-8.037 5.624l-3.23-4.616ZM222.374 287.72c-.861-5.176-2.105-7.572-5.844-11.263a4442.77 4442.77 0 0 1 6.468-3.349c3.739 3.686 4.983 6.082 5.844 11.259-2.587 1.341-3.877 2.012-6.468 3.353ZM10.175 114.146c3.997 3.298 6.482 4.279 11.65 4.603a2818.56 2818.56 0 0 0-2.642 6.688c-5.168-.323-7.653-1.304-11.645-4.602 1.054-2.674 1.582-4.011 2.641-6.689h-.004ZM268.547 205.716c4.104.578 6.209.213 9.873-1.726.398 2.267.592 3.4.99 5.667-3.664 1.938-5.76 2.304-9.868 1.725-.398-2.266-.597-3.4-.99-5.662l-.005-.004Z"
          fill="#FF4F5D"
        />
        <path
          d="M111.816 266.22c9.318.389 13.936-.897 21.713-6.046 1.383 4.987 2.082 7.475 3.465 12.462-7.772 5.148-12.39 6.43-21.708 6.041-1.388-4.986-2.082-7.48-3.47-12.457Z"
          fill="#01B5D6"
        />
        <path
          d="M105.769 3.223c-1.142-1.508-5.107-1.712-5.107-1.712-5.872 4.566-6.862 9.626-4.238 16.528 3.206-4.085 2.021-.593 2.313-4.413.342-4.473 2.979-8.475 7.032-10.403ZM234.107 227.942c1.637.509 4.663-1.425 4.663-1.425 1.93-6.448-.004-10.694-5.566-14.404-.212 4.695-1.179 1.494.62 4.464 2.101 3.478 2.258 7.813.283 11.36v.005ZM24 281.777c1.402 1.438 5.594 1.142 5.594 1.142 5.584-5.555 5.968-11 2.323-17.92-2.846 4.709-2.05.884-1.865 4.941.222 4.745-2.04 9.292-6.052 11.841v-.004ZM51.537 53.758c-1.44-1.383-5.59-.957-5.59-.957-5.366 5.69-5.579 11.11-1.739 17.864 2.674-4.764 2.008-.939 1.694-4.959-.366-4.709 1.74-9.293 5.64-11.948h-.005ZM2.407 99.534c2.42.846 7.046-1.888 7.046-1.888 3.22-9.542.555-15.99-7.565-21.828-.569 7.012-1.846 2.178.685 6.712 2.961 5.305 2.966 11.795-.166 17.004ZM153.4 41.218c1.698 2.105 7.357 2.225 7.357 2.225 8.175-6.758 9.368-14.016 5.334-23.748-4.395 5.962-2.855.93-3.109 6.392-.291 6.398-3.882 12.207-9.577 15.13h-.005ZM210.298 25.898c-1.268-.828-4.303.088-4.303.088-3.183 5.014-2.567 9.085 1.263 13.567 1.31-3.937 1.361-.99.551-3.941-.948-3.456-.037-7.175 2.489-9.714Z"
          fill="#FFCB00"
        />
        <path
          d="M13.992 145.966c1.152.967 4.252.426 4.252.426 3.738-4.561 3.608-8.65.374-13.526-1.762 3.724-1.462.81-1.017 3.821.522 3.52-.815 7.078-3.61 9.279ZM21 31.164c1.434 1.772 6.209 1.87 6.209 1.87 6.893-5.709 7.893-11.829 4.488-20.035-3.706 5.033-2.41.787-2.614 5.394-.246 5.393-3.276 10.301-8.078 12.776L21 31.164ZM138.688 132.751c-1.245 1.262-.893 4.949-.893 4.949 5.01 4.815 9.822 5.042 15.851 1.688-4.211-2.41-.819-1.79-4.396-1.545-4.182.292-8.23-1.609-10.562-5.092Z"
          fill="#FF4F5D"
        />
      </g>
      <g filter="url(#b)">
        <path
          d="M132.493 44.39a4.96 4.96 0 0 1 4.899 0l81.691 46.385a4.96 4.96 0 0 1 2.512 4.315v92.627a4.96 4.96 0 0 1-2.512 4.314l-81.691 46.385a4.959 4.959 0 0 1-4.899 0l-81.691-46.385a4.96 4.96 0 0 1-2.512-4.314V95.09c0-1.785.96-3.433 2.512-4.315l81.691-46.385Z"
          fill="#FFC700"
        />
        <path
          d="M221.616 86.315 139.925 39.93a10.09 10.09 0 0 0-9.965 0L48.27 86.315a10.09 10.09 0 0 0-5.11 8.775v92.627c0 3.631 1.952 6.982 5.11 8.775l81.69 46.384a10.088 10.088 0 0 0 9.965 0l81.691-46.384a10.092 10.092 0 0 0 5.108-8.775V95.09c0-3.631-1.951-6.982-5.108-8.775Z"
          stroke="#fff"
          strokeWidth={10.258}
        />
      </g>
      <g filter="url(#c)">
        <circle cx={41.639} cy={142.373} r={21.639} fill="#FFC700" />
        <circle
          cx={41.639}
          cy={142.373}
          r={24.947}
          stroke="#fff"
          strokeWidth={6.615}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.608 129.392c-.259.012-.484.164-.564.384L37.9 138.17H27.837v.001c-.27 0-.51.155-.593.383a.526.526 0 0 0 .223.623l8.175 5.322-3.143 8.688v-.002a.524.524 0 0 0 .229.624c.22.143.517.142.736-.001l8.175-5.322 8.175 5.322a.685.685 0 0 0 .736.001.524.524 0 0 0 .229-.624l-3.143-8.687 8.175-5.322v.001c.217-.144.307-.394.223-.623-.083-.228-.323-.383-.593-.383H45.377l-3.142-8.394v-.001c-.087-.238-.344-.396-.626-.384Z"
        fill={theme.palette.primary.main}
      />
      <defs>
        <filter
          id="b"
          x={33.928}
          y={31.434}
          width={202.028}
          height={224.042}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.052} />
          <feGaussianBlur stdDeviation={2.052} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1855_6201"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1855_6201"
            result="shape"
          />
        </filter>
        <filter
          id="c"
          x={9.282}
          y={112.067}
          width={64.716}
          height={64.716}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.052} />
          <feGaussianBlur stdDeviation={2.052} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1855_6201"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1855_6201"
            result="shape"
          />
        </filter>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h279v288H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
