import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DrawHowItWorksPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DrawHowItWorksPageDataQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName'>
  ) }
);


export const DrawHowItWorksPageDataDocument = gql`
    query DrawHowItWorksPageData {
  organization: getMyRewardsOrganization {
    id
    shortName
  }
}
    `;

/**
 * __useDrawHowItWorksPageDataQuery__
 *
 * To run a query within a React component, call `useDrawHowItWorksPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawHowItWorksPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawHowItWorksPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDrawHowItWorksPageDataQuery(baseOptions?: Apollo.QueryHookOptions<DrawHowItWorksPageDataQuery, DrawHowItWorksPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DrawHowItWorksPageDataQuery, DrawHowItWorksPageDataQueryVariables>(DrawHowItWorksPageDataDocument, options);
      }
export function useDrawHowItWorksPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrawHowItWorksPageDataQuery, DrawHowItWorksPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DrawHowItWorksPageDataQuery, DrawHowItWorksPageDataQueryVariables>(DrawHowItWorksPageDataDocument, options);
        }
export type DrawHowItWorksPageDataQueryHookResult = ReturnType<typeof useDrawHowItWorksPageDataQuery>;
export type DrawHowItWorksPageDataLazyQueryHookResult = ReturnType<typeof useDrawHowItWorksPageDataLazyQuery>;
export type DrawHowItWorksPageDataQueryResult = Apollo.QueryResult<DrawHowItWorksPageDataQuery, DrawHowItWorksPageDataQueryVariables>;