import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type UserMilestoneRewardStepDetailsFragment = (
  { __typename?: 'UserMilestoneRewardStep' }
  & Pick<Types.UserMilestoneRewardStep, 'id' | 'pointValue' | 'isComplete' | 'progress'>
  & { goal: (
    { __typename?: 'VisitMilestoneGoal' }
    & Pick<Types.VisitMilestoneGoal, 'visitNumber'>
  ) | (
    { __typename?: 'HoursMilestoneGoal' }
    & Pick<Types.HoursMilestoneGoal, 'numHours'>
  ) }
);

export const UserMilestoneRewardStepDetailsFragmentDoc = gql`
    fragment UserMilestoneRewardStepDetails on UserMilestoneRewardStep {
  id
  goal {
    ... on VisitMilestoneGoal {
      visitNumber
    }
    ... on HoursMilestoneGoal {
      numHours
    }
  }
  pointValue
  isComplete
  progress
}
    `;