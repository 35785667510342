/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { LeaderboardList } from "./leaderboard-list";
import { useLeaderboardQuery } from "./query.generated";
import { UserPositionCard } from "./user-position-card";

export function LeaderboardPage() {
  const { formatMessage } = useFormatters();

  const mobileScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("sm")
  );

  const { data, loading, error } = useLeaderboardQuery({
    onError: reportError,
  });

  const userData = data?.getMyRewardsUser;

  const leaderboardEntry: {
    place: number;
    name: string;
    points: number;
  }[] = [];

  data?.getRewardsLeaderboard.items.forEach((entry) => {
    leaderboardEntry.push({
      place: entry.place,
      name: entry.userDisplayName,
      points: entry.pointsEarned,
    });
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Leaderboard page > data load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (loading && !data) {
    return <PageLoadingState />;
  }

  return (
    <>
      <SubPageHeader
        pageName={formatMessage({
          defaultMessage: "Leaderboard",
          description: "Leaderboard page > page name",
        })}
        analyticsPageName="Leaderboard"
      />
      <PageContentContainer>
        <ScrollToTopOnMount />
        <div
          css={(theme: AppTheme) => css`
            padding-bottom: ${theme.spacing(4)};
            ${!mobileScreen &&
            css`
              display: grid;
              grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
              column-gap: ${theme.spacing(2)};
            `}
          `}
        >
          {mobileScreen ? (
            <>
              <UserPositionCard
                position={data!.getRewardsLeaderboard.myPlaceOnLeaderboard}
                name={userData!.firstName}
              />
              <LeaderboardList data={leaderboardEntry} />
            </>
          ) : (
            <>
              <LeaderboardList data={leaderboardEntry} />
              <UserPositionCard
                position={data!.getRewardsLeaderboard.myPlaceOnLeaderboard}
                name={userData!.firstName}
              />
            </>
          )}
        </div>
      </PageContentContainer>
    </>
  );
}
