/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  CandidateRecruitmentStepName,
  ReferralRewardStructureReferralRetentionItemDurationUnit,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { GetJobDetailsQuery } from "../query.generated";
import { RewardItem, RewardItemProps } from "./reward-item";

export interface RewardStructureDialogProps {
  open: boolean;
  onClose: () => void;
  referralRewardStructureItems: GetJobDetailsQuery["getMyRewardsOrganization"]["referralRewardStructure"]["items"];
  pointsPerDollar: number;
  maxPointsEarnedPerCandidate: number;
}

export function RewardStructureDialog({
  open,
  onClose,
  referralRewardStructureItems,
  pointsPerDollar,
  maxPointsEarnedPerCandidate,
}: RewardStructureDialogProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();

  const referralRecruitmentStepToTitle: {
    [key in CandidateRecruitmentStepName]: string;
  } = (() => ({
    APPLICATION_SUBMITTED: formatMessage({
      defaultMessage: "Referral applies",
      description:
        "Job details page > reward structure dialog > APPLICATION_SUBMITTED step",
    }),
    COMPLETED_FIRST_SHIFT: formatMessage({
      defaultMessage: "Completes first shift",
      description:
        "Job details page > reward structure dialog > COMPLETED_FIRST_SHIFT step",
    }),
    CONTACTED: formatMessage({
      defaultMessage: "Referral contacted",
      description:
        "Job details page > reward structure dialog > CONTACTED step",
    }),
    COMPLETED_ORIENTATION: formatMessage({
      defaultMessage: "Completes orientation",
      description:
        "Job details page > reward structure dialog > COMPLETED_ORIENTATION step",
    }),
    HIRED: formatMessage({
      defaultMessage: "Referral hired",
      description: "Job details page > reward structure dialog > HIRED step",
    }),
    INTERVIEW_SCHEDULED: formatMessage({
      defaultMessage: "Interview scheduled",
      description:
        "Job details page > reward structure dialog > INTERVIEW_SCHEDULED step",
    }),
    INTERVIEW_SUCCESSFUL: formatMessage({
      defaultMessage: "Interview successful",
      description:
        "Job details page > reward structure dialog > INTERVIEW_SUCCESSFUL step",
    }),
    STARTED_ORIENTATION: formatMessage({
      defaultMessage: "Starts orientation",
      description:
        "Job details page > reward structure dialog > STARTED_ORIENTATION step",
    }),
    STARTED_WORK: formatMessage({
      defaultMessage: "Starts work",
      description:
        "Job details page > reward structure dialog > STARTED_WORK step",
    }),
  }))();

  const rewardItems: Omit<RewardItemProps, "pointsPerDollar">[] = (() =>
    referralRewardStructureItems.map((item) => {
      const rewardType =
        item.reward.__typename === "ReferralRewardStructureDrawTicketReward" ||
        item.reward.__typename ===
          "ReferralRewardStructureManualMonthlyRaffleReward"
          ? "draw"
          : "points";

      const rewardValue = (() => {
        switch (item.reward.__typename) {
          case "ReferralRewardStructureManualMonthlyRaffleReward":
          case "ReferralRewardStructureDrawTicketReward":
            return item.reward.numTickets;
          case "ReferralRewardStructurePointReward":
            return item.reward.pointValue;
          default:
            return 0;
        }
      })();

      switch (item.__typename) {
        case "ReferralRewardStructureJobShareItem":
          return {
            title: formatMessage({
              defaultMessage: "Share job",
              description:
                "Job details page > reward structure dialog > share job step",
            }),
            subtitle: formatMessage(
              {
                defaultMessage:
                  "Limit of {max_shares} job {max_shares, plural, one {share} other {shares}} per month",
                description:
                  "Job details page > reward structure dialog > share job limit",
              },
              { max_shares: item.maxAwardableJobSharesPerMonth }
            ),
            rewardType,
            rewardValue,
          };
        case "ReferralRewardStructureReferralRecruitmentItem":
          return {
            title: referralRecruitmentStepToTitle[item.step],
            rewardType,
            rewardValue,
          };
        case "ReferralRewardStructureReferralRetentionItem":
          return {
            title:
              item.duration.unit ===
              ReferralRewardStructureReferralRetentionItemDurationUnit.Hours
                ? formatMessage(
                    {
                      defaultMessage:
                        "{hours} {hours, plural, one {hour} other {hours}} worked",
                      description:
                        "Job details page > reward structure dialog > hours worked step",
                    },
                    { hours: item.duration.amount }
                  )
                : formatMessage(
                    {
                      defaultMessage:
                        "{months} {months, plural, one {month} other {months}} worked",
                      description:
                        "Job details page > reward structure dialog > months worked step",
                    },
                    { months: item.duration.amount }
                  ),
            rewardType,
            rewardValue,
          };
        default:
          return assertNever(
            item,
            "Unexpected ReferralRewardStructureJobShareItem"
          );
      }
    }))();

  return (
    <ResponsiveDialog
      backgroundColor={theme.palette.background.paper}
      title={formatMessage({
        defaultMessage: "Reward Schedule",
        description: "Job details page > reward structure dialog > title",
      })}
      open={open}
      onClose={onClose}
      maxWidth="588px"
      maxDrawerHeight="70vh"
    >
      <div
        css={(appTheme: AppTheme) =>
          css`
            padding: ${appTheme.spacing(2.5)};
            ${appTheme.breakpoints.up("md")} {
              width: 588px;
            }
          `
        }
      >
        <Card
          variant="flat"
          css={(appTheme: AppTheme) =>
            css`
              background-color: ${appTheme.palette.background.default};
              padding: ${appTheme.spacing(2)};
            `
          }
        >
          <Typography color="textPrimary" variant="subtitle">
            {formatMessage(
              {
                defaultMessage: "Earn up to <bolded>{max_dollars}</bolded>",
                description:
                  "Job details page > reward structure dialog > earn up to title",
              },
              {
                bolded: (nodes) => (
                  <strong
                    css={(appTheme: AppTheme) =>
                      css`
                        color: ${appTheme.palette.primary.main};
                      `
                    }
                  >
                    {nodes}
                  </strong>
                ),
                max_dollars: formatDollars(
                  maxPointsEarnedPerCandidate / pointsPerDollar
                ),
              }
            )}
          </Typography>
          <Typography
            css={(appTheme: AppTheme) =>
              css`
                margin-top: ${appTheme.spacing(0.5)};
              `
            }
            color="textPrimary"
          >
            {formatMessage({
              defaultMessage: "Your referrals earn you rewards at each step!",
              description:
                "Job details page > reward structure dialog > earn up to subtitle",
            })}
          </Typography>
        </Card>
        <div
          css={(appTheme: AppTheme) =>
            css`
              padding-top: ${appTheme.spacing(3)};
              padding-bottom: ${appTheme.spacing(2.25)};
              display: flex;
              align-items: center;
              justify-content: space-between;
            `
          }
        >
          <Typography color="textPrimary" variant="subtitle">
            {formatMessage({
              defaultMessage: "Step",
              description:
                "Job details page > reward structure dialog > step title",
            })}
          </Typography>
          <Typography color="textPrimary" variant="subtitle">
            {formatMessage({
              defaultMessage: "Points earned",
              description:
                "Job details page > reward structure dialog > points earned title",
            })}
          </Typography>
        </div>
        {rewardItems.map((item, idx) => (
          <RewardItem key={idx} {...item} pointsPerDollar={pointsPerDollar} />
        ))}
      </div>
    </ResponsiveDialog>
  );
}
