import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMyRewardNotificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyRewardNotificationsQuery = (
  { __typename?: 'Query' }
  & { listMyRewardNotifications: Array<(
    { __typename?: 'RewardNotification' }
    & Pick<Types.RewardNotification, 'id' | 'messageTitle' | 'messageBody' | 'pointsEarned' | 'pointsDeducted' | 'createdAt'>
  )> }
);


export const GetMyRewardNotificationsDocument = gql`
    query GetMyRewardNotifications {
  listMyRewardNotifications {
    id
    messageTitle
    messageBody
    pointsEarned
    pointsDeducted
    createdAt
  }
}
    `;

/**
 * __useGetMyRewardNotificationsQuery__
 *
 * To run a query within a React component, call `useGetMyRewardNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRewardNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRewardNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRewardNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyRewardNotificationsQuery, GetMyRewardNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyRewardNotificationsQuery, GetMyRewardNotificationsQueryVariables>(GetMyRewardNotificationsDocument, options);
      }
export function useGetMyRewardNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyRewardNotificationsQuery, GetMyRewardNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyRewardNotificationsQuery, GetMyRewardNotificationsQueryVariables>(GetMyRewardNotificationsDocument, options);
        }
export type GetMyRewardNotificationsQueryHookResult = ReturnType<typeof useGetMyRewardNotificationsQuery>;
export type GetMyRewardNotificationsLazyQueryHookResult = ReturnType<typeof useGetMyRewardNotificationsLazyQuery>;
export type GetMyRewardNotificationsQueryResult = Apollo.QueryResult<GetMyRewardNotificationsQuery, GetMyRewardNotificationsQueryVariables>;