import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EvvGoalCardDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EvvGoalCardDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganizationEVVRules: (
    { __typename?: 'RewardsOrganizationEVVRules' }
    & Pick<Types.RewardsOrganizationEvvRules, 'id'>
    & { rules: (
      { __typename?: 'EVVRules' }
      & Pick<Types.EvvRules, 'validClockMethods'>
    ) }
  ) }
);


export const EvvGoalCardDataDocument = gql`
    query EVVGoalCardData {
  getMyRewardsOrganizationEVVRules {
    id
    rules {
      validClockMethods
    }
  }
}
    `;

/**
 * __useEvvGoalCardDataQuery__
 *
 * To run a query within a React component, call `useEvvGoalCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvvGoalCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvvGoalCardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useEvvGoalCardDataQuery(baseOptions?: Apollo.QueryHookOptions<EvvGoalCardDataQuery, EvvGoalCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvvGoalCardDataQuery, EvvGoalCardDataQueryVariables>(EvvGoalCardDataDocument, options);
      }
export function useEvvGoalCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvvGoalCardDataQuery, EvvGoalCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvvGoalCardDataQuery, EvvGoalCardDataQueryVariables>(EvvGoalCardDataDocument, options);
        }
export type EvvGoalCardDataQueryHookResult = ReturnType<typeof useEvvGoalCardDataQuery>;
export type EvvGoalCardDataLazyQueryHookResult = ReturnType<typeof useEvvGoalCardDataLazyQuery>;
export type EvvGoalCardDataQueryResult = Apollo.QueryResult<EvvGoalCardDataQuery, EvvGoalCardDataQueryVariables>;