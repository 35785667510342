/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import { BackNavigation } from "@rewards-web/shared/components/back-navigation";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

import { WhiteBannerBackground } from "../white-banner-background";
import { SubPageHeaderContent } from "./sub-page-header-content";

export const BACK_NAVIGATION_BAR_HEIGHT = 60;
const MAX_CONTENT_WIDTH = 790;

export interface SubPageHeaderProps extends StylableProps {
  pageName: string;
  backTo?: "rewards" | "share_jobs" | "draw" | "settings";
  fixedBackNavigation?: boolean;
  hideWhiteBanner?: boolean;
  bottomPaddingForPointsBanner?: boolean;
  analyticsPageName: string;
  onBackClick?: () => void;
  color?: "primary" | "white";
}

const ROUTES = {
  rewards: "/home",
  share_jobs: "/share-jobs",
  settings: "/settings",
  draw: "/draw",
};

export function SubPageHeader({
  pageName,
  backTo,
  fixedBackNavigation,
  className,
  hideWhiteBanner,
  bottomPaddingForPointsBanner,
  analyticsPageName,
  onBackClick,
  color = "primary",
}: SubPageHeaderProps): JSX.Element {
  const { formatMessage } = useFormatters();

  const mobileOrTablet = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("md")
  );

  const navigate = useNavigate();
  const track = useTrack();

  const backLinkTitle = (() => {
    switch (backTo) {
      case "rewards":
        return formatMessage({
          description: "Back link > rewards page",
          defaultMessage: "Rewards",
        });
      case "share_jobs":
        return formatMessage({
          description: "Back link > jobs page",
          defaultMessage: "Jobs",
        });
      case "settings":
        return formatMessage({
          description: "Back link > settings page",
          defaultMessage: "Settings",
        });
      case "draw":
        return formatMessage({
          description: "Back link > draw page",
          defaultMessage: "Draw",
        });
      case undefined:
        return undefined;
      default:
        assertNever(backTo);
    }
  })();

  return (
    <div
      className={className}
      css={css`
        ${mobileOrTablet &&
        css`
          padding-top: calc(${BACK_NAVIGATION_BAR_HEIGHT}px);
        `}
      `}
    >
      {mobileOrTablet && (
        <BackNavigation
          height={BACK_NAVIGATION_BAR_HEIGHT}
          maxContentWidth={MAX_CONTENT_WIDTH}
          title={pageName}
          centerTitle
          onBackClicked={
            backTo
              ? () => {
                  onBackClick?.();
                  track("Navigation back pressed", {
                    pageName: analyticsPageName,
                  });
                  fixedBackNavigation ? navigate(ROUTES[backTo]) : navigate(-1);
                }
              : undefined
          }
          color={color}
        />
      )}
      {!hideWhiteBanner && (
        <>
          <WhiteBannerBackground
            bottomPaddingForPointsBanner={bottomPaddingForPointsBanner}
          >
            {!mobileOrTablet && (
              <SubPageHeaderContent
                pageName={pageName}
                backLinkTitle={backLinkTitle}
                onBackClick={onBackClick}
              />
            )}
          </WhiteBannerBackground>
        </>
      )}
    </div>
  );
}
