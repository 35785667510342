/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { LatestUnclaimedDrawDataQuery } from "../latest-unclaimed-draw-data.generated";
import drawGiftBoxImage from "./graphic/gift-box.png";

export interface UnclaimedDrawCardProps {
  latestUnclaimedDraw: LatestUnclaimedDrawDataQuery["latestUnclaimedDraw"];
}

export function UnclaimedDrawCard({
  latestUnclaimedDraw,
}: UnclaimedDrawCardProps) {
  const { formatMessage, formatDate } = useFormatters();
  const cardRef = useRef<HTMLDivElement>(null);
  const track = useTrack();
  const onScreen = useOnScreen(cardRef, { threshold: 0.3 });

  useEffect(() => {
    if (onScreen && latestUnclaimedDraw) {
      track("Viewed rewards page unclaimed draw card", {
        draw_id: latestUnclaimedDraw.id,
      });
    }
  }, [onScreen, latestUnclaimedDraw, track]);

  if (!latestUnclaimedDraw) {
    return null;
  }

  return (
    <div
      ref={cardRef}
      css={(appTheme: AppTheme) => css`
        border-radius: 12px;
        border: 1px solid ${appTheme.palette.divider};
        margin-bottom: ${appTheme.spacing(5)};
        background-color: #ffc700;
        padding: ${appTheme.spacing(4)} ${appTheme.spacing(3)}
          ${appTheme.spacing(2)};
        display: flex;
        align-items: center;
        gap: ${appTheme.spacing(3)};
        text-align: left;
      `}
    >
      <img
        src={drawGiftBoxImage}
        alt={formatMessage({
          defaultMessage: "Gift Box",
          description:
            "Rewards page > Unclaimed draw card > gift box img alt text",
        })}
        css={(appTheme: AppTheme) => css`
          max-height: 105px;
          object-fit: contain;
        `}
      />

      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: ${appTheme.spacing(-2)};
        `}
      >
        <Typography
          variant="h4"
          color="primary"
          css={(appTheme: AppTheme) => css`
            margin-bottom: ${appTheme.spacing(0.5)};
          `}
        >
          <FormattedMessage
            defaultMessage="Congratulations, You WON!"
            description="Rewards page > Unclaimed draw card > title"
          />
        </Typography>

        <Typography variant="body" color="primary">
          <FormattedMessage
            defaultMessage="You've won a prize for the draw between {start_date} - {end_date}!"
            description="Rewards page > Unclaimed draw card > description"
            values={{
              start_date: formatDate(
                new Date(latestUnclaimedDraw.scheduledStartDate),
                {
                  month: "short",
                  day: "2-digit",
                }
              ),
              end_date: formatDate(
                new Date(latestUnclaimedDraw.scheduledEndDate),
                {
                  month: "short",
                  day: "2-digit",
                }
              ),
            }}
          />
        </Typography>

        <Button
          label={formatMessage({
            defaultMessage: "Claim your prize",
            description:
              "Rewards page > Unclaimed draw card > Claim button label",
          })}
          css={(appTheme: AppTheme) => css`
            border: 1px solid ${appTheme.palette.primary.main};
            border-radius: ${appTheme.buttonBorderRadius}px;
            background-color: transparent;
            color: ${appTheme.palette.primary.main};
            margin-top: ${appTheme.spacing(4)};
            width: fit-content;
          `}
          linkTo={`/draw/${latestUnclaimedDraw.id}/winners`}
          onClick={() => {
            track("Clicked rewards page unclaimed draw card", {
              draw_id: latestUnclaimedDraw.id,
            });
          }}
        />
      </div>
    </div>
  );
}
