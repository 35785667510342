/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  FormattedNumber,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsStarIcon } from "../../../../shared/points-star-icon";
import { RewardsProgressBar } from "../../../../shared/rewards-progress-bar";

export interface CurrentMilestoneDetailsCardProps {
  milestoneId?: string;
  totalNumberOfHoursCompleted: number;
  numberOfHoursCompletedTowardsMilestone?: string;
  hoursBetweenCurrentAndNextMilestone?: string;
  completedPercentage: number;
  currentMilestonePoints: string;
}

export function CurrentMilestoneDetailsCard({
  milestoneId,
  totalNumberOfHoursCompleted,
  numberOfHoursCompletedTowardsMilestone,
  hoursBetweenCurrentAndNextMilestone,
  completedPercentage,
  currentMilestonePoints,
}: CurrentMilestoneDetailsCardProps): JSX.Element {
  const track = useTrack();

  useEffect(() => {
    track("Viewed shift milestone page milestones details card", {
      nextMilestoneId: milestoneId,
      totalNumberOfHoursCompleted: totalNumberOfHoursCompleted,
      shiftHoursCompletedTowardCurrentMilestone: numberOfHoursCompletedTowardsMilestone,
      percentCompletion: completedPercentage,
      hoursBetweenCurrentAndNextMilestone,
      currentMilestonePoints,
    });
  }, [
    milestoneId,
    completedPercentage,
    numberOfHoursCompletedTowardsMilestone,
    totalNumberOfHoursCompleted,
    track,
    hoursBetweenCurrentAndNextMilestone,
    currentMilestonePoints,
  ]);

  return (
    <Card borderRadius={10}>
      <CardContent>
        {milestoneId && (
          <>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              <Typography
                variant="h3"
                css={(theme: AppTheme) => css`
                  color: ${theme.palette.primary.main};
                  flex-grow: 1;
                `}
              >
                <FormattedMessage
                  description="Shift milestones > next milestone progress card label"
                  defaultMessage="Next milestone"
                />
              </Typography>
              <div
                css={css`
                  display: flex;
                `}
              >
                <PointsStarIcon color="gold" />
                <Typography
                  color="primary"
                  variant="body"
                  css={(theme: AppTheme) => css`
                    font-weight: 600;
                    font-size: 0.85rem;
                    margin-left: ${theme.spacing(1)};
                  `}
                >
                  <FormattedMessage
                    description="Shift milestones > next milestone card point value"
                    defaultMessage="{points, number} {points, plural, one {point} other {points}}"
                    values={{ points: currentMilestonePoints }}
                  />
                </Typography>
              </div>
            </div>
            <RewardsProgressBar completedPercentage={completedPercentage} />
            <div
              css={(theme: AppTheme) => css`
                text-align: right;
                margin: ${theme.spacing(1.5)} 0;
              `}
            >
              <FormattedMessage
                description="Shift milestones > next milestone card hour progress indicator"
                defaultMessage="{hours_completed, number} / <bold>{hours_required, number} {hours_required, plural, one {hour} other {hours}}</bold>"
                values={{
                  hours_completed: numberOfHoursCompletedTowardsMilestone,
                  hours_required: hoursBetweenCurrentAndNextMilestone,
                  bold: (nodes) => (
                    <Typography
                      variant="inherit"
                      color="primary"
                      css={css`
                        font-weight: bold;
                      `}
                    >
                      {nodes}
                    </Typography>
                  ),
                }}
              />
            </div>
            <Divider />
          </>
        )}

        <div
          css={(theme: AppTheme) => css`
            display: flex;
            ${milestoneId &&
            css`
              margin-top: ${theme.spacing(1.5)};
            `}
            margin-bottom: ${theme.spacing(-1)};
          `}
        >
          <Typography
            variant="body"
            css={(theme: AppTheme) => css`
              color: ${theme.palette.primary.main};
              flex-grow: 1;
            `}
          >
            <FormattedMessage
              description="Shift milestones > next milestone card total hours worked label"
              defaultMessage="Total hours worked"
            />
          </Typography>
          <Typography
            variant="body"
            css={(theme: AppTheme) => css`
              color: ${theme.palette.primary.main};
              text-align: right;
              flex-grow: 1;
            `}
          >
            <FormattedNumber value={totalNumberOfHoursCompleted} />
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
