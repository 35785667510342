import { useApolloClient } from "@apollo/client";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { reportError } from "@rewards-web/shared/modules/error";

import {
  PointBalanceDataDocument,
  PointBalanceDataQuery,
} from "./point-balance-data.generated";

interface PointBalanceContextValue {
  refreshPointBalance(): void;
  addLocalPoints(amount: number): void;
  setPendingPointValue(amount: number): void;
  clearPendingPointValue(): void;
  loading: boolean;
  error: Error | undefined;
  computedBalance: number | undefined;
}

export const PointBalanceContext = createContext<PointBalanceContextValue>({
  refreshPointBalance: () => {},
  addLocalPoints: () => {},
  setPendingPointValue: () => {},
  clearPendingPointValue: () => {},
  error: undefined,
  loading: false,
  computedBalance: undefined,
});

interface PointBalanceValue {
  /**
   * Cached value of the last value fetched from the server.
   */
  latestKnownServerBalance: number | undefined;

  /**
   * Temporary points added via `addLocalPoints`.
   *
   * This is only cleared when `clearPendingPointValue` is invoked.
   */
  pointsAddedLocally: number | undefined;

  /**
   * Temporary point value reduction, added via `setPendingPointValue`
   */
  pendingPointValue: number | undefined;
}

interface PointBalanceProviderProps {
  children: ReactNode;
}

/**
 * Fetches and stores the user's current point balance (from the server).
 *
 * If points are earned while using the app, points can be added manually
 * using the `addLocalPoints` method. This is helpful if we know points are earned,
 * but there is a delay in the user receiving the points (due to eventual consistency).
 *
 */
export function PointBalanceProvider({ children }: PointBalanceProviderProps) {
  const apolloClient = useApolloClient();
  const [pointBalance, setPointBalance] = useState<PointBalanceValue>({
    latestKnownServerBalance: undefined,
    pointsAddedLocally: undefined,
    pendingPointValue: undefined,
  });
  const [loadingFromServer, setLoadingFromServer] = useState(false);
  const [error, setError] = useState<Error>();

  const loadPointBalanceFromServer = useCallback(async () => {
    setError(undefined);
    setLoadingFromServer(true);

    try {
      const res = await apolloClient.query<PointBalanceDataQuery>({
        query: PointBalanceDataDocument,
        fetchPolicy: "network-only",
      });

      const {
        pointsAvailableToRedeem: freshPointBalanceFromServer,
      } = res.data.getMyRewardsUser;

      setPointBalance((prev) => {
        if (prev.latestKnownServerBalance === freshPointBalanceFromServer) {
          // keep override in-tact, since point balance hasn't changed from our cached value
          return prev;
        }

        return {
          ...prev,
          latestKnownServerBalance: freshPointBalanceFromServer,

          // if server value changed from the cached value,
          // clear points that were added locally
          pointsAddedLocally: undefined,
        };
      });
    } catch (error) {
      reportError(error);

      if (!pointBalance.latestKnownServerBalance) {
        setError(new Error("Could not load point balance from server"));
      }
    } finally {
      setLoadingFromServer(false);
    }
  }, [apolloClient, pointBalance.latestKnownServerBalance]);

  useEffect(() => {
    const unsubscribeApolloOnResetStore = apolloClient.onResetStore(
      loadPointBalanceFromServer
    );

    return () => {
      unsubscribeApolloOnResetStore();
    };
  }, [apolloClient, loadPointBalanceFromServer]);

  const addLocalPoints = useCallback(
    (amount: number) => {
      setPointBalance((prev) => {
        return {
          ...prev,
          pointsAddedLocally: (prev.pointsAddedLocally ?? 0) + amount,
        };
      });
    },
    [setPointBalance]
  );

  const setPendingPointValue = useCallback((amount: number) => {
    setPointBalance((prev) => {
      return {
        ...prev,
        pendingPointValue: amount,
      };
    });
  }, []);

  const clearPendingPointValue = useCallback(() => {
    setPointBalance((prev) => {
      return {
        ...prev,
        pendingPointValue: undefined,
      };
    });
  }, []);

  const computedPointBalance = (() => {
    if (pointBalance.latestKnownServerBalance === undefined) {
      return undefined;
    }

    let computedPointBalance = pointBalance.latestKnownServerBalance;

    if (pointBalance.pointsAddedLocally) {
      computedPointBalance += pointBalance.pointsAddedLocally;
    }

    if (pointBalance.pendingPointValue) {
      // subtract pending value,
      // and protect from going negative
      computedPointBalance = Math.max(
        computedPointBalance - pointBalance.pendingPointValue,
        0
      );
    }

    return computedPointBalance;
  })();

  return (
    <PointBalanceContext.Provider
      value={{
        refreshPointBalance: loadPointBalanceFromServer,
        addLocalPoints,
        setPendingPointValue,
        clearPendingPointValue,
        error,
        loading: loadingFromServer,
        computedBalance: computedPointBalance,
      }}
    >
      {children}
    </PointBalanceContext.Provider>
  );
}

interface UsePointBalanceReturnValue {
  /**
   * The computed balance, which takes into account
   * the current known point balance from the survey,
   * and any applied local modifications
   * (e.g. via `addLocalPoints` or `setPendingPointValue`)
   */
  computedBalance: number | undefined;
  error: Error | undefined;
  loading: boolean;

  /**
   * Refreshes the server point balance.
   */
  refreshPointBalance(): void;

  /**
   * Adds local points as an override.
   *
   * This is useful if we know points should be added,
   * but may take several seconds to update asynchronously
   * (and will be eventually consistent), so the user will see
   * the most recent assumed point balance.
   */
  addLocalPoints(amount: number): void;

  /**
   * Sets a specific point amount to "pending".
   * This can be used if we are announcing to the user that they
   * were awarded points, and will subtract this amount from the
   * point balance displayed to the user.
   *
   * Once the user acknowledges that they earned points,
   * `clearPendingPointValue` can be invoked, which will
   * show the point value increasing to the actual amount
   * (to give the user an animation for excitement, indicating that their
   * point value just increased).
   */
  setPendingPointValue(amount: number): void;

  /**
   * Clears a pending point value that was set by `setPendingPointValue`.
   *
   * Note that this MUST be called to clear the pending point value.
   */
  clearPendingPointValue(): void;
}

interface UsePointBalanceParams {
  refreshOnMount?: boolean;
}

export function usePointBalance(
  { refreshOnMount }: UsePointBalanceParams = { refreshOnMount: false }
): UsePointBalanceReturnValue {
  const {
    computedBalance,
    error,
    loading,
    refreshPointBalance,
    addLocalPoints,
    setPendingPointValue,
    clearPendingPointValue,
  } = useContext(PointBalanceContext);

  useEffect(() => {
    if (refreshOnMount) {
      refreshPointBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      computedBalance,
      error,
      loading,
      refreshPointBalance,
      addLocalPoints,

      setPendingPointValue,
      clearPendingPointValue,
    }),
    [
      computedBalance,
      error,
      loading,
      refreshPointBalance,
      addLocalPoints,
      setPendingPointValue,
      clearPendingPointValue,
    ]
  );
}
