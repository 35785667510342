/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { ReferralStatusV2 } from "@rewards-web/shared/graphql-types";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { formatPointsAsDollars } from "../../../../shared/lib/format-points-as-dollars";
import { scrollToElement } from "../../../../shared/lib/scroll-to-element";
import { PageContentContainer } from "../../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../../shared/scroll-to-top-on-mount";
import { useReferralsProgressPageDataQuery } from "./query.generated";
import { ReferralsCard } from "./referrals-card";
import { ReferralsIcon } from "./referrals-icon";

function NoReferralsContent(props: {
  maxPointsEarnedPerCandidate: number;
  pointsPerDollar: number;
}): JSX.Element {
  const { formatMessage } = useFormatters();

  return (
    <div
      css={(theme: AppTheme) => css`
        text-align: center;
        margin: ${theme.spacing(4)} auto;
      `}
    >
      <ReferralsIcon height={100} />

      <Typography
        variant="h2"
        css={(theme: AppTheme) =>
          css`
            color: ${theme.palette.primary.main};
            padding: ${theme.spacing(2)} ${theme.spacing(8)};
          `
        }
      >
        {formatMessage({
          description: "Jobs page > My referrals tab > No Referrals Title",
          defaultMessage: "You have no referrals yet.",
        })}
      </Typography>

      <Typography variant="body">
        {formatMessage(
          {
            description:
              "Jobs page > My referrals tab > No Referrals Description",
            defaultMessage:
              "Each referral can earn you up to {max_dollars_earned_per_candidate}.",
          },
          {
            max_dollars_earned_per_candidate: formatPointsAsDollars(
              props.maxPointsEarnedPerCandidate,
              props.pointsPerDollar
            ),
          }
        )}
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        label={formatMessage({
          description: "Jobs page > My referrals tab > Share Jobs Button Text",
          defaultMessage: "Start Sharing Jobs",
        })}
        css={(theme: AppTheme) => css`
          display: block;
          text-align: center;
          margin: ${theme.spacing(4)} auto;
          max-width: 280px;
        `}
        linkTo="/jobs"
      />
    </div>
  );
}

export function MyReferralsPageContents(): JSX.Element | null {
  const [selectedReferralId] = useQueryParam("referral_id");
  const { formatMessage } = useFormatters();
  const { data: pageQueryData, loading } = useReferralsProgressPageDataQuery({
    onError: reportError,
  });
  const cardRefsByReferralId = useRef<Map<string, HTMLDivElement>>(new Map());

  useEffect(() => {
    if (
      selectedReferralId &&
      pageQueryData && // ensure scroll happens after page data loads
      cardRefsByReferralId.current.get(selectedReferralId)
    ) {
      scrollToElement(cardRefsByReferralId.current.get(selectedReferralId)!);
    }
  }, [selectedReferralId, pageQueryData]);

  if (loading || !pageQueryData) {
    return <PageLoadingState />;
  }

  const referrals = pageQueryData.getMyReferralsProgressV2.referrals;
  const organization = pageQueryData.getMyRewardsOrganization;
  const referrerDisplayName = pageQueryData.getMyRewardsUser.displayName;

  if (!referrals) {
    return null;
  }

  return (
    <PageContentContainer
      css={(theme: AppTheme) => css`
        padding-bottom: ${theme.spacing(4)};
      `}
    >
      <ScrollToTopOnMount />
      {referrals.length === 0 ? (
        <NoReferralsContent
          maxPointsEarnedPerCandidate={organization.maxPointsEarnedPerCandidate}
          pointsPerDollar={organization.pointsPerDollar}
        />
      ) : (
        <div>
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              padding-bottom: ${theme.spacing(5)};
              justify-content: space-around;

              ${theme.breakpoints.up("sm")} {
                justify-content: center;
              }
            `}
          >
            <ReferralsIcon height={100} />

            <div
              css={(theme: AppTheme) => css`
                margin-left: ${theme.spacing(2)};
              `}
            >
              <Typography variant="caption">
                {formatMessage({
                  description:
                    "Jobs page > My referrals tab > Page subtitle line 2",
                  defaultMessage:
                    "Keep up the great work sharing jobs and bringing in referrals!",
                })}
              </Typography>

              <Typography variant="caption">
                {formatMessage(
                  {
                    description:
                      "Jobs page > My referrals tab > Page subtitle line 1",
                    defaultMessage:
                      "You can receive more points as your referrals continue to work at {organization_name}.",
                  },
                  {
                    organization_name: organization?.shortName,
                  }
                )}
              </Typography>
            </div>
          </div>

          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: stretch;
              gap: ${theme.spacing(2)};
              flex-wrap: wrap;
              > * {
                flex-grow: 1;
              }
            `}
          >
            {referrals
              .filter((referral) => {
                if (
                  referral.statusV2 === ReferralStatusV2.Inactive &&
                  !referral.wasHired
                ) {
                  // hide candidates which are archived & were not hired
                  return false;
                }

                return true;
              })
              .map((referral) => (
                <ReferralsCard
                  ref={(ref) => {
                    if (ref) {
                      cardRefsByReferralId.current.set(referral.id, ref);
                    } else {
                      cardRefsByReferralId.current.delete(referral.id);
                    }
                  }}
                  key={referral.id}
                  candidateId={referral.id}
                  organizationName={organization.shortName}
                  referrerDisplayName={referrerDisplayName}
                  candidateDisplayName={referral.candidateDisplayName}
                  candidateEmail={referral.candidateEmail ?? null}
                  pointsAwarded={referral.pointsAwarded}
                  pointsAwardable={referral.pointsAwardable}
                  status={referral.statusV2}
                  steps={referral.stepsV2 ?? []}
                  wasHired={referral.wasHired}
                />
              ))}
          </div>
        </div>
      )}
    </PageContentContainer>
  );
}
