/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableHeaders } from "@rewards-web/shared/components/table-components/table-headers";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedMessage,
  FormattedNumber,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import BronzeMedalIcon from "../icons/bronze-medal-icon";
import GoldMedalIcon from "../icons/gold-medal-icon";
import SilverMedalIcon from "../icons/silver-medal-icon";

export interface LeaderboardListProps {
  data: {
    place: number;
    name: string;
    points: number;
  }[];
}

export function LeaderboardList({ data }: LeaderboardListProps): JSX.Element {
  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) => css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-width: 0px;
        padding: 0 ${theme.spacing(2)};
      `}
    >
      <Table>
        <TableHeaders>
          <TableRow>
            <TableHeader divider align="left">
              <Typography variant="subtitle">
                <FormattedMessage
                  description="Leaderboard page > rankings rank heading"
                  defaultMessage="Rank"
                />
              </Typography>
            </TableHeader>
            <TableHeader divider align="left">
              <Typography variant="subtitle">
                <FormattedMessage
                  description="Leaderboard page > rankings staff name heading"
                  defaultMessage="Staff Name"
                />
              </Typography>
            </TableHeader>
            <TableHeader divider align="right">
              <Typography variant="subtitle">
                <FormattedMessage
                  description="Leaderboard page > rankings points earned heading"
                  defaultMessage="Points Earned"
                />
              </Typography>
            </TableHeader>
          </TableRow>
        </TableHeaders>

        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell divider={data.length === index + 1 ? false : true}>
                {item.place === 1 && <GoldMedalIcon />}
                {item.place === 2 && <SilverMedalIcon />}
                {item.place === 3 && <BronzeMedalIcon />}

                {item.place > 3 && (
                  <Typography
                    variant="subtitle"
                    css={css`
                      font-weight: 600;
                    `}
                  >
                    <FormattedMessage
                      description="Leaderboard page > ranking number"
                      defaultMessage="{position, number}<sup>{position, selectordinal, one {st} two {nd} few {rd} other {th}}</sup>"
                      values={{
                        position: item.place,
                        sup: (nodes) => (
                          <sup
                            css={css`
                              font-weight: 400;
                            `}
                          >
                            {nodes}
                          </sup>
                        ),
                      }}
                    />
                  </Typography>
                )}
              </TableCell>

              <TableCell divider={data.length !== index + 1}>
                <Typography variant="body" color="textSecondary">
                  {item.name}
                </Typography>
              </TableCell>
              <TableCell divider={data.length !== index + 1} align="right">
                <Typography variant="body" color="textSecondary">
                  <FormattedNumber value={item.points} />
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}
