/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../shared/page-content-container";
import { GiftcardRedemptionInfo } from "../giftcard-redemption-info";
import { GiftcardRedemptionInfoV2 } from "../giftcard-redemption-info/gift-card-redemption-info-v2";
import { PaycheckRedemptionInfo } from "../paycheck-redemption-info";
import { useRewardsDataQuery } from "../query.generated";

export function RedeemPage() {
  const pageDataQuery = useRewardsDataQuery({ fetchPolicy: "cache-first" });
  const giftCardsRedemptionOptionsEnabled = useFeatureFlag(
    "rewards-app-gift-card-redemptions-tab-temp"
  );

  if (pageDataQuery.loading && !pageDataQuery.data) {
    return <PageLoadingState />;
  }
  const orgData = pageDataQuery.data!.getMyRewardsOrganization;
  const userData = pageDataQuery.data!.getMyRewardsUser;

  const redemptionMethod = orgData.allowedRedemptionMethods[0];
  const pointsPerDollar = orgData.pointsPerDollar;
  return (
    <div
      css={(appTheme: AppTheme) => css`
        background-color: ${appTheme.palette.background.paper};
        height: 100%;
      `}
    >
      <PageContentContainer
        css={(appTheme: AppTheme) =>
          css`
            padding-top: ${appTheme.spacing(3.75)};
          `
        }
      >
        {redemptionMethod === RedemptionMethod.GenericGiftCardLink &&
          (giftCardsRedemptionOptionsEnabled ? (
            <GiftcardRedemptionInfoV2
              minimumPointsToRedeem={orgData.minimumPointsNeededToRedeem}
              pointsPerDollar={pointsPerDollar}
              email={userData.email}
              phoneNumber={userData.phoneNumber}
            />
          ) : (
            <GiftcardRedemptionInfo />
          ))}
        {redemptionMethod === RedemptionMethod.Payroll && (
          <PaycheckRedemptionInfo />
        )}
      </PageContentContainer>
    </div>
  );
}
