import * as Types from '@rewards-web/shared/graphql-types';

import { GoalCardFragmentFragment } from '../../../shared/goal/goal-card/goal-card-fragment.generated';
import { gql } from '@apollo/client';
import { GoalCardFragmentFragmentDoc } from '../../../shared/goal/goal-card/goal-card-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'referralsEnabled' | 'userReferralLinkDisabled'>
  ), currentDraw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'status'>
    & { prizeStructure: (
      { __typename: 'DrawPrizeStructureSinglePrizeMultipleWinners' }
      & Pick<Types.DrawPrizeStructureSinglePrizeMultipleWinners, 'id' | 'totalPrizePointValue'>
      & { prize: (
        { __typename: 'DrawPrizePoints' }
        & Pick<Types.DrawPrizePoints, 'id' | 'pointValue'>
      ) | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'id'>
      ) }
    ) | (
      { __typename: 'DrawPrizeStructureTieredPrizesMultipleWinners' }
      & Pick<Types.DrawPrizeStructureTieredPrizesMultipleWinners, 'id' | 'totalPrizePointValue'>
      & { prizes: Array<{ __typename: 'DrawPrizePoints' } | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'pointValue'>
      )> }
    ) }
  )>, getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'goalsEnabled' | 'maxPointsEarnedPerCandidate' | 'pointsPerDollar'>
    & { referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & Pick<Types.ReferralRewardStructure, 'id'>
      & { jobShareStructure: (
        { __typename?: 'ReferralRewardStructureJobShareItem' }
        & Pick<Types.ReferralRewardStructureJobShareItem, 'maxAwardableJobSharesPerMonth'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets' | 'numWinnersSelectedPerMonth' | 'prizeText'>
        ) }
      ) }
    ) }
  ), countMyJobSharesThisMonth: (
    { __typename?: 'CountMyJobSharesForRange' }
    & Pick<Types.CountMyJobSharesForRange, 'numShares'>
  ), getMyEmployeePendingAndSentScheduledRecognitionPoints: Array<(
    { __typename?: 'RecognitionPoints' }
    & Pick<Types.RecognitionPoints, 'id' | 'pointValue' | 'scheduledToSendOn' | 'status'>
    & { category?: Types.Maybe<(
      { __typename?: 'RecognitionCategory' }
      & Pick<Types.RecognitionCategory, 'id' | 'name'>
    )> }
  )>, listMyRewardOffers: Array<(
    { __typename?: 'RewardOffer' }
    & Pick<Types.RewardOffer, 'id' | 'externalId' | 'completed' | 'cardTitle' | 'cardButtonLabel' | 'cardPointsText' | 'cardCompletedPointsText' | 'cardGraphicKey' | 'numCompletions'>
    & { nextReward?: Types.Maybe<(
      { __typename?: 'RewardOfferNextReward' }
      & Pick<Types.RewardOfferNextReward, 'id' | 'pointValue' | 'numDrawTickets'>
    )>, structure: { __typename: 'RewardOfferStructureUnlimited' } | { __typename: 'RewardOfferStructureOneTime' } | { __typename: 'RewardOfferStructurePeriodic' } | { __typename: 'RewardOfferStructureDiscreteProgress' } | (
      { __typename: 'RewardOfferStructurePunchCard' }
      & Pick<Types.RewardOfferStructurePunchCard, 'numPunchesPerCard' | 'pointValue' | 'numDrawTickets'>
    ), action: (
      { __typename?: 'RewardOfferModalAction' }
      & Pick<Types.RewardOfferModalAction, 'id' | 'modalContentsHtml'>
    ) | (
      { __typename?: 'RewardOfferInAppLinkAction' }
      & Pick<Types.RewardOfferInAppLinkAction, 'id' | 'linkPath'>
    ) | (
      { __typename?: 'RewardOfferExternalLinkAction' }
      & Pick<Types.RewardOfferExternalLinkAction, 'id' | 'url'>
    ), completions: Array<(
      { __typename?: 'RewardOfferCompletion' }
      & Pick<Types.RewardOfferCompletion, 'id' | 'pointValue' | 'numDrawTickets'>
    )> }
  )>, getMyRewardsUserAvailableGoals: Array<(
    { __typename?: 'Goal' }
    & GoalCardFragmentFragment
  )>, getMyRewardsUserNextLockedGoals: Array<(
    { __typename?: 'Goal' }
    & GoalCardFragmentFragment
  )>, getMyRewardsUserAchievedGoalsForHomePage: Array<(
    { __typename?: 'Goal' }
    & GoalCardFragmentFragment
  )>, getMyReferralsProgressV2: (
    { __typename?: 'ReferralsProgressV2Response' }
    & { referrals?: Types.Maybe<Array<(
      { __typename?: 'Referral' }
      & Pick<Types.Referral, 'id' | 'candidateDisplayName' | 'pointsAwarded' | 'pointsAwardable'>
      & { stepsV2: Array<(
        { __typename?: 'ReferralStepV2' }
        & Pick<Types.ReferralStepV2, 'completed' | 'pointValue'>
      )> }
    )>> }
  ) }
);


export const RewardsPageDataDocument = gql`
    query RewardsPageData {
  getMyRewardsUser {
    id
    referralsEnabled
    userReferralLinkDisabled
  }
  currentDraw: getMyCurrentDraw {
    id
    prizeStructure {
      __typename
      ... on BaseDrawPrizeStructure {
        id
      }
      ... on DrawPrizeStructureSinglePrizeMultipleWinners {
        prize {
          __typename
          ... on BaseDrawPrize {
            id
          }
          ... on DrawPrizePoints {
            pointValue
          }
        }
        totalPrizePointValue
      }
      ... on DrawPrizeStructureTieredPrizesMultipleWinners {
        prizes {
          __typename
          ... on DrawTieredPrizePoints {
            pointValue
          }
        }
        totalPrizePointValue
      }
    }
    status
  }
  getMyRewardsOrganization {
    id
    goalsEnabled
    maxPointsEarnedPerCandidate
    pointsPerDollar
    referralRewardStructure {
      id
      jobShareStructure {
        maxAwardableJobSharesPerMonth
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
          ... on ReferralRewardStructureDrawTicketReward {
            numTickets
          }
          ... on ReferralRewardStructureManualMonthlyRaffleReward {
            numTickets
            numWinnersSelectedPerMonth
            prizeText
          }
        }
      }
    }
  }
  countMyJobSharesThisMonth {
    numShares
  }
  getMyEmployeePendingAndSentScheduledRecognitionPoints {
    id
    pointValue
    scheduledToSendOn
    status
    category {
      id
      name
    }
  }
  listMyRewardOffers {
    id
    externalId
    completed
    cardTitle
    cardButtonLabel
    cardPointsText
    cardCompletedPointsText
    cardGraphicKey
    numCompletions
    nextReward {
      id
      pointValue
      numDrawTickets
    }
    structure {
      __typename
      ... on RewardOfferStructurePunchCard {
        numPunchesPerCard
        pointValue
        numDrawTickets
      }
    }
    action {
      ... on RewardOfferModalAction {
        id
        modalContentsHtml
      }
      ... on RewardOfferInAppLinkAction {
        id
        linkPath
      }
      ... on RewardOfferExternalLinkAction {
        id
        url
      }
    }
    completions {
      id
      pointValue
      numDrawTickets
    }
  }
  getMyRewardsUserAvailableGoals {
    ...GoalCardFragment
  }
  getMyRewardsUserNextLockedGoals {
    ...GoalCardFragment
  }
  getMyRewardsUserAchievedGoalsForHomePage {
    ...GoalCardFragment
  }
  getMyReferralsProgressV2(statusIn: [ACTIVE], limit: 7) {
    referrals {
      id
      candidateDisplayName
      pointsAwarded
      pointsAwardable
      stepsV2 {
        completed
        pointValue
      }
    }
  }
}
    ${GoalCardFragmentFragmentDoc}`;

/**
 * __useRewardsPageDataQuery__
 *
 * To run a query within a React component, call `useRewardsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<RewardsPageDataQuery, RewardsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsPageDataQuery, RewardsPageDataQueryVariables>(RewardsPageDataDocument, options);
      }
export function useRewardsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsPageDataQuery, RewardsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsPageDataQuery, RewardsPageDataQueryVariables>(RewardsPageDataDocument, options);
        }
export type RewardsPageDataQueryHookResult = ReturnType<typeof useRewardsPageDataQuery>;
export type RewardsPageDataLazyQueryHookResult = ReturnType<typeof useRewardsPageDataLazyQuery>;
export type RewardsPageDataQueryResult = Apollo.QueryResult<RewardsPageDataQuery, RewardsPageDataQueryVariables>;