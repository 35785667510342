/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useScrollToTop } from "@rewards-web/shared/hooks/use-scroll-to-top";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface ConfirmationPageProps {
  pointsEarnedForOptingIn: number | null;
  whiteLabelledTitle: string | undefined;
}

export function ConfirmationPage({
  pointsEarnedForOptingIn,
  whiteLabelledTitle,
}: ConfirmationPageProps): JSX.Element {
  useScrollToTop();

  return (
    <>
      <Typography
        variant="h1"
        color="primary"
        css={(theme: AppTheme) => css`
          text-align: center;
          margin-top: ${theme.spacing(2)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <FormattedMessage
          description="Opt in page > post opt-in success screen title"
          defaultMessage="Thank you!"
        />
      </Typography>

      <Typography
        variant="body"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          text-align: center;
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        {pointsEarnedForOptingIn ? (
          <FormattedMessage
            description="Opt in page > post opt-in success screen detail with opt-in bonus"
            defaultMessage="Check your personal phone for a link to access {rewards_program_name} and keep earning!"
            values={{
              rewards_program_name: whiteLabelledTitle,
            }}
          />
        ) : (
          <FormattedMessage
            description="Opt in page > post opt-in success screen detail"
            defaultMessage="Check your personal phone for a link to access {rewards_program_name}. Check it out to start earning!"
            values={{
              rewards_program_name: whiteLabelledTitle,
            }}
          />
        )}
      </Typography>
    </>
  );
}
