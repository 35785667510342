/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Avatar, alpha } from "@material-ui/core";
import { take } from "lodash";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface RadioCardProps {
  selected: boolean;
  label: string;
  primaryColor: string;
  onClick(): void;
  subLabel?: string;
}

export function RadioCard(props: RadioCardProps) {
  const labelAcronym = take(props.label.split(" "), 2)
    .map((word) => word[0])
    .join("");

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: ${theme.spacing(2)};
        border: 1px solid
          ${props.selected ? theme.palette.primary.main : "#dddddd"};
        background-color: ${props.selected
          ? alpha(theme.palette.primary.main, 0.1)
          : "white"};
        color: ${theme.palette.primary.main};
        padding: ${theme.spacing(2)};
        margin: ${theme.spacing(1)};
        cursor: pointer;
      `}
      onClick={props.onClick}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Avatar
          css={css`
            width: 48px;
            height: 48px;
            color: white;
            background-color: ${props.primaryColor};
          `}
          alt={props.label}
        >
          {labelAcronym}
        </Avatar>

        <div
          css={(theme: AppTheme) => css`
            margin-left: ${theme.spacing(2)};
          `}
        >
          <Typography
            variant="subtitle"
            css={css`
              word-break: break-word;
              font-weight: 500;
              font-size: 1rem;
            `}
          >
            {props.label}
          </Typography>
          {props.subLabel && (
            <Typography variant="body">{props.subLabel}</Typography>
          )}
        </div>
      </div>
      {props.selected && (
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: ${theme.palette.primary.main};
          `}
        >
          <Typography
            css={(theme: AppTheme) => css`
              color: ${theme.palette.primary.contrastText};
            `}
          >
            ✓
          </Typography>
        </div>
      )}
    </div>
  );
}
