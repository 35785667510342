import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { DrawStatus } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { DrawClosedState } from "./draw-closed-state";
import { DrawComingSoonState } from "./draw-coming-soon-state";
import { DrawOpenState } from "./draw-open-state";
import { useOrganizationRunDrawPageDataQuery } from "./organization-run-draw-page-data.generated";

export function CurrentDrawPage() {
  const { formatMessage } = useFormatters();
  const pageDataQuery = useOrganizationRunDrawPageDataQuery({
    onError: reportError,
  });

  if (pageDataQuery.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Draw page > error loading data",
          defaultMessage:
            "An unexpected error occurred. Please try again later.",
        })}
      />
    );
  }

  if (!pageDataQuery.data) {
    return <PageLoadingState />;
  }

  const { currentDraw, myRewardOffers, lastDraw } = pageDataQuery.data;
  const now = new Date();

  if (!currentDraw || new Date(currentDraw.scheduledOpenDate) > now) {
    return <DrawComingSoonState />;
  }

  if (
    new Date(currentDraw.scheduledCloseDate) < now ||
    currentDraw.status === DrawStatus.Closed
  ) {
    return (
      <DrawClosedState
        drawId={currentDraw.id}
        drawClosedAt={new Date(currentDraw.scheduledCloseDate)}
        prizeStructure={currentDraw.prizeStructure}
        lastDraw={lastDraw}
      />
    );
  }

  return (
    <DrawOpenState
      drawId={currentDraw.id}
      scheduledCloseDate={new Date(currentDraw.scheduledCloseDate)}
      prizeStructure={currentDraw.prizeStructure}
      myRewardOffers={myRewardOffers}
      lastDraw={lastDraw}
      announcementVideoUrl={currentDraw.announcementVideo?.url ?? undefined}
    />
  );
}
