/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { lighten } from "@material-ui/core";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedNumber } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

const RADIUS = 12;
const COLOR_LIGHTEN_AMOUNT = 0.5;

interface JobFilterBadgeProps extends StylableProps {
  numSelectedFilters: number;
}

export function JobFilterBadge({
  numSelectedFilters,
  className,
}: JobFilterBadgeProps) {
  return (
    <Typography
      className={className}
      variant="body"
      css={(theme: AppTheme) => css`
        background-color: ${lighten(
          theme.palette.primary.main,
          COLOR_LIGHTEN_AMOUNT
        )};
        color: ${theme.palette.getContrastText(
          lighten(theme.palette.primary.main, COLOR_LIGHTEN_AMOUNT)
        )};
        width: ${RADIUS * 2}px;
        height: ${RADIUS * 2}px;
        border-radius: ${RADIUS}px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
      `}
    >
      <FormattedNumber value={numSelectedFilters} />
    </Typography>
  );
}
