/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/types";

import headerBgUrl from "./header-bg.png";

interface WhiteBannerBackgroundProps {
  children?: ReactNode;
  bottomPaddingForPointsBanner?: boolean;
}

export function WhiteBannerBackground({
  children,
  bottomPaddingForPointsBanner,
}: WhiteBannerBackgroundProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        content: "";
        background-color: white;
        width: 100%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        ${bottomPaddingForPointsBanner &&
        css`
          ${theme.breakpoints.up("sm")} {
            padding-bottom: ${theme.spacing(4)};
          }
        `}
        ${bottomPaddingForPointsBanner &&
        css`
          ${theme.breakpoints.up("sm")} {
            min-height: 80px;
          }
          min-height: 50px;
        `}

        background-image: url("${headerBgUrl}");
        background-position: center;
        ${theme.breakpoints.up("md")} {
          background-image: none;
        }
      `}
    >
      {children}
    </div>
  );
}
