import * as GraphQLTypes from "@rewards-web/shared/graphql-types";

import booksUrl from "./graphics/books.png";
import briefcaseUrl from "./graphics/briefcase.png";
import calendarUrl from "./graphics/calendar.png";
import checklistClipboardUrl from "./graphics/checklist-clipboard.png";
import clockUrl from "./graphics/clock.png";
import coinsUrl from "./graphics/coins.png";
import dollarCoinUrl from "./graphics/dollar-coin.png";
import friendsUrl from "./graphics/friends.png";
import giftUrl from "./graphics/gift.png";
import heartUrl from "./graphics/heart.png";
import mailUrl from "./graphics/mail.png";
import mobileCheckUrl from "./graphics/mobile-check.png";
import partyPopperUrl from "./graphics/party-popper.png";
import phoneUrl from "./graphics/phone.png";
import planCareUrl from "./graphics/plan-care.png";
import shareUrl from "./graphics/share.png";
import starUrl from "./graphics/star.png";
import teamUrl from "./graphics/team.png";
import trophyUrl from "./graphics/trophy.png";
import walletUrl from "./graphics/wallet.png";

export function getRewardOfferGraphicUrl(
  key: GraphQLTypes.RewardOfferCardGraphicKey
) {
  switch (key) {
    case GraphQLTypes.RewardOfferCardGraphicKey.CirclingDateOnCalendar:
      return calendarUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PeopleInClassroom:
      return booksUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PeopleInPhones:
      return phoneUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.WomanWalletCelebration:
      return walletUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.WomenGiftsCelebration:
      return partyPopperUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PersonOnTrophyGifts:
      return giftUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.WomanWalkingOnCoins:
      return coinsUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.Evv:
      return mobileCheckUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.WomanLoggingIn:
      return mobileCheckUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.ReferAndEarn:
      return dollarCoinUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PeopleCommunicatingOffice:
      return friendsUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.WomanGivingPresentation:
      return briefcaseUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PeopleTrophyCelebration:
      return trophyUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PeopleCoinsInJar:
      return coinsUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PlayVideo:
      return heartUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.VacantDesk:
      return clockUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.WomanChecklist:
      return checklistClipboardUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.ManCirclingDateOnCalendar:
      return calendarUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.RecognizingEmployees:
      return starUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.PhoneChatApp:
      return shareUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.ChecklistClipboard:
      return checklistClipboardUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.HealthChecklistClipboard:
      return planCareUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.WomanSchedulingOnPhone:
      return calendarUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.MailNotification:
      return mailUrl;
    case GraphQLTypes.RewardOfferCardGraphicKey.FolksWorkingOutside:
      return teamUrl;
    default:
      return phoneUrl;
  }
}
