/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import {
  faHouse as regularHouseIcon,
  faBriefcase as regularBriefcaseIcon,
  faTicket as regularTicketIcon,
  faRankingStar as regularRankingStarIcon,
  faBell as regularBellIcon,
  faGift,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faHouse as solidHouseIcon,
  faBriefcase as solidBriefcaseIcon,
  faTicket as solidTicketIcon,
  faRankingStar as solidRankingStarIcon,
  faBell as solidBellIcon,
  faGift as solidGiftIcon,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { compact } from "lodash";
import { Link, matchPath, useLocation, useMatch } from "react-router-dom";

import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { StylableProps } from "@rewards-web/shared/types";

import { NavigationIconWithBadge } from "../navigation-icon-with-badge";
import { useNotificationBadgeData } from "./use-notification-badge-data";

export interface RewardsAppNavigationTabsProps extends StylableProps {
  referralsEnabled: boolean;
  leaderboardEnabled: boolean;
  drawsEnabled: boolean;
  redemptionMethod: RedemptionMethod;
}

export function RewardsAppNavigationTabs({
  className,
  referralsEnabled,
  leaderboardEnabled,
  redemptionMethod,
  drawsEnabled,
}: RewardsAppNavigationTabsProps): JSX.Element {
  const location = useLocation();
  const match = useMatch("/:route/*");

  const {
    rewardNotificationBadge,
    drawNotificationBadge,
  } = useNotificationBadgeData();

  const notificationsDisabledFlag = useFeatureFlag(
    "rewards-app-notifications-disabled-temp"
  );
  const giftCardsRedemptionOptionsEnabled = useFeatureFlag(
    "rewards-app-gift-card-redemptions-tab-temp"
  );

  const rewardsTabEnabled = (() => {
    // tab is only enabled for orgs on gift cards (currently),
    // since payroll agencies don't have an exciting storefront on that page yet
    if (
      redemptionMethod === RedemptionMethod.GenericGiftCardLink &&
      giftCardsRedemptionOptionsEnabled
    ) {
      return true;
    }

    return false;
  })();

  const notificationsTabEnabled =
    !rewardsTabEnabled && !notificationsDisabledFlag;

  const rootRoute = (() => {
    if (!match) {
      return null;
    }

    switch (match.params.route) {
      case "profile":
      case "points-summary":
      case "shift-milestones":
      case "video-offers":
      case "power-hours-offer":
      case "goal-track":
      case "watch-video":
        return "/home";
      default:
        return `/${match.params.route}`;
    }
  })();

  const { formatMessage } = useFormatters();

  const tabs = compact([
    (() => {
      const label = formatMessage({
        description: "Navigation tabs > home",
        defaultMessage: "Home",
      });

      return {
        label,
        path: "/home",
        iconElement: (
          <FontAwesomeIcon
            size="xl"
            icon={
              matchPath("/home", location.pathname)
                ? solidHouseIcon
                : regularHouseIcon
            }
          />
        ),
      };
    })(),
    rewardsTabEnabled && {
      label: formatMessage({
        description: "Rewards tabs > rewards",
        defaultMessage: "Rewards",
      }),
      path: `/my-rewards`,
      iconElement: (
        <NavigationIconWithBadge
          iconElement={
            <FontAwesomeIcon
              size="xl"
              icon={
                matchPath("/my-rewards", location.pathname)
                  ? faGift
                  : solidGiftIcon
              }
            />
          }
          badge={rewardNotificationBadge}
        />
      ),
    },
    referralsEnabled && {
      label: formatMessage({
        description: "Navigation tabs > jobs",
        defaultMessage: "Jobs",
      }),
      path: `/jobs`,
      iconElement: (
        <FontAwesomeIcon
          size="xl"
          icon={
            matchPath("/jobs", location.pathname)
              ? solidBriefcaseIcon
              : regularBriefcaseIcon
          }
        />
      ),
    },
    leaderboardEnabled && {
      label: formatMessage({
        description: "Navigation tabs > leaderboard",
        defaultMessage: "Leaderboard",
      }),
      path: `/leaderboard`,
      iconElement: (
        <FontAwesomeIcon
          size="xl"
          icon={
            matchPath("/leaderboard", location.pathname)
              ? solidRankingStarIcon
              : regularRankingStarIcon
          }
        />
      ),
    },
    notificationsTabEnabled && {
      label: formatMessage({
        description: "Navigation tabs > notifications",
        defaultMessage: "Notifications",
      }),
      path: `/notifications`,
      iconElement: (
        <FontAwesomeIcon
          size="xl"
          icon={
            matchPath("/notifications", location.pathname)
              ? solidBellIcon
              : regularBellIcon
          }
        />
      ),
    },
    drawsEnabled && {
      label: formatMessage({
        description: "Navigation tabs > draw",
        defaultMessage: "Draw",
      }),
      path: `/draw`,
      iconElement: (
        <NavigationIconWithBadge
          iconElement={
            <FontAwesomeIcon
              size="xl"
              icon={
                matchPath("/draw", location.pathname)
                  ? solidTicketIcon
                  : regularTicketIcon
              }
            />
          }
          badge={drawNotificationBadge}
        />
      ),
    },
  ]);

  return (
    <ClassNames>
      {({ css, cx, theme }) => {
        const backgroundColor = theme.palette.background.paper;

        const bottomNavigationClasses = {
          root: css`
            min-width: calc(100% / ${tabs.length});
            padding: 0;
            path {
              fill: ${theme.palette.text.secondary};
            }
            &.Mui-selected path {
              fill: ${theme.palette.primary.main};
            }
            &.Mui-selected {
              padding-top: 0;
            }
          `,
          label: css`
            color: ${theme.palette.text.secondary};
            &.Mui-selected {
              color: ${theme.palette.primary.main};
            }
            padding-top: ${theme.spacing(1.5)};
            font-weight: 600;
            &,
            &.Mui-selected {
              text-align: center;
              line-height: 0.75rem;
              font-size: 0.55rem;
              ${theme.breakpoints.up(350)} {
                font-size: 0.65rem;
              }
              ${theme.breakpoints.up(600)} {
                font-size: 0.7rem;
              }
            }
          `,
        };

        return (
          <BottomNavigation
            component={"nav" as any}
            className={cx(
              className,
              css`
                height: 100%;
                background-color: ${backgroundColor};
                border-top: 1px solid ${theme.palette.divider};
              `
            )}
            showLabels
            value={rootRoute}
          >
            {tabs.map((tab) => (
              <BottomNavigationAction
                key={tab.path}
                classes={bottomNavigationClasses}
                label={tab.label}
                value={tab.path}
                icon={tab.iconElement}
                component={Link}
                to={tab.path}
              />
            ))}
          </BottomNavigation>
        );
      }}
    </ClassNames>
  );
}
