/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import gift from "./gift.png";

export interface DrawWinnersAnnouncedCardProps {
  lastDraw:
    | {
        id: string;
        winnersAnnounced: boolean;
        myRewardsUserHasTicketsInDraw: boolean;
        myRewardsUserViewedWinners: boolean;
      }
    | null
    | undefined;
  marginTop?: number;
  marginBottom?: number;
}

export function DrawWinnersAnnouncedCard({
  lastDraw,
  marginTop,
  marginBottom,
}: DrawWinnersAnnouncedCardProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const cardRef = useRef<HTMLDivElement>(null);

  const onScreen = useOnScreen(cardRef, { threshold: 0.5 });

  useEffect(() => {
    if (onScreen) {
      track("Viewed Draw Winners Announced banner", {
        drawId: lastDraw?.id,
      });
    }
  }, [track, onScreen, lastDraw]);

  if (!lastDraw?.winnersAnnounced) {
    return null;
  }

  const {
    id: lastDrawId,
    myRewardsUserHasTicketsInDraw,
    myRewardsUserViewedWinners,
  } = lastDraw;

  const getBodyText = (): JSX.Element => {
    if (myRewardsUserViewedWinners) {
      return (
        <FormattedMessage
          defaultMessage="View winners from the most recent draw."
          description="Draw page > winners announced card > body text > already viewed winners"
        />
      );
    } else if (myRewardsUserHasTicketsInDraw) {
      return (
        <FormattedMessage
          defaultMessage="Check to see if you’re one of the lucky winners from the most recent draw."
          description="Draw page > winners announced card > body text > has tickets in draw"
        />
      );
    } else {
      return (
        <FormattedMessage
          defaultMessage="Check to see who the winners are from the most recent draw."
          description="Draw page > winners announced card > body text > no tickets in draw"
        />
      );
    }
  };

  return (
    <Card
      css={css`
        background-color: #2c393f;
        margin-bottom: ${marginBottom ?? 20}px;
        margin-top: ${marginTop ?? 0}px;
      `}
      ref={cardRef}
    >
      <CardContent
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: row;
          padding-bottom: ${theme.spacing(2)} !important;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            margin-right: ${theme.spacing(2)};
            display: flex;
            align-items: center;
            width: 46px;
          `}
        >
          <img
            src={gift}
            alt={formatMessage({
              defaultMessage: "Gift",
              description: "Draw page > winners announced card > gift alt text",
            })}
          />
        </div>
        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
          `}
        >
          <Typography variant="subtitle" color="white">
            <FormattedMessage
              defaultMessage="Winners announced!"
              description="Draw page > winners announced card > title"
            />
          </Typography>
          <Typography variant="footnote" color="white">
            {getBodyText()}
          </Typography>
        </div>

        <div
          css={(theme: AppTheme) => css`
            margin-left: ${theme.spacing(2.5)};
            display: flex;
            align-items: center;
          `}
        >
          <Button
            linkTo={`/draw/${lastDrawId}/winners`}
            onClick={() => {
              track("Clicked View Winners button", {
                drawId: lastDrawId,
              });
            }}
            label={formatMessage({
              defaultMessage: "View",
              description: "Draw page > winners announced card > button label",
            })}
            size="medium"
            color="secondary"
            width="auto"
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(1)} ${theme.spacing(2.5)};
              height: fit-content;
            `}
          />
        </div>
      </CardContent>
    </Card>
  );
}
