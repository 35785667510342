import { SVGProps } from "react";

export function PromotedJobsHeaderIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12px"
      height="20px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6.127A6.155 6.155 0 0 0 9.39 1.07 5.902 5.902 0 0 0 3.822.417C1.976 1.15.603 2.768.155 4.737a6.207 6.207 0 0 0 1.43 5.526v8.212c0 .186.097.359.255.454a.503.503 0 0 0 .512 0L6 16.849l3.648 2.084a.51.51 0 0 0 .512-.004.532.532 0 0 0 .255-.454v-8.212A6.168 6.168 0 0 0 12 6.127Zm-6-5.07a4.92 4.92 0 0 1 3.515 1.485 5.12 5.12 0 0 1 1.456 3.585 5.122 5.122 0 0 1-1.456 3.585A4.92 4.92 0 0 1 6 11.197a4.92 4.92 0 0 1-3.515-1.485A5.122 5.122 0 0 1 1.03 6.127a5.125 5.125 0 0 1 1.458-3.583A4.925 4.925 0 0 1 6 1.056Zm3.387 16.522L6.251 15.8a.502.502 0 0 0-.502 0l-3.136 1.78v-6.397c.169.118.344.223.521.322l.029.016c.157.086.315.164.477.236l.037.014c.078.036.161.068.242.099l.094.033c.122.045.243.082.368.118l.173.048.184.042c.093.021.188.04.283.056l.14.022c.105.013.213.026.32.037h.12c.13 0 .26.015.39.015h.01c.131 0 .26 0 .39-.015h.119c.108-.011.215-.024.32-.037l.14-.022c.096-.016.19-.036.284-.056l.183-.042.173-.048c.125-.037.247-.074.368-.118l.095-.033c.08-.031.162-.063.241-.099l.037-.014c.162-.072.32-.15.478-.236l.028-.016c.177-.098.353-.21.521-.322l.01 6.397Z"
        fill="#5D5D5E"
      />
    </svg>
  );
}
