/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

interface TabProps {
  label: string;
  to: string;
  state?: Record<string, any>;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  badgeCount?: number;
}

export function Tab(props: TabProps): JSX.Element {
  return (
    <Link
      to={props.to}
      state={props.state}
      css={css`
        text-decoration: none;
      `}
      onClick={props.onClick}
    >
      <Typography
        css={(theme: AppTheme) => css`
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
          background-color: ${props.selected
            ? theme.palette.background.default
            : "rgba(255, 255, 255, 0.1)"};
          color: ${props.selected
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText};
          text-align: center;
          font-size: 1rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          height: 100%;
        `}
        variant="subtitle"
      >
        {props.label}

        {typeof props.badgeCount === "number" && props.badgeCount > 0 && (
          <span
            css={(theme: AppTheme) => css`
              display: inline-block;
              background-color: ${props.selected
                ? theme.palette.primary.main
                : theme.palette.background.default};
              color: ${props.selected
                ? theme.palette.primary.contrastText
                : theme.palette.primary.main};
              border-radius: 50%;
              min-width: 1.2rem;
              min-height: 1.2rem;
              line-height: 1.2rem;
              text-align: center;
              font-size: 0.75rem;
              font-weight: bold;
              margin-left: 0.25rem;
              vertical-align: text-top;
            `}
          >
            {props.badgeCount}
          </span>
        )}
      </Typography>
    </Link>
  );
}
