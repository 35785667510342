/** @jsxImportSource @emotion/react */
import { useState } from "react";

import { GoalStatus } from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import shiftClockImageUrl from "../../graphics/shift-clock.png";
import { BaseGoalCard } from "../base-goal-card";
import { HourBasedGoalModal } from "../hour-based-goal-modal";
import { useCommonBaseGoalCardProps, useGoalActivation } from "../lib";

export function LastMinuteShiftGoalCard({ goal, cardContext }: GoalCardProps) {
  const [imInterestedModalOpen, setImInterestedModalOpen] = useState(false);
  const { formatMessage, formatDate } = useFormatters();
  const { activateGoalAndTrack } = useGoalActivation();
  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/last-minute-shift",
  });

  const buttonLabel = formatMessage({
    defaultMessage: "I'm interested",
    description: "Goal card > last minute shift > button label",
  });

  const detailText = (() => {
    switch (goal.status) {
      case GoalStatus.Achieved:
        return formatDate(goal.achievedAt, {
          weekday: "long",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      case GoalStatus.Available:
      case GoalStatus.Locked:
        return goal.activatedAt
          ? formatMessage({
              defaultMessage: "We will contact you soon",
              description:
                "Goal card > last minute shift > activated details text",
            })
          : formatMessage({
              defaultMessage:
                "Sign up for last-minute shift opportunities and earn points if you complete one",
              description: "Goal card > last minute shift > details text",
            });
      default:
        return undefined;
    }
  })();

  const modalTitle = formatMessage({
    defaultMessage: "Got it!",
    description: "Goal card > last minute shift modal > title",
  });

  const modalDescription = formatMessage({
    defaultMessage:
      "Your coordinator will contact you when a last minute shift becomes available",
    description: "Goal card > last minute shift modal > description",
  });

  const modalButtonLabel = formatMessage({
    defaultMessage: "Got it!",
    description: "Goal card > weekend shift modal > close button",
  });

  const handlePrimaryButtonClick = async () => {
    if (!goal.activatedAt) {
      await activateGoalAndTrack(goal.id);
    }
    setImInterestedModalOpen(true);
  };

  return (
    <>
      <HourBasedGoalModal
        open={imInterestedModalOpen}
        onClose={() => setImInterestedModalOpen(false)}
        title={modalTitle}
        description={modalDescription}
        buttonLabel={modalButtonLabel}
      />

      <BaseGoalCard
        {...commonBaseGoalCardProps}
        icon={<img src={shiftClockImageUrl} alt="clock" />}
        detailText={detailText}
        primaryButtonProps={
          goal.status === GoalStatus.Available
            ? {
                label: buttonLabel,
                disabled: Boolean(goal.activatedAt),
                onClick: handlePrimaryButtonClick,
              }
            : undefined
        }
      />
    </>
  );
}
