/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import calendarImageUrl from "./draw-calendar.png";
import { useDrawHowItWorksPageDataQuery } from "./draw-how-it-works-page-data.generated";
import trophiesImageUrl from "./draw-how-it-works-trophies.png";
import starImageUrl from "./draw-star.png";
import { EarnTicketsIcon } from "./earn-tickets-icon";
import { WinnerIcon } from "./winner-icon";

export function DrawHowItWorksPage() {
  const theme = useTheme();
  const { formatMessage } = useFormatters();
  const query = useDrawHowItWorksPageDataQuery({ onError: reportError });

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: ${theme.palette.primary.main};
        color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <SubPageHeader
        pageName={formatMessage({
          description: "Draw how it works page > page title",
          defaultMessage: "How It Works",
        })}
        analyticsPageName="Draw How It Works"
        backTo="draw"
      />

      <PageContentContainer>
        <ScrollToTopOnMount />

        <img
          src={trophiesImageUrl}
          alt={formatMessage({
            defaultMessage: "Trophies",
            description: "Draw how it works page > trophy image alt",
          })}
          css={(theme: AppTheme) => css`
            display: block;
            margin: 0 auto;
            width: 220px;
            max-width: 80%;
            margin-bottom: ${theme.spacing(3)};
          `}
        />

        <Typography
          variant="h5"
          component="h2"
          textAlign="center"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <FormattedMessage
            description="Draw how it works page > heading"
            defaultMessage="Easy chances to win prizes!"
          />
        </Typography>

        <div
          css={(theme: AppTheme) => css`
            width: 100%;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 14px;
            padding: ${theme.spacing(2)};
          `}
        >
          <table
            css={(theme: AppTheme) => css`
              & tr td:first-child {
                padding-right: ${theme.spacing(2)};
              }
              & tr:not(:last-child) td {
                padding-bottom: ${theme.spacing(2)};
              }
            `}
          >
            {[
              {
                icon: <EarnTicketsIcon color={theme.palette.primary.main} />,
                heading: formatMessage({
                  description:
                    "Draw how it works page > table > earn tickets heading",
                  defaultMessage: "Earn tickets",
                }),
                description: formatMessage({
                  description:
                    "Draw how it works page > table > earn tickets description",
                  defaultMessage: "Complete simple tasks to earn draw tickets",
                }),
              },
              {
                icon: <WinnerIcon />,
                heading: formatMessage({
                  description:
                    "Draw how it works page > table > draw ends heading",
                  defaultMessage: "Draw ends",
                }),
                description: formatMessage(
                  {
                    description:
                      "Draw how it works page > table > draw ends description",
                    defaultMessage: "{org_name} will announce winner(s)",
                  },
                  {
                    org_name: query.data?.organization.shortName ?? "",
                  }
                ),
              },
              {
                icon: (
                  <img
                    src={starImageUrl}
                    alt={formatMessage({
                      description:
                        "Draw how it works page > table > claim prize icon",
                      defaultMessage: "Star",
                    })}
                  />
                ),
                heading: formatMessage({
                  description:
                    "Draw how it works page > table > claim prize heading",
                  defaultMessage: "Claim prize",
                }),
                description: formatMessage(
                  {
                    description:
                      "Draw how it works page > table > claim prize description",
                    defaultMessage:
                      "{org_name} will give winner(s) their prize",
                  },
                  {
                    org_name: query.data?.organization.shortName ?? "",
                  }
                ),
              },
              {
                icon: (
                  <img
                    src={calendarImageUrl}
                    alt={formatMessage({
                      description:
                        "Draw how it works page > table > next draw icon",
                      defaultMessage: "Calendar",
                    })}
                  />
                ),
                heading: formatMessage({
                  description:
                    "Draw how it works page > table > next draw heading",
                  defaultMessage: "Next draw",
                }),
                description: formatMessage({
                  description:
                    "Draw how it works page > table > next draw description",
                  defaultMessage: "Get excited about the next draw!",
                }),
              },
            ].map((item) => (
              <tr key={item.heading}>
                <td>{item.icon}</td>
                <td>
                  <Typography variant="subtitle" component="h3">
                    {item.heading}
                  </Typography>
                  <Typography variant="body">{item.description}</Typography>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </PageContentContainer>
    </div>
  );
}
