import { IntlErrorCode } from "@formatjs/intl";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  createIntlCache,
  createIntl,
  RawIntlProvider,
  MessageFormatElement,
} from "react-intl";

import { Locale } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { reportError } from "../error";
import { I18nContext, DEFAULT_LOCALE } from "./context";

const cache = createIntlCache();

export type LocaleMessages =
  | Record<string, string>
  | Record<string, MessageFormatElement[]>;

interface LocaleProviderProps {
  children: ReactNode;
  getLocaleMessages(locale: Locale): Promise<LocaleMessages>;

  /**
   * If provided, controls the locale
   */
  locale?: Locale;
}

/**
 * Provides internationalization (i18n) capabilities to the app that provides this.
 *
 * The currently-selected `locale` defaults to `en`, but can be
 * changed using `setLocale`
 */
export function LocaleProvider({
  children,
  getLocaleMessages,
  locale: controlledLocale = DEFAULT_LOCALE,
}: LocaleProviderProps) {
  const [locale, setLocale] = useState<{
    locale: Locale;
    messages: LocaleMessages;
  }>();

  const handleSetLocale = useCallback(
    async (nextLocale: Locale) => {
      if (nextLocale === locale?.locale) {
        return; // locale is already set, no need to load it
      }

      try {
        const [messages] = await Promise.all([
          getLocaleMessages(nextLocale),
          importLocaleAwarePolyfills(nextLocale),
        ]);
        setLocale({ locale: nextLocale, messages });
      } catch (error) {
        reportError(error);
      }
    },
    [locale, getLocaleMessages]
  );

  useEffect(() => {
    // set initial locale on first load,
    // or if the prop changes
    handleSetLocale(controlledLocale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledLocale]);

  const currentLocale = locale?.locale ?? controlledLocale;

  const intl = useMemo(
    () =>
      createIntl(
        {
          locale: mapGQLLocaleToStandardLocale(currentLocale),
          defaultLocale: DEFAULT_LOCALE,
          messages: locale?.messages ?? {},
          onError: (error) => {
            switch (error.code) {
              case IntlErrorCode.MISSING_TRANSLATION:
                // suppress these, since they are excessive, and we fall back to english.
                // our current translation process makes it fairly possible
                // to have missing translations, and they will get fixed eventually
                return;
              default:
                reportError(error);
            }
          },
        },
        cache
      ),
    [currentLocale, locale?.messages]
  );

  return (
    <I18nContext.Provider
      value={{
        setLocale: handleSetLocale,
        locale: locale?.locale || controlledLocale,
      }}
    >
      <RawIntlProvider value={intl}>{locale && children}</RawIntlProvider>
    </I18nContext.Provider>
  );
}

/**
 * Lazy-loads polyfills which include locale-aware data.
 */
async function importLocaleAwarePolyfills(locale: Locale) {
  const standardLocale = mapGQLLocaleToStandardLocale(locale);

  try {
    await import(
      `@formatjs/intl-relativetimeformat/locale-data/${standardLocale}`
    );
    await import(`@formatjs/intl-listformat/locale-data/${standardLocale}`);
  } catch (error) {
    reportError(error);
  }
}

export function mapGQLLocaleToStandardLocale(locale: Locale): string {
  switch (locale) {
    case Locale.En:
      return "en";
    case Locale.Es:
      return "es";
    case Locale.Ht:
      return "ht";
    case Locale.Ru:
      return "ru";
    case Locale.ZhCn:
      return "zh-CN";
    case Locale.Fr:
      return "fr";
    case Locale.KmKh:
      return "km-KH";
    case Locale.Vi:
      return "vi";
    case Locale.Uk:
      return "uk";
    default:
      assertNever(locale, `Unexpected locale ${locale}`);
  }
}
