/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
  Maybe,
  RewardsOrganizationWhiteLabelConfig,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import caribouLogoUrl from "../caribou-logo.png";

interface HeaderLogoProps {
  organizationLogoImageUrl: string | null;
  whiteLabelConfig:
    | Maybe<
        {
          __typename?: "RewardsOrganizationWhiteLabelConfig" | undefined;
        } & Pick<
          RewardsOrganizationWhiteLabelConfig,
          | "id"
          | "faviconImageUrl"
          | "optInPageLogoUrl"
          | "rewardsProgramShortName"
        >
      >
    | undefined;
}

export function HeaderLogo({
  organizationLogoImageUrl,
  whiteLabelConfig,
}: HeaderLogoProps) {
  const { formatMessage } = useFormatters();

  if (organizationLogoImageUrl && !whiteLabelConfig) {
    return (
      <div
        css={(theme: AppTheme) => css`
          margin: 0 auto;
          width: calc(100% - ${theme.spacing(4)});
          padding: ${theme.spacing(2)} 0;
          ${theme.breakpoints.up("md")} {
            width: calc(100% - ${theme.spacing(16)});
            padding: ${theme.spacing(4)} 0;
          }
          margin-bottom: ${theme.spacing(6)};
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid ${theme.palette.divider};
          & img {
            max-width: 40%;
          }
        `}
      >
        <img
          src={caribouLogoUrl}
          alt={formatMessage({
            description: "Opt in page > header caribou logo image alt",
            defaultMessage: "Caribou Logo",
          })}
          css={css`
            max-height: 44px;
          `}
        />
        <img
          src={organizationLogoImageUrl}
          alt={formatMessage({
            description: "Opt in page > header organization image alt",
            defaultMessage: "Company Logo",
          })}
          css={css`
            max-height: 59px;
          `}
        />
      </div>
    );
  }

  return (
    <img
      src={whiteLabelConfig?.optInPageLogoUrl ?? caribouLogoUrl}
      alt={whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards"}
      css={(theme: AppTheme) => css`
        display: block;
        width: 250px;
        max-width: 100%;
        margin: ${theme.spacing(6)} auto;
      `}
    />
  );
}
