import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EvvCompletionRateOfferCardDataQueryVariables = Types.Exact<{
  evvCompletionRateOfferId: Types.Scalars['ID'];
}>;


export type EvvCompletionRateOfferCardDataQuery = (
  { __typename?: 'Query' }
  & { evvCompletionRateOffer?: Types.Maybe<(
    { __typename?: 'EVVCompletionRateOffer' }
    & Pick<Types.EvvCompletionRateOffer, 'id' | 'rangeEnd' | 'numCountedVisits' | 'trackedEVVCompletionPercentage' | 'lastUpdatedAsOf' | 'ended'>
    & { levels: Array<(
      { __typename?: 'EVVCompletionRateLevel' }
      & Pick<Types.EvvCompletionRateLevel, 'id' | 'levelNumber' | 'evvCompletionPercentage' | 'numDrawTickets' | 'finished'>
    )>, targetLevel: (
      { __typename?: 'EVVCompletionRateLevel' }
      & Pick<Types.EvvCompletionRateLevel, 'id' | 'levelNumber' | 'evvCompletionPercentage' | 'numDrawTickets' | 'finished'>
    ), adjustedTargetLevel: (
      { __typename?: 'EVVCompletionRateLevel' }
      & Pick<Types.EvvCompletionRateLevel, 'id' | 'levelNumber' | 'evvCompletionPercentage' | 'numDrawTickets' | 'finished'>
    ) }
  )> }
);


export const EvvCompletionRateOfferCardDataDocument = gql`
    query EVVCompletionRateOfferCardData($evvCompletionRateOfferId: ID!) {
  evvCompletionRateOffer: getEVVCompletionRateOfferById(
    evvCompletionRateOfferId: $evvCompletionRateOfferId
  ) {
    id
    levels {
      id
      levelNumber
      evvCompletionPercentage
      numDrawTickets
      finished
    }
    targetLevel {
      id
      levelNumber
      evvCompletionPercentage
      numDrawTickets
      finished
    }
    adjustedTargetLevel {
      id
      levelNumber
      evvCompletionPercentage
      numDrawTickets
      finished
    }
    rangeEnd
    numCountedVisits
    trackedEVVCompletionPercentage
    lastUpdatedAsOf
    ended
  }
}
    `;

/**
 * __useEvvCompletionRateOfferCardDataQuery__
 *
 * To run a query within a React component, call `useEvvCompletionRateOfferCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvvCompletionRateOfferCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvvCompletionRateOfferCardDataQuery({
 *   variables: {
 *      evvCompletionRateOfferId: // value for 'evvCompletionRateOfferId'
 *   },
 * });
 */
export function useEvvCompletionRateOfferCardDataQuery(baseOptions: Apollo.QueryHookOptions<EvvCompletionRateOfferCardDataQuery, EvvCompletionRateOfferCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvvCompletionRateOfferCardDataQuery, EvvCompletionRateOfferCardDataQueryVariables>(EvvCompletionRateOfferCardDataDocument, options);
      }
export function useEvvCompletionRateOfferCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvvCompletionRateOfferCardDataQuery, EvvCompletionRateOfferCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvvCompletionRateOfferCardDataQuery, EvvCompletionRateOfferCardDataQueryVariables>(EvvCompletionRateOfferCardDataDocument, options);
        }
export type EvvCompletionRateOfferCardDataQueryHookResult = ReturnType<typeof useEvvCompletionRateOfferCardDataQuery>;
export type EvvCompletionRateOfferCardDataLazyQueryHookResult = ReturnType<typeof useEvvCompletionRateOfferCardDataLazyQuery>;
export type EvvCompletionRateOfferCardDataQueryResult = Apollo.QueryResult<EvvCompletionRateOfferCardDataQuery, EvvCompletionRateOfferCardDataQueryVariables>;