/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

import coinPointsUrl from "../../../../../../shared/coin-points.png";

const RADIUS = 31;
const STROKE_WIDTH = 5;

const getStrokeDashOffset = ({
  circumference,
  progress,
}: {
  circumference: number;
  progress: number;
}) => {
  return circumference - (progress / 100) * circumference;
};

export interface PointsBannerRedemptionProgressCircleProps {
  currentPoints: number | undefined;
  minimumPointsToRedeem: number | undefined;
  animationDurationMs: number;
}

export function PointsBannerRedemptionProgressCircle({
  currentPoints,
  minimumPointsToRedeem,
  animationDurationMs,
}: PointsBannerRedemptionProgressCircleProps) {
  const normalizedRadius = RADIUS - STROKE_WIDTH / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDasharray = `${circumference} ${circumference}`;
  const [strokeDashoffset, setStrokeDashOffset] = useState(
    getStrokeDashOffset({ circumference, progress: 0 })
  );

  useEffect(() => {
    if (currentPoints && minimumPointsToRedeem) {
      const progress = Math.min(
        (currentPoints / minimumPointsToRedeem) * 100,
        100
      );
      setTimeout(() =>
        setStrokeDashOffset(getStrokeDashOffset({ circumference, progress }))
      );
    }
  }, [currentPoints, minimumPointsToRedeem, circumference]);

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <img
        src={coinPointsUrl}
        alt="Star icon"
        css={css`
          width: 29px;
          position: absolute;
        `}
      />
      <svg
        width={RADIUS * 2}
        height={RADIUS * 2}
        viewBox={`0 0 ${RADIUS * 2} ${RADIUS * 2}`}
        fill="none"
      >
        <circle
          r={normalizedRadius}
          cx={RADIUS}
          cy={RADIUS}
          stroke="#2C393F"
          stroke-opacity="0.3"
          stroke-width={STROKE_WIDTH}
        />
        <circle
          r={normalizedRadius}
          cx={RADIUS}
          cy={RADIUS}
          stroke="white"
          stroke-width={STROKE_WIDTH}
          strokeDasharray={strokeDasharray}
          strokeLinecap="round"
          css={css`
            stroke-dashoffset: ${strokeDashoffset};
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
            transition: stroke-dashoffset ${animationDurationMs}ms ease-in-out;
          `}
        />
      </svg>
    </div>
  );
}
