/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { PageContentContainer } from "../../../shared/page-content-container";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { VideoOfferPlayer } from "../../../shared/video-offer-player";
import { useVideoOfferPageDataQuery } from "./video-offer-page-data.generated";

const SUPPORTED_VIDEO_TYPES = [
  "RewardVideoUrlFile",
  "RewardVideoManagedFile",
] as const;

export function VideoOfferPage() {
  const { formatMessage } = useFormatters();
  const { id: videoOfferId } = useParams<"id">();
  const query = useVideoOfferPageDataQuery({
    onError: reportError,
    variables: { videoOfferId: videoOfferId! },
    onCompleted: (data) => {
      if (
        data.getRewardVideoOfferById &&
        !SUPPORTED_VIDEO_TYPES.includes(
          data.getRewardVideoOfferById.video.file.__typename
        )
      ) {
        reportError(
          new Error(
            `Unsupported video file type ${data.getRewardVideoOfferById.video.file.__typename}`
          )
        );
      }
    },
  });

  const alreadyWatched = query.data?.getRewardVideoOfferById?.watched ?? false;

  const content = (() => {
    if (query.error) {
      return (
        <PageContentContainer>
          <Alert
            severity="error"
            message={formatMessage({
              description: "Video offer page > data load error",
              defaultMessage:
                "An unexpected error occurred. Please try again later.",
            })}
          />
        </PageContentContainer>
      );
    }

    if (!query.data) {
      return (
        <PageContentContainer>
          <PageLoadingState />
        </PageContentContainer>
      );
    }

    if (
      !query.data.getRewardVideoOfferById ||
      query.data.getRewardVideoOfferById.deleted
    ) {
      return (
        <PageContentContainer>
          <Alert
            message={formatMessage({
              description: "Video offer page > video no longer exists error",
              defaultMessage: "This video no longer exists.",
            })}
            severity="error"
          />
        </PageContentContainer>
      );
    }

    if (
      !SUPPORTED_VIDEO_TYPES.includes(
        query.data.getRewardVideoOfferById.video.file.__typename
      )
    ) {
      return (
        <PageContentContainer>
          <Alert
            message={formatMessage({
              description: "Video offer page > malformed video error",
              defaultMessage:
                "This video cannot be played. Please try again later.",
            })}
            severity="error"
          />
        </PageContentContainer>
      );
    }

    const {
      data: {
        getMyRewardsUser: { firstName },
        getRewardVideoOfferById: {
          video: { id: videoId, title: videoTitle, url: videoUrl },
          pointValue: pointsForVideo,
        },
      },
    } = query;

    return (
      <VideoOfferPlayer
        videoId={videoId ?? undefined}
        url={videoUrl ?? undefined}
        firstName={firstName}
        pointsForVideo={pointsForVideo}
        videoTitle={videoTitle}
        isThumbnail={false}
        videoOfferId={videoOfferId!}
        alreadyWatched={alreadyWatched}
      />
    );
  })();

  return (
    <div
      css={css`
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
      `}
    >
      <SubPageHeader
        pageName={formatMessage({
          description: "Video offer page > page title",
          defaultMessage: "Video Reward",
        })}
        analyticsPageName="Video Offer"
        backTo="rewards"
        css={css`
          flex: 0 1 auto;
        `}
      />
      {content}
    </div>
  );
}
