import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListGiftCardOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListGiftCardOptionsQuery = (
  { __typename?: 'Query' }
  & { listGiftCardOptions?: Types.Maybe<(
    { __typename?: 'ListGiftCardOptionsResponseResponse' }
    & { items: Array<(
      { __typename?: 'ListGiftCardOptionsResponse' }
      & Pick<Types.ListGiftCardOptionsResponse, 'id' | 'imageUrl' | 'minPointsAllowed' | 'maxPointsAllowed' | 'brandName'>
    )> }
  )> }
);


export const ListGiftCardOptionsDocument = gql`
    query ListGiftCardOptions {
  listGiftCardOptions {
    items {
      id
      imageUrl
      minPointsAllowed
      maxPointsAllowed
      brandName
    }
  }
}
    `;

/**
 * __useListGiftCardOptionsQuery__
 *
 * To run a query within a React component, call `useListGiftCardOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGiftCardOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGiftCardOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGiftCardOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ListGiftCardOptionsQuery, ListGiftCardOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGiftCardOptionsQuery, ListGiftCardOptionsQueryVariables>(ListGiftCardOptionsDocument, options);
      }
export function useListGiftCardOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGiftCardOptionsQuery, ListGiftCardOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGiftCardOptionsQuery, ListGiftCardOptionsQueryVariables>(ListGiftCardOptionsDocument, options);
        }
export type ListGiftCardOptionsQueryHookResult = ReturnType<typeof useListGiftCardOptionsQuery>;
export type ListGiftCardOptionsLazyQueryHookResult = ReturnType<typeof useListGiftCardOptionsLazyQuery>;
export type ListGiftCardOptionsQueryResult = Apollo.QueryResult<ListGiftCardOptionsQuery, ListGiftCardOptionsQueryVariables>;