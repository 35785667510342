import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CompleteSmsLoginChallengeMutationVariables = Types.Exact<{
  clientId: Types.Scalars['String'];
  challengeId: Types.Scalars['String'];
  verificationCode: Types.Scalars['String'];
}>;


export type CompleteSmsLoginChallengeMutation = (
  { __typename?: 'Mutation' }
  & { completeRewardsUserSMSLoginChallenge: (
    { __typename?: 'RewardsUserLoginResponse' }
    & Pick<Types.RewardsUserLoginResponse, 'accessToken'>
  ) }
);


export const CompleteSmsLoginChallengeDocument = gql`
    mutation CompleteSMSLoginChallenge($clientId: String!, $challengeId: String!, $verificationCode: String!) {
  completeRewardsUserSMSLoginChallenge(
    clientId: $clientId
    challengeId: $challengeId
    verificationCode: $verificationCode
  ) {
    accessToken
  }
}
    `;
export type CompleteSmsLoginChallengeMutationFn = Apollo.MutationFunction<CompleteSmsLoginChallengeMutation, CompleteSmsLoginChallengeMutationVariables>;

/**
 * __useCompleteSmsLoginChallengeMutation__
 *
 * To run a mutation, you first call `useCompleteSmsLoginChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSmsLoginChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSmsLoginChallengeMutation, { data, loading, error }] = useCompleteSmsLoginChallengeMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      challengeId: // value for 'challengeId'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useCompleteSmsLoginChallengeMutation(baseOptions?: Apollo.MutationHookOptions<CompleteSmsLoginChallengeMutation, CompleteSmsLoginChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteSmsLoginChallengeMutation, CompleteSmsLoginChallengeMutationVariables>(CompleteSmsLoginChallengeDocument, options);
      }
export type CompleteSmsLoginChallengeMutationHookResult = ReturnType<typeof useCompleteSmsLoginChallengeMutation>;
export type CompleteSmsLoginChallengeMutationResult = Apollo.MutationResult<CompleteSmsLoginChallengeMutation>;
export type CompleteSmsLoginChallengeMutationOptions = Apollo.BaseMutationOptions<CompleteSmsLoginChallengeMutation, CompleteSmsLoginChallengeMutationVariables>;