import { reportError } from "@rewards-web/shared/modules/error";
import { useLocale } from "@rewards-web/shared/modules/formatter";

import {
  RewardsAppAuthenticatedInitializationDataQuery,
  useRewardsAppAuthenticatedInitializationDataQuery,
} from "./rewards-app-authenticated-initialization-data.generated";
import { useIdentifyUserForScreenRecording } from "./use-identify-user-for-screen-recording";
import { useInitializeFeatureFlagsForUser } from "./use-initialize-feature-flags-for-user";

type UseRewardsAppInitializerReturnValue =
  | {
      initialized: true;
      error: false;
      data: {
        userData: RewardsAppAuthenticatedInitializationDataQuery["getMyRewardsUser"];
        organizationData: RewardsAppAuthenticatedInitializationDataQuery["getMyRewardsOrganization"];
      };
    }
  | {
      initialized: true;
      error: true;
      data: null;
    }
  | {
      initialized: false;
      error: null;
      data: null;
    };

export function useRewardsAppAuthenticatedInitializer(): UseRewardsAppInitializerReturnValue {
  const { setLocale } = useLocale();
  useIdentifyUserForScreenRecording();
  const {
    initialized: initializedFeatureFlags,
  } = useInitializeFeatureFlagsForUser();
  const initializationDataQuery = useRewardsAppAuthenticatedInitializationDataQuery(
    {
      onError: reportError,
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-first",
      onCompleted: (data) => {
        // set the app's locale to the user's locale (if they have one)
        if (data.getMyRewardsUser.locale) {
          setLocale(data.getMyRewardsUser.locale);
        }
      },
    }
  );

  const initialized =
    initializedFeatureFlags &&
    Boolean(initializationDataQuery.data || initializationDataQuery.error);

  if (initialized) {
    if (initializationDataQuery.data) {
      return {
        initialized: true,
        error: false,
        data: {
          userData: initializationDataQuery.data!.getMyRewardsUser,
          organizationData: initializationDataQuery.data!
            .getMyRewardsOrganization,
        },
      };
    }

    if (initializationDataQuery.error) {
      return {
        initialized: true,
        error: true,
        data: null,
      };
    }
  }

  return {
    initialized: false,
    error: null,
    data: null,
  };
}
