/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import EmailIcon from "../../../../images/email-icon.png";

export interface GiftCardRedeemDrawerEmailWarningProps {
  onClose: () => void;
  phoneNumber: string | undefined | null;
}

export function GiftCardRedeemDrawerEmailWarning({
  onClose,
  phoneNumber,
}: GiftCardRedeemDrawerEmailWarningProps) {
  const { formatMessage } = useFormatters();
  return (
    <>
      <div
        css={(appTheme: AppTheme) =>
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          `
        }
      >
        <img
          src={EmailIcon}
          alt={formatMessage({
            defaultMessage: "Email icon",
            description: "Points summary > email warning > email icon alt",
          })}
          css={css`
            width: 135px;
          `}
        />
        <Typography
          variant="h3"
          css={(appTheme: AppTheme) =>
            css`
              margin: ${appTheme.spacing(2.5)};
            `
          }
        >
          {formatMessage({
            description: "Redeem points drawer > email warning > title",
            defaultMessage: "We need your email!",
          })}
        </Typography>
        <Typography variant="body" color="textPrimary">
          {formatMessage({
            description: "Redeem points drawer > email warning > body",
            defaultMessage:
              "Please look out for a text from us requesting your email within 3 days. Once we have your email you will receive a link to the gift card store.",
          })}
        </Typography>
        {phoneNumber && (
          <Typography
            css={(appTheme: AppTheme) => css`
              margin-top: ${appTheme.spacing(2)};
            `}
            variant="body"
            color="textPrimary"
          >
            {formatMessage(
              {
                description:
                  "Redeem points drawer > email warning > phone number",
                defaultMessage: "Your phone number: <bolded>{phone}</bolded>",
              },
              {
                phone: formatPhoneNumber(phoneNumber),
                bolded: (nodes) => (
                  <strong
                    css={css`
                      font-weight: 700px;
                    `}
                  >
                    {nodes}
                  </strong>
                ),
              }
            )}
          </Typography>
        )}
      </div>
      <Button
        css={(appTheme: AppTheme) => css`
          margin-top: ${appTheme.spacing(2)};
        `}
        color="secondary"
        label={formatMessage({
          defaultMessage: "I understand",
          description: "Redeem points drawer > email warning > button",
        })}
        onClick={onClose}
      />
    </>
  );
}
