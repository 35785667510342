import * as React from "react";

function ConfettiIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={29}
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.084 18.594c-.21-.21-.364-.47-.448-.756l-.8-2.717a2.891 2.891 0 01.714-2.87l1.459-1.458a2.891 2.891 0 012.869-.714l2.923.825-.986-3.517a1.785 1.785 0 013.424-1.012l.8 2.717a2.89 2.89 0 01-.714 2.869l-1.459 1.459a2.891 2.891 0 01-2.869.714l-2.953-.963 1.016 3.655a1.785 1.785 0 01-2.976 1.767z"
        fill="#A08DC0"
        fillOpacity={0.35}
      />
      <path
        d="M10.799 10.903l-2.923-.825a2.9 2.9 0 00-2.536.429c.2.8.457 1.873.7 2.664l2.953.963a2.9 2.9 0 002.636-.506c-.254-.81-.585-1.912-.83-2.725z"
        fill="#A183E2"
      />
      <path
        d="M17.39 19.323c.21.21.47.364.755.448l2.718.8a2.891 2.891 0 002.868-.714l1.46-1.46a2.89 2.89 0 00.713-2.868l-.825-2.923 3.517.986a1.785 1.785 0 001.012-3.424l-2.717-.8a2.89 2.89 0 00-2.868.714l-1.46 1.459a2.891 2.891 0 00-.713 2.869l.962 2.953-3.655-1.016a1.785 1.785 0 00-1.767 2.976z"
        fill="#F7C4DB"
      />
      <path
        d="M25.905 15.533l-.825-2.923c-.85-.183-2.04-.418-2.89-.608a2.907 2.907 0 00-.341 2.41l.963 2.953c.901.173 1.79.41 2.657.713a2.9 2.9 0 00.436-2.545z"
        fill="#EF8BBD"
      />
      <path
        d="M19.546 2.648a1.45 1.45 0 11-2.049-2.05 1.45 1.45 0 012.049 2.05z"
        fill="#FFA585"
      />
      <path
        d="M23.732 28.723a1.449 1.449 0 100-2.898 1.449 1.449 0 000 2.898zM15.803 28.562a1.449 1.449 0 11-2.048-2.05 1.449 1.449 0 012.048 2.05z"
        fill="#78EAC4"
      />
      <path
        d="M15.803 28.562a1.449 1.449 0 11-2.048-2.05 1.449 1.449 0 012.048 2.05z"
        fill="#8BB6EF"
      />
      <path
        d="M3.408.214l.713 2.005a.322.322 0 00.167.188l1.781.8a.355.355 0 010 .628l-1.78.8a.322.322 0 00-.168.188l-.713 2.005a.289.289 0 01-.558 0l-.713-2.005a.321.321 0 00-.167-.188l-1.78-.8a.355.355 0 010-.628l1.78-.8a.322.322 0 00.167-.188L2.85.214a.289.289 0 01.558 0z"
        fill="#F7C4DB"
      />
      <path
        d="M29.569 20.24l.529 1.487a.24.24 0 00.124.14l1.32.6a.263.263 0 010 .466l-1.32.6a.24.24 0 00-.124.14l-.53 1.487a.215.215 0 01-.413 0l-.53-1.487a.24.24 0 00-.123-.14l-1.321-.6a.263.263 0 01-.102-.37.263.263 0 01.102-.096l1.32-.6a.24.24 0 00.125-.14l.529-1.487a.215.215 0 01.414 0z"
        fill="#A08DC0"
        fillOpacity={0.35}
      />
      <path
        d="M17.73 10.04a.672.672 0 100-1.345.672.672 0 000 1.345z"
        fill="#78EAC4"
      />
      <path
        d="M29.9 4.21a1.24 1.24 0 10-2.462-.302 1.24 1.24 0 002.462.302z"
        fill="#8BB6EF"
      />
    </svg>
  );
}

export default ConfettiIcon;
