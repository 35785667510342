/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faLifeRing } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  useFormatters,
  ALL_SUPPORTED_LOCALES,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import caribouLogoUrl from "../../../shared/caribou-logo.png";
import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { LanguageIcon } from "./icons/language-icon";
import { ProfileIcon } from "./icons/profile-icon";
import { useRewardsSettingsPageDataQuery } from "./query.generated";
import { SettingsListItem } from "./settings-list-item";

function SettingsFooter() {
  const { formatMessage } = useFormatters();
  const { data } = useRewardsSettingsPageDataQuery();
  const theme = useTheme();

  const footerButtonGap = theme.spacing(1);
  const isWhiteLabeled = !!data?.getMyRewardsOrganization.whiteLabelConfig?.id;

  const buttonConfigs = [
    {
      linkTo: "https://www.caribou.care/terms-rewards",
      label: formatMessage({
        defaultMessage: "Terms & Conditions",
        description: "Settings page > terms and conditions button",
      }),
    },
    {
      linkTo: "https://www.caribou.care/privacy-policy-rewards",
      label: formatMessage({
        defaultMessage: "Privacy Policy",
        description: "Settings page > privacy policy button",
      }),
    },
  ];

  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        margin-bottom: ${appTheme.spacing(3)};
      `}
    >
      <Typography
        css={(appTheme: AppTheme) => css`
          text-align: center;
          margin-bottom: ${appTheme.spacing(1)};
        `}
        variant="body"
        color="textPrimary"
      >
        {formatMessage(
          {
            defaultMessage:
              "This information is provided by your employer.<br></br>We collect this information according to the",
            description:
              "Settings page > information and terms and conditions notice",
          },
          { br: () => <br /> }
        )}
      </Typography>
      <div
        css={css`
          display: flex;
          width: 100%;
          gap: ${footerButtonGap};
          justify-content: center;
        `}
      >
        {buttonConfigs.map(({ linkTo, label }) => (
          <Button
            key={label}
            externalLink
            target="_blank"
            css={css`
              white-space: nowrap;
              width: calc((100% - ${footerButtonGap}) / 2);
              max-width: 225px;
            `}
            size="small"
            variant="outlined"
            color="tertiaryLight"
            typographyVariant="buttonSmall"
            {...{ linkTo, label }}
          />
        ))}
      </div>
      {!isWhiteLabeled && (
        <img
          src={caribouLogoUrl}
          alt={formatMessage({
            description: "Settings page > caribou logo image alt",
            defaultMessage: "Caribou Logo",
          })}
          css={(appTheme: AppTheme) => css`
            max-height: 28px;
            margin-top: ${appTheme.spacing(3)};
          `}
        />
      )}
    </div>
  );
}

export function SettingsPage() {
  const { formatMessage } = useFormatters();
  const { data } = useRewardsSettingsPageDataQuery();
  const theme = useTheme();

  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <SubPageHeader
        pageName={formatMessage({
          description: "Profile page > page title",
          defaultMessage: "Settings",
        })}
        analyticsPageName="Settings"
        backTo="rewards"
      />

      <PageContentContainer
        css={css`
          height: 100%;
        `}
      >
        <ScrollToTopOnMount />
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(2)};
            height: 100%;
          `}
        >
          <SettingsListItem
            linkTo="/settings/profile"
            title={formatMessage({
              defaultMessage: "Profile",
              description: "Settings page > profile button",
            })}
            icon={<ProfileIcon />}
          />
          <SettingsListItem
            linkTo="/settings/language"
            title={formatMessage({
              defaultMessage: "Language",
              description: "Settings page > language button",
            })}
            icon={<LanguageIcon />}
            iconColor={theme.palette.tertiary.main}
            subtitle={
              ALL_SUPPORTED_LOCALES.find(
                ({ code }) => code === data?.getMyRewardsUser.locale
              )?.name
            }
          />
          <SettingsListItem
            linkTo="/settings/help-and-support"
            title={formatMessage({
              defaultMessage: "Help & Support",
              description: "Settings page > help & support button",
            })}
            icon={
              <FontAwesomeIcon icon={faLifeRing} width={16} color="white" />
            }
            iconColor={theme.palette.secondary.main}
          />
          <SettingsFooter />
        </div>
      </PageContentContainer>
    </div>
  );
}
