/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import {
  useForm,
  useWatch,
  SubmitHandler,
  SubmitErrorHandler,
} from "react-hook-form";
import { Link } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import MailIcon from "../../../../shared/mail-icon";
import { PhoneIcon } from "../../../../shared/phone-icon";
import { NoPersonalInfoModal } from "../no-personal-info-modal";
import { PersonalInfoReasonModal } from "../personal-info-reason-modal";
import { OptInFormValues } from "../types";
import { UnableToEditEmailModal } from "../unable-to-edit-email-modal";
import { InfoIcon } from "./icons/info-icon";
import LockIcon from "./lock-icon";

export interface OptInFormProps {
  onSubmit(
    workEmail: string,
    personalEmail: string,
    personalPhoneNumber: string
  ): Promise<void>;
  preFilledWorkEmail: string | null;
  whiteLabelledTitle?: string;
  disclaimer: string | null;
  isWorkOrPersonalInfo: boolean;
}

export function OptInForm({
  onSubmit,
  preFilledWorkEmail,
  whiteLabelledTitle,
  disclaimer,
  isWorkOrPersonalInfo,
}: OptInFormProps): JSX.Element {
  const { formatMessage } = useFormatters();

  const track = useTrack();
  const [noPersonalInfoModalOpen, setNoPersonalInfoModalOpen] = useState(false);
  const [
    personalInfoReasonModalOpen,
    setPersonalInfoReasonModalOpen,
  ] = useState(false);
  const [unableToEditEmailModalOpen, setUnableToEditEmailModalOpen] = useState(
    false
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<OptInFormValues>({
    defaultValues: {
      workEmail: preFilledWorkEmail ?? "",
      personalEmail: "",
      personalPhoneNumber: "",
    },
  });
  const workEmail = useWatch({ control, name: "workEmail" });

  const handleUserUnableToEditClick = (
    clickTarget: "workEmailField" | "whyCantIEditButton"
  ) => {
    track("can’t edit work email clicked", {
      workEmail: preFilledWorkEmail,
      clickTarget,
    });
    setUnableToEditEmailModalOpen(true);
  };

  const handleSubmitValid: SubmitHandler<OptInFormValues> = (values) =>
    onSubmit(
      values.workEmail,
      values.personalEmail,
      serializePhoneNumber(values.personalPhoneNumber)
    );

  const handleSubmitInvalid: SubmitErrorHandler<OptInFormValues> = (errors) => {
    const missingPersonalEmail = errors.personalEmail?.type === "required";
    const missingPersonalPhoneNumber =
      errors.personalPhoneNumber?.type === "required";

    if (missingPersonalEmail || missingPersonalPhoneNumber) {
      track("Tried to opt in without entering personal info", {
        workEmail: workEmail || undefined,
        missingPersonalEmail,
        missingPersonalPhoneNumber,
      });

      if (workEmail) {
        // only show modal if we're going to contact the user
        // because we know their work email to contact
        setNoPersonalInfoModalOpen(true);
      }
    }
  };

  const workEmailLabel = formatMessage({
    description: "Opt in page > work email input label",
    defaultMessage: "Work email",
  });

  const personalEmailLabel = isWorkOrPersonalInfo
    ? formatMessage({
        description: "Opt in page > sign up email input label",
        defaultMessage: "Sign Up Email",
      })
    : formatMessage({
        description: "Opt in page > personal email input label",
        defaultMessage: "Personal Email",
      });

  const personalPhoneNumberLabel = isWorkOrPersonalInfo
    ? formatMessage({
        description: "Opt in page > sign up phone number input label",
        defaultMessage: "Sign Up Cell Phone Number",
      })
    : formatMessage({
        description: "Opt in page > personal phone number input label",
        defaultMessage: "Personal Cell Phone",
      });

  const invalidEmailErrorMessage = formatMessage({
    description: "Opt in page > invalid email error message",
    defaultMessage: "Please enter a valid email address",
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit(handleSubmitValid, handleSubmitInvalid)}
        submitting={isSubmitting}
      >
        <Typography
          variant="h3"
          color="primary"
          css={(theme: AppTheme) => css`
            font-size: 2em;
            margin-bottom: ${theme.spacing(0.5)};
            text-align: center;
          `}
        >
          <FormattedMessage
            description="Opt in page > title"
            defaultMessage="Join {rewards_program_name}"
            values={{
              rewards_program_name: whiteLabelledTitle || "Caribou Rewards",
            }}
          />
        </Typography>

        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            text-align: center;
            margin-bottom: ${theme.spacing(3.5)};
          `}
        >
          <FormattedMessage
            description="Opt in page > detail with opt-in bonus"
            defaultMessage="Sign up and start earning points"
            values={{
              rewards_program_name: whiteLabelledTitle || "Caribou",
            }}
          />
          <br />
        </Typography>
        <TextField
          label={workEmailLabel}
          hideLabel
          startAdornment={
            !!preFilledWorkEmail ? <LockIcon /> : <MailIcon fill="#999FAC" />
          }
          readOnly={!!preFilledWorkEmail}
          placeholder={`${workEmailLabel} *`}
          error={errors.workEmail}
          greyReadOnly
          hideSpaceForErrorText
          onClick={() =>
            !!preFilledWorkEmail
              ? handleUserUnableToEditClick("workEmailField")
              : undefined
          }
          {...register("workEmail", {
            required: formatMessage({
              description: "Opt in page > work email required error message",
              defaultMessage: "Work email is required",
            }),
            validate: (value) => {
              if (!isEmail(value)) {
                return invalidEmailErrorMessage;
              }
            },
          })}
        />
        {preFilledWorkEmail && (
          <>
            <TextButton
              css={(theme: AppTheme) => css`
                margin: 0 0 ${theme.spacing(3)} 0;
                text-decoration: none;
                display: flex;
                align-items: center;
              `}
              type="button"
              onClick={() => handleUserUnableToEditClick("whyCantIEditButton")}
            >
              <InfoIcon />
              <Typography
                color="textPrimary"
                variant="footnote"
                css={(appTheme: AppTheme) =>
                  css`
                    margin-left: ${appTheme.spacing(0.5)};
                  `
                }
              >
                <FormattedMessage
                  description="Opt in page > disabled work email button message"
                  defaultMessage="Why can't I edit my work email?"
                />
              </Typography>
            </TextButton>
            <UnableToEditEmailModal
              workEmail={preFilledWorkEmail}
              open={unableToEditEmailModalOpen}
              onClose={() => setUnableToEditEmailModalOpen(false)}
            />
          </>
        )}
        {isWorkOrPersonalInfo && (
          <Typography
            color="primary"
            css={(appTheme: AppTheme) => css`
              font-weight: 600;
              text-align: center;
              margin-bottom: ${appTheme.spacing(1.25)};
            `}
            variant="body"
          >
            {formatMessage({
              description: "Opt in page > sign up email and phone subheader",
              defaultMessage: "Sign up with your work or personal info:",
            })}
          </Typography>
        )}

        <TextField
          label={personalEmailLabel}
          hideLabel
          hideSpaceForErrorText
          placeholder={`${personalEmailLabel} *`}
          error={errors.personalEmail}
          startAdornment={<MailIcon fill="#999FAC" />}
          {...register("personalEmail", {
            required: formatMessage({
              description:
                "Opt in page > personal email required error message",
              defaultMessage: "Personal email is required",
            }),
            validate: (value) => {
              if (!isEmail(value)) {
                return invalidEmailErrorMessage;
              }
            },
          })}
        />

        <TextField
          type="tel"
          label={personalPhoneNumberLabel}
          hideLabel
          placeholder={`${personalPhoneNumberLabel} *`}
          startAdornment={<PhoneIcon />}
          error={errors.personalPhoneNumber}
          hideSpaceForErrorText
          {...register("personalPhoneNumber", {
            required: formatMessage({
              description:
                "Opt in page > personal phone number required error message",
              defaultMessage: "Personal cell phone is required",
            }),
            validate: (value) => {
              if (!isPhoneNumber(value)) {
                return formatMessage({
                  description:
                    "Opt in page > personal phone number invalid error message",
                  defaultMessage: "Please enter a valid phone number",
                });
              }
            },
          })}
        />

        <TextButton
          css={(theme: AppTheme) => css`
            width: 100%;
            margin: 0 0 ${theme.spacing(4)} 0;
            text-decoration: none;
            display: flex;
            align-items: start;
          `}
          type="button"
          onClick={() => {
            track(
              "Personal information collection reason modal button clicked"
            );
            setPersonalInfoReasonModalOpen(true);
          }}
        >
          <div
            css={(appTheme: AppTheme) =>
              css`
                height: ${appTheme.typography.footnote.lineHeight};
                display: flex;
                align-items: center;
              `
            }
          >
            <InfoIcon />
          </div>

          <Typography
            color="textPrimary"
            variant="footnote"
            css={(appTheme: AppTheme) =>
              css`
                margin-left: ${appTheme.spacing(0.5)};
              `
            }
          >
            <FormattedMessage
              description="Opt in page > personal info collection disclaimer button"
              defaultMessage="Why do we recommend using personal contact info"
            />
          </Typography>
        </TextButton>

        <Button
          type="submit"
          label={formatMessage({
            description: "Opt in page > sign up submit button",
            defaultMessage: "Create Account",
          })}
          color={whiteLabelledTitle ? "secondary" : "primary"}
          size="large"
          loading={isSubmitting}
        />
      </Form>

      <Typography
        css={(theme: AppTheme) => css`
          padding-top: ${theme.spacing(2)};
          text-align: center;
        `}
        color="textPrimary"
      >
        <FormattedMessage
          description="Opt in page > login button text"
          defaultMessage="Already have an account? <loginlink>Login</loginlink>"
          values={{
            loginlink: (nodes) => (
              <Link
                to={"/login"}
                css={(theme: AppTheme) => css`
                  color: ${theme.palette.primary.main};
                `}
              >
                {nodes}
              </Link>
            ),
          }}
        />
      </Typography>

      {disclaimer && (
        <Typography
          variant="footnote"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            text-align: center;
            margin-top: ${theme.spacing(3.5)};
          `}
        >
          {disclaimer}
        </Typography>
      )}

      <PersonalInfoReasonModal
        open={personalInfoReasonModalOpen}
        onClose={() => setPersonalInfoReasonModalOpen(false)}
      />

      <NoPersonalInfoModal
        open={noPersonalInfoModalOpen}
        whiteLabelledTitle={whiteLabelledTitle}
        onClose={() => setNoPersonalInfoModalOpen(false)}
      />
    </>
  );
}
