/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import ConfettiIcon from "../icons/confetti-icon";

export interface UserPositionCardProps {
  position: number;
  name: string;
}

export function UserPositionCard({
  position,
  name,
}: UserPositionCardProps): JSX.Element {
  const { formatMessage } = useFormatters();

  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) => css`
        ${theme.breakpoints.down("sm")} {
          min-height: 95px;
        }
        ${theme.breakpoints.up("md")} {
          max-height: 100px;
        }

        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-width: 0px;
        margin-bottom: ${theme.spacing(2.5)};
      `}
    >
      <CardContent>
        <div
          css={css`
            ${position <= 15 &&
            css`
              display: grid;
              grid-template-columns: 62% 38%;
            `}
          `}
        >
          <div>
            <Typography variant="h6">
              <FormattedMessage
                description="Leaderboard page > greeting"
                defaultMessage="Hi {name}"
                values={{ name }}
              />
            </Typography>

            <Typography
              css={(theme: AppTheme) => css`
                font-size: 0.9em;
              `}
              variant="body"
              color="textSecondary"
            >
              {position <= 15
                ? formatMessage(
                    {
                      description:
                        "Leaderboard page > user position description",
                      defaultMessage:
                        "You're in {position, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} place!",
                    },
                    { position }
                  )
                : formatMessage(
                    {
                      description:
                        "Leaderboard page > user position description with high position",
                      defaultMessage:
                        "You're in {position, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} place on the company leaderboard.",
                    },
                    { position }
                  )}
            </Typography>
          </div>

          {position <= 15 && (
            <div
              css={css`
                align-self: center;
                text-align: right;
              `}
            >
              <Typography
                display="inline"
                variant="body"
                css={css`
                  color: #8bb6ef;
                  font-size: 2.5em;
                  font-weight: 600;
                `}
              >
                {position}
                <sup>
                  <ConfettiIcon
                    css={(theme: AppTheme) => css`
                      margin-bottom: ${theme.spacing(0.5)};
                    `}
                  />
                </sup>
              </Typography>
              <Typography
                display="inline"
                variant="h5"
                css={css`
                  margin-left: -31px;
                `}
              >
                <FormattedMessage
                  description="Leaderboard page > user position"
                  defaultMessage="{position, selectordinal, one {st} two {nd} few {rd} other {th}} Place"
                  values={{ position }}
                />
              </Typography>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
