/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import lockUrl from "./lock.png";

// the raw lock image has a figma gray filer applied, so we will apply it here with the equivalent CSS.
// otherwise, the lock looks green.
export const lockImage = (
  <img
    css={css`
      filter: grayscale(1);
    `}
    src={lockUrl}
    alt="lock"
  />
);
