interface LanguageIconProps {
  color?: string;
}

export function LanguageIcon({ color = "white" }: LanguageIconProps) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0C4.08892 0 0.5 3.58892 0.5 8C0.5 12.4111 4.08892 16 8.5 16C12.9111 16 16.5 12.4111 16.5 8C16.5 3.58892 12.9111 0 8.5 0ZM9.31533 14.6636C9.04763 14.6963 8.77586 14.7147 8.5 14.7147C8.22414 14.7147 7.95237 14.6963 7.68535 14.6636C6.98651 14.2563 6.37348 13.3442 5.94094 12.1298H11.0596C10.6271 13.3443 10.0141 14.257 9.31521 14.6636H9.31533ZM5.66381 11.2123C5.45198 10.3758 5.31915 9.44205 5.28783 8.45912H11.7131C11.6811 9.442 11.5489 10.3758 11.3371 11.2123H5.66381ZM1.80235 8.45912H4.36892C4.39753 9.44064 4.51878 10.3676 4.71495 11.2123H2.6047C2.15174 10.384 1.86976 9.45077 1.80231 8.45912H1.80235ZM7.6853 1.33638C7.9523 1.30369 8.22407 1.2853 8.49995 1.2853C8.77648 1.2853 9.04758 1.30369 9.31528 1.33638C10.0141 1.7437 10.6271 2.65575 11.059 3.87018H5.94083C6.37336 2.65572 6.98638 1.74302 7.68525 1.33638H7.6853ZM11.3363 4.78772C11.5481 5.62417 11.681 6.55795 11.7123 7.54088H5.28772C5.31974 6.558 5.45188 5.62419 5.6637 4.78772H11.3363ZM4.36892 7.54088H1.80235C1.86978 6.54914 2.15177 5.61529 2.60474 4.78772H4.71498C4.51881 5.63303 4.39757 6.55934 4.36896 7.54088H4.36892ZM12.6312 8.45905H15.1978C15.1303 9.45079 14.8483 10.3846 14.3954 11.2122H12.2851C12.4813 10.3669 12.6025 9.44059 12.6311 8.45905H12.6312ZM12.6312 7.54088C12.6026 6.55936 12.4813 5.63238 12.2852 4.78772H14.3954C14.8484 5.61599 15.1303 6.54923 15.1978 7.54088H12.6312ZM13.7891 3.87018H12.0318C11.7702 3.06235 11.4324 2.35669 11.0367 1.78382C12.1272 2.23065 13.0733 2.95539 13.7891 3.87018ZM5.96348 1.78382C5.56775 2.35597 5.23058 3.06232 4.96835 3.87018H3.21103C3.92691 2.95541 4.8737 2.23001 5.96348 1.78382ZM3.21103 12.1296H4.96835C5.2299 12.9375 5.56775 13.6431 5.96348 14.216C4.87366 13.7699 3.92682 13.0444 3.21103 12.1296ZM11.0367 14.216C11.4324 13.6439 11.7702 12.9375 12.0318 12.1296H13.7891C13.0732 13.0444 12.1271 13.7691 11.0367 14.216Z"
        fill={color}
      />
    </svg>
  );
}
