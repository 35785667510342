/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { capitalize } from "lodash";

import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useGoalRewardsUserDataQuery } from "../../goal-rewards-user.query.generated";
import { getSchedulingSoftwareDetails } from "../lib";
import { SchedulingSoftwareAppLinks } from "./scheduling-software-app-links";

export interface EVVStreakGoalCardSchedulingSoftwareProps {
  isOpen: boolean;
  onClose: () => void;
}

export function EVVStreakGoalCardSchedulingSoftwareModal({
  isOpen,
  onClose,
}: EVVStreakGoalCardSchedulingSoftwareProps) {
  const { formatMessage } = useFormatters();
  const userDataQueryResult = useGoalRewardsUserDataQuery();

  const schedulingSoftwareDetails = getSchedulingSoftwareDetails(
    userDataQueryResult.data?.getMyRewardsUser.schedulingSoftware
  );

  const localizedTitle = schedulingSoftwareDetails
    ? formatMessage(
        {
          defaultMessage: "{scheduling_software} app",
          description: "Goal card > evv streak login modal > title",
        },
        { scheduling_software: capitalize(schedulingSoftwareDetails.appName) }
      )
    : formatMessage({
        defaultMessage: "Scheduling app",
        description: "Goal card > evv streak login modal > no app title",
      });

  const localizedMessage = schedulingSoftwareDetails
    ? formatMessage(
        {
          defaultMessage:
            "To accomplish this goal, you will need to go to your {scheduling_software} app.",
          description: "Goal card > evv streak login modal > description",
        },
        {
          scheduling_software: schedulingSoftwareDetails.appName,
        }
      )
    : formatMessage({
        defaultMessage:
          "To accomplish this goal, you will need to go to your scheduling app.",
        description: "Goal card > evv streak login modal > no app description",
      });

  return (
    <ResponsiveDialog open={isOpen} onClose={onClose} maxWidth="500px">
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <button
            css={css`
              border-radius: 50%;
              border-width: 0px;
              height: 27px;
              width: 27px;
              background-color: #e0e0e0;
              padding: 0;
              cursor: pointer;
            `}
            onClick={onClose}
          >
            <CloseOutlinedIcon
              css={css`
                padding-top: 3px;
                font-size: 1.6em;
                color: #757575;
              `}
            />
          </button>
        </div>

        <Typography
          css={(theme: AppTheme) => css`
            padding-bottom: ${theme.spacing(4)};
            text-align: center;
          `}
          variant="h5"
          color="text.primary"
        >
          {localizedTitle}
        </Typography>

        <Typography
          css={(theme: AppTheme) => css`
            padding-bottom: ${theme.spacing(4)};
            text-align: center;
          `}
          variant="body"
          color="text.primary"
        >
          {localizedMessage}
        </Typography>

        {schedulingSoftwareDetails && (
          <SchedulingSoftwareAppLinks details={schedulingSoftwareDetails} />
        )}
      </div>
    </ResponsiveDialog>
  );
}
