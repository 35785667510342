/** @jsxImportSource @emotion/react */
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

export function PastRedemptionsTitle() {
  const { formatMessage } = useFormatters();

  return (
    <Typography variant="h5" color="textPrimary">
      {formatMessage({
        defaultMessage: "Past Redemptions",
        description: "Past redemptions > past redemptions title",
      })}
    </Typography>
  );
}
