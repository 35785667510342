/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Confetti,
  DEFAULT_CONFETTI_DURATION_MS,
} from "@rewards-web/shared/components/confetti";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";

import { usePointBalance } from "../../../../../shared/modules/point-balance";
import { PastRedemptionsDataDocument } from "../../past-redemptions/query.generated";
import { COUNT_UPANIMATION_DURATION_IN_MS } from "../points-count-up";
import { GiftCardRedeemPointsDrawer } from "./giftcard-redeem-points-drawer";
import { PaycheckRedeemPointsDrawer } from "./paycheck-redeem-points-drawer";

export interface RedeemPointsDrawerProps {
  open: boolean;
  onClose: () => void;
  pointsAvailableToRedeem: number;
  minimumPointsToRedeem: number;
  pointsPerDollar: number;
  redemptionMethod: RedemptionMethod;
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
}

export function RedeemPointsDrawer({
  open,
  onClose,
  pointsAvailableToRedeem,
  pointsPerDollar,
  email,
  redemptionMethod,
  phoneNumber,
}: RedeemPointsDrawerProps) {
  const [isShowingConfetti, setIsShowingConfetti] = useState(false);
  const confettiTimerRef = useRef<NodeJS.Timeout | null>(null);
  const onRedeemPointsTimerRef = useRef<NodeJS.Timeout | null>(null);
  const pointBalance = usePointBalance({ refreshOnMount: true });
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      confettiTimerRef.current && clearTimeout(confettiTimerRef.current);
      onRedeemPointsTimerRef.current &&
        clearTimeout(onRedeemPointsTimerRef.current);
    };
  }, []);

  useEffect(() => {
    if (isShowingConfetti) {
      const timer = setTimeout(
        () => setIsShowingConfetti(false),
        DEFAULT_CONFETTI_DURATION_MS + 1500
      );
      return () => clearTimeout(timer);
    }
  }, [isShowingConfetti]);

  const onRedeemed = () => {
    pointBalance.addLocalPoints(-pointsAvailableToRedeem);
    pointBalance.refreshPointBalance();
    apolloClient.refetchQueries({ include: [PastRedemptionsDataDocument] });
    confettiTimerRef.current = setTimeout(
      () => setIsShowingConfetti(true),
      COUNT_UPANIMATION_DURATION_IN_MS
    );
    onRedeemPointsTimerRef.current = setTimeout(
      () =>
        navigate("/my-rewards/past-redemptions", {
          state: { justRedeemed: true },
        }),
      0
    );
  };

  const modal = (() => {
    switch (redemptionMethod) {
      case RedemptionMethod.GenericGiftCardLink:
        return (
          <GiftCardRedeemPointsDrawer
            email={email}
            phoneNumber={phoneNumber}
            open={open}
            onClose={onClose}
            onRedeemed={onRedeemed}
            pointsAvailableToRedeem={pointsAvailableToRedeem}
          />
        );
      case RedemptionMethod.Payroll:
      default:
        return (
          <PaycheckRedeemPointsDrawer
            open={open}
            onClose={onClose}
            onRedeemed={onRedeemed}
            pointsAvailableToRedeem={pointsAvailableToRedeem}
            pointsPerDollar={pointsPerDollar}
          />
        );
    }
  })();

  return (
    <>
      <Confetti active={isShowingConfetti} />
      {modal}
    </>
  );
}
