/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function LocationIcon() {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 0.25C10.9766 0.25 14 3.27344 14 7C14 9.74219 13.0508 10.4805 7.91797 17.8984C7.74219 18.1445 7.49609 18.25 7.25 18.25C6.96875 18.25 6.72266 18.1445 6.54688 17.8984C1.41406 10.5156 0.5 9.74219 0.5 7C0.5 3.27344 3.48828 0.25 7.25 0.25ZM7.25 15.9297C7.84766 15.0508 8.41016 14.2422 8.90234 13.5742C11.9609 9.21484 12.3125 8.65234 12.3125 7C12.3125 4.22266 10.0273 1.9375 7.25 1.9375C4.4375 1.9375 2.1875 4.22266 2.1875 7C2.1875 8.65234 2.50391 9.21484 5.5625 13.5742C6.05469 14.2422 6.61719 15.0508 7.25 15.9297ZM7.25 4.1875C8.79688 4.1875 10.0625 5.41797 10.0625 7C10.0625 8.54688 8.79688 9.8125 7.25 9.8125C5.66797 9.8125 4.4375 8.54688 4.4375 7C4.4375 5.41797 5.66797 4.1875 7.25 4.1875ZM7.25 8.125C7.84766 8.125 8.375 7.63281 8.375 7C8.375 6.40234 7.84766 5.875 7.25 5.875C6.61719 5.875 6.125 6.40234 6.125 7C6.125 7.63281 6.61719 8.125 7.25 8.125Z"
        fill={theme.palette.text.primary}
      />
    </svg>
  );
}
