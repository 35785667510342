/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { AppTheme } from "@rewards-web/shared/style/types";

import { Typography, TypographyProps } from "../../typography";

export interface DialogHeaderProps {
  title: string;
  titleVariant: Exclude<TypographyProps["variant"], undefined>;
  onClose?: () => void;
}

export function DialogHeader({
  title,
  titleVariant,
  onClose,
}: DialogHeaderProps) {
  return (
    <div
      css={(appTheme: AppTheme) => css`
        text-align: center;
        margin-bottom: ${appTheme.spacing(3.75)};
      `}
    >
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: ${appTheme.spacing(1.25)};
          padding-top: ${appTheme.spacing(2.5)};
          padding-right: ${appTheme.spacing(2.5)};
        `}
      >
        <button
          css={(appTheme: AppTheme) => css`
            border-radius: 50%;
            border-width: 0px;
            height: 27px;
            width: 27px;
            background-color: ${appTheme.palette.grey[200]};
            padding: 0;
            cursor: pointer;
          `}
          onClick={onClose}
        >
          <CloseOutlinedIcon
            css={(appTheme: AppTheme) => css`
              padding-top: 3px;
              font-size: 1.6em;
              color: ${appTheme.palette.grey[800]};
            `}
          />
        </button>
      </div>
      <Typography variant={titleVariant}>{title}</Typography>
    </div>
  );
}
