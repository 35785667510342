import { Route, Routes } from "react-router-dom";

import { Page } from "@rewards-web/shared/modules/page";

import { useAuth } from "../shared/modules/auth";
import { Authenticated } from "./authenticated";
import { Unauthenticated } from "./unauthenticated";
import { Deactivated } from "./unauthenticated/deactivated";
import { OptInPage } from "./unauthenticated/opt-in";
import { PrivateBrowsing } from "./unauthenticated/private-browsing";
import { ReauthPage } from "./unauthenticated/re-auth";

export function AppRoutes(): JSX.Element {
  const { signedIn } = useAuth();

  return (
    <Routes>
      <Route path="/sign-up" element={<OptInPage />} />

      <Route
        path="/deactivated"
        element={
          <Page
            browserTitle="Account Deactivated"
            analyticsPageName="AccountDeactivated"
          >
            <Deactivated />
          </Page>
        }
      />

      <Route path="/reauth" element={<ReauthPage />} />

      <Route path="/private-browsing" element={<PrivateBrowsing />} />

      <Route
        path="*"
        element={signedIn ? <Authenticated /> : <Unauthenticated />}
      />
    </Routes>
  );
}
