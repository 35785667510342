/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";

import {
  CompleteTrainingGoalDetails,
  CompleteTrainingGoalTracker,
  GoalStatus,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { GoalCardProps } from "..";
import { useEmbedSource } from "../../../../embed-source-wrapper";
import { BaseGoalCard, BaseGoalCardProps } from "../base-goal-card";
import { secondsToHoursAndMinutes, useCommonBaseGoalCardProps } from "../lib";
import { useGenerateMyNevvonLoginLinkMutation } from "./generate-my-nevvon-login-link.generated";

// javascript channel created by Nevvon
declare var Nevvon: any;

const NEVVON_EMBED_SOURCES = ["nevvon_web", "nevvon_app"];

export function CompleteTrainingGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage, formatDuration } = useFormatters();
  const snackbar = useSnackbar();
  const { embedSource } = useEmbedSource();

  const isEmbedded = embedSource && NEVVON_EMBED_SOURCES.includes(embedSource);

  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
  });

  const [generateMyNevvonLoginLink] = useGenerateMyNevvonLoginLinkMutation({
    onError: reportError,
  });

  const [nevvonLoginLink, setNevvonLoginLink] = useState<string | null>(null);

  useEffect(() => {
    if (isEmbedded) {
      // no need to generate link since we are already embedded within Nevvon
      return;
    }

    generateMyNevvonLoginLink().then((response) => {
      setNevvonLoginLink(
        response.data?.generateMyNevvonLoginLink?.loginLink ?? null
      );
    });
  }, [generateMyNevvonLoginLink, isEmbedded]);

  const label = formatMessage({
    defaultMessage: "Take training",
    description: "Goal card > complete training > take training button label",
  });

  const primaryButtonProps:
    | BaseGoalCardProps["primaryButtonProps"]
    | undefined = (() => {
    if (goal.status !== GoalStatus.Available) {
      return undefined;
    }

    if (isEmbedded) {
      return {
        label,
        onClick: () => {
          // Send a message to Nevvon to navigate to the home page.
          const message = JSON.stringify({
            event: "navigate:home",
          });

          if (embedSource === "nevvon_app") {
            return Nevvon.postMessage(message);
          } else {
            return window.parent.postMessage(message, "*");
          }
        },
      };
    } else {
      if (nevvonLoginLink) {
        return {
          label,
          linkTo: nevvonLoginLink,
          externalLink: true,
        };
      } else {
        // missing Nevvon login link
        return {
          label,
          onClick: () => {
            // This shouldn't happen, but we want to be notified if it does.
            reportError(
              new Error(
                `Missing Nevvon login link for training goal ${goal.id}`
              )
            );
            return snackbar.show({
              message: formatMessage({
                defaultMessage:
                  "Training program link not available. Please try again later.",
                description: "Goal card > training program link not available",
              }),
              severity: "error",
            });
          },
        };
      }
    }
  })();

  const programDurationSeconds = (goal.goalDetails as CompleteTrainingGoalDetails)
    .trainingProgramDurationSeconds;
  const completedDurationSeconds = (goal.goalTracker as CompleteTrainingGoalTracker)
    .programDurationCompletedSeconds;
  const progressUpdatedAt = (goal.goalTracker as CompleteTrainingGoalTracker)
    .progressUpdatedAt;

  return (
    <BaseGoalCard
      {...commonBaseGoalCardProps}
      progressProps={{
        max: 100, // percentage
        value: goal.progress ?? 0,
        getUnitsLabel: () => {
          if (programDurationSeconds === 0) {
            return "";
          }

          const formattedTimeRemaining = formatDuration(
            secondsToHoursAndMinutes(
              programDurationSeconds - completedDurationSeconds
            ),
            { style: "short" }
          );

          return formatMessage(
            {
              defaultMessage: "{time_remaining} left",
              description: "Goal card > complete training > progress units",
            },
            {
              time_remaining: formattedTimeRemaining,
            }
          );
        },
        progressUpdatedAt,
      }}
      detailText={formatMessage({
        defaultMessage: "Complete the training program and earn rewards!",
        description: "Goal card > complete training > detail text",
      })}
      primaryButtonProps={primaryButtonProps}
    />
  );
}
