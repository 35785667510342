import { IconBadgeData } from "../navigation-icon-with-badge";

export const getRewardsTabBadge = ({
  computedBalance,
  minimumPointsNeededToRedeem,
  lastViewedPointsRedeemed,
  currentPointsRedeemed,
}: {
  computedBalance: number;
  minimumPointsNeededToRedeem: number;
  lastViewedPointsRedeemed: number | null;
  currentPointsRedeemed: number;
}): IconBadgeData | null => {
  const canRedeem = computedBalance >= minimumPointsNeededToRedeem;
  const hasViewedCurrentPointsRedeemed =
    lastViewedPointsRedeemed === currentPointsRedeemed;

  return canRedeem && !hasViewedCurrentPointsRedeemed
    ? { type: "dollar" }
    : null;
};

export const getDrawTabBadge = (
  params:
    | {
        drawHost: "organization";
        winnersAnnounced: boolean;
        hasViewedWinners: boolean;
      }
    | {
        drawHost: "caribou";
        currentDrawId: string;
        myNumTicketsInDraw: number;
        drawBadgeViewData: {
          lastViewedDrawId: string;
          lastViewedTickets: number;
        } | null;
      }
): IconBadgeData | null => {
  switch (params.drawHost) {
    case "organization": {
      const { winnersAnnounced, hasViewedWinners } = params;
      // if using org run draws, tab may show an unread notification badge
      // indicating that draw winners haven't been viewed yet
      return winnersAnnounced && !hasViewedWinners
        ? {
            type: "unread_badge",
          }
        : null;
    }

    case "caribou": {
      const { currentDrawId, myNumTicketsInDraw, drawBadgeViewData } = params;

      const numTicketsSeen = (() => {
        if (drawBadgeViewData === null) {
          return 0;
        }
        const { lastViewedDrawId, lastViewedTickets } = drawBadgeViewData;
        if (lastViewedDrawId !== currentDrawId) {
          return 0; // previously viewed tickets only count as 'seen' if the draw ID is the same
        }
        return lastViewedTickets;
      })();

      const numTicketsUnseen = Math.max(0, myNumTicketsInDraw - numTicketsSeen);

      // if using caribou-sponsored draws, tab may show a number
      // indicating the number of tickets the user has earned towards the draw
      if (numTicketsUnseen > 0) {
        return {
          type: "number",
          value: numTicketsUnseen,
        };
      }
      return null;
    }

    default:
      return null;
  }
};
