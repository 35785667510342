import { createContext } from "react";

import { Locale } from "@rewards-web/shared/graphql-types";

export const DEFAULT_LOCALE = Locale.En;

interface I18nContextValue {
  setLocale(locale: string): void;
  locale: Locale;
}

export const I18nContext = createContext<I18nContextValue>({
  setLocale: () => {},
  locale: DEFAULT_LOCALE,
});
