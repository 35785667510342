import { useCallback, useState } from "react";

type JSONSerializableValue = number | string | boolean | null;
// dont include complex types like classes, dates, functions, etc
//   so that value = JSON.parse(JSON.stringify(value))

type JSONSerializableObject =
  | JSONSerializableValue
  | Array<JSONSerializableValue>
  | {
      [key: string]: JSONSerializableObject;
    };

export function useLocalStorageSimpleObject<
  TValue extends JSONSerializableObject
>({ key }: { key: string }) {
  return useLocalStorage<TValue>({
    key,
    serializer: (value) => JSON.stringify(value),
    deserializer: (valueStr) => JSON.parse(valueStr),
  });
}

export function useLocalStorage<TValue>({
  key,
  serializer,
  deserializer,
}: {
  key: string;
  serializer: (value: TValue) => string;
  deserializer: (valueStr: string) => TValue;
}): [TValue | null, (callback: (prev: TValue | null) => TValue) => void] {
  const getValue = useCallback(() => {
    const valueStr = localStorage.getItem(key);
    return valueStr === null ? null : deserializer(valueStr);
  }, [deserializer, key]);

  const [value, setValue] = useState<TValue | null>(getValue());

  return [
    value,
    (callback: (prev: TValue | null) => TValue) => {
      setValue((prev) => {
        const newValue = callback(prev);
        if (prev && serializer(prev) === serializer(newValue)) {
          return prev; // don't perform a state update if value is equivalent
        }
        localStorage.setItem(key, serializer(newValue));
        return newValue;
      });
    },
  ];
}
