/** @jsxImportSource @emotion/react */
import { ClassNames, css, useTheme } from "@emotion/react";
import { times } from "lodash";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { TextField } from "@rewards-web/shared/components/text-field";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface VerificationCodeFieldProps {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  setWasPasted: (wasPasted: boolean) => void;
  hasError: boolean;
  onClearErrorMessage?: () => void;
}

export function VerificationCodeField({
  code,
  setCode,
  setWasPasted,
  hasError,
  onClearErrorMessage,
}: VerificationCodeFieldProps) {
  const { formatMessage } = useFormatters();

  const [inputValues, _setInputValues] = useState(
    times(6, (i) => code.at(i) ?? "") // initialize inputValues to code
  );
  const setInputValuesClearError = (
    ...params: Parameters<typeof _setInputValues>
  ) => {
    onClearErrorMessage?.();
    _setInputValues(...params);
  };

  // update code whenever inputValues changes
  useEffect(() => {
    setCode(inputValues.join(""));
  }, [inputValues, setCode]);

  // clear inputValues whenever code is reset by higher component, such as resending code
  useEffect(() => {
    if (code === "" && inputValues.join("") !== "") {
      setInputValuesClearError(Array(6).fill(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const theme = useTheme();

  const hiddenLabel = formatMessage({
    description: "Verification code field > verification code input label",
    defaultMessage: "Code from phone",
  });

  const digit0Ref = useRef<HTMLInputElement>(null);
  const digit1Ref = useRef<HTMLInputElement>(null);
  const digit2Ref = useRef<HTMLInputElement>(null);
  const digit3Ref = useRef<HTMLInputElement>(null);
  const digit4Ref = useRef<HTMLInputElement>(null);
  const digit5Ref = useRef<HTMLInputElement>(null);

  const refs = [
    digit0Ref,
    digit1Ref,
    digit2Ref,
    digit3Ref,
    digit4Ref,
    digit5Ref,
  ];

  const focusDigitInput = ({ index }: { index: number }) => {
    const validIndex = Math.min(Math.max(index, 0), refs.length - 1);
    refs[validIndex].current?.focus();
  };

  const setDigit = ({ index, value }: { index: number; value: string }) => {
    setInputValuesClearError((prev) => {
      const values = [...prev];
      values[index] = value;
      return values;
    });
  };

  const replaceInputValues = (code: string) => {
    setInputValuesClearError(times(6, (i) => code.at(i) ?? ""));
    focusDigitInput({ index: code.length });
  };

  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        width: 100%;
        column-gap: ${appTheme.spacing(1.5)};
        justify-content: center;
      `}
    >
      {times(6, (idx) => (
        <ClassNames key={idx}>
          {({ css }) => (
            <TextField
              key={idx}
              onKeyDown={(e) => {
                setWasPasted(false);

                if (e.key === "Backspace") {
                  if (!e.currentTarget.value) {
                    focusDigitInput({ index: idx - 1 });
                  } else {
                    setDigit({ index: idx, value: "" });
                  }
                }
              }}
              label={hiddenLabel}
              hideLabel
              autocomplete="one-time-code"
              autoFocus={idx === 0}
              css={css`
                width: 54px;
              `}
              styleType="outlined"
              inputProps={{
                className: css`
                  text-align: center;
                  color: ${hasError
                    ? theme.palette.error.main
                    : theme.palette.primary.main};
                `,
                inputMode: "numeric",
              }}
              hideEndAdornment
              className={css`
                .custom-notched-outline {
                  border-width: 1px !important;
                  ${inputValues[idx] !== "" &&
                  `border-color: ${
                    hasError
                      ? theme.palette.error.main
                      : theme.palette.primary.main
                  } !important`}
                }
              `}
              onPaste={(e) => {
                setWasPasted(true);
                e.preventDefault();

                const pastedCode = e.clipboardData
                  .getData("text")
                  .replace(/\D/g, "");

                if (pastedCode) {
                  replaceInputValues(pastedCode);
                }
              }}
              hideSpaceForErrorText
              ref={refs[idx]}
              value={inputValues[idx]}
              onChange={(e) => {
                const value = e.target.value ?? "";
                if (/[^0-9]/.test(value)) {
                  return;
                }
                switch (value.length) {
                  case 0:
                    setDigit({ index: idx, value: "" });
                    break;
                  case 1:
                    setDigit({ index: idx, value });
                    focusDigitInput({ index: idx + 1 });
                    break;
                  case 2:
                    // may have entered a new digit on the left or right, depending on cursor
                    const [leftDigit, rightDigit] = value;
                    setDigit({
                      index: idx,
                      value:
                        inputValues[idx] === leftDigit ? rightDigit : leftDigit, // choose new digit
                    });
                    focusDigitInput({ index: idx + 1 });
                    break;
                  default:
                    // for example, password autocomplete
                    replaceInputValues(value);
                    break;
                }
              }}
            />
          )}
        </ClassNames>
      ))}
    </div>
  );
}
