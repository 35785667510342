/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alpha, useMediaQuery } from "@material-ui/core";
import { differenceInDays } from "date-fns";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { useRefHeight } from "@rewards-web/shared/hooks/use-ref-height";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  FormattedNumber,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import coinPointsUrl from "../../../shared/coin-points.png";
import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import heartImageUrl from "./heart.png";
import { usePowerHoursOfferPageDataQuery } from "./power-hours-offer-page-data.generated";

export function PowerHoursOfferPage() {
  const { formatMessage } = useFormatters();
  const { id: powerHoursOfferId } = useParams<"id">();
  const track = useTrack();

  const query = usePowerHoursOfferPageDataQuery({
    onError: reportError,
    variables: {
      powerHoursOfferId: powerHoursOfferId!,
    },
  });

  const [getMoreShiftsModalOpen, setGetMoreShiftsModalOpen] = useState(false);

  const { ref: footerRef, height: footerHeight } = useRefHeight({
    isReady: !!query.data,
  });

  const desktop = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.up("md")
  );

  const content = (() => {
    if (query.error || (query.data && !query.data.powerHoursOffer)) {
      return (
        <Alert
          message={formatMessage({
            description: "Power hours offer page > page load error message",
            defaultMessage:
              "An unexpected error occurred. Please try again later.",
          })}
          severity="error"
        />
      );
    }

    const powerHoursOffer = query.data?.powerHoursOffer;

    return (
      <>
        <img
          src={heartImageUrl}
          alt={formatMessage({
            description: "Power hours offer page > heart image alt",
            defaultMessage: "heart image",
          })}
          css={css`
            width: 81px;
            display: block;
            margin: 0 auto;
          `}
        />
        <Typography
          variant="h2"
          component="h1"
          color="text.primary"
          textAlign="center"
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
          `}
        >
          <FormattedMessage
            description="Power hours offer page > page title"
            defaultMessage="Power hours"
          />
        </Typography>
        {powerHoursOffer ? (
          <Typography
            variant="body"
            color="grey.800"
            textAlign="center"
            css={(theme: AppTheme) => css`
              max-width: 600px;
              margin: 0 auto;
              margin-top: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(4)};
            `}
          >
            <FormattedMessage
              description="Power hours offer page > page description"
              defaultMessage="Based on the level you complete, points are rewarded at the end."
            />
          </Typography>
        ) : (
          <Skeleton
            height={80}
            animated
            css={css`
              margin: 0 auto;
              margin-top: -20px;
              max-width: 680px;
            `}
          />
        )}
        {powerHoursOffer ? (
          powerHoursOffer.levels.map((level) => (
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                max-width: 500px;
                margin: 0 auto;
                margin-bottom: ${theme.spacing(3)};
                gap: ${theme.spacing(1.75)};
              `}
            >
              <div
                css={(theme: AppTheme) => css`
                  flex-shrink: 0;
                  background-color: ${level.finished
                    ? theme.palette.tertiary.main
                    : theme.palette.grey[200]};
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  gap: 2px;
                  width: 73px;
                  height: 73px;
                  border-radius: 1000px;
                `}
              >
                <img
                  src={coinPointsUrl}
                  alt={formatMessage({
                    description: "Power hours offer page > star image alt",
                    defaultMessage: "star image",
                  })}
                  css={css`
                    width: 21px;
                    filter: grayscale(${level.finished ? 0 : 1});
                  `}
                />
                <Typography
                  variant="caption"
                  color={level.finished ? "white" : "text.primary"}
                >
                  <FormattedNumber value={level.pointValue} />
                </Typography>
              </div>
              <div
                css={css`
                  flex-grow: 1;
                `}
              >
                <Typography
                  variant="caption"
                  color="text.primary"
                  textTransform="uppercase"
                  css={(theme: AppTheme) => css`
                    margin-bottom: ${theme.spacing(0.5)};
                  `}
                >
                  <FormattedMessage
                    description="Power hours offer page > level title"
                    defaultMessage="Level {level_number, number}"
                    values={{
                      level_number: level.levelNumber,
                    }}
                  />
                </Typography>
                <Typography variant="footnote" color="grey.800">
                  {powerHoursOffer.hourCondition?.__typename ===
                  "PowerHoursWeekendHoursCondition" ? (
                    <FormattedMessage
                      description="Power hours offer page > level description > weekend hours"
                      defaultMessage="Complete {num_hours, number} weekend {num_hours, plural, one {hour} other {hours}} = Earn {num_points, number} {num_points, plural, one {point} other {points}}"
                      values={{
                        num_hours: level.hours,
                        num_points: level.pointValue,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      description="Power hours offer page > level description > any hours"
                      defaultMessage="Complete {num_hours, number} {num_hours, plural, one {hour} other {hours}} = Earn {num_points, number} {num_points, plural, one {point} other {points}}"
                      values={{
                        num_hours: level.hours,
                        num_points: level.pointValue,
                      }}
                    />
                  )}
                </Typography>
              </div>
              {level.finished && (
                <div
                  css={(theme: AppTheme) => css`
                    flex-shrink: 0;
                    background-color: ${theme.palette.success.main};
                    border-radius: 1000px;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <FontAwesomeIcon icon={faCheck} color="white" />
                </div>
              )}
            </div>
          ))
        ) : (
          <>
            <Skeleton
              height={80}
              animated
              css={css`
                margin: 0 auto;
                max-width: 460px;
              `}
            />
            <Skeleton
              height={80}
              animated
              css={css`
                margin: 0 auto;
                max-width: 460px;
              `}
            />
            <Skeleton
              height={80}
              animated
              css={css`
                margin: 0 auto;
                max-width: 460px;
              `}
            />
          </>
        )}
        <Typography
          variant="footnote"
          color="grey.800"
          textAlign="center"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(1)};
            `}
          />
          <FormattedMessage
            description="Power hours offer page > evv disclaimer"
            defaultMessage="Must clock in and out. Paper timesheets do not qualify."
          />
        </Typography>
        {powerHoursOffer && powerHoursOffer.levels.length > 1 && (
          <div
            css={(theme: AppTheme) => css`
              border: 1.5px solid ${alpha(theme.palette.grey[400], 0.9)};
              background-color: white;
              border-radius: 10px;
              padding: ${theme.spacing(2.5)};
              max-width: 400px;
              margin: 0 auto;
              margin-top: ${theme.spacing(3)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            <Typography
              variant="subtitle"
              color="text.primary"
              textAlign="center"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              <FormattedMessage
                description="Power hours offer page > example title"
                defaultMessage="For example"
              />
            </Typography>
            <Typography variant="footnote" color="grey.800" textAlign="center">
              <FormattedMessage
                description="Power hours offer page > example description"
                defaultMessage="You complete {num_hours, number} {num_hours, plural, one {hour} other {hours}} within {num_days, number} {num_days, plural, one {day} other {days}}, you will receive {num_points, number} {num_points, plural, one {point} other {points}} at the end of this challenge."
                values={{
                  num_hours: powerHoursOffer.levels[1].hours,
                  num_points: powerHoursOffer.levels[1].pointValue,
                  num_days: differenceInDays(
                    powerHoursOffer.rangeEnd,
                    powerHoursOffer.rangeStart
                  ),
                }}
              />
            </Typography>
          </div>
        )}
      </>
    );
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <SubPageHeader
        pageName={formatMessage({
          description: "Power hours offer page > navigation banner title",
          defaultMessage: "How it works",
        })}
        analyticsPageName="Power Hours Offer"
        backTo="rewards"
        color="white"
      />

      <PageContentContainer
        css={css`
          padding-bottom: ${footerHeight}px;
        `}
      >
        <ScrollToTopOnMount />
        {content}
      </PageContentContainer>
      <div
        ref={footerRef}
        css={css`
          position: fixed;
          bottom: 0;
          width: 100%;
          background-color: white;
          box-shadow: 0px -4.5px 7px rgba(0, 0, 0, 0.05);
        `}
      >
        <PageContentContainer
          css={(appTheme: AppTheme) =>
            css`
              padding-top: ${appTheme.spacing(2)};
              padding-bottom: ${appTheme.spacing(2)};
            `
          }
        >
          <Button
            css={css`
              display: block;
              margin: 0 auto;
            `}
            color="secondary"
            width={desktop ? "auto" : "full"}
            label={formatMessage({
              description: "Power hours offer page > get more shifts button",
              defaultMessage: "Get more shifts",
            })}
            onClick={() => {
              setGetMoreShiftsModalOpen(true);
              track("Clicked get more shifts button", { powerHoursOfferId });
            }}
          />
          <ResponsiveDialog open={getMoreShiftsModalOpen} mobileType="dialog">
            <div
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(3)} ${theme.spacing(2.5)}
                  ${theme.spacing(1.5)};
              `}
            >
              <Typography
                variant="h2"
                color="text.primary"
                textAlign="center"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                <FormattedMessage
                  description="Power hours offer page > get more shifts modal > title"
                  defaultMessage="Pick up shifts!"
                />
              </Typography>
              <Typography
                variant="body"
                color="grey.800"
                textAlign="center"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                <FormattedMessage
                  description="Power hours offer page > get more shifts modal > description"
                  defaultMessage="Contact your Supervisor / Coordinator to find a case for you!"
                />
              </Typography>
              <Button
                color="secondary"
                label={formatMessage({
                  description:
                    "Power hours offer page > get more shifts modal > close button",
                  defaultMessage: "Got it!",
                })}
                onClick={() => {
                  setGetMoreShiftsModalOpen(false);
                }}
              />
            </div>
          </ResponsiveDialog>
        </PageContentContainer>
      </div>
    </div>
  );
}
