import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AcknowledgeEvvCompletionRateOfferEndedMutationVariables = Types.Exact<{
  evvCompletionRateOfferId: Types.Scalars['ID'];
}>;


export type AcknowledgeEvvCompletionRateOfferEndedMutation = (
  { __typename?: 'Mutation' }
  & { acknowledgeEVVCompletionRateOfferEnded: (
    { __typename?: 'EVVCompletionRateOffer' }
    & Pick<Types.EvvCompletionRateOffer, 'id'>
  ) }
);


export const AcknowledgeEvvCompletionRateOfferEndedDocument = gql`
    mutation AcknowledgeEVVCompletionRateOfferEnded($evvCompletionRateOfferId: ID!) {
  acknowledgeEVVCompletionRateOfferEnded(
    evvCompletionRateOfferId: $evvCompletionRateOfferId
  ) {
    id
  }
}
    `;
export type AcknowledgeEvvCompletionRateOfferEndedMutationFn = Apollo.MutationFunction<AcknowledgeEvvCompletionRateOfferEndedMutation, AcknowledgeEvvCompletionRateOfferEndedMutationVariables>;

/**
 * __useAcknowledgeEvvCompletionRateOfferEndedMutation__
 *
 * To run a mutation, you first call `useAcknowledgeEvvCompletionRateOfferEndedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeEvvCompletionRateOfferEndedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeEvvCompletionRateOfferEndedMutation, { data, loading, error }] = useAcknowledgeEvvCompletionRateOfferEndedMutation({
 *   variables: {
 *      evvCompletionRateOfferId: // value for 'evvCompletionRateOfferId'
 *   },
 * });
 */
export function useAcknowledgeEvvCompletionRateOfferEndedMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgeEvvCompletionRateOfferEndedMutation, AcknowledgeEvvCompletionRateOfferEndedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgeEvvCompletionRateOfferEndedMutation, AcknowledgeEvvCompletionRateOfferEndedMutationVariables>(AcknowledgeEvvCompletionRateOfferEndedDocument, options);
      }
export type AcknowledgeEvvCompletionRateOfferEndedMutationHookResult = ReturnType<typeof useAcknowledgeEvvCompletionRateOfferEndedMutation>;
export type AcknowledgeEvvCompletionRateOfferEndedMutationResult = Apollo.MutationResult<AcknowledgeEvvCompletionRateOfferEndedMutation>;
export type AcknowledgeEvvCompletionRateOfferEndedMutationOptions = Apollo.BaseMutationOptions<AcknowledgeEvvCompletionRateOfferEndedMutation, AcknowledgeEvvCompletionRateOfferEndedMutationVariables>;