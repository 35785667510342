import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CaribouSponsoredDrawPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CaribouSponsoredDrawPageDataQuery = (
  { __typename?: 'Query' }
  & { draw?: Types.Maybe<(
    { __typename?: 'CaribouSponsoredDraw' }
    & Pick<Types.CaribouSponsoredDraw, 'id' | 'scheduledEndDate' | 'myNumTicketsInDraw' | 'linkToExternalMarketingPage'>
    & { prizeStructure: (
      { __typename: 'DrawPrizeStructureSinglePrizeMultipleWinners' }
      & Pick<Types.DrawPrizeStructureSinglePrizeMultipleWinners, 'id' | 'numWinners'>
      & { prize: (
        { __typename: 'DrawPrizePoints' }
        & Pick<Types.DrawPrizePoints, 'id' | 'pointValue'>
      ) | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'id'>
      ) }
    ) | (
      { __typename: 'DrawPrizeStructureTieredPrizesMultipleWinners' }
      & Pick<Types.DrawPrizeStructureTieredPrizesMultipleWinners, 'id' | 'numWinners' | 'totalPrizePointValue'>
      & { prizes: Array<{ __typename: 'DrawPrizePoints' } | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'pointValue' | 'numWinners'>
      )> }
    ) }
  )> }
);


export const CaribouSponsoredDrawPageDataDocument = gql`
    query CaribouSponsoredDrawPageData {
  draw: getMyCurrentCaribouSponsoredDraw {
    id
    prizeStructure {
      __typename
      ... on BaseDrawPrizeStructure {
        id
      }
      ... on DrawPrizeStructureSinglePrizeMultipleWinners {
        prize {
          __typename
          ... on BaseDrawPrize {
            id
          }
          ... on DrawPrizePoints {
            pointValue
          }
        }
        numWinners
      }
      ... on DrawPrizeStructureTieredPrizesMultipleWinners {
        prizes {
          __typename
          ... on DrawTieredPrizePoints {
            pointValue
            numWinners
          }
        }
        numWinners
        totalPrizePointValue
      }
    }
    scheduledEndDate
    myNumTicketsInDraw
    linkToExternalMarketingPage
  }
}
    `;

/**
 * __useCaribouSponsoredDrawPageDataQuery__
 *
 * To run a query within a React component, call `useCaribouSponsoredDrawPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaribouSponsoredDrawPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaribouSponsoredDrawPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCaribouSponsoredDrawPageDataQuery(baseOptions?: Apollo.QueryHookOptions<CaribouSponsoredDrawPageDataQuery, CaribouSponsoredDrawPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaribouSponsoredDrawPageDataQuery, CaribouSponsoredDrawPageDataQueryVariables>(CaribouSponsoredDrawPageDataDocument, options);
      }
export function useCaribouSponsoredDrawPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaribouSponsoredDrawPageDataQuery, CaribouSponsoredDrawPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaribouSponsoredDrawPageDataQuery, CaribouSponsoredDrawPageDataQueryVariables>(CaribouSponsoredDrawPageDataDocument, options);
        }
export type CaribouSponsoredDrawPageDataQueryHookResult = ReturnType<typeof useCaribouSponsoredDrawPageDataQuery>;
export type CaribouSponsoredDrawPageDataLazyQueryHookResult = ReturnType<typeof useCaribouSponsoredDrawPageDataLazyQuery>;
export type CaribouSponsoredDrawPageDataQueryResult = Apollo.QueryResult<CaribouSponsoredDrawPageDataQuery, CaribouSponsoredDrawPageDataQueryVariables>;