/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { AppTheme } from "@rewards-web/shared/style/types";

export interface ProgressBarProps {
  videoDurationSeconds: number | undefined;
  currentTimeSeconds: number;
  isPlaying: boolean;
}

export function ProgressBar({
  videoDurationSeconds,
  currentTimeSeconds,
  isPlaying,
}: ProgressBarProps) {
  return (
    <progress
      css={(theme: AppTheme) => css`
        position: absolute;
        width: 100%;
        bottom: 0px;
        height: 5px;
        border-radius: 0px;
        -webkit-appearance: none;
        border: 0;
        margin: 0;
        padding: 0;

        &[value]::-webkit-progress-value {
          background-color: ${theme.palette.secondary.main};
        }
        &::-moz-progress-bar {
          background-color: ${theme.palette.secondary.main};
        }

        background-color: #d9d9d9;
        &[value]::-webkit-progress-bar {
          background-color: #d9d9d9;
        }

        transition: opacity 0.2s;
        opacity: ${isPlaying ? 1 : 0};
      `}
      max={videoDurationSeconds}
      value={currentTimeSeconds}
    >
      Progress
    </progress>
  );
}
