import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsAppLocaleSelectorDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsAppLocaleSelectorDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'startedAsTrial'>
  ) }
);


export const RewardsAppLocaleSelectorDataDocument = gql`
    query RewardsAppLocaleSelectorData {
  getMyRewardsOrganization {
    id
    startedAsTrial
  }
}
    `;

/**
 * __useRewardsAppLocaleSelectorDataQuery__
 *
 * To run a query within a React component, call `useRewardsAppLocaleSelectorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsAppLocaleSelectorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsAppLocaleSelectorDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsAppLocaleSelectorDataQuery(baseOptions?: Apollo.QueryHookOptions<RewardsAppLocaleSelectorDataQuery, RewardsAppLocaleSelectorDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsAppLocaleSelectorDataQuery, RewardsAppLocaleSelectorDataQueryVariables>(RewardsAppLocaleSelectorDataDocument, options);
      }
export function useRewardsAppLocaleSelectorDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsAppLocaleSelectorDataQuery, RewardsAppLocaleSelectorDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsAppLocaleSelectorDataQuery, RewardsAppLocaleSelectorDataQueryVariables>(RewardsAppLocaleSelectorDataDocument, options);
        }
export type RewardsAppLocaleSelectorDataQueryHookResult = ReturnType<typeof useRewardsAppLocaleSelectorDataQuery>;
export type RewardsAppLocaleSelectorDataLazyQueryHookResult = ReturnType<typeof useRewardsAppLocaleSelectorDataLazyQuery>;
export type RewardsAppLocaleSelectorDataQueryResult = Apollo.QueryResult<RewardsAppLocaleSelectorDataQuery, RewardsAppLocaleSelectorDataQueryVariables>;