import { useTheme } from "@emotion/react";
import { SVGProps } from "react";

export function PointsBannerPointIcon(props: SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity={0.89} cx={15} cy={15} r={15} fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.478 6a.453.453 0 0 0-.411.283l-2.288 6.16H5.454v.001a.452.452 0 0 0-.431.281.388.388 0 0 0 .162.458l5.95 3.906-2.287 6.376a.386.386 0 0 0 .166.457c.16.105.377.104.536 0l5.95-3.907 5.95 3.906c.16.105.376.106.536 0a.386.386 0 0 0 .166-.457l-2.287-6.376 5.95-3.906a.388.388 0 0 0 .162-.457.452.452 0 0 0-.431-.28H18.22l-2.288-6.162A.457.457 0 0 0 15.478 6Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
}
