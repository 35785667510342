import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsProfileQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'displayName' | 'email' | 'phoneNumber'>
  ), getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName'>
  ) }
);


export const RewardsProfileDocument = gql`
    query RewardsProfile {
  getMyRewardsUser {
    id
    displayName
    email
    phoneNumber
  }
  getMyRewardsOrganization {
    id
    shortName
  }
}
    `;

/**
 * __useRewardsProfileQuery__
 *
 * To run a query within a React component, call `useRewardsProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsProfileQuery(baseOptions?: Apollo.QueryHookOptions<RewardsProfileQuery, RewardsProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsProfileQuery, RewardsProfileQueryVariables>(RewardsProfileDocument, options);
      }
export function useRewardsProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsProfileQuery, RewardsProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsProfileQuery, RewardsProfileQueryVariables>(RewardsProfileDocument, options);
        }
export type RewardsProfileQueryHookResult = ReturnType<typeof useRewardsProfileQuery>;
export type RewardsProfileLazyQueryHookResult = ReturnType<typeof useRewardsProfileLazyQuery>;
export type RewardsProfileQueryResult = Apollo.QueryResult<RewardsProfileQuery, RewardsProfileQueryVariables>;