import { useState } from "react";

import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { usePointBalance } from "../modules/point-balance";
import { VideoPlayer } from "../video-player";
import { useMarkVideoOfferWatchedMutation } from "./mark-video-offer-watched.generated";
import { useVideoOfferTrackingHandlers } from "./use-video-offer-tracking-handlers";
import { VideoOfferCompletedModal } from "./video-offer-completed-modal";

export interface VideoOfferPlayerProps {
  videoId: string | undefined;
  url: string | undefined;
  pointsForVideo: number | undefined;
  videoTitle: string | undefined;
  alreadyWatched?: boolean;
  videoOfferId: string;
  firstName: string;
  isThumbnail: boolean;
  isPlayingFromThumbnail?: boolean;
}

export function VideoOfferPlayer({
  videoId,
  url,
  pointsForVideo,
  videoTitle,
  alreadyWatched,
  videoOfferId,
  firstName,
  isThumbnail,
  isPlayingFromThumbnail,
}: VideoOfferPlayerProps) {
  const { formatMessage } = useFormatters();
  const [isVideoCompletedModalOpen, setIsVideoCompletedModalOpen] = useState(
    false
  );

  const snackbar = useSnackbar();
  const pointsBalance = usePointBalance();
  const [markVideoOfferWatched] = useMarkVideoOfferWatchedMutation();

  const trackingHandlers = useVideoOfferTrackingHandlers({
    videoOfferId,
    alreadyWatched: Boolean(alreadyWatched),
    playingFromOfferCard: isPlayingFromThumbnail,
  });

  const handleVideoWatched = async () => {
    try {
      if (!alreadyWatched) {
        // only mark video watched if it wasn't already watched
        await markVideoOfferWatched({
          variables: {
            videoOfferId: videoOfferId!,
          },
        });

        // add local points incase they don't update quick enough in the backend
        pointsBalance.addLocalPoints(pointsForVideo ?? 0);
      }
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Video offer page > video watched error",
          defaultMessage:
            "Points could not be awarded. Please try again later.",
        }),
      });
    }
  };

  const handleVideoEnded = () => {
    setIsVideoCompletedModalOpen(true);
  };

  return (
    <>
      <VideoOfferCompletedModal
        videoOfferId={videoOfferId}
        open={isVideoCompletedModalOpen}
        pointsEarned={pointsForVideo || 0}
        firstName={firstName}
        onAccept={() => {
          setIsVideoCompletedModalOpen(false);
        }}
      />
      <VideoPlayer
        videoId={videoId}
        url={url}
        pointsForVideo={pointsForVideo}
        videoTitle={videoTitle}
        alreadyWatched={alreadyWatched}
        isThumbnail={isThumbnail}
        isPlayingFromThumbnail={isPlayingFromThumbnail}
        onWatched={handleVideoWatched}
        onEnded={handleVideoEnded}
        trackingHandlers={trackingHandlers}
      />
    </>
  );
}
