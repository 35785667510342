import * as React from "react";

function EmailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#444">
        <path d="M23.822 10.091l-3.064-2.032V1.27A.397.397 0 0020.36.87H3.64a.397.397 0 00-.398.397V8.06L.178 10.09a.397.397 0 00-.178.331v12.31c0 .22.178.397.397.397h23.206c.219 0 .397-.178.397-.397v-12.31c0-.138-.07-.26-.178-.33zm-20.58 1.39l-1.247-.661h1.247v.661zm-2.447-.398l9.141 4.849-9.141 6.06V11.082zM12 15.518l10.284 6.816H1.716L12 15.518zm2.064.414l9.141-4.849v10.908l-9.141-6.059zm6.694-5.112h1.247l-1.247.661v-.661zm1.526-.795h-1.526V9.013l1.526 1.012zm-2.322-8.36v10.238l-6.652 3.53-1.09-.724a.397.397 0 00-.44 0l-1.09.723-6.653-3.53V1.667h15.925zm-16.72 8.36H1.717l1.525-1.011v1.01z" />
        <path d="M12 11.324h3.167a.397.397 0 100-.795H12a2.773 2.773 0 01-2.77-2.77v-.116A2.773 2.773 0 0112 4.873a2.773 2.773 0 012.77 2.77v.293a.6.6 0 01-1.2 0v-.557c0-.947-.77-1.717-1.716-1.717-.947 0-1.717.77-1.717 1.717 0 .946.77 1.717 1.717 1.717a1.71 1.71 0 001.13-.427 1.395 1.395 0 002.58-.733v-.293A3.568 3.568 0 0012 4.078a3.568 3.568 0 00-3.564 3.565v.117A3.568 3.568 0 0012 11.324zm-.146-3.023a.923.923 0 010-1.844.923.923 0 010 1.844z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmailIcon;
