/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { mapValues } from "lodash";
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { ListShareableJobPostingsFilters } from "@rewards-web/shared/graphql-types";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { JobFilterCheckboxSet } from "./job-filter-checkbox-set";
import { useJobShareFilterOptionsQuery } from "./job-share-filter-options.generated";
import {
  deduplicateAndSortStrings,
  groupCombinationsByKey,
  serializeSelectedJobFilters,
} from "./lib";
import { JobFilterDialogFormValues } from "./types";

export interface JobFilterDialogProps {
  open: boolean;
  onClose(): void;
  selectedFilters: ListShareableJobPostingsFilters;
  onChangeSelectedFilters(filters: ListShareableJobPostingsFilters): void;
}

export function JobFilterDialog({
  open,
  onClose,
  selectedFilters: prevSelectedFilters,
  onChangeSelectedFilters,
}: JobFilterDialogProps) {
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const { formatMessage } = useFormatters();
  const atLeastMediumScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.up("sm")
  );

  useEffect(() => {
    if (open) {
      track("Job share filter dialog opened", {
        prevSelectedTitles: prevSelectedFilters.titleIn ?? [],
        prevSelectedGeographies: prevSelectedFilters.geographyIn ?? [],
      });
      trackScreenRecordingEvent("rewards_job_share_filter_dialog_opened");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, track, trackScreenRecordingEvent]);

  const optionsQuery = useJobShareFilterOptionsQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
    skip: !open,
  });

  const combinationsByTitle = useMemo(
    () =>
      groupCombinationsByKey(
        optionsQuery.data?.getSharableJobPostingFilterOptions.combinations ??
          [],
        "title"
      ),
    [optionsQuery.data]
  );

  const combinationsByGeography = useMemo(
    () =>
      groupCombinationsByKey(
        optionsQuery.data?.getSharableJobPostingFilterOptions.combinations ??
          [],
        "geography"
      ),
    [optionsQuery.data]
  );

  const orderedTitleOptions = useMemo((): string[] => {
    return deduplicateAndSortStrings(
      (
        optionsQuery.data?.getSharableJobPostingFilterOptions.combinations ?? []
      ).map(({ title }) => title)
    );
  }, [optionsQuery.data]);

  const orderedGeographyOptions = useMemo((): string[] => {
    return deduplicateAndSortStrings(
      (
        optionsQuery.data?.getSharableJobPostingFilterOptions.combinations ?? []
      ).map(({ geography }) => geography)
    );
  }, [optionsQuery.data]);

  const defaultValues = useMemo((): JobFilterDialogFormValues => {
    const selectedTitles = new Set(prevSelectedFilters.titleIn ?? []);
    const selectedGeographies = new Set(prevSelectedFilters.geographyIn ?? []);

    return {
      ...orderedTitleOptions.reduce(
        (prev, titleOption) => ({
          ...prev,
          [`title:${titleOption}`]: selectedTitles.has(titleOption),
        }),
        {}
      ),
      ...orderedGeographyOptions.reduce(
        (prev, geographyOption) => ({
          ...prev,
          [`geography:${geographyOption}`]: selectedGeographies.has(
            geographyOption
          ),
        }),
        {}
      ),
    };
  }, [orderedTitleOptions, orderedGeographyOptions, prevSelectedFilters]);

  const form = useForm({ defaultValues });

  // reset the form anytime the default values change
  // (this should only happen if the incoming values change, or new values come from the API)
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const formValues = useWatch({ control: form.control });
  const {
    titleIn: selectedTitles,
    geographyIn: selectedGeographies,
  } = useMemo(
    () => serializeSelectedJobFilters(formValues as JobFilterDialogFormValues),
    [formValues]
  );

  const numJobsTotal = useMemo(() => {
    const data = optionsQuery.data ?? optionsQuery.previousData;

    if (!data) {
      return null;
    }

    return data.getSharableJobPostingFilterOptions.combinations.reduce(
      (prev, combination) => prev + combination.numJobs,
      0
    );
  }, [optionsQuery.data, optionsQuery.previousData]);

  const numJobsMatchingFilters = useMemo(() => {
    const data = optionsQuery.data ?? optionsQuery.previousData;

    if (!data) {
      return null;
    }

    if (!selectedTitles && !selectedGeographies) {
      // no filters selected; will show all jobs
      return numJobsTotal;
    }

    const selectedTitlesSet = new Set(selectedTitles);
    const selectedGeographiesSet = new Set(selectedGeographies);

    return data.getSharableJobPostingFilterOptions.combinations.reduce(
      (prev, combination) => {
        if (
          (selectedTitlesSet.size === 0 ||
            selectedTitlesSet.has(combination.title)) &&
          (selectedGeographiesSet.size === 0 ||
            selectedGeographiesSet.has(combination.geography))
        ) {
          return prev + combination.numJobs;
        }

        return prev;
      },
      0
    );
  }, [
    optionsQuery.data,
    optionsQuery.previousData,
    selectedTitles,
    selectedGeographies,
    numJobsTotal,
  ]);

  const errorLoadingOptions = Boolean(optionsQuery.error);

  return (
    <ResponsiveDialog
      open={open}
      paddingBottom={0}
      drawerHeight="100%"
      dialogHeight="95%"
      maxDialogHeight="700px"
      maxWidth="1000px"
    >
      <Form
        onSubmit={form.handleSubmit((values) => {
          onChangeSelectedFilters(
            serializeSelectedJobFilters(values as JobFilterDialogFormValues)
          );
          track("Job share filter dialog submitted", {
            didUpdateFilters: form.formState.isDirty,
            numJobsTotal,
            numJobsMatchingFilters,
            selectedTitles: selectedTitles ?? [],
            selectedGeographies: selectedGeographies ?? [],
          });
          onClose();
        })}
        css={(theme: AppTheme) => css`
          height: 100%;
          ${theme.breakpoints.up(960)} {
            width: 800px;
          }
          max-width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: ${theme.palette.background.default};
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            position: relative;
            overflow-y: auto;
            padding: ${theme.spacing(3)} ${theme.spacing(2)};
            ${theme.breakpoints.up(370)} {
              padding: ${theme.spacing(3)} ${theme.spacing(3)};
            }
            ${theme.breakpoints.up("md")} {
              padding: ${theme.spacing(3)} ${theme.spacing(8)};
            }
          `}
        >
          <IconButton
            onClick={() => {
              track("Job share filter dialog close clicked", {
                hadUnsavedFiltersApplied: form.formState.isDirty,
                selectedTitles: selectedTitles ?? [],
                selectedGeographies: selectedGeographies ?? [],
              });
              onClose();
              // reset form to initial state (maybe factoring default values)
              form.reset(defaultValues);
            }}
            aria-label="Cancel"
            css={(theme: AppTheme) => css`
              position: absolute;
              left: ${theme.spacing(1)};
              top: ${theme.spacing(2)};
              ${theme.breakpoints.up("sm")} {
                left: auto;
                right: ${theme.spacing(1)};
              }
            `}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="textPrimary"
            variant="h5"
            align="center"
            css={(theme: AppTheme) => css`
              ${theme.breakpoints.up("sm")} {
                margin-bottom: ${theme.spacing(2)};
              }
            `}
          >
            <FormattedMessage
              description="Jobs page > filter dialog > filter dialog title"
              defaultMessage="Filter Jobs"
            />
          </Typography>
          {errorLoadingOptions && (
            <Alert
              severity="error"
              message={formatMessage({
                description:
                  "Jobs page > filter dialog > data load error description",
                defaultMessage:
                  "An error occurred loading the filters. Please try again later.",
              })}
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(2)};
              `}
            />
          )}
          {!errorLoadingOptions && (
            <div
              css={(theme: AppTheme) => css`
                & > *:not(:first-child) {
                  margin-top: ${theme.spacing(3)};
                }
                ${theme.breakpoints.up("sm")} {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  & > *:not(:first-child) {
                    margin-top: 0;
                  }
                }
                ${theme.breakpoints.up("md")} {
                  margin-top: ${theme.spacing(2)};
                }
              `}
            >
              <div>
                <JobFilterCheckboxSet
                  control={form.control}
                  title={formatMessage({
                    description:
                      "Jobs page > filter dialog > filter dialog job title header",
                    defaultMessage: "Job Title",
                  })}
                  options={orderedTitleOptions}
                  formValues={form.getValues()}
                  getFormKey={(title) => `title:${title}`}
                  getIsDisabled={(title) =>
                    (selectedGeographies?.length ?? 0) > 0 &&
                    selectedGeographies!.every((selectedGeography) =>
                      combinationsByGeography
                        .get(selectedGeography)
                        ?.every((combination) => combination.title !== title)
                    )
                  }
                  numSelectedOptions={selectedTitles?.length ?? 0}
                />
              </div>
              <div>
                <JobFilterCheckboxSet
                  control={form.control}
                  title={formatMessage({
                    description:
                      "Jobs page > filter dialog > filter dialog job location header",
                    defaultMessage: "Location",
                  })}
                  options={orderedGeographyOptions}
                  formValues={form.getValues()}
                  getFormKey={(geography) => `geography:${geography}`}
                  getIsDisabled={(geography) =>
                    (selectedTitles?.length ?? 0) > 0 &&
                    selectedTitles!.every((selectedTitle) =>
                      combinationsByTitle
                        .get(selectedTitle)
                        ?.every(
                          (combination) => combination.geography !== geography
                        )
                    )
                  }
                  numSelectedOptions={selectedGeographies?.length ?? 0}
                />
              </div>
            </div>
          )}
        </div>
        <div
          css={(theme: AppTheme) => css`
            width: 100%;
            background-color: white;
            padding: ${theme.spacing(2)} ${theme.spacing(2)};
            ${theme.breakpoints.up("sm")} {
              padding: ${theme.spacing(2)} ${theme.spacing(4)};
            }
            display: flex;
            bottom: 0;
          `}
        >
          {errorLoadingOptions && (
            <Button
              color="primary"
              onClick={onClose}
              label={formatMessage({
                description: "Jobs page > filter dialog > error close button",
                defaultMessage: "Close",
              })}
            />
          )}
          {!errorLoadingOptions && (
            <>
              <Button
                color="primary"
                variant="text"
                size={atLeastMediumScreen ? "large" : "medium"}
                label={formatMessage({
                  description:
                    "Jobs page > filter dialog > clear filters button",
                  defaultMessage: "Clear All Filters",
                })}
                css={css`
                  text-decoration: underline !important;
                `}
                onClick={() => {
                  track("Job share filter dialog filters cleared", {
                    didUpdateFilters: form.formState.isDirty,
                    numSelectedTitles: (selectedTitles ?? []).length,
                    numSelectedGeographies: (selectedGeographies ?? []).length,
                  });

                  // clear values (if we call reset, it will not clear them all)
                  form.reset(mapValues(defaultValues, () => false));
                }}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size={atLeastMediumScreen ? "large" : "medium"}
                label={formatMessage(
                  {
                    description: "Jobs page > filter dialog > submit button",
                    defaultMessage:
                      "View {num_jobs, number} {num_jobs, plural, one {Job} other {Jobs}}",
                  },
                  {
                    num_jobs: numJobsMatchingFilters,
                  }
                )}
              />
            </>
          )}
        </div>
      </Form>
    </ResponsiveDialog>
  );
}
