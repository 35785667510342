import { Navigate, Route, Routes } from "react-router-dom";

import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { Page } from "@rewards-web/shared/modules/page";

import { LoginPage } from "./login";

export function Unauthenticated(): JSX.Element {
  const { formatMessage } = useFormatters();

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Page
            browserTitle={formatMessage({
              description: "Global > login page browser title",
              defaultMessage: "Caribou Rewards Login",
            })}
            analyticsPageName="Login"
          >
            <LoginPage />
          </Page>
        }
      />

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
