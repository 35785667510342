import * as Types from '@rewards-web/shared/graphql-types';

import { GoalAchievedModalFragmentFragment } from '../../../shared/goal/goal-achieved-modal/goal-achieved-modal-fragment.generated';
import { gql } from '@apollo/client';
import { GoalAchievedModalFragmentFragmentDoc } from '../../../shared/goal/goal-achieved-modal/goal-achieved-modal-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AnnouncementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AnnouncementsQuery = (
  { __typename?: 'Query' }
  & { getMyEmployeeClaimableRecognitionPoints: Array<(
    { __typename?: 'RecognitionPoints' }
    & Pick<Types.RecognitionPoints, 'id' | 'from' | 'pointValue' | 'sentAt' | 'message'>
    & { category?: Types.Maybe<(
      { __typename?: 'RecognitionCategory' }
      & Pick<Types.RecognitionCategory, 'id' | 'name'>
    )> }
  )>, getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'locale' | 'localeSetByUser'>
  ), unacknowledgedPunchCards: Array<(
    { __typename?: 'RewardOffer' }
    & Pick<Types.RewardOffer, 'id'>
    & { completions: Array<(
      { __typename?: 'RewardOfferCompletion' }
      & Pick<Types.RewardOfferCompletion, 'id' | 'awarded' | 'pointValue' | 'numDrawTickets' | 'acknowledged'>
    )> }
  )>, powerHoursWhichEndedToAcknowledge: Array<(
    { __typename?: 'PowerHoursOffer' }
    & Pick<Types.PowerHoursOffer, 'id'>
    & { levels: Array<(
      { __typename?: 'PowerHoursLevel' }
      & Pick<Types.PowerHoursLevel, 'id' | 'finished' | 'pointValue' | 'levelNumber'>
    )> }
  )>, powerHoursWithFinishedLevelsToAcknowledge: Array<(
    { __typename?: 'PowerHoursOffer' }
    & Pick<Types.PowerHoursOffer, 'id'>
    & { levels: Array<(
      { __typename?: 'PowerHoursLevel' }
      & Pick<Types.PowerHoursLevel, 'id' | 'finished' | 'pointValue' | 'levelNumber'>
    )> }
  )>, evvCompletionRateOffersWhichEndedToAcknowledge: Array<(
    { __typename?: 'EVVCompletionRateOffer' }
    & Pick<Types.EvvCompletionRateOffer, 'id'>
    & { levels: Array<(
      { __typename?: 'EVVCompletionRateLevel' }
      & Pick<Types.EvvCompletionRateLevel, 'id' | 'levelNumber' | 'evvCompletionPercentage' | 'numDrawTickets' | 'finished'>
    )> }
  )>, achievedGoalsToAcknowledge: Array<(
    { __typename?: 'Goal' }
    & GoalAchievedModalFragmentFragment
  )> }
);


export const AnnouncementsDocument = gql`
    query Announcements {
  getMyEmployeeClaimableRecognitionPoints {
    id
    from
    pointValue
    sentAt
    message
    category {
      id
      name
    }
  }
  getMyRewardsUser {
    id
    locale
    localeSetByUser
  }
  unacknowledgedPunchCards: listMyRewardOffers(
    filter: {structureType: punch_card, acknowledgedMostRecentlyAwardedCompletion: false}
  ) {
    id
    completions {
      id
      awarded
      pointValue
      numDrawTickets
      acknowledged
    }
  }
  powerHoursWhichEndedToAcknowledge: listMyPowerHoursOffers(
    filter: {ended: true, acknowledgedOfferEnded: false}
  ) {
    id
    levels {
      id
      finished
      pointValue
      levelNumber
    }
  }
  powerHoursWithFinishedLevelsToAcknowledge: listMyPowerHoursOffers(
    filter: {ended: false, finishedSomeLevels: true, acknowledgedLatestFinishedLevel: false}
  ) {
    id
    levels {
      id
      finished
      pointValue
      levelNumber
    }
  }
  evvCompletionRateOffersWhichEndedToAcknowledge: listMyEVVCompletionRateOffers(
    filter: {ended: true, acknowledgedOfferEnded: false}
  ) {
    id
    levels {
      id
      levelNumber
      evvCompletionPercentage
      numDrawTickets
      finished
    }
  }
  achievedGoalsToAcknowledge: getMyRewardsUserGoalsWithUnacknowledgedAchievement {
    ...GoalAchievedModalFragment
  }
}
    ${GoalAchievedModalFragmentFragmentDoc}`;

/**
 * __useAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, options);
      }
export function useAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, options);
        }
export type AnnouncementsQueryHookResult = ReturnType<typeof useAnnouncementsQuery>;
export type AnnouncementsLazyQueryHookResult = ReturnType<typeof useAnnouncementsLazyQuery>;
export type AnnouncementsQueryResult = Apollo.QueryResult<AnnouncementsQuery, AnnouncementsQueryVariables>;