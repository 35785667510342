/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Confetti } from "@rewards-web/shared/components/confetti";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { GoalStatus } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { GoalAchievedModal } from "../../../shared/goal/goal-achieved-modal";
import { usePointBalance } from "../../../shared/modules/point-balance";
import { PageContentContainer } from "../../../shared/page-content-container";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { VideoPlayer } from "../../../shared/video-player";
import {
  EvaluateWatchVideoGoalMutation,
  useEvaluateWatchVideoGoalMutation,
} from "./evaluate-watch-video-goal.generated";
import { useWatchVideoPageDataQuery } from "./watch-video-page-data.generated";

export function WatchVideoPage() {
  const { id: videoId } = useParams<"id">();
  const { formatMessage } = useFormatters();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const pointsBalance = usePointBalance();

  const [achievedGoalForModal, setAchievedGoalForModal] = useState<
    EvaluateWatchVideoGoalMutation["evaluateGoal"] | null
  >(null);
  const [isShowingConfetti, setIsShowingConfetti] = useState(false);

  const query = useWatchVideoPageDataQuery({
    onError: reportError,
    variables: {
      videoId: videoId!,
    },
  });

  const [evaluateGoal] = useEvaluateWatchVideoGoalMutation();

  const loadErrorMessage = formatMessage({
    description: "Watch video page > data load error",
    defaultMessage: "An unexpected error occurred. Please try again later.",
  });

  const handleEnded = async (params: { videoWatchId: string }) => {
    if (query.data?.availableGoal) {
      // there is a goal that should have been achieved after watching the video,
      // so we should evaluate the goal to make sure that it is achieved,
      // and then show a 'goal achieved' modal

      try {
        const result = await evaluateGoal({
          variables: {
            goalId: query.data.availableGoal.id,
          },
        });

        const evaluatedGoal = result.data?.evaluateGoal;

        // ensure video is achieved after watching
        if (!evaluatedGoal || evaluatedGoal.status !== GoalStatus.Achieved) {
          throw new Error(
            `Goal ${query.data.availableGoal.id} not achieved after video watch ${params.videoWatchId}`
          );
        }

        // increment point balance cache,
        // incase user dismisses modal before their points actually increment
        pointsBalance.addLocalPoints(evaluatedGoal.numPoints ?? 0);

        setAchievedGoalForModal(evaluatedGoal); // opens goal achieved modal
      } catch (error) {
        snackbar.show({
          severity: "error",
          message: formatMessage({
            description: "Watch video page > error achieving video goal",
            defaultMessage: "An unexpected error occurred.",
          }),
        });
        reportError(error);
        return;
      }
    }
  };

  const content = (() => {
    if (query.error) {
      return (
        <PageContentContainer>
          <Alert severity="error" message={loadErrorMessage} />
        </PageContentContainer>
      );
    }

    if (!query.data) {
      return (
        <PageContentContainer>
          <PageLoadingState />
        </PageContentContainer>
      );
    }

    if (!query.data.video) {
      return (
        <PageContentContainer>
          <Alert
            message={formatMessage({
              description: "Watch video page > video no longer exists error",
              defaultMessage: "This video no longer exists.",
            })}
            severity="error"
          />
        </PageContentContainer>
      );
    }

    if (!query.data.video.url) {
      return (
        <PageContentContainer>
          <Alert severity="error" message={loadErrorMessage} />
        </PageContentContainer>
      );
    }

    return (
      <>
        {achievedGoalForModal && (
          <>
            <Confetti active={isShowingConfetti} />
            <GoalAchievedModal
              open={Boolean(achievedGoalForModal)}
              goal={achievedGoalForModal}
              onOpened={() => {
                setIsShowingConfetti(true);
              }}
              onAcknowledgementCompleted={() => {
                navigate("/home");
              }}
              analyticsData={{
                fromWatchVideoPage: true,
              }}
            />
          </>
        )}
        <VideoPlayer
          videoId={query.data.video.id}
          url={query.data.video.url}
          pointsForVideo={query.data.availableGoal?.numPoints ?? undefined}
          videoTitle={query.data.video.title ?? undefined}
          isPlayingFromThumbnail={false}
          onEnded={handleEnded}
        />
      </>
    );
  })();

  return (
    <div
      css={css`
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
      `}
    >
      <SubPageHeader
        pageName={formatMessage({
          description: "Watch video page > page title",
          defaultMessage: "Watch video",
        })}
        analyticsPageName="Watch video"
        backTo="rewards"
        css={css`
          flex: 0 1 auto;
        `}
      />
      {content}
    </div>
  );
}
