/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";

export function DisclaimersFooter() {
  return (
    <Typography
      variant="footnote"
      color="grey.800"
      css={css`
        font-size: 0.7rem;
      `}
    >
      <p>
        {" "}
        The merchants represented are not sponsors of the rewards or otherwise
        affiliated with Caribou Health Technologies Inc. The logos and other
        identifying marks attached are trademarks of and owned by each
        represented company and/or its affiliates. Please visit each company's
        website for additional terms and conditions.
      </p>
      <p>
        Restrictions apply, see{" "}
        <a
          href="https://www.amazon.com/gp/help/customer/display.html/ref=s9_acss_bw_tm_BGMDT7_md1_w?nodeId=3122091&pf_rd_m=ATVPDKIKX0DER&pf_rd_s=merchandised-search-8&pf_rd_r=1FJP0A5NSBGJC5AJC6RK&pf_rd_t=101&pf_rd_p=89081f45-f3a3-44e0-8747-edb0acca2333&pf_rd_i=17238247011"
          target="_blank"
          rel="noreferrer"
        >
          amazon.com/gc-legal
        </a>
      </p>
      <p>
        Macy's is not a sponsor of the rewards or promotion or otherwise
        affiliated with this company. The logos and other identifying marks
        attached are trademarks of and owned by each represented company and/or
        its affiliates. Please visit each company's website for additional terms
        and conditions.
      </p>
      <p>
        This company is not affiliated with Wal-Mart Stores, Inc., Wal-Mart
        Stores Arkansas, LLC,{" "}
        <a href="https://www.walmart.com" target="_blank" rel="noreferrer">
          Walmart.com
        </a>
        or any of their affiliates. Wal-Mart Stores, Inc., Wal-Mart Stores
        Arkansas, LLC,
        <a href="https://www.walmart.com" target="_blank" rel="noreferrer">
          Walmart.com
        </a>
        and their affiliates do not endorse or sponsor this reward program's
        services, products, or activities. See
        <a
          href="https://www.walmart.com/help/article/gift-card-terms-and-conditions/90f9e217278146898946c1d786bedea3"
          target="_blank"
          rel="noreferrer"
        >
          www.walmart.com/giftcardtermsandconditions
        </a>
        or complete gift card terms and conditions.
      </p>

      <p>
        Target and the Bullseye Design are registered trademarks of Target
        Brands, Inc. Terms and conditions are applied to gift cards. Target is
        not a participating partner in or sponsor of this offer.
      </p>
      <p>
        T.J.Maxx, Marshalls, HomeGoods, Homesense, and Sierra are not affiliated
        with this company and are not sponsors or co-sponsors with this
        promotion. Use of T.J.Maxx, Marshalls, HomeGoods, Homesense, and Sierra
        names, logos, images, or trademarks require written approval from TJX
        Incentive Sales, Inc. Participation by T.J.Maxx, Marshalls, HomeGoods,
        Homesense, and Sierra in the program is not intended as, and shall not
        constitute, a promotion or marketing of the program by T.J.Maxx,
        Marshalls, HomeGoods, Homesense, and Sierra, The TJX Companies, Inc, or
        any of its subsidiaries or affiliates.
      </p>
      <p>
        The Starbucks word mark and the Starbucks Logo are trademarks of
        Starbucks Corporation. Starbucks is also the owner of the Copyrights in
        the Starbucks Logo and the Starbucks Card designs. All rights reserved.
        Starbucks is not a participating partner or sponsor in this offer.
      </p>
      <p>
        The Dunkin' Donuts trademarks/trade dress are owned by DD IP Holder LLC
        and used under license. Dunkin' Donuts is not a sponsor of this
        promotion. promotion.
      </p>
    </Typography>
  );
}
