/** @jsxImportSource @emotion/react */
import { QueryResult } from "@apollo/client";
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { MilestoneRewardStep } from "../../../../shared/milestone-rewards-offer/milestone-reward-step";
import { ScrollToTopOnMount } from "../../../../shared/scroll-to-top-on-mount";
import { GetMilestoneRewardsForSubPageQuery } from "../milestones-page-data.generated";
import { EVVDisclaimer } from "./evv-disclaimer";

interface MilestoneRewardsContentProps {
  userMilestoneRewardsQuery: QueryResult<GetMilestoneRewardsForSubPageQuery>;
}

export function MilestoneRewardsContent({
  userMilestoneRewardsQuery,
}: MilestoneRewardsContentProps) {
  const { formatMessage } = useFormatters();

  const errorContent = (
    <Alert
      message={formatMessage({
        description: "Milestone rewards offer page > page load error message",
        defaultMessage: "An unexpected error occurred. Please try again later.",
      })}
      severity="error"
    />
  );

  const skeletonContent = (
    <div
      css={(appTheme: AppTheme) => css`
        padding: ${appTheme.spacing(0, 2.5)};
      `}
    >
      {Array.from(Array(5)).map((_, i) => (
        <Skeleton key={i} height={100} animated />
      ))}
    </div>
  );

  if (userMilestoneRewardsQuery.error) {
    return errorContent;
  }

  if (userMilestoneRewardsQuery.loading) {
    return skeletonContent;
  }

  const { milestoneRewardsSubPageData, organization } =
    userMilestoneRewardsQuery.data ?? {};

  if (!milestoneRewardsSubPageData || !organization) {
    return errorContent;
  }

  const { pointsPerDollar } = organization;

  return (
    <div>
      <ScrollToTopOnMount />
      {milestoneRewardsSubPageData.mustCompleteEVV && <EVVDisclaimer />}
      <div>
        {milestoneRewardsSubPageData.milestoneSteps.map((milestone, index) => (
          <div
            key={milestone.id}
            css={(appTheme: AppTheme) => css`
              &:not(:first-of-type) {
                border-top: 1px solid ${appTheme.palette.divider};
              }
            `}
          >
            <MilestoneRewardStep
              variant="subpage"
              milestone={milestone}
              indexOfThisMilestone={index}
              isCurrentMilestone={
                milestoneRewardsSubPageData.currentMilestoneStep?.id ===
                milestone.id
              }
              mustCompleteEVV={milestoneRewardsSubPageData.mustCompleteEVV}
              pointsPerDollar={pointsPerDollar}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
