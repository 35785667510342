import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type JobsPageReferralsEnabledQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type JobsPageReferralsEnabledQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'referralsEnabled' | 'userReferralLinkDisabled'>
  ) }
);


export const JobsPageReferralsEnabledDocument = gql`
    query JobsPageReferralsEnabled {
  getMyRewardsUser {
    id
    referralsEnabled
    userReferralLinkDisabled
  }
}
    `;

/**
 * __useJobsPageReferralsEnabledQuery__
 *
 * To run a query within a React component, call `useJobsPageReferralsEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsPageReferralsEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsPageReferralsEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobsPageReferralsEnabledQuery(baseOptions?: Apollo.QueryHookOptions<JobsPageReferralsEnabledQuery, JobsPageReferralsEnabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsPageReferralsEnabledQuery, JobsPageReferralsEnabledQueryVariables>(JobsPageReferralsEnabledDocument, options);
      }
export function useJobsPageReferralsEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsPageReferralsEnabledQuery, JobsPageReferralsEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsPageReferralsEnabledQuery, JobsPageReferralsEnabledQueryVariables>(JobsPageReferralsEnabledDocument, options);
        }
export type JobsPageReferralsEnabledQueryHookResult = ReturnType<typeof useJobsPageReferralsEnabledQuery>;
export type JobsPageReferralsEnabledLazyQueryHookResult = ReturnType<typeof useJobsPageReferralsEnabledLazyQuery>;
export type JobsPageReferralsEnabledQueryResult = Apollo.QueryResult<JobsPageReferralsEnabledQuery, JobsPageReferralsEnabledQueryVariables>;