/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

export interface JobPromotionBannerProps extends StylableProps {
  message: string;
}

export function JobPromotionBanner({
  message,
  className,
}: JobPromotionBannerProps) {
  return (
    <div
      className={className}
      css={(theme: AppTheme) => css`
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: -10px;
          top: 0;
          width: 0;
          height: 0;
          border-top: 12px solid ${theme.palette.tertiary.main};
          border-bottom: 12px solid ${theme.palette.tertiary.main};
          border-left: 10px solid transparent;
        }
      `}
    >
      <Typography
        variant="caption"
        css={(theme: AppTheme) => css`
          display: block;
          color: white;
          background-color: ${theme.palette.tertiary.main};
          height: 24px;
          text-transform: uppercase;
          padding: 0px 8px;
          line-height: 24px;

          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
        `}
      >
        {message}
      </Typography>
    </div>
  );
}
