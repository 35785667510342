/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

interface ReauthHelpTextProps {
  onHelpClick(): void;
}

export function ReauthHelpText({ onHelpClick }: ReauthHelpTextProps) {
  return (
    <Typography
      variant="body"
      color="textPrimary"
      align="center"
      css={(theme: AppTheme) => css`
        margin-top: ${theme.spacing(1)};
        ${theme.breakpoints.up("md")} {
          font-size: 1.2em;
        }
      `}
    >
      <FormattedMessage
        description="Re-auth page > help text"
        defaultMessage="Need help? Email <mailto>{email}</mailto>"
        values={{
          email: "help@caribou.care",
          mailto: (nodes) => (
            <Typography
              color="primary"
              component="a"
              href="mailto:help@caribou.care"
              onClick={onHelpClick}
            >
              {nodes}
            </Typography>
          ),
        }}
      />
    </Typography>
  );
}
