/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { AppTheme } from "@rewards-web/shared/style/types";

export function CheckIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.75C10.8555 0.75 14 3.89453 14 7.75C14 11.6328 10.8555 14.75 7 14.75C3.11719 14.75 0 11.6328 0 7.75C0 3.89453 3.11719 0.75 7 0.75ZM10.1445 6.54688C10.4453 6.24609 10.4453 5.78125 10.1445 5.48047C9.84375 5.17969 9.37891 5.17969 9.07812 5.48047L6.125 8.43359L4.89453 7.23047C4.59375 6.92969 4.12891 6.92969 3.82812 7.23047C3.52734 7.53125 3.52734 7.99609 3.82812 8.29688L5.57812 10.0469C5.71484 10.2109 5.90625 10.2656 6.125 10.2656C6.31641 10.2656 6.50781 10.2109 6.64453 10.0469L10.1445 6.54688Z"
        css={(appTheme: AppTheme) =>
          css`
            fill: ${appTheme.palette.primary.main};
          `
        }
      />
    </svg>
  );
}
