/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import shareJobMegaphoneLargeUrl from "../../../../shared/goal/graphics/share-job-megaphone-large.png";
import { ReferralsGoalCard } from "../../../../shared/goal/referrals-goal-card";
import { BaseGoalTrackPage, BaseGoalTrackPageProps } from "../base-track-page";
import { useShareJobTrackPageDataQuery } from "./share-job-track-page-data.generated";

export interface ShareJobGoalTrackProps {
  trackData: BaseGoalTrackPageProps["trackData"];
  analyticsData: BaseGoalTrackPageProps["analyticsData"];
}

export function ShareJobGoalTrackPage({
  trackData,
  analyticsData,
}: ShareJobGoalTrackProps) {
  const { formatMessage } = useFormatters();
  const query = useShareJobTrackPageDataQuery({ onError: reportError });

  return (
    <BaseGoalTrackPage
      trackData={trackData}
      analyticsData={analyticsData}
      title={formatMessage({
        defaultMessage: "Referral goals",
        description: "Goal track page > share job track title",
      })}
      icon={<img src={shareJobMegaphoneLargeUrl} alt="megaphone" />}
      additionalGoalCards={query.data?.activeReferrals.referrals?.map(
        (referral) => (
          <ReferralsGoalCard key={referral.id} referral={referral} />
        )
      )}
      content={
        <>
          <Typography
            variant="body"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {query.data ? (
              formatMessage(
                {
                  defaultMessage:
                    "Earn points each month for sharing jobs. You'll earn up to {total_points_per_referral, number} points when your referral gets hired, so make sure they are ready to pick-up the phone.",
                  description:
                    "Goal track page > share job track > description one",
                },
                {
                  total_points_per_referral:
                    query.data.getMyRewardsOrganization
                      .maxPointsEarnedPerCandidate,
                }
              )
            ) : (
              <Skeleton width="100%" height={100} />
            )}
          </Typography>
          <Typography variant="body">
            {formatMessage({
              defaultMessage:
                "Out of job shares? You can still share jobs! You won't earn points for sharing, but will earn if your referral gets hired.",
              description:
                "Goal track page > share job track > description two",
            })}
          </Typography>
        </>
      }
    />
  );
}
