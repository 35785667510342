import { SVGProps } from "react";

export function CheckCircleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 0C10.4288 0 7.91543 0.762436 5.77759 2.19089C3.63975 3.61935 1.97351 5.64967 0.989572 8.02511C0.00563274 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5994 25.9944 17.9749 25.0104C20.3503 24.0265 22.3806 22.3602 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9959 9.55343 24.625 6.24919 22.1879 3.8121C19.7508 1.37501 16.4466 0.00406601 13 0ZM18.5628 10.881L12.6537 16.7901C12.4321 17.0116 12.1316 17.1361 11.8182 17.1361C11.5048 17.1361 11.2043 17.0116 10.9826 16.7901L7.43719 13.2446C7.32431 13.1356 7.23428 13.0052 7.17234 12.861C7.1104 12.7168 7.0778 12.5618 7.07644 12.4048C7.07507 12.2479 7.10497 12.0923 7.1644 11.9471C7.22382 11.8018 7.31157 11.6699 7.42254 11.5589C7.5335 11.4479 7.66545 11.3602 7.81069 11.3008C7.95594 11.2413 8.11156 11.2114 8.26848 11.2128C8.4254 11.2142 8.58048 11.2468 8.72466 11.3087C8.86885 11.3706 8.99926 11.4607 9.10828 11.5735L11.8182 14.2835L16.8917 9.20991C17.1146 8.99463 17.4132 8.87551 17.723 8.8782C18.0329 8.88089 18.3293 9.00519 18.5484 9.2243C18.7675 9.44342 18.8918 9.73984 18.8945 10.0497C18.8972 10.3596 18.7781 10.6581 18.5628 10.881Z"
        fill={props.fill || "url(#paint0_linear_3741_23384)"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3741_23384"
          x1="-2.69454"
          y1="30.407"
          x2="21.7242"
          y2="3.32327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BA0DB" />
          <stop offset="1" stopColor="#57BF99" />
        </linearGradient>
      </defs>
    </svg>
  );
}
