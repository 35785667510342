/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { Role } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SwitchAgencyDialog } from "../switch-agency-dialog";
import ProfileIcon from "./icons/profile-icon.png";
import { generateAdminAppLink } from "./lib";
import { PointsBanner } from "./points-banner";
import { useRewardsHeaderDataQuery } from "./rewards-header.generated";

export function RewardsHeader() {
  const { data, loading } = useRewardsHeaderDataQuery({
    onError: reportError,
  });
  const track = useTrack();
  const theme = useTheme();
  const { formatMessage } = useFormatters();
  const [switchAgencyDialogOpen, setSwitchAgencyDialogOpen] = useState(false);

  const roles = data?.getMyIdentity?.roles.reduce(
    (acc, currRole) => {
      if (!currRole.tenantId || !currRole.active) return acc;

      if (currRole.roleName === Role.RewardsUser) {
        acc.organizationIdUserRoleMap.set(currRole.tenantId, currRole.id);
      } else if (currRole.roleName === Role.RewardsAdmin) {
        acc.organizationIdAdminRoleMap.set(currRole.tenantId, currRole.id);
      }
      return acc;
    },
    {
      organizationIdUserRoleMap: new Map<string, string>(),
      organizationIdAdminRoleMap: new Map<string, string>(),
    }
  );

  const myOrgs = roles
    ? Array.from(roles.organizationIdUserRoleMap.keys())
    : [];

  const activeAdminRoleInCurrentOrg = data?.getMyIdentity?.roles.find(
    (role) =>
      role.active &&
      role.roleName === Role.RewardsAdmin &&
      data?.getMyRewardsOrganization?.id === role.tenantId
  );

  const greeting = (() => {
    if (loading) {
      return <Skeleton width="40%" />;
    } else if (data) {
      return formatMessage(
        {
          description: "Rewards page > personalized greeting",
          defaultMessage: "Hi, {name}",
        },
        {
          name: data.getMyRewardsUser.firstName,
        }
      );
    } else {
      // fallback
      return formatMessage({
        description: "Rewards page > generic greeting",
        defaultMessage: "Welcome back!",
      });
    }
  })();

  useEffect(() => {
    if (activeAdminRoleInCurrentOrg) {
      track("Open manager dashboard button visible");
    }
  }, [track, activeAdminRoleInCurrentOrg]);

  return (
    <>
      <div
        css={(appTheme: AppTheme) =>
          css`
            background-color: ${appTheme.palette.primary.main};
          `
        }
      >
        <div
          css={(appTheme: AppTheme) =>
            css`
              padding: ${appTheme.spacing(2.25)} 0;
              width: calc(100% - ${appTheme.spacing(4)});
              max-width: ${appTheme.maxContentWidth}px;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              gap: ${appTheme.spacing(3)};
              ${appTheme.breakpoints.up("md")} {
                flex-direction: row;
                padding: ${appTheme.spacing(3.75)} 0;
                gap: ${appTheme.spacing(2)};
              }
            `
          }
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex: 1;
            `}
          >
            <Link
              to="/settings"
              css={css`
                position: relative;
              `}
            >
              <div
                css={css`
                  position: absolute;
                  background-color: #ffffff;
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  top: 0px;
                  right: -8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <FontAwesomeIcon
                  icon={faGear}
                  color={theme.palette.primary.main}
                />
              </div>
              <img
                css={css`
                  width: 46px;
                `}
                src={ProfileIcon}
                alt="profile icon"
              />
            </Link>

            <div
              css={(appTheme: AppTheme) =>
                css`
                  margin-left: ${appTheme.spacing(2.5)};
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  flex-grow: 1;
                `
              }
            >
              <Typography
                css={(appTheme: AppTheme) =>
                  css`
                    color: ${appTheme.palette.primary.contrastText};
                  `
                }
                variant="h6"
              >
                {greeting}
              </Typography>
              <Typography
                css={(appTheme: AppTheme) =>
                  css`
                    color: ${appTheme.palette.primary.contrastText};
                  `
                }
                variant="footnote"
              >
                {loading ? (
                  <Skeleton width="40%" />
                ) : (
                  data?.getMyRewardsOrganization.shortName
                )}
              </Typography>
            </div>

            <div
              css={(appTheme: AppTheme) =>
                css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: right;
                  gap: ${appTheme.spacing(1)};
                `
              }
            >
              {activeAdminRoleInCurrentOrg && data && (
                <Button
                  css={(appTheme: AppTheme) => css`
                    text-align: center;
                    color: ${appTheme.palette.primary.contrastText};
                  `}
                  typographyVariant="buttonSmall"
                  size="small"
                  variant="outlined"
                  label={formatMessage({
                    description:
                      "Rewards page > Manager dashboard button label",
                    defaultMessage: "Manager Dashboard",
                  })}
                  width="auto"
                  target="_blank"
                  externalLink
                  linkTo={generateAdminAppLink({
                    adminAppBaseUrl: data?.appBaseUrls.rewardsAdminAppBaseUrl,
                    adminEmail: activeAdminRoleInCurrentOrg.email ?? null,
                  })}
                  onClick={() => {
                    track("Open manager dashboard button clicked");
                  }}
                />
              )}
              {myOrgs && myOrgs.length > 1 && (
                <Button
                  css={(appTheme: AppTheme) => css`
                    text-align: center;
                    color: ${appTheme.palette.primary.contrastText};
                  `}
                  typographyVariant="buttonSmall"
                  size="small"
                  variant="outlined"
                  label={formatMessage({
                    description: "Rewards page > Switch account button label",
                    defaultMessage: "Switch Account",
                  })}
                  width="auto"
                  onClick={() => {
                    setSwitchAgencyDialogOpen(true);
                  }}
                />
              )}
            </div>
          </div>

          <PointsBanner />
        </div>
      </div>
      {roles && myOrgs && myOrgs.length > 1 && (
        <SwitchAgencyDialog
          rewardsUserRoleIdsByOrganizationIds={roles.organizationIdUserRoleMap}
          rewardsAdminRoleIdsByOrganizationIds={
            roles.organizationIdAdminRoleMap
          }
          open={switchAgencyDialogOpen}
          onClose={() => setSwitchAgencyDialogOpen(false)}
        />
      )}
    </>
  );
}
