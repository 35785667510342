import { SchedulingSoftware } from "@rewards-web/shared/graphql-types";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";

export type SchedulingSoftwareDetails = {
  appName: string;
  appStoreLink: string;
  playStoreLink: string;
};

export const getSchedulingSoftwareDetails = (
  schedulingSoftware: SchedulingSoftware | undefined | null
): SchedulingSoftwareDetails | null => {
  switch (schedulingSoftware) {
    case SchedulingSoftware.Hhaexchange:
      return {
        appName: "HHAeXchange",
        appStoreLink: "https://apps.apple.com/us/app/hhaexchange/id883673336",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.hhaexchange.caregiver",
      };
    case SchedulingSoftware.Alayacare:
      return {
        appName: "AlayaCare",
        appStoreLink: "https://apps.apple.com/us/app/alayacare/id1030754584",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.alayacare.careworkerapp",
      };
    case SchedulingSoftware.Axiscare:
      return {
        appName: "AxisCare",
        appStoreLink:
          "https://apps.apple.com/us/app/axiscare-mobile/id1081635097",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.axiscare",
      };
    case SchedulingSoftware.Wellsky:
      return {
        appName: "WellSky",
        appStoreLink:
          "https://apps.apple.com/us/app/wellsky-personal-care/id1304726452",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.clearcare.clearcareconnect",
      };
    case SchedulingSoftware.Unknown:
    case undefined:
    case null:
      return null;

    default:
      shouldBeNever(schedulingSoftware); // avoid app crash when new scheduling software is added and the mapping hasn't been updated
      return null;
  }
};
