import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PointsBannerDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PointsBannerDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'minimumPointsNeededToRedeem' | 'pointsPerDollar'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id'>
    )> }
  ), getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'referralsEnabled'>
  ) }
);


export const PointsBannerDataDocument = gql`
    query PointsBannerData {
  getMyRewardsOrganization {
    id
    minimumPointsNeededToRedeem
    pointsPerDollar
    whiteLabelConfig {
      id
    }
  }
  getMyRewardsUser {
    id
    referralsEnabled
  }
}
    `;

/**
 * __usePointsBannerDataQuery__
 *
 * To run a query within a React component, call `usePointsBannerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointsBannerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointsBannerDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePointsBannerDataQuery(baseOptions?: Apollo.QueryHookOptions<PointsBannerDataQuery, PointsBannerDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointsBannerDataQuery, PointsBannerDataQueryVariables>(PointsBannerDataDocument, options);
      }
export function usePointsBannerDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointsBannerDataQuery, PointsBannerDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointsBannerDataQuery, PointsBannerDataQueryVariables>(PointsBannerDataDocument, options);
        }
export type PointsBannerDataQueryHookResult = ReturnType<typeof usePointsBannerDataQuery>;
export type PointsBannerDataLazyQueryHookResult = ReturnType<typeof usePointsBannerDataLazyQuery>;
export type PointsBannerDataQueryResult = Apollo.QueryResult<PointsBannerDataQuery, PointsBannerDataQueryVariables>;