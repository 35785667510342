import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ResendGenericGiftCardLinkMutationVariables = Types.Exact<{
  redemptionId: Types.Scalars['String'];
}>;


export type ResendGenericGiftCardLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'resendGenericGiftCardLink'>
);


export const ResendGenericGiftCardLinkDocument = gql`
    mutation ResendGenericGiftCardLink($redemptionId: String!) {
  resendGenericGiftCardLink(redemptionId: $redemptionId)
}
    `;
export type ResendGenericGiftCardLinkMutationFn = Apollo.MutationFunction<ResendGenericGiftCardLinkMutation, ResendGenericGiftCardLinkMutationVariables>;

/**
 * __useResendGenericGiftCardLinkMutation__
 *
 * To run a mutation, you first call `useResendGenericGiftCardLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendGenericGiftCardLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendGenericGiftCardLinkMutation, { data, loading, error }] = useResendGenericGiftCardLinkMutation({
 *   variables: {
 *      redemptionId: // value for 'redemptionId'
 *   },
 * });
 */
export function useResendGenericGiftCardLinkMutation(baseOptions?: Apollo.MutationHookOptions<ResendGenericGiftCardLinkMutation, ResendGenericGiftCardLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendGenericGiftCardLinkMutation, ResendGenericGiftCardLinkMutationVariables>(ResendGenericGiftCardLinkDocument, options);
      }
export type ResendGenericGiftCardLinkMutationHookResult = ReturnType<typeof useResendGenericGiftCardLinkMutation>;
export type ResendGenericGiftCardLinkMutationResult = Apollo.MutationResult<ResendGenericGiftCardLinkMutation>;
export type ResendGenericGiftCardLinkMutationOptions = Apollo.BaseMutationOptions<ResendGenericGiftCardLinkMutation, ResendGenericGiftCardLinkMutationVariables>;