/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { AppTheme } from "@rewards-web/shared/style/types";

export type IconBadgeData =
  | {
      type: "unread_badge";
    }
  | {
      type: "number";
      value: number;
    }
  | { type: "dollar" };

export interface NavigationIconWithBadgeProps {
  iconElement: JSX.Element;

  /**
   * The type of badge to display.
   *
   * If `type` is `unread_badge`, displays a dot.
   * If `type` is `number`, displays a number.
   */
  badge: IconBadgeData | null;
}

export function NavigationIconWithBadge({
  iconElement,
  badge,
}: NavigationIconWithBadgeProps): JSX.Element {
  const badgeContent = (() => {
    if (!badge) {
      return null;
    }

    switch (badge.type) {
      case "unread_badge":
        return (
          <div
            css={(theme: AppTheme) => css`
              position: absolute;
              top: -1px;
              right: -5px;
              width: 10px;
              height: 10px;
              background: ${theme.palette.secondary.main};
              border-radius: 50%;
            `}
          />
        );
      case "number":
        return (
          <div
            css={(theme: AppTheme) => css`
              position: absolute;
              top: -10px;
              right: -14px;
              width: 24px;
              height: 24px;
              background: ${theme.palette.tertiary.main};
              color: white;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <span
              css={(theme: AppTheme) => css`
                font-family: ${theme.typography.fontFamily};
                font-size: 11px;
                font-weight: 700;
              `}
            >
              {badge.value}
            </span>
          </div>
        );
      case "dollar":
        return (
          <div
            css={(theme: AppTheme) => css`
              position: absolute;
              top: -10px;
              right: -14px;
              width: 24px;
              height: 24px;
              background: ${theme.palette.tertiary.main};
              color: white;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <span
              css={(theme: AppTheme) => css`
                font-family: ${theme.typography.fontFamily};
                font-size: 11px;
                font-weight: 700;
              `}
            >
              $
            </span>
          </div>
        );
      default:
        assertNever(badge);
    }
  })();

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      {iconElement}
      {badgeContent}
    </div>
  );
}
