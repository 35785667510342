import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EvvTrackPageDataQueryVariables = Types.Exact<{
  startDateGte: Types.Scalars['Timestamp'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type EvvTrackPageDataQuery = (
  { __typename?: 'Query' }
  & { listMyVisitsAsOfStartDate: (
    { __typename?: 'ListMyVisitsAsOfStartDateResponse' }
    & { items: Array<(
      { __typename?: 'Visit' }
      & Pick<Types.Visit, 'id' | 'startAt' | 'endAt'>
    )> }
  ), getMyRewardsOrganizationEVVRules: (
    { __typename?: 'RewardsOrganizationEVVRules' }
    & Pick<Types.RewardsOrganizationEvvRules, 'id'>
    & { rules: (
      { __typename?: 'EVVRules' }
      & Pick<Types.EvvRules, 'minutesLessThanScheduledStart' | 'minutesGreaterThanScheduledStart' | 'minutesLessThanScheduledEnd' | 'minutesGreaterThanScheduledEnd' | 'minutesLessThanScheduledDuration' | 'minutesGreaterThanScheduledDuration' | 'validClockMethods'>
    ) }
  ) }
);


export const EvvTrackPageDataDocument = gql`
    query EVVTrackPageData($startDateGte: Timestamp!, $limit: Int) {
  listMyVisitsAsOfStartDate(page: {startDateGte: $startDateGte, limit: $limit}) {
    items {
      id
      startAt
      endAt
    }
  }
  getMyRewardsOrganizationEVVRules {
    id
    rules {
      minutesLessThanScheduledStart
      minutesGreaterThanScheduledStart
      minutesLessThanScheduledEnd
      minutesGreaterThanScheduledEnd
      minutesLessThanScheduledDuration
      minutesGreaterThanScheduledDuration
      validClockMethods
    }
  }
}
    `;

/**
 * __useEvvTrackPageDataQuery__
 *
 * To run a query within a React component, call `useEvvTrackPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvvTrackPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvvTrackPageDataQuery({
 *   variables: {
 *      startDateGte: // value for 'startDateGte'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEvvTrackPageDataQuery(baseOptions: Apollo.QueryHookOptions<EvvTrackPageDataQuery, EvvTrackPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvvTrackPageDataQuery, EvvTrackPageDataQueryVariables>(EvvTrackPageDataDocument, options);
      }
export function useEvvTrackPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvvTrackPageDataQuery, EvvTrackPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvvTrackPageDataQuery, EvvTrackPageDataQueryVariables>(EvvTrackPageDataDocument, options);
        }
export type EvvTrackPageDataQueryHookResult = ReturnType<typeof useEvvTrackPageDataQuery>;
export type EvvTrackPageDataLazyQueryHookResult = ReturnType<typeof useEvvTrackPageDataLazyQuery>;
export type EvvTrackPageDataQueryResult = Apollo.QueryResult<EvvTrackPageDataQuery, EvvTrackPageDataQueryVariables>;