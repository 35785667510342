import {
  getOperatingSystem,
  SupportedOperatingSystem,
} from "../lib/get-operating-system";

/**
 * We need to use a different url scheme depending on the operating system
 * https://stackoverflow.com/a/19126326
 */
export function generateSMSShareUrl(shareLink: string) {
  return `sms:${
    getOperatingSystem() === SupportedOperatingSystem.ANDROID ? "?" : "&"
  }body=${encodeURI(shareLink)}`;
}
