/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { EvvStreakGoalTrackerVisitDetail } from "@rewards-web/shared/graphql-types";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { VisitDetail } from "../visit-detail";

export interface ShiftsToReachGoalProps {
  targetStreak: number;
  trackedVisits: EvvStreakGoalTrackerVisitDetail[];
  untrackedVisitsLoading: boolean;
  untrackedVisits: { id: string; startAt: number; endAt: number }[];
}

export function ShiftsToReachGoal({
  targetStreak,
  trackedVisits,
  untrackedVisitsLoading,
  untrackedVisits,
}: ShiftsToReachGoalProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleShifts = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      css={(theme: AppTheme) =>
        css`
          width: 100%;
          margin-top: ${theme.spacing(3)};
        `
      }
    >
      <div
        css={(appTheme: AppTheme) =>
          css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            margin-bottom: ${appTheme.spacing(3)};
          `
        }
        onClick={toggleShifts}
      >
        <Typography
          variant="body"
          css={css`
            align-self: flex-start;
            font-weight: 600;
          `}
        >
          <FormattedMessage
            description="Goal track page > evv streak track shifts to reach goal"
            defaultMessage="{target_streak} {target_streak, plural, one {shift} other {shifts}} to reach your goal"
            values={{
              target_streak: targetStreak,
            }}
          />
        </Typography>

        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
      </div>

      <Divider
        css={css`
          width: 100%;
        `}
      />

      {isExpanded && (
        <div
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(3)};
            `
          }
        >
          {trackedVisits.map((trackedVisit) => (
            <VisitDetail
              key={trackedVisit.id}
              startAt={trackedVisit.rangeStart}
              hasTracked={true}
            />
          ))}

          {untrackedVisitsLoading && untrackedVisits?.length === 0 ? (
            <Skeleton width="100%" height={100} />
          ) : (
            untrackedVisits.map((untrackedVisit) => (
              <VisitDetail
                key={untrackedVisit.id}
                startAt={untrackedVisit.startAt}
                hasTracked={false}
              />
            ))
          )}

          <Divider
            css={(theme: AppTheme) =>
              css`
                width: 100%;
                margin-bottom: ${theme.spacing(3)};
              `
            }
          />
        </div>
      )}
    </div>
  );
}
