/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { sumBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import {
  Confetti,
  DEFAULT_CONFETTI_DURATION_MS,
} from "@rewards-web/shared/components/confetti";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { useWindowFocusHandler } from "@rewards-web/shared/hooks/use-window-focus-handler";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { GoalAchievedModal } from "../../../shared/goal/goal-achieved-modal";
import { usePointBalance } from "../../../shared/modules/point-balance";
import { ClaimRecognitionModal } from "./claim-recognition-modal";
import { EVVCompletionRateOfferEndedModal } from "./evv-completion-rate-offer-ended-modal";
import { PowerHoursOfferEndedModal } from "./power-hours-ended-modal";
import { PowerHoursLevelFinishedModal } from "./power-hours-level-finished-modal";
import { PunchCardsCompletedModal } from "./punch-cards-complete-modal";
import { AnnouncementsDocument, AnnouncementsQuery } from "./query.generated";
import { SetLocaleModal } from "./set-locale-modal";
import { Announcement } from "./types";

/**
 * Loads one or many announcements that should be shown to the user
 * anytime the page is focused.
 */
export function AnnouncementModal() {
  const { formatMessage } = useFormatters();
  const snackbar = useSnackbar();
  const client = useApolloClient();
  const pointBalance = usePointBalance();
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [claimBonusPointsParam] = useQueryParam("claim_bonus_points");
  const claimBonusPointsParamInitiallyEnabled = useMemo(
    () => claimBonusPointsParam,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  const removeAnnouncement = useCallback(
    (announcementId: Announcement["id"]) =>
      setAnnouncements((prevAnnouncements) => [
        ...prevAnnouncements.filter(({ id }) => id !== announcementId),
      ]),
    []
  );

  const loadAnnouncement = useDebouncedCallback(
    async (opts: { firstRender: boolean } = { firstRender: false }) => {
      try {
        const resp = await client.query<AnnouncementsQuery>({
          query: AnnouncementsDocument,
        });

        const {
          data: {
            getMyEmployeeClaimableRecognitionPoints: claimableRecognitionPoints,
            getMyRewardsUser: { localeSetByUser },
            unacknowledgedPunchCards,
            powerHoursWithFinishedLevelsToAcknowledge,
            powerHoursWhichEndedToAcknowledge,
            evvCompletionRateOffersWhichEndedToAcknowledge,
            achievedGoalsToAcknowledge,
          },
        } = resp;

        const announcements: Announcement[] = [];

        if (!localeSetByUser) {
          announcements.push({ id: "set_locale", type: "SET_LOCALE" });
        }

        if (claimableRecognitionPoints.length > 0) {
          announcements.push({
            id: "recognition_points",
            type: "RECOGNITION_POINTS",
            claimableRecognitionPoints,
          });
        } else if (opts.firstRender && claimBonusPointsParamInitiallyEnabled) {
          snackbar.show({
            severity: "warning",
            message: formatMessage({
              description:
                "Claim bonus points modal > already claimed warning snackbar",
              defaultMessage: "You have already claimed these points",
            }),
          });
        }

        if (unacknowledgedPunchCards.length > 0) {
          announcements.push({
            id: "punch_cards_completed",
            type: "PUNCH_CARDS_COMPLETED",
            unacknowledgedPunchCards,
            pointsEarnedForAnnouncement: sumBy(
              unacknowledgedPunchCards.map((punchCard) =>
                sumBy(
                  punchCard.completions,
                  (completion) => completion.pointValue ?? 0
                )
              )
            ),
          });
        }

        for (const powerHoursOffer of powerHoursWhichEndedToAcknowledge) {
          announcements.push({
            id: `power_hours_offer_ended:${powerHoursOffer.id}`,
            type: "POWER_HOURS_OFFER_ENDED",
            powerHoursOffer,
            pointsEarnedForAnnouncement: sumBy(
              powerHoursOffer.levels.filter((level) => level.finished),
              (level) => level.pointValue
            ),
          });
        }

        for (const powerHoursOffer of powerHoursWithFinishedLevelsToAcknowledge) {
          announcements.push({
            id: `power_hours_level_finished:${powerHoursOffer.id}`,
            type: "POWER_HOURS_LEVEL_FINISHED",
            powerHoursOffer,
          });
        }

        for (const evvCompletionRateOffer of evvCompletionRateOffersWhichEndedToAcknowledge) {
          // Show modal only if some level was completed
          if (evvCompletionRateOffer.levels.some((level) => level.finished)) {
            announcements.push({
              id: `evv_completion_rate_offer_ended:${evvCompletionRateOffer.id}`,
              type: "EVV_COMPLETION_RATE_OFFER_ENDED",
              evvCompletionRateOffer,
            });
          }
        }

        // we expect the backend to sort goals by achievedAt
        for (const achievedGoal of achievedGoalsToAcknowledge) {
          announcements.push({
            id: `goal_achieved:${achievedGoal.id}`,
            type: "GOAL_ACHIEVED",
            goal: achievedGoal,
            pointsEarnedForAnnouncement: achievedGoal.numPoints ?? 0,
          });
        }

        setAnnouncements(announcements);

        const pointsForAnnouncements = sumBy(
          announcements,
          (announcement) => announcement.pointsEarnedForAnnouncement ?? 0
        );

        if (pointsForAnnouncements > 0) {
          pointBalance.setPendingPointValue(pointsForAnnouncements);
        }
      } catch (error) {
        reportError(error);
      }
    }
  );

  useEffect(() => {
    if (announcements.length === 0) {
      // after clearing all announcements, clear the pending point value.
      // wait a tiny bit of time for the modal to close,
      // so the user notices the point balance increase
      pointBalance.clearPendingPointValue();
    }
  }, [announcements, pointBalance]);

  // load announcement on first render
  useEffect(() => {
    loadAnnouncement({ firstRender: true });
  }, [loadAnnouncement]);

  // ... and load announcement again if the page is re-focused
  useWindowFocusHandler({
    onFocused: loadAnnouncement,
  });

  useEffect(() => {
    if (isConfettiActive) {
      setTimeout(
        () => setIsConfettiActive(false),
        DEFAULT_CONFETTI_DURATION_MS
      );
    }
  }, [isConfettiActive]);

  const content = () => {
    const currentlyVisibleAnnouncement = announcements[0];

    if (!currentlyVisibleAnnouncement) {
      return null;
    }

    switch (currentlyVisibleAnnouncement.type) {
      case "SET_LOCALE":
        return (
          <SetLocaleModal closeModal={() => removeAnnouncement("set_locale")} />
        );
      case "RECOGNITION_POINTS":
        return (
          <ClaimRecognitionModal
            claimableRecognitionPoints={
              currentlyVisibleAnnouncement.claimableRecognitionPoints
            }
            closeModal={() => {
              setIsConfettiActive(true);
              removeAnnouncement("recognition_points");
            }}
          />
        );
      case "PUNCH_CARDS_COMPLETED":
        return (
          <PunchCardsCompletedModal
            punchCards={currentlyVisibleAnnouncement.unacknowledgedPunchCards}
            onOpened={() => {
              setIsConfettiActive(true);
            }}
            onRemoveAnnouncement={() =>
              removeAnnouncement("punch_cards_completed")
            }
          />
        );
      case "POWER_HOURS_LEVEL_FINISHED":
        return (
          <PowerHoursLevelFinishedModal
            powerHoursOffer={currentlyVisibleAnnouncement.powerHoursOffer}
            onOpened={() => {
              setIsConfettiActive(true);
            }}
            onRemoveAnnouncement={() =>
              removeAnnouncement(
                `power_hours_level_finished:${currentlyVisibleAnnouncement.powerHoursOffer.id}`
              )
            }
          />
        );
      case "POWER_HOURS_OFFER_ENDED":
        return (
          <PowerHoursOfferEndedModal
            powerHoursOffer={currentlyVisibleAnnouncement.powerHoursOffer}
            onOpened={() => {
              setIsConfettiActive(true);
            }}
            onRemoveAnnouncement={() =>
              removeAnnouncement(
                `power_hours_offer_ended:${currentlyVisibleAnnouncement.powerHoursOffer.id}`
              )
            }
          />
        );
      case "EVV_COMPLETION_RATE_OFFER_ENDED":
        return (
          <EVVCompletionRateOfferEndedModal
            evvCompletionRateOffer={
              currentlyVisibleAnnouncement.evvCompletionRateOffer
            }
            onOpened={() => {
              setIsConfettiActive(true);
            }}
            onRemoveAnnouncement={() =>
              removeAnnouncement(
                `evv_completion_rate_offer_ended:${currentlyVisibleAnnouncement.evvCompletionRateOffer.id}`
              )
            }
          />
        );
      case "GOAL_ACHIEVED":
        return (
          // key is necessary here to force a re-render between modals
          <GoalAchievedModal
            key={currentlyVisibleAnnouncement.goal.id}
            open
            goal={currentlyVisibleAnnouncement.goal}
            onOpened={() => setIsConfettiActive(true)}
            onDismissed={() =>
              removeAnnouncement(currentlyVisibleAnnouncement.id)
            }
          />
        );
      default:
        assertNever(
          currentlyVisibleAnnouncement,
          `Unexpected announcement type ${
            (announcements[0] as Announcement).type
          }`
        );
    }
  };

  return (
    <>
      <Confetti active={isConfettiActive} />
      {content()}
    </>
  );
}
