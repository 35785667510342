/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import {
  Drawer as MuiDrawer,
  Slide,
  Dialog,
  useMediaQuery,
  SlideProps,
} from "@material-ui/core";
import React, { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { TestProps } from "@rewards-web/shared/types";

import { TypographyProps } from "../typography";
import { DialogHeader } from "./dialog-header";

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export interface ResponsiveDialogProps extends TestProps {
  children: ReactNode;
  open: boolean;
  onClose?(): void;

  /**
   * Called after the open animation has completed, and the dialog is visible
   */
  onOpened?(): void;
  fullScreenOnMobile?: boolean;
  mobileType?: "drawer" | "dialog";
  drawerHeight?: string;
  dialogHeight?: string;
  maxDrawerHeight?: string;
  maxDialogHeight?: string;
  maxWidth?: string;
  title?: string;
  titleVariant?: Exclude<TypographyProps["variant"], undefined>;
  backgroundColor?: string;
  paddingBottom?: number;
}

/**
 * Recognizes users screen size then selects designated
 * dialog type (fullscreen modal or bottom drawer)
 * for the screen size (mobile / computer)
 */
export function ResponsiveDialog({
  children,
  open,
  onClose,
  onOpened,
  cypressId,
  fullScreenOnMobile = false,
  mobileType = "drawer",
  maxWidth = "350px",
  maxDrawerHeight = "auto",
  maxDialogHeight = "auto",
  drawerHeight = "auto",
  dialogHeight = "auto",
  title,
  titleVariant = "subtitle",
  backgroundColor,
  paddingBottom,
}: ResponsiveDialogProps): JSX.Element {
  const mobileScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("xs")
  );

  const type: "drawer" | "dialog" = mobileScreen ? mobileType : "dialog";
  return (
    <ClassNames>
      {({ css, theme }) => {
        const paddingBottomCss =
          typeof paddingBottom === "number"
            ? `${paddingBottom}px`
            : theme.spacing(1);

        return (
          <>
            {type === "drawer" && (
              <MuiDrawer
                data-cy={cypressId}
                PaperProps={{
                  className: css`
                    height: ${drawerHeight};
                    max-height: ${maxDrawerHeight};
                    border-top-left-radius: ${theme.spacing(2)};
                    border-top-right-radius: ${theme.spacing(2)};
                    background-color: ${backgroundColor || "#f6f5f6"};
                    padding-bottom: calc(
                      ${paddingBottomCss} + env(safe-area-inset-bottom)
                    );
                  `,
                }}
                anchor="bottom"
                open={open}
                onClose={onClose}
                SlideProps={{
                  onEntered: onOpened,
                }}
              >
                {title && (
                  <DialogHeader
                    title={title}
                    titleVariant={titleVariant}
                    onClose={onClose}
                  />
                )}
                {children}
              </MuiDrawer>
            )}
            {type === "dialog" && (
              <Dialog
                data-cy={cypressId}
                fullScreen={fullScreenOnMobile && mobileScreen}
                open={open}
                maxWidth={fullScreenOnMobile && mobileScreen ? undefined : "xs"}
                onClose={onClose}
                TransitionProps={{
                  onEntered: onOpened,
                }}
                TransitionComponent={Transition}
                PaperProps={{
                  className: css`
                    ${fullScreenOnMobile && mobileScreen
                      ? ""
                      : css`
                          height: ${dialogHeight};
                          max-height: ${maxDialogHeight};
                          max-width: ${maxWidth};
                          border-radius: ${theme.spacing(2)};
                          margin: ${theme.spacing(2)};
                        `}
                    padding-bottom: ${paddingBottomCss};
                    background-color: ${backgroundColor || "#f6f5f6"};
                    ${theme.breakpoints.up("sm")} {
                      min-width: 350px;
                    }

                    // force scrollbars
                    &::-webkit-scrollbar {
                      -webkit-appearance: none;
                      width: 7px;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 4px;
                      background-color: rgba(0, 0, 0, 0.5);
                      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                    }
                  `,
                }}
              >
                {title && (
                  <DialogHeader
                    title={title}
                    titleVariant={titleVariant}
                    onClose={onClose}
                  />
                )}
                {children}
              </Dialog>
            )}
          </>
        );
      }}
    </ClassNames>
  );
}
