/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { AppTheme } from "@rewards-web/shared/style/types";

export function CheckIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 17C10.7545 17 12.9162 16.1046 14.5108 14.5108C16.1046 12.9162 17 10.7545 17 8.5C17 6.24555 16.1046 4.08383 14.5108 2.48924C12.9162 0.895438 10.7545 0 8.5 0C6.24555 0 4.08383 0.895419 2.48924 2.48924C0.895438 4.08377 0 6.24555 0 8.5C0 10.7545 0.895419 12.9162 2.48924 14.5108C4.08377 16.1046 6.24555 17 8.5 17ZM4.08735 8.72385C4.38872 8.42465 4.87556 8.42465 5.17693 8.72385L6.95473 10.5017L11.429 6.02743C11.7354 5.76445 12.1925 5.78257 12.4779 6.068C12.7634 6.35344 12.7808 6.80984 12.5185 7.117L7.49589 12.1396H7.49517C7.1938 12.4395 6.70696 12.4395 6.40559 12.1396L4.08734 9.82137C3.941 9.67648 3.85913 9.4787 3.85913 9.27297C3.85913 9.0665 3.941 8.86945 4.08734 8.72384L4.08735 8.72385Z"
        css={(appTheme: AppTheme) =>
          css`
            fill: ${appTheme.palette.primary.main};
          `
        }
      />
    </svg>
  );
}
