/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { UnauthenticatedPageLayout } from "../../../shared/unauthenticated-page-layout";

export function Deactivated(): JSX.Element {
  return (
    <UnauthenticatedPageLayout>
      <Typography
        variant="h1"
        color="primary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <FormattedMessage
          description="Deactivated page > title"
          defaultMessage="Your account is no longer active"
        />
      </Typography>
      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <FormattedMessage
          description="Deactivated page > detail"
          defaultMessage="Your account has been de-activated by your company's HR. If this isn't right, please get in touch with us at <mailto>{help_email}</mailto>"
          values={{
            help_email: "help@caribou.care",
            mailto: (nodes) => <a href="mailto:help@caribou.care">{nodes}</a>,
          }}
        />
      </Typography>
    </UnauthenticatedPageLayout>
  );
}
