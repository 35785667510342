/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { darken } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { VerificationCodeField } from "./verification-code-field";

const HELP_EMAIL = "help@caribou.care";

export interface VerificationCodeFormProps {
  onSubmit(phoneNumber: string, wasPasted: boolean): Promise<void>;
  onResend: () => void;
  errorMessage?: string;
  buttonText?: string;
  onClearErrorMessage?: () => void;
  onHelpClick?: () => void;
}

export function VerificationCodeForm({
  onSubmit,
  onResend,
  errorMessage,
  onClearErrorMessage,
  buttonText,
  onHelpClick,
}: VerificationCodeFormProps): JSX.Element {
  const { formatMessage } = useFormatters();
  const [wasPasted, setWasPasted] = useState(false);

  const theme = useTheme();
  const [code, setCode] = useState("");

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      `}
    >
      <Form
        onSubmit={handleSubmit(() => onSubmit(code, wasPasted))}
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        <VerificationCodeField
          code={code}
          setCode={setCode}
          setWasPasted={setWasPasted}
          hasError={!!errorMessage}
          onClearErrorMessage={onClearErrorMessage}
        />
        <Typography
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(3.5)};
            ${theme.breakpoints.up("md")} {
              margin-top: ${theme.spacing(5)};
            }
          `}
          variant="footnote"
          color="error"
        >
          {errorMessage}
        </Typography>
        <Button
          type="submit"
          label={
            buttonText ||
            formatMessage({
              description: "Login page > verification code submit button",
              defaultMessage: "Verify",
            })
          }
          color="primary"
          size="large"
          disabled={!/^\d{6}$/.test(code)}
          loading={isSubmitting}
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(3.5)};
            ${theme.breakpoints.up("md")} {
              margin-top: ${theme.spacing(5)};
            }
            &:hover,
            &.Mui-disabled {
              opacity: 1;
              background-color: ${darken(theme.palette.primary.main, 0.1)};
            }
          `}
        />
      </Form>
      <Typography
        color="textPrimary"
        variant="footnote"
        css={(appTheme: AppTheme) =>
          css`
            margin-top: ${appTheme.spacing(3.5)};
            ${theme.breakpoints.up("md")} {
              margin-top: ${theme.spacing(5)};
            }
          `
        }
      >
        {formatMessage(
          {
            description: "Login page > resend code",
            defaultMessage: "<resend_link>Resend Code</resend_link>",
          },
          {
            resend_link: (nodes) => (
              <button
                onClick={() => {
                  setCode("");
                  onResend();
                }}
                css={(appTheme: AppTheme) =>
                  css`
                    color: ${appTheme.palette.primary.main};
                    outline: none;
                    border: none;
                    cursor: pointer;
                    background-color: transparent;
                    text-decoration: underline;
                  `
                }
              >
                <Typography variant="footnote">{nodes}</Typography>
              </button>
            ),
          }
        )}
      </Typography>
      <Typography
        color="textPrimary"
        variant="footnote"
        css={(appTheme: AppTheme) =>
          css`
            margin-top: ${appTheme.spacing(3.5)};
            text-align: center;
          `
        }
      >
        {formatMessage({
          description: "Login page > resend code help",
          defaultMessage:
            "Still didn't get a code? You may not have an account with us yet.",
        })}
      </Typography>
      <Typography
        css={(appTheme: AppTheme) =>
          css`
            margin-top: ${appTheme.spacing(3.5)};
            margin-bottom: ${appTheme.spacing(5)};
          `
        }
        color="textPrimary"
        variant="footnote"
      >
        {formatMessage(
          {
            description: "Login page > verification code > help link",
            defaultMessage:
              "Email <help_link>{email}</help_link> for assistance.",
          },
          {
            help_link: (nodes) => (
              <a
                href={`mailto:${HELP_EMAIL}`}
                css={(appTheme: AppTheme) =>
                  css`
                    color: ${appTheme.palette.primary.main};
                  `
                }
                onClick={onHelpClick}
              >
                {nodes}
              </a>
            ),
            email: HELP_EMAIL,
          }
        )}
      </Typography>
    </div>
  );
}
