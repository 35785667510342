/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEvent } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

interface NumCompletedPunchCardsPillProps {
  numCompletedCards: number;
  onClick(e: MouseEvent<HTMLDivElement>): void;
}

export function NumCompletedPunchCardsPill({
  numCompletedCards,
  onClick,
}: NumCompletedPunchCardsPillProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        // increase clickable area
        padding: ${theme.spacing(1)};
        margin: -${theme.spacing(1)};
      `}
      onClick={(e) => onClick(e)}
    >
      <div
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.tertiary.main};
          border-radius: 100px;
          padding: ${theme.spacing(0.2)} ${theme.spacing(1)};
          color: white;
          display: flex;
          align-items: center;
          margin-top: 2px;
          gap: ${theme.spacing(0.75)};
        `}
      >
        <FontAwesomeIcon size="sm" icon={faCheck} />
        <Typography variant="caption" textTransform="uppercase" color="white">
          <FormattedMessage
            description="Rewards page > punch card > points earned"
            defaultMessage="{num_completed_cards} {num_completed_cards, plural, one {card} other {cards}}"
            values={{ num_completed_cards: numCompletedCards }}
          />
        </Typography>
      </div>
    </div>
  );
}
