/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { useWindowFocusHandler } from "@rewards-web/shared/hooks/use-window-focus-handler";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePointBalance } from "../../../../../shared/modules/point-balance";
import { WithOnboardingTooltip } from "../../../../../shared/onboarding-tour-tooltip";
import { ChevronIcon } from "../icons/chevron-icon";
import { PointsBannerCurrentBalanceText } from "./points-banner-current-balance-text";
import { PointsBannerRedemptionProgressCircle } from "./points-banner-redemption-progress-circle";
import { useRewardsPointsBannerDataQuery } from "./points-banner.generated";

const POINTS_ANIMATION_DURATION_MS = 800;
export const POINTS_BANNER_TOOLTIP_NAME = "POINTS_BANNER";

export function PointsBanner() {
  const track = useTrack();
  const { computedBalance, refreshPointBalance } = usePointBalance();

  const { data, loading, error } = useRewardsPointsBannerDataQuery({
    onError: reportError,
  });
  const { formatMessage } = useFormatters();

  // refresh points balance on mount,
  // and when window re-focuses
  useEffect(() => {
    refreshPointBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useWindowFocusHandler({ onFocused: refreshPointBalance });

  if (error) {
    return null;
  }

  return (
    <WithOnboardingTooltip
      css={css`
        flex: 1;
        border-radius: 20px;
      `}
      name={POINTS_BANNER_TOOLTIP_NAME}
    >
      <Link
        to="/my-rewards"
        onClick={() => {
          track("Home page banner total points clicked", {
            currentPoints: computedBalance,
            minimumPointsToRedeem:
              data?.getMyRewardsOrganization.minimumPointsNeededToRedeem,
            hasEnoughPointsToRedeem:
              typeof computedBalance === "number" &&
              typeof data?.getMyRewardsOrganization
                .minimumPointsNeededToRedeem === "number"
                ? computedBalance >=
                  data?.getMyRewardsOrganization.minimumPointsNeededToRedeem
                : null,
          });
        }}
        css={(appTheme: AppTheme) => css`
          border-radius: 20px;
          padding: ${appTheme.spacing(2)};
          background-color: #ffffff20;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          flex: 1;
        `}
      >
        <PointsBannerRedemptionProgressCircle
          currentPoints={computedBalance}
          minimumPointsToRedeem={
            data?.getMyRewardsOrganization.minimumPointsNeededToRedeem
          }
          animationDurationMs={POINTS_ANIMATION_DURATION_MS}
        />
        <div
          css={(appTheme: AppTheme) => css`
            flex: 1;
            margin-left: ${appTheme.spacing(1.25)};
          `}
        >
          <PointsBannerCurrentBalanceText
            pointBalance={computedBalance}
            loading={loading}
            animationDurationMs={POINTS_ANIMATION_DURATION_MS}
          />

          <Typography variant="footnote" color="white">
            {loading ? (
              <Skeleton width="40%" />
            ) : (
              formatMessage(
                {
                  defaultMessage: "{points_per_dollar} pts = $1",
                  description:
                    "Rewards page > points banner > points per dollar",
                },
                {
                  points_per_dollar:
                    data?.getMyRewardsOrganization.pointsPerDollar,
                }
              )
            )}
          </Typography>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            background-color: #ffffff;
          `}
        >
          <ChevronIcon />
        </div>
      </Link>
    </WithOnboardingTooltip>
  );
}
