/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

import { PUNCH_CARD_BREAKPOINTS } from "../constants";

interface PunchCardPositionContainerProps extends StylableProps {
  children: ReactNode;
}

export function PunchCardPositionContainer({
  children,
  className,
}: PunchCardPositionContainerProps) {
  return (
    <div
      className={className}
      css={(theme: AppTheme) => css`
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 100px;

        width: 30px;
        height: 40px;
        ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.sm)} {
          width: 40px;
          height: 50px;
        }
        ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.md)} {
          width: 65px;
          height: 80px;
        }
        ${theme.breakpoints.up(PUNCH_CARD_BREAKPOINTS.lg)} {
          width: 100px;
          height: 75px;
        }
      `}
    >
      {children}
    </div>
  );
}
