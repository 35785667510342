import { SVGProps } from "react";

function YellowCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={56}
      height={56}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#a)">
        <path
          d="M28 8C17.507 8 9 16.507 9 27s8.507 19 19 19 19-8.507 19-19S38.493 8 28 8Zm5.455 12.58a2.362 2.362 0 1 1 3.34 3.34l-9.049 9.048a3 3 0 0 1-4.242 0l-4.299-4.298a2.362 2.362 0 0 1 3.34-3.34l.959.959a3 3 0 0 0 4.242 0l5.71-5.709Z"
          fill="#FFC700"
        />
        <path
          d="M28 4.899C15.794 4.899 5.899 14.794 5.899 27S15.794 49.101 28 49.101 50.101 39.206 50.101 27 40.206 4.899 28 4.8 25.353c.301.022.603.022.904 0l-.452.452-.452-.452Z"
          stroke="#fff"
          strokeWidth={6.203}
        />
      </g>
      <defs>
        <filter
          id="a"
          x={0.316}
          y={0.557}
          width={55.367}
          height={55.367}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1.241} />
          <feGaussianBlur stdDeviation={1.241} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_601_4223"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_601_4223"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default YellowCheck;
