export function closeFullscreenIfOpen() {
  // force close full screen (using fallbacks -- specifically for ios safari)
  const isInFullScreen =
    document.fullscreenElement ||
    (document as any).webkitDisplayingFullscreen ||
    (document as any).webkitFullscreenElement ||
    (document as any).mozFullScreenElement ||
    (document as any).msFullscreenElement;

  if (isInFullScreen) {
    if (typeof document.exitFullscreen === "function") {
      document.exitFullscreen?.();
    }

    if (typeof (document as any).mozCancelFullScreen === "function") {
      (document as any).mozCancelFullScreen?.();
    }

    if (typeof (document as any).webkitExitFullscreen === "function") {
      (document as any).webkitExitFullscreen?.();
    }

    if (typeof (document as any).msExitFullscreen === "function") {
      (document as any).msExitFullscreen?.();
    }
  }
}
