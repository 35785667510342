import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OrganizationRunDrawPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationRunDrawPageDataQuery = (
  { __typename?: 'Query' }
  & { currentDraw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'status' | 'scheduledOpenDate' | 'scheduledCloseDate'>
    & { prizeStructure: (
      { __typename: 'DrawPrizeStructureSinglePrizeMultipleWinners' }
      & Pick<Types.DrawPrizeStructureSinglePrizeMultipleWinners, 'id' | 'numWinners'>
      & { prize: (
        { __typename: 'DrawPrizePoints' }
        & Pick<Types.DrawPrizePoints, 'id' | 'pointValue'>
      ) | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'id'>
      ) }
    ) | (
      { __typename: 'DrawPrizeStructureTieredPrizesMultipleWinners' }
      & Pick<Types.DrawPrizeStructureTieredPrizesMultipleWinners, 'id' | 'numWinners' | 'totalPrizePointValue'>
      & { prizes: Array<{ __typename: 'DrawPrizePoints' } | (
        { __typename: 'DrawTieredPrizePoints' }
        & Pick<Types.DrawTieredPrizePoints, 'pointValue' | 'numWinners'>
      )> }
    ), announcementVideo?: Types.Maybe<(
      { __typename?: 'RewardVideo' }
      & Pick<Types.RewardVideo, 'id' | 'title' | 'status' | 'url'>
    )> }
  )>, lastDraw?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'winnersAnnounced' | 'myRewardsUserHasTicketsInDraw' | 'myRewardsUserViewedWinners'>
  )>, myRewardOffers: Array<(
    { __typename?: 'RewardOffer' }
    & Pick<Types.RewardOffer, 'id' | 'cardTitle' | 'cardButtonLabel' | 'cardGraphicKey' | 'numCompletions'>
    & { action: (
      { __typename: 'RewardOfferModalAction' }
      & Pick<Types.RewardOfferModalAction, 'id' | 'modalContentsHtml'>
    ) | (
      { __typename: 'RewardOfferInAppLinkAction' }
      & Pick<Types.RewardOfferInAppLinkAction, 'id' | 'linkPath'>
    ) | (
      { __typename: 'RewardOfferExternalLinkAction' }
      & Pick<Types.RewardOfferExternalLinkAction, 'id' | 'url'>
    ), nextReward?: Types.Maybe<(
      { __typename?: 'RewardOfferNextReward' }
      & Pick<Types.RewardOfferNextReward, 'id' | 'pointValue' | 'numDrawTickets'>
    )> }
  )> }
);


export const OrganizationRunDrawPageDataDocument = gql`
    query OrganizationRunDrawPageData {
  currentDraw: getMyCurrentDraw {
    id
    prizeStructure {
      __typename
      ... on BaseDrawPrizeStructure {
        id
      }
      ... on DrawPrizeStructureSinglePrizeMultipleWinners {
        prize {
          __typename
          ... on BaseDrawPrize {
            id
          }
          ... on DrawPrizePoints {
            pointValue
          }
        }
        numWinners
      }
      ... on DrawPrizeStructureTieredPrizesMultipleWinners {
        prizes {
          __typename
          ... on DrawTieredPrizePoints {
            pointValue
            numWinners
          }
        }
        numWinners
        totalPrizePointValue
      }
    }
    status
    scheduledOpenDate
    scheduledCloseDate
    announcementVideo {
      id
      title
      status
      url
    }
  }
  lastDraw: getLastDraw {
    id
    winnersAnnounced
    myRewardsUserHasTicketsInDraw
    myRewardsUserViewedWinners
  }
  myRewardOffers: listMyRewardOffers {
    id
    cardTitle
    cardButtonLabel
    cardGraphicKey
    numCompletions
    action {
      __typename
      ... on RewardOfferModalAction {
        id
        modalContentsHtml
      }
      ... on RewardOfferInAppLinkAction {
        id
        linkPath
      }
      ... on RewardOfferExternalLinkAction {
        id
        url
      }
    }
    nextReward {
      id
      pointValue
      numDrawTickets
    }
  }
}
    `;

/**
 * __useOrganizationRunDrawPageDataQuery__
 *
 * To run a query within a React component, call `useOrganizationRunDrawPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRunDrawPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRunDrawPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationRunDrawPageDataQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationRunDrawPageDataQuery, OrganizationRunDrawPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationRunDrawPageDataQuery, OrganizationRunDrawPageDataQueryVariables>(OrganizationRunDrawPageDataDocument, options);
      }
export function useOrganizationRunDrawPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationRunDrawPageDataQuery, OrganizationRunDrawPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationRunDrawPageDataQuery, OrganizationRunDrawPageDataQueryVariables>(OrganizationRunDrawPageDataDocument, options);
        }
export type OrganizationRunDrawPageDataQueryHookResult = ReturnType<typeof useOrganizationRunDrawPageDataQuery>;
export type OrganizationRunDrawPageDataLazyQueryHookResult = ReturnType<typeof useOrganizationRunDrawPageDataLazyQuery>;
export type OrganizationRunDrawPageDataQueryResult = Apollo.QueryResult<OrganizationRunDrawPageDataQuery, OrganizationRunDrawPageDataQueryVariables>;