import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { ButtonProps } from "@rewards-web/shared/components/button";
import { RewardOffer } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { RewardOfferModal } from "./reward-offer-modal";

type RewardOfferProps = Pick<RewardOffer, "cardTitle" | "action">;

const RewardOfferActionContext = createContext<{
  handleAction(): void;
  offer: RewardOfferProps | null;
}>({
  handleAction: () => {},
  offer: null,
});

interface RewardOfferActionProviderProps {
  children: ReactNode;
  offer: RewardOfferProps;
}

export function RewardOfferActionProvider({
  children,
  offer,
}: RewardOfferActionProviderProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleAction = useCallback(() => {
    switch (offer.action.__typename) {
      case "RewardOfferModalAction":
        return setModalOpen(true);
      case "RewardOfferInAppLinkAction":
        return navigate(offer.action.linkPath);
      case "RewardOfferExternalLinkAction":
        return window.open(offer.action.url);
      default:
        reportError(`Could not handle offer action ${offer.action.__typename}`);
    }
  }, [offer.action, navigate]);

  return (
    <RewardOfferActionContext.Provider value={{ handleAction, offer }}>
      {children}
      {offer.action.__typename === "RewardOfferModalAction" && (
        <RewardOfferModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={offer.cardTitle}
          contentsHTML={offer.action.modalContentsHtml}
        />
      )}
    </RewardOfferActionContext.Provider>
  );
}

export function useOfferActionHandler() {
  const { handleAction } = useContext(RewardOfferActionContext);
  return handleAction;
}

export function useOfferActionLinkProps(): Pick<
  ButtonProps,
  "linkTo" | "externalLink"
> {
  const { offer } = useContext(RewardOfferActionContext);

  return {
    linkTo: (() => {
      switch (offer?.action.__typename) {
        case "RewardOfferInAppLinkAction":
          return offer.action.linkPath;
        case "RewardOfferExternalLinkAction":
          return offer.action.url;
        default:
          return undefined;
      }
    })(),
    externalLink: offer?.action.__typename === "RewardOfferExternalLinkAction",
  };
}

export function useOfferActionLink() {}
