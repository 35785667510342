import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ClaimRecognitionPointsMutationVariables = Types.Exact<{
  recognitionPointsIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type ClaimRecognitionPointsMutation = (
  { __typename?: 'Mutation' }
  & { bulkClaimRecognitionPoints: Array<(
    { __typename?: 'RecognitionPoints' }
    & Pick<Types.RecognitionPoints, 'id'>
  )> }
);


export const ClaimRecognitionPointsDocument = gql`
    mutation ClaimRecognitionPoints($recognitionPointsIds: [ID!]!) {
  bulkClaimRecognitionPoints(recognitionPointsIds: $recognitionPointsIds) {
    id
  }
}
    `;
export type ClaimRecognitionPointsMutationFn = Apollo.MutationFunction<ClaimRecognitionPointsMutation, ClaimRecognitionPointsMutationVariables>;

/**
 * __useClaimRecognitionPointsMutation__
 *
 * To run a mutation, you first call `useClaimRecognitionPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimRecognitionPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimRecognitionPointsMutation, { data, loading, error }] = useClaimRecognitionPointsMutation({
 *   variables: {
 *      recognitionPointsIds: // value for 'recognitionPointsIds'
 *   },
 * });
 */
export function useClaimRecognitionPointsMutation(baseOptions?: Apollo.MutationHookOptions<ClaimRecognitionPointsMutation, ClaimRecognitionPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimRecognitionPointsMutation, ClaimRecognitionPointsMutationVariables>(ClaimRecognitionPointsDocument, options);
      }
export type ClaimRecognitionPointsMutationHookResult = ReturnType<typeof useClaimRecognitionPointsMutation>;
export type ClaimRecognitionPointsMutationResult = Apollo.MutationResult<ClaimRecognitionPointsMutation>;
export type ClaimRecognitionPointsMutationOptions = Apollo.BaseMutationOptions<ClaimRecognitionPointsMutation, ClaimRecognitionPointsMutationVariables>;