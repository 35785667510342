/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact, sum } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import { ShareDialog } from "@rewards-web/rewards-app/src/shared/share-dialog";
import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { GoalType } from "@rewards-web/shared/graphql-types";
import { useRefHeight } from "@rewards-web/shared/hooks/use-ref-height";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageContentContainer } from "../../../shared/page-content-container";
import { PointsStarIcon } from "../../../shared/points-star-icon";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { BriefcaseIcon } from "./icons/briefcase-icon";
import { DollarIcon } from "./icons/dollar-icon";
import { LocationIcon } from "./icons/location-icon";
import { useGetJobDetailsQuery } from "./query.generated";
import { RewardStructureDialog } from "./reward-structure";

export function JobDetailsPage(): JSX.Element {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const { job_id } = useParams();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [
    isRewardStructureDialogOpen,
    setIsRewardStructureDialogOpen,
  ] = useState(false);

  useEffect(() => {
    trackScreenRecordingEvent("viewed_job_details");
  }, [trackScreenRecordingEvent]);

  const { data, loading, error } = useGetJobDetailsQuery({
    variables: {
      jobPostingId: job_id!,
    },
    onError: reportError,
  });

  const { ref: footerRef, height: footerHeight } = useRefHeight({
    isReady: !!data,
  });

  const buttonText = useMemo(() => {
    const legacyStructure =
      data?.getMyRewardsOrganization.referralRewardStructure.jobShareStructure;
    const rewardFromLegacyStructure = legacyStructure?.reward;

    const availableJobShareGoal = data?.getMyRewardsUserAvailableGoals.find(
      (goal) => goal.type === GoalType.ShareJob
    );

    // take the points that the user can earn from an available job share goal,
    // or via legacy referral share points
    const points = sum(
      compact([
        availableJobShareGoal?.numPoints || 0,
        rewardFromLegacyStructure?.__typename ===
          "ReferralRewardStructurePointReward" &&
        data &&
        data.countMyJobSharesThisMonth.numShares <
          (legacyStructure?.maxAwardableJobSharesPerMonth ?? 0)
          ? rewardFromLegacyStructure.pointValue
          : 0,
      ])
    );

    if (points) {
      return formatMessage(
        {
          defaultMessage:
            "Earn <bolded>{points} points</bolded> for sharing this job 🔥",
          description: "Job details page > share footer > earn points",
        },
        {
          bolded: (nodes) => (
            <strong
              css={(appTheme: AppTheme) =>
                css`
                  color: ${appTheme.palette.primary.main};
                `
              }
            >
              {nodes}
            </strong>
          ),
          points,
        }
      );
    }

    if (
      rewardFromLegacyStructure?.__typename ===
        "ReferralRewardStructureManualMonthlyRaffleReward" ||
      rewardFromLegacyStructure?.__typename ===
        "ReferralRewardStructureDrawTicketReward"
    ) {
      return formatMessage(
        {
          defaultMessage:
            "Earn <bolded>{num_raffle_tickets, plural, one {a} other {{num_raffle_tickets}}} draw {num_raffle_tickets, plural, one {ticket} other {tickets}}</bolded> for sharing this job",
          description: "Job details page > share footer > earn draw ticket",
        },
        {
          num_raffle_tickets: rewardFromLegacyStructure.numTickets,
          bolded: (nodes) => (
            <strong
              css={(appTheme: AppTheme) =>
                css`
                  color: ${appTheme.palette.primary.main};
                `
              }
            >
              {nodes}
            </strong>
          ),
        }
      );
    }
  }, [data, formatMessage]);

  if (error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Job details page > load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (data?.getJobPostingById === null) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Job details page > job posting closed",
          defaultMessage: "This job posting is no longer available.",
        })}
      />
    );
  }

  if (loading && !data) {
    return <PageLoadingState />;
  }

  const isWhiteLabelled = !!data?.getMyRewardsOrganization.whiteLabelConfig?.id;

  const jobDetails = [
    {
      description: formatMessage({
        description: "Job details page > rate label",
        defaultMessage: "Rate",
      }),
      value: data?.getJobPostingById?.ratesOfPay,
      icon: <DollarIcon />,
    },
    {
      description: formatMessage({
        description: "Job details page > Work type label",
        defaultMessage: "Work type",
      }),
      value: data?.getJobPostingById?.hoursRequired,
      icon: <BriefcaseIcon />,
    },
    {
      description: formatMessage({
        description: "Job details page > Location label",
        defaultMessage: "Location",
      }),
      value: data?.getJobPostingById?.geography,
      icon: <LocationIcon />,
    },
  ];

  return (
    <>
      <SubPageHeader
        pageName={data!.getJobPostingById!.title}
        analyticsPageName="Jobs Details"
        backTo="share_jobs"
      />

      <PageContentContainer
        css={css`
          padding-bottom: ${footerHeight}px;
        `}
      >
        <ScrollToTopOnMount />
        <Card
          onClick={() => setIsRewardStructureDialogOpen(true)}
          css={(appTheme: AppTheme) => css`
            background-color: ${isWhiteLabelled
              ? appTheme.palette.primary.main
              : appTheme.palette.tertiary.main};
            padding: ${appTheme.spacing(2)};
            color: ${appTheme.palette.primary.contrastText};
            margin-bottom: ${appTheme.spacing(2.5)};
            cursor: pointer;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div>
              <Typography variant="subtitle">
                {formatMessage({
                  defaultMessage: "Earn up to per referral",
                  description: "Job details page > earn points banner title",
                })}
              </Typography>
              <div
                css={(appTheme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  margin-top: ${appTheme.spacing(0.25)};
                `}
              >
                <PointsStarIcon height={16} width={16} color="gold" />
                <Typography
                  variant="body"
                  css={(appTheme: AppTheme) => css`
                    margin-left: ${appTheme.spacing(0.75)};
                  `}
                >
                  {formatMessage(
                    {
                      defaultMessage: "{max_points, number} ({max_dollars})",
                      description:
                        "Job details page > earn points banner subtitle",
                    },
                    {
                      max_points:
                        data?.getMyRewardsOrganization
                          .maxPointsEarnedPerCandidate,
                      max_dollars: formatDollars(
                        (data?.getMyRewardsOrganization
                          .maxPointsEarnedPerCandidate || 0) /
                          (data?.getMyRewardsOrganization.pointsPerDollar || 0)
                      ),
                    }
                  )}
                </Typography>
              </div>
            </div>
            <Button
              label={formatMessage({
                defaultMessage: "View details",
                description:
                  "Job details page > earn points view details button",
              })}
              size="small"
              width="auto"
              variant="outlined"
              css={(appTheme: AppTheme) => css`
                color: ${appTheme.palette.primary.contrastText};
              `}
              onClick={() => {
                setIsRewardStructureDialogOpen(true);
                track(
                  "Clicked job details page view referral structure details button",
                  { jobPostingId: job_id }
                );
              }}
              noWrap
            />
          </div>
        </Card>
        <Card
          css={(appTheme: AppTheme) => css`
            padding: ${appTheme.spacing(2)};
            margin-bottom: ${appTheme.spacing(2.5)};
            display: flex;
            flex-direction: column;
            row-gap: ${appTheme.spacing(2)};
            ${appTheme.breakpoints.up("md")} {
              flex-direction: row;
              column-gap: ${appTheme.spacing(6.25)};
            }
          `}
        >
          {jobDetails.map(({ description, value, icon }) =>
            value ? (
              <div
                css={(appTheme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  ${appTheme.breakpoints.up("md")} {
                    flex-direction: column;
                    align-items: start;
                    row-gap: ${appTheme.spacing(0.25)};
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <div
                    css={css`
                      width: 20px;
                      display: flex;
                      align-items: center;
                    `}
                  >
                    {icon}
                  </div>

                  <Typography
                    color="textPrimary"
                    css={(appTheme: AppTheme) => css`
                      margin-left: ${appTheme.spacing(1)};
                    `}
                    variant="subtitle"
                  >
                    {description}
                  </Typography>
                </div>
                <Typography variant="body" color="textPrimary">
                  {value}
                </Typography>
              </div>
            ) : null
          )}
        </Card>
        <Card
          css={(appTheme: AppTheme) => css`
            padding: ${appTheme.spacing(2)};
            margin-bottom: ${appTheme.spacing(2.5)};
          `}
        >
          <Typography
            variant="subtitle"
            color="textPrimary"
            css={(appTheme: AppTheme) => css`
              margin-bottom: ${appTheme.spacing(1.25)};
            `}
          >
            {formatMessage({
              defaultMessage: "Description",
              description: "Job details page > description header",
            })}
          </Typography>
          <Typography variant="body" color="textPrimary">
            <span
              dangerouslySetInnerHTML={{
                __html: data!.getJobPostingById!.descriptionHTML ?? "",
              }}
            />
          </Typography>
        </Card>

        <ShareDialog
          jobPostingId={data!.getJobPostingById!.id}
          promotionId={data!.getJobPostingById!.activePromotion?.id ?? null}
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen((prev) => !prev)}
        />
        <RewardStructureDialog
          open={isRewardStructureDialogOpen}
          onClose={() => setIsRewardStructureDialogOpen(false)}
          referralRewardStructureItems={
            data!.getMyRewardsOrganization.referralRewardStructure.items
          }
          pointsPerDollar={data!.getMyRewardsOrganization.pointsPerDollar}
          maxPointsEarnedPerCandidate={
            data!.getMyRewardsOrganization.maxPointsEarnedPerCandidate
          }
        />
      </PageContentContainer>
      <div
        ref={footerRef}
        css={(appTheme: AppTheme) => css`
          position: fixed;
          bottom: 0;
          width: 100%;
          background-color: white;
          box-shadow: 0px -4.5px 7px rgba(0, 0, 0, 0.05);
        `}
      >
        <PageContentContainer
          css={(appTheme: AppTheme) =>
            css`
              padding-top: ${appTheme.spacing(2)};
            `
          }
        >
          <Typography
            variant="subtitle"
            css={(appTheme: AppTheme) => css`
              text-align: center;
              margin-bottom: ${appTheme.spacing(2)};
            `}
            color="textPrimary"
          >
            {buttonText}
          </Typography>
          <Button
            cypressId="view-details-share-job-button"
            label={formatMessage({
              description: "Job details page > share job button label",
              defaultMessage: "Share Job",
            })}
            color={isWhiteLabelled ? "primary" : "secondary"}
            onClick={() => {
              setShareDialogOpen(true);
              track("Pressed Share Job", {
                jobId: job_id,
                context: "job_details_page",
                promotionId:
                  data?.getJobPostingById?.activePromotion?.id ?? null,
              });
            }}
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          />
        </PageContentContainer>
      </div>
    </>
  );
}
