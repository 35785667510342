/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";

import { ShareDialog } from "@rewards-web/rewards-app/src/shared/share-dialog";
import { Button } from "@rewards-web/shared/components/button";
import {
  Card,
  CardActions,
  CardContent,
} from "@rewards-web/shared/components/card";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { ListShareableJobPostingsFilters } from "@rewards-web/shared/graphql-types";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PointsStarIcon } from "../../../../../../shared/points-star-icon";
import { BriefcaseIcon } from "../../../../job-details/icons/briefcase-icon";
import { DollarIcon } from "../../../../job-details/icons/dollar-icon";
import { LocationIcon } from "../../../../job-details/icons/location-icon";
import { JobPromotionBanner } from "./job-promotion-banner";

export interface JobCardProps {
  /**
   * If no data is passed, a skeleton (loading) view will be displayed
   */
  data?: {
    id: string;
    jobTitle: string;
    ratesOfPay: string | null | undefined;
    geography: string;
    hoursRequired: string;
    branchName: string | null | undefined;
    organizationId: string | null | undefined;
    activePromotion: null | {
      id: string;
      bannerMessage?: string | null | undefined;
    };
    maxPointsEarnedPerCandidate: number;
    pointsPerDollar: number;
  };
  /**
   * The breakpoint at which the buttons should be stacked
   * (instead of side by side)
   */
  stackedButtons: boolean;

  numJobsMatchingFilters: number | undefined;
  selectedFilters: ListShareableJobPostingsFilters;
}

const singleLineTextWithEllipses = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function JobCard({
  data,
  stackedButtons,
  numJobsMatchingFilters,
  selectedFilters,
}: JobCardProps): JSX.Element {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const jobCardRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(jobCardRef, { threshold: 0.5 });

  useEffect(() => {
    if (onScreen && data) {
      track("Viewed job card", {
        jobId: data?.id,
        promotionId: data?.activePromotion?.id ?? undefined,
        branchName: data.branchName ?? undefined,
        organizationId: data.organizationId,
      });
    }
  }, [track, onScreen, data]);

  const mobileScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Card
      ref={jobCardRef}
      variant={mobileScreen ? "outlined" : "elevation"}
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <CardContent
        css={css`
          padding-bottom: 0;
        `}
      >
        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: ${appTheme.spacing(0.75)};
            width: 100%;
          `}
        >
          <Typography
            data-cy="job-card-title"
            variant="subtitle"
            css={css`
              ${singleLineTextWithEllipses}
              width: 100%;
            `}
          >
            {data ? data.jobTitle : <Skeleton width={"50%"} />}
          </Typography>
          {data?.activePromotion && (
            <JobPromotionBanner
              css={(theme: AppTheme) => css`
                margin-right: -16px;
                margin-left: ${theme.spacing(1)};
                max-width: 50%;
              `}
              message={data.activePromotion.bannerMessage ?? "Promoted"}
            />
          )}
        </div>
        <div
          css={(appTheme: AppTheme) => css`
            display: grid;
            column-gap: ${appTheme.spacing(3.75)};
            row-gap: ${appTheme.spacing(0.75)};
            grid-template-columns: 1fr 1fr;
          `}
        >
          {(!data || data.ratesOfPay) && (
            <div
              css={css`
                display: flex;
                min-width: 0;
              `}
            >
              <div
                css={css`
                  width: 24px;
                  flex-shrink: 0;
                `}
              >
                <DollarIcon />
              </div>
              <Typography
                variant="footnote"
                color="textPrimary"
                css={css`
                  ${singleLineTextWithEllipses};
                  width: 100%;
                `}
              >
                {data ? data.ratesOfPay! : <Skeleton width={"75px"} />}
              </Typography>
            </div>
          )}
          <div
            css={css`
              display: flex;
              min-width: 0;
            `}
          >
            <div
              css={css`
                width: 24px;
                flex-shrink: 0;
              `}
            >
              <BriefcaseIcon />
            </div>
            <Typography
              variant="footnote"
              color="textPrimary"
              css={css`
                ${singleLineTextWithEllipses}
              `}
            >
              {data ? data.hoursRequired : <Skeleton width={"75px"} />}
            </Typography>
          </div>
          <div
            css={css`
              display: flex;
              min-width: 0;
            `}
          >
            <div
              css={css`
                width: 24px;
                flex-shrink: 0;
              `}
            >
              <LocationIcon />
            </div>
            <Typography
              variant="footnote"
              color="textPrimary"
              css={css`
                ${singleLineTextWithEllipses}
              `}
            >
              {data ? data.geography : <Skeleton width={"75px"} />}
            </Typography>
          </div>

          <div
            css={css`
              display: flex;
              min-width: 0;
            `}
          >
            <div
              css={css`
                width: 24px;
                display: flex;
                align-items: center;
              `}
            >
              <PointsStarIcon color="gold" height={16} width={16} />
            </div>
            <Typography
              variant="footnote"
              color="textPrimary"
              css={css`
                ${singleLineTextWithEllipses}
              `}
            >
              {data ? (
                formatMessage(
                  {
                    description: "Share job card > earn up to",
                    defaultMessage: "Earn up to {max_dollars}",
                  },
                  {
                    max_dollars: formatDollars(
                      data.maxPointsEarnedPerCandidate / data.pointsPerDollar
                    ),
                  }
                )
              ) : (
                <Skeleton width={"75px"} />
              )}
            </Typography>
          </div>
        </div>
      </CardContent>
      <CardActions
        css={(theme: AppTheme) => css`
          ${stackedButtons &&
          css`
            display: flex;
            flex-direction: column;
            > :not(:first-child) {
              margin-left: 0;
              margin-top: ${theme.spacing(1)};
            }
          `}
        `}
      >
        {data ? (
          <>
            <Button
              cypressId="view-details-button"
              label={formatMessage({
                description: "Share job card > view details button",
                defaultMessage: "View Details",
              })}
              variant="outlined"
              linkTo={"/jobs/" + data.id}
              css={css`
                ${singleLineTextWithEllipses}
              `}
              typographyVariant="buttonSmall"
              onClick={() => {
                track("Pressed job View Details", {
                  jobId: data.id,
                  context: "job_card",
                  promotionId: data?.activePromotion?.id ?? null,
                });
              }}
            />
            <Button
              cypressId="share-job-button"
              label={formatMessage({
                description: "Share job card > share job button",
                defaultMessage: "Share Job",
              })}
              color="secondary"
              onClick={() => {
                setShareDialogOpen(true);
                track("Pressed Share Job", {
                  jobId: data.id,
                  context: "job_card",
                  promotionId: data?.activePromotion?.id ?? null,
                });
              }}
              typographyVariant="buttonSmall"
              css={css`
                ${singleLineTextWithEllipses}
              `}
            />
            <ShareDialog
              jobPostingId={data.id}
              promotionId={data.activePromotion?.id ?? null}
              numJobsMatchingFilters={numJobsMatchingFilters}
              selectedFilters={selectedFilters}
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen((prev) => !prev)}
            />
          </>
        ) : (
          <Skeleton width="100%" height={stackedButtons ? 90 : 48} />
        )}
      </CardActions>
    </Card>
  );
}
