/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { JobFilterBadge } from "../job-filter-badge";
import { JobFilterIcon } from "./job-filter-icon";

export interface JobFilterButtonProps {
  onClick(): void;
  numSelectedFilters: number;
}

export function JobFilterButton({
  onClick,
  numSelectedFilters,
}: JobFilterButtonProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();

  return (
    <Button
      width="auto"
      variant="text"
      typographyVariant="body"
      label={formatMessage({
        defaultMessage: "Filter",
        description: "Jobs page > open jobs filter button",
      })}
      endIcon={
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <JobFilterIcon stroke={theme.palette.text.primary} />
          {numSelectedFilters > 0 && (
            <JobFilterBadge
              numSelectedFilters={numSelectedFilters}
              css={(theme: AppTheme) => css`
                margin-left: ${theme.spacing(1)};
              `}
            />
          )}
        </div>
      }
      css={css`
        font-size: 1.2em;
        font-weight: 700;
      `}
      onClick={onClick}
    />
  );
}
