/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import copyTextToClipboard from "copy-to-clipboard";
import { compact, sum } from "lodash";
import { useEffect, useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  GoalType,
  ListShareableJobPostingsFilters,
  SharePlatform,
} from "@rewards-web/shared/graphql-types";
import { useTrackScreenRecordingEvent } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import CoinPointsIcon from "../coin-points.png";
import TicketIcon from "../ticket-icon.png";
import { useJobShareLink } from "./hooks";
import { useJobShareRewardStructureQuery } from "./job-share-reward-structure.generated";
import { generateSMSShareUrl } from "./lib";
import { ShareButton } from "./share-button";
// import MessengerIcon from "./social-media-icons/messenger.png";
// import LinkedInIcon from "./social-media-icons/linkedin.png";
// import TwitterIcon from "./social-media-icons/twitter.png";
import WhatsAppIcon from "./social-media-icons/whatsapp.png";

export interface ShareDialogProps {
  jobPostingId: string;
  promotionId: string | null;
  open: boolean;
  onClose(): void;
  numJobsMatchingFilters?: number | undefined;
  selectedFilters?: ListShareableJobPostingsFilters;
}

export function ShareDialog({
  onClose,
  jobPostingId,
  promotionId,
  open,
  numJobsMatchingFilters,
  selectedFilters,
}: ShareDialogProps): JSX.Element {
  const { formatMessage } = useFormatters();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const [
    { data: jobShareUrl, error: jobShareUrlError },
    { markJobLinkShared },
  ] = useJobShareLink(open, jobPostingId, {
    promotionId,
    numJobsMatchingFilters,
    selectedFilters,
  });

  const {
    data: jobShareRewardStructure,
    loading: jobShareRewardStructureLoading,
  } = useJobShareRewardStructureQuery({ onError: reportError });

  const [copied, setCopied] = useState(false);
  const mobileScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    setCopied(false);
  }, [open, setCopied]);

  useEffect(() => {
    if (open) {
      trackScreenRecordingEvent("opened_share_dialog");
    }
  }, [open, trackScreenRecordingEvent]);

  const shareMessage = formatMessage({
    description: "Job share dialog > share message",
    defaultMessage:
      "I think you'd be a great fit! Check out this link for a job at my company.",
  });

  const availableJobShareGoal = jobShareRewardStructure?.getMyRewardsUserAvailableGoals.find(
    (goal) => goal.type === GoalType.ShareJob
  );

  const rewardText = ((): {
    iconSrc: string;
    alt: string;
    text: string;
  } | null => {
    const rewardFromLegacyStructure =
      jobShareRewardStructure?.getMyRewardsOrganization.referralRewardStructure
        .jobShareStructure.reward;

    // take the points that the user can earn from an available job share goal,
    // or via legacy referral share points
    const points = sum(
      compact([
        availableJobShareGoal?.numPoints || 0,
        rewardFromLegacyStructure?.__typename ===
        "ReferralRewardStructurePointReward"
          ? rewardFromLegacyStructure.pointValue
          : 0,
      ])
    );

    if (points) {
      return {
        iconSrc: CoinPointsIcon,
        alt: formatMessage({
          defaultMessage: "coin icon",
          description: "Job share dialog > coin icon alt",
        }),
        text: formatMessage(
          {
            defaultMessage: "{points} points per share",
            description: "Rewards page > Share jobs card > points per share",
          },
          { points }
        ),
      };
    }

    if (
      rewardFromLegacyStructure?.__typename ===
        "ReferralRewardStructureManualMonthlyRaffleReward" ||
      rewardFromLegacyStructure?.__typename ===
        "ReferralRewardStructureDrawTicketReward"
    ) {
      return {
        iconSrc: TicketIcon,
        alt: formatMessage({
          defaultMessage: "ticket icon",
          description: "Job share dialog > ticket icon alt",
        }),
        text: formatMessage(
          {
            defaultMessage:
              "{tickets} {tickets, plural, one {ticket} other {tickets}} per share",
            description: "Rewards page > Share jobs card > 1 ticket per share",
          },
          { tickets: rewardFromLegacyStructure.numTickets }
        ),
      };
    }

    return null;
  })();

  const generateShareMessageUsingLink = (shareLink: string) =>
    `${shareMessage} ${shareLink}`;

  const socialmediaButton = (item: {
    title: string;
    onClick(): void;
    image: string;
  }) => {
    return (
      <GridListTile key={item.title}>
        <button
          onClick={item.onClick}
          disabled={!jobShareUrl}
          css={css`
            text-decoration: none;
            background: none;
            padding: none;
            border: none;
            cursor: pointer;
            opacity: ${jobShareUrl ? 1 : 0.3};
          `}
        >
          <img
            src={item.image}
            alt={item.title}
            css={css`
              border-radius: 5px;
              width: 54px;
              height: 54px;
              display: block;
              margin: auto;
            `}
          />
          <Typography
            variant="footnote"
            css={css`
              display: inline-block;
              width: 100%;
              text-align: center;
              color: #000;
            `}
          >
            {item.title}
          </Typography>
        </button>
      </GridListTile>
    );
  };

  const socialMedia: Array<{
    title: string;
    onClick(): void;
    image: string;
  }> = [
    {
      title: formatMessage({
        description: "Job share dialog > whatsapp share button label",
        defaultMessage: "WhatsApp",
      }),
      onClick: () => {
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(
            generateShareMessageUsingLink(jobShareUrl!)
          )}`,
          "_blank"
        );

        markJobLinkShared(SharePlatform.Whatsapp);
      },
      image: WhatsAppIcon,
    },
  ];

  const content = (() => {
    if (jobShareUrlError) {
      return (
        <Alert
          severity="error"
          message={formatMessage({
            description: "Job share dialog > data load error",
            defaultMessage:
              "An unexpected error occurred. Please try again later.",
          })}
        />
      );
    }

    return (
      <>
        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(2.5)};
            display: flex;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <button
            css={(theme: AppTheme) => css`
              border-radius: 50%;
              border-width: 0px;
              height: 27px;
              width: 27px;
              background-color: #e0e0e0;
              padding: 0;
              cursor: pointer;
            `}
            onClick={onClose}
          >
            <CloseOutlinedIcon
              css={(theme: AppTheme) => css`
                padding-top: 3px;
                font-size: 1.6em;
                color: #757575;
              `}
            />
          </button>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          {jobShareRewardStructureLoading ? (
            <Skeleton width="50%" />
          ) : (
            rewardText && (
              <Typography>
                <img
                  src={rewardText.iconSrc}
                  alt={rewardText.alt}
                  css={(appTheme: AppTheme) => css`
                    width: 16px;
                    margin-right: ${appTheme.spacing(0.75)};
                  `}
                />
                {rewardText.text}
              </Typography>
            )
          )}
          <Typography
            css={(appTheme: AppTheme) =>
              css`
                margin-top: ${appTheme.spacing(0.75)};
                padding: ${appTheme.spacing(2)};
                text-align: center;
              `
            }
            variant="h5"
          >
            {formatMessage({
              defaultMessage: "Refer someone to this job:",
              description: "Job share dialog > title",
            })}
          </Typography>
        </div>

        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            overflow: hidden;
            margin: ${appTheme.spacing(4)} 0px;
          `}
        >
          <GridList
            cols={socialMedia.length}
            spacing={30}
            cellHeight="auto"
            css={css`
              flex-wrap: nowrap;
              transform: translate(0);
              padding: 10px;
            `}
          >
            {socialMedia.map((item) => (
              <GridListTile
                key={item.title}
                css={css`
                  width: 0px;
                `}
              >
                {socialmediaButton(item)}
              </GridListTile>
            ))}
          </GridList>
        </div>

        {mobileScreen && (
          <ShareButton
            onClick={() => {
              window.open(
                generateSMSShareUrl(generateShareMessageUsingLink(jobShareUrl!))
              );
              markJobLinkShared(SharePlatform.Sms);
            }}
            label={formatMessage({
              description: "Job share dialog > text message share button label",
              defaultMessage: "Text message",
            })}
            type="message"
            disabled={!jobShareUrl}
          />
        )}

        <ShareButton
          cypressId="share-email-button"
          onClick={() => {
            const subject = formatMessage({
              description: "Job share dialog > email share subject",
              defaultMessage: "Job opportunity",
            });
            window.open(
              `mailto:?Subject=${encodeURIComponent(subject)}&Body=` +
                encodeURIComponent(generateShareMessageUsingLink(jobShareUrl!))
            );
            markJobLinkShared(SharePlatform.Email);
          }}
          label={formatMessage({
            description: "Job share dialog > email share button label",
            defaultMessage: "Email",
          })}
          type="email"
          disabled={!jobShareUrl}
        />
        <ShareButton
          cypressId="copy-share-invitation-button"
          onClick={() => {
            copyTextToClipboard(generateShareMessageUsingLink(jobShareUrl!));
            setCopied(true);
            markJobLinkShared(SharePlatform.CopiedLink);
          }}
          label={
            copied
              ? formatMessage({
                  description:
                    "Job share dialog > copy share button label success",
                  defaultMessage: "Invitation copied to clipboard",
                })
              : formatMessage({
                  description:
                    "Job share dialog > copy share share button label",
                  defaultMessage: "Copy Invitation",
                })
          }
          type={copied ? "copied" : "copy"}
          disabled={!jobShareUrl}
        />
      </>
    );
  })();

  return (
    <>
      <ResponsiveDialog
        backgroundColor="white"
        cypressId="share-dialog"
        open={open}
        onClose={onClose}
      >
        {content}
      </ResponsiveDialog>
    </>
  );
}
