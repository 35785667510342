/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

import HelpIcon from "../help-icon";

interface PersonalInfoReasonModalProps {
  open: boolean;
  onClose: () => void;
}

export function PersonalInfoReasonModal({
  open,
  onClose,
}: PersonalInfoReasonModalProps): JSX.Element {
  const { formatMessage } = useFormatters();
  const theme = useTheme();

  return (
    <Modal width="365px" open={open} onClose={onClose}>
      <HelpIcon
        fill={theme.palette.tertiary.main}
        css={(theme: AppTheme) =>
          css`
            align-self: center;
            margin-bottom: ${theme.spacing(1)};
            margin-top: ${theme.spacing(2.5)};
          `
        }
      />
      <ModalTitle
        fontSize="2em"
        css={css`
          font-family: ${headerFontFamily};
          text-align: center;
        `}
      >
        {formatMessage({
          description: "Opt in page > personal info reason modal > title",
          defaultMessage: "Why do we ask for personal information?",
        })}
      </ModalTitle>
      <ModalContent>
        <Typography
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
              font-family: ${headerFontFamily};
              font-size: 1.2em;
            `
          }
        >
          {formatMessage({
            description: "Opt in page > personal info reason modal > content",
            defaultMessage:
              "Signing up with your personal information allows easy access to your rewards! Your info is not shared with anyone and you can opt-out of receiving messages at any time.",
          })}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          size="large"
          color="primary"
          label={formatMessage({
            description:
              "Opt in page > personal info reason modal > dismiss button",
            defaultMessage: "Okay",
          })}
          onClick={onClose}
          css={css`
            font-family: ${headerFontFamily};
          `}
        />
      </ModalActions>
    </Modal>
  );
}
