/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { compact } from "lodash";
import { useEffect } from "react";

import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrackScreenRecordingEvent } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { useSelectedTenantId } from "../../../../shared/modules/tenant-selector";
import { RadioCard } from "./radio-card";
import { useSwitchAgenciesDataQuery } from "./switch-agency-data.generated";

export interface SwitchAgencyDialogProps {
  rewardsUserRoleIdsByOrganizationIds: Map<string, string>;
  rewardsAdminRoleIdsByOrganizationIds: Map<string, string>;
  open: boolean;
  onClose(): void;
}

export function SwitchAgencyDialog({
  rewardsUserRoleIdsByOrganizationIds: organizationIdUserRoleMap,
  rewardsAdminRoleIdsByOrganizationIds: organizationIdAdminRoleMap,
  onClose,
  open,
}: SwitchAgencyDialogProps): JSX.Element | null {
  const { formatMessage } = useFormatters();
  const theme = useTheme();
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();

  const organizationIds = Array.from(organizationIdUserRoleMap.keys());

  const { data } = useSwitchAgenciesDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    variables: {
      organizationIds,
    },
  });
  const { setSelectedTenantId } = useSelectedTenantId();

  useEffect(() => {
    if (open) {
      trackScreenRecordingEvent("opened_switch_agency_dialog");
    }
  }, [open, trackScreenRecordingEvent]);

  if (!data) {
    return null;
  }

  const myOrganizations = compact(data.getRewardsOrganizationsByIds);
  const currentOrganizationId = data.myOrganization.id;

  const getPrimaryColorForOrganization = (
    organization: typeof myOrganizations[number]
  ) =>
    organization.whiteLabelConfig?.primaryColor ??
    organization.themePrimaryColor ??
    theme.palette.primary.main;
  return (
    <ResponsiveDialog
      title={formatMessage({
        description: "Switch Agency dialog > title",
        defaultMessage: "Your Accounts",
      })}
      open={open}
      onClose={onClose}
      maxWidth="400px"
      paddingBottom={0}
      backgroundColor={theme.palette.background.paper}
    >
      <div
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(1.5)};
        `}
      >
        <Typography align="center" variant="body">
          {formatMessage({
            description: "Switch Agency dialog > description",
            defaultMessage:
              "Here are the agencies you belong to. Click on the agency to view your points and rewards.",
          })}
        </Typography>

        <div
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(1)};
          `}
        >
          {myOrganizations.map((organization) => (
            <RadioCard
              key={organization.id}
              label={organization.shortName}
              selected={organization.id === currentOrganizationId}
              primaryColor={getPrimaryColorForOrganization(organization)}
              onClick={() => {
                setSelectedTenantId(organization.id);
                onClose();
              }}
              subLabel={
                organizationIdAdminRoleMap.has(organization.id)
                  ? formatMessage({
                      description:
                        "Switch Agency dialog > admin account available",
                      defaultMessage: "Admin Account",
                    })
                  : undefined
              }
            ></RadioCard>
          ))}
        </div>
      </div>
    </ResponsiveDialog>
  );
}
