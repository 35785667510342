import { useFormatters } from "..";

interface FormattedNumberProps extends Intl.DateTimeFormatOptions {
  value: number;
}

/**
 * Formats a number using Intl APIs, using the currently-selected locale.
 */
export function FormattedNumber({ value, ...options }: FormattedNumberProps) {
  const { formatNumber } = useFormatters();
  return <>{formatNumber(value, options)}</>;
}
