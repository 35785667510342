import { useEffect, useState } from "react";

/**
 * Given a number value, will return a new number value
 * that animates "counting up".
 * - Initially, the number starts at 0 and animates up to the `value`
 * - If `value` changes, it will animate to the new number
 * - It does not animate if the number decreases in value
 */
export function useCountUp(
  value: number | undefined,
  opts: {
    allowCountDown?: boolean;
    durationMs?: number;
  } = {}
) {
  const { durationMs = 500, allowCountDown = false } = opts;

  const [startNumber, setStartNumber] = useState(0);
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    if (value === undefined) {
      return;
    }

    const difference = value - startNumber;
    if (startNumber === value) {
      return;
    }

    if (difference < 0 && !allowCountDown) {
      // value has decreased - it should not animate up.
      // just set display value to the current value

      setDisplayValue(value);
      setStartNumber(value);
      return;
    }

    let animationStartTime: number;
    const animateCount = (time: number) => {
      if (!animationStartTime) animationStartTime = time;
      const elapsedTime = time - animationStartTime;
      const progress = elapsedTime / durationMs;

      if (progress < 1) {
        const currentCount = startNumber + Math.round(difference * progress);
        setDisplayValue(currentCount);
        requestAnimationFrame(animateCount);
      } else {
        setDisplayValue(value);
        setStartNumber(value);
      }
    };
    requestAnimationFrame(animateCount);
  }, [value, startNumber, durationMs, allowCountDown]);

  return displayValue;
}
