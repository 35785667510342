/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  EvvRules,
  EvvRulesClockMethod,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";

import { getSchedulingSoftwareDetails } from "../../../../../shared/goal/goal-card/evv-streak-goal-card/lib";
import { useGoalRewardsUserDataQuery } from "../../../../../shared/goal/goal-card/goal-rewards-user.query.generated";
import { EVVTrackPageEVVRules } from "../evv-track-page-evv-rules";

export interface EVVTrackPageGoalDescriptionProps {
  evvRulesLoading: boolean;
  evvRules?: EvvRules;
}

/**
 * Interactive Voice Response (IVR) supports Attendant and Employer of Record (EOR) access to
 * Consumer Direct Care Network. (CDCN) transaction systems via landline telephone.
 */
const CLIENT_PHONE_EVV_METHOD = EvvRulesClockMethod.Ivr;

export function EVVTrackPageGoalDescription({
  evvRulesLoading,
  evvRules,
}: EVVTrackPageGoalDescriptionProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();
  const track = useTrack();
  const userDataQueryResult = useGoalRewardsUserDataQuery();

  const schedulingSoftwareDetails = getSchedulingSoftwareDetails(
    userDataQueryResult.data?.getMyRewardsUser.schedulingSoftware
  );

  const isClientPhoneEvvValid = (evvRules?.validClockMethods ?? []).includes(
    CLIENT_PHONE_EVV_METHOD
  )
    ? "YES"
    : "NO"; // For conditional translation matching

  const localizedTrackMessage = schedulingSoftwareDetails
    ? formatMessage(
        {
          description: "Goal track page > evv streak track description",
          defaultMessage:
            "Clock in and out on time using the {scheduling_software} app {client_phone_evv_valid, select, YES {or the client's phone} other {}} to achieve these goals. Shift data may take several days to reflect on this page.",
        },
        {
          scheduling_software: schedulingSoftwareDetails.appName,
          client_phone_evv_valid: isClientPhoneEvvValid,
        }
      )
    : formatMessage(
        {
          defaultMessage:
            "Clock in and out on time using the scheduling app {client_phone_evv_valid, select, YES {or the client's phone} other {}} to achieve these goals. Shift data may take several days to reflect on this page.",
          description: "Goal track page > no app evv streak track description",
        },
        {
          client_phone_evv_valid: isClientPhoneEvvValid,
        }
      );

  return (
    <Typography
      variant="body"
      css={css`
        margin-top: ${theme.spacing(3)};
      `}
    >
      {localizedTrackMessage}

      <EVVTrackPageEVVRules
        evvRulesLoading={evvRulesLoading}
        evvRules={evvRules}
      />

      <FormattedMessage
        description="Goal track page > evv streak track description > contact us"
        defaultMessage="Something doesn't look right? <mailto>Contact us</mailto>."
        values={{
          mailto: (nodes) => (
            <a
              href={`mailto:${encodeURI("help@caribou.care")}`}
              onClick={() => {
                track("Clicked evv goal track contact us link");
              }}
              css={css`
                color: ${theme.palette.primary.main};
              `}
            >
              {nodes}
            </a>
          ),
        }}
      />
    </Typography>
  );
}
