/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactEventHandler, useEffect, useRef, useState } from "react";

import { AppTheme } from "@rewards-web/shared/style/types";

import { FullscreenButton } from "../../../../../shared/video-player/fullscreen-button";
import { PlayButton } from "../../../../../shared/video-player/play-button";
import { ProgressBar } from "../../../../../shared/video-player/progress-bar";

export interface BlurredBackgroundVideoPlayerProps {
  url: string;
  onPause?: ReactEventHandler<HTMLVideoElement> | undefined;
  onPlay?: ReactEventHandler<HTMLVideoElement> | undefined;
  onEnded?: ReactEventHandler<HTMLVideoElement> | undefined;
  onOpenFullScreen?: ReactEventHandler<HTMLDivElement> | undefined;
}

export function BlurredBackgroundVideoPlayer({
  url,
  onPlay,
  onEnded,
  onPause,
  onOpenFullScreen,
}: BlurredBackgroundVideoPlayerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const blurredVideoRef = useRef<HTMLVideoElement>(null);
  const [videoOrientation, setVideoOrientation] = useState<
    "portrait" | "landscape" | undefined
  >();
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoDurationSeconds, setVideoDurationSeconds] = useState<number>();
  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0);

  const videoEndedCallbackInvoked = useRef(false);
  const handleVideoEnded: ReactEventHandler<HTMLVideoElement> = async (
    event
  ) => {
    // we've run into issues where onEnded is called thousands of times in a minute,
    // which puts a huge strain on the server and causes the API to become less responsive.
    // we haven't reproduced, but this is a precaution to avoid it from
    // happening again.
    if (videoEndedCallbackInvoked.current) {
      return; // this function was already invoked; let's not continue
    }
    if (onEnded) {
      onEnded(event);
    }
    videoEndedCallbackInvoked.current = true;
  };

  const playVideo: ReactEventHandler<HTMLVideoElement> = (e) => {
    if (onPlay) {
      onPlay(e);
    }
    if (videoRef.current && blurredVideoRef.current) {
      blurredVideoRef.current.currentTime = videoRef.current.currentTime;
      videoRef.current.play();
      blurredVideoRef.current.play();
    }
  };

  const pauseVideo: ReactEventHandler<HTMLVideoElement> = (e) => {
    if (onPause) {
      onPause(e);
    }

    if (videoRef.current) {
      videoRef.current.pause();
    }
    if (blurredVideoRef.current) {
      blurredVideoRef.current.pause();
    }
  };

  const restartVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
    if (blurredVideoRef.current) {
      blurredVideoRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    restartVideo();
    blurredVideoRef.current?.play();
  }, []);

  const DEFAULT_VIDEO_PROPS: React.VideoHTMLAttributes<HTMLVideoElement> = {
    playsInline: true,
    onTimeUpdate: (e: React.SyntheticEvent<HTMLVideoElement>) => {
      if (videoRef.current && !videoRef.current.seeking) {
        setCurrentTimeSeconds(videoRef.current.currentTime);
      }
    },
    src: url,
  };

  const isReady = Boolean(videoOrientation);

  return (
    <div
      css={css`
        z-index: 1000;
        display: flex;
        flex-direction: column;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          ref={ref}
          css={(appTheme: AppTheme) => css`
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 10px;
            border: 4px solid white;
            aspect-ratio: 16 / 9;
            background-color: ${appTheme.palette.grey[400]};
            z-index: 0;
          `}
        >
          <video
            {...DEFAULT_VIDEO_PROPS}
            ref={videoRef}
            onClick={(e) => {
              if (isPlaying) {
                pauseVideo(e);
              } else {
                playVideo(e);
              }
            }}
            onEnded={handleVideoEnded}
            disablePictureInPicture
            css={css`
              display: ${isReady ? "flex" : "none"};
              height: 100%;
              object-fit: "contain";
              ${videoOrientation === "landscape" &&
              css`
                width: 100%;
              `}
            `}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onLoadedMetadata={() => {
              setVideoOrientation(
                videoRef.current?.videoHeight! > videoRef.current?.videoWidth!
                  ? "portrait"
                  : "landscape"
              );
              if (videoRef.current?.duration) {
                const videoDuration = videoRef.current.duration;
                setVideoDurationSeconds(videoDuration);
              }
            }}
          />
          <video
            {...DEFAULT_VIDEO_PROPS}
            ref={blurredVideoRef}
            muted
            css={css`
              display: ${isReady ? "flex" : "none"};
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              width: 100%;
              transform: translateY(-50%);
              filter: blur(20px);
            `}
          />
          {!isPlaying && isReady && <PlayButton />}
          {isReady && (
            <>
              <FullscreenButton
                onOpenFullscreen={onOpenFullScreen}
                videoRef={videoRef}
              />
              <ProgressBar
                currentTimeSeconds={currentTimeSeconds}
                isPlaying={isPlaying}
                videoDurationSeconds={videoDurationSeconds}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
