import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type VideoOfferPageDataQueryVariables = Types.Exact<{
  videoOfferId: Types.Scalars['ID'];
}>;


export type VideoOfferPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'firstName'>
  ), getRewardVideoOfferById?: Types.Maybe<(
    { __typename?: 'RewardVideoOffer' }
    & Pick<Types.RewardVideoOffer, 'id' | 'pointValue' | 'watched' | 'deleted'>
    & { video: (
      { __typename?: 'RewardVideo' }
      & Pick<Types.RewardVideo, 'id' | 'title' | 'url'>
      & { file: (
        { __typename: 'RewardVideoUrlFile' }
        & Pick<Types.RewardVideoUrlFile, 'id' | 'url'>
      ) | { __typename: 'RewardVideoManagedFile' } }
    ) }
  )> }
);


export const VideoOfferPageDataDocument = gql`
    query VideoOfferPageData($videoOfferId: ID!) {
  getMyRewardsUser {
    id
    firstName
  }
  getRewardVideoOfferById(videoOfferId: $videoOfferId) {
    id
    pointValue
    watched
    deleted
    video {
      id
      title
      url
      file {
        __typename
        ... on RewardVideoUrlFile {
          id
          url
        }
      }
    }
  }
}
    `;

/**
 * __useVideoOfferPageDataQuery__
 *
 * To run a query within a React component, call `useVideoOfferPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoOfferPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoOfferPageDataQuery({
 *   variables: {
 *      videoOfferId: // value for 'videoOfferId'
 *   },
 * });
 */
export function useVideoOfferPageDataQuery(baseOptions: Apollo.QueryHookOptions<VideoOfferPageDataQuery, VideoOfferPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoOfferPageDataQuery, VideoOfferPageDataQueryVariables>(VideoOfferPageDataDocument, options);
      }
export function useVideoOfferPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoOfferPageDataQuery, VideoOfferPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoOfferPageDataQuery, VideoOfferPageDataQueryVariables>(VideoOfferPageDataDocument, options);
        }
export type VideoOfferPageDataQueryHookResult = ReturnType<typeof useVideoOfferPageDataQuery>;
export type VideoOfferPageDataLazyQueryHookResult = ReturnType<typeof useVideoOfferPageDataLazyQuery>;
export type VideoOfferPageDataQueryResult = Apollo.QueryResult<VideoOfferPageDataQuery, VideoOfferPageDataQueryVariables>;