import {
  CandidateRecruitmentStepName,
  ReferralRewardStructureReferralRecruitmentItem,
  ReferralRewardStructureReferralRetentionItemDuration,
  ReferralRewardStructureReferralRetentionItemDurationUnit,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";

const STEP_NAME_MAP: Record<CandidateRecruitmentStepName, JSX.Element> = {
  APPLICATION_SUBMITTED: (
    <FormattedMessage
      description="Referral page > Referral card > Application submitted step upcoming text"
      defaultMessage="Application Submission"
    />
  ),
  CONTACTED: (
    <FormattedMessage
      description="Referral page > Referral card > Contacted step upcoming text"
      defaultMessage="Contact Referral"
    />
  ),
  INTERVIEW_SCHEDULED: (
    <FormattedMessage
      description="Referral page > Referral card > Interview scheduled step upcoming text"
      defaultMessage="Scheduling Interview"
    />
  ),
  INTERVIEW_SUCCESSFUL: (
    <FormattedMessage
      description="Referral page > Referral card > Interview successful step upcoming text"
      defaultMessage="Giving Offer"
    />
  ),
  HIRED: (
    <FormattedMessage
      description="Referral page > Referral card > Hired step upcoming text"
      defaultMessage="Getting Hired!"
    />
  ),
  STARTED_WORK: (
    <FormattedMessage
      description="Referral page > Referral card > Started work Step upcoming text"
      defaultMessage="Starting Work"
    />
  ),
  STARTED_ORIENTATION: (
    <FormattedMessage
      description="Referral page > Referral card > Started orientation Step upcoming text"
      defaultMessage="Starting Orientation"
    />
  ),
  COMPLETED_ORIENTATION: (
    <FormattedMessage
      description="Referral page > Referral card > Completed orientation Step upcoming text"
      defaultMessage="Completing Orientation"
    />
  ),
  COMPLETED_FIRST_SHIFT: (
    <FormattedMessage
      description="Referral page > Referral card > Completed first shift Step upcoming text"
      defaultMessage="Completing First Shift"
    />
  ),
};

interface FutureStepLabelProps {
  step:
    | ({
        __typename?: "ReferralRewardStructureReferralRecruitmentItem";
      } & Pick<ReferralRewardStructureReferralRecruitmentItem, "step">)
    | ({
        __typename?: "ReferralRewardStructureReferralRetentionItem";
      } & {
        duration: {
          __typename?: "ReferralRewardStructureReferralRetentionItemDuration";
        } & Pick<
          ReferralRewardStructureReferralRetentionItemDuration,
          "unit" | "amount"
        >;
      });
}

export function FutureStepLabel({
  step,
}: FutureStepLabelProps): JSX.Element | null {
  if (!step.__typename) {
    throw new Error("Step does not have __typename");
  }

  switch (step.__typename) {
    case "ReferralRewardStructureReferralRetentionItem":
      return step.duration.unit ===
        ReferralRewardStructureReferralRetentionItemDurationUnit.Months ? (
        <FormattedMessage
          description="Referral page > Referral card > Monthly retention step upcoming text"
          defaultMessage="Completing {duration_months, number} months"
          values={{
            duration_months: step.duration.amount,
          }}
        />
      ) : (
        <FormattedMessage
          description="Referral page > Referral card > Hourly retention step upcoming text"
          defaultMessage="Completing {duration_hours, number} hours"
          values={{
            duration_hours: step.duration.amount,
          }}
        />
      );
    case "ReferralRewardStructureReferralRecruitmentItem":
      return STEP_NAME_MAP[step.step];
    default:
      assertNever(step.__typename, `Unexpected step type`);
  }
}
