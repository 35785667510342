/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ProgressSpinner } from "@rewards-web/shared/components/progress-spinner";
import { Typography } from "@rewards-web/shared/components/typography";
import { useWindowFocusHandler } from "@rewards-web/shared/hooks/use-window-focus-handler";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  FormattedNumber,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { formatPointsAsDollars } from "../lib/format-points-as-dollars";
import { usePointBalance } from "../modules/point-balance";
import { usePointsBannerDataQuery } from "./points-banner-data.generated";
import { PointsBannerPointIcon } from "./points-banner-point-icon";

export function PointsBanner() {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const pointBalance = usePointBalance();
  const bannerDataQuery = usePointsBannerDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const queryError = pointBalance.error || bannerDataQuery.error;

  useEffect(() => {
    pointBalance.refreshPointBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useWindowFocusHandler({ onFocused: pointBalance.refreshPointBalance });

  const whiteLabeled = !!bannerDataQuery.data?.getMyRewardsOrganization
    .whiteLabelConfig;

  const content = (() => {
    const buttonType = (() => {
      if (!bannerDataQuery.data) {
        return "generic_redeem" as const;
      }

      if (
        typeof pointBalance.computedBalance === "number" &&
        pointBalance.computedBalance >=
          bannerDataQuery.data!.getMyRewardsOrganization
            .minimumPointsNeededToRedeem
      ) {
        return "redeem" as const;
      }

      if (!bannerDataQuery.data.getMyRewardsUser.referralsEnabled) {
        return "view_my_points" as const;
      }

      return "reward_options" as const;
    })();

    return (
      <>
        <div
          css={(theme: AppTheme) => css`
            margin-right: ${theme.spacing(2)};
          `}
        >
          {queryError ? (
            <Typography variant="body">
              <FormattedMessage
                description="Points summary banner > load error message"
                defaultMessage="An error occurred while loading your points. Please try again later."
              />
              {queryError.message}
            </Typography>
          ) : (
            <>
              <Typography
                variant="caption"
                css={(theme: AppTheme) => css`
                  color: ${whiteLabeled ? "#fff" : theme.palette.primary.main};
                  text-transform: uppercase;
                  opacity: 0.5;
                `}
              >
                <FormattedMessage
                  description="Points summary banner > current points label"
                  defaultMessage="Current points"
                />
              </Typography>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <PointsBannerPointIcon
                  css={(theme: AppTheme) => css`
                    margin-right: ${theme.spacing(1)};
                  `}
                />
                {pointBalance.computedBalance === undefined ? (
                  <ProgressSpinner size={34} />
                ) : (
                  <Typography
                    css={(theme: AppTheme) => css`
                      color: ${whiteLabeled
                        ? "#fff"
                        : theme.palette.primary.main};
                    `}
                    color="primary"
                    variant="h4"
                    component="span"
                  >
                    <FormattedNumber value={pointBalance.computedBalance} />
                  </Typography>
                )}
              </div>
            </>
          )}
        </div>
        {!queryError &&
          (typeof pointBalance.computedBalance === "number" &&
          bannerDataQuery.data ? (
            <Button
              color={whiteLabeled ? undefined : "primary"}
              whiteBackground={whiteLabeled}
              label={(() => {
                switch (buttonType) {
                  case "generic_redeem":
                    return formatMessage({
                      defaultMessage: "Redeem",
                      description:
                        "Points summary banner > redeem button label",
                    });
                  case "redeem":
                    return formatMessage(
                      {
                        defaultMessage: "Redeem {amount}",
                        description:
                          "Points summary banner > redeem amount button label",
                      },
                      {
                        amount: formatPointsAsDollars(
                          pointBalance.computedBalance,
                          bannerDataQuery.data!.getMyRewardsOrganization
                            .pointsPerDollar
                        ),
                      }
                    );
                  case "reward_options":
                    return formatMessage({
                      defaultMessage: "Reward Options",
                      description:
                        "Points summary banner > reward options button label",
                    });
                  case "view_my_points":
                    return formatMessage({
                      defaultMessage: "View My Points",
                      description:
                        "Points summary banner > view my points button label",
                    });
                }
              })()}
              size={mobile ? "small" : "medium"}
              width="auto"
              linkTo={"/my-rewards"}
              onClick={() => {
                track("Points banner redeem button clicked", { buttonType });
              }}
            />
          ) : (
            <ProgressSpinner size={24} />
          ))}
      </>
    );
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        ${bannerDataQuery.data || queryError
          ? css`
              background-color: ${whiteLabeled
                ? theme.palette.primary.main
                : theme.palette.gold.light};
            `
          : css`
              visibility: hidden;
            `}
        width: calc(100% - ${theme.spacing(4)});
        max-width: calc(612px);
        margin: 0 auto;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -${theme.spacing(4)};
        padding: ${theme.spacing(1, 2)};
        ${theme.breakpoints.up("sm")} {
          margin-top: -${theme.spacing(6)};
          padding: ${theme.spacing(3, 3)};
        }
      `}
    >
      {content}
    </div>
  );
}
