/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

interface ProgressBarProps extends StylableProps {
  max: number;
  value: number;
  height?: string;
  color?: "primary" | "tertiary";
  easingAlgorithm?: "linear" | "ease-out-in";
}

export function ProgressBar({
  max,
  value: valueProp,
  height = "12px",
  className,
  color,
  easingAlgorithm = "linear",
}: ProgressBarProps) {
  const value = (() => {
    switch (easingAlgorithm) {
      case "linear":
        return valueProp;
      case "ease-out-in":
        return easeOutIn(valueProp / max) * max;
      default:
        assertNever(easingAlgorithm);
    }
  })();

  return (
    <progress
      className={className}
      css={(appTheme: AppTheme) => css`
        width: 100%;
        height: ${height};
        border-radius: 1000px;
        margin-top: ${appTheme.spacing(1)};
        -webkit-appearance: none;
        border: 0;
        margin: 0;
        padding: 0;
        &[value]::-webkit-progress-value {
          background-color: ${color === "primary"
            ? appTheme.palette.primary.main
            : appTheme.palette.tertiary.main};
          border-radius: 1000px;
          transition: width 1s ease-in-out; // animates the progress bar
        }
        &::-moz-progress-bar {
          background-color: ${color === "primary"
            ? appTheme.palette.primary.main
            : appTheme.palette.tertiary.main};
          border-radius: 1000px;
        }
        &[value]::-webkit-progress-bar {
          background-color: rgba(44, 57, 63, 0.1);
          border-radius: 1000px;
        }
      `}
      max={max}
      value={value}
    />
  );
}

/**
 * Converts a fraction between 0 and 1, into fraction between 0 and 1,
 * but scaled with an ease-out-in algorithm.
 */
function easeOutIn(
  fraction: number,
  splitPoint = 0.55,
  easeInScalingFactor = 3,
  easeOutScalingFactor = 1.5
) {
  if (fraction < splitPoint) {
    // Ease-out part for the first half
    return splitPoint * Math.pow(2 * fraction, 1 / easeInScalingFactor);
  } else {
    // Ease-in part for the second half
    return (
      splitPoint * Math.pow(2 * (fraction - splitPoint), easeOutScalingFactor) +
      splitPoint
    );
  }
}
