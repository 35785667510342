/** @jsxImportSource @emotion/react */
import { QueryResult } from "@apollo/client";
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { GetMilestoneRewardsForRewardsPageOfferCardQuery } from "../milestone-rewards-offer-card-data.generated";
import { MilestoneRewardOfferCardComplete } from "./milestone-reward-offer-card-complete";
import { MilestoneRewardOfferCardInProgress } from "./milestone-reward-offer-card-in-progress";

interface MilestoneRewardOfferCardContentProps {
  userMilestoneRewardsQuery: QueryResult<GetMilestoneRewardsForRewardsPageOfferCardQuery>;
}

export function MilestoneRewardOfferCardContent({
  userMilestoneRewardsQuery,
}: MilestoneRewardOfferCardContentProps) {
  const { formatMessage } = useFormatters();

  const milestoneRewardOfferCardContentError = (
    <Alert
      message={formatMessage({
        description:
          "Rewards page > Milestone rewards offer card > page load error message",
        defaultMessage: "An unexpected error occurred. Please try again later.",
      })}
      severity="error"
    />
  );

  const milestoneRewardOfferCardContentSkeleton = (
    <div
      css={(appTheme: AppTheme) => css`
        padding: ${appTheme.spacing(0, 2.5)};
      `}
    >
      {Array.from(Array(2)).map((_, i) => (
        <Skeleton key={i} height={100} animated />
      ))}
    </div>
  );

  if (userMilestoneRewardsQuery.error) {
    return milestoneRewardOfferCardContentError;
  }

  if (!userMilestoneRewardsQuery.data) {
    return milestoneRewardOfferCardContentSkeleton;
  }

  const { rewardsPageMilestoneRewardsCardData, organization } =
    userMilestoneRewardsQuery.data ?? {};

  if (!rewardsPageMilestoneRewardsCardData || !organization) {
    return milestoneRewardOfferCardContentError;
  }

  const { pointsPerDollar } = organization;

  if (rewardsPageMilestoneRewardsCardData.allMilestonesComplete) {
    return (
      <MilestoneRewardOfferCardComplete
        totalPoints={rewardsPageMilestoneRewardsCardData.milestoneSteps.reduce(
          (total, step) => total + step.pointValue,
          0
        )}
        pointsPerDollar={pointsPerDollar}
      />
    );
  }

  return (
    <MilestoneRewardOfferCardInProgress
      milestoneRewards={rewardsPageMilestoneRewardsCardData}
      pointsPerDollar={pointsPerDollar}
    />
  );
}
