/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { Ref } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../shared/page-content-container";

interface MilestoneRewardsPageFooterProps {
  footerRef: Ref<HTMLDivElement>;
}

export function MilestoneRewardsPageFooter({
  footerRef,
}: MilestoneRewardsPageFooterProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const desktop = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.up("md")
  );
  return (
    <div
      ref={footerRef}
      css={css`
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        box-shadow: 0px -4.5px 7px rgba(0, 0, 0, 0.05);
      `}
    >
      <PageContentContainer
        css={(appTheme: AppTheme) => css`
          padding-top: ${appTheme.spacing(2)};
          padding-bottom: ${appTheme.spacing(2)};
        `}
      >
        <Button
          css={css`
            display: block;
            margin: 0 auto;
          `}
          color="secondary"
          width={desktop ? "auto" : "full"}
          label={formatMessage({
            description: "Milestone rewards offer page > close button",
            defaultMessage: "Close",
          })}
          linkTo="/rewards"
          onClick={() =>
            track("Clicked close milestone rewards offer page button")
          }
        />
      </PageContentContainer>
    </div>
  );
}
