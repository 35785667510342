/** @jsxImportSource @emotion/react */
import { GoalType } from "@rewards-web/shared/graphql-types";

import { CompleteTrainingGoalCard } from "./complete-training-goal-card";
import { EvvStreakGoalCard } from "./evv-streak-goal-card";
import { GoalCardFragmentFragment } from "./goal-card-fragment.generated";
import { HHAXLoginGoalCard } from "./hhax-login-goal-card";
import { ShareJobGoalCard } from "./job-share-goal-card";
import { LastMinuteShiftGoalCard } from "./last-minute-shift-goal-card";
import { SurveyGoalCard } from "./survey-goal-card";
import { WatchVideoGoalCard } from "./watch-video-goal-card";
import { WeekendShiftGoalCard } from "./weekend-shift-goal-card";

export interface GoalCardProps {
  goal: GoalCardFragmentFragment;
  cardContext: "home" | "subpage";
}

export function GoalCard({ goal, cardContext }: GoalCardProps) {
  switch (goal.type) {
    case GoalType.Survey:
      return <SurveyGoalCard {...{ goal, cardContext }} />;
    case GoalType.ShareJob:
      return <ShareJobGoalCard {...{ goal, cardContext }} />;
    case GoalType.EvvStreak:
      return <EvvStreakGoalCard {...{ goal, cardContext }} />;
    case GoalType.WatchVideo:
      return <WatchVideoGoalCard {...{ goal, cardContext }} />;
    case GoalType.HhaxLogin:
      return <HHAXLoginGoalCard {...{ goal, cardContext }} />;
    case GoalType.CompleteTraining:
      return <CompleteTrainingGoalCard {...{ goal, cardContext }} />;
    case GoalType.LastMinuteShift:
      return <LastMinuteShiftGoalCard {...{ goal, cardContext }} />;
    case GoalType.WeekendShift:
      return <WeekendShiftGoalCard {...{ goal, cardContext }} />;
    default:
      // if we can't recognize the goal type, we won't render the card
      return null;
  }
}
