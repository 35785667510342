export function generateAdminAppLink(params: {
  adminAppBaseUrl: string;
  adminEmail: string | null;
}) {
  const searchParams = new URLSearchParams({ utm_source: "rewards_app" });

  if (params.adminEmail) {
    searchParams.set("email", params.adminEmail);
  }

  return `${params.adminAppBaseUrl}/login?${searchParams}`;
}
