import { SVGProps } from "react";

export function RoundelIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="9.5" cy="9.88867" r="9.5" fill={props.fill} />
      <circle cx="9.5" cy="9.88867" r="4.5" fill="white" />
    </svg>
  );
}
