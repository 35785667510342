import * as Types from '@rewards-web/shared/graphql-types';

import { GoalCardFragmentFragment } from '../../../shared/goal/goal-card/goal-card-fragment.generated';
import { gql } from '@apollo/client';
import { GoalCardFragmentFragmentDoc } from '../../../shared/goal/goal-card/goal-card-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GoalsForTrackPageQueryVariables = Types.Exact<{
  trackName: Types.GoalTrackName;
}>;


export type GoalsForTrackPageQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUserGoalTrack: (
    { __typename?: 'GetMyRewardsUserGoalTrackResponse' }
    & { availableGoals: Array<(
      { __typename?: 'Goal' }
      & GoalCardFragmentFragment
    )>, lockedGoals: Array<(
      { __typename?: 'Goal' }
      & GoalCardFragmentFragment
    )>, achievedGoals: Array<(
      { __typename?: 'Goal' }
      & GoalCardFragmentFragment
    )>, trackProgress: (
      { __typename?: 'TrackProgress' }
      & Pick<Types.TrackProgress, 'totalEarnablePointsForTrack' | 'pointsEarnedSoFar'>
    ) }
  ) }
);


export const GoalsForTrackPageDocument = gql`
    query GoalsForTrackPage($trackName: GoalTrackName!) {
  getMyRewardsUserGoalTrack(trackName: $trackName) {
    availableGoals {
      ...GoalCardFragment
    }
    lockedGoals {
      ...GoalCardFragment
    }
    achievedGoals {
      ...GoalCardFragment
    }
    trackProgress {
      totalEarnablePointsForTrack
      pointsEarnedSoFar
    }
  }
}
    ${GoalCardFragmentFragmentDoc}`;

/**
 * __useGoalsForTrackPageQuery__
 *
 * To run a query within a React component, call `useGoalsForTrackPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalsForTrackPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalsForTrackPageQuery({
 *   variables: {
 *      trackName: // value for 'trackName'
 *   },
 * });
 */
export function useGoalsForTrackPageQuery(baseOptions: Apollo.QueryHookOptions<GoalsForTrackPageQuery, GoalsForTrackPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoalsForTrackPageQuery, GoalsForTrackPageQueryVariables>(GoalsForTrackPageDocument, options);
      }
export function useGoalsForTrackPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoalsForTrackPageQuery, GoalsForTrackPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoalsForTrackPageQuery, GoalsForTrackPageQueryVariables>(GoalsForTrackPageDocument, options);
        }
export type GoalsForTrackPageQueryHookResult = ReturnType<typeof useGoalsForTrackPageQuery>;
export type GoalsForTrackPageLazyQueryHookResult = ReturnType<typeof useGoalsForTrackPageLazyQuery>;
export type GoalsForTrackPageQueryResult = Apollo.QueryResult<GoalsForTrackPageQuery, GoalsForTrackPageQueryVariables>;