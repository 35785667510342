/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useCountUp } from "../../../../../shared/use-count-up";

export interface PointsCountUpProps {
  points: number;
}

export const COUNT_UPANIMATION_DURATION_IN_MS = 1500;

export function PointsCountUp({ points }: PointsCountUpProps) {
  const pointsDisplay = useCountUp(points, {
    durationMs: COUNT_UPANIMATION_DURATION_IN_MS,
    allowCountDown: true, // it counts down after redeeming
  });

  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        gap: ${appTheme.spacing(0.5)};
        margin-bottom: ${appTheme.spacing(1.25)};
        margin-top: ${appTheme.spacing(3.5)};
      `}
    >
      {pointsDisplay
        .toString()
        .padStart(Math.max(4, points.toString().length), "0")
        .split("")
        .map((letter, idx) => (
          <div
            key={idx}
            css={(appTheme: AppTheme) => css`
              border: 1px solid ${appTheme.palette.divider};
              border-radius: 5px;
              height: ${appTheme.spacing(4.5)};
              width: ${appTheme.spacing(4)};
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <Typography variant="h5" color="white">
              {letter}
            </Typography>
          </div>
        ))}
    </div>
  );
}
