/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button as MuiButton, alpha } from "@material-ui/core";

import { Button } from "@rewards-web/shared/components/button";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface PhoneNumberRetryProps {
  onRetry(): void;
}

const CONTACT_EMAIL = "help@caribou.care";

export function PhoneNumberRetry({
  onRetry,
}: PhoneNumberRetryProps): JSX.Element {
  const { formatMessage } = useFormatters();

  return (
    <>
      <MuiButton
        variant="outlined"
        onClick={onRetry}
        css={(theme: AppTheme) => css`
          color: white;
          width: 100%;
          height: 48px;
          background-color: ${alpha(theme.palette.divider, 0.4)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <FormattedMessage
          description="Login page > retry login button"
          defaultMessage="Retry"
        />
      </MuiButton>
      <Button
        label={formatMessage({
          description: "Login page > contact customer support button label",
          defaultMessage: "Contact Customer Support",
        })}
        onClick={() => {
          window.open(`mailto:${CONTACT_EMAIL}`);
        }}
        color="primary"
        size="large"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />
    </>
  );
}
