import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MarkJobSharedMutationVariables = Types.Exact<{
  jobShareId: Types.Scalars['ID'];
  platform: Types.SharePlatform;
}>;


export type MarkJobSharedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'markJobShared'>
);


export const MarkJobSharedDocument = gql`
    mutation MarkJobShared($jobShareId: ID!, $platform: SharePlatform!) {
  markJobShared(jobShareId: $jobShareId, platform: $platform)
}
    `;
export type MarkJobSharedMutationFn = Apollo.MutationFunction<MarkJobSharedMutation, MarkJobSharedMutationVariables>;

/**
 * __useMarkJobSharedMutation__
 *
 * To run a mutation, you first call `useMarkJobSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkJobSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markJobSharedMutation, { data, loading, error }] = useMarkJobSharedMutation({
 *   variables: {
 *      jobShareId: // value for 'jobShareId'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useMarkJobSharedMutation(baseOptions?: Apollo.MutationHookOptions<MarkJobSharedMutation, MarkJobSharedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkJobSharedMutation, MarkJobSharedMutationVariables>(MarkJobSharedDocument, options);
      }
export type MarkJobSharedMutationHookResult = ReturnType<typeof useMarkJobSharedMutation>;
export type MarkJobSharedMutationResult = Apollo.MutationResult<MarkJobSharedMutation>;
export type MarkJobSharedMutationOptions = Apollo.BaseMutationOptions<MarkJobSharedMutation, MarkJobSharedMutationVariables>;