import { useFormatters } from "..";

interface FormattedDateProps extends Intl.DateTimeFormatOptions {
  value: Date;
}

/**
 * Formats a JS date using Intl APIs, using the currently-selected locale.
 */
export function FormattedDate({ value, ...options }: FormattedDateProps) {
  const { formatDate } = useFormatters();
  return <>{formatDate(value, options)}</>;
}
