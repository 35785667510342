import { reportError } from "@rewards-web/shared/modules/error";

/**
 * Adapted from aws-amplify: https://github.com/aws-amplify/amplify-js/blob/44939c0338c2cc7aefda777a55fcb24f12133548/packages/core/src/StorageHelper/index.ts#L68
 *
 * Falls back to an in-memory storage solution if `localStorage` isn't available.
 * This appears to occur in Safari on a cold link open, so this solves that issue:
 * https://github.com/aws-amplify/amplify-js/discussions/8986#discussioncomment-1720332
 */
export function getCognitoAuthStorage(): Pick<
  Storage,
  "setItem" | "getItem" | "removeItem" | "clear"
> {
  try {
    window.localStorage.setItem("rewards.shared.test-ls", "1");
    window.localStorage.removeItem("rewards.shared.test-ls");
    // we know local storage is working
    return window.localStorage;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Cannot use localstorage; falling back to memory storage");
    reportError(
      new Error(
        `Cannot use localstorage; falling back to memory storage. Original error: ${
          (error as any)?.message ?? "Unknown"
        }`
      )
    );
    return MemoryStorage;
  }
}

let dataMemory: {
  [key: string]: string;
} = {};

/**
 * In-memory storage which implements the same interface as local storage
 * Source mainly copied from aws-amplify
 */
export class MemoryStorage {
  /**
   * This is used to set a specific item in storage
   * @param {string} key - the key for the item
   * @param {object} value - the value
   * @returns {string} value that was set
   */
  static setItem(key: string, value: any) {
    dataMemory[key] = value;
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   * @param {string} key - the key for the item
   * This is used to clear the storage
   * @returns {string} the data item
   */
  static getItem(key: string) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : null;
  }

  /**
   * This is used to remove an item from storage
   * @param {string} key - the key being set
   * @returns {string} value - value that was deleted
   */
  static removeItem(key: string) {
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage
   * @returns {string} nothing
   */
  static clear() {
    dataMemory = {};
    return dataMemory;
  }
}
