import { useTrack } from "@rewards-web/shared/modules/analytics";

import { VideoTrackingHandlers } from "../video-player/use-video-tracking-handlers";

interface UseVideoOfferTrackingHandlersParams {
  videoOfferId: string;
  alreadyWatched: boolean;
  playingFromOfferCard?: boolean;
}

/**
 * Returns props that can be passed to `VideoPlayer` in order
 * to track playback functionality for the user
 */
export function useVideoOfferTrackingHandlers({
  videoOfferId,
  alreadyWatched,
  playingFromOfferCard = false,
}: UseVideoOfferTrackingHandlersParams): VideoTrackingHandlers {
  const track = useTrack();
  const trackingProperties = {
    videoOfferId,
    alreadyWatched,
    playingFromOfferCard,
  };

  return {
    onEnd: ({ videoDurationSeconds }) =>
      track("Video offer watched", {
        ...trackingProperties,
        videoDurationSeconds,
      }),
    onOpenFullScreen: () =>
      track("Video offer opened full screen", trackingProperties),
    onPlay: ({
      videoDurationSeconds,
      percentageCompleted,
      loadedEnoughToPlayThrough,
    }) =>
      track("Video offer played", {
        ...trackingProperties,
        percentageCompleted,
        videoDurationSeconds,
        loadedEnoughToPlayThrough,
      }),
    onPause: ({ videoDurationSeconds, percentageCompleted }) =>
      track("Video offer paused", {
        ...trackingProperties,
        percentageCompleted,
        videoDurationSeconds,
      }),
    onLag: ({ videoDurationSeconds, percentageCompleted }) => {
      track("Video offer playback lagged", {
        ...trackingProperties,
        percentageCompleted,
        videoDurationSeconds,
      });
    },
    onLoadMetadata: ({ metadataLoadTimeMilliseconds }) => {
      track("Video offer loaded metadata", {
        ...trackingProperties,
        metadataLoadTimeMilliseconds,
      });
    },
    onLoadEnoughToPlayThrough: ({ loadTimeMilliseconds }) => {
      track("Video offer loaded enough to play through", {
        ...trackingProperties,
        loadTimeMilliseconds,
      });
    },
  };
}
