import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MarkVideoOfferWatchedMutationVariables = Types.Exact<{
  videoOfferId: Types.Scalars['ID'];
}>;


export type MarkVideoOfferWatchedMutation = (
  { __typename?: 'Mutation' }
  & { markRewardVideoOfferWatched: (
    { __typename?: 'RewardVideoOffer' }
    & Pick<Types.RewardVideoOffer, 'id' | 'watched'>
  ) }
);


export const MarkVideoOfferWatchedDocument = gql`
    mutation MarkVideoOfferWatched($videoOfferId: ID!) {
  markRewardVideoOfferWatched(videoOfferId: $videoOfferId) {
    id
    watched
  }
}
    `;
export type MarkVideoOfferWatchedMutationFn = Apollo.MutationFunction<MarkVideoOfferWatchedMutation, MarkVideoOfferWatchedMutationVariables>;

/**
 * __useMarkVideoOfferWatchedMutation__
 *
 * To run a mutation, you first call `useMarkVideoOfferWatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkVideoOfferWatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markVideoOfferWatchedMutation, { data, loading, error }] = useMarkVideoOfferWatchedMutation({
 *   variables: {
 *      videoOfferId: // value for 'videoOfferId'
 *   },
 * });
 */
export function useMarkVideoOfferWatchedMutation(baseOptions?: Apollo.MutationHookOptions<MarkVideoOfferWatchedMutation, MarkVideoOfferWatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkVideoOfferWatchedMutation, MarkVideoOfferWatchedMutationVariables>(MarkVideoOfferWatchedDocument, options);
      }
export type MarkVideoOfferWatchedMutationHookResult = ReturnType<typeof useMarkVideoOfferWatchedMutation>;
export type MarkVideoOfferWatchedMutationResult = Apollo.MutationResult<MarkVideoOfferWatchedMutation>;
export type MarkVideoOfferWatchedMutationOptions = Apollo.BaseMutationOptions<MarkVideoOfferWatchedMutation, MarkVideoOfferWatchedMutationVariables>;