import { SVGProps } from "react";

function LockIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89 0h.22c.636 0 1.109 0 1.518.11a3.2 3.2 0 0 1 2.263 2.262c.11.409.11.881.109 1.518v2.513c.387.003.726.014 1.024.04.499.046.956.143 1.376.386.486.28.89.684 1.171 1.171.243.42.34.877.386 1.375.043.48.043 1.071.043 1.788v.074c0 .716 0 1.307-.043 1.787-.046.499-.143.956-.386 1.376-.28.486-.685.89-1.171 1.171-.42.243-.877.34-1.376.386-.48.043-1.07.043-1.787.043H4.763c-.716 0-1.307 0-1.787-.043-.499-.046-.956-.143-1.376-.386A3.191 3.191 0 0 1 .43 14.4c-.243-.42-.34-.877-.386-1.376C0 12.544 0 11.954 0 11.237v-.074c0-.717 0-1.308.043-1.788C.09 8.877.186 8.421.43 8c.28-.487.685-.89 1.171-1.171.42-.243.877-.34 1.376-.385.298-.027.637-.038 1.024-.041V3.89c0-.637 0-1.11.11-1.518A3.2 3.2 0 0 1 6.371.11C6.782 0 7.254 0 7.89 0Zm2.51 4v2.4H5.6V4c0-.795.007-1.036.055-1.214a1.601 1.601 0 0 1 1.13-1.132C6.965 1.607 7.206 1.6 8 1.6c.796 0 1.036.007 1.214.054.552.149.983.58 1.131 1.132.048.178.055.419.055 1.214ZM5.6 8h-.868c-.726 0-1.224.002-1.611.037-.389.035-.585.099-.721.177-.243.14-.445.342-.586.586-.078.137-.141.332-.177.72-.036.4-.036.917-.036 1.68 0 .762 0 1.28.036 1.68.036.388.099.584.177.72.141.243.343.445.586.585.137.08.332.143.72.178.4.036.918.037 1.68.037h6.4c.762 0 1.28-.001 1.68-.037.388-.035.584-.098.72-.178.243-.14.445-.342.586-.585.079-.136.142-.332.177-.72.036-.4.037-.918.037-1.68 0-.763-.001-1.28-.037-1.68-.035-.388-.098-.583-.177-.72a1.601 1.601 0 0 0-.586-.586c-.136-.078-.332-.142-.72-.177-.388-.035-.886-.037-1.612-.037H5.6Zm1.2 2.4a.8.8 0 0 0 0 1.6h2.4a.8.8 0 1 0 0-1.6H6.8Z"
        fill="#636666"
      />
    </svg>
  );
}

export default LockIcon;
