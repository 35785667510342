import { reportError } from "@rewards-web/shared/modules/error";

import { UserMilestoneRewardStepDetailsFragment } from "./milestone-step-fragment.generated";

export const getMilestoneRewardCompletePercentage = (
  milestone: UserMilestoneRewardStepDetailsFragment | null | undefined
): number | undefined => {
  if (!milestone) {
    return undefined;
  }
  const progressTotal = (() => {
    switch (milestone.goal.__typename) {
      case "HoursMilestoneGoal":
        return milestone.goal.numHours;
      case "VisitMilestoneGoal":
        return milestone.goal.visitNumber;
      case undefined:
      default:
        reportError(
          new Error(
            `Unexpected milestone goal type when calculating complete percentage: ${milestone.goal.__typename}`
          )
        );
        return 1;
    }
  })();
  return Math.round((milestone.progress / progressTotal) * 100);
};
