/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsStarIcon } from "../../../../../shared/points-star-icon";
import { RaffleIcon } from "./icons/raffle-icon";

export interface RewardItemProps {
  title: string;
  subtitle?: string;
  rewardType?: "points" | "draw";
  rewardValue: number;
  pointsPerDollar: number;
}

export function RewardItem({
  title,
  subtitle,
  rewardValue,
  rewardType = "points",
  pointsPerDollar,
}: RewardItemProps) {
  const { formatMessage } = useFormatters();
  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${appTheme.spacing(2)} 0;
        border-top: 1px dashed ${appTheme.palette.divider};
      `}
    >
      <div>
        <Typography variant="body" color="textPrimary">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="footnote" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </div>
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          align-items: center;
          border: 1px solid ${appTheme.palette.divider};
          padding: ${appTheme.spacing(0.6)} ${appTheme.spacing(0.75)};
          border-radius: 500px;
        `}
      >
        {rewardType === "draw" ? (
          <RaffleIcon />
        ) : (
          <PointsStarIcon color="gold" />
        )}
        <Typography
          variant="caption"
          css={(appTheme: AppTheme) => css`
            text-transform: uppercase;
            margin-left: ${appTheme.spacing(0.75)};
          `}
          color="textPrimary"
        >
          {rewardType === "draw"
            ? formatMessage(
                {
                  defaultMessage:
                    "{num_tickets} draw {num_tickets, plural, one {ticket} other {tickets}}",
                  description:
                    "Job details page > reward structure dialog > number of draw tickets",
                },
                { num_tickets: rewardValue }
              )
            : formatMessage(
                {
                  defaultMessage: "{points, number} = {dollars}",
                  description:
                    "Job details page > reward structure dialog > number of points/dollars",
                },
                {
                  points: rewardValue,
                  dollars: formatDollars(rewardValue / pointsPerDollar),
                }
              )}
        </Typography>
      </div>
    </div>
  );
}
