/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { MilestoneRewardStep } from "../../../../../../shared/milestone-rewards-offer/milestone-reward-step";
import { UserMilestoneRewardStepDetailsFragment } from "../../../../../../shared/milestone-rewards-offer/milestone-step-fragment.generated";
import { GetMilestoneRewardsForRewardsPageOfferCardQuery } from "../../milestone-rewards-offer-card-data.generated";

interface MilestoneRewardOfferCardInProgressProps {
  milestoneRewards: NonNullable<
    GetMilestoneRewardsForRewardsPageOfferCardQuery["rewardsPageMilestoneRewardsCardData"]
  >;
  pointsPerDollar: number;
}

export function MilestoneRewardOfferCardInProgress({
  milestoneRewards,
  pointsPerDollar,
}: MilestoneRewardOfferCardInProgressProps) {
  const { formatMessage } = useFormatters();

  const getMilestoneIndex = (
    milestone: UserMilestoneRewardStepDetailsFragment
  ) => milestoneRewards.milestoneSteps.findIndex((m) => m.id === milestone.id);

  return (
    <div>
      <div
        css={css`
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        `}
      >
        <MilestoneRewardStep
          variant="offer-card"
          milestone={milestoneRewards.currentMilestoneStep!}
          indexOfThisMilestone={getMilestoneIndex(
            milestoneRewards.currentMilestoneStep!
          )}
          isCurrentMilestone={true}
          mustCompleteEVV={milestoneRewards.mustCompleteEVV}
          pointsPerDollar={pointsPerDollar}
        />
      </div>
      {milestoneRewards.nextMilestoneStep && (
        <>
          <Typography
            variant="caption"
            color="text.primary"
            css={(appTheme: AppTheme) => css`
              text-transform: uppercase;
              margin-left: ${appTheme.spacing(2)};
              margin-top: ${appTheme.spacing(1.5)};
              margin-bottom: -${appTheme.spacing(1)};
            `}
          >
            {formatMessage({
              defaultMessage: "up next",
              description:
                "Rewards page > Milestones rewards offer card > up next",
            })}
          </Typography>
          <MilestoneRewardStep
            variant="offer-card"
            milestone={milestoneRewards.nextMilestoneStep}
            indexOfThisMilestone={getMilestoneIndex(
              milestoneRewards.nextMilestoneStep
            )}
            isCurrentMilestone={false}
            mustCompleteEVV={milestoneRewards.mustCompleteEVV}
            pointsPerDollar={pointsPerDollar}
          />
        </>
      )}
    </div>
  );
}
