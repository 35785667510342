import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AcknowledgePowerHoursOfferEndedMutationVariables = Types.Exact<{
  powerHoursOfferId: Types.Scalars['ID'];
}>;


export type AcknowledgePowerHoursOfferEndedMutation = (
  { __typename?: 'Mutation' }
  & { acknowledgePowerHoursOfferEnded: (
    { __typename?: 'PowerHoursOffer' }
    & Pick<Types.PowerHoursOffer, 'id'>
  ) }
);


export const AcknowledgePowerHoursOfferEndedDocument = gql`
    mutation AcknowledgePowerHoursOfferEnded($powerHoursOfferId: ID!) {
  acknowledgePowerHoursOfferEnded(powerHoursOfferId: $powerHoursOfferId) {
    id
  }
}
    `;
export type AcknowledgePowerHoursOfferEndedMutationFn = Apollo.MutationFunction<AcknowledgePowerHoursOfferEndedMutation, AcknowledgePowerHoursOfferEndedMutationVariables>;

/**
 * __useAcknowledgePowerHoursOfferEndedMutation__
 *
 * To run a mutation, you first call `useAcknowledgePowerHoursOfferEndedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgePowerHoursOfferEndedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgePowerHoursOfferEndedMutation, { data, loading, error }] = useAcknowledgePowerHoursOfferEndedMutation({
 *   variables: {
 *      powerHoursOfferId: // value for 'powerHoursOfferId'
 *   },
 * });
 */
export function useAcknowledgePowerHoursOfferEndedMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgePowerHoursOfferEndedMutation, AcknowledgePowerHoursOfferEndedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgePowerHoursOfferEndedMutation, AcknowledgePowerHoursOfferEndedMutationVariables>(AcknowledgePowerHoursOfferEndedDocument, options);
      }
export type AcknowledgePowerHoursOfferEndedMutationHookResult = ReturnType<typeof useAcknowledgePowerHoursOfferEndedMutation>;
export type AcknowledgePowerHoursOfferEndedMutationResult = Apollo.MutationResult<AcknowledgePowerHoursOfferEndedMutation>;
export type AcknowledgePowerHoursOfferEndedMutationOptions = Apollo.BaseMutationOptions<AcknowledgePowerHoursOfferEndedMutation, AcknowledgePowerHoursOfferEndedMutationVariables>;