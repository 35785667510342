/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface ProfileDetailsCardProps {
  data: {
    category: string;
    details: string | ReactNode;
  }[];
}

export function ProfileDetailsCard({
  data,
}: ProfileDetailsCardProps): JSX.Element {
  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) => css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-width: 0px;
        padding: 0 ${theme.spacing(2)};
      `}
    >
      <Table>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell divider={data.length !== index + 1}>
                <Typography variant="footnote" color="textSecondary">
                  {item.category}
                </Typography>
              </TableCell>
              <TableCell divider={data.length !== index + 1} align="right">
                {typeof item.details === "string" ? (
                  <Typography
                    css={css`
                      ${(item.category === "Email" ||
                        item.category === "Employer") &&
                      css`
                        word-break: break-all;
                      `}
                    `}
                    variant="footnote"
                    color="textPrimary"
                  >
                    {item.details}
                  </Typography>
                ) : (
                  item.details
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}
