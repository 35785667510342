/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

import { useOnboadingTourTooltip } from "./context";

export interface WithOnboardingTooltipProps extends StylableProps {
  name: string;
}

export function WithOnboardingTooltip({
  children,
  name,
  className,
}: PropsWithChildren<WithOnboardingTooltipProps>) {
  const { tooltip, dismiss } = useOnboadingTourTooltip(name);
  return (
    <div
      className={className}
      css={css`
        ${tooltip &&
        css`
          box-shadow: 0 0 0 9999px rgba(44, 57, 63, 0.9);
          z-index: 1101;
        `}
        position: relative;
      `}
    >
      {children}
      {tooltip && (
        <>
          <div
            onClick={() => dismiss()}
            css={css`
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
            `}
          />
          <div
            css={(appTheme: AppTheme) =>
              css`
                background-color: ${appTheme.palette.secondary.main};
                position: absolute;
                top: calc(100% + ${appTheme.spacing(2)});
                padding: ${appTheme.spacing(1.5)};
                border-radius: 10px;
                :before {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-left: 8px solid transparent;
                  border-right: 8px solid transparent;
                  border-bottom: 8px solid ${appTheme.palette.secondary.main};
                  left: 20px;
                  top: -7px;
                }
                display: flex;
              `
            }
          >
            <Typography
              css={css`
                flex: 1;
              `}
              color="white"
            >
              {tooltip.message}
            </Typography>
            <div
              onClick={() => dismiss()}
              css={(appTheme: AppTheme) => css`
                background-color: white;
                color: ${appTheme.palette.secondary.main};
                font-size: 14px;
                border-radius: 50%;
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              `}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
