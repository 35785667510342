/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  FormattedNumber,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import starImage from "../icons/star.png";

export interface DrawPrizeClaimedModalProps {
  open: boolean;
  onClose: () => void;
  drawId: string;
  pointValue: number;
}

export function DrawPrizeClaimedModal({
  open,
  onClose,
  drawId,
  pointValue,
}: DrawPrizeClaimedModalProps): JSX.Element {
  const { formatMessage } = useFormatters();
  const theme = useTheme();
  const track = useTrack();

  const onViewPoints = () => {
    track("Clicked view points button after claiming prize", { drawId });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      css={css`
        text-align: center;
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: row;
          align-self: center;
          margin-bottom: ${theme.spacing(2.5)};
        `}
      >
        <img
          width="33px"
          src={starImage}
          alt={formatMessage({
            defaultMessage: "Star",
            description:
              "Draw winners page > claimed prize modal > star image alt",
          })}
          css={css`
            margin-right: 10px;
            align-self: center;
          `}
        />
        <Typography variant="h1" color={theme.palette.primary.main}>
          <FormattedNumber value={pointValue} />
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.primary.main}
          css={css`
            align-self: end;
            margin-left: 10px;
          `}
        >
          pts
        </Typography>
      </div>
      <ModalTitle
        css={css`
          padding: 0;
        `}
      >
        <FormattedMessage
          description="Draw winners page > claimed prize modal > title"
          defaultMessage="Your prize has been claimed!"
        />
      </ModalTitle>
      <ModalContent
        css={(theme: AppTheme) =>
          css`
            padding-left: 0;
            padding-right: 0;
            margin-top: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(1.5)};
          `
        }
      >
        <Typography variant="body" color="textPrimary">
          <FormattedMessage
            description="Draw winners page > claimed prize modal > content"
            defaultMessage="Thank you for all your hard work!"
          />
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          label={formatMessage({
            defaultMessage: "View your points",
            description:
              "Draw winners page > claimed prize modal > button label",
          })}
          linkTo={"/home"}
          onClick={onViewPoints}
          size="large"
          color="secondary"
        />
      </ModalActions>
    </Modal>
  );
}
