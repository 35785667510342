import { useSetScreenRecordingIdentity } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import { useAuth } from "../../../shared/modules/auth";
import { useRewardsAppAnalyticsAttributesQuery } from "./analytics-attributes.generated";

/**
 * Identifies a user for screen recording.
 * It relies on the user being able to query `getMyRewardsOrganization`
 * It will only run if the user is authenticated.
 */
export function useIdentifyUserForScreenRecording() {
  const { userId } = useAuth();
  const setScreenRecordingIdentity = useSetScreenRecordingIdentity();
  useRewardsAppAnalyticsAttributesQuery({
    skip: !userId,
    fetchPolicy: "cache-first",
    onError: reportError,
    onCompleted: ({
      getMyRewardsOrganization: { id: organizationId, isQA, isDemo },
    }) => {
      setScreenRecordingIdentity(userId, {
        organizationId: Number(organizationId),
        isQA,
        isDemo,
      });
    },
  });
}
