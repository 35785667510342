import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PowerHoursOfferPageDataQueryVariables = Types.Exact<{
  powerHoursOfferId: Types.Scalars['ID'];
}>;


export type PowerHoursOfferPageDataQuery = (
  { __typename?: 'Query' }
  & { powerHoursOffer?: Types.Maybe<(
    { __typename?: 'PowerHoursOffer' }
    & Pick<Types.PowerHoursOffer, 'id' | 'rangeStart' | 'rangeEnd' | 'ended'>
    & { hourCondition?: Types.Maybe<{ __typename: 'PowerHoursWeekendHoursCondition' }>, levels: Array<(
      { __typename?: 'PowerHoursLevel' }
      & Pick<Types.PowerHoursLevel, 'id' | 'levelNumber' | 'hours' | 'pointValue' | 'finished' | 'current'>
    )> }
  )> }
);


export const PowerHoursOfferPageDataDocument = gql`
    query PowerHoursOfferPageData($powerHoursOfferId: ID!) {
  powerHoursOffer: getPowerHoursOfferById(powerHoursOfferId: $powerHoursOfferId) {
    id
    hourCondition {
      __typename
    }
    rangeStart
    rangeEnd
    levels {
      id
      levelNumber
      hours
      pointValue
      finished
      current
    }
    ended
  }
}
    `;

/**
 * __usePowerHoursOfferPageDataQuery__
 *
 * To run a query within a React component, call `usePowerHoursOfferPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerHoursOfferPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerHoursOfferPageDataQuery({
 *   variables: {
 *      powerHoursOfferId: // value for 'powerHoursOfferId'
 *   },
 * });
 */
export function usePowerHoursOfferPageDataQuery(baseOptions: Apollo.QueryHookOptions<PowerHoursOfferPageDataQuery, PowerHoursOfferPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerHoursOfferPageDataQuery, PowerHoursOfferPageDataQueryVariables>(PowerHoursOfferPageDataDocument, options);
      }
export function usePowerHoursOfferPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerHoursOfferPageDataQuery, PowerHoursOfferPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerHoursOfferPageDataQuery, PowerHoursOfferPageDataQueryVariables>(PowerHoursOfferPageDataDocument, options);
        }
export type PowerHoursOfferPageDataQueryHookResult = ReturnType<typeof usePowerHoursOfferPageDataQuery>;
export type PowerHoursOfferPageDataLazyQueryHookResult = ReturnType<typeof usePowerHoursOfferPageDataLazyQuery>;
export type PowerHoursOfferPageDataQueryResult = Apollo.QueryResult<PowerHoursOfferPageDataQuery, PowerHoursOfferPageDataQueryVariables>;