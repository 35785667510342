/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../../shared/sub-page-header";
import { DrawDetailsCard } from "../shared/draw-details-card";
import { DrawDetailsCardV2 } from "../shared/draw-details-card/draw-details-card-v2";
import { DrawTicketCount } from "../shared/draw-ticket-count";
import { getDaysUntilDrawClose } from "../shared/lib/get-days-until-draw-close";
import { useCaribouSponsoredDrawPageDataQuery } from "./caribou-sponsored-draw-page-data.generated";

export function CaribouSponsoredDrawPage() {
  const { formatMessage } = useFormatters();
  const query = useCaribouSponsoredDrawPageDataQuery({ onError: reportError });
  const mobileOrTablet = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("md")
  );

  const prizeStructure = query.data?.draw?.prizeStructure;
  const ticketCount = query.data?.draw?.myNumTicketsInDraw;
  const daysUntilDrawClose = query.data?.draw?.scheduledEndDate
    ? getDaysUntilDrawClose(query.data?.draw?.scheduledEndDate)
    : undefined;

  const track = useTrack();
  useEffect(() => {
    if (typeof ticketCount === "number") {
      track("Viewed caribou sponsored draw screen", {
        ticketCount,
        prizeStructure,
        daysUntilDrawClose,
      });
    }
  }, [track, ticketCount, prizeStructure, daysUntilDrawClose]);

  const errorAlert = (
    <Alert
      severity="error"
      message={formatMessage({
        defaultMessage: "An unexpected error occurred. Please try again later.",
        description: "Caribou sponsored draw page > page load error",
      })}
    />
  );

  if (query.error) {
    return errorAlert;
  }

  if (query.data && !query.data.draw) {
    // this is `true` when data has loaded, but there is no draw.
    // this can happen if the org does not have access to Caribou-sponsored draws..
    // it shouldn't happen because the parent component shouldn't render this
    return errorAlert;
  }

  const linkToMarketingPage = query.data?.draw?.linkToExternalMarketingPage;

  return (
    <div
      css={(theme: AppTheme) => css`
        padding-bottom: ${theme.spacing(2)};
        background-color: white;
        height: 100%;
        overflow-y: scroll;
      `}
    >
      <ScrollToTopOnMount />
      <div
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.primary.main};
          color: white;
          padding-bottom: ${theme.spacing(2)};
        `}
      >
        <SubPageHeader
          pageName={formatMessage({
            description: "Draw page > Caribou-sponsored draw > page header",
            defaultMessage: "Draw",
          })}
          analyticsPageName="Draw"
          hideWhiteBanner={!mobileOrTablet}
        />
        <PageContentContainer
          css={(theme: AppTheme) => css`
            padding-top: 0px; /* overrides default in component */

            ${theme.breakpoints.up("lg")} {
              padding-top: ${theme.spacing(3)};
            }
          `}
        >
          <DrawTicketCount ticketCount={ticketCount} />

          {prizeStructure?.__typename ===
          "DrawPrizeStructureSinglePrizeMultipleWinners" ? (
            <DrawDetailsCard
              prizeStructure={prizeStructure}
              drawEndsAt={query.data?.draw?.scheduledEndDate}
            />
          ) : (
            <DrawDetailsCardV2
              prizeStructure={prizeStructure}
              drawEndsAt={query.data?.draw?.scheduledEndDate}
            />
          )}
        </PageContentContainer>
      </div>
      <PageContentContainer
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(3)} ${theme.spacing(2)};
        `}
      >
        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          {formatMessage({
            description:
              "Draw page > Caribou-sponsored draw > instructions part 1",
            defaultMessage:
              "Earn draw tickets when you complete goals. Keep completing goals to increase your chances of winning! 🎁",
          })}
        </Typography>
        <Typography
          variant="body"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          {formatMessage({
            description:
              "Draw page > Caribou-sponsored draw > instructions part 2",
            defaultMessage:
              "Are you our next lucky winner? Visit our draw page to learn more.",
          })}
        </Typography>
        <Button
          variant="outlined"
          disabled={!linkToMarketingPage}
          label={formatMessage({
            description:
              "Draw page > Caribou-sponsored draw > marketing site button label",
            defaultMessage: "More about draws",
          })}
          linkTo={linkToMarketingPage}
          externalLink
          target="_blank"
          onClick={() => {
            track("Clicked caribou sponsored draw marketing link");
          }}
        />
      </PageContentContainer>
    </div>
  );
}
