/** @jsxImportSource @emotion/react */
import {
  GoalStatus,
  WatchVideoGoalDetails,
  WatchVideoGoalTracker,
  WatchVideoGoalTrackerVideoType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { GoalCardProps } from "..";
import playButtonImageUrl from "../../graphics/video-play-button.png";
import { BaseGoalCard, BaseGoalCardProps } from "../base-goal-card";
import { useCommonBaseGoalCardProps } from "../lib";

export function WatchVideoGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage } = useFormatters();
  const snackbar = useSnackbar();

  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
  });

  const videoType = (goal.goalTracker as WatchVideoGoalTracker)
    .videoType as WatchVideoGoalTrackerVideoType;

  const detailText = (() => {
    switch (videoType) {
      case WatchVideoGoalTrackerVideoType.WelcomeVideo: {
        return formatMessage({
          defaultMessage: "Learn how your rewards work in this quick video",
          description: "Goal card > watch video details",
        });
      }
      default:
        return null;
    }
  })();

  const videoId = (goal.goalDetails as WatchVideoGoalDetails)?.videoId ?? null;

  const missingVideoCallback = () => {
    // This shouldn't happen, but we want to be notified if it does
    // without breaking the home page.
    reportError(new Error(`Missing video ID for watch video goal ${goal.id}`));
    return snackbar.show({
      message: formatMessage({
        defaultMessage: "Video not available. Please try again later.",
        description: "Goal card > video not available",
      }),
      severity: "error",
    });
  };

  const primaryButtonProps:
    | BaseGoalCardProps["primaryButtonProps"]
    | undefined = (() => {
    const label = (() => {
      switch (goal.status) {
        case GoalStatus.Available:
          return formatMessage({
            defaultMessage: "Watch",
            description: "Goal card > watch video > button label",
          });
        case GoalStatus.Achieved:
          return formatMessage({
            defaultMessage: "Watch again",
            description: "Goal card > watch video > rewatch button label",
          });
        default:
          return "";
      }
    })();
    if (!label) {
      return undefined;
    }
    return videoId
      ? { label, linkTo: `/watch-video/${videoId}` }
      : { label, onClick: missingVideoCallback };
  })();

  return (
    <BaseGoalCard
      {...commonBaseGoalCardProps}
      icon={<img src={playButtonImageUrl} alt="play" />}
      detailText={detailText ?? undefined}
      primaryButtonProps={primaryButtonProps}
    />
  );
}
