/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

const MIN_WIDTH = 700;

export interface StickyHeaderProps {
  children?: ReactNode;
}

/**
 * Enables a sticky header for the provided children if the
 * screen height is large enough
 */
export function StickyHeader({ children }: StickyHeaderProps) {
  return (
    <div
      css={css`
        @media (min-height: ${MIN_WIDTH}px) {
          position: sticky;
          top: 0;
          z-index: 1;
        }
      `}
    >
      {children}
    </div>
  );
}
