/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../points-icon";

export interface VideoDescriptionProps {
  pointsForVideo: number | undefined;
  videoTitle: string | undefined;
  alreadyWatched?: boolean;
}

export function VideoDescription({
  videoTitle,
  alreadyWatched,
  pointsForVideo,
}: VideoDescriptionProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(3)} ${theme.spacing(2)};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #2c393f;
      `}
    >
      <div
        css={css`
          max-width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Typography
          variant="h1"
          css={(theme: AppTheme) => css`
            color: white;
            text-align: center;
            margin-bottom: ${theme.spacing(2)};
            font-size: 2.2em;
          `}
        >
          {videoTitle}
        </Typography>
        {pointsForVideo !== undefined && (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 0 auto;
            `}
          >
            <PointsIcon
              css={(theme: AppTheme) => css`
                flex-shrink: 0;
                margin-right: ${theme.spacing(1)};
              `}
            />
            {alreadyWatched ? (
              <Typography
                variant="body"
                css={css`
                  color: white;
                `}
              >
                <FormattedMessage
                  description="Video offer page > already watched explanation"
                  defaultMessage="You earned {points, number} points for watching this video"
                  values={{
                    points: pointsForVideo,
                  }}
                />
              </Typography>
            ) : (
              <Typography
                variant="body"
                css={css`
                  color: white;
                `}
              >
                <FormattedMessage
                  description="Video offer page > already watched explanation"
                  defaultMessage="You are earning {points, number} {points, plural, one {point} other {points}} for watching this video"
                  values={{
                    points: pointsForVideo,
                  }}
                />
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
