/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface FadeInComponentProps {
  children: React.ReactNode;
  visible: boolean;
  delayInSeconds: number;
}

/**
 * When `visible` turns from `false` to `true`, will fade the component into view
 * with the providing `delayInSeconds` timing.
 *
 * When invisible, will take up space on the screen
 * (so the page doesn't jump when it becomes visible)
 */
export function FadeInComponent({
  children,
  visible,
  delayInSeconds,
  ...props
}: FadeInComponentProps): JSX.Element {
  return (
    <div
      css={css`
        ${visible
          ? css`
              opacity: 1;
              transition: opacity ${delayInSeconds}s;
            `
          : css`
              opacity: 0;
            `}
      `}
      {...props}
    >
      {children}
    </div>
  );
}
