import { uniq } from "lodash";

import {
  GetSharableJobPostingFilterCombination,
  ListShareableJobPostingsFilters,
} from "@rewards-web/shared/graphql-types";

import { JobFilterDialogFormValues } from "./types";

export function deduplicateAndSortStrings(strings: string[]): string[] {
  return uniq(strings).sort();
}

export function groupCombinationsByKey(
  combinations: Pick<
    GetSharableJobPostingFilterCombination,
    "title" | "geography"
  >[],
  key: "title" | "geography"
) {
  return combinations.reduce<
    Map<
      string,
      Pick<GetSharableJobPostingFilterCombination, "title" | "geography">[]
    >
  >(
    (prev, combination) =>
      prev.set(
        combination[key],
        (prev.get(combination[key]) ?? []).concat(combination)
      ),
    new Map()
  );
}

export function serializeSelectedJobFilters(
  formValues: JobFilterDialogFormValues
): ListShareableJobPostingsFilters {
  const selectedFormValueKeys = Object.keys(formValues).filter(
    (key) => formValues[key as keyof typeof formValues]
  );

  const [selectedTitles, selectedGeographies] = selectedFormValueKeys.reduce<
    [Set<string>, Set<string>]
  >(
    ([prevSelectedTitles, prevSelectedGeographies], key) => {
      if (key.startsWith("title:")) {
        return [
          prevSelectedTitles.add(key.split("title:")[1]),
          prevSelectedGeographies,
        ];
      }

      return [
        prevSelectedTitles,
        prevSelectedGeographies.add(key.split("geography:")[1]),
      ];
    },
    [new Set(), new Set()]
  );

  return {
    titleIn: selectedTitles.size > 0 ? Array.from(selectedTitles) : undefined,
    geographyIn:
      selectedGeographies.size > 0
        ? Array.from(selectedGeographies)
        : undefined,
  };
}
