/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { Fragment } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { ListShareableJobPostingsFilters } from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { SharableJobPostingsQueryResult } from "../sharable-job-postings.generated";
import { BranchJobsHeaderIcon } from "./branch-jobs-header-icon";
import { useInfinitelyScrolledJobData } from "./hooks";
import { JobCard } from "./job-card";
import { getJobsGroupedByTitle } from "./lib";
import { PromotedJobsHeaderIcon } from "./promoted-jobs-header-icon";

interface JobListProps {
  query: SharableJobPostingsQueryResult;
  numJobsMatchingFilters: number | undefined;
  selectedFilters: ListShareableJobPostingsFilters;
  perPage: number;
  searchQuery: string;
}

export function JobList({
  query,
  numJobsMatchingFilters,
  selectedFilters,
  perPage,
}: JobListProps): JSX.Element {
  const { formatMessage } = useFormatters();
  const theme = useTheme();
  const stackedButtons = useMediaQuery("(max-width: 350px)");
  const twoColumns = useMediaQuery(theme.breakpoints.up("sm"));

  const itemHeight = stackedButtons ? 248 : 195;

  const { data, loading, networkStatus, fetchMore, error } = query;

  const paginatedData = useInfinitelyScrolledJobData({
    data,
    networkStatus,
    perPage,
    fetchMore,
    itemHeight,
    twoColumns,
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Share jobs page > data load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (loading && !data) {
    return <PageLoadingState />;
  }

  if (data!.listShareableJobPostings?.total === 0) {
    return (
      <Alert
        severity="info"
        message={
          Object.values(selectedFilters).some(
            (filterSet) => Array.isArray(filterSet) && filterSet.length > 0
          )
            ? formatMessage({
                description: "Share jobs page > search results empty",
                defaultMessage: "There are no matches for your search.",
              })
            : formatMessage({
                description: "Share jobs page > no open job postings",
                defaultMessage: "There are no job listings. Check back later!",
              })
        }
      />
    );
  }

  return (
    <>
      {getJobsGroupedByTitle(paginatedData).map(({ header, items }, index) => {
        return (
          <Fragment key={header?.key ?? `no-header-${index}`}>
            {index > 0 && (
              <Divider
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(4)};
                `}
              />
            )}
            {header && (
              <Typography
                css={css`
                  font-weight: 300;
                  font-size: 1.2em;
                `}
                color="textPrimary"
                variant="footnote"
              >
                {header.type === "promoted" ? (
                  <PromotedJobsHeaderIcon
                    css={css`
                      margin-bottom: -4px;
                      margin-right: 8px;
                    `}
                  />
                ) : (
                  <BranchJobsHeaderIcon
                    css={css`
                      margin-bottom: -2px;
                      margin-right: 8px;
                    `}
                  />
                )}{" "}
                {header.text}
              </Typography>
            )}
            <ul
              css={(theme: AppTheme) => css`
                padding: 0; // list style reset

                ${twoColumns &&
                css`
                  // split into grid of two columns for tablet and larger
                  display: grid;
                  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                  column-gap: ${theme.spacing(2)};
                `}
              `}
            >
              {items.map((item, index) => (
                <li
                  key={item?.id ?? `empty-${index}`}
                  css={(theme: AppTheme) => css`
                    height: ${itemHeight}px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: ${theme.spacing(2)};
                  `}
                >
                  <JobCard
                    stackedButtons={stackedButtons}
                    numJobsMatchingFilters={numJobsMatchingFilters}
                    selectedFilters={selectedFilters}
                    data={
                      item
                        ? {
                            id: item.id,
                            jobTitle: item.title,
                            ratesOfPay: item.ratesOfPay,
                            geography: item.geography,
                            hoursRequired: item.hoursRequired,
                            activePromotion: item.activePromotion ?? null,
                            branchName: item.organization.branchName,
                            organizationId: item.organization.id,
                            maxPointsEarnedPerCandidate:
                              item.organization.maxPointsEarnedPerCandidate,
                            pointsPerDollar: item.organization.pointsPerDollar,
                          }
                        : undefined
                    }
                  />
                </li>
              ))}
            </ul>
          </Fragment>
        );
      })}
    </>
  );
}
