/** @jsxImportSource @emotion/react */

export function InfoIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.10718 0.802979C10.9626 0.802979 14.1072 3.94751 14.1072 7.80298C14.1072 11.6858 10.9626 14.803 7.10718 14.803C3.22437 14.803 0.107178 11.6858 0.107178 7.80298C0.107178 3.94751 3.22437 0.802979 7.10718 0.802979ZM7.10718 13.4905C10.2244 13.4905 12.7947 10.9475 12.7947 7.80298C12.7947 4.68579 10.2244 2.11548 7.10718 2.11548C3.96265 2.11548 1.41968 4.68579 1.41968 7.80298C1.41968 10.9475 3.96265 13.4905 7.10718 13.4905ZM8.20093 9.99048C8.5564 9.99048 8.85718 10.2913 8.85718 10.6467C8.85718 11.0295 8.5564 11.303 8.20093 11.303H6.01343C5.63062 11.303 5.35718 11.0295 5.35718 10.6467C5.35718 10.2913 5.63062 9.99048 6.01343 9.99048H6.45093V8.24048H6.23218C5.84937 8.24048 5.57593 7.96704 5.57593 7.58423C5.57593 7.22876 5.84937 6.92798 6.23218 6.92798H7.10718C7.46265 6.92798 7.76343 7.22876 7.76343 7.58423V9.99048H8.20093ZM7.10718 6.05298C6.61499 6.05298 6.23218 5.67017 6.23218 5.17798C6.23218 4.71313 6.61499 4.30298 7.10718 4.30298C7.57202 4.30298 7.98218 4.71313 7.98218 5.17798C7.98218 5.67017 7.57202 6.05298 7.10718 6.05298Z"
        fill="#636666"
      />
    </svg>
  );
}
