/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme, AppThemeProvider } from "@rewards-web/shared/style/theme";

import caribouLogoUrl from "../../../shared/caribou-logo.png";
import { HeaderLogo } from "../../../shared/header-logo";
import { useAuth } from "../../../shared/modules/auth";
import {
  RewardsAppAuthError,
  RewardsAppAuthErrorCode,
} from "../../../shared/modules/auth/errors";
import { UnauthenticatedPageLayout } from "../../../shared/unauthenticated-page-layout";
import { VerificationCodeForm } from "../../../shared/verification-code-form";
import { LanguagePage } from "../../authenticated/language";
import { useLanguageSelector } from "../hooks/use-language-selector";
import { UnauthenticatedLanguageSelector } from "../language-selector";
import { ReauthPrivacyPolicy } from "../re-auth/re-auth-privacy-policy";
import { useLoginPageDataForOrganizationQuery } from "./login-page-data-for-organization.generated";
import { LoginPhoneNumberForm } from "./phone-number-form";
import { PhoneNumberRetry } from "./phone-number-retry";

const CONTACT_EMAIL = "help@caribou.care";

export function LoginPage(): JSX.Element {
  const {
    localeName,
    setHasUserUpdatedLanguageForLogin,
  } = useLanguageSelector();
  const { formatMessage } = useFormatters();
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [error, setError] = useState<{
    message: string;
    severity: "error" | "warning";
  } | null>(null);
  const {
    signInWithPhoneVerification,
    submitPhoneVerificationCode,
  } = useAuth();
  const [rawOrganizationId] = useQueryParam("org");
  /**
   * Is only populated if a valid int has been passed into the query param
   */
  const parsedOrganizationId =
    rawOrganizationId !== null && !isNaN(parseInt(rawOrganizationId))
      ? rawOrganizationId
      : null;

  const dataForOrganization = useLoginPageDataForOrganizationQuery({
    skip: parsedOrganizationId === null,
    onError: reportError,
    variables: { organizationId: parsedOrganizationId! },
  });

  const [verifyingPhoneNumber, setVerifyingPhoneNumber] = useState(false);
  const [retryingPhoneNumber, setRetryingPhoneNumber] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(null);
  const snackbar = useSnackbar();

  // it's possible that a user is logged out because of a bug.
  // if this happens, the query parameters `unexpected_logout` is set,
  // indicating that we should show a message to the user
  const [searchParams] = useSearchParams();
  const track = useTrack();
  const unexpectedLogoutError = searchParams.has("unexpected_logout");
  useEffect(() => {
    if (unexpectedLogoutError) {
      track("Unexpected logout message shown to user");
    }
  }, [unexpectedLogoutError, track]);

  if (dataForOrganization.loading && !dataForOrganization.data) {
    return <PageLoadingState />;
  }

  const whiteLabelConfig =
    dataForOrganization.data?.organization?.whiteLabelConfig;

  const onPhoneNumberRetry = () => {
    setRetryingPhoneNumber(false);
  };

  const onSubmitPhoneNumber = async (phoneNumber: string) => {
    try {
      setUserPhoneNumber(phoneNumber);
      await signInWithPhoneVerification({
        phoneNumber: serializePhoneNumber(phoneNumber),
        organizationId: parsedOrganizationId,
      });
      if (verifyingPhoneNumber) {
        snackbar.show({
          severity: "success",
          message: formatMessage({
            description: "Login page > resend code success snackbar",
            defaultMessage: "A new code has been sent",
          }),
        });
      }

      setVerifyingPhoneNumber(true);
      setError(null);
    } catch (error) {
      if (
        error instanceof RewardsAppAuthError &&
        error.code === RewardsAppAuthErrorCode.DEACTIVATED
      ) {
        setError({
          severity: "error",
          message: formatMessage(
            {
              description: "Login page > deactivated user login error",
              defaultMessage:
                "Your account has been de-activated, contact your manager or {contact_email}",
            },
            {
              contact_email: CONTACT_EMAIL,
            }
          ),
        });
        reportError(error);
      } else {
        setError({
          severity: "error",
          message: formatMessage({
            description: "Login page > unexpected error occurred logging in",
            defaultMessage:
              "An unexpected error occurred while trying to sign in. Please try again.",
          }),
        });
        reportError(error);
        track("SMS login failed", {
          phoneNumber,
          reason: "unknown",
        });
      }
    }
  };

  const onSubmitVerificationCode = async (code: string) => {
    try {
      await submitPhoneVerificationCode(code);
      setError(null);
      track("SMS login succeeded");
    } catch (error) {
      if (
        error instanceof RewardsAppAuthError &&
        error.code === RewardsAppAuthErrorCode.INVALID_VERIFICATION_CODE
      ) {
        setError({
          severity: "warning",
          message: formatMessage({
            description: "Login page > invalid login code entered",
            defaultMessage:
              "The entered code is not correct. Please enter your phone number to try again.",
          }),
        });
      } else if (
        error instanceof RewardsAppAuthError &&
        error.code === RewardsAppAuthErrorCode.CHALLENGE_EXPIRED
      ) {
        setError({
          severity: "warning",
          message: formatMessage({
            description: "Login page > login code expired",
            defaultMessage:
              "The verification code has expired. Please enter your phone number to try again.",
          }),
        });
      } else {
        setError({
          severity: "error",
          message: formatMessage({
            description:
              "Login page > unexpected error occurred verifying code",
            defaultMessage:
              "An unexpected error occurred while trying to sign in. Please try again.",
          }),
        });
        reportError(error);
      }
      setVerifyingPhoneNumber(false);
    }
  };

  const title = (() => {
    if (retryingPhoneNumber) {
      return formatMessage({
        description: "Login page > invalid phone number title",
        defaultMessage: "Hmmm...",
      });
    }

    if (verifyingPhoneNumber) {
      return formatMessage({
        description: "Login page > enter verification code title",
        defaultMessage: "Enter code",
      });
    }

    return formatMessage({
      description: "Login page > welcome title",
      defaultMessage: "Welcome!",
    });
  })();

  const description = (() => {
    if (retryingPhoneNumber) {
      return formatMessage(
        {
          description: "Login page > invalid phone number detail",
          defaultMessage: "We don't have {phone_number} in our records.",
        },
        { phone_number: formatPhoneNumber(userPhoneNumber!) }
      );
    }

    if (verifyingPhoneNumber) {
      return formatMessage(
        {
          description: "Login page > enter verification code detail",
          defaultMessage: "A code was texted to {obfuscated_phone_number}",
        },
        {
          obfuscated_phone_number: `+1 (***) ***-${userPhoneNumber?.slice(-4)}`,
        }
      );
    }

    return formatMessage({
      description: "Login page > welcome back detail",
      defaultMessage: "Login to view your rewards with your agency.",
    });
  })();

  const content = (() => {
    if (retryingPhoneNumber) {
      return <PhoneNumberRetry onRetry={onPhoneNumberRetry} />;
    }

    if (verifyingPhoneNumber) {
      return (
        <VerificationCodeForm
          onResend={() =>
            userPhoneNumber && onSubmitPhoneNumber(userPhoneNumber)
          }
          onSubmit={onSubmitVerificationCode}
        />
      );
    }

    return <LoginPhoneNumberForm onSubmit={onSubmitPhoneNumber} />;
  })();

  return (
    <AppThemeProvider
      theme={
        whiteLabelConfig?.primaryColor && whiteLabelConfig?.secondaryColor
          ? {
              palette: {
                primary: {
                  main: whiteLabelConfig.primaryColor,
                },
                secondary: {
                  main: whiteLabelConfig.secondaryColor,
                },
              },
            }
          : {}
      }
    >
      {showLanguageSelector && (
        <LanguagePage
          pageType={{
            type: "fullscreen_modal",
            onBackClicked: () => {
              setShowLanguageSelector(false);
            },
            onLocaleUpdated: () => {
              setHasUserUpdatedLanguageForLogin(true);
              setShowLanguageSelector(false);
            },
          }}
        />
      )}

      {!showLanguageSelector && (
        <UnauthenticatedPageLayout>
          {unexpectedLogoutError && (
            <Alert
              severity="warning"
              message={formatMessage({
                description: "Login page > unexpected logout error message",
                defaultMessage:
                  "There was a problem with your session. Please enter your phone number or click your login link to log back in.",
              })}
              css={css`
                margin-bottom: 10%;
              `}
            />
          )}
          <div
            css={(appTheme: AppTheme) => css`
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0 ${appTheme.spacing(1)};
              justify-content: space-between;
              height: 100%;
            `}
          >
            <div
              css={(appTheme: AppTheme) => css`
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                max-width: 382px;
                flex: 1;
                ${appTheme.breakpoints.up("md")} {
                  justify-content: center;
                }
              `}
            >
              <HeaderLogo
                organizationLogoImageUrl={
                  dataForOrganization.data?.organization?.logoImageUrl ?? null
                }
                whiteLabelConfig={whiteLabelConfig}
              />
              <Typography
                variant="h1"
                color="primary"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                {title}
              </Typography>
              <Typography
                variant="body"
                color="textPrimary"
                css={(appTheme: AppTheme) => css`
                  text-align: center;
                  margin-bottom: ${appTheme.spacing(3.5)};
                  ${appTheme.breakpoints.up("md")} {
                    margin-bottom: ${appTheme.spacing(5)};
                  }
                `}
              >
                {description}
              </Typography>

              {error && (
                <Alert
                  severity={error.severity}
                  message={error.message}
                  css={(theme: AppTheme) => css`
                    margin-bottom: ${theme.spacing(2)};
                  `}
                />
              )}
              {content}
            </div>
            <div
              css={(theme: AppTheme) => css`
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: ${theme.spacing(2)};
                ${theme.breakpoints.up("md")} {
                  margin: ${theme.spacing(5)} 0 ${theme.spacing(2)};
                }
              `}
            >
              <div
                css={(appTheme: AppTheme) => css`
                  display: flex;
                  align-items: end;
                  margin-bottom: ${appTheme.spacing(2.5)};
                `}
              >
                <Typography
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  {formatMessage({
                    description: "Login page > powered by",
                    defaultMessage: "Powered by",
                  })}
                </Typography>
                <img
                  src={caribouLogoUrl}
                  alt={formatMessage({
                    description: "Login page > caribou logo image alt",
                    defaultMessage: "Caribou Logo",
                  })}
                  css={(appTheme: AppTheme) => css`
                    height: 22px;
                    margin-left: ${appTheme.spacing(1)};
                  `}
                />
              </div>
              <ReauthPrivacyPolicy />
            </div>

            <Divider
              css={css`
                width: 90%;
                margin-left: 2.5%;
              `}
            />

            <UnauthenticatedLanguageSelector
              localeName={localeName}
              setShowLanguageSelector={setShowLanguageSelector}
            />
          </div>
        </UnauthenticatedPageLayout>
      )}
    </AppThemeProvider>
  );
}
