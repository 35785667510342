import { useEffect } from "react";

export function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // support pages that use the navigation container for scrolling,
    // instead of the full document
    document.getElementById("navigation-scroll-container")?.scrollTo(0, 0);
  }, []);

  return null;
}
