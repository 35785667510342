/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import {
  Confetti,
  DEFAULT_CONFETTI_DURATION_MS,
} from "@rewards-web/shared/components/confetti";
import { ProgressSpinner } from "@rewards-web/shared/components/progress-spinner";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../shared/page-content-container";
import { COUNT_UPANIMATION_DURATION_IN_MS } from "../points-progress/points-count-up";
import { useRewardsDataQuery } from "../query.generated";
import { RedemptionsEmptyState } from "./empty-state";
import { usePastRedemptionsDataQuery } from "./query.generated";
import { RedemptionCard } from "./redemption-card";
import {
  ResendGiftCardLinkDrawer,
  ResendGiftCardLinkDrawerVariantType,
} from "./resend-gift-card-link-drawer";
import { PastRedemptionsTitle } from "./title";

export function PastRedemptions() {
  const [
    isResendGiftCardLinkDrawerOpen,
    setIsResendGiftCardLinkDrawerOpen,
  ] = useState(false);
  const [resendGiftCardTimer, setResendGiftCardTimer] = useState(0);
  const [
    resendGiftCardLinkDrawerVariant,
    setResendGiftCardLinkDrawerVariant,
  ] = useState<ResendGiftCardLinkDrawerVariantType>("ready_to_resend");
  const { formatMessage } = useFormatters();

  const resendGiftCardLinkEnabled = useFeatureFlag(
    "rewards-app-resend-gift-card-link-enabled-temp"
  );
  const location = useLocation();
  const navigate = useNavigate();
  // Check if the user came from the redeem Modal
  const justRedeemed = location.state?.justRedeemed || false;
  const [isShowingConfetti, setIsShowingConfetti] = useState(false);
  const confettiTimerRef = useRef<NodeJS.Timeout | null>(null);
  const onRedeemPointsTimerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (justRedeemed) {
      confettiTimerRef.current = setTimeout(() => {
        setIsShowingConfetti(true);
        // Clear the justRedeemed state from the location
        navigate(location.pathname, { replace: true, state: {} });
      }, COUNT_UPANIMATION_DURATION_IN_MS);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [justRedeemed, location, navigate]);

  useEffect(() => {
    const confettiTimer = confettiTimerRef.current;
    const redeemPointsTimer = onRedeemPointsTimerRef.current;

    return () => {
      if (confettiTimer) {
        clearTimeout(confettiTimer);
      }
      if (redeemPointsTimer) {
        clearTimeout(redeemPointsTimer);
      }
    };
  }, []);

  useEffect(() => {
    if (isShowingConfetti) {
      const timer = setTimeout(
        () => setIsShowingConfetti(false),
        DEFAULT_CONFETTI_DURATION_MS + 1500
      );
      return () => clearTimeout(timer);
    }
  }, [isShowingConfetti]);
  const pageDataQuery = useRewardsDataQuery({ fetchPolicy: "cache-first" });

  const { data, error, loading } = usePastRedemptionsDataQuery();
  if (pageDataQuery.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Points summary page > data load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Past redemptions > data load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (loading || pageDataQuery.loading) {
    return (
      <PageContentContainer
        css={(appTheme: AppTheme) =>
          css`
            padding: 5px;
            padding-top: ${appTheme.spacing(3.75)};
            background-color: ${appTheme.palette.background.paper};
          `
        }
      >
        <div
          css={(appTheme: AppTheme) => css`
            padding-top: ${appTheme.spacing(3.75)};
          `}
        >
          <PastRedemptionsTitle />
          <div
            css={(appTheme: AppTheme) => css`
              padding-top: ${appTheme.spacing(4)};
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <ProgressSpinner />
          </div>
        </div>
      </PageContentContainer>
    );
  }
  const userData = pageDataQuery.data!.getMyRewardsUser;
  const redemptions = data?.getMyRewardsUserRedemptions.items;

  const isGiftCardRemption =
    data?.getMyRewardsOrganization.allowedRedemptionMethods[0] ===
    RedemptionMethod.GenericGiftCardLink;

  const linkNode = (node: ReactNode[]) => (
    <a
      css={(appTheme: AppTheme) =>
        css`
          color: ${appTheme.palette.primary.main};
          font-family: ${appTheme.typography.subtitle.fontFamily};
          font-size: ${appTheme.typography.subtitle.fontSize};
          line-height: ${appTheme.typography.subtitle.lineHeight};
          font-weight: ${appTheme.typography.subtitle.fontWeight};
        `
      }
      href={`mailto:${encodeURI("help@caribou.care")}`}
    >
      {node}
    </a>
  );

  return (
    <PageContentContainer
      css={(appTheme: AppTheme) =>
        css`
          padding: 5px;
          padding-top: ${appTheme.spacing(3.75)};
          background-color: ${appTheme.palette.background.paper};
        `
      }
    >
      <Confetti active={isShowingConfetti} />
      <div
        css={(appTheme: AppTheme) => css`
          padding-top: ${appTheme.spacing(3.75)};
        `}
      >
        <div
          css={(appTheme: AppTheme) =>
            css`
              padding-bottom: ${appTheme.spacing(6)};
            `
          }
        >
          <PastRedemptionsTitle />
          {redemptions?.length ? (
            <>
              <Typography
                css={(appTheme: AppTheme) =>
                  css`
                    margin-top: ${appTheme.spacing(2.5)};
                  `
                }
                variant="body"
                color="textPrimary"
              >
                {isGiftCardRemption
                  ? formatMessage({
                      defaultMessage:
                        "For each redemption, a link to choose your gift card is sent to your email. It may take up to 3 business days.",
                      description: "Past redemptions > gift card subtitle",
                    })
                  : formatMessage({
                      defaultMessage:
                        "Redemptions may take up to 3 weeks to appear on your next paycheck.",
                      description: "Past redemptions > paycheck subtitle",
                    })}
              </Typography>

              <div
                css={(appTheme: AppTheme) =>
                  css`
                    padding: ${appTheme.spacing(3)} 0;
                  `
                }
              >
                {redemptions?.map((redemption) => (
                  <RedemptionCard
                    key={redemption.id}
                    redemption={redemption}
                    setIsResendGiftCardLinkDrawerOpen={
                      setIsResendGiftCardLinkDrawerOpen
                    }
                    resendGiftCardTimer={resendGiftCardTimer}
                    setResendGiftCardTimer={setResendGiftCardTimer}
                    setResendGiftCardLinkDrawerVariant={
                      setResendGiftCardLinkDrawerVariant
                    }
                    email={userData.email}
                  />
                ))}
              </div>
              <div
                css={(appTheme: AppTheme) =>
                  css`
                    border: 2px solid ${appTheme.palette.grey[200]};
                    border-radius: 10px;
                    padding: ${appTheme.spacing(1.75)};
                    text-align: center;
                  `
                }
              >
                <Typography variant="subtitle">
                  <FontAwesomeIcon
                    css={(appTheme: AppTheme) =>
                      css`
                        margin-right: ${appTheme.spacing(1)};
                      `
                    }
                    icon={faEnvelope}
                  />
                  {formatMessage({
                    defaultMessage: "Need help?",
                    description: "Past redemptions > help title",
                  })}
                </Typography>
                <Typography
                  css={(appTheme: AppTheme) =>
                    css`
                      margin-top: ${appTheme.spacing(1)};
                    `
                  }
                  variant="body"
                  color="textPrimary"
                >
                  {resendGiftCardLinkEnabled && isGiftCardRemption
                    ? formatMessage(
                        {
                          defaultMessage:
                            "Resend your gift card link above or email <a>help@caribou.care</a> for assistance.",
                          description: "Past redemptions > gift card help",
                        },
                        {
                          a: linkNode,
                        }
                      )
                    : formatMessage(
                        {
                          defaultMessage:
                            "Email <a>help@caribou.care</a> for assistance.",
                          description: "Past redemptions > paycheck help",
                        },
                        {
                          a: linkNode,
                        }
                      )}
                </Typography>
              </div>
            </>
          ) : (
            <RedemptionsEmptyState />
          )}

          {isGiftCardRemption && (
            <ResendGiftCardLinkDrawer
              open={isResendGiftCardLinkDrawerOpen}
              variant={resendGiftCardLinkDrawerVariant}
              resendGiftCardTimer={resendGiftCardTimer}
              onClose={() => setIsResendGiftCardLinkDrawerOpen(false)}
              email={userData.email}
            />
          )}
        </div>
      </div>
    </PageContentContainer>
  );
}
