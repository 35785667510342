/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  DrawPrizeStructureTieredPrizesMultipleWinners,
  DrawStatus,
  DrawTieredPrizePoints,
  DrawWinnerClaimedPrize,
} from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { MOBILE_TAB_NAVIGATION_HEIGHT } from "../navigation-container";
import { useClaimDrawPrizeMutation } from "./claim-draw-prize.generated";
import { DrawPrizeClaimedModal } from "./draw-prize-claimed-modal";
import { useGetDrawWinnersQuery } from "./draw-winners-page-data.generated";
import { FadeInComponent } from "./fade-in-component";
import profileImage from "./icons/profile.png";
import starImage from "./icons/star.png";
import { isIndex } from "./lib";
import { useMarkDrawWinnersAsViewedMutation } from "./mark-draw-winners-as-viewed.generated";
import animationUrl from "./videos/DrawWinnersAnimation.mp4";

const FADE_IN_DELAY_SECONDS = {
  header: 0.5,
  subheader: 1,
  myWinnerDetails: 0.5,
  otherWinners: 2,
  cta: 3,
};

const ANIMATION_BOX_OPENED_DURATION_SECONDS = 2;

export function DrawWinnersPage() {
  const { formatMessage } = useFormatters();
  const { draw_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const track = useTrack();
  const snackbar = useSnackbar();
  const mobileOrTablet = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("md")
  );
  const [showComponents, setShowComponents] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(true);
  const [loadingLabel, setLoadingLabel] = useState(
    formatMessage({
      defaultMessage: "Loading winners...",
      description: "Draw Winners page > loading message",
    })
  );
  const [claimedPrizeModalOpen, setClaimedPrizeModalOpen] = useState(false);

  useEffect(() => {
    if (draw_id) {
      track("Landed on draw winners page", { drawId: draw_id });
    }
  }, [track, draw_id]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (loadingMedia) {
        setLoadingLabel(
          formatMessage({
            defaultMessage: "This may take a moment...",
            description: "Draw Winners page > loading message > slow loading",
          })
        );
        track("Slow loading animation on draw winners page", {
          drawId: draw_id,
        });
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [formatMessage, loadingMedia, draw_id, track]);

  const pageDataQuery = useGetDrawWinnersQuery({
    variables: { drawId: draw_id! },
    onError: reportError,
    fetchPolicy: "no-cache",
  });

  const [markWinnersAsViewed] = useMarkDrawWinnersAsViewedMutation();
  const [
    claimDrawPrize,
    { loading: claimingPrize },
  ] = useClaimDrawPrizeMutation();

  if (pageDataQuery.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Draw Winners page > error loading data",
          defaultMessage:
            "An unexpected error occurred. Please try again later.",
        })}
      />
    );
  }

  if (!pageDataQuery.data) {
    return <PageLoadingState label={loadingLabel} />;
  }

  if (!pageDataQuery.data.draw || !pageDataQuery.data.draw.winners) {
    return <Navigate to={state?.backTo ?? "/draw"} replace />;
  }

  const {
    draw: {
      id: drawId,
      winners,
      myRewardsUserHasTicketsInDraw,
      prizeStructure,
      scheduledStartDate,
      scheduledEndDate,
    },
    myRewardsUser,
    org: { pointsPerDollar },
    currentDraw,
  } = pageDataQuery.data;

  const myRewardsUserWinner = winners.find((winner) => winner.isMe);
  const iWon = !!myRewardsUserWinner;
  const myRewardsUserWinnerClaimedPrize =
    myRewardsUserWinner?.claimedPrize === DrawWinnerClaimedPrize.Yes;
  const otherWinners = iWon
    ? winners.filter((winner) => !winner.isMe)
    : winners;
  const getAllOtherTieredWinners = (
    prizeTierIndex?: number | undefined | null
  ) => {
    return iWon
      ? winners.filter(
          (winner) => !winner.isMe && winner.prizeTierIndex === prizeTierIndex
        )
      : winners.filter((winner) => winner.prizeTierIndex === prizeTierIndex);
  };

  const handlePointValue = (prizeTierIndex?: number | undefined | null) => {
    if (
      prizeStructure.__typename ===
        "DrawPrizeStructureSinglePrizeMultipleWinners" &&
      prizeStructure.prize.__typename === "DrawPrizePoints"
    ) {
      return prizeStructure.prize.pointValue;
    } else if (
      prizeStructure.__typename ===
        "DrawPrizeStructureTieredPrizesMultipleWinners" &&
      isIndex(prizeTierIndex) &&
      prizeStructure.prizes[prizeTierIndex!].__typename ===
        "DrawTieredPrizePoints"
    ) {
      /**
       * If I don't use the type assertion I get the following error:
       * Property 'pointValue' does not exist on type
       * '{ __typename: "DrawPrizePoints"; } | ({ __typename: "DrawTieredPrizePoints"; } &
       * Pick<DrawTieredPrizePoints, "numWinners" | "pointValue">)'.
       * Property 'pointValue' does not exist on type '{ __typename: "DrawPrizePoints"; }'
       */
      return (prizeStructure.prizes[prizeTierIndex!] as DrawTieredPrizePoints)
        .pointValue;
    }
    return 0;
  };
  const onAnimationProgress = ({
    playedSeconds,
  }: {
    playedSeconds: number;
  }) => {
    if (
      playedSeconds >= ANIMATION_BOX_OPENED_DURATION_SECONDS &&
      !showComponents
    ) {
      setShowComponents(true);
      track("Viewed draw winners list", { drawId, winner: iWon });
      markWinnersAsViewed({ variables: { drawId } }).catch(reportError);
    }
  };

  const onClaimPrize = async () => {
    track("Clicked claim prize button", { drawId });
    try {
      await claimDrawPrize({ variables: { drawId } });
      track("Successfully claimed prize", { drawId });
      setClaimedPrizeModalOpen(true);
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Draw Winners page > claim prize error snackbar",
          defaultMessage:
            "An unexpected error occurred while claiming your prize. Please try again later.",
        }),
      });
    }
  };

  const closeModal = () => {
    setClaimedPrizeModalOpen(false);
  };

  const buttonProps = (() => {
    if (iWon && !myRewardsUserWinnerClaimedPrize) {
      return {
        label: formatMessage({
          defaultMessage: "Claim your prize",
          description: "Draw Winners page > claim prize button",
        }),
        onClick: onClaimPrize,
        loading: claimingPrize,
      };
    } else if (currentDraw?.status === DrawStatus.Open) {
      return {
        label: formatMessage({
          defaultMessage: "See next active draw!",
          description: "Draw Winners page > see next active draw button",
        }),
        linkTo: "/draw/current-draw",
      };
    } else {
      return {
        label: formatMessage({
          defaultMessage: "Go to Draws",
          description: "Draw Winners page > go to draws button",
        }),
        linkTo: "/draw/past-draws",
      };
    }
  })();

  const dollarValue = (prizeTierIndex: number | undefined | null) => {
    return formatDollars(handlePointValue(prizeTierIndex) / pointsPerDollar);
  };

  const getBackgroundColor = (
    prizeTierIndex?: number | null
  ): string | undefined => {
    switch (prizeTierIndex) {
      case 0:
        return "#FFFAEA";
      case 1:
        return "#F7FAFF";
      case 2:
        return "#F8F0EA";
      default:
        return "none";
    }
  };
  function getPrizeTierNameForWinner(index: number) {
    switch (index) {
      case 0:
        return (
          <FormattedMessage
            defaultMessage="🏆 First Prize Winner"
            description="Draw Winners page > header > Winner of first prize winner"
          />
        );

      case 1:
        return (
          <FormattedMessage
            defaultMessage="🥈 Second Prize Winner"
            description="Draw Winners page > header > Winner of second prize winner"
          />
        );

      case 2:
        return (
          <FormattedMessage
            defaultMessage="🥉 Third Prize Winner"
            description="Draw Winners page > header > Winner of third prize winner"
          />
        );

      default:
        return (
          <FormattedMessage
            defaultMessage="🏆 First Prize Winner"
            description="Draw Winners page > header > Winner of first prize winner"
          />
        );
    }
  }
  function getPrizeTierName(index: number) {
    switch (index) {
      case 0:
        return (
          <FormattedMessage
            defaultMessage="🏆 First Prize {num_winners, plural, one {Winner} other {Winners}}"
            description="Draw Winners page > header > First Prize Winner"
            values={{
              num_winners: getAllOtherTieredWinners(index).length,
            }}
          />
        );
      case 1:
        return (
          <FormattedMessage
            defaultMessage="🥈 Second Prize {num_winners, plural, one {Winner} other {Winners}}"
            description="Draw Winners page > header > Second Prize Winner"
            values={{
              num_winners: getAllOtherTieredWinners(index).length,
            }}
          />
        );
      case 2:
        return (
          <FormattedMessage
            defaultMessage="Third Prize {num_winners, plural, one {Winner} other {Winners}}"
            description="Draw Winners page > header > Third Prize Winner"
            values={{
              num_winners: getAllOtherTieredWinners(index).length,
            }}
          />
        );
      default:
        return (
          <FormattedMessage
            defaultMessage="🏆 First Prize {num_winners, number} {num_winners, plural, one {Winner} other {Winners}}"
            description="Draw Winners page > header > First Prize Winner"
            values={{
              num_winners: getAllOtherTieredWinners(index).length,
            }}
          />
        );
    }
  }

  const otherWinnersContent = (() => {
    if (
      prizeStructure.__typename ===
      "DrawPrizeStructureSinglePrizeMultipleWinners"
    ) {
      return (
        <div
          css={(theme: AppTheme) => css`
            border-radius: 20px;
            border: 1px solid;
            border-color: ${theme.palette.grey[400]};
            padding: 20px;
            margin-bottom: ${theme.spacing(7.5)};
          `}
        >
          {otherWinners.map((winner, idx, { length }) => {
            return (
              <div key={idx}>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                  `}
                >
                  <div
                    css={css`
                      border-radius: 100px;
                      background: #f3f0ec;
                      width: 50px;
                      height: 50px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <img
                      src={profileImage}
                      alt={formatMessage({
                        defaultMessage: "Profile",
                        description: "Draw winners page > profile image alt",
                      })}
                    />
                  </div>
                  <Typography
                    variant="body"
                    color="textPrimary"
                    css={css`
                      flex: 1;
                      text-align: left;
                      margin: 0 10px;
                    `}
                  >
                    {winner.userDisplayName}
                  </Typography>
                  <>
                    <img
                      src={starImage}
                      alt={formatMessage({
                        defaultMessage: "Star",
                        description: "Draw winners page > star image alt",
                      })}
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                    />
                    <Typography variant="subtitle" color="textPrimary">
                      <FormattedNumber value={handlePointValue()} />
                    </Typography>
                  </>
                </div>
                {idx + 1 < length && (
                  <Divider
                    css={(theme: AppTheme) =>
                      css`
                        margin: ${theme.spacing(2.5)} 0;
                      `
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    } else if (
      prizeStructure.__typename ===
        "DrawPrizeStructureTieredPrizesMultipleWinners" &&
      prizeStructure.prizes[0].__typename === "DrawTieredPrizePoints"
    ) {
      return winnersforEachTier(
        prizeStructure as DrawPrizeStructureTieredPrizesMultipleWinners
      );
    }
  })();

  function winnersforEachTier(
    prizeStructure: DrawPrizeStructureTieredPrizesMultipleWinners
  ) {
    let results = [];
    for (let index = 0; index < prizeStructure.prizes.length; index++) {
      const isLastElement = index === prizeStructure.prizes.length - 1;
      if (index !== 2 && getAllOtherTieredWinners(index).length > 0) {
        results.push(
          <>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 5px;
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              <Typography variant="h5" color="textPrimary">
                {" "}
                {getPrizeTierName(index)}
              </Typography>
              <Typography
                variant="h5"
                css={(theme: AppTheme) => css`
                  color: ${theme.palette.grey[600]};
                `}
              >
                <FormattedMessage
                  defaultMessage=" ({num_winners})"
                  description="Draw Winners page > other winners subheader > winner"
                  values={{
                    num_winners: getAllOtherTieredWinners(index).length,
                  }}
                />
              </Typography>
            </div>
            <div
              css={(theme: AppTheme) => css`
                border-radius: 20px;
                padding: 20px;
                margin-bottom: ${isLastElement
                  ? theme.spacing(7.5)
                  : theme.spacing(3.5)};
                background-color: ${getBackgroundColor(index)};
              `}
            >
              {getAllOtherTieredWinners(index).map(
                (winner, idx, { length }) => {
                  return (
                    <div key={idx}>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                        `}
                      >
                        <div
                          css={css`
                            border-radius: 100px;
                            background: #f3f0ec;
                            width: 50px;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <img
                            src={profileImage}
                            alt={formatMessage({
                              defaultMessage: "Profile",
                              description:
                                "Draw winners page > profile image alt",
                            })}
                          />
                        </div>
                        <Typography
                          variant="body"
                          color="textPrimary"
                          css={css`
                            flex: 1;
                            text-align: left;
                            margin: 0 10px;
                          `}
                        >
                          {winner.userDisplayName}
                        </Typography>
                        <div
                          css={css`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <img
                            src={starImage}
                            alt={formatMessage({
                              defaultMessage: "Star",
                              description: "Draw winners page > star image alt",
                            })}
                            css={(theme: AppTheme) => css`
                              margin-right: ${theme.spacing(1)};
                              width: 17px;
                            `}
                          />
                          <Typography
                            variant="caption"
                            color="textPrimary"
                            css={(theme: AppTheme) => css`
                              margin-right: ${theme.spacing(1)};
                            `}
                          >
                            <FormattedNumber value={handlePointValue(index)} />
                          </Typography>
                          <Typography
                            variant="subtitle"
                            color={theme.palette.primary.main}
                          >
                            {" "}
                            ({dollarValue(index)}){" "}
                          </Typography>
                        </div>{" "}
                      </div>
                      {idx + 1 < length && (
                        <Divider
                          css={(theme: AppTheme) =>
                            css`
                              margin: ${theme.spacing(2.5)} 0;
                            `
                          }
                        />
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </>
        );
      } else if (index === 2 && getAllOtherTieredWinners(index).length > 0) {
        results.push(
          <>
            <Typography
              variant="subtitle"
              color="textPrimary"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {" "}
              {getPrizeTierName(index)}{" "}
            </Typography>
            <div
              css={(theme: AppTheme) => css`
                border-radius: 20px;
                padding: 20px;

                margin-bottom: ${theme.spacing(7.5)};

                background-color: ${getBackgroundColor(index)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                  `}
                >
                  <div
                    css={css`
                      border-radius: 100px;
                      background: #f3f0ec;
                      width: 50px;
                      height: 50px;
                      font-size: 35px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    🥉
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <Typography
                      variant="body"
                      color="textPrimary"
                      css={css`
                        flex: 1;
                        text-align: left;
                        margin: 0 10px;
                        font-weight: 600;
                      `}
                    >
                      {getAllOtherTieredWinners(index).length}{" "}
                      {getAllOtherTieredWinners(index).length > 1 ? (
                        <FormattedMessage
                          defaultMessage="people"
                          description="Draw Winners page > people"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="person"
                          description="Draw Winners page > person"
                        />
                      )}{" "}
                      <FormattedMessage
                        defaultMessage="won"
                        description="Draw Winners page > won"
                      />
                    </Typography>
                    <Typography
                      variant="body"
                      color="textPrimary"
                      css={css`
                        flex: 1;
                        text-align: left;
                        margin: 0 10px;
                      `}
                    >
                      <FormattedMessage
                        defaultMessage="Third prize"
                        description="Draw Winners page > the third prize"
                      />
                    </Typography>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <img
                    src={starImage}
                    alt={formatMessage({
                      defaultMessage: "Star",
                      description: "Draw winners page > star image alt",
                    })}
                    css={(theme: AppTheme) => css`
                      margin-right: ${theme.spacing(1)};
                      width: 17px;
                      height: 20px;
                    `}
                  />
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    css={(theme: AppTheme) => css`
                      margin-right: ${theme.spacing(1)};
                    `}
                  >
                    <FormattedNumber value={handlePointValue(index)} />
                  </Typography>
                  <Typography
                    variant="subtitle"
                    color={theme.palette.primary.main}
                  >
                    {" "}
                    ({dollarValue(index)}){" "}
                  </Typography>
                </div>{" "}
              </div>
            </div>
          </>
        );
      }
    }
    return results;
  }
  return (
    <>
      {loadingMedia && <PageLoadingState label={loadingLabel} />}
      <div
        css={css`
          height: 100%;
          overflow-y: scroll;
          background-color: white;
        `}
      >
        <ScrollToTopOnMount />
        <div
          css={(theme: AppTheme) => css`
            padding-bottom: ${theme.spacing(2)};
          `}
        >
          <PageContentContainer
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              padding-top: 0;
            `}
          >
            <IconButton
              onClick={() => navigate(state?.backTo ?? "/draw")}
              aria-label={"Close"}
              css={(theme: AppTheme) => css`
                align-self: flex-end;
                padding: ${theme.spacing(2)};
              `}
            >
              <CloseIcon
                css={(theme: AppTheme) => css`
                  color: ${theme.palette.text.primary};
                `}
              />
            </IconButton>
            <Typography variant="caption" color="textPrimary">
              {
                <FormattedDate
                  value={new Date(scheduledStartDate)}
                  month="short"
                  day="numeric"
                />
              }
              -
              {
                <FormattedDate
                  value={new Date(scheduledEndDate)}
                  month="short"
                  day="numeric"
                />
              }
            </Typography>
            <FadeInComponent
              visible={showComponents}
              delayInSeconds={FADE_IN_DELAY_SECONDS.header}
            >
              <Typography variant="h2" color="textPrimary">
                {iWon ? (
                  <>
                    <FormattedMessage
                      defaultMessage="Congratulations,"
                      description="Draw Winners page > header > winner line 1"
                    />
                    <br />
                    <FormattedMessage
                      defaultMessage="you won!"
                      description="Draw Winners page > header > winner line 2"
                    />
                  </>
                ) : (
                  <FormattedMessage
                    defaultMessage="Congratulations to the winner(s)!"
                    description="Draw Winners page > header > non winner"
                  />
                )}
              </Typography>
            </FadeInComponent>
            {!iWon && (
              <FadeInComponent
                visible={showComponents}
                delayInSeconds={FADE_IN_DELAY_SECONDS.subheader}
              >
                <Typography
                  variant="body"
                  color="textPrimary"
                  css={(theme: AppTheme) => css`
                    margin-top: ${theme.spacing(2.5)};
                  `}
                >
                  {myRewardsUserHasTicketsInDraw ? (
                    <FormattedMessage
                      defaultMessage="😔 This time your ticket wasn't the lucky one. Better luck next time! Thanks for playing."
                      description="Draw Winners page > non winner message"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="😔 You did not earn any tickets to win. See ways to participate next time in Draws!"
                      description="Draw Winners page > non participant message"
                    />
                  )}
                </Typography>
              </FadeInComponent>
            )}
            <ReactPlayer
              url={animationUrl}
              playing={true}
              loop={false}
              playsinline={true}
              muted
              height={280}
              width="auto"
              onProgress={onAnimationProgress}
              onReady={() => setLoadingMedia(false)}
              fileconfig={{ attributes: { preload: "auto" } }}
            />
            {iWon && (
              <FadeInComponent
                visible={showComponents}
                delayInSeconds={FADE_IN_DELAY_SECONDS.myWinnerDetails}
                css={(theme: AppTheme) => css`
                  width: 100%;
                  ${otherWinners.length === 0 &&
                  css`
                    margin-bottom: ${theme.spacing(7.5)};
                  `}
                `}
              >
                <div
                  css={css`
                    display: flex;
                    padding: 20px 60px;
                    margin-bottom: 10px;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;
                    flex-shrink: 0;
                    width: 100%;
                    border-radius: 20px;
                    padding: 20px;
                    background-color: ${getBackgroundColor(
                      myRewardsUserWinner.prizeTierIndex
                    )};
                  `}
                >
                  {isIndex(myRewardsUserWinner.prizeTierIndex) && (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      css={css`
                        text-transform: uppercase;
                      `}
                    >
                      {getPrizeTierNameForWinner(
                        myRewardsUserWinner.prizeTierIndex!
                      )}
                    </Typography>
                  )}
                  <Typography variant="h3" color="textPrimary">
                    {myRewardsUser.firstName} {myRewardsUser.lastName}
                  </Typography>

                  <Typography variant="h1" color={theme.palette.primary.main}>
                    {dollarValue(myRewardsUserWinner!.prizeTierIndex)}{" "}
                  </Typography>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                    `}
                  >
                    <img
                      src={starImage}
                      alt={formatMessage({
                        defaultMessage: "Star",
                        description: "Draw winners page > star image alt",
                      })}
                      css={css`
                        margin-right: 5px;
                        width: 17px;
                        align-self: center;
                      `}
                    />
                    <Typography
                      variant="subtitle"
                      color="textPrimary"
                      css={css`
                        font-wight: 600;
                      `}
                    >
                      <FormattedNumber
                        value={handlePointValue(
                          myRewardsUserWinner.prizeTierIndex
                        )}
                      />
                    </Typography>
                    <Typography
                      variant="subtitle"
                      color="textPrimary"
                      css={css`
                        align-self: end;
                        margin-left: 10px;
                        font-wight: 600;
                      `}
                    >
                      <FormattedMessage
                        defaultMessage="points"
                        description="Draw Winners page > points"
                      />
                    </Typography>
                  </div>
                </div>
              </FadeInComponent>
            )}
            {otherWinners.length > 0 && (
              <FadeInComponent
                visible={showComponents}
                delayInSeconds={FADE_IN_DELAY_SECONDS.otherWinners}
                css={css`
                  width: 100%;
                `}
              >
                <Typography
                  variant="caption"
                  color="textPrimary"
                  css={(theme: AppTheme) =>
                    css`
                      margin-bottom: ${theme.spacing(2)};
                    `
                  }
                >
                  {iWon ? (
                    <FormattedMessage
                      defaultMessage="All other winners"
                      description="Draw Winners page > other winners subheader > winner"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Lucky winner(s) from this draw"
                      description="Draw Winners page > other winners subheader > nonwinner"
                    />
                  )}
                </Typography>
                {otherWinnersContent}
              </FadeInComponent>
            )}
            <FadeInComponent
              visible={showComponents}
              delayInSeconds={FADE_IN_DELAY_SECONDS.cta}
              css={(theme: AppTheme) => css`
                position: fixed;
                width: calc(100% - ${theme.spacing(4)});
                z-index: 1;
                ${mobileOrTablet
                  ? css`
                      bottom: ${MOBILE_TAB_NAVIGATION_HEIGHT + 16}px;
                    `
                  : css`
                      bottom: ${theme.spacing(2)};
                    `}
              `}
            >
              <Button
                color="secondary"
                size="large"
                label={buttonProps.label}
                linkTo={buttonProps.linkTo}
                css={css`
                  width: 100%;
                  max-width: 345px;
                  margin-top: 50px;
                `}
                onClick={buttonProps.onClick}
                loading={buttonProps.loading}
              />
            </FadeInComponent>
          </PageContentContainer>
        </div>
        <DrawPrizeClaimedModal
          open={claimedPrizeModalOpen}
          onClose={closeModal}
          drawId={drawId}
          pointValue={handlePointValue(myRewardsUserWinner?.prizeTierIndex)}
        />
      </div>
    </>
  );
}
