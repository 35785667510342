/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faDollarCircle,
  faLocationDot,
  faChevronRight,
  faBriefcase,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ProgressBar } from "../../../../shared/progress-bar";
import { ShareDialog } from "../../../../shared/share-dialog";
import StarIcon from "../../../../shared/star-icon.png";
import TicketIcon from "../../../../shared/ticket-icon.png";
import { useSharableJobPostingsQuery } from "../../jobs/jobs-page/sharable-job-postings.generated";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import BriefcaseIcon from "./icons/briefcase.png";

export interface ShareJobsCardProps {
  numJobSharesThisMonth: number;
  maxJobSharesPerMonth: number;
  jobShareReward: RewardsPageDataQuery["getMyRewardsOrganization"]["referralRewardStructure"]["jobShareStructure"]["reward"];
}

export function ShareJobsCard({
  numJobSharesThisMonth,
  maxJobSharesPerMonth,
  jobShareReward,
}: ShareJobsCardProps) {
  const { formatMessage } = useFormatters();
  const { data } = useSharableJobPostingsQuery({
    variables: { limit: 1, offset: 0 },
    onError: reportError,
  });
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const job = data?.listShareableJobPostings?.items?.[0];
  const track = useTrack();

  const onScreen = useOnScreen(cardRef, { threshold: 0.3 });
  useEffect(() => {
    if (onScreen) {
      track("Viewed rewards page jobs card", {
        completed: numJobSharesThisMonth >= maxJobSharesPerMonth,
        numJobSharesThisMonth,
        maxJobSharesPerMonth,
        version: 2,
      });
    }
  }, [track, onScreen, numJobSharesThisMonth, maxJobSharesPerMonth]);

  if (data && !job) {
    return null;
  }

  const isDrawReward =
    jobShareReward.__typename ===
      "ReferralRewardStructureManualMonthlyRaffleReward" ||
    jobShareReward.__typename === "ReferralRewardStructureDrawTicketReward";

  const rewardText = (() => {
    switch (jobShareReward.__typename) {
      case "ReferralRewardStructurePointReward":
        if (jobShareReward.pointValue === 0) {
          // this could happen if the organization is on goals
          return null;
        }

        return formatMessage(
          {
            defaultMessage: "{points, number} points per share",
            description: "Rewards page > Share jobs card > points per share",
          },
          { points: jobShareReward.pointValue }
        );
      case "ReferralRewardStructureDrawTicketReward":
      case "ReferralRewardStructureManualMonthlyRaffleReward":
        return formatMessage(
          {
            defaultMessage:
              "{tickets} {tickets, plural, one {ticket} other {tickets}} per share",
            description: "Rewards page > Share jobs card > 1 ticket per share",
          },
          { tickets: jobShareReward.numTickets }
        );
      default:
        return null;
    }
  })();

  const referralStructureDisabled =
    jobShareReward.__typename === "ReferralRewardStructurePointReward" &&
    jobShareReward.pointValue === 0;

  return (
    <>
      <div
        ref={cardRef}
        css={(appTheme: AppTheme) => css`
          border-radius: 12px;
          border: 1px solid ${appTheme.palette.divider};
          margin-bottom: ${appTheme.spacing(5)};
          background-color: ${appTheme.palette.background.paper};
        `}
      >
        {!referralStructureDisabled && (
          <div
            css={(appTheme: AppTheme) =>
              css`
                padding: ${appTheme.spacing(2.5)};
                border-bottom: 1px solid ${appTheme.palette.divider};
                display: flex;
              `
            }
          >
            <img
              src={BriefcaseIcon}
              alt="briefcase icon"
              css={css`
                height: 40px;
              `}
            />
            <div
              css={(appTheme: AppTheme) => css`
                flex: 1;
                margin-left: ${appTheme.spacing(1.5)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <Typography variant="footnote">
                  {formatMessage({
                    defaultMessage: "Your monthly shares",
                    description:
                      "Rewards page > Share jobs card > Your monthly shares",
                  })}
                </Typography>
                <Typography
                  css={css`
                    white-space: nowrap;
                  `}
                  color="textPrimary"
                  variant="caption"
                >
                  {numJobSharesThisMonth} / {maxJobSharesPerMonth}
                </Typography>
              </div>
              <ProgressBar
                max={maxJobSharesPerMonth}
                value={numJobSharesThisMonth}
              />
            </div>
          </div>
        )}
        <div
          css={(appTheme: AppTheme) =>
            css`
              padding: ${appTheme.spacing(2.5)};
            `
          }
        >
          <Typography variant="subtitle">
            {job ? job?.title : <Skeleton width="50%" />}
          </Typography>
          {rewardText && (
            <div
              css={(appTheme: AppTheme) => css`
                margin-top: ${appTheme.spacing(0.75)};
                display: flex;
                align-items: center;
              `}
            >
              {job ? (
                <>
                  <img
                    src={isDrawReward ? TicketIcon : StarIcon}
                    alt={
                      isDrawReward
                        ? formatMessage({
                            defaultMessage: "ticket icon",
                            description:
                              "Rewards page > Share jobs card > ticket icon alt",
                          })
                        : formatMessage({
                            defaultMessage: "star icon",
                            description:
                              "Rewards page > Share jobs card > star icon alt",
                          })
                    }
                    css={(appTheme: AppTheme) => css`
                      width: 16px;
                      margin-right: ${appTheme.spacing(0.75)};
                    `}
                  />
                  <Typography variant="footnote">{rewardText}</Typography>
                </>
              ) : (
                <Skeleton width="30%" />
              )}
            </div>
          )}
          <div
            css={(appTheme: AppTheme) => css`
              margin-top: ${appTheme.spacing(0.75)};
              display: flex;
              align-items: center;
              font-size: 14px;
              color: ${appTheme.palette.text.primary};
              gap: ${appTheme.spacing(2)};
              ${appTheme.breakpoints.down(400)} {
                flex-direction: column;
                align-items: flex-start;
                gap: ${appTheme.spacing(0.75)};
              }
            `}
          >
            {job ? (
              <>
                {(!job || job?.ratesOfPay) && (
                  <div
                    css={(appTheme: AppTheme) => css`
                      display: flex;
                      align-items: center;
                      gap: ${appTheme.spacing(1.25)};
                    `}
                  >
                    <FontAwesomeIcon icon={faDollarCircle} />
                    <Typography variant="footnote">
                      {job?.ratesOfPay}
                    </Typography>
                  </div>
                )}
                <div
                  css={(appTheme: AppTheme) => css`
                    display: flex;
                    align-items: center;
                    gap: ${appTheme.spacing(1.25)};
                  `}
                >
                  <FontAwesomeIcon icon={faLocationDot} />
                  <Typography variant="footnote">{job?.geography}</Typography>
                </div>
                <div
                  css={(appTheme: AppTheme) => css`
                    display: flex;
                    align-items: center;
                    gap: ${appTheme.spacing(1.25)};
                  `}
                >
                  <FontAwesomeIcon icon={faBriefcase} />
                  <Typography variant="footnote">
                    {job?.hoursRequired}
                  </Typography>
                </div>
              </>
            ) : (
              <Skeleton width="50%" />
            )}
          </div>
          <div
            css={(appTheme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${appTheme.spacing(1.5)};
              margin-top: ${appTheme.spacing(3)};
              ${appTheme.breakpoints.down(400)} {
                flex-direction: column;
                align-items: flex-start;
              }
            `}
          >
            {job ? (
              <>
                <Button
                  label={formatMessage({
                    defaultMessage: "View details",
                    description:
                      "Rewards page > Share jobs card > View details button label",
                  })}
                  variant="outlined"
                  linkTo={`/jobs/${job.id}`}
                  onClick={() =>
                    track("Clicked rewards page jobs card view details", {
                      jobPostingId: job.id,
                      version: 2,
                    })
                  }
                />
                <Button
                  label={formatMessage({
                    defaultMessage: "Share job",
                    description:
                      "Rewards page > Share jobs card > Share job button label",
                  })}
                  color="secondary"
                  onClick={() => {
                    setIsShareDialogOpen(true);
                    track("Clicked rewards page jobs card share job", {
                      jobPostingId: job.id,
                      version: 2,
                    });
                  }}
                />
              </>
            ) : (
              <Skeleton width="100%" />
            )}
          </div>
        </div>
        <Link
          to="/jobs"
          css={(appTheme: AppTheme) =>
            css`
              padding: ${appTheme.spacing(2.5)};
              border-top: 1px solid ${appTheme.palette.divider};
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              text-decoration: none;
            `
          }
          onClick={() =>
            track("Clicked rewards page jobs card view all jobs", {
              version: 2,
            })
          }
        >
          <Typography variant="subtitle">
            {formatMessage({
              defaultMessage: "View all jobs",
              description: "Rewards page > Share jobs card > View all jobs",
            })}
          </Typography>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
      {job && (
        <ShareDialog
          jobPostingId={job.id}
          promotionId={job.activePromotion?.id ?? null}
          open={isShareDialogOpen}
          onClose={() => setIsShareDialogOpen((prev) => !prev)}
        />
      )}
    </>
  );
}
