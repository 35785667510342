/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useReducer } from "react";

import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { useAppTheme } from "@rewards-web/shared/style/theme";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useAuth } from "../../../../../../shared/modules/auth";
import { GiftCardRedeemDrawerConfirmNewEmail } from "./confirm-new-email-step";
import { GiftCardRedeemDrawerEmailWarning } from "./email-warning-step";
import { useGiftcardRedeemIdentityQuery } from "./giftcard-redeem-identity-query.generated";
import { GiftCardRedeemDrawerRedeemStep } from "./redeem-step";
import { GiftCardRedeemDrawerRedeemSuccess } from "./redeem-success-step";
import { GiftCardRedeemDrawerRequireEmailDisclaimerStep } from "./require-email-disclaimer-step";
import { GiftCardRedeemDrawerVerifySms } from "./verify-sms-step";

export interface GiftCardRedeemPointsDrawerProps {
  open: boolean;
  onClose: () => void;
  onRedeemed: () => void;
  email: string | undefined | null;
  phoneNumber: string | null | undefined;
  pointsAvailableToRedeem: number;
}

type Step =
  | "redeemStep"
  | "emailWarningStep"
  | "emailDisclaimerStep"
  | "verifySmsStep"
  | "confirmNewEmailStep"
  | "redeemSuccessStep";

type GiftcardRedeemState = {
  latestEmail: string;
  hasRedeemed: boolean;
  currentStep: Step;
};

type GiftcardRedeemAction =
  | { type: "redeemedPoints"; showUpdateEmailFlowFlag: boolean }
  | { type: "enterUpdateEmailFlow" }
  | { type: "verifiedSms" }
  | { type: "cancelVerifySms" }
  | { type: "cancelConfirmNewEmail" }
  | { type: "confirmNewEmail"; newEmail: string }
  | { type: "closedModal" };

const isEmailValid = (email: string) => !email.endsWith("@example.com");

export function GiftCardRedeemPointsDrawer({
  open,
  onClose,
  onRedeemed,
  email,
  phoneNumber,
  pointsAvailableToRedeem,
}: GiftCardRedeemPointsDrawerProps) {
  const { formatMessage } = useFormatters();
  const theme = useAppTheme();
  const snackbar = useSnackbar();
  const track = useTrack();
  const { getIsUserSmsVerified } = useAuth();

  const showUpdateEmailFlowFlag = useFeatureFlag(
    "rewards-app-gift-cards-redemption-update-email-temp"
  );

  const { data } = useGiftcardRedeemIdentityQuery({
    fetchPolicy: "cache-first",
  });
  const organizationId = data?.getMyRewardsOrganization.id;
  const userId = data?.getMyRewardsUser.id;
  const getIsCurrentUserSmsVerified = () =>
    userId ? getIsUserSmsVerified(userId) : false;

  /**
   * Returns the first step a user sees when they enter the update email flow.
   * They should skip the SMS verification step if they are already SMS verified.
   */
  const getEnterUpdateEmailFlowStep = (): Extract<
    Step,
    "confirmNewEmailStep" | "verifySmsStep"
  > =>
    getIsCurrentUserSmsVerified() ? "confirmNewEmailStep" : "verifySmsStep";

  /**
   * The update email flow business logic is to force a user to update their email if it is invalid.
   * However, this should only be enabled if the update email flow feature flag is enabled.
   */
  const getInitialStep = ({
    lastestEmail,
  }: {
    lastestEmail: string;
  }): Extract<Step, "emailDisclaimerStep" | "redeemStep"> => {
    const shouldForceUpdateSms =
      showUpdateEmailFlowFlag && !isEmailValid(lastestEmail);

    return shouldForceUpdateSms ? "emailDisclaimerStep" : "redeemStep";
  };

  const [state, dispatch] = useReducer(
    (
      state: GiftcardRedeemState,
      action: GiftcardRedeemAction
    ): GiftcardRedeemState => {
      switch (action.type) {
        case "redeemedPoints": {
          return {
            ...state,
            currentStep: (() => {
              if (!isEmailValid(state.latestEmail)) {
                return "emailWarningStep";
              }
              if (action.showUpdateEmailFlowFlag) {
                return "redeemSuccessStep";
              }
              return "redeemStep";
            })(),
            hasRedeemed: true,
          };
        }
        case "enterUpdateEmailFlow":
          return {
            ...state,
            currentStep: getEnterUpdateEmailFlowStep(),
          };
        case "verifiedSms":
          return { ...state, currentStep: "confirmNewEmailStep" };
        case "cancelVerifySms":
        case "cancelConfirmNewEmail":
          return { ...state, currentStep: "redeemStep" };
        case "confirmNewEmail":
          return {
            ...state,
            currentStep: "redeemStep",
            latestEmail: action.newEmail,
          };
        case "closedModal":
          return {
            ...state,
            currentStep: getInitialStep({
              lastestEmail: state.latestEmail,
            }),
            hasRedeemed: false,
          };
        default:
          shouldBeNever(action);
          return state;
      }
    },
    {
      currentStep: getInitialStep({
        lastestEmail: email ?? "",
      }),
      latestEmail: email ?? "",
      hasRedeemed: false,
    }
  );

  const closeModal = ({ hasRedeemed }: { hasRedeemed: boolean }) => {
    track("Closed giftcard redeem points drawer", { step: state.currentStep });
    if (hasRedeemed) {
      onRedeemed();
    }
    onClose();
    dispatch({ type: "closedModal" });
  };

  const content = (() => {
    switch (state.currentStep) {
      case "redeemStep":
        return (
          <GiftCardRedeemDrawerRedeemStep
            pointsAvailableToRedeem={pointsAvailableToRedeem}
            latestEmail={state.latestEmail}
            isLatestEmailValid={isEmailValid(state.latestEmail)}
            onRedeemedPoints={() => {
              if (!showUpdateEmailFlowFlag && isEmailValid(state.latestEmail)) {
                closeModal({ hasRedeemed: true });
              }
              dispatch({ type: "redeemedPoints", showUpdateEmailFlowFlag });
            }}
            onEnterUpdateEmailFlow={() =>
              dispatch({ type: "enterUpdateEmailFlow" })
            }
            onCancel={() => closeModal({ hasRedeemed: state.hasRedeemed })}
          />
        );
      case "emailWarningStep":
        return (
          <GiftCardRedeemDrawerEmailWarning
            onClose={() => closeModal({ hasRedeemed: state.hasRedeemed })}
            phoneNumber={phoneNumber}
          />
        );
      case "redeemSuccessStep":
        return (
          <GiftCardRedeemDrawerRedeemSuccess
            onClose={() => closeModal({ hasRedeemed: state.hasRedeemed })}
            email={state.latestEmail}
          />
        );
      case "emailDisclaimerStep":
        return (
          <GiftCardRedeemDrawerRequireEmailDisclaimerStep
            pointsAvailableToRedeem={pointsAvailableToRedeem}
            onEnterUpdateEmailFlow={() =>
              dispatch({ type: "enterUpdateEmailFlow" })
            }
            onCancel={() => closeModal({ hasRedeemed: state.hasRedeemed })}
          />
        );
      case "verifySmsStep":
        return (
          <GiftCardRedeemDrawerVerifySms
            phoneNumber={phoneNumber}
            onCancel={
              isEmailValid(state.latestEmail)
                ? () => dispatch({ type: "cancelVerifySms" })
                : null
            }
            organizationId={organizationId!}
            onSmsVerified={() => {
              snackbar.show({
                severity: "success",
                position: "top",
                message: formatMessage({
                  defaultMessage: "Verification successful",
                  description:
                    "Redeem points drawer > redeemStep > verify sms alert",
                }),
              });
              dispatch({ type: "verifiedSms" });
            }}
          />
        );
      case "confirmNewEmailStep":
        return (
          <GiftCardRedeemDrawerConfirmNewEmail
            userCurrentEmail={state.latestEmail}
            onCancel={
              isEmailValid(state.latestEmail)
                ? () => dispatch({ type: "cancelConfirmNewEmail" })
                : null
            }
            onConfirmNewEmail={(newEmail: string) => {
              snackbar.show({
                severity: "success",
                position: "top",
                message: formatMessage({
                  defaultMessage: "Updated email",
                  description:
                    "Redeem points drawer > redeemStep > updated email alert",
                }),
              });
              dispatch({ type: "confirmNewEmail", newEmail });
            }}
          />
        );
      default:
        shouldBeNever(state.currentStep);
        return null;
    }
  })();

  const title = (() => {
    switch (state.currentStep) {
      case "verifySmsStep":
        return formatMessage({
          description: "Redeem points drawer > verify sms > title",
          defaultMessage: "Verify it's you",
        });
      case "confirmNewEmailStep":
        return formatMessage({
          description: "Redeem points drawer > confirm new email > title",
          defaultMessage: "Change email",
        });
      case "redeemSuccessStep":
        return formatMessage({
          description: "Redeem points drawer > redeem success > title",
          defaultMessage: "Redemption success!",
        });
      default:
        return formatMessage({
          defaultMessage: "Redeem points",
          description: "Redeem points drawer > title",
        });
    }
  })();

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => closeModal({ hasRedeemed: state.hasRedeemed })}
      maxWidth="430px"
      paddingBottom={0}
      title={title}
      titleVariant="h5"
      backgroundColor={theme.palette.background.paper}
    >
      <div
        css={(appTheme: AppTheme) => css`
          padding: ${appTheme.spacing(2.5)};
          padding-top: 0;
        `}
      >
        {content}
      </div>
    </ResponsiveDialog>
  );
}
