/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { GoalType } from "@rewards-web/shared/graphql-types";

import booksUrl from "../graphics/books.png";
import calendarUrl from "../graphics/calendar.png";
import hhaPhoneUrl from "../graphics/hha-phone.png";
import shareJobMegaphoneUrl from "../graphics/share-job-megaphone.png";
import shiftClockUrl from "../graphics/shift-clock.png";
import surveyClipboardUrl from "../graphics/survey-clipboard.png";
import videoPlayButtonUrl from "../graphics/video-play-button.png";

export interface GoalIconProps {
  goalType: GoalType;
}

/**
 * Icon for goal type. Example usage includes goal cards and goal achievement modal.
 *
 * Note: images are 128px x 128px, dimensions should not exceed 64px;
 */
export function GoalIcon({ goalType }: GoalIconProps) {
  const { src, alt } = (() => {
    switch (goalType) {
      case GoalType.EvvStreak:
      case GoalType.HhaxLogin:
        return { src: hhaPhoneUrl, alt: "phone" };
      case GoalType.ShareJob:
        return { src: shareJobMegaphoneUrl, alt: "megaphone" };
      case GoalType.Survey:
        return { src: surveyClipboardUrl, alt: "clipboard" };
      case GoalType.WatchVideo:
        return { src: videoPlayButtonUrl, alt: "play button" };
      case GoalType.CompleteTraining:
        return { src: booksUrl, alt: "books" };
      case GoalType.LastMinuteShift:
        return { src: shiftClockUrl, alt: "clock" };
      case GoalType.WeekendShift:
        return { src: calendarUrl, alt: "calendar" };
      // fallback to no icon
      default:
        return {
          src: "",
          alt: "",
        };
    }
  })();

  return (
    <img
      css={css`
        object-fit: contain;
        max-height: 64px;
        max-width: 64px;
      `}
      src={src}
      alt={alt}
    />
  );
}
