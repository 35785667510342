import { useEffect, useRef, useState } from "react";

/*
  Calculates the height of a component using a ref.
*/
export function useRefHeight({ isReady }: { isReady?: boolean }) {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref && isReady) {
      setHeight(ref.current?.getBoundingClientRect().height || 0);
    }
  }, [isReady, ref]);

  return { ref, height };
}
