import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ClaimDrawPrizeMutationVariables = Types.Exact<{
  drawId: Types.Scalars['ID'];
}>;


export type ClaimDrawPrizeMutation = (
  { __typename?: 'Mutation' }
  & { claimDrawPrize: (
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id'>
  ) }
);


export const ClaimDrawPrizeDocument = gql`
    mutation claimDrawPrize($drawId: ID!) {
  claimDrawPrize(drawId: $drawId) {
    id
  }
}
    `;
export type ClaimDrawPrizeMutationFn = Apollo.MutationFunction<ClaimDrawPrizeMutation, ClaimDrawPrizeMutationVariables>;

/**
 * __useClaimDrawPrizeMutation__
 *
 * To run a mutation, you first call `useClaimDrawPrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimDrawPrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimDrawPrizeMutation, { data, loading, error }] = useClaimDrawPrizeMutation({
 *   variables: {
 *      drawId: // value for 'drawId'
 *   },
 * });
 */
export function useClaimDrawPrizeMutation(baseOptions?: Apollo.MutationHookOptions<ClaimDrawPrizeMutation, ClaimDrawPrizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimDrawPrizeMutation, ClaimDrawPrizeMutationVariables>(ClaimDrawPrizeDocument, options);
      }
export type ClaimDrawPrizeMutationHookResult = ReturnType<typeof useClaimDrawPrizeMutation>;
export type ClaimDrawPrizeMutationResult = Apollo.MutationResult<ClaimDrawPrizeMutation>;
export type ClaimDrawPrizeMutationOptions = Apollo.BaseMutationOptions<ClaimDrawPrizeMutation, ClaimDrawPrizeMutationVariables>;