import { SVGProps } from "react";

export function WinnerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={39}
      fill="none"
      {...props}
    >
      <circle cx={20} cy={19.5} r={19.5} fill="#fff" fillOpacity={0.2} />
      <path
        fill="#00C7C7"
        fillRule="evenodd"
        d="M28.556 23.94a4.354 4.354 0 0 0-4.354-4.354h-7.917a4.354 4.354 0 0 0-4.354 4.354v1.583c0 1.53 1.241 2.77 2.77 2.77h11.084a2.77 2.77 0 0 0 2.77-2.77V23.94Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#a)"
        d="M20.243 18.794a4.354 4.354 0 1 0 0-8.708 4.354 4.354 0 0 0 0 8.708Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={20.731}
          x2={20.731}
          y1={14.439}
          y2={38.889}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB7BD" />
          <stop offset={1} stopColor="#FF2C3E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
