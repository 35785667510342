import UAParser from "ua-parser-js";

/**
 * Contains a subset of the full list of supported operating systems in UAParser,
 * which is used by Caribou. It doesn't use UAParser types because they are lagging
 * behind in DefinitelyTyped.
 *
 * @see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/ua-parser-js/index.d.ts
 */
export enum SupportedOperatingSystem {
  IOS = "iOS",
  ANDROID = "Android",
  MAC = "Mac",
  WINDOWS = "Windows",
}

export function getOperatingSystem(): SupportedOperatingSystem | null {
  const parser = new UAParser();
  const os = parser.getOS();
  const device = parser.getDevice();

  if (os.name?.match(/iOS/i)) return SupportedOperatingSystem.IOS;

  // check for iPad first, because iPad also matches /Mac/ sometimes
  if (device.model?.match(/iPad/i)) return SupportedOperatingSystem.IOS;

  if (os.name?.match(/Mac/i)) return SupportedOperatingSystem.MAC;

  if (os.name?.match(/Android/i)) return SupportedOperatingSystem.ANDROID;

  if (os.name?.match(/Windows/i)) return SupportedOperatingSystem.WINDOWS;

  return null;
}
