import { useTheme } from "@emotion/react";
import { SVGProps } from "react";

function HelpIcon(props: SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  return (
    <svg
      width={46}
      height={42}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.362 15.626c.824 0 1.792-.098 2.472-.297V8.711c0-.592-.436-1.038-1.018-1.038H14.185c-.581 0-1.018.444-1.018 1.038v6.57c.677.197 1.695.345 2.518.297 2.083-.05 3.779 1.63 3.826 3.705 0 .196 0 .443-.05.642h7.17c-.049-.197-.049-.395-.049-.642.002-2.028 1.747-3.707 3.78-3.657ZM6.289 9.402c2.372 0 4.263-1.976 4.263-4.347 0-2.371-1.938-4.347-4.263-4.347-2.373 0-4.263 1.976-4.263 4.347 0 2.371 1.937 4.347 4.263 4.347Z"
        fill={props.fill || theme.palette.primary.main}
      />
      <path
        d="M15.685 17.5c-1.503.05-3.925-.345-4.553-1.184-.726-.988-1.21-2.172-1.55-3.014-.63-1.63-2.13-2.766-3.875-2.865-1.114-.098-2.276.348-3.148 1.187-.823.84-1.357 1.976-1.357 3.16l-.146 9.632v.395l.97 6.668-1.938 7.064c-.34 1.136.338 2.371 1.453 2.716.193.05.388.099.58.099a2.13 2.13 0 0 0 2.035-1.58l2.083-7.508c.097-.297.097-.592.05-.937l-.824-5.63 3.003 6.076.58 7.557a2.173 2.173 0 0 0 2.326 1.976c1.162-.098 2.034-1.136 1.938-2.371l-.63-8.001c0-.297-.097-.544-.242-.791l-3.003-6.176v-4.05c2.276 1.532 5.57 1.532 6.346 1.482 1.066-.05 1.889-.938 1.889-2.026-.05-1.087-.92-1.926-1.987-1.878ZM43.975 5.055c0 2.4-1.91 4.347-4.263 4.347-2.354 0-4.263-1.947-4.263-4.347 0-2.4 1.909-4.347 4.263-4.347s4.263 1.947 4.263 4.347ZM45.913 38.544l-1.987-7.064.969-6.668c0-.148.049-.297 0-.395l-.193-9.632c0-1.184-.485-2.371-1.357-3.16-.872-.84-1.987-1.285-3.198-1.235-1.744.098-3.245 1.185-3.875 2.865-.193.84-.677 2.074-1.404 3.012-.63.84-3.003 1.235-4.553 1.185-1.018-.05-1.937.839-1.986 1.925-.05 1.086.823 1.976 1.888 2.026.776 0 4.068 0 6.347-1.482v4.052l-3.052 6.174c-.146.247-.193.544-.243.791l-.63 8.001c-.096 1.185.776 2.273 1.938 2.371a2.174 2.174 0 0 0 2.325-1.975l.581-7.558 3.003-6.076-.823 5.63c-.05.297-.05.643.05.938l2.082 7.509c.292.938 1.115 1.58 2.035 1.58.193 0 .388-.05.58-.098a2.19 2.19 0 0 0 1.503-2.717Z"
        fill={props.fill || theme.palette.primary.main}
      />
    </svg>
  );
}

export default HelpIcon;
