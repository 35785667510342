/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppTheme } from "@rewards-web/shared/style/types";

import { FormattedVisitDate } from "../formatted-visit-date";

export interface VisitDetailProps {
  startAt: number;
  hasTracked: boolean;
}

export function VisitDetail({ startAt, hasTracked }: VisitDetailProps) {
  const theme = useTheme();

  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: ${appTheme.spacing(3)};
      `}
    >
      <FormattedVisitDate dateValue={startAt} boldDay />

      {hasTracked && (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="lg"
          color={theme.palette.success.main}
          css={css`
            font-size: 32px;
          `}
        />
      )}
    </div>
  );
}
