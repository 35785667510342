/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";

export function GoalCardSkeleton() {
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          width: 90%;
        `}
      >
        <Skeleton height="150px" width="100%" animated />
      </div>
    </div>
  );
}
