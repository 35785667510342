/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { ProfileDetailsCard } from "./profile-details-card";
import { useRewardsProfileQuery } from "./query.generated";

export function ProfilePage() {
  const { formatMessage } = useFormatters();

  const { data, error } = useRewardsProfileQuery({
    onError: reportError,
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Profile page > error loading data",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (!data) {
    return <PageLoadingState />;
  }

  const userDetails = compact([
    {
      category: formatMessage({
        description: "Profile page > info table > employer label",
        defaultMessage: "Employer",
      }),
      details: data.getMyRewardsOrganization.shortName,
    },
    {
      category: formatMessage({
        description: "Profile page > info table > user full name label",
        defaultMessage: "Name",
      }),
      details: data.getMyRewardsUser.displayName,
    },
    data.getMyRewardsUser.phoneNumber && {
      category: formatMessage({
        description: "Profile page > info table > user cell phone label",
        defaultMessage: "Cellphone",
      }),
      details: formatPhoneNumber(data.getMyRewardsUser.phoneNumber.toString()),
    },
    data.getMyRewardsUser.email &&
      // temporarily hide emails ending in @example.com, which is a workaround
      // that allows us to create users with only their phone number
      !data.getMyRewardsUser.email.endsWith("@example.com") && {
        category: formatMessage({
          description: "Profile page > info table > user email label",
          defaultMessage: "Email",
        }),
        details: (
          <Typography variant="footnote" color="primary">
            {data.getMyRewardsUser.email}
          </Typography>
        ),
      },
    {
      category: formatMessage({
        defaultMessage: "See something that's not accurate?",
        description: "Profile page > info table > help label",
      }),
      details: (
        <Button
          externalLink
          linkTo={`mailto:${encodeURI("help@caribou.care")}?Subject=${encodeURI(
            "Comment about my profile page"
          )}`}
          css={css`
            width: fit-content;
          `}
          typographyVariant="footnote"
          size="small"
          color="primary"
          variant="outlined"
          label={formatMessage({
            defaultMessage: "help@caribou.care",
            description: "Profile page > info table > help button",
          })}
        />
      ),
    },
  ]);

  return (
    <>
      <SubPageHeader
        pageName={formatMessage({
          description: "Profile page > page title",
          defaultMessage: "Profile",
        })}
        analyticsPageName="Profile"
        backTo="settings"
      />

      <PageContentContainer>
        <ScrollToTopOnMount />
        <ProfileDetailsCard data={userDetails} />
      </PageContentContainer>
    </>
  );
}
