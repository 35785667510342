/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { initial, last, partition } from "lodash";
import { forwardRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  ReferralRewardStructureReferralRecruitmentItem,
  ReferralRewardStructureReferralRetentionItemDuration,
  ReferralStatusV2,
  ReferralStepV2,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";

import MailIcon from "../../../../../shared/mail-icon";
import { FutureStepLabel } from "../future-step-label";
import { StepLabel } from "../step-label";
import { CheckCircleIcon, RoundelIcon, StarIcon } from "./icons";
import { ReferralsCardTimelineDot } from "./referrals-card-timeline-dot";
import { ReferralsCardTimelineItem } from "./referrals-card-timeline-item";

export interface ReferralsCardProps {
  organizationName: string;
  referrerDisplayName: string;
  candidateId: string;
  candidateDisplayName: string;
  candidateEmail: string | null;
  pointsAwarded: number;
  pointsAwardable: number;
  status: ReferralStatusV2;
  steps: ReferralsCardStep[];
  wasHired: boolean;
}

export type ReferralsCardStep = Pick<
  ReferralStepV2,
  "__typename" | "date" | "completed" | "pointValue"
> & {
  step:
    | ({
        __typename?: "ReferralRewardStructureReferralRecruitmentItem";
      } & Pick<ReferralRewardStructureReferralRecruitmentItem, "step">)
    | ({
        __typename?: "ReferralRewardStructureReferralRetentionItem";
      } & {
        duration: {
          __typename?: "ReferralRewardStructureReferralRetentionItemDuration";
        } & Pick<
          ReferralRewardStructureReferralRetentionItemDuration,
          "unit" | "amount"
        >;
      });
};

function getMailToLink(email: string, subject: string, body: string) {
  return `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body.replaceAll("<br/>", "\n"))}`; // because i18n text can contain <br/> tags
}

export const ReferralsCard = forwardRef<HTMLDivElement, ReferralsCardProps>(
  (props: ReferralsCardProps, ref) => {
    const theme = useTheme();
    const track = useTrack();
    const { formatMessage, formatDate } = useFormatters();

    // We only support point value based recruitment and retention steps for now
    const pointValueBasedSteps = props.steps.filter((step) => step.pointValue);

    const [completedSteps, incompleteSteps] = partition(
      pointValueBasedSteps,
      (step) => step.completed
    );

    const initialCompletedSteps =
      props.status !== "ACTIVE" ? completedSteps : initial(completedSteps);

    const lastCompletedStep = last(
      props.status !== "ACTIVE" ? [] : completedSteps
    );

    const getKeyForStep = (step: ReferralsCardStep) => {
      if (!step.step.__typename) {
        throw new Error("Step does not have __typename");
      }

      switch (step.step.__typename) {
        case "ReferralRewardStructureReferralRetentionItem":
          return `ReferralRewardStructureReferralRetentionItem-${step.step.duration.unit}-${step.step.duration.amount}`;
        case "ReferralRewardStructureReferralRecruitmentItem":
          return `ReferralRewardStructureReferralRecruitmentItem-${step.step.step}`;
        default:
          assertNever(
            step.step.__typename,
            `Could not handle recruitment step typename ${step.step.__typename}`
          );
      }
    };

    if (props.status === ReferralStatusV2.Inactive && !props.wasHired) {
      return null; // we shouldn't show inactive applicants if they weren't hired
    }

    return (
      <Card
        ref={ref}
        css={css`
          min-width: fit-content;
        `}
      >
        <div
          css={css`
            padding: ${theme.spacing(2)};
            padding-bottom: 0;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <CheckCircleIcon
              width={32}
              height={32}
              fill={
                props.status === ReferralStatusV2.Inactive
                  ? "#CCCCCC"
                  : undefined
              }
            />

            <Typography
              variant="subtitle"
              color={
                props.status === ReferralStatusV2.Inactive
                  ? "textSecondary"
                  : undefined
              }
              css={css`
                margin-left: ${theme.spacing(2)};
              `}
            >
              {props.status === ReferralStatusV2.Inactive && (
                <FormattedMessage
                  description="Referral page > Referral card > Heading hired archived text"
                  defaultMessage="{candidate_name_text} is not actively working for {organization_name}."
                  values={{
                    candidate_name_text: props.candidateDisplayName,
                    organization_name: props.organizationName,
                  }}
                />
              )}

              {props.status === "ACTIVE" && props.wasHired && (
                <FormattedMessage
                  description="Referral page > Referral card > Heading hired text"
                  defaultMessage="<candidatename>{candidate_name_text}</candidatename> hired!"
                  values={{
                    candidate_name_text: props.candidateDisplayName,
                    candidatename: (nodes) => (
                      <Typography
                        component="span"
                        variant="subtitle"
                        css={css`
                          color: ${theme.palette.primary.main};
                        `}
                      >
                        {nodes}
                      </Typography>
                    ),
                  }}
                />
              )}

              {props.status === "ACTIVE" && !props.wasHired && (
                <FormattedMessage
                  description="Referral page > Referral card > Heading applied text"
                  defaultMessage="<candidatename>{candidate_name_text}</candidatename> applied!"
                  values={{
                    candidate_name_text: props.candidateDisplayName,
                    candidatename: (nodes) => (
                      <Typography
                        component="span"
                        variant="subtitle"
                        css={css`
                          color: ${theme.palette.primary.main};
                        `}
                      >
                        {nodes}
                      </Typography>
                    ),
                  }}
                />
              )}

              {props.status === "COMPLETED" && (
                <Typography
                  component="span"
                  variant="subtitle"
                  css={css`
                    color: ${theme.palette.primary.main};
                  `}
                >
                  {props.candidateDisplayName}
                </Typography>
              )}
            </Typography>
          </div>

          {props.status === "ACTIVE" && props.wasHired && (
            <Typography
              variant="body"
              css={css`
                margin-top: ${theme.spacing(1)};
              `}
            >
              <FormattedMessage
                description="Referral page > Referral card > Contact email button label"
                defaultMessage="<email_button>Email</email_button> Contact {candidate_name} to see how they are liking their role"
                values={{
                  candidate_name: props.candidateDisplayName,
                  email_button: (nodes) => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      width="auto"
                      label={nodes.join("")}
                      startIcon={<MailIcon fill="#fff" />}
                      css={css`
                        margin-right: ${theme.spacing(1)};
                        display: ${props.candidateEmail ? undefined : "none"};
                      `}
                      onClick={() => {
                        track(
                          "Referral candidate check-in email button clicked for candidate",
                          { candidateId: props.candidateId }
                        );
                      }}
                      linkTo={getMailToLink(
                        props.candidateEmail!,
                        formatMessage(
                          {
                            description:
                              "Referral page > Referral card > Contact email template subject",
                            defaultMessage: `Hey! How is {organization}?`,
                          },
                          {
                            organization: props.organizationName,
                          }
                        ),
                        formatMessage(
                          {
                            description:
                              "Referral page > Referral card > Contact email template body",
                            defaultMessage:
                              "Hi {candidate_name},<br/><br/>How are you doing? How are you liking working at {organization}?<br/><br/>{referrer_name}",
                          },
                          {
                            organization: props.organizationName,
                            candidate_name: props.candidateDisplayName,
                            referrer_name: props.referrerDisplayName,
                          }
                        )
                      )}
                      externalLink
                    />
                  ),
                }}
              />
            </Typography>
          )}

          <div
            css={css`
              margin-top: ${theme.spacing(1)};
              padding-bottom: ${theme.spacing(2)};
            `}
          >
            <Typography component="span" variant="caption">
              <FormattedMessage
                description="Referral page > Referral card > points summary text"
                defaultMessage="Points Earned: <earnedpoints>{earned_points_text, number} </earnedpoints><earnablepoints>/ {earnable_points_text, number} </earnablepoints><primarytext>points</primarytext>"
                values={{
                  earned_points_text: props.pointsAwarded,
                  earnedpoints: (nodes) => (
                    <Typography
                      component="span"
                      variant="subtitle"
                      color="primary"
                      css={css`
                        margin-left: ${theme.spacing(0.5)};
                        font-weight: bolder;
                      `}
                    >
                      {nodes}
                    </Typography>
                  ),
                  earnable_points_text: props.pointsAwardable,
                  earnablepoints: (nodes) => (
                    <Typography
                      component="span"
                      variant="subtitle"
                      color="textSecondary"
                      css={css`
                        font-weight: bolder;
                      `}
                    >
                      {nodes}
                    </Typography>
                  ),
                  primarytext: (nodes) => (
                    <Typography
                      component="span"
                      variant="caption"
                      color="textSecondary"
                    >
                      {nodes}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </div>

          <Divider />

          <Timeline
            css={css`
              margin: ${theme.spacing(2.5)} 0 0 auto;
              padding: auto 0 0 0;
            `}
          >
            {initialCompletedSteps.map((step, index) => (
              <ReferralsCardTimelineItem key={getKeyForStep(step)}>
                <TimelineSeparator
                  css={css`
                    color: #ffffff;
                  `}
                >
                  <ReferralsCardTimelineDot color="inherit">
                    <CheckCircleIcon width={16} height={16} />
                  </ReferralsCardTimelineDot>

                  {
                    // Don't show connector if this is the last step overall
                    index === initialCompletedSteps.length - 1 &&
                    lastCompletedStep === undefined &&
                    incompleteSteps.length === 0 ? null : (
                      <TimelineConnector
                        css={css`
                          background-color: "#44b6b0";
                          width: 1.5px;
                        `}
                      />
                    )
                  }
                </TimelineSeparator>

                <TimelineContent
                  css={css`
                    padding-left: 8px;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      margin-top: -${theme.spacing(1.5)};
                    `}
                  >
                    <StarIcon fill="#FFC700" />

                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        margin-left: ${theme.spacing(1.5)};
                      `}
                    >
                      <div>
                        <FormattedMessage
                          description="Referral page > Referral card > Points earned text"
                          defaultMessage="<pointvalue>{point_value_text, number} </pointvalue><primarytext>points earned! </primarytext><date>{full_date, date, medium}</date>"
                          values={{
                            point_value_text: step.pointValue,
                            pointvalue: (nodes) => (
                              <Typography
                                component="span"
                                variant="subtitle"
                                color="primary"
                                css={css`
                                  font-weight: bolder;
                                `}
                              >
                                {nodes}
                              </Typography>
                            ),
                            primarytext: (nodes) => (
                              <Typography
                                component="span"
                                variant="caption"
                                color="primary"
                              >
                                {nodes}
                              </Typography>
                            ),
                            date: (nodes) =>
                              step.date && (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {nodes}
                                </Typography>
                              ),
                            full_date: step.date ? new Date(step.date) : null,
                          }}
                        />
                      </div>

                      <Typography variant="caption">
                        <StepLabel step={step.step} />
                      </Typography>
                    </div>
                  </div>
                </TimelineContent>
              </ReferralsCardTimelineItem>
            ))}

            {lastCompletedStep && (
              <ReferralsCardTimelineItem>
                <TimelineSeparator
                  css={css`
                    color: #ffffff;
                  `}
                >
                  <ReferralsCardTimelineDot color="inherit" variant="outlined">
                    <RoundelIcon width={16} height={16} fill="#44B6B0" />
                  </ReferralsCardTimelineDot>
                  {incompleteSteps.length > 0 && (
                    <TimelineConnector
                      css={css`
                        background-color: #cccccc;
                        width: 1.5px;
                      `}
                    />
                  )}
                </TimelineSeparator>

                <TimelineContent
                  css={css`
                    padding-left: 8px;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-top: -${theme.spacing(1.5)};
                    `}
                  >
                    <StarIcon fill="#44B6B0" />

                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        margin-left: ${theme.spacing(1.5)};
                      `}
                    >
                      <div>
                        {lastCompletedStep.date ? (
                          <FormattedMessage
                            description="Referral page > Last completed step referral card > Points earned text"
                            defaultMessage="<pointvalue>{point_value_text, number} </pointvalue><primarytext>points earned! </primarytext><plaintext>in </plaintext><monthandyear>{month_and_year}</monthandyear>"
                            values={{
                              point_value_text: lastCompletedStep.pointValue,
                              pointvalue: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="subtitle"
                                  color="primary"
                                  css={css`
                                    font-weight: bolder;
                                  `}
                                >
                                  {nodes}
                                </Typography>
                              ),
                              primarytext: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="primary"
                                >
                                  {nodes}
                                </Typography>
                              ),
                              plaintext: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {nodes}
                                </Typography>
                              ),
                              monthandyear: (nodes) =>
                                lastCompletedStep.date && (
                                  <Typography
                                    component="span"
                                    variant="caption"
                                    color="primary"
                                    css={css`
                                      padding: ${theme.spacing(0.5)};
                                      background-color: #d6e9eb;
                                      border-radius: ${theme.spacing(0.5)};
                                      border: 1px solid #badade;
                                      margin-left: ${theme.spacing(0.5)};
                                    `}
                                  >
                                    {nodes}
                                  </Typography>
                                ),
                              month_and_year: lastCompletedStep.date
                                ? formatDate(new Date(lastCompletedStep.date), {
                                    month: "short",
                                    year: "numeric",
                                  })
                                : null,
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            description="Referral page > Last completed step referral card > Points earned text (no date)"
                            defaultMessage="<pointvalue>{point_value_text, number} </pointvalue><primarytext>points </primarytext>"
                            values={{
                              point_value_text: lastCompletedStep.pointValue,
                              pointvalue: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="subtitle"
                                  color="primary"
                                  css={css`
                                    font-weight: bolder;
                                  `}
                                >
                                  {nodes}
                                </Typography>
                              ),
                              primarytext: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="primary"
                                >
                                  {nodes}
                                </Typography>
                              ),
                              plaintext: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {nodes}
                                </Typography>
                              ),
                            }}
                          />
                        )}
                      </div>

                      <Typography variant="caption">
                        <StepLabel step={lastCompletedStep.step} />
                      </Typography>
                    </div>
                  </div>
                </TimelineContent>
              </ReferralsCardTimelineItem>
            )}

            {incompleteSteps.map((step, index) => (
              <ReferralsCardTimelineItem key={getKeyForStep(step)}>
                <TimelineSeparator
                  css={css`
                    color: #ffffff;
                  `}
                >
                  <ReferralsCardTimelineDot color="inherit" variant="outlined">
                    <RoundelIcon width={16} height={16} fill="#cccccc" />
                  </ReferralsCardTimelineDot>
                  {index < incompleteSteps.length - 1 && (
                    <TimelineConnector
                      css={css`
                        background-color: #cccccc;
                        width: 1.5px;
                      `}
                    />
                  )}
                </TimelineSeparator>

                <TimelineContent
                  css={css`
                    padding-left: 8px;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-top: -${theme.spacing(1.5)};
                    `}
                  >
                    <StarIcon fill="#cccccc" />

                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        margin-left: ${theme.spacing(1.5)};
                      `}
                    >
                      <div>
                        {step.date ? (
                          <FormattedMessage
                            description="Referral page > Future step referral card > Points earned text"
                            defaultMessage="<pointvalue>{point_value_text, number} </pointvalue><primarytext>points </primarytext><plaintext>in </plaintext><monthandyear>{month_and_year}</monthandyear>"
                            values={{
                              point_value_text: step.pointValue,
                              pointvalue: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="subtitle"
                                  color="primary"
                                  css={css`
                                    font-weight: bolder;
                                  `}
                                >
                                  {nodes}
                                </Typography>
                              ),
                              primarytext: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="primary"
                                >
                                  {nodes}
                                </Typography>
                              ),
                              plaintext: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="textSecondary"
                                  hidden={props.status !== "ACTIVE"}
                                >
                                  {nodes}
                                </Typography>
                              ),
                              monthandyear: (nodes) =>
                                step.date && (
                                  <Typography
                                    component="span"
                                    variant="caption"
                                    color="primary"
                                    hidden={props.status !== "ACTIVE"}
                                    css={css`
                                      padding: ${theme.spacing(0.5)};
                                      background-color: #d6e9eb;
                                      border-radius: ${theme.spacing(0.5)};
                                      border: 1px solid #badade;
                                      margin-left: ${theme.spacing(0.5)};
                                    `}
                                  >
                                    {nodes}
                                  </Typography>
                                ),
                              month_and_year: step.date
                                ? formatDate(new Date(step.date), {
                                    month: "short",
                                    year: "numeric",
                                  })
                                : null,
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            description="Referral page > Future step referral card > Points earned text (no date)"
                            defaultMessage="<pointvalue>{point_value_text, number} </pointvalue><primarytext>points </primarytext>"
                            values={{
                              point_value_text: step.pointValue,
                              pointvalue: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="subtitle"
                                  color="primary"
                                  css={css`
                                    font-weight: bolder;
                                  `}
                                >
                                  {nodes}
                                </Typography>
                              ),
                              primarytext: (nodes) => (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="primary"
                                >
                                  {nodes}
                                </Typography>
                              ),
                            }}
                          />
                        )}
                      </div>

                      <Typography variant="caption">
                        <FutureStepLabel step={step.step} />
                      </Typography>
                    </div>
                  </div>
                </TimelineContent>
              </ReferralsCardTimelineItem>
            ))}
          </Timeline>
        </div>
      </Card>
    );
  }
);
