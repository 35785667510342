import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReferralsProgressPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ReferralsProgressPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyReferralsProgressV2: (
    { __typename?: 'ReferralsProgressV2Response' }
    & { referrals?: Types.Maybe<Array<(
      { __typename?: 'Referral' }
      & Pick<Types.Referral, 'id' | 'candidateDisplayName' | 'candidateEmail' | 'pointsAwarded' | 'pointsAwardable' | 'wasHired' | 'statusV2'>
      & { stepsV2: Array<(
        { __typename?: 'ReferralStepV2' }
        & Pick<Types.ReferralStepV2, 'pointValue' | 'date' | 'completed'>
        & { step: (
          { __typename?: 'ReferralRewardStructureReferralRecruitmentItem' }
          & Pick<Types.ReferralRewardStructureReferralRecruitmentItem, 'step'>
        ) | (
          { __typename?: 'ReferralRewardStructureReferralRetentionItem' }
          & { duration: (
            { __typename?: 'ReferralRewardStructureReferralRetentionItemDuration' }
            & Pick<Types.ReferralRewardStructureReferralRetentionItemDuration, 'unit' | 'amount'>
          ) }
        ) }
      )> }
    )>> }
  ), getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName' | 'maxPointsEarnedPerCandidate' | 'pointsPerDollar'>
  ), getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'displayName'>
  ) }
);


export const ReferralsProgressPageDataDocument = gql`
    query ReferralsProgressPageData {
  getMyReferralsProgressV2 {
    referrals {
      id
      candidateDisplayName
      candidateEmail
      pointsAwarded
      pointsAwardable
      wasHired
      statusV2
      stepsV2 {
        pointValue
        step {
          ... on ReferralRewardStructureReferralRecruitmentItem {
            step
          }
          ... on ReferralRewardStructureReferralRetentionItem {
            duration {
              unit
              amount
            }
          }
        }
        date
        completed
      }
    }
  }
  getMyRewardsOrganization {
    id
    shortName
    maxPointsEarnedPerCandidate
    pointsPerDollar
  }
  getMyRewardsUser {
    id
    displayName
  }
}
    `;

/**
 * __useReferralsProgressPageDataQuery__
 *
 * To run a query within a React component, call `useReferralsProgressPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsProgressPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsProgressPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralsProgressPageDataQuery(baseOptions?: Apollo.QueryHookOptions<ReferralsProgressPageDataQuery, ReferralsProgressPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralsProgressPageDataQuery, ReferralsProgressPageDataQueryVariables>(ReferralsProgressPageDataDocument, options);
      }
export function useReferralsProgressPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsProgressPageDataQuery, ReferralsProgressPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralsProgressPageDataQuery, ReferralsProgressPageDataQueryVariables>(ReferralsProgressPageDataDocument, options);
        }
export type ReferralsProgressPageDataQueryHookResult = ReturnType<typeof useReferralsProgressPageDataQuery>;
export type ReferralsProgressPageDataLazyQueryHookResult = ReturnType<typeof useReferralsProgressPageDataLazyQuery>;
export type ReferralsProgressPageDataQueryResult = Apollo.QueryResult<ReferralsProgressPageDataQuery, ReferralsProgressPageDataQueryVariables>;