/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Card } from "@rewards-web/shared/components/card/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { ListShareableJobPostingsFilters } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../../shared/scroll-to-top-on-mount";
import notificationImgUrl from "./icons/notification.png";
import { JobCountAndFilter } from "./job-count-and-filter";
import { JobList } from "./job-list";
import { useJobsPageReferralsEnabledQuery } from "./job-page-referral-enabled.generated";
import { useSharableJobPostingsQuery } from "./sharable-job-postings.generated";

const PER_PAGE = 12;

export function JobsPageContents(): JSX.Element {
  const [
    selectedFilters,
    setSelectedFilters,
  ] = useState<ListShareableJobPostingsFilters>({});
  const jobsPageReferralsEnabledQuery = useJobsPageReferralsEnabledQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
  });

  const query = useSharableJobPostingsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: PER_PAGE,
      filters: selectedFilters,
    },
    onError: reportError,
  });

  const numJobs = query.data?.listShareableJobPostings?.total;
  const referralDisableForUser =
    jobsPageReferralsEnabledQuery.data?.getMyRewardsUser
      .userReferralLinkDisabled;
  return (
    <PageContentContainer
      css={(theme: AppTheme) => css`
        padding-top: ${theme.spacing(2)};
      `}
    >
      <ScrollToTopOnMount />
      {!referralDisableForUser ? (
        <>
          <JobCountAndFilter
            numJobs={numJobs}
            selectedFilters={selectedFilters}
            onChangeSelectedFilters={setSelectedFilters}
          />
          <JobList
            searchQuery={""}
            selectedFilters={selectedFilters}
            numJobsMatchingFilters={numJobs}
            query={query}
            perPage={PER_PAGE}
          />
        </>
      ) : (
        <Card
          css={(theme: AppTheme) => css`
            width: 100%;
            border-width: 0px;
            padding: ${theme.spacing(3)};
            background-color: #f5f9fa;
            margin-top: ${theme.spacing(2.8)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              margin: 0 auto;
              max-width: 520px;
              gap: ${theme.spacing(2)};
            `}
          >
            <div>
              <img
                src={notificationImgUrl}
                alt={"notification"}
                css={(theme: AppTheme) => css`
                  padding-top: ${theme.spacing(1)};
                  width: 80px;
                  height: 75px;
                  object-fit: contain;
                `}
              />
            </div>

            <div>
              <Typography variant="subtitle" color="textPrimary">
                Your referrals are currently disabled.
              </Typography>

              <Typography
                variant="body"
                color="textPrimary"
                css={(theme: AppTheme) =>
                  css`
                    padding-bottom: ${theme.spacing(2)};
                  `
                }
              >
                For more information or to request permission, please contact
                your agency.
              </Typography>
            </div>
          </div>
        </Card>
      )}
    </PageContentContainer>
  );
}
