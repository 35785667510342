export enum RewardsAppAuthErrorCode {
  DEACTIVATED = "DEACTIVATED",
  INVALID_VERIFICATION_CODE = "INVALID_VERIFICATION_CODE",
  EXCEEDED_MAX_ATTEMPTS = "EXCEEDED_MAX_ATTEMPTS",
  CHALLENGE_EXPIRED = "CHALLENGE_EXPIRED",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  TOKEN_ALREADY_USED = "TOKEN_ALREADY_USED",
  TOKEN_AUTH_FAILED = "TOKEN_AUTH_FAILED",
}

export class RewardsAppAuthError extends Error {
  constructor(message: string, public readonly code: RewardsAppAuthErrorCode) {
    super(message);

    this.code = code;
  }
}
