/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import GiftcardRedemptionsImage from "../images/giftcard-redemptions.png";

export function GiftcardRedemptionInfo() {
  const { formatMessage } = useFormatters();

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid ${theme.palette.grey[200]};
        border-radius: 20px;
        height: 100%;
        padding: ${theme.spacing(2.5)};
      `}
    >
      <Typography variant="h6">
        {formatMessage({
          defaultMessage: "What could you redeem for?",
          description: "Points summary page > giftcard info > title",
        })}
      </Typography>
      <img
        src={GiftcardRedemptionsImage}
        css={(appTheme: AppTheme) => css`
          margin-top: ${appTheme.spacing(2.5)};
          height: 70px;
        `}
        alt={formatMessage({
          description: "Points summary page > giftcard illustration alt",
          defaultMessage: "Giftcard illustration",
        })}
      />
      <Typography
        css={(appTheme: AppTheme) =>
          css`
            margin-top: ${appTheme.spacing(2.5)};
          `
        }
        variant="subtitle"
        color="textPrimary"
      >
        {formatMessage({
          description: "Points summary page > choose gift card title",
          defaultMessage: "Choose gift cards from hundreds of stores!",
        })}
      </Typography>
      <Typography
        variant="body"
        color="textPrimary"
        css={(appTheme: AppTheme) =>
          css`
            margin-top: ${appTheme.spacing(0.5)};
            max-width: 390px;
          `
        }
      >
        {formatMessage({
          defaultMessage:
            "When you redeem you will receive an email with access to a gift card store.",
          description: "Points summary page > gift card subtitle",
        })}
      </Typography>
    </div>
  );
}
