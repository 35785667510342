import { SVGProps } from "react";

function MailIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.857 0H2.143C.96 0 0 .94 0 2.1v9.8C0 13.06.96 14 2.143 14h15.714C19.04 14 20 13.06 20 11.9V2.1C20 .94 19.04 0 17.857 0ZM2.143 1.4h15.714a.716.716 0 0 1 .643.408l-7.194 5.425c-.77.58-1.843.58-2.612 0L1.5 1.808a.716.716 0 0 1 .643-.408Zm15.714 11.2H2.143a.707.707 0 0 1-.714-.7V3.522l6.393 4.82c.625.47 1.39.725 2.179.725.788 0 1.554-.255 2.179-.725l6.392-4.82V11.9a.694.694 0 0 1-.21.495.722.722 0 0 1-.505.205Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default MailIcon;
