import { SVGProps } from "react";

export function BranchJobsHeaderIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 0C2.707 0 0 2.426 0 5.885c0 1.234.308 2.093.88 3.044l4.716 7.846A.467.467 0 0 0 6 17a.467.467 0 0 0 .404-.225L11.12 8.93c.572-.95.88-1.81.88-3.044C12 2.425 9.293 0 6 0Zm0 .872c2.824 0 5.077 1.972 5.077 5.013 0 1.108-.228 1.735-.758 2.615L6 15.679 1.68 8.5C1.151 7.62.923 6.993.923 5.885.923 2.845 3.176.872 6 .872Zm0 1.743c-1.779 0-3.23 1.372-3.23 3.052S4.22 8.717 6 8.717c1.778 0 3.23-1.37 3.23-3.05 0-1.68-1.452-3.052-3.23-3.052Zm0 .872c1.28 0 2.308.97 2.308 2.18 0 1.209-1.028 2.18-2.308 2.18-1.28 0-2.308-.971-2.308-2.18 0-1.21 1.028-2.18 2.308-2.18Z"
        fill="#5D5D5E"
      />
    </svg>
  );
}
