import { NetworkStatus } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useThrottledCallback } from "use-debounce";

import {
  JobPosting,
  JobPostingPromotion,
  Maybe,
  RewardsOrganization,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { SharableJobPostingsQuery } from "../sharable-job-postings.generated";

export type JobPostingItem = Pick<
  JobPosting,
  "id" | "title" | "ratesOfPay" | "geography" | "hoursRequired"
> & {
  activePromotion?: Maybe<
    { __typename?: "JobPostingPromotion" } & Pick<
      JobPostingPromotion,
      "id" | "bannerMessage"
    >
  >;

  organization: { __typename?: "RewardsOrganization" } & Pick<
    RewardsOrganization,
    "id" | "branchName" | "maxPointsEarnedPerCandidate" | "pointsPerDollar"
  >;

  branch?:
    | Maybe<
        {
          __typename?: "RewardsOrganizationBranch" | undefined;
        } & Pick<RewardsOrganizationBranch, "id" | "name">
      >
    | undefined;
};

interface UseJobScrollerParams {
  data?: SharableJobPostingsQuery;
  networkStatus: NetworkStatus;
  perPage: number;
  fetchMore(params: { variables: { offset: number; limit: number } }): void;
  itemHeight: number;
  twoColumns: boolean;
}

/**
 * - Returns the job listing items with extra `null` items at the end to allow for additional scrolling
 * - Calls `fetchMore` when more items should be fetched (via scroll)
 */
export function useInfinitelyScrolledJobData({
  data,
  networkStatus,
  perPage,
  fetchMore,
  itemHeight,
  twoColumns,
}: UseJobScrollerParams): Array<JobPostingItem | null> {
  const dataWithPadding = useMemo(() => {
    const total = data?.listShareableJobPostings?.total ?? perPage;
    const items = data?.listShareableJobPostings?.items ?? [];

    const padding = Math.min(items.length + perPage, total); // add an extra page of padding

    const itemsWithPadding = new Array<JobPostingItem | null>(padding).fill(
      null,
      0,
      padding
    );

    items.forEach((item, index) => {
      itemsWithPadding[index] = item;
    });

    return itemsWithPadding;
  }, [data?.listShareableJobPostings, perPage]);

  const handleScroll = useThrottledCallback(() => {
    if (
      data &&
      dataWithPadding[dataWithPadding.length - 1] === null &&
      networkStatus !== NetworkStatus.fetchMore
    ) {
      const indexOfFirstItemToLoad = dataWithPadding.findIndex(
        (item) => item === null
      );
      const amountOfItemsToLoad =
        dataWithPadding.length - indexOfFirstItemToLoad;

      const totalScrollableHeight = document.body.scrollHeight;
      const verticalScrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      const positionOfFirstEmptyItem =
        totalScrollableHeight -
        (amountOfItemsToLoad * itemHeight) / (twoColumns ? 2 : 1);

      const lastVisiblePosition = verticalScrollPosition + viewportHeight;

      const positionToStartLoading = lastVisiblePosition + viewportHeight * 2;

      if (positionOfFirstEmptyItem < positionToStartLoading) {
        fetchMore({
          variables: {
            offset: indexOfFirstItemToLoad,
            limit: perPage,
          },
        });
      }
    }
  }, 300);

  useEffect(() => {
    // need to get the scroll container because the whole window isn't scrollable for the app
    const scrollContainer = document.getElementById(
      "navigation-scroll-container"
    );

    if (!scrollContainer) {
      reportError(new Error("No page-scroll-container element"));
      return;
    }

    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return dataWithPadding;
}
