import { createContext } from "react";

interface AuthContextValue {
  userId: null | string;
  setUserId(userId: string | null): void;
}

export const AuthContext = createContext<AuthContextValue>({
  userId: null,
  setUserId: () => {},
});
