import { ReactNode, useState } from "react";

import { AnalyticsContext } from "./context";
import { useTrackPageview } from "./hooks";

export * from "./hooks";

interface PageviewTrackerProps {
  pageName: string;
}

export function PageviewTracker({ pageName }: PageviewTrackerProps) {
  useTrackPageview(pageName);

  return null;
}

interface AnalyticsProviderProps {
  children: ReactNode;
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  const [disabled, setDisabled] = useState(false);

  return (
    <AnalyticsContext.Provider
      value={{
        disabled,
        disable: () => setDisabled(true),
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}
