import { Alert } from "@rewards-web/shared/components/alert";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Locale } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  LocaleCards,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { LocaleCardsProps } from "@rewards-web/shared/modules/formatter/components/locale-cards";

import { useRewardsAppLocaleSelectorDataQuery } from "./rewards-app-locale-selector-data.generated";

interface RewardsLocaleSelectorProps
  extends Omit<LocaleCardsProps, "supportedLocaleCodes"> {}

export function RewardsLocaleSelector({
  ...localeCardsProps
}: RewardsLocaleSelectorProps) {
  const { formatMessage } = useFormatters();
  const query = useRewardsAppLocaleSelectorDataQuery({ onError: reportError });

  if (query.error) {
    return (
      <Alert
        message={formatMessage({
          description: "home page > language selector",
          defaultMessage: "An unexpected error occurred. Please try again",
        })}
        severity="error"
      />
    );
  }

  if (!query.data) {
    return <Skeleton />;
  }

  return (
    <LocaleCards
      {...localeCardsProps}
      supportedLocaleCodes={
        query.data.getMyRewardsOrganization.startedAsTrial
          ? // trial orgs only support a subset of languages
            [Locale.En, Locale.Es]
          : undefined
      }
    />
  );
}
