/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface PageContentContainerProps extends StylableProps {
  children: ReactNode;
}

/**
 * Centers, applies max-width, and applies padding for page content
 */
export function PageContentContainer({
  className,
  children,
}: PageContentContainerProps) {
  return (
    <div
      className={className}
      css={(theme: AppTheme) => css`
        width: calc(100% - ${theme.spacing(4)});
        padding-top: ${theme.spacing(5)};
        max-width: ${theme.maxContentWidth}px;
        margin: 0 auto;
      `}
    >
      {children}
    </div>
  );
}
