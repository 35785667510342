import { sortBy } from "lodash";

import {
  EvvStreakGoalTrackerVisitDetail,
  Visit,
} from "@rewards-web/shared/graphql-types";

// Helper function to get the previous midnight (today's start) in milliseconds
export const getTodayMidnightInMs = (): number => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today.getTime();
};

// Helper function to sort and slice visits
export const getSortedAndSlicedUntrackedVisits = (
  visits: Visit[],
  trackedVisits: EvvStreakGoalTrackerVisitDetail[],
  targetStreak: number | null
): Visit[] => {
  const sorted = sortBy(visits, (visit) => visit.startAt);

  return sorted.slice(0, (targetStreak || 10) - trackedVisits.length);
};
