import { MOBILE_TAB_NAVIGATION_HEIGHT } from "../../pages/authenticated/navigation-container";

export function scrollToElement(element: HTMLElement) {
  document.getElementById("navigation-scroll-container")?.scrollTo({
    top:
      (element.getBoundingClientRect().top || 0) - MOBILE_TAB_NAVIGATION_HEIGHT,
    left: 0,
    behavior: "smooth",
  });
}
