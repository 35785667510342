/** @jsxImportSource @emotion/react */

export function PlayIcon() {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 21.5C1.4375 21.5 0.5 20.4688 0.5 19.25V2.75C0.5 1.57812 1.4375 0.546875 2.75 0.546875C3.125 0.546875 3.54688 0.640625 3.92188 0.875L17.4219 9.125C18.0781 9.5 18.5 10.25 18.5 11C18.5 11.7969 18.0781 12.5469 17.4219 12.9219L3.92188 21.1719C3.54688 21.4062 3.125 21.5 2.75 21.5Z"
        fill="white"
      />
    </svg>
  );
}
