/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useEffect } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { getDaysUntilDrawClose } from "../lib/get-days-until-draw-close";
import { BlurredBackgroundVideoPlayer } from "./blurred-background-video-player";
import { useDrawPrizeCardDataQuery } from "./draw-prize-card-data.generated";
import { BlueCircleLeft } from "./graphics/blue-circle-left";
import goldStarImgUrl from "./graphics/gold-star.png";
import pinkBalloonImgUrl from "./graphics/pink-balloon.png";
import { PinkCircleRight } from "./graphics/pink-circle-right";

const BORDER_RADIUS_PX = 14;
interface DrawDetailsCardProps {
  prizeStructure: {
    __typename: "DrawPrizeStructureSinglePrizeMultipleWinners";
  } & Pick<
    GraphQLTypes.DrawPrizeStructureSinglePrizeMultipleWinners,
    "id" | "numWinners"
  > & {
      prize:
        | ({ __typename: "DrawPrizePoints" } & Pick<
            GraphQLTypes.DrawPrizePoints,
            "id" | "pointValue"
          >)
        | ({ __typename: "DrawTieredPrizePoints" } & Pick<
            GraphQLTypes.DrawTieredPrizePoints,
            "id"
          >);
    };
  drawEndsAt: Date;
  announcementVideoUrl?: string;
}

export function DrawDetailsCard({
  prizeStructure,
  drawEndsAt,
  announcementVideoUrl,
}: DrawDetailsCardProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();

  const cardData = useDrawPrizeCardDataQuery({ onError: reportError });

  const daysUntilDrawClose = getDaysUntilDrawClose(drawEndsAt);

  const text = ((): {
    prizeNode: ReactNode;
    numWinnersNode: ReactNode;
    unknown?: true;
  } => {
    const prizeTBDNode = formatMessage({
      defaultMessage: "Prize TBD",
      description: "Draw page > draw active state > prize TBD",
    });

    switch (prizeStructure.__typename) {
      case "DrawPrizeStructureSinglePrizeMultipleWinners": {
        switch (prizeStructure.prize.__typename) {
          case "DrawPrizePoints": {
            const prizeNode = cardData.data ? (
              formatDollars(
                prizeStructure.prize.pointValue /
                  cardData.data.getMyRewardsOrganization.pointsPerDollar
              )
            ) : (
              <Skeleton />
            );

            if (prizeStructure.numWinners === 1) {
              return {
                prizeNode,
                numWinnersNode: (
                  <FormattedMessage
                    defaultMessage="Chance to win a cash prize!"
                    description="Draw page > draw active state > num winners text (one winner)"
                    values={{ num_winners: prizeStructure.numWinners }}
                  />
                ),
              };
            }

            return {
              prizeNode,
              numWinnersNode: (
                <FormattedMessage
                  defaultMessage="Chance to win 1 of {num_winners, number} cash prizes!"
                  description="Draw page > draw active state > num winners text"
                  values={{ num_winners: prizeStructure.numWinners }}
                />
              ),
            };
          }

          default:
            return {
              prizeNode: prizeTBDNode,
              numWinnersNode: null,
              unknown: true,
            };
        }
      }

      default:
        return { prizeNode: prizeTBDNode, numWinnersNode: null, unknown: true };
    }
  })();

  useEffect(() => {
    if (text.unknown) {
      switch (prizeStructure.__typename) {
        case "DrawPrizeStructureSinglePrizeMultipleWinners": {
          if (prizeStructure.prize?.__typename) {
            reportError(
              new Error(
                `Unrecognized prize structure ${prizeStructure.__typename} or prize ${prizeStructure.prize.__typename}`
              )
            );
          } else {
            reportError(
              new Error(
                `Prize is undefined for structure ${prizeStructure.__typename}`
              )
            );
          }
          break;
        }

        default:
          reportError(new Error("Unrecognized prize structure"));
          break;
      }
    }
  }, [
    text.unknown,
    prizeStructure?.__typename,
    prizeStructure?.prize?.__typename,
  ]);

  return (
    <div
      css={css`
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: ${BORDER_RADIUS_PX}px;
        position: relative;
      `}
    >
      {/* background graphics: */}
      <BlueCircleLeft
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          border-top-left-radius: ${BORDER_RADIUS_PX}px;
        `}
      />
      <PinkCircleRight
        css={css`
          position: absolute;
          top: 40px;
          right: 0;
          overflow: hidden;
        `}
      />
      <img
        src={goldStarImgUrl}
        alt="Gold star"
        css={(theme: AppTheme) => css`
          position: absolute;
          top: 10px;
          right: 70px;

          ${theme.breakpoints.up("sm")} {
            right: 100px;
          }
        `}
      />
      <img
        src={goldStarImgUrl}
        alt="Gold star"
        css={(theme: AppTheme) => css`
          position: absolute;
          top: 134px;
          left: 30px;
          height: 40px;

          ${theme.breakpoints.up("sm")} {
            left: 160px;
          }
        `}
      />
      <img
        src={pinkBalloonImgUrl}
        alt="Pink balloon"
        css={(theme: AppTheme) => css`
          position: absolute;
          top: 20px;
          left: 120px;
          display: none;
          ${theme.breakpoints.up(440)} {
            display: inline-block;
          }
          ${theme.breakpoints.up("sm")} {
            left: 170px;
          }
        `}
      />

      {/* regular content */}
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(6)} ${theme.spacing(2)};
          text-align: center;
          position: relative;
        `}
      >
        <Typography
          variant="h1"
          component="span"
          css={(theme: AppTheme) => css`
            display: block;
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {text.prizeNode}
        </Typography>
        {text.numWinnersNode && (
          <Typography variant="body">{text.numWinnersNode}</Typography>
        )}
      </div>

      {announcementVideoUrl && (
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            justify-content: center;
            align-items: center;
            padding: ${theme.spacing(2)};
          `}
        >
          <BlurredBackgroundVideoPlayer
            url={announcementVideoUrl}
            onPlay={() => {
              track("Draw announcement video played", {
                organizationId: cardData.data?.getMyRewardsOrganization.id,
                userId: cardData.data?.getMyRewardsUser.id,
                drawId: prizeStructure.id,
              });
            }}
            onEnded={() => {
              track("Draw announcement video watched", {
                organizationId: cardData.data?.getMyRewardsOrganization.id,
                userId: cardData.data?.getMyRewardsUser.id,
                drawId: prizeStructure.id,
              });
            }}
            onOpenFullScreen={() => {
              track("Draw announcement video fullscreen opened", {
                organizationId: cardData.data?.getMyRewardsOrganization.id,
                userId: cardData.data?.getMyRewardsUser.id,
                drawId: prizeStructure.id,
              });
            }}
          />
        </div>
      )}

      <Divider
        css={css`
          opacity: 0.3;
        `}
      />
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(2)};
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        <FormattedMessage
          defaultMessage="<draw_ends_in_text>Draw ends in</draw_ends_in_text><day_counter_text><day_counter>{num_days}</day_counter> {num_days, plural, one {day} other {days}}</day_counter_text>"
          description="Draw page > draw active state > draw ends in text"
          values={{
            num_days: daysUntilDrawClose,
            draw_ends_in_text: (nodes) => (
              <Typography variant="subtitle" component="span">
                {nodes}
              </Typography>
            ),
            day_counter_text: (nodes) => (
              <Typography variant="subtitle" component="span">
                {nodes}
              </Typography>
            ),
            day_counter: (nodes) =>
              (nodes[0] as string).split("").map((digit, index) => (
                <div
                  css={(theme: AppTheme) => css`
                    display: inline-block;
                    border: 1px solid white;
                    border-radius: 10px;
                    ${index !== 0 &&
                    css`
                      margin-left: ${theme.spacing(1)};
                    `}
                    width: 30px;
                    height: 36px;
                  `}
                >
                  <Typography
                    variant="subtitle"
                    component="span"
                    css={css`
                      height: 100%;
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    {digit}
                  </Typography>
                </div>
              )),
          }}
        />
      </div>
    </div>
  );
}
