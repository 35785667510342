import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OptInWithPersonalInformationMutationVariables = Types.Exact<{
  workEmail: Types.Scalars['String'];
  personalEmail: Types.Scalars['String'];
  personalPhoneNumber: Types.Scalars['String'];
}>;


export type OptInWithPersonalInformationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'optInWithPersonalInformation'>
);


export const OptInWithPersonalInformationDocument = gql`
    mutation OptInWithPersonalInformation($workEmail: String!, $personalEmail: String!, $personalPhoneNumber: String!) {
  optInWithPersonalInformation(
    workEmail: $workEmail
    personalEmail: $personalEmail
    personalPhoneNumber: $personalPhoneNumber
  )
}
    `;
export type OptInWithPersonalInformationMutationFn = Apollo.MutationFunction<OptInWithPersonalInformationMutation, OptInWithPersonalInformationMutationVariables>;

/**
 * __useOptInWithPersonalInformationMutation__
 *
 * To run a mutation, you first call `useOptInWithPersonalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptInWithPersonalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optInWithPersonalInformationMutation, { data, loading, error }] = useOptInWithPersonalInformationMutation({
 *   variables: {
 *      workEmail: // value for 'workEmail'
 *      personalEmail: // value for 'personalEmail'
 *      personalPhoneNumber: // value for 'personalPhoneNumber'
 *   },
 * });
 */
export function useOptInWithPersonalInformationMutation(baseOptions?: Apollo.MutationHookOptions<OptInWithPersonalInformationMutation, OptInWithPersonalInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptInWithPersonalInformationMutation, OptInWithPersonalInformationMutationVariables>(OptInWithPersonalInformationDocument, options);
      }
export type OptInWithPersonalInformationMutationHookResult = ReturnType<typeof useOptInWithPersonalInformationMutation>;
export type OptInWithPersonalInformationMutationResult = Apollo.MutationResult<OptInWithPersonalInformationMutation>;
export type OptInWithPersonalInformationMutationOptions = Apollo.BaseMutationOptions<OptInWithPersonalInformationMutation, OptInWithPersonalInformationMutationVariables>;