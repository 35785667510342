import { SVGProps } from "react";

export function EarnTicketsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={47}
      height={32}
      fill="none"
      {...props}
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M3.223 3.12c.198-.181.376-.38.44-.634.02-.079.047-.156.082-.23A3.97 3.97 0 0 1 7.287 0h.038c4.281 0 10.23 0 16.176.013C29.448 0 35.395 0 39.676 0h.042a3.97 3.97 0 0 1 3.538 2.257c.034.073.062.15.082.229.063.247.233.442.428.625l.002.002.011.01c.175.161.368.306.6.379.063.02.125.044.185.075a3.96 3.96 0 0 1 2.095 4.295c-.358 2.234-.533 4.86-.553 7.517-.006 3.074.197 6.182.595 8.77l.02.135a3.956 3.956 0 0 1-2.143 4.126 1.153 1.153 0 0 1-.192.076c-.235.072-.43.219-.609.383-.197.183-.376.382-.44.635a1.25 1.25 0 0 1-.081.23A3.967 3.967 0 0 1 39.683 32L23.5 31.988C17.552 32 11.605 32 7.325 32a3.97 3.97 0 0 1-3.58-2.257 1.254 1.254 0 0 1-.083-.229c-.062-.25-.236-.447-.43-.627l-.009-.008c-.178-.164-.374-.311-.609-.383a1.15 1.15 0 0 1-.191-.076A3.962 3.962 0 0 1 .3 24.16c.398-2.59.6-5.697.595-8.771C.875 12.733.7 10.106.342 7.872a3.955 3.955 0 0 1 2.094-4.295c.06-.03.122-.056.186-.075.232-.074.425-.219.601-.381Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M23.518 26.082c5.598 0 10.136-4.538 10.136-10.135 0-5.598-4.538-10.136-10.136-10.136-5.597 0-10.135 4.538-10.135 10.136 0 5.597 4.538 10.135 10.136 10.135Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M35.864 24.819c4.896-4.897 4.896-12.848 0-17.745a1.224 1.224 0 0 0-1.73 1.73c3.942 3.942 3.942 10.343 0 14.285a1.224 1.224 0 0 0 1.73 1.73ZM11.326 7.074c-4.897 4.897-4.897 12.848 0 17.745a1.224 1.224 0 0 0 1.73-1.73c-3.943-3.942-3.943-10.342 0-14.284a1.224 1.224 0 0 0-1.73-1.73Z"
        clipRule="evenodd"
      />
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M23.32 10.05a.263.263 0 0 0-.24.164L21.75 13.78h-4.265a.263.263 0 0 0-.251.163.224.224 0 0 0 .094.265l3.464 2.262-1.331 3.691a.223.223 0 0 0 .097.265c.093.06.219.06.312 0l3.464-2.262 3.464 2.261a.29.29 0 0 0 .312 0 .223.223 0 0 0 .097-.264l-1.331-3.692 3.464-2.262a.224.224 0 0 0 .094-.264.263.263 0 0 0-.251-.163h-4.265l-1.332-3.567c-.036-.101-.145-.168-.265-.163Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
