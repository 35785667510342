/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

import HelpIcon from "../help-icon";

interface NoPersonalInfoModalProps {
  open: boolean;
  onClose: () => void;
  whiteLabelledTitle?: string;
}

export function NoPersonalInfoModal({
  open,
  onClose,
  whiteLabelledTitle,
}: NoPersonalInfoModalProps): JSX.Element {
  const { formatMessage } = useFormatters();

  return (
    <Modal width="365px" open={open} onClose={onClose}>
      <HelpIcon
        css={(theme: AppTheme) =>
          css`
            align-self: center;
            margin-bottom: ${theme.spacing(1)};
            margin-top: ${theme.spacing(2.5)};
          `
        }
      />
      <ModalTitle fontSize="2em">
        <FormattedMessage
          description="Opt in page > modal if tried to submit without providing all personal info > title"
          defaultMessage="Trouble signing up?"
        />
      </ModalTitle>
      <ModalContent>
        <Typography
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
              font-family: ${headerFontFamily};
              font-size: 1.2em;
            `
          }
        >
          <FormattedMessage
            description="Opt in page > modal if tried to submit without providing all personal info > detail 1"
            defaultMessage="We recommend using your personal email and phone number to access {rewards_program_name} so that you can easily access your personal contacts and social media when making referrals."
            values={{
              rewards_program_name: whiteLabelledTitle || "Caribou Rewards",
            }}
          />
        </Typography>

        <Typography
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
            font-family: ${headerFontFamily};
            font-size: 1.2em;
          `}
        >
          <FormattedMessage
            description="Opt in page > modal if tried to submit without providing all personal info > detail 2"
            defaultMessage="If you are unable to create an account, {rewards_program_name} will be in touch shortly to help you get set up!"
            values={{
              rewards_program_name: whiteLabelledTitle || "Caribou Rewards",
            }}
          />
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          size="large"
          color="primary"
          label={formatMessage({
            description:
              "Opt in page > modal if tried to submit without providing all personal info > close button",
            defaultMessage: "Okay",
          })}
          onClick={onClose}
          css={css`
            font-family: ${headerFontFamily};
          `}
        />
      </ModalActions>
    </Modal>
  );
}
