/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { MilestoneRewardPoints } from "../../../../../../shared/milestone-rewards-offer/milestone-reward-points";
import friendsImageUrl from "../../graphics/friends.png";

interface MilestoneRewardOfferCardCompleteProps {
  totalPoints: number;
  pointsPerDollar: number;
}

export function MilestoneRewardOfferCardComplete({
  totalPoints,
  pointsPerDollar,
}: MilestoneRewardOfferCardCompleteProps) {
  const { formatMessage } = useFormatters();

  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: ${appTheme.spacing(2.5)};
      `}
    >
      <div>
        <Typography
          color="tertiary.main"
          css={css`
            text-transform: uppercase;
          `}
          variant="caption"
        >
          {formatMessage({
            defaultMessage: "nice work!",
            description:
              "Rewards page > Milestones rewards offer card > nice work!",
          })}
        </Typography>
        <Typography
          css={(appTheme: AppTheme) => css`
            margin-bottom: ${appTheme.spacing(0.5)};
          `}
          variant="subtitle"
        >
          {formatMessage({
            defaultMessage: "All milestones completed",
            description:
              "Rewards page > Milestones rewards offer card > all milestones completed",
          })}
        </Typography>
        <MilestoneRewardPoints
          points={totalPoints}
          pointsPerDollar={pointsPerDollar}
        />
      </div>
      <img
        css={(appTheme: AppTheme) => css`
          width: 83px;
          margin: ${appTheme.spacing(0, 2)};
          align-self: center;
          margin-left: 0;
        `}
        src={friendsImageUrl}
        alt={formatMessage({
          defaultMessage: "hands clapping",
          description:
            "Rewards page > Milestones rewards offer card > hands clapping alt",
        })}
      />
    </div>
  );
}
