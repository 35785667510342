import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateJobShareLinkMutationVariables = Types.Exact<{
  jobPostingId: Types.Scalars['ID'];
}>;


export type CreateJobShareLinkMutation = (
  { __typename?: 'Mutation' }
  & { createJobShareLink?: Types.Maybe<(
    { __typename?: 'CreateJobShareResponse' }
    & Pick<Types.CreateJobShareResponse, 'jobShareId' | 'jobShareUrl'>
  )> }
);


export const CreateJobShareLinkDocument = gql`
    mutation CreateJobShareLink($jobPostingId: ID!) {
  createJobShareLink(jobPostingId: $jobPostingId) {
    jobShareId
    jobShareUrl
  }
}
    `;
export type CreateJobShareLinkMutationFn = Apollo.MutationFunction<CreateJobShareLinkMutation, CreateJobShareLinkMutationVariables>;

/**
 * __useCreateJobShareLinkMutation__
 *
 * To run a mutation, you first call `useCreateJobShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobShareLinkMutation, { data, loading, error }] = useCreateJobShareLinkMutation({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *   },
 * });
 */
export function useCreateJobShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobShareLinkMutation, CreateJobShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobShareLinkMutation, CreateJobShareLinkMutationVariables>(CreateJobShareLinkDocument, options);
      }
export type CreateJobShareLinkMutationHookResult = ReturnType<typeof useCreateJobShareLinkMutation>;
export type CreateJobShareLinkMutationResult = Apollo.MutationResult<CreateJobShareLinkMutation>;
export type CreateJobShareLinkMutationOptions = Apollo.BaseMutationOptions<CreateJobShareLinkMutation, CreateJobShareLinkMutationVariables>;