/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { BackNavigation } from "@rewards-web/shared/components/back-navigation";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface SubPageHeaderContentProps {
  pageName: string;
  backLinkTitle?: string;
  onBackClick?: () => void;
  color?: "primary" | "white";
}

export function SubPageHeaderContent({
  pageName,
  backLinkTitle,
  onBackClick,
  color = "white",
}: SubPageHeaderContentProps): JSX.Element {
  const navigate = useNavigate();
  const track = useTrack();

  return (
    <div
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(2.25)};
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        position: relative;
        background-color: ${color === "primary"
          ? theme.palette.primary.main
          : "white"};
      `}
    >
      {backLinkTitle && (
        <div
          css={(theme: AppTheme) => css`
            margin-left: ${theme.spacing(2)};
            position: absolute;
            display: flex;
            align-items: center;
          `}
        >
          <BackNavigation
            fixedToTop={false}
            height={60}
            maxContentWidth={190}
            title={backLinkTitle}
            variant="button"
            color={color}
            onBackClicked={() => {
              track("Navigation back pressed", {
                pageName,
              });
              if (onBackClick) {
                onBackClick();
              }
              navigate(-1);
            }}
          />
        </div>
      )}
      <Typography
        variant="h6"
        color={color === "primary" ? "white" : "text.primary"}
        css={css`
          margin: auto;
        `}
      >
        {pageName}
      </Typography>
    </div>
  );
}
