/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

const MAX_CONTENT_WIDTH = 600;

interface UnauthenticatedPageLayoutProps {
  children: ReactNode;
}

export function UnauthenticatedPageLayout({
  children,
}: UnauthenticatedPageLayoutProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        background: ${theme.palette.background.paper};
        height: 100%;
        overflow: scroll;
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          position: relative;
          height: 100%;
          width: calc(100% - ${theme.spacing(4)});
          max-width: ${MAX_CONTENT_WIDTH}px;
          margin: 0 auto;

          padding-top: ${theme.spacing(6)};
          ${theme.breakpoints.up("md")} {
            padding-top: ${theme.spacing(10)};
          }
          padding-bottom: ${theme.spacing(4)};
        `}
      >
        {children}
      </div>
    </div>
  );
}
