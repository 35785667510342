/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button as MuiButton } from "@material-ui/core";

import { Typography } from "@rewards-web/shared/components/typography";
import { TestProps, StylableProps } from "@rewards-web/shared/types";

import { AppTheme } from "../../../../../shared/style/theme";
import CheckIcon from "../icons/check-icon";
import CopyIcon from "../icons/copy-icon";
import EmailIcon from "../icons/email-icon";
import SmsIcon from "../icons/sms-icon";

export interface ShareButtonProps extends StylableProps, TestProps {
  label: string;
  onClick: () => void;
  type: "copy" | "message" | "email" | "copied";
  disabled: boolean;
}

export function ShareButton({
  label,
  className,
  onClick,
  type = "message",
  disabled,
  cypressId,
}: ShareButtonProps): JSX.Element {
  return (
    <MuiButton
      data-cy={cypressId}
      className={className}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      css={(theme: AppTheme) => css`
        margin-right: ${theme.spacing(2)};
        margin-left: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(1)};
        min-width: 90%;
        height: 50px;
        text-transform: none;
        background-color: white;
        border-radius: 5px;
        border-color: ${theme.palette.divider};
        font-size: 1.25em;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 80% 20%;
          width: 100%;
          margin: 5px;
        `}
      >
        <div
          css={css`
            align-self: center;
          `}
        >
          <Typography
            css={css`
              font-size: 0.8em;
            `}
            align="left"
          >
            {label}
          </Typography>
        </div>
        <div
          css={css`
            font-size: 5px;
            text-align: right;
            opacity: ${disabled ? 0.26 : 1};
          `}
        >
          {type === "message" && <SmsIcon />}
          {type === "copy" && <CopyIcon />}
          {type === "copied" && <CheckIcon />}
          {type === "email" && <EmailIcon />}
        </div>
      </div>
    </MuiButton>
  );
}
