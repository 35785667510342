/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function BriefcaseIcon() {
  const theme = useTheme();
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 3.625C17.4805 3.625 18.5 4.64453 18.5 5.875V14.875C18.5 16.1406 17.4805 17.125 16.25 17.125H2.75C1.48438 17.125 0.5 16.1406 0.5 14.875V5.875C0.5 4.64453 1.48438 3.625 2.75 3.625H5V2.5C5 1.26953 5.98438 0.25 7.25 0.25H11.75C12.9805 0.25 14 1.26953 14 2.5V3.625H16.25ZM7.25 1.9375C6.93359 1.9375 6.6875 2.21875 6.6875 2.5V3.625H12.3125V2.5C12.3125 2.21875 12.0312 1.9375 11.75 1.9375H7.25ZM2.75 5.3125C2.43359 5.3125 2.1875 5.59375 2.1875 5.875V9.25H16.8125V5.875C16.8125 5.59375 16.5312 5.3125 16.25 5.3125H2.75ZM16.25 15.4375C16.5312 15.4375 16.8125 15.1914 16.8125 14.875V10.9375H11.75V11.5C11.75 12.1328 11.2227 12.625 10.625 12.625H8.375C7.74219 12.625 7.25 12.1328 7.25 11.5V10.9375H2.1875V14.875C2.1875 15.1914 2.43359 15.4375 2.75 15.4375H16.25Z"
        fill={theme.palette.text.primary}
      />
    </svg>
  );
}
