import { useTheme } from "@emotion/react";
import { SVGProps } from "react";

function BagIcon(props: SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  return (
    <svg
      width={43}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.768 25.642a2.966 2.966 0 0 0 1.698-2.678A2.965 2.965 0 0 0 21.5 20a2.965 2.965 0 0 0-2.966 2.964c0 1.181.695 2.203 1.698 2.678l-.784 3.271c-.094.396.162.717.562.717h2.98c.405 0 .657-.32.563-.717l-.785-3.27ZM11.862 8.888V4.441A4.44 4.44 0 0 1 16.303 0h10.394a4.444 4.444 0 0 1 4.441 4.44v4.448h1.483V40H10.38V8.888h1.483Zm2.967 0h13.343V4.441a1.48 1.48 0 0 0-1.475-1.479H16.303c-.815 0-1.475.661-1.475 1.479v4.447Zm20.757 0h4.443c1.639 0 2.971 1.331 2.971 2.966v25.18A2.97 2.97 0 0 1 40.029 40h-4.443V8.888ZM2.97 40A2.97 2.97 0 0 1 0 37.034v-25.18a2.97 2.97 0 0 1 2.971-2.966h4.443V40H2.971Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
}

export default BagIcon;
