import * as React from "react";

function BronzeMedalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.098 9.465L0 18.473l4.63-.4 2.239 3.82 5.098-9.007-6.87-3.421z"
        fill="#A46800"
      />
      <path
        d="M5.682 19.27a.575.575 0 00.012-.448.625.625 0 00-.318-.335l-.293-.146a.597.597 0 01-.3-.351.556.556 0 01.052-.447.62.62 0 01.374-.282.664.664 0 01.477.05l.83.412a.551.551 0 00.58-.057.498.498 0 00.129-.15l.02-.037a.446.446 0 00.056-.366.455.455 0 00-.091-.168.489.489 0 00-.156-.12l-2.48-1.234a.627.627 0 01-.318-.334.577.577 0 01.009-.448.595.595 0 01.151-.19.64.64 0 01.47-.147c.085.006.167.029.243.066l2.3 1.145c.123.06.268.072.4.034a.527.527 0 00.313-.239l.013-.03v-.008a.494.494 0 00-.06-.572.546.546 0 00-.167-.128l-1.29-.642a.627.627 0 01-.32-.336.577.577 0 01.012-.45.598.598 0 01.152-.19.643.643 0 01.47-.147.66.66 0 01.242.066l1.426.71a.56.56 0 00.595-.072.504.504 0 00.128-.158v-.014a.48.48 0 00.053-.385.488.488 0 00-.095-.177.525.525 0 00-.161-.128l-1.538-.765a.626.626 0 01-.316-.334.576.576 0 01.01-.446.595.595 0 01.152-.19.64.64 0 01.47-.147c.084.006.167.029.242.066l3.794 1.888-5.098 9.008L5.462 19.5a.575.575 0 00.22-.23z"
        fill="#A46800"
      />
      <path
        d="M14.634 9.465l5.098 9.008-4.63-.4-2.239 3.82-5.097-9.007 6.868-3.421z"
        fill="#A46800"
      />
      <path
        d="M14.052 19.27a.575.575 0 01-.012-.45.625.625 0 01.32-.336l.293-.146a.597.597 0 00.3-.351.555.555 0 00-.052-.447.621.621 0 00-.374-.282.665.665 0 00-.477.05l-.83.412a.547.547 0 01-.709-.207l-.02-.037a.448.448 0 01-.056-.366.452.452 0 01.091-.168.49.49 0 01.156-.12l2.48-1.235a.627.627 0 00.319-.334.577.577 0 00-.01-.448.595.595 0 00-.151-.19.665.665 0 00-.713-.081l-2.3 1.145a.564.564 0 01-.4.034.527.527 0 01-.313-.239l-.013-.03v-.008a.495.495 0 01.06-.572.546.546 0 01.168-.128l1.29-.642a.627.627 0 00.319-.336.577.577 0 00-.012-.45.598.598 0 00-.152-.19.643.643 0 00-.47-.147.665.665 0 00-.242.066l-1.426.711a.56.56 0 01-.596-.071.505.505 0 01-.127-.159v-.014a.48.48 0 01-.054-.385.49.49 0 01.095-.177.528.528 0 01.161-.128l1.538-.765a.626.626 0 00.316-.333.576.576 0 00-.01-.447.595.595 0 00-.152-.19.665.665 0 00-.713-.081l-3.793 1.888 5.098 9.008 1.405-2.395a.587.587 0 01-.232-.23z"
        fill="#A46800"
      />
      <path
        d="M9.865 15.976c4.704 0 8.517-3.576 8.517-7.988C18.382 3.576 14.57 0 9.865 0 5.161 0 1.348 3.576 1.348 7.988c0 4.412 3.813 7.988 8.517 7.988z"
        fill="#C97800"
      />
      <path
        d="M11.021 7.072H5.623a.793.793 0 01-.54-.21.697.697 0 01-.225-.507l.005-.05v-.01a.707.707 0 01.059-.289.746.746 0 01.173-.245.803.803 0 01.26-.164.844.844 0 01.307-.057h3.742a.983.983 0 00.65-.228.869.869 0 00.31-.582.81.81 0 00-.057-.34.852.852 0 00-.194-.293.918.918 0 00-.301-.197.967.967 0 00-.36-.07H5.496a.814.814 0 01-.3-.062.773.773 0 01-.25-.166.718.718 0 01-.161-.244.682.682 0 01-.047-.283v-.022a.682.682 0 01.047-.284.718.718 0 01.161-.244.773.773 0 01.25-.166.814.814 0 01.3-.061H9.64a.982.982 0 00.647-.224.869.869 0 00.313-.576.806.806 0 00-.055-.341.848.848 0 00-.194-.293.915.915 0 00-.302-.197.964.964 0 00-.36-.07H6.652A8.426 8.426 0 002.813 3.54a7.64 7.64 0 00-1.442 4.447 7.64 7.64 0 001.442 4.447 8.426 8.426 0 003.839 2.94h3.855a.982.982 0 00.647-.223.868.868 0 00.313-.576.808.808 0 00-.055-.341.848.848 0 00-.194-.293.913.913 0 00-.302-.197.962.962 0 00-.36-.07H6.17a.96.96 0 01-.36-.069.91.91 0 01-.3-.197.844.844 0 01-.193-.293.803.803 0 01-.054-.34.866.866 0 01.31-.581.98.98 0 01.65-.225h1.7a.933.933 0 00.599-.277c.155-.16.24-.369.236-.585a.826.826 0 00-.254-.579.936.936 0 00-.607-.26h-2.58a.802.802 0 01-.539-.21.71.71 0 01-.164-.232.672.672 0 01-.056-.274v-.06a.635.635 0 01.04-.271.667.667 0 01.153-.234.72.72 0 01.24-.157.759.759 0 01.285-.055h5.694a.984.984 0 00.647-.224.87.87 0 00.313-.576.806.806 0 00-.055-.341.848.848 0 00-.193-.293.915.915 0 00-.3-.198.963.963 0 00-.36-.07z"
        fill="#C97800"
      />
      <path
        d="M9.867 13.69a6.368 6.368 0 01-3.377-.96 5.795 5.795 0 01-2.238-2.559 5.372 5.372 0 01-.346-3.293A5.6 5.6 0 015.569 3.96 6.212 6.212 0 018.681 2.4a6.453 6.453 0 013.512.324 6.01 6.01 0 012.727 2.1 5.452 5.452 0 011.024 3.166c-.002 1.511-.643 2.96-1.782 4.028-1.14 1.068-2.684 1.67-4.295 1.672z"
        fill="#F90"
      />
      <path
        opacity={0.2}
        d="M15.942 7.989c-.001-1.455-.595-2.854-1.66-3.911-1.066-1.057-2.522-1.692-4.07-1.775h-.567a.75.75 0 00-.493.172.662.662 0 00-.236.44.615.615 0 00.042.26.647.647 0 00.148.222.735.735 0 00.503.202h3.347a.73.73 0 01.502.203c.065.064.115.14.147.223a.61.61 0 01.042.259.66.66 0 01-.236.442.747.747 0 01-.495.173H11.62a.716.716 0 00-.488.197.63.63 0 00-.196.462.632.632 0 00.21.457c.132.12.309.186.493.184h1.966a.611.611 0 01.41.16.513.513 0 01.168.386v.045a.483.483 0 01-.147.384.578.578 0 01-.4.162H9.294a.75.75 0 00-.496.17.663.663 0 00-.239.443.615.615 0 00.043.259.647.647 0 00.148.222.735.735 0 00.503.203h4.116c.155 0 .302.058.411.16.11.103.171.241.171.386v.046a.54.54 0 01-.176.407.613.613 0 01-.433.169h-2.853a.75.75 0 00-.497.172.663.663 0 00-.237.445.618.618 0 00.192.48.738.738 0 00.502.203h3.022a.623.623 0 01.418.174.521.521 0 01.16.402v.017a.52.52 0 01-.16.402.621.621 0 01-.418.174h-3.163a.75.75 0 00-.493.172.662.662 0 00-.236.44.615.615 0 00.042.26.647.647 0 00.149.222.735.735 0 00.503.203h.834a6.167 6.167 0 003.47-1.986 5.497 5.497 0 001.365-3.592z"
        fill="#F90"
      />
      <path
        d="M14.111 6.722h-2.956l-.915-2.638a.37.37 0 00-.143-.184.41.41 0 00-.461 0 .37.37 0 00-.143.184L8.58 6.722H5.623a.41.41 0 00-.23.07.37.37 0 00-.143.185.346.346 0 000 .227.37.37 0 00.143.184l2.394 1.634-.913 2.638a.346.346 0 000 .228.37.37 0 00.143.184.41.41 0 00.461 0l2.393-1.63 2.393 1.63a.41.41 0 00.461 0 .37.37 0 00.143-.184.346.346 0 000-.228l-.922-2.638 2.393-1.63a.37.37 0 00.147-.183.346.346 0 00.002-.23.37.37 0 00-.143-.186.41.41 0 00-.234-.07z"
        fill="#A46800"
      />
    </svg>
  );
}

export default BronzeMedalIcon;
