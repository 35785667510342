/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import blueHexagon from "./hexagon-blue.png";
import yellowHexagon from "./hexagon-yellow.png";
import YellowCheck from "./yellow-check";

export interface ShiftMilestonesBadgeProps {
  state: "completed" | "in-progress" | "future";
  shiftMilestoneId: string;
  imageUrl: string;
  imagePositionTop?: boolean;
  numberOfPoints?: number;
  numberOfHours?: number;
}

export function ShiftMilestonesBadge({
  state,
  imageUrl,
  shiftMilestoneId,
  numberOfHours,
  numberOfPoints,
  imagePositionTop = true,
}: ShiftMilestonesBadgeProps): JSX.Element {
  const badgeState:
    | "completed"
    | "in-progress"
    | "future"
    | "unknown" = (() => {
    if (!numberOfHours && !numberOfPoints) {
      return "unknown";
    } else return state;
  })();

  const track = useTrack();
  const badgeRef = useRef<HTMLDivElement>(null);

  const onScreen = useOnScreen(badgeRef, { threshold: 0.3 });

  useEffect(() => {
    if (onScreen && shiftMilestoneId) {
      track("Viewed shift milestones badge", {
        status: badgeState,
        shiftMilestoneId,
      });
    }
  }, [track, badgeState, onScreen, shiftMilestoneId]);

  return (
    <div
      css={css`
        background-image: url("${badgeState === "completed"
          ? yellowHexagon
          : blueHexagon}");
        height: 196px;
        width: 196px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        ${(badgeState === "future" || badgeState === "unknown") &&
        css`
          opacity: 0.4;
        `}
      `}
      ref={badgeRef}
      onClick={() =>
        track("Clicked shift milestones badge", {
          status: badgeState,
          shiftMilestoneId,
        })
      }
    >
      {badgeState === "completed" && (
        <div
          css={css`
            position: relative;
          `}
        >
          <YellowCheck
            css={css`
              position: absolute;
              right: 23px;
              top: -8px;
            `}
          />
        </div>
      )}

      <div
        css={(theme: AppTheme) => css`
          padding-top: ${theme.spacing(4.5)};
          text-align: center;
          height: 100%;
        `}
      >
        {imagePositionTop && (
          <img
            css={css`
              max-height: 65px;
            `}
            src={imageUrl}
            alt="starboy"
          />
        )}

        <Typography
          variant="body"
          css={css`
            color: #fff;
            line-height: normal;
            *:first-child {
              font-size: 1.2em;
            }
          `}
        >
          {badgeState === "unknown" || !numberOfPoints ? (
            <strong>???</strong>
          ) : (
            <FormattedMessage
              description="Shift milestone badge > number of points"
              defaultMessage="{points, number} {points, plural, one {point} other {points}}"
              values={{ points: numberOfPoints }}
            />
          )}
        </Typography>
        <Typography
          variant="body"
          css={(theme: AppTheme) => css`
            color: #fff;
            margin-bottom: ${theme.spacing(1)};
            line-height: normal;

            *:first-child {
              font-size: 1.2em;
            }
          `}
        >
          {badgeState === "unknown" || !numberOfHours ? (
            <strong>???</strong>
          ) : (
            <FormattedMessage
              description="Shift milestone badge > number of hours"
              defaultMessage="{hours, number} {hours, plural, one {hour} other {hours}}"
              values={{ hours: numberOfHours }}
            />
          )}
        </Typography>
        {!imagePositionTop && (
          <img
            css={css`
              max-height: 65px;
            `}
            src={imageUrl}
            alt="starboy"
          />
        )}
      </div>
    </div>
  );
}
