/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  useOfferActionHandler,
  useOfferActionLinkProps,
} from "../../../../shared/reward-offer-action";
import { OfferReward } from "../offer-reward";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import { getRewardOfferGraphicUrl } from "./lib";

export interface RewardsOfferCardProps {
  offer: NonNullable<RewardsPageDataQuery["listMyRewardOffers"][number]>;
}

export function RewardsOfferCard({ offer }: RewardsOfferCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const track = useTrack();
  const onScreen = useOnScreen(ref, { threshold: 0.5 });

  const trackingProperties = useMemo(
    () => ({
      offerCardTitle: offer.cardTitle,
      offerCardButtonLabel: offer.cardButtonLabel,
      offerId: offer.id,
      completed: offer.completed,
      graphicKey: offer.cardGraphicKey,
      version: 2,
    }),
    [offer]
  );

  const handleAction = useOfferActionHandler();
  const offerActionLinkProps = useOfferActionLinkProps();

  useEffect(() => {
    if (onScreen) {
      track("Viewed rewards page offer card", trackingProperties);
    }
  }, [track, onScreen, trackingProperties]);

  return (
    <div
      ref={ref}
      onClick={() => {
        track("Clicked rewards page card", {
          ...(trackingProperties ?? {}),
        });

        handleAction();
      }}
      css={(appTheme: AppTheme) =>
        css`
          margin-top: ${appTheme.spacing(1.25)};
          border: 1.5px solid ${appTheme.palette.divider};
          padding: ${appTheme.spacing(2.5)};
          border-radius: 10px;
          background-color: white;
        `
      }
    >
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        `}
      >
        <div>
          <Typography
            css={(appTheme: AppTheme) =>
              css`
                margin-bottom: ${appTheme.spacing(0.5)};
              `
            }
            variant="subtitle"
          >
            {offer.cardTitle}
          </Typography>
          <OfferReward
            isCompleted={offer.completed}
            nextReward={offer.nextReward}
            value={{
              type: "custom_text",
              text:
                offer.completed && offer.cardCompletedPointsText
                  ? offer.cardCompletedPointsText
                  : offer.cardPointsText || "",
            }}
          />
          <Button
            css={(appTheme: AppTheme) =>
              css`
                margin-top: ${appTheme.spacing(2.5)};
              `
            }
            color="secondary"
            label={offer.cardButtonLabel}
            {...offerActionLinkProps}
            target="_blank"
            width="auto"
            onClick={(e) => {
              track("Clicked rewards page card button", {
                ...(trackingProperties ?? {}),
              });
              e.stopPropagation();

              if (!offerActionLinkProps.linkTo) {
                handleAction();
              }
            }}
          />
        </div>
        <img
          src={getRewardOfferGraphicUrl(offer.cardGraphicKey)}
          alt="megaphone icon"
          css={(appTheme: AppTheme) => css`
            width: 83px;
            margin: ${appTheme.spacing(0, 2)};
            align-self: center;
          `}
        />
      </div>
    </div>
  );
}
