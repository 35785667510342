/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme, headerFontFamily } from "@rewards-web/shared/style/theme";

import BagIcon from "./bag-icon";

interface EditEmailInfoModalProps {
  workEmail: string;
  open: boolean;
  onClose: () => void;
}

export function UnableToEditEmailModal({
  workEmail,
  open,
  onClose,
}: EditEmailInfoModalProps): JSX.Element {
  const { formatMessage } = useFormatters();

  const content = (() => {
    return (
      <>
        <BagIcon
          css={(theme: AppTheme) =>
            css`
              align-self: center;
              margin-bottom: ${theme.spacing(1)};
              margin-top: ${theme.spacing(2.5)};
            `
          }
        />
        <ModalTitle
          fontSize="2em"
          css={css`
            text-align: center;
          `}
        >
          <FormattedMessage
            description="Opt in page > unable to edit work email modal > title"
            defaultMessage="Why can't I edit my work email?"
          />
        </ModalTitle>
        <ModalContent>
          <Typography
            variant="body"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
              font-family: ${headerFontFamily};
              font-size: 1.2em;
            `}
          >
            <FormattedMessage
              description="Opt in page > unable to edit work email modal > detail"
              defaultMessage="Your account must be created with your work email so we know it's you! If this is not your email please contact <mailto>{email}</mailto>"
              values={{
                email: "help@caribou.care",
                mailto: (nodes) => (
                  <a href={`mailto:${encodeURI("help@caribou.care")}`}>
                    {nodes}
                  </a>
                ),
              }}
            />
          </Typography>
        </ModalContent>
        <ModalActions>
          <Button
            size="large"
            color="primary"
            label={formatMessage({
              description:
                "Opt in page > unable to edit work email > dismiss button label",
              defaultMessage: "Okay",
            })}
            onClick={onClose}
          />
        </ModalActions>
      </>
    );
  })();

  return (
    <Modal width="365px" open={open} onClose={onClose}>
      {content}
    </Modal>
  );
}
