/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { getMilestoneRewardCompletePercentage } from "../../../../shared/milestone-rewards-offer/lib";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import { MilestoneRewardOfferCardContent } from "./milestone-reward-offer-card-content";
import { useGetMilestoneRewardsForRewardsPageOfferCardQuery } from "./milestone-rewards-offer-card-data.generated";

export interface MilestoneRewardsOfferCardProps {
  offer: RewardsPageDataQuery["listMyRewardOffers"][0];
}

export function MilestoneRewardsOfferCard({
  offer,
}: MilestoneRewardsOfferCardProps) {
  const track = useTrack();
  const { formatMessage } = useFormatters();
  const ref = useRef<HTMLDivElement>(null);

  const query = useGetMilestoneRewardsForRewardsPageOfferCardQuery({
    onError: reportError,
  });

  const milestoneRewardsOffer =
    query.data?.rewardsPageMilestoneRewardsCardData ?? undefined;

  const trackingProperties = useMemo(
    () => ({
      offerCardTitle: offer.cardTitle,
      offerCardButtonLabel: offer.cardButtonLabel,
      offerId: offer.id,
      offerCompleted: offer.completed,
      mustCompleteEVV: milestoneRewardsOffer?.mustCompleteEVV,
      currentMilestoneId: milestoneRewardsOffer?.currentMilestoneStep?.id,
      currentMilestoneProgress:
        milestoneRewardsOffer?.currentMilestoneStep?.progress,
      currentMilestoneCompletionPercent: getMilestoneRewardCompletePercentage(
        milestoneRewardsOffer?.currentMilestoneStep
      ),
      nextMilestoneId: milestoneRewardsOffer?.nextMilestoneStep?.id,
    }),
    [offer, milestoneRewardsOffer]
  );

  const onScreen = useOnScreen(ref, { threshold: 0.5 });

  useEffect(() => {
    if (onScreen && milestoneRewardsOffer) {
      track(
        "Viewed rewards page milestone rewards offer card",
        trackingProperties
      );
    }
  }, [track, onScreen, trackingProperties, milestoneRewardsOffer]);

  const viewAllMilestonesButton = (
    <Link
      css={(appTheme: AppTheme) => css`
        padding: ${appTheme.spacing(2.5)};
        border-top: 1px solid ${appTheme.palette.divider};
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        text-decoration: none;
      `}
      to="/milestones"
      onClick={() =>
        track("Clicked rewards page milestone card view my milestones")
      }
    >
      <Typography variant="subtitle">
        {formatMessage({
          defaultMessage: "View my milestones",
          description:
            "Rewards page > Milestone rewards offer card > View my milestones",
        })}
      </Typography>
      <FontAwesomeIcon icon={faChevronRight} />
    </Link>
  );

  return (
    <div
      ref={ref}
      css={(appTheme: AppTheme) => css`
        margin-top: ${appTheme.spacing(1.25)};
        border: 1.5px solid ${appTheme.palette.divider};
        padding: ${appTheme.spacing(2.5)};
        border-radius: 10px;
        background-color: white;
        padding: 0;
      `}
    >
      <MilestoneRewardOfferCardContent userMilestoneRewardsQuery={query} />
      {viewAllMilestonesButton}
    </div>
  );
}
