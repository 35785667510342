/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  DrawPrizePoints,
  DrawTieredPrizePoints,
} from "@rewards-web/shared/graphql-types";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../shared/points-icon";
import { RewardsPageDataQuery } from "../rewards-page-data.generated";
import drawTrophyImageUrl from "./graphic/draw-two-trophies.png";

export interface DrawCardProps {
  getMyRewardsOrganization: RewardsPageDataQuery["getMyRewardsOrganization"];
  currentDraw: RewardsPageDataQuery["currentDraw"];
}

export function DrawCard({
  getMyRewardsOrganization,
  currentDraw,
}: DrawCardProps) {
  const { formatMessage, formatNumber } = useFormatters();

  const cardRef = useRef<HTMLDivElement>(null);

  const track = useTrack();

  const onScreen = useOnScreen(cardRef, { threshold: 0.3 });

  useEffect(() => {
    if (onScreen && currentDraw) {
      track("Viewed rewards page draw card", { draw_id: currentDraw.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, onScreen]);
  if (!currentDraw) {
    return null;
  }
  const prizeStructure = currentDraw.prizeStructure;
  if (!prizeStructure) {
    return null;
  }

  const grandPrize = (() => {
    switch (currentDraw.prizeStructure.__typename) {
      case "DrawPrizeStructureTieredPrizesMultipleWinners": {
        return (currentDraw.prizeStructure.prizes[0] as DrawTieredPrizePoints)
          .pointValue;
      }
      case "DrawPrizeStructureSinglePrizeMultipleWinners": {
        return (currentDraw.prizeStructure.prize as DrawPrizePoints).pointValue;
      }
      default:
        return 0;
    }
  })();

  return (
    <div
      ref={cardRef}
      css={(appTheme: AppTheme) => css`
        border-radius: 12px;
        border: 1px solid ${appTheme.palette.divider};
        margin-bottom: ${appTheme.spacing(5)};
        background-color: ${appTheme.palette.primary.main};
      `}
    >
      <div
        css={(appTheme: AppTheme) =>
          css`
            padding: ${appTheme.spacing(2)};
            display: flex;
            justify-content: space-between;
          `
        }
      >
        <div
          css={(appTheme: AppTheme) =>
            css`
              display: flex;
              flex-direction: column;
            `
          }
        >
          <Typography variant="h4" color="white">
            <FormattedMessage
              defaultMessage="Win up to {prize_value}"
              description="Rewards page > Draw card >  header"
              values={{
                prize_value: formatDollars(
                  grandPrize / getMyRewardsOrganization.pointsPerDollar
                ),
              }}
            />
          </Typography>
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              column-gap: ${theme.spacing(1)};
            `}
          >
            <div
              css={css`
                width: 20px;
                height: 25px;
                margin-top: 5px;
              `}
            >
              <PointsIcon />
            </div>
            <Typography variant="body" color="white">
              <FormattedMessage
                defaultMessage="{total_point_value} points to be awarded"
                description="Rewards page > Draw card > points to be awarded"
                values={{
                  total_point_value: formatNumber(
                    currentDraw.prizeStructure.totalPrizePointValue
                  ),
                }}
              />
            </Typography>
          </div>

          <div
            css={(appTheme: AppTheme) => css`
              margin-top: ${appTheme.spacing(2)};
              width: fit-content;
            `}
          >
            <Button
              label={formatMessage({
                defaultMessage: "Join in the draw",
                description:
                  "Rewards page > Draw card > Draw card button label",
              })}
              color="secondary"
              linkTo="/draw/current-draw"
              css={(theme: AppTheme) => css`
                @media (max-width: 405px) {
                  padding: 5px;
                }
                @media (max-width: 365px) {
                  padding: 3px;
                }
              `}
              onClick={() => {
                track("Clicked rewards page draw card", {
                  drawId: currentDraw.id,
                });
              }}
            />
          </div>
        </div>
        <img
          src={drawTrophyImageUrl}
          alt={formatMessage({
            defaultMessage: "Trophies",
            description: "Rewards page > Draw card > trophy image alt",
          })}
          css={css`
            position: relative;
            max-width: 45%;
            max-height: 150px;
            flex-shrink: 0;
            object-fit: contain;
          `}
        />
      </div>
    </div>
  );
}
