/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

export function EVVDisclaimer() {
  const { formatMessage } = useFormatters();

  return (
    <div
      css={(appTheme: AppTheme) => css`
        background-color: #eef3f3;
        border-radius: 10px;
        padding: ${appTheme.spacing(2)};
        margin: ${appTheme.spacing(2, 3.5)};
      `}
    >
      <Typography
        css={(appTheme: AppTheme) => css`
          margin-bottom: ${appTheme.spacing(0.5)};
        `}
        variant="subtitle"
      >
        {formatMessage({
          description:
            "Milestone rewards offer page > EVV disclaimer > important note",
          defaultMessage: "Important note",
        })}
      </Typography>
      <Typography>
        {formatMessage({
          description:
            "Milestone rewards offer page > EVV disclaimer > content",
          defaultMessage: "Clock in and out on time to qualify",
        })}
      </Typography>
    </div>
  );
}
