import * as Types from '@rewards-web/shared/graphql-types';

import { OptInOrganizationFragmentFragment } from './opt-in-organization-fragment.generated';
import { gql } from '@apollo/client';
import { OptInOrganizationFragmentFragmentDoc } from './opt-in-organization-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OptInPageDataForWorkEmailQueryVariables = Types.Exact<{
  workEmail: Types.Scalars['String'];
}>;


export type OptInPageDataForWorkEmailQuery = (
  { __typename?: 'Query' }
  & { getRewardsOrganizationOptInPromo?: Types.Maybe<(
    { __typename?: 'RewardsOrganizationOptInPromo' }
    & Pick<Types.RewardsOrganizationOptInPromo, 'pointsEarnedForOptingIn'>
  )>, organization?: Types.Maybe<(
    { __typename?: 'RewardsOrganization' }
    & OptInOrganizationFragmentFragment
  )> }
);


export const OptInPageDataForWorkEmailDocument = gql`
    query OptInPageDataForWorkEmail($workEmail: String!) {
  getRewardsOrganizationOptInPromo(workEmail: $workEmail) {
    pointsEarnedForOptingIn
  }
  organization: getRewardsOrganizationByWorkEmail(workEmail: $workEmail) {
    ...OptInOrganizationFragment
  }
}
    ${OptInOrganizationFragmentFragmentDoc}`;

/**
 * __useOptInPageDataForWorkEmailQuery__
 *
 * To run a query within a React component, call `useOptInPageDataForWorkEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptInPageDataForWorkEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptInPageDataForWorkEmailQuery({
 *   variables: {
 *      workEmail: // value for 'workEmail'
 *   },
 * });
 */
export function useOptInPageDataForWorkEmailQuery(baseOptions: Apollo.QueryHookOptions<OptInPageDataForWorkEmailQuery, OptInPageDataForWorkEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptInPageDataForWorkEmailQuery, OptInPageDataForWorkEmailQueryVariables>(OptInPageDataForWorkEmailDocument, options);
      }
export function useOptInPageDataForWorkEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptInPageDataForWorkEmailQuery, OptInPageDataForWorkEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptInPageDataForWorkEmailQuery, OptInPageDataForWorkEmailQueryVariables>(OptInPageDataForWorkEmailDocument, options);
        }
export type OptInPageDataForWorkEmailQueryHookResult = ReturnType<typeof useOptInPageDataForWorkEmailQuery>;
export type OptInPageDataForWorkEmailLazyQueryHookResult = ReturnType<typeof useOptInPageDataForWorkEmailLazyQuery>;
export type OptInPageDataForWorkEmailQueryResult = Apollo.QueryResult<OptInPageDataForWorkEmailQuery, OptInPageDataForWorkEmailQueryVariables>;