/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { usePrevious } from "@rewards-web/shared/hooks/use-previous";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { VideoCompletedBadge } from "./video-completed-badge";

interface VideoOfferCompletedModalProps {
  videoOfferId: string;
  pointsEarned: number;
  open: boolean;
  firstName: string;
  onAccept?: () => void;
}

export function VideoOfferCompletedModal({
  videoOfferId,
  pointsEarned,
  open,
  firstName,
  onAccept,
}: VideoOfferCompletedModalProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const navigate = useNavigate();

  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (open && !prevOpen) {
      track("Viewed video offer completed modal", { videoOfferId });
    }
  }, [prevOpen, open, videoOfferId, track]);

  return (
    <ResponsiveDialog maxWidth="400px" open={open} mobileType="dialog">
      <div
        css={(theme: AppTheme) => css`
          margin: ${theme.spacing(1)};
          margin-bottom: 0;
          border-radius: 12px;
          background-color: #e7f0f2;
        `}
      >
        <Typography
          variant="h1"
          color="primary"
          css={(theme: AppTheme) => css`
            text-align: center;
            padding: ${theme.spacing(2)} ${theme.spacing(2)};
            ${theme.breakpoints.up("sm")} {
              padding: ${theme.spacing(2)} ${theme.spacing(5)};
            }
          `}
        >
          <FormattedMessage
            description="Video offer page > video completed modal > personalized congratulations"
            defaultMessage="Congratulations {name}!"
            values={{ name: firstName }}
          />
        </Typography>

        <Typography
          color="primary"
          variant="body"
          css={css`
            text-align: center;
            font-size: 1.2em;
            font-weight: 500;
          `}
        >
          <FormattedMessage
            description="Video offer page > video completed modal > main message"
            defaultMessage="You just earned {points, number} {points, plural, one {point} other {points}}"
            values={{ points: pointsEarned }}
          />
        </Typography>

        <div
          css={(theme: AppTheme) => css`
            position: relative;
            height: 288px;
            width: 279px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            margin: ${theme.spacing(1)} auto 0;

            @media (max-height: 600px) {
              // hide on landscape (or short screens)
              display: none;
            }
          `}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            <VideoCompletedBadge
              css={css`
                margin-left: 10px;
              `}
            />

            <Typography
              variant="body"
              color="primary"
              css={css`
                position: absolute;
                line-height: normal;
                top: 110px;
                left: 84px;
                width: 120px;
                text-align: center;
                font-size: 1.8em;
                font-weight: 600;
                line-height: 1.2em;
              `}
            >
              <FormattedMessage
                description="Video offer page > video completed modal > points earned"
                defaultMessage="<bold>{points, number}</bold> {points, plural, one {point} other {points}}"
                values={{
                  points: pointsEarned,
                  bold: (nodes) => (
                    <>
                      <strong
                        css={css`
                          font-size: 1.7em;
                        `}
                      >
                        {nodes}
                      </strong>
                      <br />
                    </>
                  ),
                }}
              />
            </Typography>
          </div>
        </div>

        <div
          css={(theme: AppTheme) => css`
            border-radius: ${theme.shape.borderRadius}px;
            padding: ${theme.spacing(2)};
          `}
        >
          <Button
            label={formatMessage({
              description:
                "Video offer page > video completed modal > accept points button",
              defaultMessage: "Accept",
            })}
            size="large"
            color="secondary"
            onClick={() => {
              onAccept?.();
              track("Dismissed video offer completed modal", { videoOfferId });
              navigate("/rewards");
            }}
          />
        </div>
      </div>
    </ResponsiveDialog>
  );
}
