/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactEventHandler } from "react";

import { AppTheme } from "@rewards-web/shared/style/types";

import { FullscreenIcon } from "./icons/fullscreen-icon";

export interface FullscreenButtonProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  onOpenFullscreen?: ReactEventHandler<HTMLDivElement> | undefined;
}

export function FullscreenButton({
  videoRef,
  onOpenFullscreen,
}: FullscreenButtonProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        position: absolute;
        right: 4px;
        bottom: 10px;
        padding: ${theme.spacing(2)};
        padding-bottom: 10px;
        cursor: pointer;
        transition: opacity 0.2s;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
        background-color: rgba(44, 57, 63, 0.7);
        border-radius: 16px;
      `}
      onClick={(e) => {
        e.stopPropagation();
        onOpenFullscreen?.(e);
        if (videoRef.current?.requestFullscreen) {
          videoRef.current?.requestFullscreen();
        }
      }}
    >
      <FullscreenIcon />
    </div>
  );
}
