import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SurveyGoalCardDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SurveyGoalCardDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'shortName'>
  ) }
);


export const SurveyGoalCardDataDocument = gql`
    query SurveyGoalCardData {
  getMyRewardsOrganization {
    id
    shortName
  }
}
    `;

/**
 * __useSurveyGoalCardDataQuery__
 *
 * To run a query within a React component, call `useSurveyGoalCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyGoalCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyGoalCardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveyGoalCardDataQuery(baseOptions?: Apollo.QueryHookOptions<SurveyGoalCardDataQuery, SurveyGoalCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyGoalCardDataQuery, SurveyGoalCardDataQueryVariables>(SurveyGoalCardDataDocument, options);
      }
export function useSurveyGoalCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyGoalCardDataQuery, SurveyGoalCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyGoalCardDataQuery, SurveyGoalCardDataQueryVariables>(SurveyGoalCardDataDocument, options);
        }
export type SurveyGoalCardDataQueryHookResult = ReturnType<typeof useSurveyGoalCardDataQuery>;
export type SurveyGoalCardDataLazyQueryHookResult = ReturnType<typeof useSurveyGoalCardDataLazyQuery>;
export type SurveyGoalCardDataQueryResult = Apollo.QueryResult<SurveyGoalCardDataQuery, SurveyGoalCardDataQueryVariables>;