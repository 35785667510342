import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PowerHoursOfferCardDataQueryVariables = Types.Exact<{
  powerHoursOfferId: Types.Scalars['ID'];
}>;


export type PowerHoursOfferCardDataQuery = (
  { __typename?: 'Query' }
  & { powerHoursOffer?: Types.Maybe<(
    { __typename?: 'PowerHoursOffer' }
    & Pick<Types.PowerHoursOffer, 'id' | 'rangeEnd' | 'totalNumLevels' | 'currentLevelNumber' | 'trackedNumCompletedHours' | 'ended'>
    & { hourCondition?: Types.Maybe<{ __typename: 'PowerHoursWeekendHoursCondition' }>, levels: Array<(
      { __typename?: 'PowerHoursLevel' }
      & Pick<Types.PowerHoursLevel, 'id' | 'levelNumber' | 'hours' | 'pointValue' | 'finished' | 'current' | 'acknowledgedLevelFinished'>
    )> }
  )> }
);


export const PowerHoursOfferCardDataDocument = gql`
    query PowerHoursOfferCardData($powerHoursOfferId: ID!) {
  powerHoursOffer: getPowerHoursOfferById(powerHoursOfferId: $powerHoursOfferId) {
    id
    hourCondition {
      __typename
    }
    rangeEnd
    totalNumLevels
    currentLevelNumber
    levels {
      id
      levelNumber
      hours
      pointValue
      finished
      current
      acknowledgedLevelFinished
    }
    trackedNumCompletedHours
    ended
  }
}
    `;

/**
 * __usePowerHoursOfferCardDataQuery__
 *
 * To run a query within a React component, call `usePowerHoursOfferCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerHoursOfferCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerHoursOfferCardDataQuery({
 *   variables: {
 *      powerHoursOfferId: // value for 'powerHoursOfferId'
 *   },
 * });
 */
export function usePowerHoursOfferCardDataQuery(baseOptions: Apollo.QueryHookOptions<PowerHoursOfferCardDataQuery, PowerHoursOfferCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerHoursOfferCardDataQuery, PowerHoursOfferCardDataQueryVariables>(PowerHoursOfferCardDataDocument, options);
      }
export function usePowerHoursOfferCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerHoursOfferCardDataQuery, PowerHoursOfferCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerHoursOfferCardDataQuery, PowerHoursOfferCardDataQueryVariables>(PowerHoursOfferCardDataDocument, options);
        }
export type PowerHoursOfferCardDataQueryHookResult = ReturnType<typeof usePowerHoursOfferCardDataQuery>;
export type PowerHoursOfferCardDataLazyQueryHookResult = ReturnType<typeof usePowerHoursOfferCardDataLazyQuery>;
export type PowerHoursOfferCardDataQueryResult = Apollo.QueryResult<PowerHoursOfferCardDataQuery, PowerHoursOfferCardDataQueryVariables>;