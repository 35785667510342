/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import PaycheckRedemptionImage from "../images/paycheck-redemptions.png";

export function PaycheckRedemptionInfo() {
  const { formatMessage } = useFormatters();
  return (
    <div
      css={(appTheme: AppTheme) => css`
        padding: ${appTheme.spacing(2.5)};
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid ${appTheme.palette.grey[200]};
        border-radius: 20px;
      `}
    >
      <div>
        <Typography variant="h6">
          {formatMessage({
            defaultMessage: "Redeem to paycheck",
            description: "Points summary page > paycheck info > title",
          })}
        </Typography>
        <Typography
          variant="footnote"
          css={(appTheme: AppTheme) => css`
            margin-top: ${appTheme.spacing(0.5)};
          `}
        >
          {formatMessage({
            defaultMessage:
              "Redemptions may take up to 3 weeks to appear on your next paycheck.",
            description: "Points summary page > paycheck info > description",
          })}
        </Typography>
      </div>

      <img
        src={PaycheckRedemptionImage}
        alt={formatMessage({
          defaultMessage: "Paycheck redemption illustration",
          description: "Points summary page > paycheck info > image alt",
        })}
        css={css`
          width: 95px;
        `}
      />
    </div>
  );
}
