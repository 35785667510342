import { useEffect } from "react";

interface UseWindowFocusHandlerParams {
  onFocused(): void;
}

/**
 * Calls `onFocused` if window is visible
 */
export function useWindowFocusHandler({
  onFocused,
}: UseWindowFocusHandlerParams) {
  useEffect(() => {
    const listener = () => {
      onFocused();
    };

    window.addEventListener("focus", listener);
    return () => {
      window.removeEventListener("focus", listener);
    };
  }, [onFocused]);
}
