/** @jsxImportSource @emotion/react */
import { ClassNames, css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { Link } from "react-router-dom";

import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardNotification } from "@rewards-web/shared/graphql-types";
import {
  FormattedDate,
  FormattedMessage,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

export interface NotificationTimelineProps {
  notifications: Pick<
    RewardNotification,
    | "id"
    | "messageTitle"
    | "messageBody"
    | "pointsEarned"
    | "pointsDeducted"
    | "createdAt"
  >[];
}

export function NotificationTimeline({
  notifications,
}: NotificationTimelineProps): JSX.Element {
  if (notifications.length === 0) {
    return (
      <Typography
        variant="body"
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        <FormattedMessage
          description="Notifications page > timeline empty-state"
          defaultMessage="Earn points to see a timeline of notifications!"
        />

        <br />

        <Link to="/rewards">
          <FormattedMessage
            description="Notifications page > timeline empty-state cta"
            defaultMessage="Click here to start earning points"
          />
        </Link>
      </Typography>
    );
  }

  return (
    <ClassNames>
      {({ css, theme }) => (
        <Timeline
          align="left"
          className={css`
            padding: 0;
            margin-top: 0;
          `}
        >
          {notifications.map((notification) => (
            <TimelineItem
              key={notification.id}
              classes={{
                missingOppositeContent: css`
                  &:before {
                    flex: 0;
                    padding-left: 0;
                    padding-right: 0;
                  }
                `,
              }}
            >
              <TimelineSeparator>
                <TimelineDot
                  className={css`
                    background-color: ${theme.palette.divider};
                    box-shadow: none;
                  `}
                />
                <TimelineConnector
                  className={css`
                    background-color: ${alpha(theme.palette.divider, 0.7)};
                  `}
                />
              </TimelineSeparator>
              <TimelineContent
                className={css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                <Card>
                  <CardContent
                    className={css`
                      &:last-child {
                        padding-bottom: ${theme.spacing(2)};
                      }
                    `}
                  >
                    <Typography variant="subtitle" gutterBottom>
                      {notification.messageTitle}
                    </Typography>
                    <Typography
                      variant="body"
                      color="textSecondary"
                      gutterBottom
                    >
                      <FormattedDate
                        value={new Date(notification.createdAt)}
                        dateStyle="long"
                      />{" "}
                      {(notification.pointsEarned ||
                        notification.pointsDeducted) && (
                        <>
                          •{" "}
                          <FormattedMessage
                            description="Notifications page > timeline notification point value"
                            defaultMessage="{points, number} {points, plural, one {point} other {points}}"
                            values={{
                              points: notification.pointsEarned
                                ? notification.pointsEarned
                                : notification.pointsDeducted! * -1,
                            }}
                          />
                        </>
                      )}
                    </Typography>
                    {notification.messageBody && (
                      <Typography
                        variant="body"
                        color="textSecondary"
                        className={css`
                          margin-top: ${theme.spacing(1.5)};
                        `}
                      >
                        {notification.messageBody}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </ClassNames>
  );
}
