/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { GoalType } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import hhaPhoneLargeUrl from "../../../../shared/goal/graphics/hha-phone-large.png";
import { BaseGoalTrackPage, BaseGoalTrackPageProps } from "../base-track-page";
import { useEvvTrackPageDataQuery } from "./evv-track-page-data.generated";
import { EVVTrackPageGoalDescription } from "./evv-track-page-goal-description";
import { getSortedAndSlicedUntrackedVisits, getTodayMidnightInMs } from "./lib";
import { ShiftsToReachGoal } from "./shifts-to-reach-goal";

export interface EvvGoalTrackProps {
  trackData: BaseGoalTrackPageProps["trackData"];
  analyticsData: BaseGoalTrackPageProps["analyticsData"];
}

export function EvvGoalTrackPage({
  trackData,
  analyticsData,
}: EvvGoalTrackProps) {
  const { formatMessage } = useFormatters();

  const availableGoal = !trackData.loading
    ? trackData.availableGoals?.[0]
    : null;

  const targetStreak =
    availableGoal?.goalTracker?.__typename === "EVVStreakGoalTracker"
      ? availableGoal.goalTracker.targetStreak
      : null;

  const trackedVisits =
    availableGoal?.goalTracker?.__typename === "EVVStreakGoalTracker"
      ? availableGoal.goalTracker.trackedVisits
      : [];

  const startDateGte = trackedVisits.length
    ? trackedVisits[trackedVisits.length - 1].rangeStart + 1 // Ensures the query skips the last tracked visit
    : getTodayMidnightInMs();

  const { data, loading: dataLoading } = useEvvTrackPageDataQuery({
    variables: {
      startDateGte,
      limit: 50,
    },
    skip: !targetStreak,
    onError: reportError,
  });

  const untrackedVisits = data?.listMyVisitsAsOfStartDate.items || [];
  const sortedUntrackedVisits = getSortedAndSlicedUntrackedVisits(
    untrackedVisits,
    trackedVisits,
    targetStreak
  );

  const evvRules = data?.getMyRewardsOrganizationEVVRules.rules;

  return (
    <BaseGoalTrackPage
      trackData={trackData}
      analyticsData={analyticsData}
      title={formatMessage({
        defaultMessage: "Clock in and out Goals",
        description: "Goal track page > evv streak track title",
      })}
      icon={<img src={hhaPhoneLargeUrl} alt="phone" />}
      content={
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: ${theme.spacing(3)};
          `}
        >
          {availableGoal && availableGoal.type === GoalType.HhaxLogin && (
            <Typography
              variant="body"
              css={(theme: AppTheme) => css`
                margin-top: ${theme.spacing(3)};
              `}
            >
              {formatMessage({
                description:
                  "Goal track page > evv streak track > hhax login description",
                defaultMessage:
                  "Earn points for clocking in and out of upcoming shifts. You'll first need to log in to the HHAeXchange app to unlock other goals.",
              })}
            </Typography>
          )}

          {targetStreak && (
            <>
              <EVVTrackPageGoalDescription
                evvRulesLoading={dataLoading}
                evvRules={evvRules}
              />

              {(trackedVisits.length > 0 ||
                sortedUntrackedVisits.length > 0) && (
                <ShiftsToReachGoal
                  targetStreak={targetStreak}
                  trackedVisits={trackedVisits}
                  untrackedVisitsLoading={dataLoading}
                  untrackedVisits={sortedUntrackedVisits}
                />
              )}
            </>
          )}
        </div>
      }
    />
  );
}
